import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { error } from 'console';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationModalComponent } from 'src/app/layout/confirmation-modal/confirmation-modal.component';
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { SchoolData } from 'src/app/models/schoolUser';
import { JobDetailsComponent } from '../../jobs/job-details/job-details.component';
import { SharedService } from '../../../../services/api/message.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent implements OnInit {
  @ViewChild(JobDetailsComponent) viewData!: JobDetailsComponent
  public onClose!: Subject<boolean>;
  
  public schoolProps!: SchoolData;
  teacherName: any;
  type: any;
  notes: any;
  id: any;
  teacher: any;
  from: any;
  sendType: any;
  headHunterJobId: any
  checked_status: any;
  public sendMessageToStaff: any;
  ShowLenghtError: any = 280;
  ShowLenght: any;
  // @ViewChild('addEdit') addEdit: any;
  submitted: boolean = false;
  selectAllCheck: any;
  selectAllVal: any
  constructor(public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private bookingService: BookingService,
    private _router: Router,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private sharedService: SharedService
    ) { }

  ngOnInit(): void {
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.formInit();
    this.checkLength()
    this.onClose = new Subject();
  }
  formInit() {
    this.sendMessageToStaff = this.fb.group({
      email_content: ['', [Validators.required, Validators.maxLength(280), this.noWhitespaceValidator]],
      provider_ids: ['']
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.sendMessageToStaff.controls;
  }
  getControlMailValues() {
    return this.sendMessageToStaff.value;
  }
  sendMessage() {
    this.submitted = true;
    if (this.sendMessageToStaff.invalid) {
      return;
    }
    this.ngxService.start();
    if (this.from == 'smart_groups') {
      this.sendMessageToStaff.patchValue({
        provider_ids: this.teacher
      });
      this.checked_status.forEach((x: any) => {
        x.isChecked = false;
      });
    } else if (this.from == 'find_new') {
      this.sendMessageToStaff.patchValue({
        provider_ids: (this.teacher)
      });
      this.checked_status.forEach((x: any) => {
        x.isChecked = false;
      });
    } else if (this.from == 'job_details_bulk') {
      this.sendMessageToStaff.patchValue({
        provider_ids: (this.teacher)
      });
      this.checked_status.forEach((x: any) => {
        x.isChecked = false;
      });
    } else if (this.from == 'headHunter') {
      this.sendMessageToStaff.patchValue({
        provider_ids: (this.teacher)
      });
      this.selectAllVal = false
      this.checked_status.forEach((x: any) => {
        x.isChecked = false;
      });
    } else {
      this.sendMessageToStaff.patchValue({
        provider_ids: (this.teacher.id).toString()
      });
    }


    // this.sendMessageToStaff.addControl('provider_ids',  new FormControl((this.teacher.id).toString()));
    this.bookingService.sendMailInvite(this.getControlMailValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.toastr.success(res.message);
          this.bsModalRef.hide();
          this.selectAllCheck = this.selectAllVal
          if (this.headHunterJobId != undefined) {
            let setValueTrue = 'triggerSetAllFunc'
            this.sharedService.setValData.next(setValueTrue)
            this.bsModalRef.hide()
            this.ngxService.stop()
            // let currentUrl = "school/headhunter";
            // this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            //   this._router.navigate([currentUrl], { queryParams: { jobId: this.headHunterJobId } });
            // });
          } else if (this.from == 'job_details_bulk') {
            // this.viewData.setAllTrue()
            let setValueTrue = 'triggerSetAllFunc'
            this.sharedService.setValData.next(setValueTrue)
            this.bsModalRef.hide()
            this.ngxService.stop()
          } else if (this.from == 'job_details') {
            this.bsModalRef.hide()
            this.ngxService.stop()
          } else {
            this.bsModalRef.hide()
            this.ngxService.stop()
          }
        } else {
          this.toastr.error(res.message);
          this.bsModalRef.hide();
          this.ngxService.stop();
        }
        this.onClose.next(true);
      },
      error: (err: any) => {
        this.toastr.error(err.error.message);
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  checkLength() {
    let timing = this.sendMessageToStaff.controls['email_content'].value;
    let charectorLength = this.ShowLenghtError - timing.length
    this.ShowLenght = charectorLength
  }
  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }
}
