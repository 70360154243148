import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
// Services
import { AuthenticationService } from 'src/app/services/auth/authentication.service';

@Component({
  selector: 'app-consumer-header',
  templateUrl: './consumer-header.component.html',
  styleUrls: ['./consumer-header.component.scss']
})
export class ConsumerHeaderComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }


}
