import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { url } from 'inspector';
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { TeacherProfilePopupComponent } from '../../teacher-profile-popup/teacher-profile-popup.component';
import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';
import { SendMailComponent } from '../../modals/send-mail/send-mail.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-job-settings',
  templateUrl: './job-settings.component.html',
  styleUrls: ['./job-settings.component.scss']
})
export class JobSettingsComponent implements OnInit {
  public schoolProps!: SchoolData;
  public updatePassword: any;
  public personalForm: any;
  @ViewChild('cancelSubsModal') cancelSubsModal: any;

  public jobApllicantNotification: any;
  editData: any;
  edit: boolean = false;
  action: any;

  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.ngxService.start()
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.formInit()
    this.bookingService.profilePageDetails('job_settings').subscribe({
      next: (res: any) => {
        this.editData = res?.data;
        this.personalForm = this.fb.group({
          action: ['personal_info_edit', [Validators.required]],
          first_name: [this.editData?.add_user_info?.first_name, [Validators.required]],
          last_name: [this.editData?.add_user_info?.last_name, [Validators.required]],
          phone_number: [this.editData?.add_user_info?.phone, [Validators.required]],
        });
        this.jobApllicantNotification = this.fb.group({
          action: ['job_applicant_notification', [Validators.required]],
          enable_notification: [this.editData?.add_user_info.job_summary_settings?.job_summary, [Validators.required]],
          job_alert_notify: [this.editData?.add_user_info.job_summary_settings?.send_every, [Validators.required]],
        });
        this.ngxService.stop();
      },
      error: (err: any) => {
        this.ngxService.stop();
        console.log(err);
      }
    })
  }

  formInit() {
    this.updatePassword = this.fb.group({
      action: 'password_change',
      current_password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]],
    });

    this.personalForm = this.fb.group({
      action: 'personal_info_edit',
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
    });

    this.jobApllicantNotification = this.fb.group({   
        action: 'job_applicant_notification',
        enable_notification: ['', [Validators.required]],
        job_alert_notify: ['', [Validators.required]],
      });
    
  }
  
  getFormControlValues() {
      return this.updatePassword.value;
  }

  SubmitForm(formFrom: any) {
    this.ngxService.start();
    this.bookingService.profileSaveChanges(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.modalService.hide()
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          // this.modalService.hide()
          this.toastr.error(res.message);
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  getJobNotiFormControlValues() {
    return this.jobApllicantNotification.value;
}

SubmiJobNotitForm(formFrom: any) {
  this.ngxService.start();
  this.bookingService.profileSaveChanges(this.getJobNotiFormControlValues()).subscribe({
    next: (res: any) => {
      if (res.status) {
        this.modalService.hide()
        this.toastr.success(res.message);
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      } else {
        // this.modalService.hide()
        this.toastr.error(res.message);
      }
      this.ngxService.stop();
    },
    error: (err: any) => {
      console.log(err);
      this.ngxService.stop();
    }
  })
}

  getPersonalFormControlValues(){
    return this.personalForm.value;
  }
  submitPersonalForm(formFrom: any) {
    this.ngxService.start();
    this.bookingService.profileSaveChanges(this.getPersonalFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.modalService.hide()
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          // this.modalService.hide()
          this.toastr.error(res.message);
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  setJobAlert() {
    this.jobApllicantNotification.controls['enable_notification'].value == 0 ? this.jobApllicantNotification.patchValue({
      job_alert_notify: '',
    }) : this.jobApllicantNotification.patchValue({
      job_alert_notify: 'weekly',
    });
  }
  disableFalse(){
    this.edit = true
  }
  openCancelSubsModal(action: any){
    this.bsModalRef = this.modalService.show(this.cancelSubsModal, { class: 'modal-md', backdrop: 'static' });
    this.action = action
  }

  closeSubs(action: any){
    this.ngxService.start();
    this.bookingService.closeSubsAPI(action).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.modalService.hide()
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          // this.modalService.hide()
          this.toastr.error(res.message);
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  
}


