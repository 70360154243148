

<div class="modal-body pb-0 text-center">
    <div class="row align-items-center">
        <div class="col-md-12">
            <p class="user-title">Payment Processing...</p>
            <p class="loader"></p>
            <p class="txt-regular fs-13 mt-5">Do  not refresh or reload the page</p>
            <p class="txt-regular fs-13 mt-5">Page will be redirected<br> automatically</p>
            <!-- <p class="user-title mt-10">{{display}}
            </p> -->
            <p class="user-title fs-13 mt-10">Please wait...</p>
        </div>
    </div>
</div>
