<div class="row mt-20">
    <div class="col-lg-12 col-12">
        <div class="card px-15">
            <nav>
                <div class="nav nav-tabs  invite-request-preview" id="nav-tab" role="tablist">
                    <button class="nav-link active mr-0 h_b" id="nav-profile-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile"
                        aria-selected="false">Requests <span
                            class="preview-length ml-5">{{pendingRequest?.length}}</span>
                    </button>
                    <button class="nav-link  mr-0 h_b" id="nav-home-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                        aria-selected="true">Invites <span
                            class="preview-length  ml-5">{{pendingInvites?.length}}</span></button>

                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <div *ngIf="pendingRequest?.length > 0">
                        <div class="mt-10" *ngFor="let teacher of pendingRequest;  index as i">
                            <div class="col-md-12 col-lg-12 d-flex align-items-center invite-request-preview">
                                <div class="pic-and-check ml-0">
                                    <img alt="img"
                                        [src]="teacher.teacher.meta.profile_image != null ? teacher.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                        class="profile-picture img-complete-match">
                                </div>

                                <div class="pic-and-check ml-10">
                                    <div class="d-flex">
                                        <p>
                                            <span
                                                class="text-regular-bold c-p">{{teacher.teacher.user.full_name}}</span>
                                        </p>
                                    </div>
                                    <p class="user-sub-title">
                                        <span
                                            *ngIf="teacher?.teacher?.meta.you_are_a">{{teacher?.teacher?.meta.you_are_a}}
                                        </span>
                                        <span *ngIf="teacher?.teacher?.meta.you_are_a" class="divider-right ml-3 mr-5">
                                        </span>
                                        {{teacher?.teacher?.distance_from}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="pendingRequest?.length == 0">
                        <div class="col-md-12 col-lg-12 mt-20 text-center invite-request-preview">
                            <p class="text-regular-medium">You don’t have any requests</p>
                            <p><img class="no-invite-image"
                                    src="assets/images/NoResultFound/no_data_pending_request.png"
                                    alt="No requests found!" /></p>
                        </div>
                    </div>

                    <p class="text-regular-bold clr-cc-vilot text-center mt-20 c-p h_b"
                        routerLink="/school/pending-request" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Go to requests <i
                            class="bi bi-arrow-up-right fs-12"></i></p>

                </div>
                <div class="tab-pane fade " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div *ngIf="pendingInvites?.length > 0">
                        <div class="mt-10" *ngFor="let teacher of pendingInvites;  index as i">
                            <div class="col-md-12 col-lg-12 d-flex invite-request-preview">
                                <div class="pic-and-check ml-0">
                                    <img alt="img"
                                        [src]="teacher.teacher.meta.profile_image != null ? teacher.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                        class="profile-picture img-complete-match">
                                </div>

                                <div class="pic-and-check ml-10">
                                    <div class="d-flex">
                                        <p>
                                            <span
                                                class="text-regular-bold c-p">{{teacher.teacher.user.full_name}}</span>
                                        </p>
                                    </div>
                                    <p class="user-sub-title">
                                        <span
                                            *ngIf="teacher?.teacher?.meta.you_are_a">{{teacher?.teacher?.meta.you_are_a}}
                                        </span>
                                        <span *ngIf="teacher?.teacher?.meta.you_are_a" class="divider-right ml-3 mr-5">
                                        </span>
                                        {{teacher?.teacher?.distance_from}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="pendingInvites?.length == 0">
                        <div class="col-md-12 col-lg-12 mt-20 text-center invite-request-preview">
                            <p class="text-regular-medium">You don’t have any invites</p>
                            <p><img class="no-invite-image"
                                    src="assets/images/NoResultFound/no_data_pending_invites.png"
                                    alt="No invites found!" /></p>

                        </div>
                    </div>
                    <p class="text-regular-bold clr-cc-vilot text-center mt-20 c-p h_b"
                        routerLink="/school/pending-invites" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Go to invites <i
                            class="bi bi-arrow-up-right fs-12"></i></p>
                </div>

            </div>
        </div>
    </div>
</div>