<div class="find-new-page pb-90 p-15">
    <p class="mt-15 text-regular-medium text-right">
        Do you want to grow your list?
        <button (click)="redirectToPostCallOut()" class="btn cc_p_btn fs_13 ml-10 pt-10 pb-10 pl-30 pr-30">
            Post a callout
        </button>
    </p>
    <div class="row">
        <div class="col-lg-9 col-md-12 col-12">
            <form [formGroup]="findNewFilterForm">
                <div class="row align-items-center mt-10">
                    <div class="col-lg-3 col-md-3 mt-10">
                        <div class="input-group">
                            <input id="name" formControlName="filter_name" type="text"
                                placeholder="Search for name and email" class="form-control"
                                aria-label="Search for name and email" />
                            <button class="input-group-text" for="search-by-name" (click)="getFilterInputs()"><i
                                    class="bi bi-search"></i></button>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 mt-10">
                        <div class="input-group">
                            <input type="text" readonly placeholder="Available on" class="form-control" bsDatepicker
                                [bsConfig]="{dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-red',showWeekNumbers: false}"
                                [daysDisabled]="[6, 0]" [(ngModel)]="bsValue" (ngModelChange)="getFilterInputs()"
                                [ngModelOptions]="{ standalone: true }" />
                            <span class="input-group-text" for="date-filter" (click)="clearDateInput()"><i
                                    class="bi  bi-x-lg"></i></span>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 mt-10">
                        <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                            [multiple]="false" id="state" (change)="getFilterInputs()"
                            aria-label="Default select example" formControlName="filter_state">
                            <ng-option *ngFor="let state of filtersArray.states" value="{{ state.value }}">{{
                                state.title }}</ng-option>
                        </ng-select>
                    </div>

                    <div class="col-lg-3 col-md-3 mt-10">
                        <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                            [multiple]="false" id="distance" (change)="getFilterInputs()"
                            aria-label="Default select example" formControlName="filter_distance">
                            <ng-option *ngFor="let distance of filtersArray.distance_choices"
                                value="{{ distance.value }}">
                                {{ distance.title }}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>

                <div class="row align-items-center mt-10">
                    <div class="col-lg-4 col-md-4 mt-10">
                        <ng-select [items]="subjectsAndTags" formControlName="filter_tags" (change)="getFilterInputs()"
                            bindLabel="title" bindValue="value" groupBy="tags" [multiple]="true"
                            placeholder="Filter by subjects">
                            <ng-template ng-optgroup-tmp let-item="item">
                                {{ item.tag }}
                            </ng-template>
                        </ng-select>
                    </div>

                    <div class="col-lg-4 col-md-4 mt-10">
                        <ng-select formControlName="filter_experienceItems" [items]="levelFilter" [multiple]="true"
                            bindLabel="title" bindValue="value" placeholder="Levels" (change)="getFilterInputs()">
                        </ng-select>
                    </div>

                    <div class="col-lg-4 col-md-4 mt-10" id="custom-find-new-ng">
                        <ng-select formControlName="filter_consumer_type" [multiple]="true" placeholder="Role type"
                            (change)="getFilterInputs()">
                            <ng-option *ngFor="let tag of consumerType" [value]="tag['id']"
                                [disabled]="tag['is_parent']">
                                {{ tag["title"] }}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>

                <div class="row align-items-center mt-10">
                    <!-- <div class="col-lg-3 col-md-3 mt-10">
                <a>
                    <label class="form-check-label c-p">
                        <strong><i class="bi bi-x pre_icon_clear"></i> Clear all filters</strong>
                    </label>
                </a>
            </div>

            <div class="col-lg-3 col-md-3 mt-10">
                <a class="form-check">
                    <label class="form-check-label c-p">
                        <strong><i class="bi bi-filter"></i> Filters</strong>
                    </label>
                </a>
            </div> -->

                    <div class="col-lg-12 col-md-12 mt-10" *ngIf="this.schoolProps.properties.is_doe_school">
                        <div class="d-flex flex-prop">
                            <div class="form-check">
                                <input class="form-check-input c-p mt-4" type="checkbox" id="find_new_is_validated"
                                    formControlName="filter_is_validated" (change)="getFilterInputs()" />
                                <label class="form-check-label c-p" for="find_new_is_validated">
                                    Approved to teach only
                                </label>
                            </div>
                            <!-- <div *ngIf="this.schoolProps.properties.is_doe_school"> -->
                            <div class="form-check">
                                <input class="form-check-input c-p mt-4" type="checkbox" id="small_group_tuition"
                                    formControlName="is_sgt" (change)="getFilterInputs()" />
                                <label class="form-check-label c-p" for="small_group_tuition">
                                    Small Group Tuition (SGT)
                                </label>
                            </div>
                            <div *ngIf="from_page_cookie == 'loginas'">
                                <div class="form-check">
                                    <input class="form-check-input c-p mt-4" type="checkbox" id="NSBTS_PSL"
                                        formControlName="is_nsbts" (change)="getFilterInputs()" />
                                    <label class="form-check-label c-p" for="NSBTS_PSL">
                                        NSBTS/PSL Staff
                                    </label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input c-p mt-4" type="checkbox" id="other_staff"
                                        formControlName="is_other_staff" (change)="getFilterInputs()" />
                                    <label class="form-check-label c-p" for="other_staff">
                                        Other Staff
                                    </label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input c-p mt-4" type="checkbox" id="corporate_staff"
                                        formControlName="is_corporate_staff" (change)="getFilterInputs()" />
                                    <label class="form-check-label c-p" for="corporate_staff">
                                        Corporate Staff
                                    </label>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mt-10" *ngIf="this.schoolProps.properties.is_bathurst_school">
                        <div class="d-flex flex-prop">
                            <div class="form-check">
                                <input class="form-check-input c-p mt-4" type="checkbox" id="find_new_is_validated"
                                    formControlName="filter_is_validated" (change)="getFilterInputs()" />
                                <label class="form-check-label c-p" for="find_new_is_validated">
                                    Verified teachers only
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mt-10"
                        *ngIf="this.schoolProps.properties.is_maitland_newcastle_school">
                        <div class="d-flex flex-prop">
                            <div class="form-check">
                                <input class="form-check-input c-p mt-4" type="checkbox" id="find_new_is_validated"
                                    formControlName="filter_is_validated" (change)="getFilterInputs()" />
                                <label class="form-check-label c-p" for="find_new_is_validated">
                                    Verified teachers only
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mt-10"
                        *ngIf="this.schoolProps.properties.is_canberra_goulburn_school">
                        <div class="d-flex flex-prop">
                            <div class="form-check">
                                <input class="form-check-input c-p mt-4" type="checkbox" id="find_new_is_validated"
                                    formControlName="filter_is_validated" (change)="getFilterInputs()" />
                                <label class="form-check-label c-p" for="find_new_is_validated">
                                    Verified teachers only
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mt-10" *ngIf="this.schoolProps.properties.is_edubase_school">
                        <div class="d-flex flex-prop">
                            <div class="form-check">
                                <input class="form-check-input c-p mt-4" type="checkbox" id="find_new_is_validated"
                                    formControlName="filter_is_validated" (change)="getFilterInputs()" />
                                <label class="form-check-label c-p" for="find_new_is_validated">
                                    Verified teachers only
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mt-10" *ngIf="this.schoolProps.properties.is_lismore_school">
                        <div class="d-flex flex-prop">
                            <div class="form-check">
                                <input class="form-check-input c-p mt-4" type="checkbox" id="find_new_is_validated"
                                    formControlName="filter_is_validated" (change)="getFilterInputs()" />
                                <label class="form-check-label c-p" for="find_new_is_validated">
                                    Verified teachers only
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mt-10" *ngIf="this.schoolProps.properties.is_wagga_school">
                        <div class="d-flex flex-prop">
                            <div class="form-check">
                                <input class="form-check-input c-p mt-4" type="checkbox" id="find_new_is_validated"
                                    formControlName="filter_is_validated" (change)="getFilterInputs()" />
                                <label class="form-check-label c-p" for="find_new_is_validated">
                                    Verified teachers only
                                </label>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-3 col-md-3 mt-10">
                <div class="form-check">
                    <input class="form-check-input c-p" type="checkbox" value=""
                        id="find-new-intensive-learning-program">
                    <label class="form-check-label c-p" for="find-new-intensive-learning-program">
                        COVID intensive learning support program
                    </label>
                </div>
            </div> -->
                </div>
            </form>
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="">
                        <div class="col-md-12 col-lg-12 col-sm-12 align-items-center mt-10">
                            <!-- <p class="grey-3-14">{{(teachersList | async).length}} matches found</p> -->
                            <div class="col-md-12 col-lg-12 col-sm-12 mt-10">
                                <div class="justify-content-space-bet flex-wrap availability-legends">
                                    <div class="text-legend align-items-center d-flex">
                                        <div class="user-sub-title available-color mr-5"></div>
                                        <div class="txt-regular">Available</div>
                                    </div>
                                    <div class="text-legend align-items-center d-flex ml-5">
                                        <div class="havent-indicated user-sub-title mr-5"></div>
                                        <div class="txt-regular">Haven’t Indicated</div>
                                    </div>
                                    <div class="text-legend align-items-center d-flex ml-5">
                                        <div class="booked user-sub-title mr-5"></div>
                                        <div class="txt-regular">Booked</div>
                                    </div>
                                    <div class="text-legend align-items-center d-flex ml-5">
                                        <div class="booked-elsewhere user-sub-title mr-5"></div>
                                        <div class="txt-regular">Booked Elsewhere</div>
                                    </div>
                                    <div class="text-legend align-items-center d-flex ml-5">
                                        <div class="unavailable user-sub-title mr-5"></div>
                                        <div class="txt-regular">Unavailable</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-15 align-items-center " *ngIf="consumerList?.length > 0">
                            <div class="col-lg-6 align-items-center mt-10">
                                <div class="form-check">

                                    <input #checkAll class="form-check-input mt-4 mr-5" [(ngModel)]="selectAllCheck"
                                        (change)="setCheckbox($event, 'All')" id="checkAll" type="checkbox" />
                                    <label class="form-check-label" for="checkAll">
                                        <span class="h_b" *ngIf="!selectAllCheck">Select all</span>
                                        <span class="h_b" *ngIf="selectAllCheck">Unselect all</span>
                                    </label>
                                    <span class="ml-10 text-r-m-16 c-p">
                                        {{ consumerDetails.length }} Selected
                                    </span>
                                </div>


                            </div>
                            <!-- <div class="col-lg-4 mt-10">
                                <p class="">{{ teacherLengthLimit }} matches found</p>
                            </div> -->
                            <div class="col-lg-6 mt-10">
                                <div class="d-flex lign-items-center  text-regular-medium justify-content-end "
                                    [ngClass]="hideButton ? 'input-cursor-na':''">
                                    <!-- <span class="h_b d-flex align-items-center c-p"> <i class="bi  bi-send-fill"></i>
                                        <span class="ml-3 h_b" [ngClass]="!hideButton ? 'text-regular-bold':''"> Invite
                                            selected </span>
                                    </span> -->
                                    <span class="d-flex align-items-center h_b ml-12 c-p" (click)="sendMail()">
                                        <i class="bi bi-chat-left fs-14"></i>
                                        <span [ngClass]="!hideButton ? 'text-regular-bold':''" class="ml-4 h_b">Message
                                            selected</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 col-sm-12 col-lg-12 mt-20 Fortnight" *ngIf="consumerList?.length > 0">
                            <a class="c-p user-title t-dec-none h_b clr-cc-vilot" (click)="prevFortnight()"><i
                                    class="bi fs-20 bi-arrow-left-short v-align-m"></i> Previous
                                Fortnight</a>

                            <a class="c-p user-title float-right h_b t-dec-none h_b clr-cc-vilot"
                                (click)="nextFortnight()">Next Fortnight
                                <i class="bi bi-arrow-right-short fs-20 v-align-m"></i></a>
                        </div>
                    </div>

                    <div class="" *ngIf="FindNewteachersList | async as FindNewlist">
                        <div class="mt-30" *ngIf="consumerList?.length > 0">
                            <div class="card px-15 mt-15" *ngFor="let teacher of FindNewlist; index as i">
                                <div class="row mb-10">
                                    <div class="col-md-9 col-sm-8 col-lg-9">
                                        <div class="col-md-12 col-lg-12 d-flex">
                                            <div class="pic-and-check">
                                                <input (click)="setCheckbox($event, teacher)"
                                                    [checked]="teacher.isChecked" #checkboxes
                                                    class="form-check-input mr-10 ml-10" type="checkbox" />
                                            </div>
                                            <div class="pic-and-check ml-0">
                                                <img alt="img" [src]="
                            teacher.teacher.meta.profile_image != null
                              ? teacher.teacher.meta.profile_image
                              : '../../../../assets/images/teacher-static-profile.png'
                          " class="profile-picture img-complete-match" />
                                            </div>

                                            <div class="pic-and-check ml-10">
                                                <p class="d-flex align-items-center">

                                                    <span class="s_n"
                                                        (click)="getTeacherAvailabilityModal(teacher.teacher.id)">
                                                        {{ teacher.teacher.user.full_name }}
                                                    </span>
                                                    <span
                                                        *ngIf="teacher.teacher.is_student && teacher.teacher.student_tag != ''"
                                                        class="s_tag ml-5">{{
                                                        teacher.teacher.student_tag }}
                                                    </span>
                                                    <span *ngIf="teacher.teacher.user.new_teacher"
                                                        class="new_tag ml-5">New
                                                    </span>
                                                    <span>
                                                        <app-validated-dict
                                                            [validateDict]="teacher.teacher.validated_dict">
                                                        </app-validated-dict>
                                                    </span>
                                                </p>

                                                <p class="user-sub-title">
                                                    {{ teacher.teacher.meta.you_are_a
                                                    }}<span class="divider-right ml-3 mr-5"></span>{{
                                                    teacher.teacher.user.previous_login
                                                    }}<span class="divider-right ml-3 mr-5"></span>{{
                                                    teacher.teacher.distance_from }}
                                                </p>
                                                <p class="" *ngIf="teacher.teacher.meta.status">
                                                    <span class="clr-grey-3 user-sub-title">Status : </span><span
                                                        class="user-sub-title">{{teacher.teacher.meta.status}}</span>
                                                </p>
                                                <!-- <p *ngIf="teacher.teacher.accreditation.accreditation" class="text-s-m-g-3">Acc No :
                                <span class="user-sub-title"
                                    *ngIf="teacher.teacher.accreditation.accreditation.length > 0">{{teacher.teacher.accreditation.accreditation[0].accreditation_id}}</span>
                            </p> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="col-lg-3 col-sm-4 col-md-3  d-flex text-right justify-content-right  sys-tab-res-567">
                                        <span class="add-all-section pr-15">
                                            <p [ngClass]="this.consumerDetails.length > 0 ? 'input-cursor-na' : ''"
                                                class="c-p text-right clr-cc-vilot"
                                                (click)="sendMessage(teacher.teacher)">
                                                <i class="bi bi-chat-left mr-3"></i>
                                            </p>
                                            <div *ngIf="teacher.is_invite_on == false"
                                                [ngClass]="this.consumerDetails.length > 0 ? 'input-cursor-na' : ''"
                                                class="text-regular-bold c-p fit-content clr-cc-vilot h_b mt-10 text-right"
                                                (click)="openSendInviteModal(teacher.teacher)">
                                                <i class="bi bi-send-fill"></i> Send Invite
                                            </div>

                                            <button *ngIf="teacher.is_invite_on == true"
                                                class="notesTooltip  c-p fit-content  mt-10">
                                                <span
                                                    class="text-regular-bold fit-content c-p clr-green mt-10 text-right"><i
                                                        class="bi bi-check2"></i> Invite sent
                                                </span>
                                                <span class="notesTooltiptext text-s-m-g-1">{{ teacher.invite_sent_date
                                                    }}</span>
                                            </button>
                                            <!-- 
                                            <div *ngIf="teacher.is_invite_on == true" triggers="mouseenter:mouseleave"
                                                popover="{{ teacher.invite_sent_date }}"
                                                class="text-regular-bold fit-content c-p clr-green mt-10 text-right">
                                                <i class="bi bi-check2"></i> Invite sent
                                            </div> -->
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <app-inline-calendar from="FNT" [availability]="teacher.availability"
                                        (onDatePicked)="setArrData($event)">
                                    </app-inline-calendar>
                                </div>
                            </div>
                        </div>
                        <div class="mt-30" *ngIf="consumerList?.length == 0">
                            <p class=" text-center"><img class="no-invite-image width-40"
                                    src="assets/images/my_list_empty.png" alt="No matches found!" /></p>
                            <p class="title text-center">No results match your criteria
                            </p>
                            <p class="title text-center">Please try again or adjust the distance from school filter
                            </p>
                            <p class="text-center"><button class="btn cc_p_btn fs_13 mt-20 text-center"
                                    (click)="resetFilters();">Reset
                                    filters</button></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-3 hideON991">
            <app-invite-request-tab></app-invite-request-tab>
            <!-- <div class="row mt-20">
        <div class="col-lg-12 col-12">
          <div class="card px-15">
            <p class="text-s-m-g-3">
              Are you looking for more teachers to add to your list?
            </p>
            <button class="btn cc_p_btn fs_13 mt-10">Post a callout</button>
          </div>
        </div>
      </div> -->
        </div>
    </div>
</div>
<ng-template #sendInviteModal>
    <form [formGroup]="sendInviteForm">
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">
                Invite to join your list
            </h4>
            <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <p class="text-regular-medium">
                        Clicking “Confirm” assumes you have crossed checked with any
                        relevant registration body that they are qualified and approved to
                        work in your school.
                    </p>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button type="button" (click)="sendInvite(teacher)" class="btn cc_p_btn fs_13">
                Confirm
            </button>
            <!-- <button type="button" *ngIf="!multipleType" (click)="cancelInvite(teacher)"
                        class="btn cc_p_btn fs_13">confirm</button>
                    <button type="button" *ngIf="multipleType" (click)="cancelMultipleInvite()"
                        class="btn cc_p_btn fs_13">confirm</button> -->
        </div>
    </form>
</ng-template>