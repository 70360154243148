import { Component, Injector, AfterViewInit  } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Route } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { BodyComponent } from './body/body.component';
import { ConsumerHeaderComponent } from './layout/consumer-header/consumer-header.component';
import { ProducerHeaderComponent } from './layout/producer-header/producer-header.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';

interface toggleSidenav {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],    
    standalone: true,
    imports: [SideNavComponent, ProducerHeaderComponent, ConsumerHeaderComponent, BodyComponent]
})

export class AppComponent {

  title = 'Class Cover';
  producerHeader: boolean = false;
  consumerHeader: boolean = false;
  publicHeader: boolean = false;
  sideNav: boolean = false;

  titleService: Title;

  // Manage the sidebar collapse and screen width
  isSidenavCollapsed = false;
  screenWidth = 0;

  constructor(
    public router: Router,
    injector: Injector,
    public activatedRoute: ActivatedRoute
  ) {
    this.titleService = injector.get<Title>(Title);
  }

  // ngAfterViewInit(): void {
  //   console.log('View has been initialized');
  // }

  ngOnInit() {
    this.isCheckUser();
    const appTitle = this.titleService.getTitle();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Check the current route's title from route data
      const title = this.getTitleFromRoute();
      this.titleService.setTitle(title);  // Set the page title
    });

    // Automatically collapse sidebar on small screens
    this.onResize(window.innerWidth);
    window.addEventListener('resize', () => this.onResize(window.innerWidth));
  }

  private getTitleFromRoute(): string {
    let title = 'Class Cover';  // Default title if no title is set
    let route = this.router.routerState.snapshot.root;

    // Traverse the router tree to find the title set in the route data
    while (route.firstChild) {
      route = route.firstChild;
    }

    if (route.data && route.data['title']) {
      title = route.data['title'];
    }

    return title;
  }

  // Detect screen size and adjust sidebar state
  onResize(width: number): void {
    this.screenWidth = width;
    if (width <= 768) { // Example: collapse sidebar on small screens
      this.isSidenavCollapsed = true;
    } else {
      this.isSidenavCollapsed = false;
    }
  }

  // Check user roles and set headers and side nav visibility
  isCheckUser() {
    const url = this.router.url;
    if (
      (url === '/') ||
      (url === '/login') ||
      (url.match(/\/login.*/)) ||
      (url.match(/\/public-profile.*/)) ||
      (url.match(/\/signup.*/)) ||
      (url === '/signup') ||
      (url === '/register') ||
      (url === '/public-profile')
    ) {
      return false;
    }
    let userRole = localStorage.getItem('userRole');
    switch (userRole) {
      case 'School':
        this.producerHeader = true;
        this.sideNav = true;
        break;
      case 'Teacher':
        this.consumerHeader = true;
        break;
      default:
        this.publicHeader = true;
        break;
    }

    return true;
  }
  onToggleSideNav(data: toggleSidenav): void {
    this.screenWidth = data.screenWidth;
    this.isSidenavCollapsed = data.collapsed;
  }
}
