<div [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'"
    class="booking-request-page pb-40 pt-15 ">
    <span *ngIf="paymentHistory?.length > 0">
        <div class="history-header sticky  px-15 md-d-none mt-20">
            <div class="row align-items-center">
                <div class="col-lg-2">
                    <p class="txt-regular">Date</p>
                </div>
                <div class="col-lg-4">
                    <p class="txt-regular">Description</p>
                </div>
                <div class="col-lg-2">
                    <p class="txt-regular">Invoice total</p>
                </div>
                <div class="col-lg-4">
                    <p class="txt-regular">Status</p>
                </div>
            </div>
        </div>
        <!-- <ul>
			<li *ngFor="let item of data | paginate: { itemsPerPage: 10, currentPage: 1 }">{{ item.created_at }}</li>
		</ul> -->


        <div class="history-description mt-20" *ngIf="paymentHistoryList | async as paymentHistory">
            <div class="card px-15 mt-10" *ngFor="let data of paymentHistory">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-md-4 col-sm-6 col-4">
                        <p class="text-regular-bold lg-d-none mb-5">Date</p>
                        <p class="txt-regular">{{data.date}}</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-8">
                        <p class="text-regular-bold lg-d-none mb-5">Description</p>
                        <p class="txt-regular">{{data.description}}</p>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-4">
                        <p class="text-regular-bold lg-d-none mb-5">Invoice total</p>
                        <p class="txt-regular mt-5">$ {{data.amount_paid}}</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-8">
                        <p class="text-regular-bold lg-d-none mb-5">Status</p>
                        <p class="txt-regular">{{data.status}}</p>
                        <p (click)="jobInvoice(data.invoice_id)" class="text_medium fit-content clr-cc-vilot c-p mt-2">
                            View Invoice</p>
                    </div>

                </div>
            </div>
        </div>
    </span>
    <span *ngIf="(paymentHistory?.length == 0 || paymentHistory == undefined) && fakePage == false">
        <h4 class="text-center mt-50">You have not made any purchases on ClassCover Jobs.</h4>
        <p class="text-center mt-20" *ngIf="this.schoolProps.properties.booking_school == false"><button routerLink="/school/job-subscription"
                class="btn cc_p_btn  pl-30 pr-30 text-regular-bold-w">Upgrade your account</button></p>
    </span>
</div>