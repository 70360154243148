import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-html-components',
    templateUrl: './html-components.component.html',
    styleUrls: ['./html-components.component.scss'],
    
    standalone: true,
    imports: [FormsModule]
})
export class HtmlComponentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
