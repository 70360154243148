import { Component, Input, OnInit } from '@angular/core';
import { BookingService } from 'src/app/services/api/booking.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-invite-request-tab',
  templateUrl: './invite-request-tab.component.html',
  styleUrls: ['./invite-request-tab.component.scss']
})
export class InviteRequestTabComponent implements OnInit {
  @Input() tabData: any;
  pendingInvites: any;
  pendingRequest: any;

  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.bookingService.getInviteRequest().subscribe({
      next: (res: any) => {
        this.pendingInvites = res.data.pending_invite
        this.pendingRequest = res.data.pending_request
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

}
