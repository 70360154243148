import { Component, Input, OnInit, Injector, ElementRef } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, NavigationEnd, Route } from '@angular/router';
import { SharedService } from '../services/api/message.service';
import { Subscription, fromEvent, Observable, } from 'rxjs';
import { SchoolData } from 'src/app/models/schoolUser';
import { Intercom } from 'ng-intercom';
import { MixpanelService } from 'src/app/mixpanel.service';

interface toggleSidenav {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {
  sub!: Subscription;
  title: any;
  hideNavOnJobs: boolean = false;
  public schoolProps!: SchoolData;
  viewAsProducer: any;
  windowLocation: any;
  checkToken: any;
  routeDetect: Boolean = false;

  constructor(
    private injector: Injector,
    public activatedRoute: ActivatedRoute,
    private _router: Router,
    private sharedService: SharedService,
    private el: ElementRef,
    public intercom: Intercom,
    private mixpanelService: MixpanelService,
  ) {
  }
  ngOnInit(): void {

    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.routerChangeMethod(event.url);
      }
    })
  }


  @Input() collapsed = true;
  @Input() screenWidth = 0;

  getBodyClass(): string {
    let currentUrl = this._router.url.split('?');
    this.checkToken = document.cookie.match('(^|;)\\s*' + 'access_token' + '\\s*=\\s*([^;]+)')?.pop() || ''
    this.activatedRoute.params.subscribe(params => {
      this.viewAsProducer = params['slugId'];
    });
    this.windowLocation = window.location.href.split('/')
    const url = this._router.url;
    let styleClass = '';
    if (
      (url === '/') ||
      (url === '/login') ||
      (url.match(/\/login.*/)) ||
      (url.match(/\/signup.*/)) ||
      (url.match(/\/public-profile.*/)) ||
      (url === '/signup') ||
      (url === '/public-profile') ||
      (url === '/school-onboarding') ||
      (url === '/school-onboarding-findNew')||
      (url === '/onboarding-profile')||
      (url === '/onboarding-completed')
    ) {
      styleClass = ''
    }
    if ((url.match('/school'))) {
      styleClass = 'body'
    }

    if (currentUrl[0] == '/school/job-details') {
      styleClass = 'body-jobs';
    } else if (currentUrl[0] == '/school/post-job') {
      styleClass = 'body-jobs';
    } else if (currentUrl[0] == '/school/termsAndCondition') {
      styleClass = 'body-jobs';
    } else if (currentUrl[0] == '/school/school-onboarding') {
      styleClass = 'body-jobs';
    } else if (currentUrl[0] == '/school/school-onboarding-findNew') {
      styleClass = 'body-jobs';
    } else if (currentUrl[0] == '/school/onboarding-profile') {
      styleClass = 'body-jobs';
    }else if (currentUrl[0] == '/school/onboarding-completed') {
      styleClass = 'body-jobs';
    } else {
      if (this.collapsed && this.screenWidth > 996) {
        styleClass = 'body-trimmed';
      } else if (this.screenWidth <= 996 && this.screenWidth > 0) {
        styleClass = 'body-md-screen'
      }
    }
    return styleClass;
  }

  routerChangeMethod(url: any) {
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    let styleClass = ''
    if (
      (url === '/') ||
      (url === '/login') ||
      (url.match(/\/login.*/)) ||
      (url.match(/\/signup.*/)) ||
      (url.match(/\/public-profile.*/)) ||
      (url === '/signup') ||
      (url === '/public-profile') ||
      (url === '/school-onboarding') ||
      (url === '/school-onboarding-findNew') ||
      (url === '/onboarding-profile') ||
      (url === '/onboarding-completed')
    ) {
      styleClass = ''
    }
    if ((url.match('/school'))) {
      styleClass = 'body'
    }

    this.title = url.split('?');
    if (this.title[0] == '/school/job-details') {
      styleClass = 'body-jobs';
    } else if (this.title[0] == '/school/post-job') {
      styleClass = 'body-jobs';
    } else if (this.title[0] == '/school/termsAndCondition') {
      styleClass = 'body-jobs';
    } else if (this.title[0] == '/school/school-onboarding') {
      styleClass = 'body-jobs';
    } else if (this.title[0] == '/school/school-onboarding-findNew') {
      styleClass = 'body-jobs';
    }  else if (this.title[0] == '/school/onboarding-profile') {
      styleClass = 'body-jobs';
    }  else if (this.title[0] == '/school/onboarding-completed') {
      styleClass = 'body-jobs'; 
    }  else {
      if (this.collapsed && this.screenWidth > 996) {
        styleClass = 'body-trimmed';
      } else if (this.screenWidth <= 996 && this.screenWidth > 0) {
        styleClass = 'body-md-screen'
      }
    }
    return styleClass;
  }

  ngAfterContentInit() {
    this.sub = this.sharedService.navData.subscribe(
      data => {
        this.hideNavOnJobs = data
      }
    )
  }
}
