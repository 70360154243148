import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationStart, RouterLink, RouterLinkActive } from '@angular/router';
import { environment } from 'src/environments/environment';
// Services
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
// models
import { SchoolData } from 'src/app/models/schoolUser';
import { CommonService } from 'src/app/services/api/common.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../../services/api/message.service';
import { Intercom } from '@supy-io/ngx-intercom';
import {MixpanelService} from 'src/app/mixpanel.service';
import { SwichBackToPythonComponent } from '../swich-back-to-python/swich-back-to-python.component';
import { NgClass, NgIf } from '@angular/common';
declare var $: any;
@Component({
    selector: 'app-producer-header',
    templateUrl: './producer-header.component.html',
    styleUrls: ['./producer-header.component.scss'],
    
    standalone: true,
    imports: [NgClass, NgIf, RouterLink, RouterLinkActive, SwichBackToPythonComponent]
})
export class ProducerHeaderComponent implements OnInit {

  public schoolProps!: SchoolData;
  plan: any;
  title: any;
  sub!: Subscription
  languages = []
  schoolLogo: any;
  current_plan: any;
  activateScript: boolean = false;
  from_page_cookie: any;
  header: any;
  hideHeader: any;
  currentUrl: any;
  constructor(
    private authService: AuthenticationService,
    @Inject(Router) private router: Router,
    private commonService: CommonService,
    private sharedService: SharedService,
    public intercom: Intercom,
    private mixpanelService: MixpanelService,
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.routerChangeMethod(event.url);
      }
    })

    if( window.location.hostname == 'booking.classcover.com.au'){
      this.activateScript = true
    }
    this.from_page_cookie = document.cookie.match('(^|;)\\s*' + 'from' + '\\s*=\\s*([^;]+)')?.pop() || '';
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.schoolLogo = localStorage.getItem('schoolLogo');
    if(localStorage.getItem('current_plan') == '' || localStorage.getItem('current_plan') == null){
      this.plan = '';
    }else{
      this.plan = localStorage.getItem('current_plan') + ' plan';
    }
    this.mixpanelService.init(this.schoolProps.mixpanel_data.user_id.toString(),this.schoolProps.mixpanel_data.mixpanel_app_id)
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.routerChangeMethod(event.url);
      }
    })
    if(this.schoolProps.properties.booking_school && !this.schoolProps.properties.job_only_school){
      $('head').append('<script src="//embed.typeform.com/next/embed.js"></script><div data-tf-live="01J1Y5S4E6D3S23C76ST2V29VP"></div>');
    }
    this.currentUrl = this.router.url.split('?');

    if(this.currentUrl[0] == '/school/onboarding-classcover-overview'){
      this.hideHeader = true
    }else if(this.currentUrl[0] == '/school/school-onboarding'){
      this.hideHeader = true
    }else if(this.currentUrl[0] == '/school/school-onboarding-findNew'){
      this.hideHeader = true
    }else if(this.currentUrl[0] == '/school/onboarding-profile'){
      this.hideHeader = true
    }else{
      this.hideHeader = false
    }

    // window.onload = () => {
      this.intercomValues();
    // };

   
   
    this.mixpanelService.schoolUserLoad(this.schoolProps)
  }
  intercomValues(){
    this.intercom.boot({
      app_id: this.schoolProps.mixpanel_data.app_id.toString(),
      name: this.schoolProps.mixpanel_data.name,
      user_id: this.schoolProps.mixpanel_data.user_id.toString(),
      user_hash: this.schoolProps.mixpanel_data.user_hash,
      school_name: this.schoolProps.mixpanel_data.school_name, // Full name
      group_name: this.schoolProps.mixpanel_data.group_name,
      email: this.schoolProps.mixpanel_data.email,// Email address
      created_at:this.schoolProps.mixpanel_data.created_at, // Signup date as a Unix timestamp,
      user_type: this.schoolProps.mixpanel_data.school_type,
      state: this.schoolProps.mixpanel_data.state,
      city: this.schoolProps.mixpanel_data.city,
      postal_code: this.schoolProps.mixpanel_data.postal_code,
      enabled: this.schoolProps.mixpanel_data.enabled,
      school_id: this.schoolProps.mixpanel_data.school_id,
      company : {        
        id: this.schoolProps.mixpanel_data.school_id,
        name: this.schoolProps.mixpanel_data.school_name,
        subscription_type: this.schoolProps.mixpanel_data.subscription_type,
        school_level: this.schoolProps.mixpanel_data.school_level,
        school_type:this.schoolProps.mixpanel_data.school_type,
        low_year: this.schoolProps.mixpanel_data.low_year,
        high_year: this.schoolProps.mixpanel_data.high_year,
        enrolment_size: this.schoolProps.mixpanel_data.enrolment_size,
        school_summary: this.schoolProps.mixpanel_data.school_summary,
        created_at: this.schoolProps.mixpanel_data.created_at
    },
    school_signup_date: this.schoolProps.mixpanel_data.school_signup_date,
    customer_id: this.schoolProps.mixpanel_data.user_id,
    school_user_role: this.schoolProps.mixpanel_data.school_user_role,
    school_booking_request: this.schoolProps.mixpanel_data.school_booking_request,
    school_manual_bookings: this.schoolProps.mixpanel_data.school_booking_request,
    school_teacher_count: this.schoolProps.mixpanel_data.school_teacher_count,       
    } as any);
  }

  ngAfterContentInit() {
    this.sub = this.sharedService.send_data.subscribe(
      data => {
        this.plan = data
      }
    )
    this.sub = this.sharedService.school_logo.subscribe(
      data => {
        this.schoolLogo = data;
      }
    )
    this.header = this.sharedService.header.subscribe(
      data => {
        this.hideHeader = data
      }
    )
     this.current_plan = this.sharedService.current_plan.subscribe(
      data => {
        localStorage.setItem('current_plan', data);
        if(localStorage.getItem('current_plan') == '' || localStorage.getItem('current_plan') == null){
          this.plan = '';
        }else{
          this.plan = localStorage.getItem('current_plan') + ' plan';
        }
      }
    )

    
  }
 
  routerChangeMethod(url: any) {
    if( window.location.hostname == 'booking.classcover.com.au'){
      this.activateScript = true
    }
    this.mixpanelService.init(this.schoolProps.mixpanel_data.user_id.toString(),this.schoolProps.mixpanel_data.mixpanel_app_id)

    this.currentUrl = url.split('?');
    
    if(this.currentUrl[0] == '/school/onboarding-classcover-overview'){
      this.hideHeader = true
    }else if(this.currentUrl[0] == '/school/school-onboarding'){
      this.hideHeader = true
    }else if(this.currentUrl[0] == '/school/school-onboarding-findNew'){
      this.hideHeader = true
    }else if(this.currentUrl[0] == '/school/onboarding-profile'){
      this.hideHeader = true
    }else{
      this.hideHeader = false
    }
  }
  logout() {
    if(localStorage.getItem('bookingSchool') == "true"){
      this.authService.loggedOut();
      if (this.router) {
        this.router.ngOnDestroy();
      }
    }else{
      this.authService.loggedOut();
      if (this.router) {
        this.router.ngOnDestroy();
      }
    }
   
  }
  backToAdmin(){
    this.authService.loggedOut();
    if (this.router) {
      this.router.ngOnDestroy();
    }
      window.open(environment.backToAdmin , "_self")
  }
  backToCoOrdinator(){
    this.authService.loggedOut();
    if (this.router) {
      this.router.ngOnDestroy();
    }
      window.open(environment.backToCoOrdinator , "_self")
  }
}
