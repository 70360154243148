<form [formGroup]="sendMessageToStaff"  [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'">
    <div class="modal-header">
        <h4 class="modal-title" id="addEdit">Send Message</h4>
        <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
    </div>

    <div class="modal-body pb-0">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div *ngIf="sendType == 'single'">
                    <label for="exampleFormControlTextarea1" class="form-label user-title-m">Send message to
                        {{teacher.user.full_name}}
                        *</label>
                </div>
                <div *ngIf="sendType == 'multiple'">
                    <label for="exampleFormControlTextarea1" class="form-label user-title-m">Send message to staff*
                        </label>
                </div>
                <textarea class="form-control mt-5" (keyup)="checkLength()" maxlength=280  formControlName="email_content" placeholder="Add Message here..."
                    id="exampleFormControlTextarea1" rows="6"></textarea>
                    <p class="txt-regular mt-10 mb-0">Max ({{this.ShowLenght}} characters)</p>
                <div *ngIf="submitted">
                    <span class="show-errors" *ngIf="f['email_content'].errors?.['required'] || f['email_content'].errors?.['whitespace']">* Message is
                        required</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer mt-10">
        <button type="button" (click)="sendMessage()" class="btn cc_p_btn fs_13">Send</button>
    </div>
</form>