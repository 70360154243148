import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(
    private httpClient: HttpClient,
    private apiUrl: AppConfig,
  ) { }

  public getBookingList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getBookingList, { params: filterData });
  }
  public getBookingHistoryList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getBookingList, { params: filterData });
  }
  public getTeachersList = (filterData: any): Observable<any> => {
    // let params = new HttpParams();
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getBookingList, { params: filterData });
  }

  public sendBookingRequest = (requestData: any): Observable<any> => {
    // let params = new HttpParams();
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.bookingRequest, requestData);
  }

  public updateBookingRequest = (requestData: any): Observable<any> => {
    // let params = new HttpParams();
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.updateBookingRequest, requestData);
  }
  public reSendInvite = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.reSendInvite, requestData);
  }
  public cancelInvite = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.cancelInvite, requestData);
  }
  public editAdminSettings = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.profilePageDetails, requestData);
  }
  public saveChangesAdminSettings = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.saveChangesAdminSettings, requestData);
  }
  public addAdditionalUserApi = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.addAdditionalUserApi, requestData);
  }
  public saveChangesSchoolSettings = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.saveChangesAdminSettings, requestData);
  }
  public manageTeamSettings = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.manageTeamSettings, requestData);
  }
  public jobAccessApi = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.manageTeamSettings, requestData);
  }
  public addNewListBulk = (requestData: any): Observable<any> => {
    let testData:FormData = new FormData();
    testData.append('bulk_upload', requestData, 'test data');
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.addNewList, testData);
  }
  public addNewListSingle = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.addNewList, requestData);
  }
  public profileSaveChanges = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.profileSaveChanges, requestData);
  }
  public sendMailInvite = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.sendMailInvite, requestData);
  }


  public getPreconfirmedList = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.preConfirmed, { params: requestData });
  }
  public getPreconfrimFilterList = (filterData: any): Observable<any> => {
    // let params = new HttpParams();
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.preConfirmed, { params: filterData });
  }
  public getReviewOccurrrence = (OccurrrenceData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.preConfirmedReviewOccurence, { params: OccurrrenceData });
  }

  public cancelBookingRequest = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.cancelBookingRequest, requestData);
  }
  public editBookingRequest = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.editBookingRequest, requestData);
  }

  public getRequestHistory = (pageCount: number): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getRequestHistory + '?page=' + pageCount);
  }
  public getRequestFilterHistory = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getRequestHistory, { params: filterData });
  }
  public getInFlightData = (id: any, pagetype: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getInFlightData + '?id=' + id + '&request_page_type=' + pagetype);
  }
  public getFindNewTeacher = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.findNewTeacher, { params: filterData });
  }

  public getFindNewFilterTeacher = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.findNewTeacher, { params: filterData });
  }

  public stopBookingRequest = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.stopBookingRequest, requestData);
  }
  public getPendingInviteList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getPendingInviteList, { params: filterData });
  }
  public getPendingInviteTeacherList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getPendingInviteList, { params: filterData });
  }
  public getInvitePendingList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getInvitePendingList, { params: filterData });
  }
  public getInviteHistoryList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getInviteHistoryList, { params: filterData });
  }
  public listHealth = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.listHealth,{ params: filterData });
  }
  public pendingRequestList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.pendingRequestList, { params: filterData });
  }
  public getPendingrequestTeacherList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.pendingRequestList, { params: filterData });
  }
  public pendingRequestPendingList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.pendingRequestPendingList, { params: filterData });
  }
  public pendingRequestHistoryList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.pendingRequestHistoryList, { params: filterData });
  }
  public pendingRequestBlockedList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.pendingRequestBlockedList, { params: filterData });
  }
  public getMyList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getMyList, { params: filterData });
  }
  public requestAction = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.requestAction, requestData);
  }
  public getMyFilterList = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getMyList, { params: filterData });
  }
  public myListAction = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.myListAction, requestData);
  }

  public getJobs = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getJobs, { params: filterData });
  }
  public getSideComponentJobListing = (): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getJobsListingSideCom);
  }

  public callBackPaymentFunction = (paymentID:any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.callBackPaymentFunctionAPI + '?id=' + paymentID);
  }

  public getFilterJobs = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getJobs, { params: filterData });
  }
  public boostJobApi = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.boostJob + '?job_id=' + filterData);
  }

  public getJobDetails = (job_id: number): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getJobDetails + '?job_id=' + job_id);
  }
  public getJobFilterDetails = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getJobDetails, { params: filterData });
  }

  public jobDetailsAction = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.jobDetailsAction, requestData);
  }

  public postJobsAPi = (requestData: any): Observable<any> => {
    // let params = new HttpParams();
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.postJobsApi, requestData);
  }

  public updateJobsAPi = (requestData: any, job_id: any): Observable<any> => {
    // let params = new HttpParams();
    return this.httpClient.put<any>(this.apiUrl.schoolApiUrl.updateJobsAPi + job_id + '/', requestData);
  }


  public editJobsAPi = (job_id: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.editJobsApi + '?job_id=' + job_id);
  }

  public jobMakeLive = (requestData: any): Observable<any> => {
    // let params = new HttpParams();
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.jobMakeLive, requestData);
  }
  public jobSubsUpdate = (requestData: any): Observable<any> => {
    // let params = new HttpParams();
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.jobSubsUpdate, requestData);
  }

  public jobPaymentHistory = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.jobPaymentHistory, { params: filterData });
  }

  public jobPaymentHistoryInvoice = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.jobPaymentHistory + '?invoice_id=' + filterData);
  }


  public cancelJobsAPi = (id: any): Observable<any> => {
    // let params = new HttpParams();
    return this.httpClient.delete<any>(this.apiUrl.schoolApiUrl.postJobsApi + id + '/');
  }
  
  public addNotesApi = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.addNotesApi, requestData);
  }
  public addTeacherToList = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.addTeacherToList, requestData);
  }
  public CreateSmartGroup = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.createSmart, requestData);
  }
  public ProviderRatings = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.ProviderRatings, requestData);
  }

  public profilePageDetails = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.profilePageDetails, requestData);
  }

  public editProfileData = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.editProfileData, requestData);
  }
  public sendReceiveRequestAPI = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.sendReceiveRequestAPI, requestData);
  }

  public profilePageChanges = (country: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.profilePageChanges + country);
  }
  public profilePageTimeZoneChanges = (country: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.profilePageChanges + country);
  }
  public calendarbookingDetails = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.calendarbookings, { params: filterData });
  }
  public bulkEditData = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.bulkEditData, { params: filterData });
  }
  public updateBulkDataApi = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.updateBulkDataApi, requestData);
  }

  public exportBookingsAPI = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.exportBookingsAPI, {params: filterData});
  }
  public exportPayrollAPI = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.exportPayrollAPI, {params: filterData});
  }

  public sendInviteTeachers = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.sendInvite, requestData);
  }
  public getSmartGroups = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getSmartGroups, { params: filterData });
  }
  public getSmartGroupsList = (group_id: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getSmartGroupsList + group_id + '/');
  }
  public getmyListSmartGroupsList = (group_id: any, requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getSmartGroupsList + group_id + '/', { params: requestData });
  }
  public smartGroupsActions = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.smartGroupsActions, requestData);
  }
  public sendNudgeApi = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.sendNudgeApi, requestData);
  }
  public exportTeachers = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.exportTeachers, { params: requestData });
  }
  public addToSmartGroup = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.addToSmartGroup, requestData);
  }
  public getAvailability = (teacher_id: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getAvailability + '?teacher_id=' + teacher_id);
  }
  public checkAvalability = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.checkAvalability, requestData);
  }

  public getInviteRequest = (): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getInviteRequest);
  }

  public chargebee = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.chargebee, { params: requestData });
  }

  public editSmartGroup = (id: any, requestData: any): Observable<any> => {
    return this.httpClient.put<any>(this.apiUrl.schoolApiUrl.editSmartGroup + id + '/', requestData);
  }
  public register = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.register, requestData);
  }
  public getSchoolAccess = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.getSchoolAccessApi, requestData);
  }

  public getHeadHunter = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.headHunter, { params: requestData });
  }

  public inviteJob = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.inviteJobApi, requestData);
  }
  public closeSubsAPI = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.closeSubsAPI, {action : requestData});
  }

  public getHubSpokeDataAPI = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.getHubSpokeDataAPI, { params: requestData });
  }
  public acceptDecHubSpokeAPI = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.getHubSpokeDataAPI, requestData);
  }

  public insightReportPageAPI = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.insightReportPageAPI,  { params: requestData });
  }

  public teachersListReportPageAPI = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.teachersListReportPageAPI,  { params: requestData });
  }
  
  public absenceListGraphReportPageAPI = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.absenceListGraphReportPageAPI,  { params: requestData });
  }
  public absenceGraphReportPageAPI = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.absenceGraphReportPageAPI,  { params: requestData });
  }
  public teachersGraphReportPageAPI = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.teachersGraphReportPageAPI,  { params: requestData });
  }
  public jobTermsConditionAPI = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.jobTermsConditionAPI, requestData);
  }
  public nextPrevFortAPI = (requestData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.nextPrevFortAPI,  { params: requestData });
  }

  public checkBookingStatus = (id: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.checkBookingStatus, id);
  }
  
}
