<div class="row">
	<div class="col-lg-12">
		<button (click)="openaddAdditionalUser()" type="button"
			class="btn cc_p_btn fs_13 float-right" id="invite-team">Add classroom teacher</button>
	</div>
</div>
<div class="history-header sticky  px-15 md-d-none mt-20">
	<div class="row align-items-center">
		<div class="col-lg-3">
			<p class="txt-regular">Name</p>
		</div>
		<div class="col-lg-2">
			<p class="txt-regular">Email</p>
		</div>
		<div class="col-lg-2">
			<p class="txt-regular">Phone Number</p>
		</div>
		<div class="col-lg-3">
			<p class="txt-regular">Smart Groups</p>
		</div>
		<div class="col-lg-2">
			<p class="text-right"><button class="text-regular-bold btn c-p clr-cc-vilot fit-content"
					[ngClass]="checkBoxSelectedTeacher?.length == 0 ? 'input-cursor-na' : ''"
					(click)="openDeleteAdditionalUser()"><i class="bi bi-trash-fill mr-2"></i>Remove Selected</button>
			</p>
		</div>
	</div>
</div>
<div class="history-description">
	<div class="text-right lg-d-none">
		<p class="text-regular-bold c-p clr-cc-vilot ml-10 fit-content" (click)="openDeleteAdditionalUser()"><i
				class="bi bi-trash-fill mr-2"></i>Remove Selected</p>
	</div>
	<div class="card px-15 mt-10" *ngFor="let staff of staffList; index as i">
		<div class="row align-items-center">
			<div class="col-lg-3 col-md-6 col-sm-6 col-12">
				<p class="text-regular-bold lg-d-none mb-5">Name</p>
				<div class="d-flex">
					<input #checkboxes (click)="setCheckbox(staff, i)" [checked]="staff?.isChecked" [disabled]="
				staff.hideme
				" class="form-check-input mr-10" type="checkbox" value="" id="flexCheckDefault" />
					<p class="txt-regular">
						{{staff?.full_name}}
					</p>
				</div>
			</div>
			<div class="col-lg-2 col-md-6 col-sm-6 col-12">
				<p class="text-regular-bold lg-d-none mb-5">Email</p>
				<p class="txt-regular">{{staff?.email}}</p>
			</div>
			<div class="col-lg-2 col-md-6 col-sm-6 col-5">
				<p class="text-regular-bold lg-d-none mb-5">Phone Number</p>
				<p class="txt-regular">{{staff?.phone}}</p>
			</div>
			<div class="col-lg-3 col-md-4 col-sm-4 col-5">
				<p class="text-regular-bold lg-d-none mb-5">Smart Groups</p>
				<p class="txt-regular">
					<span *ngFor="let group of staff?.smart_group?.smartgroup; let isLast=last">
						{{group?.name}}{{isLast ? '' : ', '}}
					</span>
				</p>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-2 col-2">
				<p class="text-regular-bold lg-d-none mb-5"></p>
				<p class="text-regular-bold clr-cc-vilot text-right c-p h_b" (click)="openEditAdditionalUser(staff)">
					Edit
				</p>
			</div>
		</div>
	</div>
</div>
<ng-template #editAdditionalUser>
	<div class="modal-header">
		<h4 class="modal-title" id="exampleModalLabel">{{modalTitle}}</h4>
		<button type="button" (click)="this.bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
	</div>
	<form [formGroup]="additionalUserForm" #additionalUser="ngForm">
		<div class="modal-body pb-0">
			<div class="row align-items-center">
				<div class="col-md-6 mt-10">
					<label>First name *</label>
					<input class="form-control" placeholder="First name" formControlName="first_name">
					<div *ngIf="additionalUser.submitted">
						<span class="show-errors"
							*ngIf="additionalUserForm.controls['first_name'].errors?.['required'] || additionalUserForm.controls['first_name'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
				<div class="col-md-6 mt-10">
					<label>Last name *</label>
					<input class="form-control" placeholder="Last name" formControlName="last_name">
					<div *ngIf="additionalUser.submitted">
						<span class="show-errors"
							*ngIf="additionalUserForm.controls['last_name'].errors?.['required'] || additionalUserForm.controls['last_name'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
				<div class="col-md-6 mt-10">
					<label>Email *</label>
					<input class="form-control" placeholder="Email" formControlName="email">
					<div *ngIf="additionalUser.submitted">
						<span class="show-errors"
							*ngIf="additionalUserForm.controls['email'].errors?.['required'] || additionalUserForm.controls['email'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
				<div class="col-md-6 mt-10">
					<label>Phone Number</label>
					<input class="form-control" placeholder="Phone number" formControlName="phone">
				</div>
				<div class="col-md-12 mt-10">
					<label>Assign this smart group to your classroom teachers</label>
					<ng-select formControlName="smart_group_ids" [addTag]="false" placeholder="Type here"
						[items]="smartGroupList" bindLabel="name" bindValue="id" labelForId="state" [multiple]="true"
						clearAllText="Clear">
					</ng-select>
				</div>
			</div>
			<div class="col-md-12 mt-20 text-right">
				<button *ngIf='modalTitle != "Add classroom teacher"' type="submit" (click)="updateAdditionalUser()"
					class="btn cc_p_btn">
					Update
				</button>
				<button *ngIf='modalTitle == "Add classroom teacher"' type="submit" (click)="saveAdditionalUser()"
					class="btn cc_p_btn">
					Save
				</button>
			</div>
		</div>
	</form>
</ng-template>
<!-- Edit profile RemoveAnnounceConfirmation model -->
<ng-template #RemovestaffConfirmation>
	<div class="modal-header">
		<h4 class="model_headings" id="editProfileInformationModal">
			Confirmation
		</h4>
		<button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
	</div>
	<div class="modal-body pb-0">
		<p>Are you sure you want to remove the following staffs from this classroom teachers list?</p>
		<ol class="mt-15">
			<li class="text-regular-bold" *ngFor="let removedStaff of checkBoxSelectedTeacher;">
				{{removedStaff?.full_name}}</li>
		</ol>
	</div>
	<div class="modal-footer">
		<button type="submit" (click)="deleteAdditionalUser()" class="btn cc_p_btn">
			Remove
		</button>
	</div>
</ng-template>