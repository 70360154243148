<div class=" pb-30 pt-15 jobs-page" *ngIf="this.schoolProps.properties.job_only_school == false">
    <div class="row">
        <!-- Pending Request Tabs -->
        <div class="col-lg-9 col-md-8 col-12  mb-20">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-12">
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link" [ngClass]="{ 'active':activeTab==='activeJobs'}"
                                (click)="activeJobs('activeJobs')" id="nav-invite-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-invite" type="button" role="tab" aria-controls="nav-invite"
                                aria-selected="true">Active</button>
                            <!-- <button class="nav-link" [ngClass]="{ 'active':activeTab==='expiredJobs'}"
                                (click)="expiredJobs('expiredJobs')" id="nav-history-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-history" type="button" role="tab" aria-controls="nav-history"
                                aria-selected="false">Expired</button> -->
                            <button class="nav-link" [ngClass]="{ 'active':activeTab==='closed'}"
                                (click)="ClosedJobs('closed')" id="nav-closed-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-closed" type="button" role="tab" aria-controls="nav-closed"
                                aria-selected="false">Closed</button>
                            <button class="nav-link" [ngClass]="{ 'active':activeTab==='draft'}"
                                (click)="DraftJobs('draft')" id="nav-draft-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-draft" type="button" role="tab" aria-controls="nav-draft"
                                aria-selected="false">Draft</button>
                        </div>
                    </nav>
                </div>
                <div class="col-lg-6 col-md-6 col-12" *ngIf="!this.schoolProps.properties.job_only_school">
                    <div class="d-flex align-items-center place-right">
                        <p class="txt-regular mr-10">Do you want to grow your list?</p>
                        <button class="btn cc_p_btn fs_13" routerLink="/school/post-job" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Post a job</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-12 mb-20">
                <div class="tab-content" id="nav-tabContent">
                    <!-- Pending Tab Content -->
                    <div class="tab-pane fade show" [ngClass]="{ 'active':activeTab==='activeJobs'}" id="nav-invite"
                        role="tabpanel" aria-labelledby="nav-invite-tab">
                        <div *ngIf="activeJob?.length > 0">
                            <div class="card mt-10 px-15 c-p" *ngFor="let jobs of activeJob"
                                (click)="sendJobId(jobs.id)">
                                <div class="row align-items-center">
                                    <div class="col-lg-9 col-md-8 col-sm-8 col-12">
                                        <div class="col-md-12 col-lg-12 d-flex">
                                            <div class="pic-and-check ml-0">
                                                <img alt="img"
                                                    [src]="jobs.school.additional_info.profile_image != null ? jobs.school.additional_info.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                    class="profile-picture img-complete-match">
                                            </div>
                                            <div class="pic-and-check ml-10">
                                                <div class="d-flex flex-wrap">
                                                    <div class="user-title c-p">{{jobs.job_title}}</div>
                                                    <!-- <div class="type-badge user-sub-title">{{jobs.job_type}}</div> -->
                                                    <div *ngIf="jobs.is_boost_job" class="job-boosted">Boosted</div>
                                                </div>
                                                <p class="user-sub-title mt-2">
                                                    {{jobs.school.title}}
                                                    <span class="divider-right ml-3 mr-5"></span>
                                                    {{jobs.school_level}}
                                                </p>
                                                <p class="user-sub-title mt-2">
                                                    {{jobs.externalclick_view_application_count.application_count}}
                                                    Applicant
                                                    <span class="divider-right ml-3 mr-5"></span>
                                                    {{jobs.externalclick_view_application_count.views_count}} Views
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-4 col-md-4 d-flex place-content-end">
                                        <span>
                                            <div  *ngIf="jobs.expiring_soon" class="accept-decline mb-2">
                                                <div class="d-flex txt-regular c-p">
                                                    <!-- <i class="bi bi-people-fill fs-13 mr-4"></i> -->
                                                    <p class="clr-orange"> Expiring soon</p>
                                                </div>
                                            </div>

                                            <div class="reason-for-decline">
                                                <div class="d-flex text-regular-bold  clr-cc-vilot  align-items-center c-p"
                                                    (click)="sendJobId(jobs.id)">
                                                    <p class="" *ngIf="jobs.externalclick_view_application_count.application_count == 0 || jobs.externalclick_view_application_count.application_count == 1">View applicant</p>
                                                    <p class="" *ngIf="jobs.externalclick_view_application_count.application_count > 1">View applicants</p>
                                                </div>
                                            </div>

                                            <div class="days-ago mt-5">
                                                <p class="text-s-m-g-2">Posted {{jobs.time_ago}}</p>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="activeJob?.length == 0" class="text-center mt-50">
                            <h4 class="mb-20">No active jobs found. Post a job to see them over here</h4>
                            <!-- <button type="button" id="send-invite-btn" class="btn cc_p_btn fs_13 btn-sm mt-10">
                            <i class="bi bi-send-fill" style="margin-right: 2px;"></i>
                            Reset Filters</button> -->
                            <div>
                                <p><img *ngIf="this.schoolProps.properties.job_only_school" class="no-result-image"
                                        src="assets/images/jobs/job_only_user/no_job.png"
                                        alt="No matches for the applied filter!" /></p>
                                <p><img *ngIf="!this.schoolProps.properties.job_only_school" class="no-result-image"
                                        src="assets/images/jobs/no_job.png" alt="No matches for the applied filter!" />
                                </p>
                                <button routerLink="/school/post-job"
                                    class="btn cc_p_btn  pl-30 pr-30 text-regular-bold-w mt-30">Post a new job</button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-closed" [ngClass]="{ 'active':activeTab==='closed'}"
                        role="tabpanel" aria-labelledby="nav-closed-tab">
                        <div *ngIf="close_job?.length > 0">
                            <div class="card mt-10 px-15 c-p" *ngFor="let jobs of close_job"
                                (click)="sendJobId(jobs.id)">
                                <div class="row align-items-center">
                                    <div class="col-lg-9 col-md-8 col-11">
                                        <div class="col-md-12 col-lg-12 d-flex">
                                            <div class="pic-and-check ml-0">
                                                <img alt="img"
                                                    [src]="jobs.school.additional_info.profile_image != null ? jobs.school.additional_info.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                    class="profile-picture img-complete-match">
                                            </div>
                                            <div class="pic-and-check ml-10">
                                                <div class="d-flex flex-wrap">
                                                    <div class="user-title c-p">{{jobs.job_title}}</div>
                                                    <!-- <div class="type-badge user-sub-title">{{jobs.job_type}}</div>
                                                    <div class="job-boosted">{{jobs.job_type}}</div> -->
                                                </div>
                                                <p class="user-sub-title mt-2">
                                                    {{jobs.school.title}}
                                                    <span class="divider-right ml-3 mr-5"></span>
                                                    {{jobs.school_level}}
                                                </p>
                                                <p class="user-sub-title mt-2">
                                                    {{jobs.externalclick_view_application_count.application_count}}
                                                    Applicant
                                                    <span class="divider-right ml-3 mr-5"></span>
                                                    {{jobs.externalclick_view_application_count.views_count}} Views
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4  d-flex place-content-end">
                                        <span>
                                            <div class="reason-for-decline">
                                                <div class="d-flex text-regular-bold  clr-cc-vilot  align-items-center c-p"
                                                    (click)="sendJobId(jobs.id)">
                                                    <p class="">Reopen job</p>
                                                </div>
                                            </div>

                                            <div class="days-ago mt-5">
                                                <p class="text-s-m-g-2">Posted {{jobs.time_ago}}</p>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="close_job?.length == 0" class="text-center mt-50">
                            <h4 class="mb-20">No closed jobs. Once a job is no longer live, you will see it here</h4>
                            <!-- <button type="button" id="send-invite-btn" class="btn cc_p_btn fs_13 btn-sm mt-10">
                        <i class="bi bi-send-fill" style="margin-right: 2px;"></i>
                        Post a new job</button> -->
                            <p><img *ngIf="this.schoolProps.properties.job_only_school" class="no-result-image"
                                    src="assets/images/jobs/job_only_user/no_job.png"
                                    alt="No matches for the applied filter!" /></p>
                            <p><img *ngIf="!this.schoolProps.properties.job_only_school" class="no-result-image"
                                    src="assets/images/jobs/no_job.png" alt="No matches for the applied filter!" /></p>
                            <button routerLink="/school/post-job"
                                class="btn cc_p_btn  pl-30 pr-30 text-regular-bold-w mt-30">Post a new job</button>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-draft" [ngClass]="{ 'active':activeTab==='draft'}"
                        role="tabpanel" aria-labelledby="nav-draft-tab">
                        <div *ngIf="draft_jobs?.length > 0">
                            <div class="card mt-10 px-15 c-p" *ngFor="let jobs of draft_jobs"
                                (click)="sendJobId(jobs.id)">
                                <div class="row align-items-center">
                                    <div class="col-lg-9 col-md-8 col-11">
                                        <div class="col-md-12 col-lg-12 d-flex">
                                            <div class="pic-and-check ml-0">
                                                <img alt="img"
                                                    [src]="jobs.school.additional_info.profile_image != null ? jobs.school.additional_info.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                    class="profile-picture img-complete-match">
                                            </div>
                                            <div class="pic-and-check ml-10">
                                                <div class="d-flex flex-wrap">
                                                    <div class="user-title c-p">{{jobs.job_title}}</div>
                                                    <!-- <div class="type-badge user-sub-title">{{jobs.job_type}}</div>
                                                    <div class="job-boosted">{{jobs.job_type}}</div> -->
                                                </div>
                                                <p class="user-sub-title mt-2">
                                                    {{jobs.school.title}}
                                                    <span class="divider-right ml-3 mr-5"></span>
                                                    {{jobs.school_level}}
                                                </p>
                                                <p class="user-sub-title mt-2">
                                                    {{jobs.externalclick_view_application_count.application_count}}
                                                    Applicant
                                                    <span class="divider-right ml-3 mr-5"></span>
                                                    {{jobs.externalclick_view_application_count.views_count}} Views
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4  d-flex place-content-end">
                                        <span>
                                        <div class="reason-for-decline">
                                            <div class="d-flex text-regular-bold  clr-cc-vilot  align-items-center c-p"
                                                (click)="editJob(jobs.id)">
                                                <i class="bi bi-people-fill  fs-19 mr-4"></i>
                                                <p class="">Edit job</p>
                                            </div>
                                        </div>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="draft_jobs?.length == 0" class="text-center mt-50">
                            <h4 class="mb-20">No draft jobs found.</h4>
                            <!-- <button type="button" id="send-invite-btn" class="btn cc_p_btn fs_13 btn-sm mt-10">
                    <i class="bi bi-send-fill" style="margin-right: 2px;"></i>
                    Post a new job</button> -->
                            <p><img *ngIf="this.schoolProps.properties.job_only_school" class="no-result-image"
                                    src="assets/images/jobs/job_only_user/no_job.png"
                                    alt="No matches for the applied filter!" /></p>
                            <p><img *ngIf="!this.schoolProps.properties.job_only_school" class="no-result-image"
                                    src="assets/images/jobs/no_job.png" alt="No matches for the applied filter!" /></p>
                            <button routerLink="/school/post-job"
                                class="btn cc_p_btn  pl-30 pr-30 text-regular-bold-w mt-30">Post a new job</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3  col-md-4  col-12 mb-20 side_c">
            <div *ngIf="this.schoolProps.properties.job_only_school">
                <button routerLink="/school/post-job" class="btn cc_p_btn text-regular-bold-w">Post a new job</button>
                <div class="card px-15 mt-15">
                    <h4>Total job ad credits</h4>
                    <p class="txt-regular">Want to post more jobs? Buy more ad credits or upgrade to the Premium Plan to
                        post unlimited jobs.</p>
                    <div class="d-flex align-items-center mt-10">
                        <h3 class="mb-0 mr-7 o_t">{{job_ad_credit}}</h3>
                        <p class="u_t_r">Job ad credit remaining</p>
                    </div>
                    <p class="c-p clr-cc-vilot user-title mt-10">Buy more ad credits <i
                            class="bi bi-arrow-up-right"></i>
                    </p>
                </div>

                <!-- <div class="card px-15 mt-15">
                    <p class="txt-regular">Are you looking for more teachers to add to your list?</p>
                    <p class="c-p clr-cc-vilot user-title mt-10">Post a callout <i class="bi bi-arrow-up-right"></i></p>
                </div> -->

                <div class="card px-15 mt-15">
                    <h4>Upgrade your account</h4>
                    <p class="txt-regular">Want to post more jobs? Upgrade to the Premium Plan to post unlimited jobs.
                    </p>
                    <p class="c-p clr-cc-vilot user-title mt-10">Upgrade now <i class="bi bi-arrow-up-right"></i></p>
                </div>

                <div class="card px-15 mt-15">
                    <h4>Job Insights</h4>
                    <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-12">
                            <p class="txt_r_g">Active Jobs</p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                            <p class="txt_r_m_g float-right">{{statics?.active_job_count}}</p>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-12">
                            <p class="txt_r_g">Total Job Views</p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                            <p class="txt_r_m_g float-right">{{statics?.views_count}}</p>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-12">
                            <p class="txt_r_g">Total Job Applicants</p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                            <p class="txt_r_m_g float-right">{{statics?.job_applicant_count}}</p>
                        </div>
                    </div>
                </div>

                <div class="card px-15 mt-15">
                    <h4>Profile Completion</h4>
                    <p class="u_r_s_g2 l_h_12">Upload a logo to get to 100%</p>
                    <div class="d-flex align-items-center mt-10">
                        <progressbar [value]="profilePercent" type="danger" class=""></progressbar>
                        <p class="u_r_s_g2 l_h_12 ml-10">{{profilePercent}}%</p>
                    </div>
                    <div class="d-flex align-items-center mt-10">
                        <h3 class="mb-0 mr-10"><i class="bi bi-lightbulb"></i></h3>
                        <p class="u_r_s_g2 l_h_12">Profiles at 100% completion get their jobs ads featured in their
                            location.</p>
                    </div>
                    <p routerLink="/school/school-profile" class="c-p clr-cc-vilot user-title mt-10">Complete profile
                        <i class="bi bi-arrow-up-right"></i>
                    </p>
                </div>
                <div class="card mt-20 px-15">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4>Other jobs posted</h4>
                            <div *ngIf="other_job?.length > 0" class="mt-10">
                                <div class="mt-10" *ngFor="let school of other_job;  index as i">
                                    <div class="col-md-12 col-lg-12 d-flex invite-request-preview">
                                        <div class="pic-and-check ml-0">
                                            <img alt="avatar"
                                                [src]="school.profile_image != null ? school.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                class="schl-picture img-complete-match">
                                        </div>

                                        <div class="pic-and-check ml-10">
                                            <div class="d-flex">
                                                <p>
                                                    <span class="text-regular-bold c-p">{{school.job_title}}</span>
                                                </p>
                                            </div>
                                            <p class="user-sub-title">
                                                <span>{{school?.school_name}}
                                                </span>
                                                <span class="divider-right ml-3 mr-5">
                                                </span>
                                                {{school?.time_ago}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="other_job?.length == 0">
                                <div class="col-md-12 col-lg-12 mt-20 text-center invite-request-preview">
                                    <p class="text-regular-medium">You don’t have any jobs</p>
                                    <p><img class="no-invite-image"
                                            src="assets/images/NoResultFound/no_data_pending_invites.png"
                                            alt="No invites found!" /></p>

                                </div>
                            </div>
                            <p class="c-p clr-cc-vilot text-center user-title mt-10">View all
                                <i class="bi bi-arrow-up-right"></i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!this.schoolProps.properties.job_only_school">
                <div class="card mt-20 px-15">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="user-title">Job post by other schools</h4>
                            <div *ngIf="other_job?.length > 0" class="mt-10">
                                <div class="mt-10" *ngFor="let school of other_job;  index as i">
                                    <div class="col-md-12 col-lg-12 d-flex invite-request-preview">
                                        <div class="pic-and-check ml-0">
                                            <img alt="avatar"
                                                [src]="school.profile_image != null ? school.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                class="schl-picture img-complete-match">
                                        </div>

                                        <div class="pic-and-check ml-10">
                                            <div class="d-flex">
                                                <p>
                                                    <span class="text-regular-bold c-p">{{school.job_title}}</span>
                                                </p>
                                            </div>
                                            <p class="user-sub-title">
                                                <span>{{school?.school_name}}
                                                </span>
                                                <span class="divider-right ml-3 mr-5">
                                                </span>
                                                {{school?.time_ago}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="other_job?.length == 0">
                                <div class="col-md-12 col-lg-12 mt-20 text-center invite-request-preview">
                                    <p class="text-regular-medium">You don’t have any jobs</p>
                                    <p><img class="no-invite-image"
                                            src="assets/images/NoResultFound/no_data_pending_invites.png"
                                            alt="No invites found!" /></p>

                                </div>
                            </div>
                            <p class="c-p clr-cc-vilot text-center user-title mt-10">View all
                                <i class="bi bi-arrow-up-right"></i>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-lg-12 col-12">
                        <div class="card px-15">
                            <p class="text-s-m-g-2">Are you looking for more teachers to add to your list?</p>
                            <button class="btn cc_p_btn fs_13 mt-10">Post a callout</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>