<section *ngIf="pageLoad">
    <div class="alert alert-warning alert-dismissible fade show redirect-angular" role="alert" *ngIf="fromComp === 'main'">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h1 class="red-ang-info">🚀  Want to switch back to the previous experience? </h1>
            </div>
            <div>
                <span class="text-regular-bold clr-cc-vilot text-center c-p h_b u_l mr-50" (click)="switchBack()">Switch back<i class="bi bi-info-circle-fill ml-6 fs-16" data-bs-toggle="tooltip" data-bs-placement="bottom"  title="Switch back from this new ClassCover experience to the old one"></i></span>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
</section>
<li *ngIf="fromComp === 'header_menu' && pageLoad">
    <a (click)="switchBack()" class="dropdown-item">Switch back to old ClassCover</a>
</li>