import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { last, map, take } from "rxjs/operators";

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { url } from 'inspector';
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-job-payment-history',
  templateUrl: './job-payment-history.component.html',
  styleUrls: ['./job-payment-history.component.scss']
})
export class JobPaymentHistoryComponent implements OnInit {
  data: any
  currentPage: number = 1;
  history: any;
  invoiceLink: any;
  totalCount: any;
  totalItems: any;
  rotate = true;
  maxSize = 5;
  showBoundaryLinks = true;
  filterCheck: any;
  public schoolProps!: SchoolData;
  lengthLimit: any
  totalArrayCount: any;
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  paymentHistoryList: Observable<any> = this.obsArray.asObservable();
  paymentHistory: any;
  paymentHistoryForm: FormGroup = this.fb.group({
    page: ['']
  });
  fakePage: boolean = true;
  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.fakePage = true
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.bookingService.jobPaymentHistory(this.getFormValue()).subscribe({
      next: (res: any) => {
        this.ngListener()
        this.fakePage = false
        this.paymentHistory = res.data.history_data
        this.obsArray.next(res.data.history_data);
        this.lengthLimit = res.data.total_count;
        this.ngxService.stop();
      },
      error: (err: any) => {
        this.fakePage = false
        console.log(err);
      }
    })
  }

  jobInvoice(invoiceId: any) {
    this.ngxService.start();
    this.bookingService.jobPaymentHistoryInvoice(invoiceId).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.invoiceLink = res.invoice_url
          // window.location.href =
          window.open(res.invoice_url, "_self");

          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }
  getFormValue() {
    this.paymentHistoryForm.patchValue({
      page: this.currentPage
    })
    return this.paymentHistoryForm.value
  }
  ngListener() {
      window.addEventListener('scroll', this.scroll, true); //third parameter
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll,true);
  }
  getLength() {
    this.paymentHistoryList.subscribe(result => {
      this.totalArrayCount = result.length;
    });
  }
  paginator(totlallength: any, page: any, per_page: any) {
    var page = page || 1,
      per_page = per_page || 15,
      offset = (page - 1) * per_page,

      // paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }
  scroll = (event: any): void => {
    this.getLength();
    let totalPages = Math.ceil(this.lengthLimit / this.totalArrayCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.paginator(this.totalArrayCount, this.currentPage, 15);
      this.currentPage = activePage + 1;

      if (this.paymentHistoryForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.paymentHistoryList.pipe(take(1)), this.bookingService.jobPaymentHistory(this.getFormValue())]).subscribe((data: any) => {
          this.lengthLimit = data[1].data.total_count;
          const newArr = [...data[0], ...data[1].data.history_data];
          this.obsArray.next(newArr);
          this.paymentHistory = newArr
          this.ngxService.stop();
        });
      }
    }
  };

}
