<div class="modal-header" [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : ''">
    <!-- <h5 class="modal-title" id="exampleModalLabel">Booking Confirmation</h5> -->
    <button type="button" class="btn-close" (click)="bsModalRef.hide()" aria-label="Close"></button>
</div>
<div class="modal-body" [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : ''">

    <div class="row">
        <div class="col-md-9 col-sm-8 col-lg-9">
            <div class="col-md-12 col-lg-12 d-flex align-items-center">
                <div class="pic-and-check ml-0">
                    <img alt="img"
                        [src]="teacher.meta.profile_image != null ? teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                        class="profile-picture img-complete-match">
                </div>

                <div class="pic-and-check ml-10">
                    <div class="d-flex">
                        <p>
                            <span class="user-title">{{teacher?.user.full_name}}</span>
                            <span>
                                <app-validated-dict  [validateDict]="teacher.validated_dict">
                                </app-validated-dict>
                            </span>
                        </p>
                    </div>
                    <p class="user-sub-title">{{teacher.meta.you_are_a}}
                    <p>
                        <span class="grey-3 fs-12">Teacher status</span>
                        <span class="user-sub-title  ml-2" *ngIf="teacher.meta.status"> {{teacher.meta.status}}</span>
                    </p>
                    <p class="user-sub-title">
                        <span *ngIf="teacher.location != ''"><i class="bi bi-geo-alt clr-grey-7"></i>
                            {{teacher.location}}</span>
                        <span *ngIf="this.from != 'headhunter'" class="divider-right ml-5 mr-5"> </span>
                        <span *ngIf="this.from != 'headhunter'"> <i class="bi bi-telephone clr-grey-7"></i>
                            {{teacher.phone}}</span>
                            <!-- <span *ngIf="this.from == 'FNT'"> <i class="bi bi-telephone clr-grey-7"></i>
                                Hidden until they accept to join your list</span> -->

                    </p>
                    <p class="envelope-icon">
                        <span class="grey-3 fs-12">Accreditation No </span>
                        <span *ngIf="teacher.accreditation.accreditation">
                            <span class="user-sub-title ml-2" *ngIf="teacher.accreditation.accreditation.length > 0">
                                {{teacher.accreditation.accreditation[0].accreditation_id}}</span>
                        </span>
                        <span *ngIf="this.from != 'headhunter'">
                            <span class="divider-right ml-5 mr-5"></span><i class="bi bi-envelope clr-grey-7"></i>
                            <span class="user-sub-title ml-5">{{teacher.user.email}}</span>
                        </span>
                        <!-- <span *ngIf="this.from == 'FNT'">
                            <span class="divider-right ml-5 mr-5"></span><i class="bi bi-envelope clr-grey-7"></i>
                            <span class="user-sub-title ml-5">Hidden until they accept to join your list</span>
                        </span> -->
                    </p>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-6" *ngIf="this.schoolProps.properties.job_only_school == false">
            <div class="stats mt-15">                
               <h5 class="text-regular-bold c-p fit-content mb-5  clr-cc-vilot">Booking stats</h5>
               <div class="d-flex" style="justify-content: space-between;">
                  <div class="">
                     <strong>
                        <p class="text-regular-bold fs-22">{{teacher?.stat?.booking_response}}%</p>
                     </strong>
                     <p class="fs-16 clr-grey-2">Booking response<i class="bi bi-info-circle ml-4 clr-cc-vilot text-regular-bold" triggers="mouseenter:mouseleave" popover="{{ 'This indicates how often a teacher responds to booking requests. A higher response rate indicates a more engaged teacher.'}}"></i></p>             
                  </div>
                  <div class="">
                    <strong>
                       <p class="text-regular-bold fs-22">{{teacher?.stat?.avail_update_count}}%</p>
                    </strong>
                    <p class="fs-16 clr-grey-2">Availablity updated<i class="bi bi-info-circle ml-4 clr-cc-vilot" triggers="mouseenter:mouseleave" popover="{{'This indicates how many upcoming days a teacher has their availability updated for. A higher rate indicates more days updated.'}}"></i></p>
                 </div>
                 <div class="">
                    <strong>
                       <p class="text-regular-bold fs-22">{{teacher?.stat?.last_login}}</p>
                    </strong>
                    <p class="fs-16 clr-grey-2">Last seen<i class="bi bi-info-circle ml-4 clr-cc-vilot" triggers="mouseenter:mouseleave" popover="{{'This indicates the last time a teacher was on the ClassCover app.'}}"></i></p>
                 </div>
               </div>
            </div>
         </div> -->
        <div *ngIf="this.from == 'headhunter'" class="col-md-3 col-sm-4 col-lg-3">
            <div class=" d-flex justify-content-right align-items-center mt-10">
                <p (click)="openInviteModal(teacher)" [ngClass]="remainingInvites == '0' ? 'input-cursor-na':''"
                    *ngIf="this.invite_sent == false" class="c-p clr-cc-vilot text_medium"><i
                        class="bi bi-send-fill"></i> Invite to job</p>
                <p *ngIf="this.invite_sent == true" class="c-p clr-green text_medium"><i class="bi bi-check2"></i>
                    Invite sent</p>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="this.schoolProps.properties.job_only_school == false">
        <!-- <div class="col-md-12 col-sm-12 col-lg-12 mt-20 Fortnight">
            <a class="c-p user-title t-dec-none h_b clr-cc-vilot" (click)="prevFortnight()"><i
                    class="bi fs-20 bi-arrow-left-short v-align-m"></i> Previous
                Fortnight</a>

            <a class="c-p user-title float-right h_b t-dec-none h_b clr-cc-vilot"
                (click)="nextFortnight()">Next Fortnight
                <i class="bi bi-arrow-right-short fs-20 v-align-m"></i></a>
        </div> -->
        <div class="col-md-12 col-lg-12 mt-20">
            <app-inline-calendar [from]="from" [teacherID]="teacher.id" [availability]="teacher.availability"
                (onDatePicked)="setArrData($event)"></app-inline-calendar>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-lg-12 mt-20">
            <nav>
                <div class="nav nav-tabs profilePopUpTab" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-sub-tab" data-bs-toggle="tab" data-bs-target="#nav-sub"
                        type="button" role="tab" aria-controls="nav-sub" aria-selected="true">Subjects &
                        Levels</button>
                    <button class="nav-link" id="nav-exp-tab" data-bs-toggle="tab" data-bs-target="#nav-exp"
                        type="button" role="tab" aria-controls="nav-exp" aria-selected="false">Experience</button>
                    <button class="nav-link" id="nav-qual-tab" data-bs-toggle="tab" data-bs-target="#nav-qual"
                        type="button" role="tab" aria-controls="nav-qual" aria-selected="false">Qualifications</button>
                    <button class="nav-link" id="nav-attach-tab" data-bs-toggle="tab" data-bs-target="#nav-attach"
                        type="button" role="tab" aria-controls="nav-attach" aria-selected="false">Attachments</button>
                    <button class="nav-link" id="nav-ref-tab" data-bs-toggle="tab" data-bs-target="#nav-ref"
                        type="button" role="tab" aria-controls="nav-ref" aria-selected="false">References</button>

                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-sub" role="tabpanel" aria-labelledby="nav-sub-tab">
                    <div class="col-md-12 col-lg-12 mt-5">
                        <div class="row">
                            <div class="col-md-12 mt-15">
                                <p class="user-title">Subjects qualified to teach</p>
                            </div>
                            <div class="col-md-12" *ngIf="teacher.meta.tags">
                                <p class="txt-regular tags" *ngIf="teacher.meta.tags.length > 0">
                                    <span class="qualified-in mt-5 bg-tags" *ngFor="let qualified of teacher.meta.tags">
                                        {{qualified}}
                                    </span>
                                </p>
                                <p class="txt-regular tags mt-5" *ngIf="teacher.meta.tags.length == 0">
                                    <span>No Subjects found</span>
                                </p>
                            </div>
                            <div class="col-md-12" *ngIf="!teacher.meta.tags">
                                <p class="txt-regular tags mt-5">
                                    No Subjects found
                                </p>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-12">
                                <p class="user-title mt-15">Levels</p>
                            </div>
                            <div class="col-md-12" *ngIf="teacher.meta.experiance_tree">
                                <p class="txt-regular tags" *ngIf="teacher.meta.experiance_tree.length > 0">
                                    <span class="levels mt-5 bg-tags"
                                        *ngFor="let levels of teacher.meta.experiance_tree">
                                        {{levels}}
                                    </span>
                                <p class="txt-regular tags mt-5" *ngIf="teacher.meta.experiance_tree.length == 0">
                                    No levels found
                                </p>
                            </div>
                            <div class="col-md-12" *ngIf="!teacher.meta.experiance_tree">
                                <p class="txt-regular tags mt-5">
                                    No levels found
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-exp" role="tabpanel" aria-labelledby="nav-exp-tab">
                    <div class="col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-md-12  mt-15">
                                <p class="user-title">Employment Summary</p>
                                <div class="" *ngIf="teacher.meta.experience_description">
                                    <p class="txt-regular mt-5" *ngIf="teacher.meta.experience_description.length > 0">
                                        {{teacher.meta.experience_description}}
                                    </p>
                                    <p class="txt-regular mt-5" *ngIf="teacher.meta.experience_description.length == 0">
                                        No employment summary found
                                    </p>
                                </div>
                                <div class="" *ngIf="!teacher.meta.experience_description">
                                    <p class="txt-regular mt-5">
                                        No employment summary found
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mt-15">
                                <p class="user-title">Experience</p>
                                <div class="" *ngIf="teacher.work_experience.work_experience">
                                    <div *ngIf="teacher.work_experience.work_experience.length > 0">
                                        <div class=""
                                            *ngFor="let work_experience of teacher.work_experience.work_experience">
                                            <p class="user-title mt-10">{{work_experience.school_name}}</p>
                                            <p class="user-sub-title mt-5">{{work_experience.job_title}}<span
                                                    class="divider-right ml-5 mr-5"></span>{{work_experience.from_date}}
                                                -
                                                {{work_experience.to_date}} </p>
                                            <p class="txt-regular mt-5">
                                                {{work_experience.experience_summary}}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="txt-regular mt-5"
                                            *ngIf="teacher.work_experience.work_experience.length == 0">
                                            No experience found
                                        </p>
                                    </div>
                                </div>
                                <div class="" *ngIf="!teacher.work_experience.work_experience">
                                    <p class="txt-regular mt-5">
                                        No experience found
                                    </p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="tab-pane fade" id="nav-qual" role="tabpanel" aria-labelledby="nav-qual-tab">
                    <div class="col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-md-12  mt-15">
                                <p class="user-title">Qualifications</p>
                            </div>
                        </div>

                        <div class="" *ngIf="teacher.meta.qualifications_v2">
                            <div class="qualification-modal-table mt-15"
                                *ngIf="teacher.meta.qualifications_v2.length > 0">
                                <table>
                                    <tr class="text-r-m-16 table-header">
                                        <th>Title</th>
                                        <th>Institution</th>
                                        <th>Type</th>
                                        <th>Actions</th>
                                    </tr>
                                    <tr class="txt-regular"
                                        *ngFor="let qualifications of teacher.meta.qualifications_v2">
                                        <td>{{qualifications.title}}</td>
                                        <td>{{qualifications.institution}}</td>
                                        <td>{{qualifications.type}}</td>
                                        <td class="text_medium clr-cc-vilot" *ngIf="qualifications.file"><a
                                                *ngIf="qualifications.file.length > 0" class=" clr-cc-vilot"
                                                href="{{qualifications.file}}">Download</a>
                                        <td class=" clr-cc-vilot" *ngIf="!qualifications.file"></td>
                                    </tr>

                                </table>
                            </div>
                            <p class="txt-regular mt-5" *ngIf="teacher.meta.qualifications_v2.length == 0">
                                No qualification found
                            </p>
                        </div>
                        <div class="" *ngIf="!teacher.meta.qualifications_v2">
                            <p class="txt-regular mt-5">
                                No qualification found
                            </p>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-attach" role="tabpanel" aria-labelledby="nav-attach-tab">
                    <div *ngIf="this.from != 'headhunter'">
                        <div class="row">
                            <div class="col-md-12  mt-15">
                                <p class="user-title">Attachments</p>
                            </div>
                        </div>
                        <div class="" *ngIf="teacher.meta.attachment_files">
                            <div class="qualification-modal-table mt-15"
                                *ngIf="teacher.meta.attachment_files.length > 0">
                                <table>
                                    <tr class="text-r-m-16 table-header">
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                    </tr>
                                    <tr class="txt-regular"
                                        *ngFor="let attachment_files of teacher.meta.attachment_files">
                                        <td>{{attachment_files.name}}</td>
                                        <td>{{attachment_files.description}}</td>
                                        <td class="text_medium" *ngIf="attachment_files.file">
                                            <a class=" clr-cc-vilot" *ngIf="attachment_files.file.length > 0"
                                                href="{{attachment_files.file}}">Download
                                                certificate</a>
                                        </td>
                                        <td class=" clr-cc-vilot" *ngIf="!attachment_files.file"></td>
                                    </tr>
                                </table>
                            </div>
                            <p class="txt-regular mt-5" *ngIf="teacher.meta.attachment_files.length == 0">
                                No attachments found
                            </p>
                        </div>
                        <div class="" *ngIf="!teacher.meta.attachment_files">
                            <p class="txt-regular mt-5">
                                No attachments found
                            </p>
                        </div>
                    </div>
                    <div *ngIf="this.from == 'headhunter'">
                        <p class="text-center mt-30"><img class="no-invite-image"
                                src="assets/images/NoResultFound/hideOnHead.png" alt="No invites found!" /></p>
                        <h5 class="title text-center mt-15">Attachments and CV's are only shown if a potential candidate applies for the job.</h5>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-ref" role="tabpanel" aria-labelledby="nav-ref-tab">
                    <div *ngIf="this.from != 'headhunter'">
                    <div class="row">
                        <div class="col-md-12  mt-15">
                                 <p class="user-title">References</p>
                        </div>
                    </div>

                    <div class="" *ngIf="teacher.meta.references">
                        <div class="qualification-modal-table mt-15" *ngIf="teacher.meta.references.length > 0">
                            <table>
                                <tr class="text-r-m-16 table-header">
                                    <th>Name</th>
                                    <th>Phone No</th>
                                    <th>Email</th>
                                </tr>
                                <tr class="txt-regular" *ngFor="let reference of teacher.meta.references">
                                    <td>{{reference.name}} <br>{{reference.org}}</td>
                                    <td>{{reference.phone}}</td>
                                    <td>{{reference.email}}</td>
                                </tr>
                            </table>
                        </div>
                        <p class="txt-regular mt-5" *ngIf="teacher.meta.references.length == 0">
                            No reference found
                        </p>
                    </div>
                    <div class="" *ngIf="!teacher.meta.references">
                        <p class="txt-regular mt-5">
                            No reference found
                        </p>
                    </div>
                    </div>
                    <div *ngIf="this.from == 'headhunter'">
                        <p class="text-center mt-30"><img class="no-invite-image text-center"
                                src="assets/images/NoResultFound/hideOnHead.png" alt="No invites found!" /></p>
                        <h5 class="title text-center mt-15">References are only shown if potential candidate applies for
                            the job.</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #resendPendingInviteModal>
    <form [formGroup]="inviteToJobForm"
        [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : ''">
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">Send a custom message with your job invite (optional)
            </h4>
            <button type="button" (click)="this.bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <p class="text_medium">You can add a custom message or provide additional context to your
                        invite</p>
                </div>
                <div class="col-md-12 mt-5">
                    <textarea class="form-control" placeholder="Type here" formControlName="custom_message"
                        rows="3"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button (click)="inviteToJob(Invitedteacher)" type="button" class="btn cc_p_btn f_c fs_13">Send
                invite</button>
        </div>
    </form>
</ng-template>