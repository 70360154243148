<div class="container pt-15 pb-50">
    <div class="row">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                    type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Terms and
                    Conditions</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                    type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Privacy Policy</button>
            </li>
        </ul>
        <div class="tab-content mt-20 p-0" id="myTabContent">
            <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                tabindex="0">
                <embed class="termsDescription"
                src="https://quetzalstore.blob.core.windows.net/terms-and-conditions/ClassCover%20Marketplace%20Terms%20and%20Conditions%20May%202024.pdf"
                type="application/pdf" width="100%" />
            </div>
            <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                <embed class="termsDescription"
                src="https://quetzalstore.blob.core.windows.net/terms-and-conditions/ClassCover%20Privacy%20Policy%20May%202024.pdf"
                type="application/pdf" width="100%" />
            </div>
        </div>
    </div>
    <div class="row mt-10">
        <div class="col-lg-12 p-0">
            <input id="checkBox" (change)="checkCondition($event)" (ngModel)="conditionCheck" class="form-check-input" type="checkbox" />
            <label for="checkBox" class="ml-5 txt-regular m-0 mt-5">I acknowledge ClassCover's Terms and Conditions</label>
        </div>
        <div class="col-lg-12 p-0">
            <input id="checkBox1" (change)="checkPrivacy($event)" (ngModel)="privacyCheck"  class="form-check-input" type="checkbox" />
            <label for="checkBox1" class="ml-5 txt-regular m-0 mt-5">I acknowledge ClassCover's Privacy Policy</label>
        </div>
        <div class="col-lg-12 p-0 mt-10">
           <p class="txt-regular warn-badge fit-content pt-5 pb-5 pl-10 pr-30"><i class="bi bi-exclamation-triangle-fill"></i> Check both boxes to proceed</p>
        </div>

    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="d-flex place-content-end">
                <button (click)="redirectToJobsPage('rejected')"
                    class="btn btn-plain-txt clr-grey mr-20">Decline</button>
                <button [ngClass]="showAcceptBtn ? '' : 'disabled'" (click)="redirectToJobsPage('accepted')"
                    class="btn cc-blue-btn">Accept</button>
            </div>
        </div>
    </div>
</div>