<div class=" pb-30 pt-15">
    <div class="row">
        <div class="col-lg-4 m_a">
            <div class="card px-20  mt-30">
            <div class="text-center" *ngIf="payment_status == 'succeeded' || payment_status == undefined">
                <i class="bi bi-check2-circle"></i>
                <p>Payment success</p>
                <p>please wait...</p>
            </div>
        </div>
        </div>
    </div>
</div>