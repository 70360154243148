<div class="container pb-50" id="school-calendar">
  <div class="row mt-20">
    <div class="col-md-9 col-lg-9">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link active" (click)="resetCalendarOnEvent()" id="nav-calender-tab" data-bs-toggle="tab"
            data-bs-target="#nav-calender" type="button" role="tab" aria-controls="nav-calender" aria-selected="true"
            (click)="setActiveTab(true)">
            Calendar
          </button>
          <button class="nav-link" (click)="getCalenderBookings()" id="nav-bookings_cal-tab" data-bs-toggle="tab"
            data-bs-target="#nav-bookings_cal" type="button" role="tab" aria-controls="nav-bookings_cal"
            aria-selected="false" (click)="setActiveTab(false)">
            Bookings
          </button>
        </div>
      </nav>
      <!-- <ckeditor [data]="content" [config]="config"></ckeditor> -->
      <div class="tab-content mt-20" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-calender" role="tabpanel" aria-labelledby="nav-calender-tab">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="row mt-5 mb-20 align-items-center">
                <div class="col-lg-6">
                  <div class="d-flex">
                    <div class="d-flex  align-items-center ">
                      <div class="d-flex  top-filter align-items-center c-p  mr-40">
                        <i class="bi bi-calendar2"></i>
                        <ng-select class="calenderView fit-content  c-p" [searchable]="false" [multiple]="false"
                          [(ngModel)]="defaultViewMonth" id="calendar-type" (change)="changeCalendarType($event)">
                          <ng-option value="month">Month</ng-option>
                          <ng-option value="week">Week</ng-option>
                        </ng-select>
                      </div>
                      <div>
                        <p #customPickerDiv (click)="openCustomPicker()"
                          class="c-p h_b export_filter top-filter  fit-content">Export</p>
                        <div *ngIf="is_openCustomPicker" #customDatepickerExport
                          class="card customDatepickerExport pb-15">
                          <bs-daterangepicker-inline [bsConfig]="{showWeekNumbers: false,displayMonths: 1,
                        containerClass: 'theme-orange'}" (bsValueChange)="getExportValue($event)"
                            [daysDisabled]="[6, 0]">
                          </bs-daterangepicker-inline>

                          <p class="text-right"><button type="button" (click)="exportCalandarData()"
                              class="btn  fw-500 cc_p_btn fit-content fs_13 mr-15">Export</button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6  top-filter">
                  <div class="d-flex place-content-end">
                    <p class="c-p  h_b" (click)="bulkEdit.show()">Bulk Action</p>

                    <p class="c-p h_b ml-20" (click)="externalEventPopup('')">Add External Events</p>
                  </div>
                </div>
              </div>
              <full-calendar #calendar [options]="calendarOptions"></full-calendar>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-bookings_cal" role="tabpanel" aria-labelledby="nav-bookings_cal-tab">
          <form [formGroup]="calendarBookingFilterForm">
            <div class="row align-items-center mt-10">
              <div class="col-lg-6 col-md-6 mt-10">
                <div class="input-group">
                  <input id="name" formControlName="filter_name" type="text" placeholder="Search for name or email"
                    class="form-control" aria-label="Search for name" />
                  <button class="input-group-text" for="search-by-name" (click)="getFilterInputs()"><i
                      class="bi bi-search"></i>
                    </button>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 mt-10">
                <input type="text" placeholder="Select date" class="form-control" bsDatepicker [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-red',
                    showWeekNumbers: false
                  }" [(ngModel)]="bsValue" (ngModelChange)="getFilterInputs()" [ngModelOptions]="{ standalone: true }"
                  [daysDisabled]="[6, 0]" />
              </div>
            </div>
          </form>
          <div class="row mt-15 align-items-center" *ngIf="bookingDetails?.length > 0">
            <div class="col-lg-6  mt-10">
              <div class="d-flex align-items-center">
              <input #checkAll class="form-check-input mt-0 mr-10" [(ngModel)]="selectAllCheck"
                (change)="setCheckbox($event, 'All')" id="checkAll" type="checkbox" />
              <!-- <label class="form-check-label mt-0 mr-20" for="checkAll">
                <span class="h_b" *ngIf="!selectAllCheck">Select all</span>
                <span class="h_b" *ngIf="selectAllCheck">Unselect all</span>
              </label> -->
              <span class="ml-10 text-r-m-16 c-p">
                {{ consumerDetails.length }} Selected
              </span>
              </div>
            </div>
            <div class="col-lg-6 mt-10">
              <div class="d-flex place-content-end">
                <!-- <div class="">{{checkBoxSelectedTeacher.length}} Selected</div> -->
                <!-- <p [ngClass]="hideButton ? 'input-cursor-na' : ''" class="ml-30 txt-regular c-p" (click)="sendMail()">
                  <i class="bi bi-envelope"></i> Message
                </p> -->
                <span  [ngClass]="hideButton ? 'input-cursor-na' : ''" class="d-flex align-items-center h_b ml-12 c-p" (click)="sendMail()">
                  <i class="bi bi-chat-left fs-14"></i>
                  <span [ngClass]="!hideButton ? 'text-regular-bold':''" class="ml-4 h_b text-regular-medium">Message selected</span>
                </span>
              </div>
            </div>
          </div>
          <div *ngIf="bookingDetails?.length > 0">
            <div *ngFor="let teacher of bookingDetails">
              <h3 class="mt-20">{{ teacher?.date | date : "MMM, d" }}</h3>
              <div *ngFor="let bookingList of teacher?.booking">
                <div class="card mt-10 px-18"
                  [ngClass]="bookingList.status == 'casual_cancelled' ? 'casual_cancelled' : ''">
                  <div class="row">
                    <div class="col-lg-9 col-md-9 col-10">
                      <div class="col-md-12 col-lg-12 d-flex align-items-center">
                        <div class="pic-and-check c_input">
                          <input (click)="setCheckbox($event, bookingList)" [checked]="bookingList.isChecked"
                            #checkboxes class="form-check-input mr-10 ml-10" type="checkbox" />
                          <!-- <input type="hidden" formControlName="selectedValues" value="{{values}}"> -->
                        </div>
                        <div class="pic-and-check ml-0">
                          <img
                            [src]="bookingList?.notification?.consumer?.meta.profile_image != null ? bookingList?.notification?.consumer?.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                            class="profile-picture-l img-complete-match" />
                        </div>

                        <div class="pic-and-check ml-10">
                          <div class="d-flex">
                            <p>
                              <span
                                class="user-title c-p h_b">{{bookingList?.notification?.consumer?.user?.full_name}}</span>
                              <span>
                                <app-validated-dict
                                  [validateDict]="bookingList?.notification?.consumer?.validated_dict">
                                </app-validated-dict>
                              </span>
                            </p>
                          </div>
                          <p class="user-sub-title">
                            {{ bookingList?.display_date }}
                          </p>
                          <p class="mb-0 lh-12" *ngIf="!bookingList.is_flexi_booking">
                            <span class="text-s-m-g-3 mr-4">Class being covered:</span>
                            <span class="user-sub-title">{{
                              bookingList.information.class_being_covered
                              }}</span>
                          </p>
                          <p class="mb-0 lh-12" *ngIf="!bookingList.is_flexi_booking">
                            <span class="text-s-m-g-3 mr-4">Teacher being covered:</span>
                            <span class="user-sub-title">{{
                              bookingList.information.provider_being_covered
                              }}</span>
                          </p>
                          <p class="mb-0 lh-12" *ngIf="bookingList.is_flexi_booking && ((bookingList.information.class_being_covered != '' && bookingList.information.class_being_covered != null ) ||
                          (bookingList.information.provider_being_covered != '' && bookingList.information.provider_being_covered != null))">
                            <span class="text-s-m-g-3 mr-4">Class being covered:</span>
                            <span class="user-sub-title">{{bookingList.information.class_being_covered}}</span>
                          </p>
                          <p class="mb-0 lh-12" *ngIf="bookingList.is_flexi_booking && ((bookingList.information.class_being_covered != '' && bookingList.information.class_being_covered != null ) ||
                          (bookingList.information.provider_being_covered != '' && bookingList.information.provider_being_covered != null))">
                            <span class="text-s-m-g-3 mr-4">Teacher being covered:</span>
                            <span class="user-sub-title">{{bookingList.information.provider_being_covered}}</span>
                          </p>
                          <div *ngIf="bookingList.is_flexi_booking && ((bookingList.information.class_being_covered == '' || bookingList.information.class_being_covered == null) && (bookingList.information.provider_being_covered == '' || bookingList.information.provider_being_covered == null))">
                            <div class="d-flex">
                              <div>
                                <i class="bi bi-exclamation-circle-fill mr-7 clr-red"></i>
                              </div>
                              <div class="mb-0 mt-5">
                                <p class="user-sub-title mr-4">
                                  This is a Flexi Booking. Make sure you update
                                  the class and teacher being covered before the
                                  booking date.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="bookingList.is_flexi_booking && ((bookingList.information.class_being_covered == '' || bookingList.information.class_being_covered == null) && (bookingList.information.provider_being_covered == '' || bookingList.information.provider_being_covered == null))">
                            <p (click)="bookingPopup(bookingList, 'edit')"
                              class="mt-10 c-p clr-cc-vilot text-regular-bold">
                              Update now <i class="bi bi-arrow-right"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-2">
                      <div class="place-right d-flex">
                        <p (click)="sendMessage(bookingList?.notification?.consumer)" class="c-p"
                          [ngClass]="hideButton ? '':'input-cursor-na'"><i class="bi bi-chat-left mr-3"></i></p>
                      </div>
                    </div>
                  </div>
                  <span class="cancelledTag" *ngIf="bookingList.status == 'casual_cancelled'">
                    Cancelled
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="bookingDetails?.length == ''" class="text-center">
            <img class="no-bookings" src="assets/images/no_bookings_calander.png" alt="no_bookings" />
            <p class="title">You have no booking for the selected date</p>
            <button class="btn  cc_p_btn fs_13 mt-20">Make a booking</button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 md-4" *ngIf="activeTab">
      <h1>Bookings</h1>
      <h3 class="selected-date">{{ selectedDate }}</h3>
      <!-- {{bookingList | json}} -->
      <div>
        <div *ngFor="let list of bookingList">
          <div class="card booking-card-details mb-10 px-10 c-p"
            [ngClass]="list.status == 'casual_cancelled' ? 'cancelled' : ''">
            <div class="row align-items-center" (click)="bookingPopup(list, '')">
              <div class="col-md-12 col-sm-12 col-lg-12">
                <div class="col-md-12 col-lg-12 d-flex profile-info">
                  <div class="pic-and-check ml-0">
                    <img class="cal_pp" alt="img" [src]="
                        list.notification.consumer.meta.profile_image != null
                          ? list.notification.consumer.meta.profile_image
                          : '../../../../assets/images/teacher-static-profile.png'
                      " />
                  </div>
                  <div class="pic-and-check ml-10">
                    <p class="u_n mb-0">
                      {{ list.notification.consumer.user.full_name }}
                    </p>
                    <p class="mb-0 mt-2">
                      <span class="text-s-m-g-3 mr-4">Time:</span>
                      <span class="user-sub-title">{{ list.start_time }} - {{ list.end_time }}</span>
                    </p>
                    <p class="mb-0 mt-1" *ngIf="!list.is_flexi_booking">
                      <span class="text-s-m-g-3 mr-4">Class being covered:</span>
                      <span class="user-sub-title">{{
                        list.information.class_being_covered
                        }}</span>
                    </p>
                    <p class="mb-0 mt-1" *ngIf="!list.is_flexi_booking">
                      <span class="text-s-m-g-3 mr-4">Teacher being covered:</span>
                      <span class="user-sub-title">{{
                        list.information.provider_being_covered
                        }}</span>
                    </p>
                    <!-- <p>{{list.information.class_being_covered}} hi {{list.is_flexi_booking}}</p> -->
                    <p class="mb-0 mt-1" *ngIf="list.is_flexi_booking && ((list.information.class_being_covered != '' && list.information.class_being_covered != null ) ||
                        (list.information.provider_being_covered != '' && list.information.provider_being_covered != null))">
                      <span class="text-s-m-g-3 mr-4">Class being covered:</span>
                      <span class="user-sub-title">{{
                        list.information.class_being_covered
                        }}</span>
                    </p>
                    <p class="mb-0 mt-1" *ngIf="list.is_flexi_booking && ((list.information.class_being_covered != '' && list.information.class_being_covered != null ) ||
                        (list.information.provider_being_covered != '' && list.information.provider_being_covered != null))">
                      <span class="text-s-m-g-3 mr-4">Teacher being covered:</span>
                      <span class="user-sub-title">{{
                        list.information.provider_being_covered
                        }}</span>
                    </p>
                  </div>
                </div>
                <!-- {{list.information | json}}
                <p>{{list.is_flexi_booking}}</p>
                <p>j {{list.information.class_being_covered}}</p>
                <p> h {{list.information.provider_being_covered}}</p> -->
                <div *ngIf="list.is_flexi_booking && ((list.information.class_being_covered == '' || list.information.class_being_covered == null) && (list.information.provider_being_covered == '' || list.information.provider_being_covered == null))">
                  <div class="d-flex mt-5">
                    <div>
                      <i class="bi bi-exclamation-circle-fill mr-7 clr-red"></i>
                    </div>
                    <div class="mb-0 mt-5">
                      <p class="user-sub-title mr-4">
                        This is a Flexi Booking. Make sure you update the class
                        and teacher being covered before the booking date.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="list.is_flexi_booking && ((list.information.class_being_covered == '' || list.information.class_being_covered == null) && (list.information.provider_being_covered == '' || list.information.provider_being_covered == null))">
              <p (click)="bookingPopup(list, 'edit')" class="mt-10 clr-cc-vilot text-regular-bold">
                Update now <i class="bi bi-arrow-right"></i>
              </p>
            </div>
            <div *ngIf="list.status == 'casual_cancelled'">
              <p (click)="reOpenBooking(list.booking_id,list.line_item_id)" class="mt-10 clr-cc-vilot text-regular-bold">
                Rebook
              </p>
            </div>
          </div>
        </div>

        <!-- No Booking Found  -->
        <div *ngIf="bookingList.length == 0">
          <p class="text-center">
            <img class="no-booking-image" src="assets/images/no_bookings_calander.png" alt="no_bookings" />
          </p>
          <h6 class="text-center title mt-20">No bookings found</h6>
        </div>
        <div class="card align-items-center event-info mb-10 mt-15 d-flex">
          <div class="flex-grow-1 user-sub-title">
            Total bookings this month
          </div>
          <div class="bd-highlight text-regular-bold">{{ totalBookings }}</div>
        </div>
        <div class="card event-info align-items-center mb-20 d-flex">
          <div class="flex-grow-1 user-sub-title">Hours booked</div>
          <div class="bd-highlight text-regular-bold">
            {{ totalHoursBooking }}
          </div>
        </div>
        <div class="card mb-20 pl-15 pr-15 pt-15 pb-15 text-center">
          <p class="txt-regular">Are you looking to book a teacher?</p>
          <button routerLink="/school/booking-request" type="button" class="btn cc_p_btn fs_14 mt-15">
            Make a booking
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-3 hideON991" *ngIf="!activeTab">
      <app-invite-request-tab></app-invite-request-tab>
      <div class="card px-15 mt-10">
        <p class="txt-regular">
          Upload or quick add your existing casual staff to your list
        </p>
        <p (click)="openAddnewModal()" class="c-p"><button class="btn cc_p_btn fs_13   fit-content mt-10">Add
            new</button></p>
      </div>
    </div>
  </div>

  <!-- External Event -->
  <ng-template #addExternalEvent>
    <div class="modal-header">
      <h4 class="modal-title">Create external event</h4>
      <button type="button" (click)="closeExternalModal()" class="btn-close" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="addExternalEventForm" (ngSubmit)="adddExternalEvent(this.selectedEventID)">
        <div class="mb-16">
          <label for="formFileMultiple">Event title *</label>
          <input class="form-control" placeholder="Enter title" type="text" formControlName="title" />
          <div *ngIf="submitted" class="fs-13 clr-red mt-5">
            <span *ngIf="f['title'].errors?.['required']">Title is required</span>
            <span *ngIf="f['title'].errors?.['minlength']">(Min 6 char)</span>
          </div>
        </div>
        <div class="mb-16 calender-date-range">
          <label for="formFileMultiple">Event date range</label>
          <!-- <input class="form-control" type="text" formControlName="dateRange"> -->
          <input type="text" formControlName="start_end_date" (click)="openCustomPickerRange()"
            placeholder="Daterangepicker" class="form-control" />
          <div *ngIf="is_openCustomPickerRange" #eventDateRangeExport class="card  customDatepickerExport pb-15 pr-15">
            <div class="d-flex">
              <bs-daterangepicker-inline [bsConfig]="{showWeekNumbers: false,displayMonths: 1,
          containerClass: 'theme-orange'}" [(bsValue)]="eventRangeValue" (bsValueChange)="setEventRangeValue($event)"
                [daysDisabled]="[6, 0]">
              </bs-daterangepicker-inline>
              <div class="pt-10">
                <label>Start time</label>
                <timepicker [(ngModel)]="startTime" [ngModelOptions]="{ standalone: true }"></timepicker>
                <hr class="mt-5 mb-5">
                <label>End time</label>
                <timepicker [(ngModel)]="endTime" [ngModelOptions]="{ standalone: true }"></timepicker>
              </div>
            </div>
            <p class="text-right"><button type="button" (click)="closeDateRange()"
                class="btn  fw-500 cc_p_btn fit-content fs_13">Done</button>
            </p>
          </div>
        </div>
        <div class="mb-16">
          <label for="formFileMultiple">Comment *</label>
          <textarea class="form-control" placeholder="Comment" id="popup_confirmation_details" rows="3"
            formControlName="comment"></textarea>
          <div *ngIf="selectedEventID != ''">
            <div *ngFor="let comments of selectedEvent?.event?.extendedProps?.comments">
              <p class="mt-5 text-regular-medium" (click)="deleteExternalEvent(comments?.id, 'comment');">
                [<i class="bi bi-x c-p clr-red fs-17"></i>] {{comments?.comment}}
              </p>
            </div>
          </div>
          <div *ngIf="submitted" class="fs-13 clr-red mt-5">
            <span *ngIf="f['comment'].errors?.['required']">Comment is required</span>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <button *ngIf="selectedEventID != ''" [ngClass]="selectedEventID.length > 0 ? '' : 'disabled'"
            (click)="deleteExternalEvent('', 'event');" class="btn-plain-txt mr-30  " id="delete">
            Delete
          </button>
          <button type="submit" class="btn cc_p_btn fs_13 float-right">
            Save Event
          </button>
        </div>
      </form>
      <div *ngIf="">

      </div>
    </div>
  </ng-template>

  <!-- Bulk Edit -->
  <div class="modal fade" bsModal #bulkEdit="bs-modal" tabindex="-1" role="dialog"
    aria-labelledby="dialog-nested-name1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Edit Booking</h4>
          <button type="button" class="btn-close close pull-right" aria-label="Close"
            (click)="bulkEdit.hide();closeBulkModal()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h6>Edit or delete an individual booking or a series of bookings</h6>
          <form [formGroup]="getBulkDataForm">
            <div class="row mt-20">
              <div class="col-lg-4">
                <div class="input-group">
                  <input id="search-by-name" formControlName="filter_name" type="text" placeholder="Search by name"
                    class="form-control" aria-label="Search by name" />
                  <button class="input-group-text" (click)="getBulkEditData('')" for="search-by-name"><i
                      class="bi bi-search"></i>
                    </button>
                </div>
              </div>
              <div class="col-lg-4">
                <input type="text" [minDate]="minDate" [(ngModel)]="bsBulkFromValue"
                  [ngModelOptions]="{standalone: true}" (ngModelChange)="getBulkEditData($event)"
                  placeholder="From date" class="form-control" bsDatepicker [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-red',
                  showWeekNumbers: false
                }" />
              </div>
              <div class="col-lg-4">
                <input type="text" [minDate]="minDate" [(ngModel)]="bsBulkToValue" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="getBulkEditData($event)" placeholder="To date" class="form-control" bsDatepicker
                  [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-red',
                  showWeekNumbers: false
                }" />
              </div>
            </div>

          </form>
          <div class="row mt-8">
            <div class="col-lg-12" *ngIf="bulkData">
              <table class="table" *ngIf="bulkData.length > 0">
                <thead class="cardThead">
                  <tr>
                    <th scope="col">
                      <input #checkAllApp [(ngModel)]="selectAllAppCheck" class="form-check-input mt-0 mr-5"
                        id="checkAllApp" type="checkbox" (change)="selectAllApp('All', $event)" />
                    </th>
                    <th class="u_m_s_g2" scope="col">Casual booked</th>
                    <th class="u_m_s_g2" scope="col">Date</th>
                    <th class="u_m_s_g2" scope="col">Class being covered</th>
                    <th class="u_m_s_g2" scope="col">Teacher being covered</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of bulkData">
                    <td scope="row">
                      <input class="form-check-input mr-10 c-p" (change)="selectAllApp(data, $event)"
                        [checked]="data.isChecked" type="checkbox" id="{{data.id}}" type="checkbox">
                    </td>
                    <td class="txt-regular  mt-1">
                      <img class="cal_pp" [src]="
                        data.profile_image != null
                          ?data.profile_image
                          : '../../../../assets/images/teacher-static-profile.png'
                      " />
                      <span class="ml-10">{{data.full_name}}</span>

                    </td>
                    <td class="u_m_s_g2  mt-1">{{data.date}} <br> {{data.time}} </td>
                    <td class="u_m_s_g2">{{data.class_covered}}</td>
                    <td class="u_m_s_g2">{{data.teacher_covered}}</td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="bulkData.length == 0">
                <p class="text-center mt-10">
                  <img class="no-bulkData" src="assets/images/CCPI_PendingRequests_NoAcceptedRequests-02.png"
                    alt="No_img" />
                </p>
                <h6 class="text-center title mt-20">Enter a staff name or a date range to
                  be able to edit a booking or multiple bookings</h6>
              </div>
            </div>
            <div *ngIf="!bulkData">
              <p class="text-center  mt-10">
                <img class="no-bulkData" src="assets/images/CCPI_PendingRequests_NoAcceptedRequests-02.png"
                  alt="No_img" />
              </p>
              <h6 class="text-center title mt-20">Enter a staff name or a date range to
                be able to edit a booking or multiple bookings</h6>
            </div>
            <div class="col-lg-12 mt-20">
              <div class="row">
                <div class="col-md-12 d-flex justify-content-end">
                  <button [ngClass]="this.bulkDetails.length > 0 ? '' : 'disabled'"
                    (click)="openBulkDeleteModal();bulkEdit.hide()" class="btn-plain-txt mr-30  " id="delete">
                    Delete <span *ngIf="this.bulkDetails.length > 0">{{this.bulkDetails.length}} bookings</span>
                  </button>
                  <button [ngClass]="this.bulkDetails.length > 0 ? '' : 'disabled'"
                    (click)="openBulkDetailModal();bulkEdit.hide()" type="submit" class="btn cc_p_btn fs_14">
                    Edit Booking
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #bulkDataModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update booking</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeBulkEditModal()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editBookings">

      <div class="row">
        <div class="col-md-12">
          <p class="txt-regular">Booking request edits will need to be reviewed and confirmed by staff that are
            involved. You can edit your booking up until the day of your booking.</p>
        </div>
      </div>
      <div class="row  align-items-center">
        <div class="col-md-6  mt-10">
          <input type="text" class="form-control" formControlName="booking_teachercovered"
            placeholder="{{filtersArray.custom_field2}}">
        </div>
        <div class="col-md-6  mt-10">
          <input type="text" class="form-control" formControlName="booking_classcovered"
            placeholder="{{filtersArray.custom_field1}}">
        </div>
        <div class="col-md-6  mt-10">
          <select class="form-select" formControlName="booking_absencereason" aria-label="booking-absence-reason">
            <option *ngFor="let absense of filtersArray.absence_reason" value="{{absense.value}}">
              {{absense.title}}
            </option>
          </select>
        </div>
        <!-- <div class="col-md-12  mt-10">
              <textarea type="text" class="form-control" formControlName="booking_notes" rows="3"
                placeholder="This note will be sent to the staff via email"></textarea>
            </div> -->

        <div class="col-md-6  mt-10">
          <input type="text" class="form-control" formControlName="booking_payrollfield1"
            placeholder="{{filtersArray.payroll_report_fields?.payroll_field_label_1}}">
        </div>
        <div class="col-md-6  mt-10">
          <input type="text" class="form-control" formControlName="booking_payrollfield2"
            placeholder="{{filtersArray.payroll_report_fields?.payroll_field_label_2}}">
        </div>
        <div class="col-md-6  mt-10">
          <input type="text" class="form-control" formControlName="booking_payrollfield3"
            placeholder="{{filtersArray.payroll_report_fields?.payroll_field_label_3}}">
        </div>
      </div>

      <div class="col-lg-12 mt-20">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-end">
            <!-- <button class="btn-plain-txt mr-30  " id="delete">
                Cancel
              </button> -->
            <button (click)="openBulkConfrimModal()" type="submit" class="btn cc_p_btn fs_14">
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #bulkEditConfirmModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Any updates you make will affect all bookings and casuals involved.</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeConfirmModal()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editBookings">

      <div class="row">
        <div class="col-md-12">
          <p class="txt-regular">Are you sure you want to update the selected booking(s)?</p>
        </div>
      </div>
      <div class="col-lg-12 mt-20">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-end">
            <!-- <button class="btn-plain-txt mr-30  " id="delete">
                Cancel
              </button> -->
            <button (click)="updateBulkData('bulk_edit')" type="submit" class="btn cc_p_btn fs_14">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #cancelReasonModal>
  <form [formGroup]="cancelBookingRequestForm">
    <div class="modal-header">
      <h4 class="modal-title" id="exampleModalLabel">Confirm Delete
      </h4>
      <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
    </div>

    <div class="modal-body pb-0">
      <div class="row  align-items-center">
        <div class="col-md-12">
          <p class="user-title-m">Are you sure you want to confirm this action?
          </p>
          <label for="exampleFormControlTextarea1" class="form-label user-title-m mt-5">Send a message to the
            staff *</label>
          <textarea class="form-control mt-5" (keyup)="checkLength()" maxlength="280"
            formControlName="custom_cancel_msg" placeholder="Add Message here..." id="exampleFormControlTextarea1"
            rows="6"></textarea>
          <p class="txt-regular mt-10">Max({{this.ShowLenght}} characters)</p>

          <div *ngIf="Desubmitted">
            <span class="show-errors"
              *ngIf="cm['custom_cancel_msg'].errors?.['required']  || cm['custom_cancel_msg'].errors?.['whitespace']">*
              Message is
              required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer mt-10">
      <!-- <button type="button"  (click)="bsModalRef.hide()" class="btn_cc_back">Back</button> -->
      <button type="button" (click)="updateBulkData('bulk_delete')" class="btn cc_p_btn fs_13">Confirm</button>
    </div>
  </form>
</ng-template>

<ng-template #exportModal>
  <form [formGroup]="cancelBookingRequestForm">
    <div class="modal-header">
      <h4 class="modal-title" id="exampleModalLabel">Confirm export
      </h4>
      <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
    </div>

    <div class="modal-body pb-0">
      <div class="row  align-items-center">
        <div class="col-md-12">
          <label>Enter date range to export bookings
          </label>
          <input formArrayName="export_bookings" placeholder="Enter date range" name="export_bookings"
            class="form-control mt-2">

          <div class="card">
            <bs-daterangepicker-inline [bsConfig]="{
            showWeekNumbers: false,
            displayMonths: 1
          }" [daysDisabled]="[6, 0]" [bsValue]="bsInlineRangeValue"></bs-daterangepicker-inline>

            <button type="button" (click)="updateBulkData('bulk_delete')"
              class="btn cc_p_btn fs_13 mt-10">Export</button>

          </div>
        </div>
      </div>
      <div class="modal-footer mt-10">
        <!-- <button type="button"  (click)="bsModalRef.hide()" class="btn_cc_back">Back</button> -->
        <button type="button" (click)="updateBulkData('bulk_delete')" class="btn cc_p_btn fs_13">Export</button>
      </div>
    </div>
  </form>
</ng-template>