import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { last, map, take } from "rxjs/operators";
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CardProfileViewComponent } from '../card-profile-view/card-profile-view.component';
import { TeacherProfilePopupComponent } from '../teacher-profile-popup/teacher-profile-popup.component';
import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pending-request',
  templateUrl: './pending-request.component.html',
  styleUrls: ['./pending-request.component.scss']
})
export class PendingRequestComponent implements OnInit {
  public schoolProps!: SchoolData;
  @ViewChild('actionModals') actionModals: any;
  @ViewChild('acceptRequestModal') acceptRequestModal: any;

  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  obsHistoryArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  PendingRequestteachersList: Observable<any> = this.obsArray.asObservable();
  PendingHistoryteachersList: Observable<any> = this.obsHistoryArray.asObservable();
  totalArrayCount: any;
  totalArrayHistoryCount: any
  teacherLengthLimit: any;
  teacherHistoryLengthLimit: any;
  currentHistoryPage: number = 1

  pendingData: any;
  historyData: any;
  currentPage: number = 1
  blockedData: any;

  filtersArray: any = []
  subjectsAndTags = [];
  levelFilter = [];
  schoolTimings = [];
  consumerType = [];
  teacher: Array<any> = [];
  listHealth: any;
  public submitted: boolean = false;

  declineInviteForm: FormGroup = this.fb.group({
  });
  pendingRequestFilterForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_levels: [''],
    filter_subjects: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    page: ['']
  });
  pendingHistoryFilterForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_levels: [''],
    filter_subjects: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    page: ['']
  });
  pendingBlockedFilterForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_levels: [''],
    filter_subjects: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    page: ['']
  });
  pendingRequestData: any = [];
  GroupPendingRequestData: any = [];
  consumerDetails: Array<any> = [];
  hideButton: boolean = true;
  selectAllCheck: boolean = false;
  actionType: any;
  teacherName: any;
  public actionForm: any
  availTeacher: any;

  ShowLenght: number = 500;
  ShowLenghtError: any = 500;

  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.getFiltersData();
    this.getListHealth();
    this.formInit();
    // Booking request
    this.bookingService.pendingRequestPendingList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.pendingData = res.data.pending
        this.obsArray.next(res.data.pending);
        this.teacherLengthLimit = res.data.total_count;
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    })
    this.ngListener()
    this.ngRequestHistoryOnDestroy()
    //End of booking request
  }

  getUpdatedPendingList() {
    this.bookingService.pendingRequestPendingList(this.currentPage).subscribe({
      next: (res: any) => {
        this.pendingData = res.data.pending
        this.obsArray.next(res.data.pending);
        this.teacherLengthLimit = res.data.total_count;
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }
  // Get list health data
  getListHealth() {
    // this.bookingService.listHealth(this.currentPage).subscribe({
    //   next: (res: any) => {
    //     console.log("hellooooo", res.data);
    //     this.listHealth = res.data
    //   },
    //   error: (err: any) => {
    //     console.log(err);
    //   }
    // })
  }
  // Get filters data
  getFilterInputs() {
    this.currentPage = 1;
    this.ngxService.start();
    // Get filters data API

    if (this.activeTab == 'pending') {
      this.bookingService.pendingRequestPendingList(this.getInputFormControlValues()).subscribe({
        next: (res: any) => {
          this.pendingData = res.data.pending
          this.obsArray.next(res.data.pending);
          this.teacherLengthLimit = res.data.total_count;
          this.ngxService.stop();
          this.selectAllCheck = false
          this.consumerDetails = [];
          this.hideButton = true
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    } else if (this.activeTab == 'history') {
      this.bookingService.pendingRequestHistoryList(this.getInputFormControlValues()).subscribe({
        next: (res: any) => {
          this.historyData = res.data.history
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    } else if (this.activeTab == 'blocked') {
      this.bookingService.pendingRequestBlockedList(this.getInputFormControlValues()).subscribe({
        next: (res: any) => {
          this.blockedData = res.data.blocked
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
    // End of get filters data API
  }
  // End of get filters data
  // Get Filter Data
  getFiltersData() {
    // this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.commonService.schoolChoices('pending_request').subscribe({
      next: (res: any) => {
        this.filtersArray = res.data;
        this.subjectsAndTags = res.data.tags;
        this.levelFilter = res.data.experience_tree;
        this.consumerType = res.data.consumer_type;
        // this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  formInit() {
    this.actionForm = this.fb.group({
      email_content: ['', [Validators.required, Validators.maxLength(500)]],
      action: [''],
      request_id: [''],
      provider_ids: ['']
    });
  }

  getInputFormControlValues() {
    if (this.activeTab == 'pending') { //Filter for request
      this.pendingRequestFilterForm.patchValue({
        filterType: 'pending',
        page: this.currentPage
      });
      return this.pendingRequestFilterForm.value;
    } else if (this.activeTab == 'history') {//Filter for history
      this.pendingHistoryFilterForm.patchValue({
        filterType: 'history',
        page: this.currentHistoryPage
      });
      return this.pendingHistoryFilterForm.value;
    } else if (this.activeTab == 'blocked') {//Filter for blocked
      this.pendingBlockedFilterForm.patchValue({
        filterType: 'blocked',
        page: this.currentPage
      });
      return this.pendingBlockedFilterForm.value;
    }
  }
  teacherProfile(teacher: any) {
    const initialState = {
      teacher: teacher
    };
    this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  activeTab = 'pending';

  invites(activeTab: string) {
    this.currentPage = 1
    this.activeTab = activeTab;
    this.ngxService.start();
    this.ngRequestHistoryOnDestroy()
    this.ngListener()
    this.bookingService.pendingRequestPendingList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.pendingData = res.data.pending
        this.obsArray.next(res.data.pending);
        this.teacherLengthLimit = res.data.total_count;
        this.selectAllCheck = false
        this.consumerDetails = [];
        this.hideButton = true
        this.ngxService.stop();
      },
      error: (err: any) => {
        this.toastr.error(err.message)
        console.log(err);
      }
    });
  }

  history(activeTab: string) {
    this.activeTab = activeTab;
    this.currentHistoryPage = 1
    this.ngxService.start();
    this.bookingService.pendingRequestHistoryList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.historyData = res.data.history
        this.obsHistoryArray.next(res.data.history);
        this.teacherHistoryLengthLimit = res.data.total_count;
        this.ngOnDestroy()
        this.ngRequestHistoryListener()
        this.ngxService.stop();
      },
      error: (err: any) => {
        this.toastr.error(err.message)
        console.log(err);
        this.ngxService.stop();
      }
    });

  }
  blocked(activeTab: string) {
    this.activeTab = activeTab;
    this.ngOnDestroy()
    this.ngRequestHistoryOnDestroy()
    this.ngxService.start();
    this.bookingService.pendingRequestBlockedList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.blockedData = res.data.blocked
        this.ngxService.stop();
      },
      error: (err: any) => {
        this.toastr.error(err.message)
        console.log(err);
        this.ngxService.stop();
      }
    });
  }
  selectAll(type: any, event: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.consumerDetails = [];
      this.pendingData.forEach((x: any) => {
        if (x.status != 'GAP') {
          if (checked) {
            x.isChecked = true;
            this.consumerDetails.push(x);
            this.hideButton = false
          } else {
            this.hideButton = true
            x.isChecked = false;
            this.consumerDetails = [];
          }
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.consumerDetails.push(type)
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.consumerDetails = this.consumerDetails.filter(x => x != type);
      }
    }
    this.selectAllCheck = this.pendingData.length == this.consumerDetails.length ? true : false;
    if (this.consumerDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }

  accepetRequestModal(action: any) {
    if (action == 'accept') {
      this.actionType = action
      this.bsModalRef = this.modalService.show(this.acceptRequestModal, { class: 'modal-md', backdrop: 'static' });
    } else if (action == 'decline') {
      this.actionType = action
      this.bsModalRef = this.modalService.show(this.acceptRequestModal, { class: 'modal-md', backdrop: 'static' });
    } else if (action == 'email') {
      this.actionType = action
      this.bsModalRef = this.modalService.show(this.acceptRequestModal, { class: 'modal-md', backdrop: 'static' });
    }

  }

  actionModal(teacher: any, action: any) {
    this.teacher = teacher
    this.teacherName = teacher.teacher.user.full_name
    this.actionType = action
    this.bsModalRef = this.modalService.show(this.actionModals, { class: 'modal-md', backdrop: 'static' });
  }

  mailSend(teacher: any, action: any) {
    this.actionType = action;
    this.submitted = true;
    if ((this.actionType == 'email') && teacher == '' || (this.actionType == 'email')) {
      if (this.actionForm.invalid) {
        return;
      }
    }
    this.ngxService.start();
    if (this.actionType == 'email' && teacher == '') {
      this.actionForm.patchValue({
        provider_ids: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(","))
      });
    }
    this.bookingService.sendMailInvite(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          this.consumerDetails = [];
          this.hideButton = true
          this.getUpdatedPendingList();
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  actions(teacher: any, action: any) {
    this.actionType = action;
    this.submitted = true;
    if ((this.actionType == 'email') && teacher == '' || (this.actionType == 'email')) {
      if (this.actionForm.invalid) {
        return;
      }
    }
    this.ngxService.start();
    if ((this.actionType == 'accept' || this.actionType == 'decline') && teacher == '') {
      this.actionForm.patchValue({
        request_id: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.id; }).join(","))
      });
      // this.actionForm.addControl('request_id', new FormControl(Array.prototype.map.call(this.consumerDetails, function (item) { return item.id; }).join(",")));
    } else if (this.actionType == 'email' && teacher == '') {
      this.actionForm.patchValue({
        provider_ids: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(","))
      });
      // this.actionForm.addControl('provider_ids', new FormControl(Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(",")));  
    } else if (this.actionType == 'email' && teacher != '') {
      this.actionForm.patchValue({
        provider_ids: (teacher.teacher.id).toString()
      });
      // this.actionForm.addControl('provider_ids', new FormControl((teacher.teacher.id).toString()));
    } else {
      this.actionForm.patchValue({
        request_id: (teacher.id).toString()
      });
      // this.actionForm.addControl('request_id', new FormControl((teacher.id).toString()));
    }
    if (this.actionType == 'email') {
      this.bookingService.sendMailInvite(this.getFormControlValues()).subscribe({
        next: (res: any) => {
          if (res.status) {
            this.bsModalRef.hide();
            this.toastr.success(res.message);
            this.consumerDetails = [];
            this.hideButton = true
            this.getUpdatedPendingList();
            this.ngxService.stop();
          } else {
            this.toastr.error(res.message);
            this.ngxService.stop();
          }
        },
        error: (err: any) => {
          this.toastr.error(err.statusText + ', Please contact admin');
          console.log(err);
          this.ngxService.stop();
        }
      })
    } else {
      this.bookingService.requestAction(this.getFormControlValues()).subscribe({
        next: (res: any) => {
          if (res.status) {
            this.bsModalRef.hide();
            this.toastr.success(res.message);
            this.consumerDetails = [];
            this.hideButton = true
            this.getUpdatedPendingList();
            this.ngxService.stop();
          } else {
            this.toastr.error(res.message);
            this.ngxService.stop();
          }
        },
        error: (err: any) => {
          this.toastr.error(err.statusText + ', Please contact admin');
          console.log(err);
          this.ngxService.stop();
        }
      })
    }

  }

  getFormControlValues() {
    if (this.actionType == 'block') {
      this.actionForm.patchValue({
        action: 'block'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'remove') {
      this.actionForm.patchValue({
        action: 'remove'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'unblock') {
      this.actionForm.patchValue({
        action: 'unblock'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'accept') {
      this.actionForm.patchValue({
        action: 'accept'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'decline') {
      this.actionForm.patchValue({
        action: 'decline'
      });
      return this.actionForm.value;
    } else if (this.actionType == 'email') {
      this.actionForm.patchValue({
        action: 'send_message'
      });
      return this.actionForm.value;
    } else {
      return this.declineInviteForm.value;
    }
  }

  declineRequest(teacher: any) {
    this.ngxService.start();
    this.declineInviteForm.addControl('invite_id', new FormControl((teacher.id).toString()));
    this.bookingService.cancelInvite(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          this.consumerDetails = [];
          this.hideButton = true
          this.getUpdatedPendingList();
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.bsModalRef.hide();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
        this.bsModalRef.hide();
      }
    })
  }

  getTeacherAvailabilityModal(teacher_id: any) {
    // this.ngxService.start();
    this.availTeacher = teacher_id
    this.bookingService.getAvailability(this.availTeacher).subscribe({
      next: (res: any) => {
        if (res.status) {
          const initialState = {
            teacher: res.data
          };
          this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.closeBtnName = 'Close';
        } else {
          this.toastr.error(res.message);
        }

      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
      }
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.actionForm.controls;
  }

  resetFilters() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }

  checkLength() {
    let timing = this.actionForm.controls['email_content'].value;
    let charectorLength = this.ShowLenghtError - timing.length
    this.ShowLenght = charectorLength
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
    window.removeEventListener('scroll', this.scrollRequestHistoryPage, true);
  }
  ngListener() {
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }
  ngRequestHistoryOnDestroy() {
    window.removeEventListener('scroll', this.scrollRequestHistoryPage, true);
  }
  ngRequestHistoryListener() {
    window.addEventListener('scroll', this.scrollRequestHistoryPage, true); //third parameter
  }
  getTeacherLength() {
    this.PendingRequestteachersList.subscribe(result => {
      this.totalArrayCount = result.length;
    });
  }
  getHistoryTeacherLength() {
    this.PendingHistoryteachersList.subscribe(result => {
      this.totalArrayHistoryCount = result.length;
    });
  }
  scroll = (event: any): void => {
    this.getTeacherLength();
    let totalPages = Math.ceil(this.teacherLengthLimit / this.totalArrayCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.Paginator(this.totalArrayCount, this.currentPage, 15);
      this.currentPage = activePage + 1;
      if (this.pendingRequestFilterForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.PendingRequestteachersList.pipe(take(1)), this.bookingService.pendingRequestPendingList(this.getInputFormControlValues())]).subscribe((data: any) => {
          this.teacherLengthLimit = data[1].data.total_count;
          const newArr = [...data[0], ...data[1].data.pending];
          this.obsArray.next(newArr);
          this.pendingData = newArr
          this.ngxService.stop();
        });
      }
    }
  };

  scrollRequestHistoryPage = (event: any): void => {
    this.getHistoryTeacherLength();
    let totalPages = Math.ceil(this.teacherHistoryLengthLimit / this.totalArrayHistoryCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.HistoryPaginator(this.totalArrayHistoryCount, this.currentHistoryPage, 15);
      this.currentHistoryPage = activePage + 1;
      if (this.pendingHistoryFilterForm.controls['page'].value != this.currentHistoryPage) {
        this.ngxService.start();
        forkJoin([this.PendingHistoryteachersList.pipe(take(1)), this.bookingService.pendingRequestHistoryList(this.getInputFormControlValues())]).subscribe((data: any) => {
          this.teacherHistoryLengthLimit = data[1].data.total_count;
          const newArr = [...data[0], ...data[1].data.history];
          this.obsHistoryArray.next(newArr);
          this.historyData = newArr
          this.ngxService.stop();
        });
      }
    }
  };
  Paginator(totlallength: any, page: any, per_page: any) {
    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }
  HistoryPaginator(totlallength: any, page: any, per_page: any) {
    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }
}
