<div class="hunandspoke-page pb-30 p-15">
    <div class="row align-items-center mt-10">
        <form [formGroup]="hubSPokeForm">
            <div class="col-md-3 col-sm-6 col-lg-3 mt-10">
                <div class="form-floating  mt-10">
                    <ng-select (change)="filterHubSpoke()" [clearable]="false" [searchable]="false"
                        [dropdownPosition]="'bottom'" [multiple]="false" formControlName="status"
                        aria-label="Default select example">
                        <ng-option value="">All</ng-option>
                        <ng-option value="P">Pending</ng-option>
                        <ng-option value="D">Rejected</ng-option>
                        <ng-option value="A">Accepted</ng-option>
                    </ng-select>
                    <label for="floatingSelect">
                        Request status
                    </label>
                </div>
            </div>
        </form>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <span *ngIf="hubSpokeData?.length > 0">
                <div class="history-header sticky  px-15 md-d-none mt-20">
                    <div class="row align-items-center">
                        <div class="col-lg-3">
                            <p class="text-regular-medium">Requested by & Date</p>
                        </div>
                        <div class="col-lg-2">
                            <p class="text-regular-medium">Booking date & time</p>
                        </div>
                        <div class="col-lg-2">
                            <p class="text-regular-medium">Teacher requested</p>
                        </div>
                        <div class="col-lg-2">
                            <p class="text-regular-medium">Absence reason</p>
                        </div>
                    </div>
                </div>

                <div class="history-description mt-20">

                    <div class="card px-15 mt-10" *ngFor="let teacher of hubSpokeData">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                <p class="text-regular-bold lg-d-none mb-5">Requested by & Date</p>
                                <p class="txt-regular">{{teacher.school.title}}</p>
                                <p class="txt-regular">{{teacher.created_at|date:"MMM d, Y, h:mm a"}}</p>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                <p class="text-regular-bold lg-d-none mb-5">Booking date & time</p>
                                <p class="txt-regular">{{teacher.booking_date}}</p>
                                <p class="txt-regular">{{teacher.booking_time}}</p>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                <p class="text-regular-bold lg-d-none mb-5">Teacher requested</p>
                                <p class="txt-regular">
                                    {{teacher.notification.teacher_name}}
                                </p>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                <p class="text-regular-bold lg-d-none mb-5">Absence reason</p>
                                <div class="reason-for-decline mt-2">
                                    <!-- <div class="d-flex  align-items-center c-p h_b" triggers="mouseenter:mouseleave"
                                        popover="{{teacher.information.reason_for_absence}}">
                                        <i class="bi bi-exclamation-circle-fill fs-16 mr-4 clr-cc-vilot"></i>
                                        <p class="text-regular-medium">Absence Reason</p>
                                    </div> -->
                                    <button class="notesTooltip d-flex  align-items-center c-p h_b">
                                        <span class="text-regular-medium"><i
                                                class="bi bi-exclamation-circle-fill fs-16 mr-4 clr-cc-vilot"></i>
                                            Absence Reason
                                        </span>
                                        <span class="notesTooltiptext text-s-m-g-1">{{
                                            teacher.information.reason_for_absence }}</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6 col-12 actionDiv">
                                <p class="text-regular-bold  lg-d-none mb-5">Action</p>
                                <div class="d-flex place-content-end align-items-center"
                                    *ngIf="teacher.status == 'Pending'">
                                    <a (click)="action(teacher.id , 'decline')" class="text-regular-bold  c-p mr-15"><i
                                            class="bi bi-x-lg fs-14"></i> Decline</a>
                                    <p (click)="action(teacher.id,'accept')"
                                        class="text-regular-bold  clr-cc-vilot c-p"><i class="bi bi-check2 fs-17"></i>
                                        Accept</p>
                                </div>
                                <div class="d-flex place-content-end align-items-center"
                                    *ngIf="teacher.status == 'Booked'">
                                    <a class="text-regular-bold clr-green  c-p mr-15">Accepted</a>
                                </div>
                                <div class="d-flex place-content-end align-items-center"
                                    *ngIf="teacher.status == 'Declined'">
                                    <a class="text-regular-bold clr-red  c-p mr-15">Rejected</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </span>
            <div class="px-15 md-d-none mt-20" *ngIf="hubSpokeData?.length == 0">
                <p class="user-title text-center">No request found. Place a booking request to see it over here.</p>
                <p class="text-center"><button class="btn cc_p_btn fs_13 mt-20">Booking request</button></p>
            </div>
        </div>
    </div>
</div>