<div class="pb-30" [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'">
    <div class="row">
        <div class="col-lg-9 col-md-8 col-sm-12 mt-15">
            <button routerLink="/school/post-job"
                class="btn cc_p_btn w-100 mb-10 mblResTop  pl-30 pr-30 text-regular-bold-w">Post
                a
                new
                job</button>
            <div *ngIf="showRemainingCard" class="card mt-15 mb-15 px-15 mblResTop">
                <div class="row">
                    <div class="col-lg-12">
                        <h4 class="mb-10">Invites remaining for this job</h4>
                        <div class="mt-10">
                            <p class="text_medium clr-cc-vilot fs-30">{{remainingInvites}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <p class="page_title">Headhunter</p>
                <p *ngIf="this.headHunter?.length == 0" class="mt-10 head_des">Post a job, and we'll help by finding
                    teachers that match your job's criteria.
                </p>
                <p *ngIf="this.headHunter?.length > 0 && !showFilters" class="mt-10 head_des">Search for and invite
                    potential candidates who might be a good fit for this job.
                </p>
                <p *ngIf="this.headHunter?.length > 0 && showFilters" class="mt-10 head_des">We’ve matched potential
                    candidates that fit your job description. ​
                </p>
            </div>

            <div *ngIf="this.headHunter?.length > 0" class="">
                <div class="row">
                    <form [formGroup]="allAppJobDetailsFilterForm">
                        <div class="row mt-15">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <ng-select [clearable]="false" (change)="getData()" [dropdownPosition]="'bottom'"
                                    [searchable]="true" [multiple]="false" formControlName="job_id"
                                    placeholder="Select job">
                                    <ng-option *ngFor="let jobs of headHunter" value="{{jobs.id}}">
                                        {{jobs.job_title}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="row mt-10" *ngIf="showFilters && openFilters">
                            <div class="col-lg-4 col-md-4 col-sm-6 mb-10">
                                <div class="">
                                    <input formControlName="filter_by_name" id="search_by_name" type="text"
                                        placeholder="Search by name" class="form-control" aria-label="Search by name">
                                    <!-- <span class="input-group-text" 
                                    for="search_by_name"><i class="bi bi-search"></i></span> -->
                                </div>
                            </div>

                            <div class="col-md-4 col-lg-4 col-12 mb-10">

                                <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                                    class="mb-0" formControlName="job_role_type" aria-label="job_role_type"
                                    placeholder="Select Role type">
                                    <ng-option *ngFor="let level of filtersArray.role_type" [value]="level['id']"
                                        [disabled]="level['is_parent']">
                                        {{level.title}}</ng-option>
                                </ng-select>
                            </div>

                            <div class="col-md-4 col-lg-4 col-12 mb-10">
                                <!-- <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                                    id="level" class="mb-0" formControlName="school_level" aria-label="school_level">
                                    <ng-option *ngFor="let level of filtersArray.school_level" value="{{level.value}}">
                                        {{level.title}}</ng-option>
                                </ng-select> -->
                                <ng-select class="mb-10" [clearable]="false"
                                [items]="filtersArray.school_level" formControlName="school_level" bindLabel="title"
                                bindValue="value" [closeOnSelect]="true" [dropdownPosition]="'bottom'"
                                [multiple]="true" placeholder="Select level" aria-label="school_level">
                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                    <label class="mt-0">Select level</label>
                                </ng-template>
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items;  index as i">
                                        <span class="ng-value-label">{{items[i].title}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </div>
                                </ng-template>
                            </ng-select>
                            </div>

                            <div class="col-md-4 col-lg-4 col-12 mb-10">

                                <ng-select class="" [clearable]="false" [items]="filtersArray.tags"
                                    formControlName="subject" bindLabel="title" bindValue="title" groupBy="tags"
                                    [closeOnSelect]="true" [dropdownPosition]="'bottom'" [multiple]="true"
                                    placeholder="Search by tags">
                                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                                        <label class="mt-0">System tags</label>
                                    </ng-template>
                                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                        <div class="ng-value" *ngFor="let item of items | slice:0:2;  index as i">
                                            <span class="ng-value-label">{{items[i].title}}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                aria-hidden="true">×</span>
                                        </div>
                                        <div class="ng-value" *ngIf="items.length > 2">
                                            <span class="ng-value-label">+ {{items.length - 2}}</span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-md-4 col-lg-4 col-12 mb-10">
                                <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                                    id="level" class="mb-0" formControlName="school_distance" aria-label="school_level">
                                    <ng-option *ngFor="let level of filtersArray.distance_choices"
                                        value="{{level.value}}">
                                        {{level.title}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-4 col-lg-4 col-12 mt-9">
                                <p (click)="getFilterInputs('clearFilter')" class="text-regular-medium h_b c-p f_c"><i
                                        class="bi bi-x-lg"></i> Clear filters</p>
                            </div>
                        </div>
                        <div class="row mt-8" *ngIf="showFilters && !openFilters">
                            <div class="col-lg-12">
                                <p (click)="openFilter()"
                                    class="float-right text-regular-medium c-p fs-14 clr-cc-vilot"><i
                                        class="bi bi-sliders2"></i> Refine filters</p>
                            </div>
                        </div>
                        <div class="row mt-8 mb-8" *ngIf="showFilters && openFilters">
                            <div class="col-lg-6 col-6">
                                <p (click)="openFilter()" class="text-regular-medium h_b fit-content c-p fs-14"><i
                                        class="bi bi-eye-slash"></i> Hide filters</p>
                            </div>
                            <div class="col-lg-6 col-6">
                                <p (click)="getFilterInputs('filterApplied')"
                                    class="float-right text-regular-medium c-p fs-14 clr-cc-vilot">Apply filters</p>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12" *ngIf="showFilters">
                            <div class="form-check">
                                <input class="form-check-input c-p mt-4" [(ngModel)]="isPartialChecked"
                                    (change)="isPartial($event)" [ngModelOptions]="{standalone: true}" type="checkbox"
                                    id="partial-matches">
                                <label class="form-check-label c-p" for="partial-matches">
                                    Show Partial Matches
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="row mt-10" *ngIf="showMatched">
                    <div class="col-md-12">
                        <h4 class="mb-0">Matched teachers</h4>
                    </div>
                </div>
                <div class="" *ngIf="showMatched">
                    <div class="row align-items-center">
                        <div class="col-lg-9 disFlex align-items-center  mt-24">
                            <div class="d-flex align-items-center">
                                <input (change)="setCheckbox($event,'All')" #checkAll [checked]="selectAllCheck"
                                    class="ver_a_sub form-check-input" id="checkAll" type="checkbox">
                                <p class="ml-5  txt-regular-16 mt-2">{{teacherLengthLimit}} matches found</p>
                            </div>
                            <p (click)="openInviteModal('')" class="d-flex align-items-center text_medium  c-p h_b"
                                [ngClass]="hideButton || remainingInvites == '0' ? 'input-cursor-na':''">
                                <i class="bi bi-plus"></i>
                                Invite Selected
                            </p>
                            <p [ngClass]="hideButton ? 'input-cursor-na':''" class="h_b  text_medium c-p"
                                (click)="sendMail();"><i class="bi bi-chat-left mr-2"></i>
                                Message</p>
                        </div>

                        <div class="col-lg-3 mt-24">
                            <div class="d-flex place-content-end">


                                <div class="dropdown">
                                    <p data-bs-toggle="dropdown"
                                        class="ml-10 d-flex dropdown-toggle place-content-end h_b  text_medium c-p">
                                        Sort by <i class="bi bi-filter-left"></i></p>
                                    <ul class="dropdown-menu c-p">
                                        <li><a class="dropdown-item" (click)="sort('lastActive')"
                                                [ngClass]="setActiveActive == true ? 'active' : ''">Last active</a></li>
                                        <li><a class="dropdown-item" (click)="sort('distance')"
                                                [ngClass]="setDistanceActive == true ? 'active' : ''">Distance</a></li>
                                    </ul>
                                </div>
                                <!-- <div class="">{{this.consumerDetails.length}} Selected</div> -->
                            </div>
                        </div>
                    </div>

                    <div class="" *ngIf="FindNewteachersList | async as FindNewlist">
                        <div class="mt-30" *ngIf="FindNewlist?.length > 0">
                            <div class="card px-16 mt-10" *ngFor="let teacher of FindNewlist; index as i">
                                <div class="row">
                                    <div class="col-md-9 col-sm-8 col-lg-9">
                                        <div class="col-md-12 col-lg-12 d-flex">
                                            <div class="pic-and-check">
                                                <input (click)="setCheckbox($event,teacher)"
                                                    [checked]="teacher.isChecked" #checkboxes
                                                    class="form-check-input mr-10 c-p" type="checkbox">
                                            </div>
                                            <div class="pic-and-check ml-0">
                                                <img [src]="teacher.meta.profile_image != null ? teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                    class="profile-picture img-complete-match">
                                            </div>

                                            <div class="pic-and-check ml-10">
                                                <p [ngClass]="!doubleClickDisabled ? '':'ProfileModaldisabled'"
                                                    (click)="getTeacherAvailabilityModal(teacher?.id , 'headhunter',teacher.invite_job_at?.invite_sent ,remainingInvites);"
                                                    class="user-title s_n">
                                                    {{teacher.user.full_name}}</p>
                                                <div>
                                                    <span
                                                        class="user-sub-title pr-5 bod-r">{{teacher.meta.you_are_a}}</span>
                                                    <!-- <span class="divider-right ml-8 mr-4"></span> -->
                                                    <span class="user-sub-title pl-5">{{teacher.distance_from}}
                                                        km</span>
                                                </div>
                                                <p class="user-sub-title">{{teacher.location}}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-sm-4 col-md-3 col-12">
                                        <div class="d-flex justify-content-right">
                                            <p><i (click)="sendMessage(teacher)"
                                                    class="bi bi-chat-left mr-3 clr-cc-vilot c-p text-right"
                                                    [ngClass]="hideButton ? '':'input-cursor-na'"></i>
                                            </p>
                                        </div>
                                        <div class="d-flex justify-content-right">
                                            <button *ngIf="teacher.invite_job_at?.invite_sent"
                                                [ngClass]="this.consumerDetails.length > 0 ? 'input-cursor-na':''"
                                                class=" notesTooltip  c-p clr-green mt-20">
                                                <span class="text_medium c-p">
                                                    <i class="bi bi-check2"></i>
                                                    Invite sent
                                                </span>
                                                <span class="notesTooltiptext text-s-m-g-1">{{ teacher.invite_job_at?.sent_at }}</span>
                                            </button>
                                            <!-- <p *ngIf="teacher.invite_job_at?.invite_sent"
                                                triggers="mouseenter:mouseleave"
                                                popover="{{teacher.invite_job_at?.sent_at}}"
                                                [ngClass]="this.consumerDetails.length > 0 ? 'input-cursor-na':''"
                                                class="text_medium  c-p clr-green mt-20">
                                                <i class="bi bi-check2"></i> Invite sent
                                            </p> -->
                                            <p *ngIf="!teacher.invite_job_at?.invite_sent"
                                                (click)="openInviteModal(teacher)"
                                                [ngClass]="this.consumerDetails.length > 0 || remainingInvites == '0' ? 'input-cursor-na':''"
                                                class="text_medium c-p clr-cc-vilot h_b  mt-20">
                                                <i class="bi bi-send-fill"></i> Invite to job
                                            </p>
                                        </div>
                                        <!-- <p *ngIf="!teacher.invite_job_at?.invite_sent" (click)="inviteToJob(teacher)"
                                            [ngClass]="this.consumerDetails.length > 0 ? 'input-cursor-na':''"
                                            class="text_medium c-p clr-cc-vilot h_b text-right">
                                            <i class="bi bi-send-fill"></i> Invite to job
                                        </p> -->
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="mt-10" *ngIf="this.HeadHunterList?.length == 0 && showFilters">
                    <p class="text-center"><img class="no-result-image"
                            src="assets/images/jobs/job_only_user/postJob.png"></p>
                    <h4 class="text-center">No match found</h4>
                </div>
                <div class="mt-10" *ngIf="this.headHunter?.length != 0 && !showFilters">
                    <p class="text-center"><img class="no-result-image"
                            src="assets/images/jobs/job_only_user/postJob.png"></p>
                    <h4 class="text-center">Select a job post to find and invite potential candidates</h4>
                </div>
            </div>
            <div *ngIf="this.headHunter?.length == 0" class="">
                <div class="mt-30 n_found">
                    <p class="text-center"><img class="no-result-image"
                            src="assets/images/jobs/job_only_user/postJob.png"></p>
                    <p class="text-center"> <button routerLink="/school/post-job"
                            class="btn cc_p_btn  pl-30 pr-30 text-regular-bold-w">Post a
                            new
                            job</button>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-12 mt-30 side_c">
            <button routerLink="/school/post-job" class="btn cc_p_btn w-100  pl-30 pr-30 text-regular-bold-w">Post
                a
                new
                job</button>
            <div *ngIf="showRemainingCard" class="card mt-20 px-15">
                <div class="row">
                    <div class="col-lg-12">
                        <h4 class="mb-10">Invites remaining for this job</h4>
                        <div class="mt-10">
                            <p class="text_medium clr-cc-vilot fs-30">{{remainingInvites}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.recent_applicant?.length > 0" class="card mt-20 px-15">
                <div class="row">
                    <div class="col-lg-12">
                        <h4 class="mb-10">Recent applicants</h4>
                        <div class="mt-10" *ngFor="let applicant of recent_applicant;  index as i">
                            <div class="col-md-12 col-lg-12 d-flex invite-request-preview">
                                <div class="pic-and-check ml-0">
                                    <img alt=""
                                        [src]="(applicant?.profile_image == null || applicant?.profile_image == '') ? '../../../../assets/images/teacher-static-profile.png' : applicant?.profile_image"
                                        class="teacher-picture img-complete-match">
                                </div>

                                <div class="pic-and-check ml-10">
                                    <div class="d-flex">
                                        <p [ngClass]="!doubleClickDisabled ? '':'ProfileModaldisabled'"
                                            (click)="getTeacherAvailabilityModal(applicant?.teacher_id, '','','')"
                                            class="text_medium c-p s_n">
                                            {{applicant?.teacher_name}}
                                        </p>
                                    </div>
                                    <p class="user-sub-title">
                                        <span>{{applicant?.job_title}}
                                        </span>
                                    </p>
                                    <p class="user-sub-title">
                                        <span>{{applicant?.role_type}}
                                        </span>
                                        <span class="divider-right ml-3 mr-5">
                                        </span>
                                        {{applicant?.distance}} km
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #resendPendingInviteModal>
        <form [formGroup]="inviteToJobForm">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Send a custom message with your job invite (optional)
                </h4>
                <button type="button" (click)="closeModal()" class="btn-close" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-0">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <p class="text_medium">You can add a custom message or provide additional context to your
                            invite</p>
                    </div>
                    <div class="col-md-12 mt-5">
                        <textarea class="form-control" placeholder="Type here" formControlName="custom_message"
                            rows="3"></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer mt-10">
                <button type="button" class="btn cc_p_btn f_c fs_13" (click)="inviteToJob(Invitedteacher)">Send
                    invite</button>
            </div>
        </form>
    </ng-template>
</div>