import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

// Libs
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgSelectModule } from '@ng-select/ng-select';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Routes, RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxPaginationModule } from 'ngx-pagination';
// Import ng-circle-progress
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ImageCropperModule } from 'ngx-image-cropper';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
// import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { HammerModule } from '@angular/platform-browser';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgChartsModule } from 'ng2-charts';

import {
  DashboardComponent,
  BookingRequestComponent,
  PreConfirmBookingComponent,
  BookingHistoryComponent,
  PendingRequestComponent,
  PendingInvitesComponent,
  InlineCalendarComponent,
  BookingsInFlightComponent,
  JobsComponent,
} from './';
import { ValidatedDictComponent } from './validated-dict/validated-dict.component';
import { TeacherProfilePopupComponent } from './teacher-profile-popup/teacher-profile-popup.component';
import { FindNewComponent } from './find-new/find-new.component';
import { SchoolCalendarComponent } from './school-calendar/school-calendar.component';
import { BookingConfrimModalComponent } from './modals/booking-confrim-modal/booking-confrim-modal.component';
import { CardProfileViewComponent } from './card-profile-view/card-profile-view.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { MyListComponent } from './my-list/my-list.component';
import { JobDetailsComponent } from './jobs/job-details/job-details.component';
import { PostJobComponent } from './jobs/post-job/post-job.component';
import { AddNotesComponent } from './modals/add-notes/add-notes.component';
import { SendMailComponent } from './modals/send-mail/send-mail.component';
import { SettingsComponent } from './settings/settings.component';
import { ProfileSchoolSettingsComponent } from './settings/profile-school-settings/profile-school-settings.component';
import { AdminSettingsComponent } from './settings/admin-settings/admin-settings.component';
import { EditAdminSettingsComponent } from './settings/admin-settings/edit-admin-settings/edit-admin-settings.component';
import { EditProfileSettingsComponent } from './settings/profile-school-settings/edit-profile-settings/edit-profile-settings.component';
import { SmartGroupsComponent } from './smart-groups/smart-groups.component';
import { CreateSmartGroupComponent } from './modals/create-smart-group/create-smart-group.component';
import { InviteRequestTabComponent } from './invite-request-tab/invite-request-tab.component';
import { JobSubscriptionComponent } from './jobs/job-subscription/job-subscription.component';
import { JobPaymentComponent } from './jobs/job-payment/job-payment.component';
import { JobPaymentHistoryComponent } from './jobs/job-payment-history/job-payment-history.component';
import { JobOnlyUserComponent } from './jobs/job-only-user/job-only-user.component';
import { JobUserComponent } from './jobs/job-user/job-user.component';
import { BoostJobComponent } from './jobs/boost-job/boost-job.component';
import { RoleTypeComponent } from './filters/role-type/role-type.component';
import { LevelsComponent } from './filters/levels/levels.component';
import { SubjectTagsComponent } from './filters/subject-tags/subject-tags.component';
import { DistanceComponent } from './filters/distance/distance.component';
import { NameSearchComponent } from './filters/name-search/name-search.component';
import { HeadhunterComponent } from './jobs/headhunter/headhunter.component';
import { AddNewModalComponent } from './modals/add-new-modal/add-new-modal.component';
import { ManageSmartGroupComponent } from './settings/manage-smart-group/manage-smart-group.component';
import { ManageTeamAccessComponent } from './settings/manage-team-access/manage-team-access.component';
import { HubandspokeComponent } from './hubandspoke/hubandspoke.component';
import { JobSettingsComponent } from './jobs/job-settings/job-settings.component';
import { InsightsComponent } from './reports/insights/insights.component';
import { PayrollReportComponent } from './reports/payroll-report/payroll-report.component';
import { InsightsReportComponent } from './reports/insights-report/insights-report.component';
import { SessionEndsModalComponent } from './modals/session-ends-modal/session-ends-modal.component';
import { OtherJobsPostedComponent } from './other-jobs-posted/other-jobs-posted.component';
import { JobTermsConditionComponent } from './jobs/job-terms-condition/job-terms-condition.component';
import { PaymentProcessModalComponent } from './modals/payment-process-modal/payment-process-modal.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { OnboardingSchoolComponent } from './onboarding-school/onboarding-school.component';
import { OnboardingFindNewComponent } from './onboarding-school/onboarding-find-new/onboarding-find-new.component';
import { CompletedOnboardingComponent } from './onboarding-school/completed-onboarding/completed-onboarding.component';
import { OnboardingTourComponent } from './onboarding-school/onboarding-tour/onboarding-tour.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    DashboardComponent,
    BookingRequestComponent,
    PreConfirmBookingComponent,
    ValidatedDictComponent,
    BookingHistoryComponent,
    PendingRequestComponent,
    PendingInvitesComponent,
    TeacherProfilePopupComponent,
    FindNewComponent,
    InlineCalendarComponent,
    BookingsInFlightComponent,
    SchoolCalendarComponent,
    BookingConfrimModalComponent,
    JobsComponent,
    CardProfileViewComponent,
    HealthCheckComponent,
    MyListComponent,
    JobDetailsComponent,
    PostJobComponent,
    AddNotesComponent,
    SettingsComponent,
    ProfileSchoolSettingsComponent,
    AdminSettingsComponent,
    EditAdminSettingsComponent,
    EditProfileSettingsComponent,
    SendMailComponent,
    SmartGroupsComponent,
    CreateSmartGroupComponent,
    InviteRequestTabComponent,
    JobSubscriptionComponent,
    JobPaymentComponent,
    JobPaymentHistoryComponent,
    JobOnlyUserComponent,
    JobUserComponent,
    BoostJobComponent,
    RoleTypeComponent,
    LevelsComponent,
    SubjectTagsComponent,
    DistanceComponent,
    NameSearchComponent,
    HeadhunterComponent,
    AddNewModalComponent,
    ManageSmartGroupComponent,
    ManageTeamAccessComponent,
    HubandspokeComponent,
    JobSettingsComponent,
    InsightsComponent,
    PayrollReportComponent,
    InsightsReportComponent,
    SessionEndsModalComponent,
    OtherJobsPostedComponent,
    JobTermsConditionComponent,
    PaymentProcessModalComponent,
    OnboardingSchoolComponent,
    OnboardingFindNewComponent,
    CompletedOnboardingComponent,
    OnboardingTourComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    BsDatepickerModule,
    PopoverModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserModule,
    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot(),
    FullCalendarModule,
    SortableModule.forRoot(),
    TimepickerModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgxPaginationModule,
    PaginationModule.forRoot(),
    ImageCropperModule,
    ProgressbarModule.forRoot(),
    HammerModule,
    AccordionModule,
    NgChartsModule,
    CKEditorModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [InlineCalendarComponent]
})
export class ProducerModule { }
