import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subject-tags',
  templateUrl: './subject-tags.component.html',
  styleUrls: ['./subject-tags.component.scss']
})
export class SubjectTagsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
