import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { SchoolData } from 'src/app/models/schoolUser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-teacher-profile-popup',
  templateUrl: './teacher-profile-popup.component.html',
  styleUrls: ['./teacher-profile-popup.component.scss']
})
export class TeacherProfilePopupComponent implements OnInit {
  public schoolProps!: SchoolData;
  title?: string;
  closeBtnName?: string;
  teacher: any;
  href: any;
  from: any;
  invite_sent: any;
  remainingInvites: any
  Invitedteacher: any;
  job_id: any;
  inlineEndDate: any;

  @ViewChild('resendPendingInviteModal') resendPendingInviteModal: any;

  inviteToJobForm: FormGroup = this.fb.group({
    job_id: [''],
    teacher_id: [''],
    custom_message: ['']
  });
  nextPrevForm: FormGroup = this.fb.group({
    providerId: [''],
    dateStart: [''],
    dateTime: ['']
  });
  dateList: any;

  constructor(public bsModalRef: BsModalRef,
    private _router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService) { 
      this.inlineEndDate = moment(new Date()).format('DD/MM/YYYY');
    }

  ngOnInit() {
    this.href = this._router.url.split('?')[0];
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
  }
  openInviteModal(teacher: any) {
    this.bsModalRef.hide()
    this.Invitedteacher = teacher
    this.bsModalRef = this.modalService.show(this.resendPendingInviteModal, { class: 'modal-md', backdrop: 'static' });
  }
  getInviteJobFormControlValue() {
    return this.inviteToJobForm.value
  }

  inviteToJob(teacher: any) {
    this.ngxService.start();
    this.inviteToJobForm.patchValue({
      job_id: this.job_id,
      teacher_id: teacher.id.toString()
    })

    this.bsModalRef.hide();
    this.bookingService.inviteJob(this.getInviteJobFormControlValue()).subscribe({
      next: (res: any) => {
        this.inviteToJobForm.patchValue({
          custom_message: ''
        })
        if (res.status) {
          this.toastr.success(res.message);
          let currentUrl = "school/headhunter";
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl], { queryParams: { jobId: this.job_id } });
          });
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        alert("error")
        console.log(err);
        this.toastr.error(err.message);
        this.ngxService.stop()
      }
    });

  }
 
  public setArrData(data: any): void {
    console.log(data);
  }

}
