<div class="modal-header">
    <h4 class="modal-title" *ngIf="type == 'edit'" id="exampleModalLabel">Edit Smart Group</h4>
    <h4 class="modal-title" *ngIf="type == ''" id="exampleModalLabel">Create a new Smart Group</h4>
    <button type="button" (click)="closeSmartModal()" class="btn-close" aria-label="Close"></button>
</div>

<div class="modal-body pb-0">
    <div class="row  align-items-center">
        <div class="col-md-12 mt-10">
            <p *ngIf="type == ''" class="txt-regular">Sort your list into groups and save time when searching for and
                booking staff.
            </p>
        </div>
    </div>

    <form [formGroup]="smartGroups">
        <div class="row">
            <div class="col-md-12 mt-10">
                <label *ngIf="type == ''">Name your smart group*</label>
                <input type="text" formControlName="name" placeholder="Example: Class 3 List" class="form-control">
            </div>
            <div *ngIf="submitted">
                <span class="show-errors" *ngIf="s['name'].errors?.['required']">* Field should not be empty</span>
            </div>
            <div [ngClass]="!showNewStaffOne ? 'c-p':'input-cursor-na'" class="col-md-12 mt-10">
                <label>Assign this smart group to your classroom teachers</label>
                <ng-select [addTag]="false" placeholder="Type here" [items]="schoolStaffs" bindLabel="full_name"
                    bindValue="id" labelForId="state" [multiple]="true" clearAllText="Clear"
                    formControlName="school_staff">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items; index as i">
                            <span class="ng-value-label">{{
                                items[i].full_name
                                }}</span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div *ngIf="type != 'edit'">
            <div *ngIf="!is_individualDetails" class="row">
                <div class="col-md-12 mt-10">
                    <a class="fs-14 c-p" (click)="addNewAddressGroup()">Can't find the classroom teacher you are
                        searching
                        for? Add them now</a>
                </div>
            </div>
            <div *ngIf="is_individualDetails" class="row">
                <div class="col-md-12 mt-10">
                    <p class="txt-regular">Add new staff member <a class="c-p"
                            (click)="deleteAddressGroup()">(Remove)</a>
                    </p>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-12 mt-10">
                    <input type="text" formControlName="first_name" placeholder="First name" class="form-control">
                    <div *ngIf="submitted">
                        <span class="show-errors" *ngIf="s['first_name'].errors?.['required']">* Field should not be
                            empty</span>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-12 mt-10">
                    <input type="text" formControlName="last_name" placeholder="Last name" class="form-control">
                    <div *ngIf="submitted">
                        <span class="show-errors" *ngIf="s['last_name'].errors?.['required']">* Field should not be
                            empty</span>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-12 mt-10">
                    <input type="text" formControlName="email" placeholder="Email" class="form-control">
                    <div *ngIf="submitted">
                        <span class="show-errors" *ngIf="s['email'].errors?.['required']">* Field should not be
                            empty</span>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-12 mt-10">
                    <input type="text" formControlName="phone" placeholder="Phone Number" class="form-control">
                    <div *ngIf="submitted">
                        <span class="show-errors" *ngIf="s['phone'].errors?.['required']">* Field should not be
                            empty</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="col-md-12 mt-10">
                    <label>Smart group description*</label>
                    <input type="text" formControlName="description" placeholder="Add smart group description here"
                        class="form-control">
                </div>
                <div *ngIf="submitted">
                    <span class="show-errors" *ngIf="s['description'].errors?.['required']">* Field should not be
                        empty</span>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer mt-10">
    <button *ngIf="type == ''" type="button" (click)="submitSmartGroup()" class="btn cc_p_btn fs_13">Create</button>
    <button *ngIf="type == 'edit'" type="button" (click)="submitEditedSmartGroup()"
        class="btn cc_p_btn fs_13">Update</button>
</div>