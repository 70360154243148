import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';

// Services
import { CommonService } from 'src/app/services/api/common.service';
import { ToastrService } from 'ngx-toastr';

// Libraries
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-manage-smart-group',
  templateUrl: './manage-smart-group.component.html',
  styleUrls: ['./manage-smart-group.component.scss']
})
export class ManageSmartGroupComponent implements OnInit {

  smartGroupList: any = []
  staffList: any = [];
  updateStaffId: any;
  checkBoxSelectedTeacher: any = [];
  modalTitle: any;
  @ViewChild('editAdditionalUser') editAdditionalUser: any;
  @ViewChild('RemovestaffConfirmation') RemovestaffConfirmation: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private _router: Router,
  ) { }

  additionalUserForm: FormGroup = this.fb.group({
    first_name: ['', [Validators.required, this.noWhitespaceValidator]],
    last_name: ['', [Validators.required, this.noWhitespaceValidator]],
    email: ['', [Validators.required, this.noWhitespaceValidator]],
    phone: [''],
    smart_group_ids: [''],
  });

  ngOnInit(): void {
    this.commonService.schoolStaff().subscribe({
      next: (res: any) => {
        console.log("schoolStaff", res);
        this.staffList = res?.data;
        this.smartGroupList = res?.smart_group;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  openEditAdditionalUser(staffData: any) {
    console.log(staffData);
    this.modalTitle = "Edit classroom teacher";
    this.additionalUserForm.patchValue({
      first_name: staffData?.first_name,
      last_name: staffData?.last_name,
      email: staffData?.email,
      phone: staffData?.phone,
      smart_group_ids: staffData?.smart_group?.smartgroup.map(function (value: any) {
        return value.id;
      })
    });
    this.updateStaffId = staffData?.id;
    this.bsModalRef = this.modalService.show(this.editAdditionalUser, { class: 'modal-lg', backdrop: 'static' });
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }

  // Additional User CRED start

  openaddAdditionalUser() {
    this.additionalUserForm.reset();
    this.modalTitle = "Add classroom teacher";
    this.bsModalRef = this.modalService.show(this.editAdditionalUser, { class: 'modal-lg', backdrop: 'static' });
  }

  openDeleteAdditionalUser() {
    this.bsModalRef = this.modalService.show(this.RemovestaffConfirmation, { class: 'modal-md', backdrop: 'static' });
  }

  updateAdditionalUser() {
    if (this.additionalUserForm.invalid) {
      return;
    }
    this.ngxService.start();
    if (this.additionalUserForm.controls['smart_group_ids'].value != null) {
      let staffIds = this.additionalUserForm.controls['smart_group_ids'].value.map(function (value: any) {
        return value;
      });
      this.additionalUserForm.patchValue({
        smart_group_ids: staffIds.join(",")
      });
    }


    this.commonService.updateSchoolStaff(this.additionalUserForm.value, this.updateStaffId).subscribe({
      next: (res: any) => {
        console.log("success", res);
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }

  saveAdditionalUser() {
    if (this.additionalUserForm.invalid) {
      return;
    }
    this.ngxService.start();
    if (this.additionalUserForm.controls['smart_group_ids'].value != null) {
      let staffIds = this.additionalUserForm.controls['smart_group_ids'].value.map(function (value: any) {
        return value;
      });
      this.additionalUserForm.patchValue({
        smart_group_ids: staffIds.join(",")
      });
    }
    this.commonService.createSchoolStaff(this.additionalUserForm.value).subscribe({
      next: (res: any) => {
        console.log("success", res);
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message);
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }

  deleteAdditionalUser() {
    this.ngxService.start();
    let staffIds = this.checkBoxSelectedTeacher.map(function (value: any) {
      return value.id;
    });
    let deletedStaff = {
      staff_id: staffIds.join(",")
    }
    this.commonService.deleteSchoolStaff(deletedStaff).subscribe({
      next: (res: any) => {
        console.log("success", res);
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message);
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }

  // set checkbox selected teachers
  setCheckbox(event: any, index: number) {
    if (!this.checkBoxSelectedTeacher.includes(event)) {
      this.checkBoxSelectedTeacher = [...this.checkBoxSelectedTeacher, event];
    } else {
      this.checkBoxSelectedTeacher = this.checkBoxSelectedTeacher.filter((item: any) => item !== event);
    }
    console.log(this.checkBoxSelectedTeacher)
  }

}
