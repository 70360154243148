<!-- <div *ngIf="this.access_token == ''" class="container login-container jobs_only_user">
<div class="row flex-res">
  <div class="col-md-6 ads r_simage">
    <p class="join_hub"><span class="hub">The ultimate hub</span> for jobs and bookings in education</p>
  </div>
  <div class="col-md-6  card">
    <div class="">
      <div class="register-form">
        <div class="px-0 pt-5 mb-4 ">

          <p class="text-center">
            <img alt="" class="r_image" src="assets/images/side-nav/cc_original@2x.png">
          </p>
          <p class="text-center mt-20">
            Login to classCover
          </p>

          <div class="col-lg-12 mt-5 text-center">
            <div class="">
              <form [formGroup]="loginForm" (ngSubmit)="logIn()" novalidate class="text-center">

                <input type="text" id="userName" class="form-control input-sm mt-20 chat-input"
                  formControlName="username" placeholder="username" />
                  <br />
                  <input type="text" id="userPassword" class="form-control input-sm chat-input"
                    formControlName="password" placeholder="password" />
                    <br />
                    <div class="mt-5">
                      <button type="submit" class="btn w-100 cc_p_btn fs_13">Login</button>
                    </div>
                    <hr class="mt-25">
                    <div *ngIf="mandatoryMessage" class="warningMessage text-center text-danger mt-2">
                      Please Enter User Name and Password.
                    </div>
                    <div *ngIf="validationMessage" class="warningMessage text-center text-danger mt-2">
                      Invalid User Name or Password.
                    </div>
                  </form>
                </div>
                <p  class="mt-10 txt-regular clr-grey-3 c-p">New to ClassCover? <a href="/register" class="text-regular-medium clr-cc-vilot c-p">Click here to register</a></p>

                <div class="col-lg-12 mt-5 text-center">
                  <p class="text-regular-medium"> or</p>
                </div>
                <p class="txt-regular clr-grey-3 c-p mt-20">Looking for work? <a
                  href="https://phoenix.classcover.com.au/en/teacher/register/"
                class="text-regular-medium clr-cc-vilot c-p"> Create a profile</a></p>
              </div>

              <div class="col-lg-12 mt-20 text-center">
                <p class="txt-regular clr-grey-3 c-p">Looking to hire<a
                  href="https://www.classcover.com.au/lets-make-sure-you-are-in-the-right-place/"
                class="text-regular-medium clr-cc-vilot c-p"> Get started</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  @if (this.access_token == '') {
    <div class="container">
      <div class="row align-items-center flex-res r-form">
        <div class="offset-md-1  col-md-5 p-0 rounded-0 border-0 login_background_image">
          <div class="d-flex align-items-center justify-content-center position-relative">
            <img alt="" class="img-fluid w-100" src="../../../../assets/images/login/login_img.png">
            <div class="position-absolute w-100 card-text-white">
              <div class="card-body  login-content">
                <p class="join_hub"><span class="hub">The ultimate hub</span> for jobs and bookings in education
              </p>
              <div class="d-flex mt-20">
                <p class="txt-regular class-txt">New to ClassCover?</p>
                <a class="txt-regular ml-10 clr-cc-vilot"
                  href="https://www.classcover.com.au/lets-make-sure-you-are-in-the-right-place/">
                  Get started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-md-5">
        <div class="">
          <div class="register-form">
            <div class="px-0 pt-5 mb-4 ">
              <p class="text-center">
                <img alt="" class="r_image" src="assets/images/side-nav/cc_original@2x.png">
              </p>
              <p class="text-center mt-10">
                Login to classCover
              </p>
              <div class="col-lg-12 mt-20 text-center">
                <div class="">
                  <form [formGroup]="loginForm" (ngSubmit)="logIn()" novalidate class="text-center">
                    <input type="text" id="userName" class="form-control input-sm mt-20 chat-input"
                      formControlName="username" placeholder="username" />
                      <br />
                      <input type="password" id="userPassword" class="form-control input-sm chat-input"
                        formControlName="password" placeholder="password" />
                        <br />
                        <div class="mt-5">
                          <button type="submit" class="btn w-100 cc_p_btn fs_13">Login</button>
                        </div>
                        <hr class="mt-25">
                        @if (mandatoryMessage) {
                          <div class="warningMessage text-center text-danger mt-2">
                            Please Enter User Name and Password.
                          </div>
                        }
                        @if (validationMessage) {
                          <div class="warningMessage text-center text-danger mt-2">
                            Invalid User Name or Password.
                          </div>
                        }
                      </form>
                    </div>
                    <p class="mt-10 txt-regular clr-grey-3 c-p">New to ClassCover? <a href="/register"
                    class="text-regular-medium clr-cc-vilot c-p">Click here to register</a></p>
                    <div class="col-lg-12 mt-5 text-center">
                      <p class="text-regular-medium"> or</p>
                    </div>
                    <p class="txt-regular clr-grey-3 c-p mt-20">Looking for work? <a
                      href="{{environmentURL}}/en/teacher/register/"
                    class="text-regular-medium clr-cc-vilot c-p"> Create a profile</a></p>
                  </div>
                  <div class="col-lg-12 mt-20 text-center">
                    <p class="txt-regular clr-grey-3 c-p">Looking to hire<a
                      href="https://www.classcover.com.au/lets-make-sure-you-are-in-the-right-place/"
                    class="text-regular-medium clr-cc-vilot c-p"> Get started</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }