<div class="pb-30 pt-15">
    <p class="page_title">Payroll report</p>
    <div class="row mt-10">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="row mt-10">
                <div class="col-md-6 col-lg-6 col-sm-6">
                    <div class="form-floating mb-3">
                        <input type="text" placeholder="Select date" class="form-control" bsDatepicker
                            [(bsValue)]="this.fromDate"
                            [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-red', showWeekNumbers:false,adaptivePosition: true}"
                            [daysDisabled]="[6,0]">
                        <label for="floatingInput">Start date</label>
                    </div>

                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="form-floating mb-3">
                        <input type="text" placeholder="Select date" class="form-control" bsDatepicker
                            [(bsValue)]="this.toDate"
                            [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-red', showWeekNumbers:false,adaptivePosition: true}"
                            [daysDisabled]="[6,0]">
                        <label for="floatingInput">End date</label>
                    </div>

                </div>
              
            </div>

            <p class="text-r-18 mt-20 text-center">Select the date range to download the payroll report </p>
            <p class="text-center">
                <img class="downloadReport" src="assets/images/NoResultFound/preConfirm/addCasuals.png"
                    alt="No matches for the applied filter!">
            </p>
            <p class="text-center"><button (click)="getInsightsData('')" class="btn fs-13 cc_p_btn">Download report</button></p>
        </div>
    </div>
</div>