<div class="bookings-in-flight-page pb-30 pt-15">
    <div class="row">
        <div class="col-lg-9 col-md-12">
            <!-- <p class="txt-regular">You are looking to book <strong>{{data?.teacher_count}} teacher</strong> for the <strong>full
                    day</strong> on <strong>{{data?.start_date|date:"MMMM d, Y"}} - {{data?.end_date|date:"MMMM d, Y"}}.</strong></p> -->
            <p *ngIf="!data?.is_closed  && data?.status != 'B'" class="ul-title-medium">Your booking is in progress...
            </p>
            <p *ngIf="data?.is_closed && data?.status == 'B'" class="ul-title-medium">Your booking was
                successful!</p>
            <p *ngIf="data?.is_closed && data?.status == 'X'" class="ul-title-medium">Your booking is
                partially filled</p>
            <p *ngIf="data?.is_closed && data?.status == 'U'" class="ul-title-medium">Your booking is
                unfilled</p>
        </div>
        <div class="col-lg-3 col-md-12">
            <div *ngIf="data?.meta.is_add_more_teacher" class="stop-booking c-p" (click)="cancelReason()">
                <!-- <p class="status-count"></p> -->
                <p class="clr-cc-vilot text-right t-3"><i class="bi bi-stop-circle"></i> Stop booking</p>
            </div>
            <!-- <div *ngIf="data?.is_closed">
                <div *ngIf="data?.status != 'C'" class="stop-booking c-p">
                    <p class="btn cc_p_btn fs_13">Reopen booking</p>
                </div>
            </div> -->

            <!-- <p *ngIf="!data?.is_closed && data?.status != 'U' && data?.status != 'C'" class="text-regular-bold float-right c-p h_b" (click)="addMoreStaff(data.id)"><a>Add more staff</a></p> -->
        </div>
       
        <div class="col-lg-12 col-md-12 mt-10">
            <div class="card px-15 if_det">
                <div class="row align-items-center">
                    <div class="col-lg-9">
                        <div class="row">
                            <div class="col-lg-3  mb-10">
                                <p class="small">Started</p>
                                <p class="text-regular-bold">{{data?.start_date|date:"d MMMM, Y"}} ,
                                    {{data?.booking_start_time}}</p>
                            </div>
                            <div class="col-lg-3  mb-10">
                                <p class="small">Ended</p>
                                <p class="text-regular-bold">{{data?.end_date|date:"d MMMM, Y"}} ,
                                    {{data?.booking_end_time}}</p>
                            </div>
                            <div class="col-lg-3  mb-10">
                                <p class="small">Number of teacher(S)</p>
                                <p class="text-regular-bold">{{data?.teacher_count}}</p>
                            </div>
                            <div class="col-lg-3  mb-10">
                                <p class="small">Role type</p>
                                <p class="text-regular-bold">{{data?.booking_role_type.tag}}</p>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-lg-3  mb-10">
                                <p class="small">CLASS BEING COVERED</p>
                                <p *ngIf="data?.information.class_being_covered != ''" class="text-regular-bold">
                                    {{data?.information.class_being_covered}}</p>
                            </div>
                            <div class="col-lg-3   mb-10">
                                <p class="small">TEACHER BEING COVERED</p>
                                <p *ngIf="data?.information.provider_being_covered != ''" class="text-regular-bold">
                                    {{data?.information.provider_being_covered}}</p>
                            </div>
                            <div class="col-lg-3  mb-10" *ngIf="data?.sending_to != ''">
                                <p class="small">SENDING TO</p>
                                <p class="text-regular-bold">{{data?.sending_to}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <button *ngIf="data?.meta.is_add_more_teacher" class="btn cc_p_btn fs_13 fs_13"
                            (click)="addMoreStaff(data.id)">Add more teachers</button>
                        <div *ngIf="data?.meta.is_clone_booking">
                            <button (click)="cloneBooking(data.id)" class="btn cc_p_btn fs_13 pl-20 pr-20">Create a new
                                booking</button>
                        </div>
                        <div *ngIf="data?.meta.is_reopen_booking">
                            <button (click)="reOpenBooking(data.id)" class="btn cc_p_btn fs_13 pl-20 pr-20">Reopen
                                booking</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-6 mt-12">
          
        </div>
        <div class="col-lg-6">
           
        </div> -->
        <div class="col-lg-8  mt-20  pr-20 in-flight-status">
            <div class="booked-status">
                <p class="status-count clr-green">{{booked_status.length}}</p>
                <p class="txt-regular">BOOKED</p>
            </div>
            <div class="declined-status">
                <p class="status-count clr-blue">{{accepted_status.length}}</p>
                <p class="txt-regular">ACCEPTED</p>
            </div>
            <div class="declined-status">
                <p class="status-count clr-red">{{declined_status.length}}</p>
                <p class="txt-regular">DECLINED</p>
            </div>

            <div class="invite-sent-status" *ngIf="!data?.is_closed">
                <p class="text-r-g-3">
                    <span class="text-regular-bold">
                        {{data?.pending_teachers}}/{{data?.total_teachers}}
                    </span> Invite sent
                </p>
                <div class="progress mt-9">
                    <div class="progress-bar bg-success" role="progressbar" style="{{stylePercentage}}"
                        aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p *ngIf="data?.strategy == 'One at a Time' && stylePercentage != '100'" class="txt-regular mt-9">Next
                    invite in {{data?.delay_time}} min</p>
            </div>
            <div class="invite-sent-status" *ngIf="data?.is_closed">
                <p class="text-r-g-3">
                    <span class="text-regular-bold">
                        {{data?.booked_teacher_count}}/{{data?.teacher_count}}
                    </span> Staff booked
                </p>
                <div class="progress mt-9">
                    <div class="progress-bar"
                        [ngClass]="data?.booking_pending_count == '0' ? 'bg-success' : 'bg-warning'" role="progressbar"
                        style="{{stylePercentage}}"></div>
                </div>
            </div>
        </div>
       
        <div class="col-lg-4 pl-20  mt-20  in-flight-status-2">
            <div class="booking-details">
                <!-- <p class="text-regular-bold">Booking Details:</p> -->
                <p class="text-r-g-3">Request type:
                    <span class="text-regular-medium" *ngIf="data?.strategy == 'PRE'">
                        Pre-Confirmed Booking
                    </span>
                    <span class="text-regular-medium" *ngIf="data?.strategy == 'OAT'">
                        One at a time
                    </span>
                    <span class="text-regular-medium" *ngIf="data?.strategy == 'AAO'">
                        All at once
                    </span>
                </p>
                <div *ngIf="data?.strategy != 'PRE'">
                    <p class="text-r-g-3">Delay
                        between request: <span class="text-regular-medium">{{data?.delay_time}} min</span></p>

                    <p *ngIf="data?.send_backup_list && !data?.backup_list_sent" class="text-r-g-3">Send to backup list
                        after: <span class="text-regular-medium">{{(data?.meta.update_timetolive)/60}} min</span></p>
                    <p *ngIf="(data?.send_backup_list == false || data?.backup_list_sent == true) && data?.send_emergency_list && !data?.emergency_list_sent "
                        class="text-r-g-3">Send to emergency list after: <span
                            class="text-regular-medium">{{(data?.meta.emergency_timetolive)/60}} min</span></p>
                    <p *ngIf="!(data?.send_backup_list && !data?.backup_list_sent) && !(data?.send_emergency_list && !data?.emergency_list_sent)"
                        class="text-r-g-3">Close booking after: <span
                            class="text-regular-medium">{{(data?.time_to_live)/60}} min</span></p>
                    <p class="text-r-g-3">Delay send: <span class="text-regular-medium"
                            *ngIf="data?.is_scheduled == false">Off</span> <span class="text-regular-medium"
                            *ngIf="data?.is_scheduled == true">On</span></p>
                </div>
            </div>


            <!-- <div class="booking-details" *ngIf="data?.is_closed">
                <p class="text-regular-bold">Booking Details:</p>
                <p class="text-r-g-3">Started: <span class="text-regular-medium">{{data?.created_at|date:"d MMMM, Y,h:mm a"}}</span></p>
                <p class="text-r-g-3">Ended: <span class="text-regular-medium">{{data?.booking_expiry_time|date:"d MMMM,Y, h:mm a"}}</span></p>
            </div> -->
        </div>
    </div>
    <div class="col-lg-12 col-md-12 mt-10 d-flex place-content-end">
        <p (click)="refreshPage()" class="text-regular-medium clr-cc-vilot c-p hvr_btn"><i class="bi bi-arrow-clockwise"></i> Refresh</p>
    </div>

    <div class="row mt-20">
        <div class="col-md-12">
            <div class="accordion p-0" id="accordionExample">
                <div class="accordion-item mt-15">

                    <div class="row">
                        <div class="col-lg-4">
                            <h2 class="accordion-header accepted_tab" id="headingOne">
                                <button *ngIf="booked_status.length > 0" class="accordion-button" type="button">
                                    Booked ({{booked_status.length}})
                                </button>
                            </h2>
                        </div>

                        <div class="col-lg-3">
                            <p class="text-regular-medium md-d-none">Contact</p>
                        </div>
                        <div class="col-lg-2">
                            <p class="text-regular-medium md-d-none">Invite status</p>
                        </div>
                        <div class="col-lg-3">
                            <p class="text-regular-medium md-d-none">Status</p>
                        </div>
                    </div>
                    <div>
                        <div class="accordion-body mt-20" *ngIf="booked_status.length > 0">
                            <div class="history-description">
                                <span *ngFor="let status of booked_status">
                                    <div class="card px-15 mt-10">
                                        <div class="row align-items-center">
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="d-flex align-items-center">
                                                    <div class="pic-and-check ml-0">
                                                        <img alt=""
                                                            [src]="'../../../../assets/images/teacher-static-profile.png'"
                                                            class="profile-picture img-complete-match">
                                                    </div>

                                                    <div class="pic-and-check ml-10">
                                                        <p class="d-flex align-items-center">
                                                            <span class="s_n"
                                                                (click)="getTeacherAvailabilityModal(status.teacher.id);">{{status.teacher.user.full_name}}</span>
                                                            <app-validated-dict
                                                                [validateDict]="status.teacher.validated_dict">
                                                            </app-validated-dict>
                                                            <span *ngIf="status?.request_type == 'Backup list'" class="bl_badge">{{status?.request_type}}</span>
                                                            <span *ngIf="status?.request_type == 'Emergency list'" class="em_badge">{{status?.request_type}}</span>                                                        </p>
                                                        <p class="user-sub-title">
                                                            <span *ngIf="status?.teacher?.meta.you_are_a">
                                                                {{status?.teacher?.meta.you_are_a}}
                                                            </span>
                                                            <span *ngIf="status?.teacher?.meta.you_are_a"
                                                                class="divider-right ml-3 mr-5">
                                                            </span>
                                                            <span>
                                                                Active {{status?.teacher?.user.previous_login}}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Phone</p>
                                                <p class="txt-regular">{{status.teacher.phone}}</p>
                                            </div> -->
                                            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Contact</p>
                                                <p class="txt-regular mt-5">{{status.teacher.user.email}}</p>
                                                <p class="txt-regular mt-5">{{status.teacher.phone}}</p>
                                            </div>
                                            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Invite status</p>
                                                <p class="txt-regular mt-5">Sent at {{status.sent_at}}</p>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Status</p>
                                                <p class="user-title clr-green">Booked</p>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="accordion-body mt-20" *ngIf="booked_status.length == 0">
                            <p class="text-regular-medium text-center">No bookings found</p>
                        </div> -->
                    </div>
                </div>
                <div class="accordion-item mt-15">

                    <div class="row">
                        <div class="col-lg-4">
                            <h2 class="accordion-header accepted_tab" id="headingOne">
                                <button *ngIf="accepted_status.length > 0" class="accordion-button" type="button">
                                    Accepted ({{accepted_status.length}})
                                </button>
                            </h2>
                        </div>
                    </div>
                    <div>
                        <div class="accordion-body mt-20" *ngIf="accepted_status.length > 0">
                            <div class="history-description">
                                <span *ngFor="let status of accepted_status">
                                    <div class="card px-15 mt-10">
                                        <div class="row align-items-center">
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="d-flex align-items-center">
                                                    <div class="pic-and-check ml-0">
                                                        <img alt=""
                                                            [src]="'../../../../assets/images/teacher-static-profile.png'"
                                                            class="profile-picture img-complete-match">
                                                    </div>

                                                    <div class="pic-and-check ml-10">
                                                        <p class="d-flex align-items-center">
                                                            <span class="s_n"
                                                                (click)="getTeacherAvailabilityModal(status.teacher.id);">{{status.teacher.user.full_name}}</span>
                                                            <app-validated-dict
                                                                [validateDict]="status.teacher.validated_dict">
                                                            </app-validated-dict>
                                                            <span *ngIf="status?.request_type == 'Backup list'" class="bl_badge">{{status?.request_type}}</span>
                                                            <span *ngIf="status?.request_type == 'Emergency list'" class="em_badge">{{status?.request_type}}</span>
                                                            <!-- <span *ngIf="status?.request_type == ''" class="bl_badge">{{status?.request_type}}</span> -->
                                                        </p>
                                                        <p class="user-sub-title">
                                                            <span *ngIf="status?.teacher?.meta.you_are_a">
                                                                {{status?.teacher?.meta.you_are_a}}
                                                            </span>
                                                            <span *ngIf="status?.teacher?.meta.you_are_a"
                                                                class="divider-right ml-3 mr-5">
                                                            </span>
                                                            <span>
                                                                Active {{status?.teacher?.user.previous_login}}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Phone</p>
                                                <p class="txt-regular">{{status.teacher.phone}}</p>
                                            </div> -->
                                            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Contact</p>
                                                <p class="txt-regular mt-5">{{status.teacher.user.email}}</p>
                                                <p class="txt-regular mt-5">{{status.teacher.phone}}</p>
                                            </div>
                                            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Invite status</p>
                                                <p class="txt-regular mt-5">Sent at {{status.sent_at}}</p>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Status</p>
                                                <p class="user-title clr-blue">Accepted (Not booked)</p>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="accordion-body mt-20" *ngIf="accepted_status.length == 0">
                            <p class="text-regular-medium text-center">No accepted invites</p>
                        </div> -->
                    </div>
                </div>
                <div class="accordion-item mt-20">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button" (click)="toggleDisplayPending()">
                            Pending ({{pending_status.length}})
                        </button>
                    </h2>
                    <div>
                        <div class="accordion-body mt-20" *ngIf="pending_status.length > 0">
                            <div class="history-description">
                                <span *ngFor="let status of pending_status">
                                    <div class="card px-15 mt-10">
                                        <div class="row align-items-center">
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="d-flex align-items-center">
                                                    <div class="pic-and-check ml-0">
                                                        <img alt=""
                                                            [src]="'../../../../assets/images/teacher-static-profile.png'"
                                                            class="profile-picture img-complete-match">
                                                    </div>

                                                    <div class="pic-and-check ml-10">
                                                        <p class="d-flex align-items-center">
                                                            <span class="s_n"
                                                                (click)="getTeacherAvailabilityModal(status.teacher.id);">{{status.teacher.user.full_name}}</span>
                                                            <app-validated-dict
                                                                [validateDict]="status.teacher.validated_dict">
                                                            </app-validated-dict>
                                                            <span *ngIf="status?.request_type == 'Backup list'" class="bl_badge">{{status?.request_type}}</span>
                                                            <span *ngIf="status?.request_type == 'Emergency list'" class="em_badge">{{status?.request_type}}</span>                                                        </p>

                                                        <p class="user-sub-title">
                                                            <span *ngIf="status?.teacher?.meta.you_are_a">
                                                                {{status?.teacher?.meta.you_are_a}}
                                                            </span>
                                                            <span *ngIf="status?.teacher?.meta.you_are_a"
                                                                class="divider-right ml-3 mr-5">
                                                            </span>
                                                            <span>
                                                                Active {{status?.teacher?.user.previous_login}}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Phone</p>
                                                <p class="txt-regular">{{status.teacher.phone}}</p>
                                            </div> -->
                                            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Contact</p>
                                                <p class="txt-regular mt-5">{{status.teacher.user.email}}</p>
                                                <p class="txt-regular mt-5">{{status.teacher.phone}}</p>
                                            </div>
                                            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Invite status</p>
                                                <p class="txt-regular mt-5">Sent at {{status.sent_at}}</p>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Status</p>
                                                <p class="user-title clr-orange">Pending <span
                                                        *ngIf="status.status == 'Y'">(Did not sent)</span><span
                                                        *ngIf="status.status == 'S'">(Notified)</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="accordion-body mt-20" *ngIf="pending_status.length == 0">
                            <p class="text-regular-medium text-center">No pending invites</p>
                        </div>
                    </div>
                </div>
                <!-- <hr> -->
                <div class="accordion-item mt-20">
                    <h2 class="accordion-header" id="headingThree">
                        <button *ngIf="declined_status.length > 0" class="accordion-button"
                            (click)="toggleDisplayDeclined()">
                            Declined ({{declined_status.length}})
                        </button>
                    </h2>
                    <div>
                        <div class="accordion-body mt-20" *ngIf="declined_status.length > 0">
                            <div class="history-description">
                                <span *ngFor="let status of declined_status">
                                    <div class="card px-15 mt-10">
                                        <div class="row align-items-center">
                                            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="d-flex align-items-center">
                                                    <div class="pic-and-check ml-0">
                                                        <img alt=""
                                                            [src]="status.teacher.meta.profile_image != null ? status.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                            class="profile-picture img-complete-match">
                                                    </div>

                                                    <div class="pic-and-check ml-10">
                                                        <p class="d-flex align-items-center">
                                                            <span class="s_n"
                                                                (click)="getTeacherAvailabilityModal(status.teacher.id);">{{status.teacher.user.full_name}}</span>
                                                            <app-validated-dict
                                                                [validateDict]="status.teacher.validated_dict">
                                                            </app-validated-dict>
                                                            <span *ngIf="status?.request_type == 'Backup list'" class="bl_badge">{{status?.request_type}}</span>
                                                            <span *ngIf="status?.request_type == 'Emergency list'" class="em_badge">{{status?.request_type}}</span>                                                        </p>
                                                        <p class="user-sub-title">
                                                            <span *ngIf="status?.teacher?.meta.you_are_a">
                                                                {{status?.teacher?.meta.you_are_a}}
                                                            </span>
                                                            <span *ngIf="status?.teacher?.meta.you_are_a"
                                                                class="divider-right ml-3 mr-5">
                                                            </span>
                                                            <span>
                                                                Active {{status?.teacher?.user.previous_login}}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Phone</p>
                                                <p class="txt-regular">{{status.teacher.phone}}</p>
                                            </div> -->
                                            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Contact</p>
                                                <p class="txt-regular mt-5">{{status.teacher.user.email}}</p>
                                                <p class="txt-regular mt-5">{{status.teacher.phone}}</p>
                                            </div>
                                            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Invite status</p>
                                                <p class="txt-regular mt-5">Sent at {{status.sent_at}}
                                                </p>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                                                <p class="text-regular-bold lg-d-none mb-5">Status</p>
                                                <p class="user-title clr-red">Declined</p>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="accordion-body mt-20" *ngIf="declined_status.length == 0">
                            <p class="text-regular-medium text-center">No declined invites</p>
                        </div> -->
                    </div>
                </div>
                <!-- <hr> -->
            </div>
        </div>
    </div>
</div>
<ng-template #cancelReasonModal>
    <form [formGroup]="cancelBookingRequestForm">
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">Stop booking</h4>
            <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row  align-items-center">
                <div class="col-md-12">
                    <p class="user-title-m">Are you sure you want to stop this request?</p>
                    <label for="exampleFormControlTextarea1" class="form-label user-title-m mt-5">Notify those informed
                        of why you're stopping this request *</label>
                    <textarea class="form-control mt-5" (keyup)="checkLength()" maxlength="280"
                        formControlName="cancel_message" placeholder="Add Message here..."
                        id="exampleFormControlTextarea1" rows="6"></textarea>
                    <p class="txt-regular mt-10">Max({{this.ShowLenght}} characters)</p>

                    <div *ngIf="submitted">
                        <span class="show-errors"
                            *ngIf="f['cancel_message'].errors?.['required']  || f['cancel_message'].errors?.['whitespace']">*
                            Message is
                            required</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <!-- <button type="button"  (click)="bsModalRef.hide()" class="btn_cc_back">Back</button> -->
            <button type="button" (click)="cancelBookingRequest()" class="btn cc_p_btn fs_13">confirm</button>
        </div>
    </form>
</ng-template>