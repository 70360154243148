import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { CreateSmartGroupComponent } from '../modals/create-smart-group/create-smart-group.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SendMailComponent } from '../modals/send-mail/send-mail.component';
import { TeacherProfilePopupComponent } from '../teacher-profile-popup/teacher-profile-popup.component';
import { MixpanelService } from 'src/app/mixpanel.service';

@Component({
  selector: 'app-smart-groups',
  templateUrl: './smart-groups.component.html',
  styleUrls: ['./smart-groups.component.scss']
})
export class SmartGroupsComponent implements OnInit {
  isOpen = false;
  checkIsOpen:boolean = false
  teacherLengthLimit: any;
  smartGroupList: any;
  currentPage: number = 1;
  smart_group: any;
  groupId: any;
  openPanel: boolean = false;
  checkBoxSelectedTeacher: any = [];
  @ViewChild('sendEmailModal') sendEmailModal: any;
  @ViewChild('moveSatffsModal') moveSatffsModal: any;
  @ViewChild('RemoveStaffsModal') RemoveStaffsModal: any;
  @ViewChild('DeleteGroupModal') DeleteGroupModal: any;

  public sendEmailForm: any

  activeTab = 'preferred';
  @ViewChild('createSmartGroupModal') createSmartGroupModal: any;
  schoolStaffs: any;
  searchNameForm: FormGroup = this.fb.group({
    filter_group_name: [''],
  });
  myListFilterForm: FormGroup = this.fb.group({
    group_id: [''],
  });
  moveToForm: FormGroup = this.fb.group({
    teacher_ids: [''],
    group_id: [''],
    action: ['']
  });
  backUplist: any;
  preferredList: any;
  openedGroupId: any;
  totalListLength: any;
  consumerDetails: any = [];
  action: any;
  multi: any;
  id: any;
  filter_group_name: any;
  teacher_id: any;
  hideButton: boolean = true;
  availTeacher: any;
  filter_list: any;
  UpdatesmartGroupList: any;
  openCollapseFind: any = [false];
  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.formInit();
    this.bookingService.getSmartGroups(this.currentPage).subscribe({
      next: (res: any) => {
        console.log("hi", res);
        this.smartGroupList = res.data.smart_group;
        this.UpdatesmartGroupList = res.data.smart_group;
        this.schoolStaffs = res.data.school_staff;
        this.filter_list = res.data.filter_list;;
        this.mixpanelService.smartGroupPage(this.smartGroupList.length);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  formInit() {
    this.sendEmailForm = this.fb.group({
      email_content: ['', [Validators.required, Validators.maxLength(280)]],
      provider_ids: ['']
    });
  }

  getFilterFormControlValues() {
    return this.searchNameForm.value;
  }

  filterNameSearch() {
    this.ngxService.start();
    this.formInit();
    this.bookingService.getSmartGroups(this.getFilterFormControlValues()).subscribe({
      next: (res: any) => {
        this.smartGroupList = res.data.smart_group;
        this.schoolStaffs = res.data.school_staff;
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  openCollapse(smartGroupId: any, data: any, event: any, i: any) {
    event.stopPropagation()
    // debugger;
    if (event.srcElement.classList[0] == 'restrict') {
      return;
    }
    for(let j = 0; j < this.openCollapseFind.length; j++){
      if(i != j){
        this.openCollapseFind[j] = false;
      }      
    }

    if (event.srcElement.classList[0] == 'restrict') {
      this.openCollapseFind[i] = false
      return;
    }else{
      this.openCollapseFind[i] = !this.openCollapseFind[i]
    }
  
    this.ngxService.start();
    this.activeTab = 'preferred'
    this.consumerDetails.forEach((x: any) => {
      x.isChecked = false;
    });
    this.consumerDetails = [];
    data.openPanel = !data.openPanel
    if (this.consumerDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
    this.groupId = smartGroupId.toString();
    if (this.groupId == this.openedGroupId) {
      this.ngxService.stop();
      return;
    }
    this.bookingService.getSmartGroupsList(smartGroupId).subscribe({
      next: (res: any) => {
        console.log("hi", res);
        data.openPanel = true
        this.openedGroupId = res.id.toString();
        this.backUplist = res.back_uplist;
        this.preferredList = res.preferred_list;
        // this.totalListLength = (this.backUplist.length + this.preferredList.length)
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })

  }
  // openCreateModal(){
  //     this.bsModalRef = this.modalService.show(this.createSmartGroupModal, { class: 'modal-lg', backdrop: 'static' });
  // }
  showSmartCreateModal(type: any, data: any) {
    if (type == 'edit') {
      console.log('s',this.schoolStaffs)
      const initialState = {
        type: type,
        data: data,
        pageFrom: 'smart_group_page',
        schoolStaffs: this.schoolStaffs
      };
      this.bsModalRef = this.modalService.show(CreateSmartGroupComponent, { initialState, class: 'modal-md', backdrop: 'static' });
    } else {
      const initialState = {
        pageFrom: 'smart_group_page',
        schoolStaffs: this.schoolStaffs
      };
      this.bsModalRef = this.modalService.show(CreateSmartGroupComponent, { initialState, class: 'modal-md', backdrop: 'static' });
    }

  }


  showSmartList(activeTab: string) {
    this.activeTab = activeTab;
    this.consumerDetails.forEach((x: any) => {
      x.isChecked = false;
    });
    this.consumerDetails = [];
    if (this.consumerDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }

  history(activeTab: string) {
    this.activeTab = activeTab;
    this.consumerDetails.forEach((x: any) => {
      x.isChecked = false;
    });
    this.consumerDetails = [];
    if (this.consumerDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }
  selectAll(type: any, event: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.consumerDetails = [];
      this.backUplist.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          console.log("ta", x.isChecked)
          this.consumerDetails.push(x);
          this.hideButton = false
        } else {
          x.isChecked = false;
          console.log("ya", x.isChecked)
          this.consumerDetails = [];
        }
        if (this.consumerDetails.length > 0) {
          this.hideButton = false
        } else {
          this.hideButton = true
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        console.log("ha", this.consumerDetails)
        this.consumerDetails.push(type)
        if (this.consumerDetails.length > 0) {
          this.hideButton = false
        } else {
          this.hideButton = true
        }
      } else {
        type.isChecked = false;
        console.log("da", this.consumerDetails)
        this.consumerDetails = this.consumerDetails.filter((x: any) => x != type);
        if (this.consumerDetails.length > 0) {
          this.hideButton = false
        } else {
          this.hideButton = true
        }
      }
    }
  }
  sendMailModal(actions: any, teacher_id: any) {
    this.action = actions
    this.teacher_id = teacher_id.toString()
    this.bsModalRef = this.modalService.show(this.sendEmailModal, { class: 'modal-md', backdrop: 'static' });
  }


  getFormControlValues() {
    return this.moveToForm.value;
  }

  smartGroupActionAPI(group_id: any) {
    this.bookingService.smartGroupsActions(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          console.log("hi", res);
          this.bsModalRef.hide();
          this.consumerDetails = []
          this.hideButton = true
          this.toastr.success(res.message);
          this.bookingService.getSmartGroupsList(group_id).subscribe({
            next: (res: any) => {
              console.log("hiiiii", res);
              // data.openPanel = true
              this.openedGroupId = res.id.toString();
              this.backUplist = res.back_uplist;
              this.preferredList = res.preferred_list;
              // this.totalListLength = (this.backUplist.length + this.preferredList.length)
              this.ngxService.stop();
            },
            error: (err: any) => {
              console.log(err);
              this.ngxService.stop();
            }
          })
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }

      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.statusText + ', Please contact admin');
        this.ngxService.stop();
      }
    })
  }
  moveToList(group_id: any, teacher_id: any) {
    this.ngxService.start();
    if (this.activeTab == 'preferred') {
      this.action = 'move_to_back_uplist'
    }
    if (this.activeTab == 'backup') {
      this.action = 'move_to_preffered_list'
    }
    this.moveToForm.patchValue({
      teacher_ids: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.id; }).join(",")),
      group_id: group_id,
      action: this.action
    });
    this.smartGroupActionAPI(group_id)
  }
  RemoveFromList(group_id: any, teacher_id: any) {
    this.ngxService.start();
    if (this.activeTab == 'preferred') {
      this.action = 'remove_preferred_list'
    }
    if (this.activeTab == 'backup') {
      this.action = 'remove_back_uplist'
    }
    if (teacher_id == undefined) {
      this.moveToForm.patchValue({
        teacher_ids: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.id; }).join(",")),
        group_id: group_id,
        action: this.action
      });
    } else {
      this.moveToForm.patchValue({
        teacher_ids: teacher_id,
        group_id: group_id,
        action: this.action
      });
    }
    this.smartGroupActionAPI(group_id)
  }
  OpenMoveStaffsModal() {
    this.bsModalRef = this.modalService.show(this.moveSatffsModal, { class: 'modal-md', backdrop: 'static' });
  }
  OpenRemoveStaffsModal() {
    this.bsModalRef = this.modalService.show(this.RemoveStaffsModal, { class: 'modal-md', backdrop: 'static' });
  }
  OpenSingleRemoveStaffsModal(group_id: any, teacher_id: any) {
    this.teacher_id = teacher_id.toString()
    this.bsModalRef = this.modalService.show(this.RemoveStaffsModal, { class: 'modal-md', backdrop: 'static' });
  }

  sendMessage() {
    const initialState = {
      teacher: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(",")),
      from: 'smart_groups',
      sendType: 'multiple',
      checked_status: this.consumerDetails,
    };
    this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
    this.bsModalRef.content.onClose.subscribe((result: any) => {
      this.hideButton = true;
    })
  }
  openDeleteGroupModal(group_id: any) {
    this.groupId = group_id.toString()
    this.bsModalRef = this.modalService.show(this.DeleteGroupModal, { class: 'modal-md', backdrop: 'static' });
  }
  DeleteGroup(group_id: any) {
    this.ngxService.start();
    this.moveToForm.patchValue({
      group_id: this.groupId,
      action: 'remove_group'
    });
    this.bookingService.smartGroupsActions(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          console.log("hi", res);
          this.bsModalRef.hide();
          this.consumerDetails = []
          this.hideButton = true
          this.toastr.success(res.message);

          // var index = this.smartGroupList.map((x: any) => {
          //   return x.id.toString();
          // }).indexOf(this.groupId);
          // this.smartGroupList.splice(index, 1);

          // var index = this.UpdatesmartGroupList.map((x: any) => {
          //   return x.id.toString();
          // }).indexOf(this.groupId);
          // this.UpdatesmartGroupList.splice(index, 1);


          var index = this.filter_list.map((x: any) => {
            return x.id.toString();
          }).indexOf(this.groupId);
          this.filter_list.splice(index, 1);


          // if(name != ''){
          //   var index = name.map((x: any) => {
          //     return x.toString();
          //   }).indexOf(this.groupId);
          //   name.splice(index, 1);
          //   console.log(this.searchNameForm.controls['filter_group_name'].value)
          let name = this.searchNameForm.controls['filter_group_name'].value

          if (name != '') {
            var index = name.map((x: any) => {
              return x.toString();
            }).indexOf(this.groupId);
            name.splice(index, 1);
            this.searchNameForm.patchValue({
              filter_group_name: name,
            });
            this.bookingService.getSmartGroups(this.getFilterFormControlValues()).subscribe({
              next: (res: any) => {
                this.smartGroupList = res.data.smart_group;
                this.schoolStaffs = res.data.school_staff;
                this.ngxService.stop();
              },
              error: (err: any) => {
                console.log(err);
                this.ngxService.stop();
              }
            })
          } else {
            this.bookingService.getSmartGroups(this.currentPage).subscribe({
              next: (res: any) => {
                console.log("hi", res);
                this.smartGroupList = res.data.smart_group;
                this.schoolStaffs = res.data.school_staff;
                this.ngxService.stop();
              },
              error: (err: any) => {
                console.log(err);
                this.ngxService.stop();
              }
            })
          }
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.statusText + ', Please contact admin');
        this.ngxService.stop();
      }
    })
  }

  redirectBookingPage(request_id: any) {
    this.mixpanelService.smartGroupPageBookings(request_id)
    this._router.navigate(['/school/booking-request/smart-group', request_id]);
  }

  getTeacherAvailabilityModal(teacher_id: any) {
    // this.ngxService.start();
    this.availTeacher = teacher_id
    this.bookingService.getAvailability(this.availTeacher).subscribe({
      next: (res: any) => {
        if (res.status) {
          console.log(res.data)
          // this.obsArray.next(res.data.data);
          const initialState = {
            teacher: res.data
          };
          this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.closeBtnName = 'Close';
          // this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
        }
      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
        this.ngxService.stop();
      }
    });
  }
  log(event: any) {
    console.log(`Accordion has been ${event ? 'opened' : 'closed'}`);
  }
  checkTrue(){
    this.checkIsOpen = true
  }
}
