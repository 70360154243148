<div class="preconfrimed-page pb-30 p-15">
    <form [formGroup]="preConfrimFilterForm">
        <div class="row align-items-center mt-10">
            <div class="col-md-3 col-sm-6 col-lg-3 mt-10">
                <input type="text" placeholder="Select date" class="form-control" bsDatepicker
                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-red', showWeekNumbers:false}"
                    [daysDisabled]="[6,0]" [(ngModel)]="bsValue" (ngModelChange)="getFilterInputs()"
                    [ngModelOptions]="{standalone: true}">
            </div>

            <div class="col-md-3 col-sm-6 col-lg-3 mt-10">
                <ng-select [searchable]="false" [clearable]="false" formControlName="filter_consumer_type"
                    [dropdownPosition]="'bottom'" [multiple]="false" placeholder="Role type"
                    (change)="getFilterInputs()">
                    <ng-option value>Select role type</ng-option>
                    <ng-option *ngFor="let tag of bookingRoleType" [value]="tag['id']" [disabled]="tag['is_parent']">
                        {{ tag["title"] }}
                    </ng-option>
                </ng-select>
                <!-- <ng-select [closeOnSelect]="false" [selectableGroup]="true" [selectableGroupAsModel]="false"
                    [clearable]="false" [searchable]="false" [items]="consumerRoleType" formControlName="consumer_type"
                    [dropdownPosition]="'bottom'" [multiple]="true" placeholder="Role type" groupBy="type"
                    bindLabel="title" bindValue="id">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" [checked]="item$.selected" class="form-check-input mt-0"
                            type="checkbox" />
                        <label>{{item.type}}</label>
                        <label *ngIf="item$.selected">Unselect all</label> 
                        <span class="float-right" aria-hidden="true">Clear</span>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" class="form-check-input mt-0" value="{{item.title}}"
                            [checked]="item$.selected" type="checkbox" />
                        <label>{{item.title}}</label>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:2;  index as i">
                            <span class="ng-value-label">{{items[i].title}}</span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">{{items.length - 2}} more...</span>
                        </div>
                    </ng-template>
                </ng-select> -->
            </div>

            <div class="col-md-3 col-sm-6 col-lg-3 mt-10">
                <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                    id="distance" (change)="getFilterInputs()" formControlName="distance">
                    <ng-option *ngFor="let distance of filtersArray.distance_choices" value="{{distance.value}}">
                        {{distance.title}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="col-md-3 col-sm-6 col-lg-3 mt-10">
                <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                    id="rating" (change)="getFilterInputs()" formControlName="rating">
                    <ng-option *ngFor="let rating of filtersArray.rating_choices" value="{{rating.value}}">
                        {{rating.title}}
                    </ng-option>
                </ng-select>
                <!-- <div class="form-floating mb-3">
                <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                <label for="floatingInput">Email address</label>
              </div> -->
            </div>

        </div>

        <div class="row align-items-center mt-10">
            <div class="col-md-12 col-lg-4 col-sm-12 align-items-center  mt-10">
                <!-- <p class="grey-3-14">{{teacherLengthLimit}} matches found</p> -->
            </div>
            <div class="col-md-12 col-lg-8 col-sm-12  mt-10">
                <div class="justify-content-space-bet availability-legends">
                    <div class="text-legend align-items-center d-flex">
                        <div class="user-sub-title available-color mr-5"></div>
                        <div class="txt-regular">Available</div>
                    </div>
                    <div class="text-legend align-items-center d-flex ml-5">
                        <div class="havent-indicated user-sub-title mr-5"></div>
                        <div class="txt-regular">Haven’t Indicated</div>
                    </div>
                    <div class="text-legend align-items-center d-flex ml-5">
                        <div class="booked user-sub-title mr-5"></div>
                        <div class="txt-regular">Booked</div>
                    </div>
                    <div class="text-legend align-items-center d-flex ml-5">
                        <div class="booked-elsewhere user-sub-title mr-5"></div>
                        <div class="txt-regular">Booked Elsewhere</div>
                    </div>
                    <div class="text-legend align-items-center d-flex ml-5">
                        <div class="unavailable user-sub-title mr-5"></div>
                        <div class="txt-regular">Unavailable</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-15">
            <div class="col-lg-4 col-md-4">
                <div class="addAllDiv">
                    <!-- <p class="grey-3-14">{{teacherLengthLimit}} Match found</p> -->
                    <p class="text-r-m-16 booking-selected">
                        {{teacherLengthLimit}}
                        matches found
                    </p>
                </div>
            </div>

            <div class="col-lg-8 col-md-8">
                <div class="addAllDiv1">
                    <div class="form-check d-flex align-items-center">
                        <input class="form-check-input c-p" type="checkbox" id="custom"
                            formControlName="filter_is_certified" (change)="getFilterInputs()">
                        <label class="form-check-label cb_label c-p ml-5" for="custom">
                            {{custom_text}}
                        </label>
                    </div>

                    <div class="dropdown sortByBR clr-cc-vilot ml-20">
                        <p data-bs-toggle="dropdown"
                            class="ml-10 mt-2 d-flex dropdown-toggle h_b c-p clr-cc-vilot text-regular-bold">
                            <span>
                                <i class="bi bi-filter-left ml-5"></i>
                            </span>
                            <span class="mt-1">Sort by</span>
                        </p>
                        <ul class="dropdown-menu c-p">
                            <li>
                                <a class="dropdown-item" (click)="sortBy('availability')">Availability</a>
                              </li>
                            <li>
                                <a class="dropdown-item" (click)="sortBy('name')">
                                    Name
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" (click)="sortBy('lastSeen')">
                                    Last Seen
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" (click)="sortBy('distance')">
                                    Distance
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- <a class="c-p  h_b text-regular-bold ml-20">Sort by <i class="bi bi-filter-left fs-16"></i></a> -->

                    <a class="c-p clr-cc-vilot text-regular-bold h_b showHideTag t-dec-none ml-20"
                        (click)="showtags();">
                        <i class="bi mr-4 fs-16" [ngClass]="tagsAndSubjects ? 'bi-eye-slash-fill': 'bi-eye'"></i>
                        <span *ngIf="tagsAndSubjects">Hide</span>
                        <span *ngIf="!tagsAndSubjects">Show</span>
                        additional information
                    </a>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-20 mt-10">
            <div class="col-md-6 col-sm-6 col-lg-3 mt-10">
                <div class="input-group">
                    <input id="search-by-name" formControlName="name" type="text" placeholder="Search by name"
                        class="form-control" aria-label="Search by name">
                    <button class="input-group-text" for="search-by-name" (click)="getFilterInputs()">
                        <i class="bi bi-search"></i>
                    </button>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-3 mt-10">
                <ng-select [dropdownPosition]="'bottom'" [clearable]="false" [selectableGroupAsModel]="false"
                    [closeOnSelect]="false" [selectableGroup]="true" [items]="subjectsAndTags" formControlName="tags"
                    (change)="getFilterInputs()" bindLabel="title" bindValue="title" groupBy="tags" [multiple]="true"
                    placeholder="Search by subjects and tags">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" [checked]="item$.selected" class="form-check-input mt-0"
                            type="checkbox">
                        <label *ngIf="!item$.selected">Select all</label>
                        <label *ngIf="item$.selected">Unselect all</label>
                        <!-- <span class="float-right" aria-hidden="true">Clear</span> -->
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" class="form-check-input mt-0" value="{{item.title}}"
                            [checked]="item$.selected" type="checkbox">
                        <label>{{item.title}}</label>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:2;  index as i">
                            <span class="ng-value-label">{{items[i].title}}</span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">
                                ×
                            </span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">
                                {{items.length - 2}}
                                more...
                            </span>
                        </div>
                    </ng-template>
                    <!-- <ng-template ng-option-tmp let-item="item">
                {{item.title}}
            </ng-template> -->
                </ng-select>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-3 mt-10">
                <ng-select formControlName="filter_experience_items" [clearable]="false"
                    [selectableGroupAsModel]="false" [closeOnSelect]="false" [selectableGroup]="true"
                    [dropdownPosition]="'bottom'" [items]="levelFilter" [multiple]="true" bindLabel="title"
                    groupBy="levels" bindValue="value" placeholder="Search by level" (change)="getFilterInputs()">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" [checked]="item$.selected" class="form-check-input mt-0"
                            type="checkbox">
                        <label *ngIf="!item$.selected">Select all</label>
                        <label *ngIf="item$.selected">Unselect all</label>
                        <!-- <span class="float-right" aria-hidden="true">Clear</span> -->
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" class="form-check-input mt-0" value="{{item.title}}"
                            [checked]="item$.selected" type="checkbox">
                        <label>{{item.title}}</label>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:2;  index as i">
                            <span class="ng-value-label">{{items[i].title}}</span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">
                                ×
                            </span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label">
                                {{items.length - 2}}
                                more...
                            </span>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-3 mt-10">
                <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                    id="booking_smartgroup" (change)="getFilterInputs()" formControlName="booking_smartgroup">
                    <ng-option *ngFor="let group of smartGroups" value="{{group.value}}">
                        {{group.title}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-12 mt-20 Fortnight sticky px-12" *ngIf="preConfirmList.length > 0">
            <div class="d-flex align-items-center s_between">
                <a class="c-p text-regular-bold t-dec-none h_b btn-cc-vilot" (click)="prevFortnight();">
                    <i class="bi fs-17 bi-arrow-left-short v-align-m"></i>
                    Previous
                    Fortnight
                </a>
                <p class="text-regular-medium">{{monthsShown}}</p>

                <a class="c-p text-regular-bold  h_b t-dec-none h_b btn-cc-vilot" (click)="nextFortnight();">
                    Next
                    Fortnight
                    <i class="bi bi-arrow-right-short fs-17 v-align-m"></i>
                </a>
            </div>
        </div>

        <!-- List of Teachers Card -->
        <div class="mt-15">
            <div class *ngIf="teachersList | async as list">
                <div class="rowMr0">
                    <div class="card px-12 mt-16" *ngFor="let teacher of list; index as i">
                        <div class="row" *ngIf="tagsAndSubjects">
                            <div class="col-md-9 col-sm-8 col-lg-9   mb-10">
                                <div class="col-md-12 col-lg-12 d-flex">
                                    <div class="pic-and-check ml-0">
                                        <img alt="img"
                                            [src]="teacher.teacher.meta.profile_image != null ? teacher.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                            class="profile-picture img-complete-match">
                                        <br>
                                        <div class="s_r_div">
                                            <p class="text-center mt-2" *ngIf="teacher.rating == 0">
                                                <i class="bi bi-star"></i>
                                                <i class="bi bi-star ml-5"></i>
                                            </p>
                                            <p class="text-center" *ngIf="teacher.rating == 1">
                                                <i class="bi bi-star-fill"></i>
                                                <i class="bi bi-star ml-5"></i>
                                            </p>
                                            <p class="text-center" *ngIf="teacher.rating == 2">
                                                <i class="bi bi-star-fill"></i>
                                                <i class="bi bi-star-fill ml-5"></i>
                                            </p>
                                            <p class="text-center  d_2" *ngIf="teacher.rating == -2">
                                                <i class="bi bi-star-fill"></i>
                                                <i class="bi bi-star-fill ml-5"></i>
                                            </p>
                                            <p class="text-center d_1" *ngIf="teacher.rating == -1">
                                                <i class="bi bi-star-fill"></i>
                                                <i class="bi bi-star-fill ml-5"></i>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="pic-and-check ml-10">
                                        <p>
                                            <span class="s_n"
                                                (click)="getTeacherAvailabilityModal(teacher.teacher.id);">
                                                {{teacher.teacher.user.full_name}}
                                            </span>
                                            <span>
                                                <app-validated-dict
                                                    [validateDict]="teacher.teacher.validated_dict"></app-validated-dict>
                                            </span>
                                            <span *ngIf="teacher.teacher.is_complete == false"
                                                class="bg-tags-custom ml-10">Incomplete
                                                Profile
                                            </span>
                                        </p>
                                        <p class="user-sub-title">
                                            {{teacher.teacher.meta.you_are_a}}
                                            <span class="divider-right ml-3 mr-5"></span>
                                            Active
                                            {{teacher.teacher.user.previous_login}}
                                        </p>
                                        <p class="text-s-m-g-3">
                                            <i class="bi bi-telephone clr-grey-7"></i>
                                            <span class="user-sub-title ml-5">{{teacher.teacher.phone}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-lg-3  d-flex justify-content-right">
                                <div class="is_certified_text_disabled">
                                    <div class="form-check" *ngIf="teacher.is_certified">
                                        <input type="checkbox" id="is-certified-{{teacher.id}}"
                                            name="filter_is_certified" checked disabled
                                            class="form-check-input  mt-4 c-p">
                                        <label for="is-certified-{{teacher.id}}" class="form-check-label c-p">
                                            {{custom_text}}
                                        </label>
                                    </div>
                                    <div class="form-check" *ngIf="!teacher.is_certified">
                                        <input type="checkbox" id="is-certified-{{teacher.id}}"
                                            name="filter_is_certified" disabled class="form-check-input  mt-4 c-p">
                                        <label for="is-certified-{{teacher.id}}" class="form-check-label c-p">
                                            {{custom_text}}
                                        </label>
                                    </div>

                                    <button
                                        (click)="addEditNotes(teacher.teacher.user.full_name,'edit',teacher.notes,teacher.id)"
                                        *ngIf="teacher.notes != null" class="notesTooltip  fit-content add-notes">
                                        <span class="text-regular-bold c-p clr-cc-vilot">
                                            <i class="bi bi-pencil-fill vertical-align-bottom fs-11 ml-5"></i>
                                            View
                                            notes
                                        </span>
                                        <span class="notesTooltiptext text-s-m-g-1">{{ teacher.notes }}</span>
                                    </button>
                                    <!-- <p class="text-regular-bold c-p hover-blue fit-content  mt-10"
                                        triggers="mouseenter:mouseleave" placement="top" popover="{{teacher.notes}}"
                                        *ngIf="teacher.notes != null"
                                        (click)="addEditNotes(teacher.teacher.user.full_name,'edit', teacher.notes,teacher.id)">
                                        <i class="bi bi-pencil-fill vertical-align-bottom fs-11 ml-5"></i>
                                        View
                                        notes
                                    </p> -->
                                    <p class="text-regular-bold c-p hover-blue add-notes  mt-10"
                                        (click)="addEditNotes(teacher.teacher.user.full_name,'add','',teacher.id)"
                                        *ngIf="teacher.notes == null">
                                        <i class="bi bi-pencil-fill vertical-align-bottom fs-11 ml-5"></i>
                                        Add
                                        notes
                                    </p>
                                </div>
                            </div>
                            <div class="call-outs call-outs-clone mt-5">
                                <span class="availability-grid" *ngFor="let availability of teacher.availability">
                                    <div class="call-out {{availability.day}}"
                                        [ngClass]="availability.intervals.length > 1 ? 'half-day': ''">
                                        <span *ngIf="availability.intervals.length == 1">
                                            <div class="link user-{{availability.intervals[0].status}} tooltipss"
                                                (click)="preConfrimBookingModal(teacher,availability.intervals[0],availability.day)">
                                                <span
                                                    *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'"
                                                    class="tooltiptext txt-regular clr-white">
                                                    {{availability.tool_tip}}
                                                    <br>
                                                    {{availability.intervals[0].display_status}}
                                                </span>
                                                <a
                                                    *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">{{availability.date}}</a>
                                                <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                                                    <i class="bi bi-calendar-x sat-sun-icon"></i>
                                                </a>
                                            </div>
                                        </span>

                                        <span *ngIf="availability.intervals.length > 1">
                                            <div class="user-{{availability.intervals[0].status}} tooltipsss"
                                                [ngClass]="availability.intervals.length[0] ? 'link-interval': 'link-interval'"
                                                (click)="preConfrimBookingModal(teacher,availability.intervals[0],availability.day)">
                                                <span class="tooltiptext txt-regular clr-white">
                                                    {{availability.tool_tip}}
                                                    <br>
                                                    {{availability.intervals[0].display_status}}
                                                </span>
                                                <a
                                                    *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">{{availability.date}}</a>
                                                <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                                                    <i class="bi bi-calendar-x sat-sun-icon"></i>
                                                </a>
                                            </div>
                                            <div class="link-intervals user-{{availability.intervals[1].status}} tooltipssss"
                                                (click)="preConfrimBookingModal(teacher,availability.intervals[1],availability.day)">
                                                <span class="tooltiptext txt-regular clr-white">
                                                    {{availability.tool_tip}}
                                                    <br>
                                                    {{availability.intervals[1].display_status}}
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="row" *ngIf="!tagsAndSubjects">
                            <div class="col-md-12 col-sm-12 col-lg-4">
                                <div class="col-md-12 col-lg-12 d-flex">
                                    <div class="pic-and-check ml-0">
                                        <img alt="img"
                                            [src]="teacher.teacher.meta.profile_image != null ? teacher.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                            class="profile-picture-s img-complete-match">
                                        <br>
                                        <div class="s_r_div">
                                            <p class="text-center mt-2" *ngIf="teacher.rating == 0">
                                                <i class="bi bi-star"></i>
                                                <i class="bi bi-star ml-5"></i>
                                            </p>
                                            <p class="text-center" *ngIf="teacher.rating == 1">
                                                <i class="bi bi-star-fill"></i>
                                                <i class="bi bi-star ml-5"></i>
                                            </p>
                                            <p class="text-center" *ngIf="teacher.rating == 2">
                                                <i class="bi bi-star-fill"></i>
                                                <i class="bi bi-star-fill ml-5"></i>
                                            </p>
                                            <p class="text-center  d_2" *ngIf="teacher.rating == -2">
                                                <i class="bi bi-star-fill"></i>
                                                <i class="bi bi-star-fill ml-5"></i>
                                            </p>
                                            <p class="text-center d_1" *ngIf="teacher.rating == -1">
                                                <i class="bi bi-star-fill"></i>
                                                <i class="bi bi-star-fill ml-5"></i>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="pic-and-check ml-10">
                                        <p>
                                            <span class="s_n"
                                                (click)="getTeacherAvailabilityModal(teacher.teacher.id);">
                                                {{teacher.teacher.user.full_name}}
                                            </span>
                                            <span>
                                                <app-validated-dict
                                                    [validateDict]="teacher.teacher.validated_dict"></app-validated-dict>
                                            </span>
                                            <!-- <span *ngIf="teacher.teacher.is_complete == false" class="bg-tags-custom ml-10">Incomplete
                                                Profile
                                              </span> -->
                                        </p>
                                        <div
                                            class="is_certified_text_disabled d-flex flex-wrap align-items-center  mr-10">
                                            <div class="form-check" *ngIf="teacher.is_certified">
                                                <input type="checkbox" id="is-certified-{{teacher.id}}"
                                                    name="filter_is_certified" checked disabled
                                                    class="form-check-input  mt-4 c-p">
                                                <label for="is-certified-{{teacher.id}}" class="form-check-label c-p">
                                                    {{custom_text}}
                                                </label>
                                            </div>
                                            <div class="form-check" *ngIf="!teacher.is_certified">
                                                <input type="checkbox" id="is-certified-{{teacher.id}}"
                                                    name="filter_is_certified" disabled
                                                    class="form-check-input  mt-4 c-p">
                                                <label for="is-certified-{{teacher.id}}" class="form-check-label c-p">
                                                    {{custom_text}}
                                                </label>
                                            </div>

                                            <!-- <p class="text-regular-bold c-p clr-cc-vilot fit-content"
                                                triggers="mouseenter:mouseleave" placement="top" popover="{{teacher.notes}}"
                                                *ngIf="teacher.notes != null"
                                                (click)="addEditNotes(teacher.teacher.user.full_name,'edit', teacher.notes,teacher.id)">
                                                <i class="bi bi-pencil-fill vertical-align-bottom fs-11 ml-5"></i>
                                                View
                                                notes
                                            </p> -->
                                            <button
                                                (click)="addEditNotes(teacher.teacher.user.full_name,'edit',teacher.notes,teacher.id)"
                                                *ngIf="teacher.notes != null"
                                                class="notesTooltip  fit-content add-notes">
                                                <span class="text-regular-bold c-p clr-cc-vilot">
                                                    <i class="bi bi-pencil-fill vertical-align-bottom fs-11 ml-5"></i>
                                                    View
                                                    notes
                                                </span>
                                                <span class="notesTooltiptext text-s-m-g-1">{{ teacher.notes }}</span>
                                            </button>
                                            <p class="text-regular-bold c-p  clr-cc-vilot  add-notes"
                                                (click)="addEditNotes(teacher.teacher.user.full_name,'add','',teacher.id)"
                                                *ngIf="teacher.notes == null">
                                                <i class="bi bi-pencil-fill vertical-align-bottom fs-11 ml-5"></i>
                                                Add
                                                notes
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-lg-8 hideAddDetails">
                                <div class="call-outs mt-5 call-outs-clone">
                                    <span class="availability-grid" *ngFor="let availability of teacher.availability">
                                        <div class="call-out {{availability.day}}"
                                            [ngClass]="availability.intervals.length > 1 ? 'half-day': ''">
                                            <span *ngIf="availability.intervals.length == 1">
                                                <div class="link user-{{availability.intervals[0].status}} tooltipss"
                                                    (click)="preConfrimBookingModal(teacher,availability.intervals[0],availability.day)">
                                                    <span
                                                        *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'"
                                                        class="tooltiptext txt-regular clr-white">
                                                        {{availability.tool_tip}}
                                                        <br>
                                                        {{availability.intervals[0].display_status}}
                                                    </span>
                                                    <a
                                                        *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">{{availability.date}}</a>
                                                    <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                                                        <i class="bi bi-calendar-x sat-sun-icon"></i>
                                                    </a>
                                                </div>
                                            </span>

                                            <span *ngIf="availability.intervals.length > 1">
                                                <div class="user-{{availability.intervals[0].status}} tooltipsss"
                                                    [ngClass]="availability.intervals.length[0] ? 'link-interval': 'link-interval'"
                                                    (click)="preConfrimBookingModal(teacher,availability.intervals[0],availability.day)">
                                                    <span class="tooltiptext txt-regular clr-white">
                                                        {{availability.tool_tip}}
                                                        <br>
                                                        {{availability.intervals[0].display_status}}
                                                    </span>
                                                    <a
                                                        *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">{{availability.date}}</a>
                                                    <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                                                        <i class="bi bi-calendar-x sat-sun-icon"></i>
                                                    </a>
                                                </div>
                                                <div class="link-intervals user-{{availability.intervals[1].status}} tooltipssss"
                                                    (click)="preConfrimBookingModal(teacher,availability.intervals[1],availability.day)">
                                                    <span class="tooltiptext txt-regular clr-white">
                                                        {{availability.tool_tip}}
                                                        <br>
                                                        {{availability.intervals[1].display_status}}
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="text-center mt-50" *ngIf="list?.length == 0 ">
                    <h4>
                        You don’t have any teachers on your list
                    </h4>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12 px-50">
                            <p>
                                <img class="n_df" src="assets/images/NoResultFound/preConfirm/addCasuals.png"
                                    alt="No matches for the applied filter!">
                            </p>
                            <p class="user-title mb-10">Add</p>
                            <p class="txt-regular mb-10">
                                Add casual you already book
                                reguarly. You can add in bulk or one by one
                            </p>
                            <a class="mt-20  clr-cc-vilot user-title c-p ">Add casual</a>

                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12 px-50">
                            <p>
                                <img class="n_df" src="assets/images/NoResultFound/preConfirm/findNew.png"
                                    alt="No matches for the applied filter!">
                            </p>
                            <p class="user-title mb-10">Find</p>
                            <p class="txt-regular mb-10">
                                Find a new casual from our
                                database of casuals being updated every
                                month
                            </p>
                            <a routerLink="/school/find-new" class="mt-20  clr-cc-vilot user-title">Find new</a>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12 px-50">
                            <p>
                                <img class="n_df" src="assets/images/NoResultFound/preConfirm/postCallOut.png"
                                    alt="No matches for the applied filter!">
                            </p>
                            <p class="user-title mb-10">Post</p>
                            <p class="txt-regular mb-10">
                                Create a job post for relief
                                teachers to apply to be on your list
                            </p>
                            <a (click)="redirectToPostCallOut()" class="mt-20 c-p clr-cc-vilot user-title">Post a
                                callout</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>

    <form [formGroup]="preConfrimBookingForm">
        <ng-template #preConfrimModal>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Booking
                    Confirmation
                </h5>
                <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
            </div>
            <ul class="nav nav-tabs mt-10" id="myTab" role="tablist" *ngIf="showOccurenceReview">
                <li class="nav-item" role="presentation" *ngIf="modalData.enable_preconfirmed">
                    <button class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
                        [ngClass]="showOccurenceModalTab ? '' : 'active'" role="tab" aria-controls="home"
                        aria-selected="true">
                        Single day
                    </button>
                </li>
                <li class="nav-item" role="presentation" *ngIf="modalData.enable_preconfirmed">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                        type="button" role="tab" [ngClass]="showOccurenceModalTab ? 'active' : ''"
                        aria-controls="profile" aria-selected="false">
                        Repeating
                    </button>
                </li>
            </ul>

            <!-- <div class="" *ngIf="filtersArray.teachercovered_required">
                <div *ngIf="submitted" class="mt-10">
                    <span class="show-errors" *ngIf="f['booking_teachercovered'].errors?.['required']">* {{filtersArray.custom_field1}} is required</span>
                </div>
            </div>
            <div class="" *ngIf="filtersArray.classbeingcovered_required">
                <div *ngIf="submitted">
                    <span class="show-errors mt-1" *ngIf="f['booking_classcovered'].errors?.['required']">* {{filtersArray.custom_field2}} is required</span>
                </div>
            </div>
            <div class="" *ngIf="filtersArray.absencereason_required">
                <div *ngIf="submitted">
                    <span class="show-errors mt-1" *ngIf="f['booking_absencereason'].errors?.['required']">* Absence reason is required</span>
                </div>
            </div> -->
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="modal-body pb-0">
                        <div class="row  align-items-center">
                            <div class="col-md-6 col-sm-6 col-lg-6">
                                <div class="col-md-12 col-lg-12 d-flex profile-head-info">
                                    <div class="pic-and-check ml-0">
                                        <img alt="img"
                                            [src]="modalData.teacher.meta.profile_image != null ? modalData.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                            class="profile-picture img-complete-match">
                                    </div>

                                    <div class="pic-and-check ml-10">
                                        <div class="d-flex">
                                            <p class="mb-0">
                                                <span class="s_n">{{modalData.teacher.user.full_name}}</span>
                                                <span>
                                                    <app-validated-dict
                                                        [validateDict]="modalData.teacher.validated_dict"></app-validated-dict>
                                                </span>
                                            </p>
                                        </div>
                                        <p class="user-sub-title mb-0">
                                            <span
                                                *ngIf="modalData.teacher.meta.you_are_a">{{modalData.teacher.meta.you_are_a}}</span>
                                            <span *ngIf="!modalData.teacher.meta.you_are_a">N/A</span>

                                        </p>
                                        <p class="user-sub-title envelope-icon mb-0">
                                            Accreditation No.
                                            <span *ngIf="modalData.teacher.accreditation.accreditation">
                                                <span *ngIf="modalData.teacher.accreditation.accreditation.length > 0">
                                                    {{modalData.teacher.accreditation.accreditation[0].accreditation_id}}
                                                </span>
                                            </span>
                                        </p>
                                        <p class="user-sub-title mb-0">
                                            <span *ngIf="modalData.teacher.user.previous_login">
                                                Active
                                                {{modalData.teacher.user.previous_login}}
                                            </span>
                                            <span *ngIf="!modalData.teacher.user.previous_login">N/A</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-6">
                                <div class="col-md-12 col-sm-12">
                                    <div class="form-floating  mt-10">
                                        <!-- <input type="text" placeholder="Select date" class="form-control" bsDatepicker
                                            [minDate]="minDate" [maxDate]="maxDate" formControlName="booking_dates"
                                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-red', showWeekNumbers:false}"
                                            [daysDisabled]="[6,0]">
                                        <label for="floatingSelect">Date</label> -->
                                        <!-- <div class="tooltips">
                                        <i class="bi bi-calendar-x sat-sun-icon"></i>
                                        <span class="tooltiptext">
                                            <bs-datepicker-inline 
                                            [readonly]="true"
                                            autocomplete="off"
                                            (bsValueChange)="onChangeDate($event)"
                                            (onShown)="datepickerOpen()"
                                            #dp="bsDatepicker"
                                            [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="bsRangeValue"
                                            selectionDone="false"
                                            bsDatepicker
                                            [dateCustomClasses]="dateCustomClasses"></bs-datepicker-inline>                                        </span>
                                    </div> -->
                                        <div class="d-flex col-12 mt-4" #customPickerDiv>
                                            <input class="cloned-dp form-control" (click)="showCusCal()" type="text"
                                                value="{{selectedItem}}">
                                        </div>
                                        <div class="card pb-15 customDatepickerPreConfrim" #customDatepickerExport
                                            *ngIf="is_showCusCal">
                                            <bs-datepicker-inline [readonly]="true" autocomplete="off"
                                                (bsValueChange)="onChangeDate($event)" [isOpen]="isOpen"
                                                (onShown)="datepickerOpen()" [ngModelOptions]="{standalone: true}"
                                                selectionDone="false" [bsConfig]="{showWeekNumbers: false,
                                                    containerClass: 'theme-dark-blue'}"
                                                [dateCustomClasses]="dateCustomClasses">
                                            </bs-datepicker-inline>
                                            <p class="text-right"><button type="button"
                                                    (click)="closeBookingDatepicker()"
                                                    class="fit-content text-regular fs_13 mr-15">Done</button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12">
                                    <div class="form-floating  mt-10">
                                        <ng-select (change)="getSpecialValue()" [clearable]="false" [searchable]="false"
                                            [dropdownPosition]="'bottom'" [multiple]="false" formControlName="timerange"
                                            aria-label="Default select example">
                                            <ng-option *ngFor="let timings of schoolTimings" value="{{timings.value}}">
                                                {{ timings.timing }}
                                            </ng-option>
                                        </ng-select>
                                        <label for="floatingSelect">
                                            Booking
                                            Range
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 mt-10 d-flex  custom-input-select"
                                    *ngIf="hidetimeRange">
                                    <!-- <input type="time" class="form-control" formControlName="start_time"
                                        [ngClass]="getFormControl['timerange'].value == 'special' ? 'c-p':'input-cursor-na'"
                                        (change)="getFilterInputs('regular')" /> -->
                                    <div class="form-control input-group">
                                        <div class="form-floating">
                                            <div class="d-flex mt-10">
                                                <ng-select [searchable]="false" [clearable]="false"
                                                    [dropdownPosition]="'bottom'" [multiple]="false"
                                                    formControlName="start_hrs" aria-label="start_hrs">
                                                    <ng-option *ngFor="let item of hours_d"
                                                        value="{{item?.value}}">{{item?.title}}</ng-option>
                                                </ng-select>
                                                <span class="ml-10 mr-10">:</span>
                                                <ng-select [searchable]="false" [clearable]="false"
                                                    [dropdownPosition]="'bottom'" [multiple]="false"
                                                    formControlName="start_min" aria-label="start_min">
                                                    <ng-option *ngFor="let items of minutes_d"
                                                        value="{{items?.value}}">{{items?.title}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <label for="floatingSelect">
                                                Start
                                                time
                                            </label>
                                        </div>
                                    </div>

                                    <!-- <input type="time" class="form-control" formControlName="start_time"
                                        [ngClass]="getFormControl['timerange'].value == 'special' ? 'c-p':'input-cursor-na'"
                                        (change)="getFilterInputs('regular')" /> -->
                                    <div class="form-control ml-3 input-group">
                                        <div class="form-floating">
                                            <div class="d-flex mt-10">
                                                <ng-select [searchable]="false" [clearable]="false"
                                                    [dropdownPosition]="'bottom'" [multiple]="false"
                                                    formControlName="end_hrs" aria-label="end_hrs">
                                                    <ng-option *ngFor="let item of hours_d"
                                                        value="{{item?.value}}">{{item?.title}}</ng-option>
                                                </ng-select>
                                                <span class="ml-10 mr-10">:</span>
                                                <ng-select [searchable]="false" [clearable]="false"
                                                    [dropdownPosition]="'bottom'" [multiple]="false"
                                                    formControlName="end_min" aria-label="end_min">
                                                    <ng-option *ngFor="let items of minutes_d"
                                                        value="{{items?.value}}">{{items?.title}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <label for="floatingSelect">
                                                End
                                                time
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12">
                                    <div class="form-floating  mt-10">
                                        <ng-select (change)="getSpecialValue()" [clearable]="false" [searchable]="false"
                                            [dropdownPosition]="'bottom'" [multiple]="false" formControlName="booking_role_type"
                                            aria-label="Booking type">
                                            <ng-option *ngFor="let roletype of bookingRoleType" value="{{roletype.id}}">
                                                {{ roletype.title }}
                                            </ng-option>
                                        </ng-select>
                                        <label for="floatingSelect">
                                            Booking
                                            type
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-15">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="main-option-tab" data-bs-toggle="tab"
                                        data-bs-target="#main-option" type="button" role="tab" aria-controls="main"
                                        aria-selected="true">
                                        Main
                                        options
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="add-option-tab" data-bs-toggle="tab"
                                        data-bs-target="#add-option" type="button" role="tab" aria-controls="add"
                                        aria-selected="false">
                                        Additional
                                        options
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="attachment-option-tab" data-bs-toggle="tab"
                                        data-bs-target="#attachment-tab" type="button" role="tab" aria-controls="add"
                                        aria-selected="false">
                                        Attachments
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="notes-option-tab" data-bs-toggle="tab"
                                        data-bs-target="#notes-tab" type="button" role="tab" aria-controls="add"
                                        aria-selected="false">
                                        Add a note
                                    </button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="main-option" role="tabpanel"
                                    aria-labelledby="main-option-tab">
                                    <div class="row mt-10">
                                        <div class="col-lg-6 mt-10">
                                            <input class="form-control" type="text"
                                                [required]="filtersArray.teachercovered_required"
                                                formControlName="booking_teachercovered"
                                                placeholder="{{filtersArray.custom_field2}}">
                                            <div *ngIf="filtersArray.teachercovered_required">
                                                <div *ngIf="submitted">
                                                    <span class="show-errors"
                                                        *ngIf="f['booking_teachercovered'].errors?.['required'] || f['booking_teachercovered'].errors?.['whitespace']">
                                                        *
                                                        {{filtersArray.custom_field2}}
                                                        is required
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 mt-10">
                                            <input class="form-control" type="text"
                                                formControlName="booking_classcovered"
                                                [required]="filtersArray.classbeingcovered_required"
                                                placeholder="{{filtersArray.custom_field1}}">
                                            <div *ngIf="filtersArray.classbeingcovered_required">
                                                <div *ngIf="submitted">
                                                    <span class="show-errors"
                                                        *ngIf="f['booking_classcovered'].errors?.['required'] || f['booking_classcovered'].errors?.['whitespace']">
                                                        *
                                                        {{filtersArray.custom_field1}}
                                                        is required
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 mt-10">
                                            <div class="col-lg-12">
                                                <ng-select [searchable]="false" [clearable]="false"
                                                    [dropdownPosition]="'bottom'" [multiple]="false"
                                                    [required]="filtersArray.absencereason_required"
                                                    formControlName="booking_absencereason"
                                                    aria-label="booking-absence-reason">
                                                    <ng-option *ngFor="let absense of filtersArray.absence_reason"
                                                        value="{{absense.value}}">
                                                        {{absense.title}}
                                                    </ng-option>
                                                </ng-select>
                                                <div *ngIf="filtersArray.absencereason_required">
                                                    <div *ngIf="submitted">
                                                        <span class="show-errors"
                                                            *ngIf="f['booking_absencereason'].errors?.['required']">
                                                            *
                                                            Absence
                                                            reason is
                                                            required
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="add-option" role="tabpanel"
                                    aria-labelledby="attachment-option-tab">
                                    <div class="row mt-10">
                                        <div class="col-lg-6">
                                            <div class="col-md-12 mt-10 ">
                                                <input class="form-control" type="text"
                                                    formControlName="booking_payrollfield1"
                                                    placeholder="{{filtersArray.payroll_report_fields?.payroll_field_label_1}}">
                                            </div>
                                            <div class="col-md-12 mt-10">
                                                <input class="form-control" type="text"
                                                    formControlName="booking_payrollfield2"
                                                    placeholder="{{filtersArray.payroll_report_fields?.payroll_field_label_2}}">
                                            </div>
                                            <div class="col-md-12 mt-10">
                                                <input class="form-control" type="text"
                                                    formControlName="booking_payrollfield3"
                                                    placeholder="{{filtersArray.payroll_report_fields?.payroll_field_label_3}}">
                                            </div>

                                        </div>
                                        <div class="col-lg-6  mt-10">
                                            <div class="col-lg-12">
                                                <ng-select [searchable]="false" [clearable]="false"
                                                    [dropdownPosition]="'bottom'" [multiple]="false"
                                                    id="booking_option_leaveApproved"
                                                    formControlName="booking_leaveapproved" aria-label="Leave approved">
                                                    <ng-option value="1">Approved</ng-option>
                                                    <ng-option value="0">Not Approved</ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-lg-12">
                                                <p class="mt-10 txt-regular">
                                                    These
                                                    fields names are
                                                    customisable
                                                    and can be
                                                    changed in the school
                                                    <span (click)="openAdminSettings()"
                                                        class="text-regular-bold c-p hover-blue">
                                                        settings
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="tab-pane fade" id="attachment-tab" role="tabpanel"
                                    aria-labelledby="add-option-tab">
                                    <div class="row  mt-10">
                                        <div class="col-lg-12">
                                            <div class="col-lg-12  mt-10">
                                                <input class="form-control" type="text"
                                                    formControlName="booking_attachement_link"
                                                    placeholder="Insert link to uploaded document">
                                            </div>
                                            <div class="col-lg-12  mt-10">
                                                <div class="row align-items-center p-0 m-0 mt-10">
                                                    <div class="add-image p-0">
                                                        <label class="filelabel" for="FileInput">
                                                            <p class="mt-5">Attachment</p>
                                                            <p class="mt-5">
                                                                Drag
                                                                and drop a
                                                                file
                                                            </p>
                                                            <p class="text-regular mt-5">Or</p>
                                                            <div class="d-flex justify-content-center mt-5">
                                                                <p class="d-flex file-upload-text align-items-center">
                                                                    <i class="bi bi-paperclip mr-5"></i>
                                                                    Upload file
                                                                </p>
                                                            </div>
                                                            <p class="text-regular mt-5 user-sub-title">
                                                                <!-- <span *ngIf="!fileName" class="clr-red ">No file
                                                                                                    choosen</span> -->
                                                                <span *ngIf="fileName" class="clr-green">
                                                                    {{fileName}}
                                                                </span>
                                                            </p>

                                                            <input class="FileUpload1 form-control"
                                                                formControlName="booking_attachment" id="FileInput"
                                                                (change)="onFileSelected($event)"
                                                                name="booking_option_attachment" type="file">
                                                        </label>
                                                    </div>
                                                </div>
                                                <p class="txt-regular mt-10">
                                                    This
                                                    information will be sent to
                                                    the
                                                    teacher you are booking
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="notes-tab" role="tabpanel"
                                    aria-labelledby="notes-option-tab">
                                    <div class="row  mt-10">
                                        <div class="col-lg-12">
                                            <div class="col-lg-12 mt-10">
                                                <textarea class="form-control" type="text"
                                                    formControlName="booking_notes"
                                                    placeholder="Notes for teacher (Add information that you want to send to the teacher you are booking)">
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-20" *ngIf="!modalData.enable_preconfirmed">
                        <p class="">
                            <i class="bi bi-exclamation-triangle-fill clr-cc-vilot mr-5"></i>
                            <span class="clr-cc-vilot text-regular-medium">{{modalData.teacher.user.full_name}} has
                                disabled pre-confirmed bookings for your
                                school. They will receive this as a booking request with the option to accept or
                                decline. You can see booking result via the request history page.</span>
                        </p>
                        <div class="mt-10">
                            <input (change)="enableDisableSendBtn($event)" formControlName="readTerms" type="checkbox"
                                id="readTerms" class="form-check-input clr-cc-vilot c-p">
                            <label for="readTerms" class="form-check-label clr-cc-vilot text-regular-medium c-p ml-10">
                                I have read the message above. Send as booking request.
                            </label>
                        </div>
                    </div>

                    <div *ngIf="!modalData.enable_preconfirmed" class="modal-footer mt-10">
                        <button type="button" [ngClass]="showSendBookingBtn ? 'disabled': ''" (click)="checkFlexi()"
                            class="btn cc_p_btn fs_13">
                            Send
                            booking request
                        </button>
                    </div>
                    <div *ngIf="modalData.enable_preconfirmed" class="modal-footer mt-10">
                        <button type="button"
                            (click)="sendPreconfrimBooking('oneDayBooking' , modalData.enable_preconfirmed , modalData.teacher.user.full_name)"
                            class="btn cc_p_btn fs_13">
                            Send
                            booking
                        </button>
                    </div>
                </div>

                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="review-occurence" *ngIf="showOccurenceReview">
                        <div class="modal-body pb-0">
                            <div class="row  align-items-center">
                                <div class="col-md-6 col-sm-6 col-lg-6">
                                    <div class="col-md-12 col-lg-12 d-flex profile-head-info">
                                        <div class="pic-and-check ml-0">
                                            <img alt="img"
                                                [src]="modalData.teacher.meta.profile_image != null ? modalData.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                class="profile-picture img-complete-match">
                                        </div>

                                        <div class="pic-and-check ml-10">
                                            <div class="d-flex">
                                                <p>
                                                    <span class="s_n">{{modalData.teacher.user.full_name}}</span>
                                                    <span>
                                                        <app-validated-dict
                                                            [validateDict]="modalData.teacher.validated_dict"></app-validated-dict>
                                                    </span>
                                                </p>
                                            </div>
                                            <p class="user-sub-title">
                                                <span
                                                    *ngIf="modalData.teacher.meta.you_are_a">{{modalData.teacher.meta.you_are_a}}</span>
                                                <span *ngIf="!modalData.teacher.meta.you_are_a">N/A</span>

                                            </p>
                                            <p class="user-sub-title envelope-icon">
                                                Accreditation No.
                                                <span *ngIf="modalData.teacher.accreditation.accreditation">
                                                    <span
                                                        *ngIf="modalData.teacher.accreditation.accreditation.length > 0">
                                                        {{modalData.teacher.accreditation.accreditation[0].accreditation_id}}
                                                    </span>
                                                </span>
                                            </p>
                                            <p class="user-sub-title">
                                                <span *ngIf="modalData.teacher.user.previous_login">
                                                    Active
                                                    {{modalData.teacher.user.previous_login}}
                                                </span>
                                                <span *ngIf="!modalData.teacher.user.previous_login">N/A</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-lg-6">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-floating  mt-10">
                                            <ng-select [searchable]="false" [clearable]="false"
                                                [dropdownPosition]="'bottom'" [multiple]="false"
                                                (change)="ocurrenceChange()" formControlName="occurrence"
                                                aria-label="Default select example">
                                                <ng-option value="daily">Daily</ng-option>
                                                <ng-option value="weekly">Weekly</ng-option>
                                                <ng-option value="monthly">Monthly</ng-option>
                                            </ng-select>
                                            <label for="floatingSelect">Occurrence</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12"
                                        [ngClass]="HideOnOccurenceTimeWeek ? 'show-div' : 'hide-div'">
                                        <div class="form-floating  mt-10">
                                            <ng-select [searchable]="false" [clearable]="false"
                                                [dropdownPosition]="'bottom'" [multiple]="false"
                                                formControlName="times_per_week_month"
                                                aria-label="Default select example">
                                                <ng-option value="1">
                                                    One
                                                    week
                                                </ng-option>
                                                <ng-option selected value="2">
                                                    Two
                                                    week
                                                </ng-option>
                                            </ng-select>
                                            <label for="floatingSelect">
                                                Repeat
                                                every
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 ends_in">
                                    <div class="repeat_inTab  mt-10"
                                        [ngClass]="HideOnOccurenceWeekly ? 'show-div' : 'hide-div'">
                                        <label class="pl-10 r_label">
                                            Repeat
                                            on
                                        </label>
                                        <div class="form-floating">
                                            <div class="form-check">
                                                <input class="form-check-input" checked [checked]="checked(1)"
                                                    (change)="onChange($event, 1)" type="checkbox" id="repeat_tuesday">
                                                <label class="form-check-label" for="repeat_tuesday">
                                                    M
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" [checked]="checked(2)"
                                                    (change)="onChange($event, 2)" type="checkbox" id="repeat_tuesday">
                                                <label class="form-check-label" for="repeat_tuesday">
                                                    T
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" [checked]="checked(3)"
                                                    (change)="onChange($event, 3)" type="checkbox" value="3"
                                                    id="repeat_wednesday">
                                                <label class="form-check-label" for="repeat_wednesday">
                                                    W
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" [checked]="checked(4)"
                                                    (change)="onChange($event, 4)" type="checkbox" value="4"
                                                    id="repeat_thursday">
                                                <label class="form-check-label" for="repeat_thursday">
                                                    Th
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" [checked]="checked(5)"
                                                    (change)="onChange($event, 5)" type="checkbox" value="5"
                                                    id="repeat_friday">
                                                <label class="form-check-label" for="repeat_friday">
                                                    F
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="repeat_inTab  mt-10"
                                        [ngClass]="HideOnOccurenceMonthWeekly ? 'show-div' : 'hide-div'">
                                        <label class="pl-10 r_label">
                                            Repeat
                                            on
                                        </label>
                                        <div class="form-floating">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="month_repeater"
                                                    value="1" formControlName="month_repeater" id="day-of-month"
                                                    checked>
                                                <label class="form-check-label" for="day-of-month">
                                                    day of the month
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="month_repeater"
                                                    value="2" formControlName="month_repeater" id="day-of-week">
                                                <label class="form-check-label" for="day-of-week">
                                                    day of the week
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-floating  mt-10">
                                        <input type="text" placeholder="Select date" class="form-control" bsDatepicker
                                            [minDate]="minDate" [maxDate]="maxDate" formControlName="start_date"
                                            [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-red', showWeekNumbers:false,adaptivePosition: true}"
                                            [daysDisabled]="[6,0]">
                                        <label for="floatingSelect">
                                            Start
                                            on
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-10">
                                <div class="col-md-12 col-sm-12  ends_in">
                                    <div class="end_inTab">
                                        <p>Ends</p>
                                        <div class="form-check">
                                            <input class="form-check-input mt-3" formControlName="recurrent_end"
                                                value="1" type="radio" name="recurrent_end" id="Occurrence_ends_year">
                                            <label class="form-check-label" for="Occurrence_ends_year">
                                                At the end of this year
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input mt-10" formControlName="recurrent_end"
                                                value="2" type="radio" name="recurrent_end" id="Occurrence_ends_after">
                                            <label class="form-check-label occurrence_loop" for="Occurrence_ends_after">
                                                After
                                                <input class="form-control" formControlName="occurrence_number"
                                                    value="1">
                                                Occurrence
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input mt-10" formControlName="recurrent_end"
                                                value="3" type="radio" name="recurrent_end" id="Occurrence_ends_on">
                                            <label class="form-check-label occurrence_on_date" for="Occurrence_ends_on">
                                                On
                                                <input type="text" placeholder="Select date" class="form-control"
                                                    bsDatepicker [minDate]="minDate" [maxDate]="maxDate"
                                                    formControlName="end_date"
                                                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-red', showWeekNumbers:false,adaptivePosition: true}"
                                                    [daysDisabled]="[6,0]">
                                            </label>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer mt-10">
                            <button type="button" (click)="reviewOccurrences(modalData);" class="btn cc_p_btn fs_13">
                                Review
                                Occurrences
                            </button>
                        </div>
                    </div>
                    <div class="review-occurence" *ngIf="!showOccurenceReview">
                        <div class="modal-body pb-0">
                            <div class="row  align-items-center">
                                <div class="col-md-12 col-sm-12">
                                    <p class="text-r-m-16">
                                        Review individual
                                        booking occurrences
                                    </p>
                                </div>
                                <div class="col-md-12 mt-10 mb-5" *ngIf="!showUpdateBtn">
                                    <p class="show-errors">
                                        Booking cannot be
                                        completed: The staff is already booked
                                        for
                                        certain dates during the selected date
                                        range, as noted below in red.
                                    </p>
                                </div>
                            </div>
                            <div class="row  align-items-center mt-10" *ngFor="let list of OccuringData">
                                <div class="col-md-12 col-sm-12">
                                    <div class="review-individual-list not-available" *ngIf="list.is_booked == true">
                                        <p class="user-sub-title clr-red">
                                            {{list.date|date:"EEEE, d MMM"}}
                                            -
                                            {{teacher.teacher.user.full_name}} -
                                            {{list.start_time}} -
                                            {{list.end_time}}
                                        </p>
                                        <span (click)="removeOccurrence(list.date)">
                                            <i class="bi bi-x-lg clr-red"></i>
                                        </span>
                                    </div>
                                    <div class="review-individual-list" *ngIf="list.is_booked == false">
                                        <p class="user-sub-title">
                                            {{list.date|date:"EEEE, d MMM"}}
                                            -
                                            {{teacher.teacher.user.full_name}} -
                                            {{list.start_time}} -
                                            {{list.end_time}}
                                        </p>
                                        <span (click)="removeOccurrence(list.date)">
                                            <i class="bi bi-x-lg"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer mt-10">
                            <button type="button" (click)="showOccurrences();" class="btn_cc_back h_b">
                                Back
                            </button>
                            <button type="button"
                                (click)="sendPreconfrimBooking('repeating', modalData.enable_preconfirmed, modalData.teacher.user.full_name)"
                                class="btn cc_p_btn fs_13" [ngClass]="!showUpdateBtn ? 'disable-btn': ''">
                                Create individual instances
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <!-- For Booked -->
        <ng-template #preConfrimBookedModal>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Booking
                    Details
                </h5>
                <button type="button" class="btn-close" (click)="bsModalRef.hide()" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="modal-body">

                    <div class="row  align-items-center mt-20">
                        <div class="col-md-6 col-sm-6 col-lg-6">
                            <div class="col-md-12 col-lg-12 d-flex align-items-center profile-head-info">
                                <div class="pic-and-check ml-0">
                                    <img alt="img"
                                        [src]="modalData.teacher.meta.profile_image != null ? modalData.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                        class="profile-picture img-complete-match">
                                </div>

                                <div class="pic-and-check ml-10">
                                    <div class="d-flex">
                                        <p>
                                            <span class="s_n">{{modalData.teacher.user.full_name}}</span>
                                            <span>
                                                <app-validated-dict
                                                    [validateDict]="modalData.teacher.validated_dict"></app-validated-dict>
                                            </span>
                                        </p>
                                    </div>
                                    <p class="user-sub-title">
                                        <span
                                            *ngIf="modalData.teacher.meta.you_are_a">{{modalData.teacher.meta.you_are_a}}</span>
                                        <span *ngIf="!modalData.teacher.meta.you_are_a">N/A</span>

                                    </p>
                                    <p class="user-sub-title envelope-icon">
                                        Accreditation No.
                                        <span *ngIf="modalData.teacher.accreditation.accreditation">
                                            <span *ngIf="modalData.teacher.accreditation.accreditation.length > 0">
                                                {{modalData.teacher.accreditation.accreditation[0].accreditation_id}}
                                            </span>
                                        </span>
                                    </p>
                                    <p class="user-sub-title">
                                        <span *ngIf="modalData.teacher.user.previous_login">
                                            Active
                                            {{modalData.teacher.user.previous_login}}
                                        </span>
                                        <span *ngIf="!modalData.teacher.user.previous_login">N/A</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn cc_p_btn fs_13">
                    Cancel
                    booking
                </button>
                <button type="button" class="btn cc_p_btn fs_13">
                    Edit
                    booking
                </button>
            </div>
        </ng-template>

        <ng-template #flexiBookingModal>
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">
                    Not sure of the
                    class or teacher being covered yet?
                </h4>
                <button type="button" (click)="closeFlexiModal()" class="btn-close" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-0">
                <div class="row  align-items-center">
                    <div class="col-md-12">
                        <label for="exampleFormControlTextarea1" class="form-label txt-regular">
                            Send this request as
                            a
                            Flexi Booking so they are aware. You can update the
                            booking details when you have more
                            details.
                        </label>
                        <div class="ml-25 mt-10">
                            <div class="form-check">
                                <input class="form-check-input mt-4" (change)="changeMandatory('not_mandaotry')"
                                    formControlName="flexi_booking" value="1" type="radio" name="flexi_booking"
                                    id="flexi_booking">
                                <label class="form-check-label" for="flexi_booking">
                                    Yes, send as a Flexi booking
                                </label>
                            </div>
                            <div class="form-check mt-5">
                                <input class="form-check-input mt-4" (change)="changeMandatory('mandaotry')"
                                    formControlName="flexi_booking" value="0" type="radio" name="flexi_booking"
                                    id="no_flexi_booking">
                                <label class="form-check-label" for="no_flexi_booking">
                                    No, I’ll enter teacher and class being
                                    covered
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer mt-10">
                <!-- <button type="button"  (click)="bsModalRef.hide()" class="btn_cc_back">Back</button> -->
                <button type="button" (click)="checkFlexyFilled()" class="btn cc_p_btn fs_13">
                    Continue
                </button>
            </div>
        </ng-template>
        <ng-template #BookingRequestModal>
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">
                    Not sure of the
                    class or teacher being covered yet?
                </h4>
                <button type="button" (click)="closeRequestModal()" class="btn-close" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-0">
                <div class="row  align-items-center">
                    <div class="col-md-12">
                        <label for="exampleFormControlTextarea1" class="form-label txt-regular">
                            Send this request as
                            a
                            Flexi Booking so they are aware. You can update the
                            booking details when you have more
                            details.
                        </label>
                        <div class="ml-25 mt-10">
                            <div class="form-check"></div>
                            <div class="form-check mt-5">
                                <input class="form-check-input" (change)="changeMandatory('mandaotry')"
                                    formControlName="flexi_booking" value="0" type="radio" name="flexi_booking"
                                    id="no_flexi_booking">
                                <label class="form-check-label" for="no_flexi_booking">
                                    No, I’ll enter teacher and class being
                                    covered
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer mt-10">
                <!-- <button type="button"  (click)="bsModalRef.hide()" class="btn_cc_back">Back</button> -->
                <button type="button" (click)="checkFlexyFilled()" class="btn cc_p_btn fs_13">
                    Continue
                </button>
            </div>
        </ng-template>

        <ng-template #sendPreconfirmBooking>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Booking Invite
                    Details
                </h5>
                <button type="button" (click)="closeBookingModal()" class="btn-close" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-0">
                <div class="row  align-items-center">
                    <div class="col-md-12">
                        <div class="col-md-12 col-sm-6">
                            <div class>
                                <div class="form-floating mt-10">
                                    <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                                        [multiple]="false" formControlName="time_to_live">
                                        <ng-option value="300">
                                            5
                                            minutes
                                        </ng-option>
                                        <ng-option value="900">
                                            15
                                            minutes
                                        </ng-option>
                                        <ng-option value="1800">
                                            30
                                            minutes
                                        </ng-option>
                                        <ng-option value="3600">
                                            1
                                            hour
                                        </ng-option>
                                        <ng-option value="7200">
                                            2
                                            hours
                                        </ng-option>
                                    </ng-select>
                                    <label for="floatingSelect">
                                        Close booking
                                        after
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-6 mt-10">
                                <div class="d-flex align-items-center">
                                    <label class="form-check-label delay-send-label">
                                        Delay
                                        send
                                    </label>
                                    <div class="form-check form-switch delay-send">
                                        <input (change)="showDelayTime($event)" formControlName="schedule_booking"
                                            type="checkbox" role="switch" id="delay-send" class="form-check-input c-p">
                                    </div>
                                </div>
                                <p class="delay-send-text mt-10">
                                    Turns on the
                                    delay time of booking request to you
                                    invite list.
                                </p>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div *ngIf="showDelay" id="filter_timeRange_startTime"
                                    class="form-control fit-content input-group schudule_time mt-5">
                                    <div class="form-floating d-flex">
                                        <ng-select [searchable]="false" [clearable]="false"
                                            [dropdownPosition]="'bottom'" [multiple]="false" id="schudule_time_hrs"
                                            formControlName="schudule_time_hours" name="schudule_time_hrs" class>
                                            <ng-option *ngFor="let item of hours_d"
                                                value="{{item?.value}}">{{item?.title}}</ng-option>
                                        </ng-select>
                                        :
                                        <ng-select [searchable]="false" [clearable]="false"
                                            [dropdownPosition]="'bottom'" [multiple]="false" id="schudule_time_mins"
                                            formControlName="schudule_time_mins" name="schudule_time_mins"
                                            class="ml-10">
                                            <ng-option *ngFor="let items of minutes_d"
                                                value="{{items?.value}}">{{items?.title}}</ng-option>
                                        </ng-select>
                                        <ng-select [searchable]="false" [clearable]="false"
                                            [dropdownPosition]="'bottom'" [multiple]="false"
                                            formControlName="schedule_day" name class>
                                            <ng-option value="today">Today</ng-option>
                                            <ng-option value="tomorrow">Tomorrow</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer mt-10">
                <button type="button" class="btn cc_p_btn fs_13"
                    (click)="sendPreconfrimBooking('bookingRequest', true ,'')">
                    Send
                    Invite
                </button>
            </div>
        </ng-template>
    </form>
    <button *ngIf="showTopBtn" (click)="topFunction()" class="myBtn" title="Go to top"><i class="bi bi-arrow-up"></i></button>

</div>