import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  send_data = new Subject<any>();
  isPublicPage = new Subject<any>();
  navData = new Subject<any>();
  header = new Subject<any>();
  school_logo = new Subject<any>();
  current_plan = new Subject<any>();
  setValData = new Subject<any>();
  is_premium_subscription = new Subject<any>();
  constructor() { }
}