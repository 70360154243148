import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { HttpInterceptor } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, filter, mergeMap, switchMap, take } from 'rxjs/operators';
import { AppConfig } from '../app-config';
import { environment } from '../../../environments/environment'
import { BehaviorSubject } from 'rxjs';
// Services
import { AuthenticationService } from './authentication.service';
import { SchoolData } from 'src/app/models/schoolUser';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptorInterceptor implements HttpInterceptor {
  public testRefreshToen: any;
  public tokenData: any;
  public schoolProps!: SchoolData;
  // Variables
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public authService: AuthenticationService,
    public router: Router,
    private http: HttpClient,
    private apiUrl: AppConfig,
    private ngxService: NgxUiLoaderService
  ) {
  }

  token: any;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // this.ngxService.start();
    this.token = this.authService.getToken(); //auth is provided via constructor.
    // console.log(this.token);
    // debugger;
    if (this.token != null || this.token != '') {
      // if (this.apiUrl.createToken != environment.apiUrl + 'oauth/v2/token/') {
      req = req.clone({
        headers: req.headers.append('Authorization', "Bearer " + this.token)
      })
      // }
      // this.ngxService.stop();
    }


    return next.handle(req).pipe(
      catchError((err) => {
        if (err.status === 401) {
          //Genrate params for token refreshing
          console.log("calling")
          console.log("refresh_token",this.authService.getRefreshToken())
          let params = {
            client_id: environment.client_id,
            client_secret: environment.client_secret,
            refresh_token: this.authService.getRefreshToken(),
            grant_type: 'refresh_token',
          };
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            req = this.addHeader(req);
            // console.log('Token request');
            return this.http.post(this.apiUrl.createToken, params).pipe(
              switchMap((token: any) => {
                // console.log('inside token');
                // console.log(token);
                this.isRefreshing = false;

                if (token && token.access_token && token.refresh_token) {
                  sessionStorage.setItem('accessToken', token.access_token);
                  sessionStorage.setItem('refreshToken', token.refresh_token);

                  document.cookie = "access_token=" + token.access_token + ";domain=." + environment.cookiesDomain + ";path=/";
                  document.cookie = "refresh_token=" + token.refresh_token + ";domain=." + environment.cookiesDomain + ";path=/";
                }
                // request = this.addHeader(request);
                // console.log('request: ', request);
                this.refreshTokenSubject.next(token);
                return next.handle(this.addHeader(req));
              }),catchError((permissionsErr: HttpErrorResponse) => {
                // console.log(permissionsErr);
                  if (this.router) {
                    this.authService.loggedOut();
                    this.router.ngOnDestroy();
                  }
                  return throwError(permissionsErr);
                }));

          } else {
            // console.log('inside else call');
            // console.log('token : ', this.refreshTokenSubject.getValue());
            return this.refreshTokenSubject.pipe(
              filter(token => (token != null && token != undefined)),
              take(1),
              switchMap(() => {
                // console.log('adding header in else');
                return next.handle(this.addHeader(req))

              }));
          }

        } else {
          //Logout from account or do some other stuff
        }
        return throwError(err);
      })
    )
  }

  private addHeader(request: HttpRequest<any>) {
    let getEndPoint = request.url.split('/')[request.url.split('/').length - 1];
    if (getEndPoint !== 'refreshToken') {
      const accessToken = this.authService.getToken();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    } else if (getEndPoint === 'refreshToken') {
      const refreshToken = sessionStorage.getItem('refreshToken');
      request = request.clone({
        setHeaders: {
          // applicationCode: environment.applicationCode,
          'refresh-token': `${refreshToken}`,
        }
      });
    }
    return request;
  }

}