<div class="modal-header">
    <!-- <h4 *ngIf="type == 'add'" class="modal-title" id="addEdit">Staff notes</h4> -->
    <!-- <h4 *ngIf="type == 'edit'" class="modal-title" id="addEdit">Edit notes</h4> -->
    <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
</div>

<div class="modal-body pb-0">
    <div class="row align-items-center">
        <div class="col-md-12">
            <form [formGroup]="addEditNotes">
                <label *ngIf="type == 'add'" for="exampleFormControlTextarea1" class="form-label user-title-m">Staff
                    notes for {{teacherName}}
                    *</label>
                <label *ngIf="type == 'edit'" for="exampleFormControlTextarea1" class="form-label user-title-m">Edit
                    staff notes for {{teacherName}}
                    *</label>
                <textarea class="form-control mt-5" (keyup)="checkLength()" maxlength=200 formControlName="notes" placeholder="Add Message here..."
                    id="exampleFormControlTextarea1" rows="6"></textarea>
                    <p class="txt-regular mt-10 mb-0">Max ({{this.ShowLenght}} characters)</p>
                <div *ngIf="submitted">
                    <span class="show-errors" *ngIf="f['notes'].errors?.['required'] || f['notes'].errors?.['whitespace']">* Notes is
                        required</span>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer mt-10">
    <button type="button" (click)="addNotes()" class="btn cc_p_btn fs_13">Add notes</button>
</div>