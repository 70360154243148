<!-- <button (click)="testPopup()">Confirmation Popup</button> -->

<div class="booking-request-page pb-90 p-15" #bookingRequest>
  <div class="row mt-10">
    <div class="col-xl-12">
      <!-- <h1>{{teacherLengthLimit}} == {{(teachersList | async).length}}</h1> -->
      <form [formGroup]="bookingFilterForm">
        <!-- Filters -->
        <div class="row mt-10">
          <div class="col-md-3 col-sm-6 col-lg-3 mt-10">
            <input  type="text" aria-label="Calendar" [attr.disabled]="addMoreBooking ? '' : null" placeholder="Choose date"
              class="form-control" bsDaterangepicker [bsConfig]="{
                rangeInputFormat: 'DD/MM/YYYY',
                customTodayClass: 'custom-today-class',
                containerClass: 'theme-red',
                showWeekNumbers: false,
                displayMonths: 1
              }" [minDate]="minDate" [daysDisabled]="[6, 0]" [(ngModel)]="bsRangeValue"
              (ngModelChange)="getFilterInputs('regular')" [ngModelOptions]="{ standalone: true }" />
            <!-- <input aria-label="" type="text" class="form-control date" placeholder="Pick the multiple dates"> -->
          </div>
          <div class="col-md-2 col-sm-6 col-lg-2 mt-10">
            <!-- <ng-select [items]="noOfStaffs" bindLabel="title" autofocus bindValue="value" placeholder="No of staff"
                            [attr.disabled]="addMoreBooking ? '' : null" formControlName="no_of_staff" aria-label="no_of_staff">
                        </ng-select> -->
            <!-- <ng-select appearance="outline" [readonly]="true"   labelForId="yesno" [searchable]="false" formControlName="no_of_staff" [disabled]="true">
                            <ng-option *ngFor="let staffs of noOfStaffs" [value]="staffs.value">{{staffs.value}}</ng-option>
                        </ng-select> -->
            <ng-select (change)="makePlural()" [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
              [multiple]="false" [readonly]="addMoreBooking" aria-label="No of staff" formControlName="no_of_staff">
              <!-- <ng-option [disabled]="true">No of staff</ng-option> -->
              <ng-option *ngFor="let n of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" value="{{ n }}">
                {{ n }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-3 col-sm-6 col-lg-3 mt-10">
            <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
              [readonly]="addMoreBooking" aria-label="Default select example" formControlName="timerange"
              (change)="getSpecialValue()">
              <!-- <ng-option [disabled]="true">Booking range</ng-option> -->
              <ng-option *ngFor="let timings of schoolTimings" value="{{ timings.value }}">
                {{ timings.timing }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-4 col-sm-6 col-6 col-lg-4 mt-10 d-flex custom-input-select" *ngIf="hidetimeRange">
            <!-- <input aria-label="" type="time" class="form-control" formControlName="start_time"
                            [ngClass]="getFormControl['timerange'].value == 'special' ? 'c-p':'input-cursor-na'"
                            (change)="getFilterInputs('regular')" /> -->
            <div class="form-control input-group">
              <div class="form-floating">
                <div class="d-flex mt-5">
                  <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                    [readonly]="addMoreBooking" formControlName="start_hrs" [ngClass]="
                      getFormControl['timerange'].value == 'special'
                        ? 'c-p'
                        : 'input-cursor-na'
                    " aria-label="start_hrs">
                    <ng-option *ngFor="let item of hours_d" value="{{item?.value}}">{{item?.title}}</ng-option>
                  </ng-select>
                  <span class="ml-10 mr-10">:</span>
                  <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                    [readonly]="addMoreBooking" formControlName="start_min" [ngClass]="
                      getFormControl['timerange'].value == 'special'
                        ? 'c-p'
                        : 'input-cursor-na'
                    " aria-label="start_min">
                    <ng-option *ngFor="let items of minutes_d" value="{{items?.value}}">{{items?.title}}</ng-option>
                  </ng-select>
                </div>
                <label for="floatingSelect">Start time</label>
              </div>
            </div>

            <!-- <input aria-label="" type="time" class="form-control" formControlName="start_time"
                            [ngClass]="getFormControl['timerange'].value == 'special' ? 'c-p':'input-cursor-na'"
                            (change)="getFilterInputs('regular')" /> -->
            <div class="form-control ml-3 input-group">
              <div class="form-floating">
                <div class="d-flex mt-5">
                  <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                    [readonly]="addMoreBooking" formControlName="end_hrs" [ngClass]="
                      getFormControl['timerange'].value == 'special'
                        ? 'c-p'
                        : 'input-cursor-na'
                    " aria-label="end_hrs">
                    <ng-option *ngFor="let item of hours_d" value="{{item?.value}}">{{item?.title}}</ng-option>
                  </ng-select>
                  <span class="ml-10 mr-10">:</span>
                  <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                    [readonly]="addMoreBooking" formControlName="end_min" [ngClass]="
                      getFormControl['timerange'].value == 'special'
                        ? 'c-p'
                        : 'input-cursor-na'
                    " aria-label="end_min">
                    <ng-option *ngFor="let items of minutes_d" value="{{items?.value}}">{{items?.title}}</ng-option>
                  </ng-select>
                </div>
                <label for="floatingSelect">End time</label>
              </div>
            </div>
          </div>
          <!-- {{getFormControl['timerange'].value != 'special'}} -->
          <!-- <div class="col-md-3 col-sm-6  col-lg-2 mt-10">
                        <input aria-label="" type="time" class="form-control" formControlName="end_time"
                            [ngClass]="getFormControl['timerange'].value == 'special' ? 'c-p':'input-cursor-na'"
                            (change)="getFilterInputs('regular')" />
                    </div> -->

          <div class="col-md-3 col-sm-6 mt-10">
            <ng-select (change)="makePlural()" (change)="getFilterInputs('setBookingRole')" [searchable]="false"
              [clearable]="false" formControlName="booking_role_type" [readonly]="addMoreBooking"
              [dropdownPosition]="'bottom'" [multiple]="false" placeholder="Role type">
              <ng-option *ngFor="let tag of bookingRoleType" [value]="tag['id']" [disabled]="tag['is_parent']">
                {{ tag["title"] }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-3 col-sm-6 mt-10">
            <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
              [readonly]="addMoreBooking" [items]="smart_group" bindLabel="title" bindValue="value"
              placeholder="Choose smart group" (change)="getFilterInputs('smartFilter')"
              formControlName="booking_smartgroup" aria-label="booking_samrtgroup">
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.title }}">{{ item.title }}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-md-3 col-sm-6 mt-10">
            <ng-select (change)="checkMan()" [dropdownPosition]="'bottom'" [readonly]="addMoreBooking" [addTag]="true"
              [items]="school_staff" bindLabel="data" bindValue="data" labelForId="state" [multiple]="false"
              placeholder="{{ filtersArray.custom_field2 }}" [required]="
                filtersArray.booking_teachercovered ||
                filtersArray.booking_teachercovered == null
              " clearAllText="Clear" formControlName="booking_teachercovered">
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.data }}">{{ item.data }}</div>
              </ng-template>
            </ng-select>
            <div *ngIf="filtersArray.teachercovered_required">
              <div *ngIf="submitted">
                <span class="show-errors" *ngIf="f['booking_teachercovered'].errors?.['required']">* {{
                  filtersArray.custom_field2 }} is required</span>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mt-10">
            <input aria-label="Class being coverd" class="form-control" (keyup)="checkMan()" [required]="filtersArray.classbeingcovered_required"
              [attr.disabled]="addMoreBooking ? '' : null" type="text" formControlName="booking_classcovered"
              placeholder="{{ filtersArray.custom_field1 }}" />
            <div *ngIf="filtersArray.classbeingcovered_required">
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="f['booking_classcovered'].errors?.['required'] || f['booking_classcovered'].errors?.['whitespace']">*
                  {{ filtersArray.custom_field1 }} is required</span>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mt-10">
            <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
              [required]="filtersArray.absencereason_required" [readonly]="addMoreBooking"
              formControlName="booking_absencereason" aria-label="booking-absence-reason">
              <ng-option *ngFor="let absense of filtersArray.absence_reason" value="{{ absense.value }}">
                {{ absense.title }}
              </ng-option>
            </ng-select>
            <div *ngIf="filtersArray.absencereason_required">
              <div *ngIf="submitted">
                <span class="show-errors" *ngIf="f['booking_absencereason'].errors?.['required']">* Absence reason is
                  required</span>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 mt-10" *ngIf="this.schoolProps.properties.is_doe_school">
            <div class="d-flex flex-prop">
              <div class="form-check">
                <input aria-label="Approved to teach" class="form-check-input c-p mt-4" type="checkbox" id="is-validated"
                  formControlName="filter_is_validated" (change)="getFilterInputs('regular')">
                <label class="form-check-label c-p ml-5" for="is-validated">
                  Approved to teach only
                </label>
              </div>
              <!-- <div > -->
              <div class="form-check">
                <input aria-label="Small Group Tuition" class="form-check-input c-p mt-4" type="checkbox" id="small_group_tuition"
                  formControlName="is_sgt" (change)="getFilterInputs('regular')" />
                <label class="form-check-label c-p" for="small_group_tuition">
                  Small Group Tuition (SGT)
                </label>
              </div>
              <div *ngIf="from_page_cookie == 'loginas'">
                <div class="form-check">
                  <input aria-label="NSBTS/PSL Staff" class="form-check-input c-p mt-4" type="checkbox" id="NSBTS_PSL" formControlName="is_nsbts"
                    (change)="getFilterInputs('regular')" />
                  <label class="form-check-label c-p" for="NSBTS_PSL">
                    NSBTS/PSL Staff
                  </label>
                </div>

                <div class="form-check">
                  <input aria-label="Other Staff" class="form-check-input c-p mt-4" type="checkbox" id="other_staff"
                    formControlName="is_other_staff" (change)="getFilterInputs('regular')" />
                  <label class="form-check-label c-p" for="other_staff">
                    Other Staff
                  </label>
                </div>

                <div class="form-check">
                  <input aria-label="Corporate Staff" class="form-check-input c-p mt-4" type="checkbox" id="corporate_staff"
                    formControlName="is_corporate_staff" (change)="getFilterInputs('regular')" />
                  <label class="form-check-label c-p" for="corporate_staff">
                    Corporate Staff
                  </label>
                </div>
              </div>
              <!-- </div> -->

            </div>
          </div>
        </div>

        <div class="row align-items-center" *ngIf="!showInformation">
          <div class="col-md-12 col-sm-6 mt-10">
            <p class="text-regular-bold c-p fit-content h_b_ul clr-cc-vilot" (click)="showAdditionalInformation()"
              [ngClass]="
                showInformation
                  ? 'additional-information-btn hide'
                  : ' additional-information-btn show'
              ">
              <i class="bi bi-plus fs-20 v-align-m"></i> Add more booking
              information
            </p>
          </div>
        </div>

        <div class="row align-items-center mt-10 bg-light pt-10 pb-10" [ngClass]="
            showInformation
              ? 'additional-informations show'
              : ' additional-informations hide'
          ">
          <p class="text-regular-medium">Additional information</p>
          <div class="row align-items-center p-0 m-0 mt-10">
            <div class="col-md-6 col-sm-12">
              <input aria-label="Booking attachment link" class="form-control" [attr.disabled]="addMoreBooking ? '' : null" type="text"
                formControlName="booking_attachement_link" placeholder="Insert link to uploaded document" />
            </div>
          </div>
          <div class="row align-items-center p-0 m-0 mt-10">
            <div class="col-md-6 col-sm-12">
              <textarea class="form-control" [attr.disabled]="addMoreBooking ? '' : null" type="text"
                formControlName="booking_notes"
                placeholder="Add information that you want to send to the staff you are booking">
                </textarea>
            </div>
          </div>

          <div class="row align-items-center p-0 m-0 mt-10">
            <div class="col-md-6 col-sm-12 add-image">
              <label class="filelabel" for="FileInput" [attr.disabled]="addMoreBooking ? '' : null">
                <p class="b clr-grey mt-5">Attachment</p>
                <p class="clr-grey mt-5">Drag and drop a file</p>
                <p class="text-regular mt-5">Or</p>
                <div class="d-flex justify-content-center mt-5">
                  <p class="d-flex file-upload-text align-items-center">
                    <i class="bi bi-paperclip mr-5"></i> Upload file
                  </p>
                </div>
                <p class="text-regular mt-5 file_name" *ngIf="fileName">
                  <!-- <span *ngIf="!fileName" class="clr-red ">No file
                                        choosen</span> -->
                  <span  class="file_attached">{{ fileName }}</span>
                  <button class="ml-4"  (click)="removeFile($event); $event.stopPropagation()"><i class="bi bi-x-lg"></i></button>
                </p>

                <input aria-label="Booking Attachment" class="FileUpload1 form-control" [attr.disabled]="addMoreBooking ? '' : null"
                  formControlName="booking_attachment" id="FileInput" (change)="onFileSelected($event)"
                  name="booking_option_attachment" type="file" />
              </label>
            </div>
          </div>
          <p class="mt-10">
            This information will be sent to the teacher you are booking
          </p>
          <div class="row align-items-center p-0 m-0 mt-10">
            <div class="col-md-4 col-sm-6 mt-10">
              <input aria-label="payroll field 1" class="form-control" type="text" [attr.disabled]="addMoreBooking ? '' : null"
                formControlName="booking_payrollfield1" placeholder="{{
                  filtersArray.payroll_report_fields?.payroll_field_label_1
                }}" />
            </div>
            <div class="col-md-4 col-sm-6 mt-10">
              <input aria-label="payroll field 2" class="form-control" type="text" [attr.disabled]="addMoreBooking ? '' : null"
                formControlName="booking_payrollfield2" placeholder="{{
                  filtersArray.payroll_report_fields?.payroll_field_label_2
                }}" />
            </div>
            <div class="col-md-4 col-sm-6 mt-10">
              <input aria-label="payroll field 3" class="form-control" type="text" [attr.disabled]="addMoreBooking ? '' : null"
                formControlName="booking_payrollfield3" placeholder="{{
                  filtersArray.payroll_report_fields?.payroll_field_label_3
                }}" />
            </div>
            <p class="mt-10">
              These field names are customisable and can be changed in the
              school
              <span class="text-regular-bold c-p h_b" (click)="openAdminSettings()">settings</span>
            </p>
          </div>
          <div class="row align-items-center p-0 m-0 mt-10">
            <div class="col-md-4 col-sm-6 mt-10">
              <label class="compact-label control-label required" for="booking_option_leaveApproved">{{
                filtersArray.payroll_report_fields
                ?.leave_approved_labell_value
                }}</label>
              <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                id="booking_option_leaveApproved" [readonly]="addMoreBooking" formControlName="booking_leaveapproved"
                aria-label="Leave approved">
                <!-- <ng-option value="" disabled selected>{{filtersArray.payroll_report_fields.leave_approved_labell_value}}</ng-option> -->
                <ng-option value="1">Yes</ng-option>
                <ng-option value="0">No</ng-option>
              </ng-select>
            </div>
            <p class="mt-10">
              This field is displayed in your payroll report and is customisable
              in the school
              <span class="text-regular-bold c-p h_b"><a (click)="openAdminSettings()">settings</a></span>
            </p>
          </div>
          <p class="mt-10 text-regular-bold c-p fit-content clr-cc-vilot" (click)="showAdditionalInformation()">
            - Show less
          </p>
        </div>
        <div class="row align-items-center pt-10">
          <div class="col-md-12">
            <p>
              <i class="bi bi-info-circle-fill"></i> You are looking to book
              <span class="text-regular-bold">{{ getFormControl["no_of_staff"].value }} {{this.selectedRole}}</span>
              <span *ngIf="getFormControl['timerange'].value != ''">
                for the
                <span class="text-regular-bold">
                  {{
                  getFormControl["timerange"].value == "full_day"
                  ? "Full Day"
                  : getFormControl["timerange"].value == "first_half"
                  ? "Half Day AM"
                  : getFormControl["timerange"].value == "second_half"
                  ? "Half Day PM"
                  : "Special"
                  }}
                </span>
                on
                <span class="text-regular-bold">{{
                  getFormControl["booking_dates"].value
                  }}</span>
              </span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="row mt-15">
              <div class="col-lg-6 col-xl-5 col-md-4">
                <div class="addAllDiv">
                  <div class="select-filter-list">
                    <input aria-label="Select teachers" type="checkbox" data-bs-toggle="dropdown" aria-expanded="true"
                      class="dropdown-toggle text-regular-bold c-p form-check-input mr-10" />
                    <ul class="dropdown-menu c-p txt-regular px-10">
                      <li class="dropdown-item" *ngFor="let filters of selectFilter"
                        (click)="setSelectFilter(filters?.value)" [ngClass]="
                      selectedFilter == filters?.value ? 'selected-filter' : ''
                    ">
                        <a>{{ filters?.title }}</a>
                      </li>
                    </ul>
                  </div>
                  <!-- <p class="grey-3-14">{{teacherLengthLimit}} Match found</p> -->
                  <p class="text-r-m-16 ml-15 booking-selected">
                    {{ checkBoxSelectedTeacher.length }} Selected
                  </p>

                  <span class="d-flex ml-15 align-items-center">
                    <i class="bi bi-plus fs-20"></i>
                    <p class="text-regular-bold c-p clr-cc-vilot booking-add-all">
                      <span *ngIf="checkBoxSelectedTeacher.length > 0" (click)="inviteSelectedCheck('selected')">Add
                        Selected</span>
                      <span *ngIf="checkBoxSelectedTeacher.length == 0" (click)="inviteSelectedCheck('all')">Add
                        all</span>
                    </p>
                  </span>
                </div>
              </div>

              <div class="col-lg-6 col-xl-7 col-md-8">
                <div class="addAllDiv1 top-filter">
                  <div class="form-check d-flex align-items-center">
                    <input aria-label="is certified" class="form-check-input c-p" type="checkbox" id="custom"
                      formControlName="filter_is_certified" (change)="getFilterInputs('regular')">
                    <label class="form-check-label cb_label  c-p ml-5" for="custom">
                      {{custom_text}}
                    </label>
                  </div>
                  <div class="c-p text-regular-bold clr-cc-vilot h_b showHideTag t-dec-none" (click)="showfilters()"><i
                      class="bi mr-4 fs-16" [ngClass]="showFilters ? 'bi-eye-slash-fill' : 'bi-eye'"></i>
                    <span *ngIf="showFilters">Hide</span><span *ngIf="!showFilters">Show</span> Filters
                  </div>
                  <div class="c-p text-regular-bold clr-cc-vilot h_b showHideTag t-dec-none" (click)="showtags()"><i
                      class="bi mr-4 fs-16" [ngClass]="tagsAndSubjects ? 'bi-eye-slash-fill' : 'bi-eye'"></i>
                    <span *ngIf="tagsAndSubjects">Hide</span><span *ngIf="!tagsAndSubjects">Show</span> details
                  </div>

                  <div class="dropdown sortByBR">
                    <p data-bs-toggle="dropdown" class="mt-2 d-flex dropdown-toggle clr-cc-vilot h_b  c-p">
                      <span><i class="bi bi-filter-left mr-5"></i></span>
                      <span class="mt-1">Sort by</span>

                    </p>
                    <!-- <button class="btn" (click)="showInviteList= !showInviteList">Click Here</button> -->
                    <!-- <h2>{{showInviteList}}</h2> -->
                    <ul class="dropdown-menu txt-regular c-p">
                      <li>
                        <a class="dropdown-item" (click)="sortBy('name')">Name</a>
                      </li>
                      <li>
                        <a class="dropdown-item" (click)="sortBy('lastSeen')">Last Seen</a>
                      </li>
                      <li>
                        <a class="dropdown-item" (click)="sortBy('distance')">Distance</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="" *ngIf="showFilters">
              <div class="row align-items-center mt-10">
                <div class="col-md-4 col-sm-6 mt-10">
                  <div class="input-group">
                    <input aria-label="Search by name" formControlName="filter_name" id="search-by-name" type="text" placeholder="Search by name"
                      class="form-control" aria-label="Search by name" />
                    <button class="input-group-text" for="search-by-name" (click)="getFilterInputs('regular')"><i
                        class="bi bi-search"></i></button>
                  </div>
                </div>

                <div class="col-md-4 col-sm-6 mt-10">
                  <ng-select aria-label="Search by tags" [dropdownPosition]="'bottom'" [clearable]="false" [selectableGroupAsModel]="false"
                    [closeOnSelect]="false" [selectableGroup]="true" [items]="subjectsAndTags" [addTag]="false"
                    formControlName="filter_tags" bindLabel="title" bindValue="title" groupBy="tags" [multiple]="true"
                    placeholder="Search by tags" (change)="getFilterInputs('regular')">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input aria-label="Multiple tag select" id="item-{{ index }}" [checked]="item$.selected" class="form-check-input mt-0"
                        type="checkbox" />
                      <label *ngIf="!item$.selected">Select all</label>
                      <label *ngIf="item$.selected">Unselect all</label>
                      <!-- <span class="float-right" aria-hidden="true">Clear</span> -->
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input aria-label="Single tag select" id="item-{{ index }}" class="form-check-input mt-0" value="{{ item.title }}"
                        [checked]="item$.selected" type="checkbox" />
                      <label>{{ item.title }}</label>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngFor="let item of items | slice : 0 : 2; index as i">
                        <span class="ng-value-label">{{ items[i].title }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                      </div>
                      <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                      </div>
                    </ng-template>
                    <!-- <ng-template ng-optgroup-tmp let-item="item" let-index="index">
                                        <input aria-label="" id="item.index" type="checkbox"/> Select all
                                     </ng-template>
                                     <ng-template  ng-multi-label-tmp ng-option-tmp let-item="item" let-index="index">
                                        <input aria-label="" id="item.index" type="checkbox"/> {{item.title}}
                                            <div class="ng-value" *ngIf="item.length > 2">
                                                 <span class="ng-value-label">{{item.length - 2}} more...</span>
                                            </div>
                                    </ng-template> -->
                  </ng-select>
                  <!-- [closeOnSelect]="false" -->
                </div>
                <div class="col-md-4 col-sm-6 mt-10">
                  <ng-select formControlName="filter_experience_items" [clearable]="false"
                    [selectableGroupAsModel]="false" [closeOnSelect]="false" [selectableGroup]="true"
                    [dropdownPosition]="'bottom'" [items]="levelFilter" [multiple]="true" bindLabel="title"
                    groupBy="levels" bindValue="value" placeholder="Search by level"
                    (change)="getFilterInputs('regular')">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input aria-label="Multiple level select" id="item-{{ index }}" [checked]="item$.selected" class="form-check-input mt-0"
                        type="checkbox" />
                      <label *ngIf="!item$.selected">Select all</label>
                      <label *ngIf="item$.selected">Unselect all</label>
                      <!-- <span class="float-right" aria-hidden="true">Clear</span> -->
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input aria-label="Single level select" id="item-{{ index }}" class="form-check-input mt-0" value="{{ item.title }}"
                        [checked]="item$.selected" type="checkbox" />
                      <label>{{ item.title }}</label>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngFor="let item of items | slice : 0 : 2; index as i">
                        <span class="ng-value-label">{{ items[i].title }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                      </div>
                      <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="row align-items-center mt-10">
                <div class="col-md-3 col-sm-6 mt-10" id="custom-group-ng">
                  <ng-select [closeOnSelect]="false" [selectableGroup]="true" [selectableGroupAsModel]="false"
                    [clearable]="false" [searchable]="false" [items]="consumerRoleType"
                    formControlName="filter_consumer_type" [dropdownPosition]="'bottom'" [multiple]="true"
                    placeholder="Role type" (change)="getFilterInputs('regular')" groupBy="type" bindLabel="title"
                    bindValue="id">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input aria-label="" id="item-{{ index }}" [checked]="item$.selected" class="form-check-input mt-0"
                        type="checkbox" />
                      <label>{{ item.type }}</label>
                      <!-- <label *ngIf="item$.selected">Unselect all</label>  -->
                      <!-- <span class="float-right" aria-hidden="true">Clear</span> -->
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input aria-label="" id="item-{{ index }}" class="form-check-input mt-0" value="{{ item.title }}"
                        [checked]="item$.selected" type="checkbox" />
                      <label>{{ item.title }}</label>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value" *ngFor="let item of items | slice : 0 : 2; index as i">
                        <span class="ng-value-label">{{ items[i].title }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                      </div>
                      <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                      </div>
                    </ng-template>
                    <!-- <ng-option *ngFor="let tag of consumerType" [value]="tag['id']" [disabled]="tag['is_parent']">
                                    {{tag['title']}}
                                </ng-option> -->
                    <!-- <ng-option *ngFor="let tag of consumerType" [value]="tag['id']" [disabled]="tag['is_parent']"> -->
                    <!-- <ng-template ng-multi-label-tmp  let-items="items" let-clear="clear" >
                                        <div  class="ng-value" *ngFor="let item of items;  index as i">
                                            <span class="ng-value-label">{{item.title}}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                        </div>
                                    </ng-template> -->
                    <!-- </ng-option> -->
                  </ng-select>
                </div>
                <div class="col-md-3 col-sm-6 mt-10">
                  <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                    id="quick_filter_rating_rating" formControlName="filter_rating"
                    (change)="getFilterInputs('regular')">
                    <ng-option *ngFor="let rating of filtersArray.rating_choices" value="{{ rating.value }}">
                      {{ rating.title }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-3 col-sm-6 mt-10">
                  <ng-select [clearable]="false" [dropdownPosition]="'bottom'" [searchable]="false" [multiple]="false"
                    formControlName="filter_distance" (change)="getFilterInputs('regular')">
                    <ng-option *ngFor="let distance of filtersArray.distance_choices" value="{{ distance.value }}">
                      {{ distance.title }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="col-md-3 col-sm-6 mt-10" id="custom-group-ng">
                  <ng-select id="active_inactive" [clearable]="false" [searchable]="false" [dropdownPosition]="'bottom'"
                    [multiple]="false" formControlName="filter_isactive" (change)="getFilterInputs('regular')">
                    <ng-option value="0">Active / Inactive</ng-option>
                    <ng-option value="1">Active</ng-option>
                    <ng-option value="2">Inactive</ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
            <!-- List of Teachers Card -->

            <div class="mt-20" *ngIf="teachersList | async as teacher">
              <div class="card mt-20 pb-15" *ngFor="let teacher of teacher; index as i">
                <div class="row align-items-center mt-10">
                  <div class="col-md-9 col-sm-8 col-lg-8">
                    <div class="col-md-12 col-lg-12 d-flex align-items-center profile-info">
                      <div class="pic-and-check c_input mr-10 ml-10">
                        <input aria-label="" #checkboxes class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                          (click)="setCheckbox(teacher, i)" [checked]="teacher?.isChecked" [disabled]="
                        teacher.teacher.hideme ||
                        teacher.booking_requeted == true
                      " />
                      </div>
                      <div class="pic-and-check ml-0">
                        <img alt="pic" [src]="
                        teacher.teacher.meta.profile_image != null
                          ? teacher.teacher.meta.profile_image
                          : '../../../../assets/images/teacher-static-profile.png'
                      " class="profile-picture img-{{
                        teacher.avail_status
                      }}-match" />
                        <br>
                        <div class="s_r_div">
                          <p class="text-center mt-2" *ngIf="teacher.rating == 0">
                            <i class="bi bi-star"></i>
                            <i class="bi bi-star ml-5"></i>
                          </p>
                          <p class="text-center" *ngIf="teacher.rating == 1">
                            <i class="bi bi-star-fill"></i>
                            <i class="bi bi-star ml-5"></i>
                          </p>
                          <p class="text-center" *ngIf="teacher.rating == 2">
                            <i class="bi bi-star-fill"></i>
                            <i class="bi bi-star-fill ml-5"></i>
                          </p>
                          <p class="text-center  d_2" *ngIf="teacher.rating == -2">
                            <i class="bi bi-star-fill"></i>
                            <i class="bi bi-star-fill ml-5"></i>
                          </p>
                          <p class="text-center d_1" *ngIf="teacher.rating == -1">
                            <i class="bi bi-star-fill"></i>
                            <i class="bi bi-star-fill ml-5"></i>
                          </p>
                        </div>
                      </div>

                      <div class="pic-and-check ml-10">
                        <p class="d-flex align-items-center">
                          <span class="s_n" (click)="
                          getTeacherAvailabilityModal(teacher.teacher.id)
                        ">{{ teacher.teacher.user.full_name }}
                          </span>
                          <span *ngIf="teacher.teacher.is_complete == false" class="bg-tags-custom ml-10">Incomplete
                            Profile
                          </span>
                          <span *ngIf="teacher.is_student && teacher.student_tag != ''" class="s_tag ml-10">{{
                            teacher.student_tag }}
                          </span>

                          <span>
                            <app-validated-dict [validateDict]="teacher.teacher.validated_dict">
                            </app-validated-dict>
                          </span>
                        </p>
                        <p class="user-sub-title">
                          <span *ngIf="teacher.teacher.meta.you_are_a">{{
                            teacher.teacher.meta.you_are_a
                            }}</span>
                          <span *ngIf="!teacher.teacher.meta.you_are_a">N/A</span>
                          <span class="divider-right ml-8 mr-4"></span>
                          <span *ngIf="teacher.teacher.user.previous_login">
                            Active {{ teacher.teacher.user.previous_login }}</span>
                          <span *ngIf="!teacher.teacher.user.previous_login">
                            N/A</span>
                        </p>
                        <p *ngIf="teacher.complete_match" class="complete-match">
                          Compete Match
                        </p>
                        <p *ngIf="!teacher.complete_match" class="partial-match">
                          Partial Match
                        </p>
                        <div class="form-check mt-1">
                          <input aria-label="" [checked]="teacher.is_certified" disabled id="is_certified{{teacher.id}}"
                            type="checkbox" class="form-check-input mt-4">
                          <label class="form-check-label" for="is_certified{{teacher.id}}"> {{custom_text}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--System and tab reponsive section add to list and add notes break point 576px  -->
                  <div class="col-md-3 col-sm-4 col-lg-4 d-flex justify-content-right align-self-flex-start">
                    <div class="add-all-section pr-15" *ngIf="!editOnClone && !addMoreBooking">
                      <p class="text-regular-bold clr-cc-vilot add-invite" *ngIf="!teacher.teacher.hideme" [ngClass]="
                          checkBoxSelectedTeacher.length == 0 ? 'c-p' : 'cursor-na'
                        " (click)="addToInviteList(teacher.teacher)">
                        <i class="bi bi-plus fs-19 v-align-m"></i> Add to invite
                        list
                      </p>

                      <p class="text-regular-bold c-p clr-green add-invite" *ngIf="teacher.teacher.hideme"
                        (click)="removeTeacher(teacher.teacher)">
                        <i class="bi bi-check-lg fs-19 v-align-m"></i> Added to
                        invite list
                      </p>
                      <button (click)="addEditNotes(teacher.teacher.user.full_name,'edit',teacher.notes,teacher.id)"
                        *ngIf="teacher.notes != null && !addMoreBooking"
                        class="notesTooltip  c-p fit-content add-notes mt-10">
                        <span class="text-regular-bold clr-cc-vilot"><i
                            class="bi bi-pencil-fill vertical-align-bottom fs-11 ml-5 mr-3"></i> View notes
                        </span>
                        <span class="notesTooltiptext text-s-m-g-1">{{ teacher.notes }}</span>
                      </button>

                      <button class="text-regular-bold c-p clr-cc-vilot edit-notes mt-10"
                        (click)="addEditNotes(teacher.teacher.user.full_name,'add','',teacher.id)"
                        *ngIf="teacher.notes == null && !addMoreBooking">
                        <i class="bi bi-pencil-fill vertical-align-bottom fs-11 ml-5 mr-3"></i>
                        Add notes
                      </button>
                    </div>
                    <div class="add-all-section pr-15"
                      *ngIf="(addMoreBooking || reOpenBooking) && teacher.booking_requeted == null">
                      <p class="text-regular-bold clr-cc-vilot add-invite"
                        *ngIf="!teacher.teacher.hideme && !teacher.teacher.hidemeNone" [ngClass]="
                          checkBoxSelectedTeacher.length == 0 ? 'c-p' : 'cursor-na'"
                        (click)="addToInviteList(teacher.teacher)">
                        <i class="bi bi-plus fs-19 v-align-m"></i> Add to invite
                        list
                      </p>
                      <p class="text-regular-bold c-p clr-green add-invite"
                        *ngIf="teacher.teacher.hideme && !teacher.teacher.hidemeNone"
                        (click)="removeTeacher(teacher.teacher)">
                        <i class="bi bi-check-lg fs-19 v-align-m"></i> Added to
                        invite list
                      </p>
                    </div>
                    <div class="add-all-section pr-15" *ngIf="editOnClone">
                      <p class="text-regular-bold clr-cc-vilot add-invite" *ngIf="!teacher.teacher.hideme" [ngClass]="
                          checkBoxSelectedTeacher.length == 0 ? 'c-p' : 'cursor-na'"
                        (click)="addToInviteList(teacher.teacher)">
                        <i class="bi bi-plus fs-19 v-align-m"></i> Add to invite
                        list
                      </p>
                      <p class="text-regular-bold c-p clr-green add-invite" *ngIf="teacher.teacher.hideme"
                        (click)="removeTeacher(teacher.teacher)">
                        <i class="bi bi-check-lg fs-19 v-align-m"></i> Added to
                        invite list
                      </p>
                    </div>
                    <!--End of system and tab reponsive section add to list and add notes break point 576px  -->
                  </div>
                </div>

                <div class="row align-items-center  mt-5" *ngIf="tagsAndSubjects">
                  <div class="col-md-12 col-lg-12 ">
                    <div class="d-flex level-sub-info" *ngIf="teacher.teacher.meta.experiance_tree != ''">
                      <div class="col-md-2 col-3">
                        <p class="grey-3-14 mt-5">Levels</p>
                      </div>
                      <div class="col-md-9">
                        <p class="txt-regular tags">
                          <span class="levels mt-5" *ngFor="
                            let levels of teacher.teacher.meta.experiance_tree
                          ">{{ levels }},
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex level-sub-info" *ngIf="teacher.teacher.meta.tags != ''">
                      <div class="col-md-2  col-3 mt-5">
                        <p class="grey-3-14">Qualified in</p>
                      </div>
                      <div class="col-md-9">
                        <p class="txt-regular tags">
                          <span class="qualified-in mt-5" *ngFor="let qualified of teacher.teacher.meta.tags">{{
                            qualified
                            }},
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex level-sub-info" *ngIf="teacher.smart_group != ''">
                      <div class="col-md-2  col-3">
                        <p class="grey-3-14 mt-5">Smart groups</p>
                      </div>
                      <div class="col-md-9">
                        <p class="txt-regular tags"><span class="bg-tags mt-5"
                            *ngFor="let group of teacher.smart_group">{{group.group_name}}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="teacher.length == 0" class="text-center">
                <h4>No matches for the applied filter</h4>

                <p>
                  <img class="n_dfs" src="assets/images/NoResultFound/no_data_for_booking_request.png"
                    alt="No matches" />
                </p>
                <button type="button" id="" class="btn cc_p_btn fs_13 pl-25 pr-25 btn-sm mt-10"
                  (click)="resetFilters()">
                  <i class="bi bi-send-fill" style="margin-right: 2px"></i>
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
          <!-- Side Component -->
          <div class="col-lg-4" *ngIf="!showInviteList">
            <div class="card px-15">
              <div class="d-flex s_between">
                <p class="user-title">Invite list</p>
                <p (click)="clearallInvites(inviteList)" class="text-regular-medium c-p clr-cc-vilot">clear all</p>
              </div>
              <div class="row">
                <div class="col-lg-12 col-xl-6">
                  <div class="form-floating mt-10">
                    <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                      formControlName="strategy" (change)="patchRequestDetails('')" id="floatingSelect"
                      aria-label="Floating label select example">
                      <ng-option *ngFor="let request_type of filtersArray.request_type"
                        value="{{ request_type.value }}">
                        {{ request_type.title }}
                      </ng-option>
                    </ng-select>
                    <label for="floatingSelect">Request type</label>
                  </div>
                </div>
                <div class="col-lg-12 col-xl-6">
                  <div class="form-floating mt-10">
                    <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                      (change)="patchRequestDetails('')" formControlName="time_to_live" id="floatingSelect"
                      aria-label="Floating label select example">
                      <ng-option value="300">5 minutes</ng-option>
                      <ng-option value="900">15 minutes</ng-option>
                      <ng-option value="1800">30 minutes</ng-option>
                      <ng-option value="3600">1 hour</ng-option>
                      <ng-option value="7200">2 hours</ng-option>
                      <ng-option value="10800">3 hours</ng-option>
                      <ng-option value="21600">6 hours</ng-option>
                      <ng-option value="43200">12 hours</ng-option>
                      <ng-option value="86400">1 day</ng-option>
                      <ng-option value="172800">2 days</ng-option>
                      <ng-option value="604800">1 week</ng-option>
                    </ng-select>
                    <label for="floatingSelect">Close booking after</label>
                  </div>
                </div>
                <div class="col-lg-12 col-xl-12  mt-10" *ngIf="!delayBetReq">
                  <div class="form-floating">
                    <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                      class="" [readonly]="delayBetReq" (change)="patchRequestDetails('customDelay')"
                      formControlName="delay_from_previous" id="delay-between-requests"
                      aria-label="delay-between-requests" [disabled]="!delayBetReq">
                      <ng-option value="2">2 min gap</ng-option>
                      <ng-option value="3">3 min gap</ng-option>
                      <ng-option value="4">4 min gap</ng-option>
                      <ng-option value="5">5 min gap</ng-option>
                      <ng-option value="10">10 min gap</ng-option>
                      <ng-option value="15">15 min gap</ng-option>
                      <ng-option value="20">20 min gap</ng-option>
                      <ng-option value="30">30 min gap</ng-option>
                      <ng-option value="60">60 min gap</ng-option>
                      <ng-option value="120">120 min gap</ng-option>
                    </ng-select>
                    <label for="delay-between-requests">Delay between requests</label>
                  </div>
                </div>
                <div *ngIf="!addMoreBooking">
                  <div class="form-check form-switch d-flex align-items-center p-0 mt-10">
                    <label class="form-check-label" for="delay-send">Delay send</label><br />
                    <input aria-label="" class="form-check-input delay-send" (change)="showDelayTime()"
                      formControlName="schedule_booking" type="checkbox" role="switch" id="delay-send" />
                    <br />
                  </div>
                  <p class="text-regular fs-12 clr-grey">Choose a time to send the booking request in the future.</p>
                  <div *ngIf="showDelay" class="row" id="filter_timeRange_startTime">
                    <div class="col-xl-6 col-md-12 col-12">
                      <div class="form-control input-group schudule_time mt-5">
                        <label class="timeDelay" for="timeDelay">Time</label>
                        <div class="form-floating">
                          <div class="d-flex pt-12">
                            <ng-select id="schudule_time_hrs" [clearable]="false" [searchable]="false"
                              [dropdownPosition]="'bottom'" [multiple]="false" formControlName="schudule_time_hours"
                              (change)="patchRequestDetails('')" name="schudule_time_hrs">
                              <ng-option *ngFor="let item of hours_d"
                                value="{{item?.value}}">{{item?.title}}</ng-option>
                            </ng-select>:
                            <ng-select class="ml-10" id="schudule_time_mins" [clearable]="false" [searchable]="false"
                              [dropdownPosition]="'bottom'" [multiple]="false" formControlName="schudule_time_mins"
                              (change)="patchRequestDetails('')" name="schudule_time_mins">
                              <ng-option *ngFor="let items of minutes_d"
                                value="{{items?.value}}">{{items?.title}}</ng-option>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-12">
                      <div class="form-control input-group schudule_time mt-5">
                        <label class="timeDelay" for="timeDelay">Day</label>
                        <div class="form-floating">
                          <div class="d-flex pt-12">
                            <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                              [multiple]="false" *ngIf="showDelay" formControlName="schedule_day"
                              (change)="patchRequestDetails('')" name="">
                              <ng-option value="today">Today</ng-option>
                              <ng-option value="tomorrow">Tomorrow</ng-option>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-xs-12 col-12 col-md-12 col-lg-12" *ngIf="!showInviteList">
                  <bs-sortable [(ngModel)]="inviteList" [ngModelOptions]="{ standalone: true }"
                    [itemTemplate]="itemTemplate" itemClass="sortable-item line-item"></bs-sortable>
                </div>
                <div class="inviteListEmptyState" *ngIf="inviteList.length == 0 && !addMoreBooking">
                  <p class="text-">Invite list is empty</p>
                </div>
                <!-- <h1>Desktop</h1>{{showInviteList}} -->
                <!-- <p>{{inviteList | json}}</p> -->
                <ng-template #itemTemplate let-item="item" let-index="index">
                  <div class="card px-7" [ngClass]="item.initData.unAvailable ? 's_ua' : ''">
                    <div class="row headings">
                      <div class="col-md-10 col-lg-10 col-10">
                        <div class="d-flex align-items-center">
                          <div class="">
                            <img class="profile-picture profile_picture_small img-{{
                              item.initData.avail_status
                            }}-match" alt="img" [src]="
                                item.initData.meta.profile_image != null
                                  ? item.initData.meta.profile_image
                                  : '../../../../assets/images/teacher-static-profile.png'
                              " />
                          </div>
                          <div class="">
                            <p class="name">
                              {{ item.initData.user.full_name }}
                              <span>
                                <app-validated-dict [from]="'bookingSideInviteList'" [validateDict]="
                                    item.initData.validated_dict
                                  ">
                                </app-validated-dict>
                              </span>
                            </p>

                            <div class="input-group" *ngIf="!delayBetReq">
                              <input aria-label="" id="changeMins" value="{{ item.initData.minutes }}"
                                (input)="changeDelayInput(index, $event)" class="form-control changeMinsFormControl" />
                              <span class="input-group-text changeMinsgroupText" for="changeMins">mins</span>
                            </div>
                            <!-- <p class="user-sub-title mt-2">
                              <span *ngIf="item.initData.meta.you_are_a">{{
                                item.initData.meta.you_are_a
                                }}</span>
                              <span *ngIf="!item.initData.meta.you_are_a">N/A</span>
                              <span class="divider-right ml-8 mr-4"></span>
                              <span *ngIf="item.initData.user.previous_login">
                                Active
                                {{ item.initData.user.previous_login }}</span>
                              <span *ngIf="!item.initData.user.previous_login">
                                N/A</span>
                            </p> -->
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-3 col-lg-2 col-3 d-flex line-item-delay align-items-center">
                        <p class="d-flex align-items-center" *ngIf="!delayBetReq">
                          <input aria-label="" value="{{ item.initData.minutes }}" (input)="changeDelayInput(index, $event)"
                            class="form-control" />
                          <span> mins</span>
                        </p>
                      </div> -->
                      <div class="col-md-2 col-lg-2 col-2 d-flex align-items-center">
                        <p (click)="removeTeacher(item.initData)" class="c-p">
                          <i class="bi bi-x clr-cc-vilot remove-line-item"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- <p>{{inviteList | json}}</p> -->

                <span *ngIf="addMoreBooking">
                  <div class="inFlightList">
                    <div class="card px-7">
                      <div class="row headings mt-8 pt-3 pb-3" *ngFor="let list of bookingInFlightData">
                        <div class="col-md-12 col-lg-12 col-12 d-flex align-items-center">
                          <img class="profile-picture profile_picture_small mr-10" alt="img" [src]="
                            list.teacher.meta.profile_image != null
                              ? list.teacher.meta.profile_image
                              : '../../../../assets/images/teacher-static-profile.png'
                          " />
                          <p class="name fs-14">{{ list.teacher.user.full_name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
                <div class="mt-10" *ngIf="backUpList.length > 0">
                  <div class="form-check">
                    <input aria-label="" class="form-check-input c-p mt-4" [attr.disabled]="disableBackup ? '' : null"
                      (change)="showBackupLiveTime()" type="checkbox" id="sendBackupList"
                      formControlName="send_backup_list" />
                    <label class="form-check-label c-p" for="sendBackupList">
                      Send to backup list
                    </label>
                  </div>
                  <p class="text-regular fs-12 clr-grey">
                    If you want the booking automatically sent to your
                    backup list if the booking goes unfilled with your
                    preferred list.
                  </p>

                  <div class="drawer-item mt-5" *ngIf="showBackupTime">
                    <div class="form-floating">
                      <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                        [multiple]="false" [readonly]="disableBackup" (change)="patchRequestDetails('')"
                        formControlName="update_timetolive" id="update_timetolive"
                        aria-label="Send to backup list after">
                        <ng-option selected value="300">5 minutes</ng-option>
                        <ng-option value="900">15 minutes</ng-option>
                        <ng-option value="1800">30 minutes</ng-option>
                        <ng-option value="3600">1 hour</ng-option>
                        <ng-option value="7200">2 hours</ng-option>
                      </ng-select>
                      <label for="update_timetolive">Send to backup list after</label>
                    </div>
                  </div>
                </div>
                <div class="mt-10">
                  <div class="form-check">
                    <input aria-label="" class="form-check-input c-p mt-4" [attr.disabled]="disableEmergencyList ? '' : null"
                      (change)="showEmergencyLiveTime()" (change)="openEmergencyModal($event)" type="checkbox"
                      id="send_emergency_list" formControlName="send_emergency_list" />
                    <label class="form-check-label c-p" for="send_emergency_list">
                      Send to emergency list
                    </label>
                  </div>
                  <p class="text-regular fs-12 clr-grey">
                    Have your booking request automatically go out to your
                    emergency list if it goes unfilled.
                  </p>
                </div>
                <div class="mt-10" *ngIf="showEmergencyTime">
                  <div class="form-floating">
                    <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
                      [readonly]="disableEmergencyList" (change)="patchRequestDetails('')"
                      formControlName="emergency_timetolive" id="emergency_timetolive"
                      aria-label="Send to backup list after">
                      <ng-option value="300">5 minutes</ng-option>
                      <ng-option value="900">15 minutes</ng-option>
                      <ng-option selected value="1800">30 minutes</ng-option>
                    </ng-select>
                    <label for="emergency_timetolive">Send to emergency list after</label>
                  </div>
                </div>
                <div class="pb-10 mt-20">
                  <div>
                    <button [ngClass]="inviteList.length > 0 ? '' : 'disabled'" *ngIf="!addMoreBooking" type="button"
                      (click)="checkAvail('regular')" class="btn cc_p_btn fs_13 w-100" id="send-invite-btn">
                      <i class="bi bi-send-fill" style="margin-right: 2px"></i>
                      Send Invite
                    </button>
                    <span *ngIf="addMoreBooking">
                      <button [ngClass]="
                          inviteList.length > 0 || showUpdatebtn
                            ? ''
                            : 'disable_b_p'
                        " type="button" (click)="checkAvail('update')" class="btn cc_p_btn  w-100 mt-20 fs_13"
                        id="send-invite-btn">
                        <i class="bi bi-send-fill" style="margin-right: 2px"></i>
                        Update Booking
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Bottom trey -->
        <div class="booking-bottom-trey-header hideOn992" [ngClass]="
            status ? 'booking-header-drawer-collapsed' : 'booking-header-drawer'
          ">
          <div class="align-items-center" [ngClass]="
              status
                ? 'booking-heading-drawer-collapsed'
                : 'booking-heading-drawer'
            ">
            <ul class="bottom-drawer-items">
              <li class="drawer-item">
                <p class="invite-list h_b" (click)="showHiddenDrawer()">
                  <span class="up-down-icon"><i *ngIf="inviteList.length > 0 || addMoreBooking"
                      class="bi bi-arrow-up-circle-fill fs-20 mr-5"></i></span><span class="bottom-border-invite">
                    Invite list</span>
                  <span *ngIf="addMoreBooking" class="list-length ml-4">{{
                    inviteList.length + bookingInFlightData.length
                    }}</span>
                  <span *ngIf="!addMoreBooking" class="list-length ml-4">{{
                    inviteList.length
                    }}</span>
                </p>
              </li>

              <!-- <li class="drawer-item">
                <div class="form-floating">
                  <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'top'" [multiple]="false"
                    class="request-type" (change)="patchRequestDetails('')" formControlName="strategy" id="request-type"
                    aria-label="request-type">
                    <ng-option *ngFor="let request_type of filtersArray.request_type" value="{{ request_type.value }}">
                      {{ request_type.title }}
                    </ng-option>
                  </ng-select>
                  <label for="request-type">Request type</label>
                </div>
              </li>

              <li class="drawer-item" *ngIf="!delayBetReq">
                <div class="form-floating">
                  <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'top'" [multiple]="false"
                    class="delay-between-requests" [readonly]="delayBetReq"
                    (change)="patchRequestDetails('customDelay')" formControlName="delay_from_previous"
                    id="delay-between-requests" aria-label="delay-between-requests" [disabled]="!delayBetReq">
                    <ng-option value="2">2 min gap</ng-option>
                    <ng-option value="3">3 min gap</ng-option>
                    <ng-option value="4">4 min gap</ng-option>
                    <ng-option value="5">5 min gap</ng-option>
                    <ng-option value="10">10 min gap</ng-option>
                    <ng-option value="15">15 min gap</ng-option>
                    <ng-option value="20">20 min gap</ng-option>
                    <ng-option value="30">30 min gap</ng-option>
                    <ng-option value="60">60 min gap</ng-option>
                    <ng-option value="120">120 min gap</ng-option>
                  </ng-select>
                  <label for="delay-between-requests">Delay between requests</label>
                </div>
              </li>

              <li class="drawer-item">
                <div class="form-floating">
                  <ng-select class="close-booking-after" [clearable]="false" [searchable]="false"
                    [dropdownPosition]="'top'" [multiple]="false" (change)="patchRequestDetails('')"
                    formControlName="time_to_live" id="close-booking-after" aria-label="close-booking-after">
                    <ng-option value="300">5 minutes</ng-option>
                    <ng-option value="900">15 minutes</ng-option>
                    <ng-option value="1800">30 minutes</ng-option>
                    <ng-option value="3600">1 hour</ng-option>
                    <ng-option value="7200">2 hours</ng-option>
                    <ng-option value="10800">3 hours</ng-option>
                    <ng-option value="21600">6 hours</ng-option>
                    <ng-option value="43200">12 hours</ng-option>
                    <ng-option value="86400">1 day</ng-option>
                    <ng-option value="172800">2 days</ng-option>
                    <ng-option value="604800">1 week</ng-option>
                  </ng-select>
                  <label for="close-booking-after">Close booking after</label>
                </div>
              </li>

              <li *ngIf="!addMoreBooking" class="drawer-item txt-webkit-cntr">
                <div class="form-check form-switch">
                  <label class="form-check-label" for="delay-send">Delay send</label><br />
                  <input aria-label="" class="form-check-input delay-send" (change)="showDelayTime()"
                    formControlName="schedule_booking" type="checkbox" role="switch" id="delay-send" />
                  <br />
                </div>
                <div *ngIf="showDelay" id="filter_timeRange_startTime"
                  class="form-control fit-content input-group schudule_time mt-5 delay-send-time-picker">
                  <div class="form-floating d-flex">
                    <ng-select id="schudule_time_hrs" [clearable]="false" [searchable]="false"
                      [dropdownPosition]="'top'" [multiple]="false" formControlName="schudule_time_hours"
                      (change)="patchRequestDetails('')" name="schudule_time_hrs">
                      <ng-option *ngFor="let item of hours_d" value="{{item?.value}}">{{item?.title}}</ng-option>
                    </ng-select>:
                    <ng-select class="ml-10" id="schudule_time_mins" [clearable]="false" [searchable]="false"
                      [dropdownPosition]="'top'" [multiple]="false" formControlName="schudule_time_mins"
                      (change)="patchRequestDetails('')" name="schudule_time_mins">
                      <ng-option *ngFor="let items of minutes_d" value="{{items?.value}}">{{items?.title}}</ng-option>
                    </ng-select>
                    <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'top'" [multiple]="false"
                      *ngIf="showDelay" formControlName="schedule_day" (change)="patchRequestDetails('')" name="">
                      <ng-option value="today">Today</ng-option>
                      <ng-option value="tomorrow">Tomorrow</ng-option>
                    </ng-select>
                  </div>
                </div>
              </li>
      
              <li class="drawer-item">
                <button *ngIf="!addMoreBooking" [ngClass]="inviteList.length == 0 ? 'disable_b_p' : ''" type="button"
                  (click)="checkAvail('regular')" class="btn cc_p_btn fs_13 send-invite-btn float-right"
                  id="send-invite-btn">
                  <i class="bi bi-send-fill" style="margin-right: 2px"></i>
                  Send Invite
                </button>
                <button *ngIf="addMoreBooking" [ngClass]="
                    inviteList.length > 0 || showUpdatebtn ? '' : 'disable_b_p'
                  " type="button" (click)="checkAvail('update')" class="btn cc_p_btn fs_13 send-invite-btn float-right"
                  id="send-invite-btn">
                  <i class="bi bi-send-fill" style="margin-right: 2px"></i>
                  Update Booking
                </button>
              </li> -->
            </ul>
          </div>
        </div>
        <!-- Bottom trey inside list -->
        <div  *ngIf="showInviteList" class="booking-bottom-trey" [ngClass]="
            status ? 'booking-bottom-drawer-collapsed' : 'booking-bottom-drawer'
          ">
          <div class="bottom-header" (click)="showHiddenDrawer()">
            <p class="invite-list h_b">
              <i class="bi bi-arrow-down-circle-fill fs-20 mr-5"></i><span class="bottom-border-invite"> Invite
                list</span>
              <span *ngIf="addMoreBooking" class="list-length ml-4">{{
                inviteList.length + bookingInFlightData.length
                }}</span>
              <span *ngIf="!addMoreBooking" class="list-length ml-4">{{
                inviteList.length
                }}</span>
            </p>
            <!-- <i class="bi bi-x clr-cc-vilot trey-close-btn fs-20 mr-2" (click)="showHiddenDrawer()"></i> -->
          </div>
          <div class="booking-bottom-trey-content">
            <div class="row px-15">
              <div class="col-md-8">
                <!-- <div class="col-md-12">
                                    <p class="invite-list pb-10 pt-2" (click)="showHiddenDrawer()"><i
                                            class="bi bi-arrow-down-circle-fill fs-20 mr-2"></i><span class="bottom-border-invite"> Invite
                                            list</span>
                                        <span class="list-length ml-5">{{inviteList.length}}</span>
                                    </p>
                                </div> -->
                <div class="line-item-heading">
                  <div class="row headings">
                    <div class="col-md-7 col-lg-8 col-7 align-items-center">
                      <p class="heading-name">Name</p>
                    </div>
                    <div class="col-md-3 col-lg-2 col-3 align-items-center">
                      <p *ngIf="!delayBetReq">Send after</p>
                    </div>
                    <div class="col-md-2 col-lg-2 col-2 align-items-center">
                      <p (click)="clearallInvites(inviteList)" class="text-regular-bold clr-cc-vilot">
                        Clear all
                      </p>
                    </div>
                  </div>
                </div>

                <div class="all-line-items hideOn992">
                  <div class="row">
                    <div class="col-xs-12 col-12 col-md-12 col-lg-12" *ngIf="showInviteList">
                      <bs-sortable [(ngModel)]="inviteList" [ngModelOptions]="{ standalone: true }"
                        [itemTemplate]="itemTemplate" itemClass="sortable-item line-item"></bs-sortable>
                    </div>
                  </div>
                  <ng-template #itemTemplate let-item="item" let-index="index">
                    <div class="card px-7" [ngClass]="item.initData.unAvailable ? 's_ua' : ''">
                      <div class="row headings">
                        <div class="col-md-7 col-lg-8 col-7">
                          <div class="d-flex align-items-center">
                            <div class="">
                              <img class="profile-picture img-{{
                                item.initData.avail_status
                              }}-match" alt="img" [src]="
                                  item.initData.meta.profile_image != null
                                    ? item.initData.meta.profile_image
                                    : '../../../../assets/images/teacher-static-profile.png'
                                " />
                            </div>
                            <div class="">
                              <p class="name">
                                {{ item.initData.user.full_name }}
                                <span>
                                  <app-validated-dict [validateDict]="
                                      item.initData.validated_dict
                                    ">
                                  </app-validated-dict>
                                </span>
                              </p>
                              <p class="user-sub-title mt-2">
                                <span *ngIf="item.initData.meta.you_are_a">{{
                                  item.initData.meta.you_are_a
                                  }}</span>
                                <span *ngIf="!item.initData.meta.you_are_a">N/A</span>
                                <span class="divider-right ml-8 mr-4"></span>
                                <span *ngIf="item.initData.user.previous_login">
                                  Active
                                  {{ item.initData.user.previous_login }}</span>
                                <span *ngIf="!item.initData.user.previous_login">
                                  N/A</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-lg-2 col-3 d-flex line-item-delay align-items-center">
                          <p class="d-flex align-items-center" *ngIf="!delayBetReq">
                            <input aria-label="" value="{{ item.initData.minutes }}" (input)="changeDelayInput(index, $event)"
                              class="form-control" />
                            <span> mins</span>
                          </p>
                        </div>
                        <div class="col-md-2 col-lg-2 col-2 d-flex align-items-center">
                          <p (click)="removeTeacher(item.initData)">
                            <i class="bi bi-x clr-cc-vilot remove-line-item"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <span *ngIf="addMoreBooking">
                    <div class="inFlightList">
                      <div class="row headings mt-8 pt-3 pb-3" *ngFor="let list of bookingInFlightData">
                        <div class="col-md-7 col-lg-9 col-7 d-flex align-items-center">
                          <img class="profile-picture mr-10" alt="img" [src]="
                              list.teacher.meta.profile_image != null
                                ? list.teacher.meta.profile_image
                                : '../../../../assets/images/teacher-static-profile.png'
                            " />
                          <p class="name fs-14">{{ list.teacher.user.full_name }}</p>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-md-4 side_component">
                <div class="row side_content1">
                  <div class="col-md-12 col-5 col-sm-6">
                    <div class="form-floating mt-10">
                      <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                        [multiple]="false" formControlName="strategy" (change)="patchRequestDetails('')"
                        id="floatingSelect" aria-label="Floating label select example">
                        <ng-option *ngFor="let request_type of filtersArray.request_type"
                          value="{{ request_type.value }}">
                          {{ request_type.title }}
                        </ng-option>
                      </ng-select>
                      <label for="floatingSelect">Request type</label>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-6 col-7">
                    <div class="form-floating mt-10" *ngIf="!delayBetReq">
                      <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                        [multiple]="false" (change)="patchRequestDetails('customDelay')"
                        formControlName="delay_from_previous" id="floatingSelect"
                        aria-label="Floating label select example">
                        <ng-option value="2">2 min gap</ng-option>
                        <ng-option value="3">3 min gap</ng-option>
                        <ng-option value="4">4 min gap</ng-option>
                        <ng-option value="5">5 min gap</ng-option>
                        <ng-option value="10">10 min gap</ng-option>
                        <ng-option value="15">15 min gap</ng-option>
                        <ng-option value="20">20 min gap</ng-option>
                        <ng-option value="30">30 min gap</ng-option>
                        <ng-option value="60">60 min gap</ng-option>
                        <ng-option value="120">120 min gap</ng-option>
                      </ng-select>
                      <label for="floatingSelect">Delay between requests</label>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-6">
                    <div class="form-floating mt-10">
                      <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                        [multiple]="false" (change)="patchRequestDetails('')" formControlName="time_to_live"
                        id="floatingSelect" aria-label="Floating label select example">
                        <ng-option value="300">5 minutes</ng-option>
                        <ng-option value="900">15 minutes</ng-option>
                        <ng-option value="1800">30 minutes</ng-option>
                        <ng-option value="3600">1 hour</ng-option>
                        <ng-option value="7200">2 hours</ng-option>
                        <ng-option value="10800">3 hours</ng-option>
                        <ng-option value="21600">6 hours</ng-option>
                        <ng-option value="43200">12 hours</ng-option>
                        <ng-option value="86400">1 day</ng-option>
                        <ng-option value="172800">2 days</ng-option>
                        <ng-option value="604800">1 week</ng-option>
                      </ng-select>
                      <label for="floatingSelect">Close booking after</label>
                    </div>
                  </div>

                  <div *ngIf="!addMoreBooking" class="col-md-12 col-sm-6 mt-10">
                    <div class="d-flex align-items-center">
                      <label class="form-check-label delay-send-label" for="delay-send">Delay send</label>
                      <div class="form-check form-switch">
                        <input aria-label="" class="form-check-input delay-send c-p" formControlName="schedule_booking"
                          (change)="showDelayTime()" type="checkbox" role="switch" id="delay-send" />
                      </div>
                    </div>
                    <p class="delay-send-text mt-10">
                      Choose a time to send the booking request in the future.
                    </p>
                  </div>
                  <div class="col-md-12 col-sm-6">
                    <div *ngIf="showDelay" id="filter_timeRange_startTime"
                      class="form-control fit-content input-group schudule_time mt-5">
                      <div class="form-floating d-flex">
                        <ng-select id="schudule_time_hrs" [clearable]="false" [searchable]="false"
                          [dropdownPosition]="'bottom'" [multiple]="false" formControlName="schudule_time_hours"
                          (change)="patchRequestDetails('')" name="schudule_time_hrs">
                          <ng-option *ngFor="let item of hours_d" value="{{item?.value}}">{{item?.title}}</ng-option>
                        </ng-select>:
                        <ng-select class="ml-10" id="schudule_time_mins" [clearable]="false" [searchable]="false"
                          [dropdownPosition]="'bottom'" [multiple]="false" formControlName="schudule_time_mins"
                          (change)="patchRequestDetails('')" name="schudule_time_mins">
                          <ng-option *ngFor="let items of minutes_d"
                            value="{{items?.value}}">{{items?.title}}</ng-option>
                        </ng-select>
                        <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                          [multiple]="false" *ngIf="showDelay" formControlName="schedule_day"
                          (change)="patchRequestDetails('')" name="">
                          <ng-option value="today">Today</ng-option>
                          <ng-option value="tomorrow">Tomorrow</ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-12">
                    <div class="mt-10" *ngIf="backUpList.length > 0">
                      <div class="form-check">
                        <input aria-label="" class="form-check-input c-p" [attr.disabled]="disableBackup ? '' : null"
                          (change)="showBackupLiveTime()" type="checkbox" id="sendBackupList"
                          formControlName="send_backup_list" />
                        <label class="form-check-label c-p" for="sendBackupList">
                          Send to backup list
                        </label>
                      </div>
                      <p class="text-regular fs-12 clr-grey">
                        If you want the booking automatically sent to your
                        backup list if the booking goes unfilled with your
                        preferred list.
                      </p>

                      <div class="drawer-item mt-5" *ngIf="showBackupTime">
                        <div class="form-floating">
                          <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'top'"
                            [multiple]="false" [readonly]="disableBackup" (change)="patchRequestDetails('')"
                            formControlName="update_timetolive" id="update_timetolive"
                            aria-label="Send to backup list after">
                            <ng-option selected value="300">5 minutes</ng-option>
                            <ng-option value="900">15 minutes</ng-option>
                            <ng-option value="1800">30 minutes</ng-option>
                            <ng-option value="3600">1 hour</ng-option>
                            <ng-option value="7200">2 hours</ng-option>
                          </ng-select>
                          <label for="update_timetolive">Send to backup list after</label>
                        </div>
                      </div>
                    </div>
                    <div class="mt-10">
                      <div class="form-check">
                        <input aria-label="" class="form-check-input c-p mt-4" [attr.disabled]="disableEmergencyList ? '' : null"
                          (change)="showEmergencyLiveTime()" (change)="openEmergencyModal($event)" type="checkbox"
                          id="send_emergency_list" formControlName="send_emergency_list" />
                        <label class="form-check-label c-p" for="send_emergency_list">
                          Send to emergency list
                        </label>
                      </div>
                      <p class="text-regular fs-12 clr-grey">
                        Have your booking request automatically go out to your
                        emergency list if it goes unfilled.
                      </p>
                      <div class="drawer-item mt-5" *ngIf="showEmergencyTime">
                        <div class="form-floating">
                          <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'top'"
                            [multiple]="false" [readonly]="disableEmergencyList" (change)="patchRequestDetails('')"
                            formControlName="emergency_timetolive" id="emergency_timetolive"
                            aria-label="Send to backup list after">
                            <ng-option value="300">5 minutes</ng-option>
                            <ng-option value="900">15 minutes</ng-option>
                            <ng-option selected value="1800">30 minutes</ng-option>
                          </ng-select>
                          <label for="emergency_timetolive">Send to emergency list after</label>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="mt-10">
                                            <div class="form-check">
                                                <input aria-label="" class="form-check-input c-p" type="checkbox" id="send_emergency_list" formControlName="send_emergency_list">
                                                <label class="form-check-label c-p" for="send_emergency_list">
                                                    Send to everyone on my list
                                                </label>
                                            </div>
                                            <p class="delay-send-text">Select this to have the booking automatically sent to all available teachers on your list who match the booking criteria if preferred and backup lists fail.</p>
                                        </div> -->
                    <div class="send-invite-sec pb-10 mt-10">
                      <div>
                        <button *ngIf="!addMoreBooking" type="button" (click)="checkAvail('regular')"
                          class="btn cc_p_btn fs_13 float-right" id="send-invite-btn">
                          <i class="bi bi-send-fill" style="margin-right: 2px"></i>
                          Send Invite
                        </button>
                        <span *ngIf="addMoreBooking">
                          <button [ngClass]="
                              inviteList.length > 0 || showUpdatebtn
                                ? ''
                                : 'disable_b_p'
                            " type="button" (click)="checkAvail('update')" class="btn cc_p_btn mt-20 fs_13 float-right"
                            id="send-invite-btn">
                            <i class="bi bi-send-fill" style="margin-right: 2px"></i>
                            Update Booking
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #flexiBookingModal>
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">
              Not sure of the class or teacher being covered yet?
            </h4>
            <button type="button" (click)="closeModal()" class="btn-close" aria-label="Close"></button>
          </div>

          <div class="modal-body pb-0">
            <div class="row align-items-center">
              <div class="col-md-12">
                <label for="exampleFormControlTextarea1" class="form-label txt-regular">Send this request as a Flexi
                  Booking so they are aware. You
                  can update the booking details when you have more
                  details.</label>
                <div class="ml-25 mt-10">
                  <div class="form-check">
                    <input aria-label="" class="form-check-input mt-4" (change)="changeMandatory('not_mandaotry')"
                      formControlName="flexi_booking" value="1" type="radio" name="flexi_booking" id="flexi_booking" />
                    <label class="form-check-label" for="flexi_booking">
                      Yes, send as a Flexi booking
                    </label>
                  </div>
                  <div class="form-check mt-5">
                    <input aria-label="" class="form-check-input  mt-4" (change)="changeMandatory('mandaotry')"
                      formControlName="flexi_booking" value="0" type="radio" name="flexi_booking"
                      id="no_flexi_booking" />
                    <label class="form-check-label" for="no_flexi_booking">
                      No, I’ll enter teacher and class being covered
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-10">
            <!-- <button type="button"  (click)="bsModalRef.hide()" class="btn_cc_back">Back</button> -->
            <button type="button" *ngIf="!addMoreBooking" (click)="checkFlexyFilled('regular')"
              class="btn cc_p_btn fs_13">
              Continue
            </button>
            <button type="button" *ngIf="addMoreBooking" (click)="checkFlexyFilled('update')"
              class="btn cc_p_btn fs_13">
              Continue
            </button>
          </div>
        </ng-template>
        <ng-template #emergencyBookingModal>
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">
              Make your Emergency Booking more effective
            </h4>
            <button type="button" (click)="closeModalEmergency()" class="btn-close" aria-label="Close"></button>
          </div>

          <div class="modal-body pb-0">
            <div class="row align-items-center">
              <div class="col-md-12">
                <label for="exampleFormControlTextarea1" class="form-label txt-regular">
                  Help us out by giving us some additional information to ensure
                  your request goes out to the right people.
                </label>

                <div class="mt-10">
                  <div class="col-md-12 col-sm-12 mt-10">
                    <label for="exampleFormControlTextarea1" class="form-label">
                      Subjects
                    </label>
                    <ng-select [readonly]="sub_is_readonly" [clearable]="false" [items]="subjectsAndTags"
                      (change)="disableEveryone()" formControlName="system_tags" bindLabel="title" bindValue="title"
                      groupBy="tags" [closeOnSelect]="true" [dropdownPosition]="'bottom'" [multiple]="true"
                      placeholder="Search by subjects and tags">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <label>System tags</label>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items; index as i">
                          <span class="ng-value-label">{{
                            items[i].title
                            }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                      </ng-template>
                    </ng-select>
                    <!-- [closeOnSelect]="false" -->
                  </div>
                  <div class="col-md-12 col-sm-12 mt-10">
                    <label for="exampleFormControlTextarea1" class="form-label">
                      Levels
                    </label>
                    <ng-select class="mt-5" [clearable]="false" [readonly]="level_is_readonly"
                      (change)="disableEveryone()" formControlName="booking_levels" [items]="levelFilter"
                      [multiple]="true" [closeOnSelect]="true" [dropdownPosition]="'bottom'" groupBy="levels"
                      bindLabel="title" bindValue="value" placeholder="Search by level">
                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                        <label>Levels</label>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items; index as i">
                          <span class="ng-value-label">{{
                            items[i].title
                            }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="mt-10">
                    <div class="form-check">
                      <input aria-label="" class="form-check-input c-p mt-4" [attr.disabled]="send_is_readonly ? '' : null"
                        (change)="disableEveryone()" type="checkbox" id="send_to_everyone"
                        formControlName="send_to_everyone" />
                      <label class="form-check-label c-p" for="send_to_everyone">
                        Send to everyone on my list
                      </label>
                    </div>
                    <p class="delay-send-text">
                      Select this to have the booking automatically sent to all
                      available teachers on your list who match the booking
                      criteria if preferred and backup lists fail.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-10">
            <!-- <button type="button"  (click)="bsModalRef.hide()" class="btn_cc_back">Back</button> -->
            <button type="button" (click)="patchLevelSubjectValue()" class="btn cc_p_btn fs_13">
              Save
            </button>
            <!-- <button type="button"   class="btn cc_p_btn fs_13">Continue</button> -->
          </div>
        </ng-template>
      </form>
    </div>
    <!-- <div class="col-xl-3 hideOn">
      <app-invite-request-tab></app-invite-request-tab>
    </div> -->
  </div>
</div>