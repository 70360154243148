import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { flatMap, last, map, take } from "rxjs/operators";
import { DatePipe, formatDate, NgClass } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../../../environments/environment'

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../../../services/auth/authentication.service';

// models
import { SchoolData } from 'src/app/models/schoolUser';

// Components
import { ConfirmationModalComponent } from '../../../layout/confirmation-modal/confirmation-modal.component';
import { format } from 'path';
import { ToastrService } from 'ngx-toastr';
import { NgSelectComponent, NgOptionComponent } from '@ng-select/ng-select';
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    
    standalone: true,
    imports: [NgClass, FormsModule, ReactiveFormsModule, NgSelectComponent, NgOptionComponent, RouterLink]
})
export class RegisterComponent implements OnInit {
  hideBasicInfos: boolean = false;
  hideOtherInfos: boolean = false;
  submittedBasic: boolean = false;
  submittedOther: boolean = false;
  submitted: boolean = false
  @ViewChild('cancelReasonModal') cancelReasonModal: any;
  public cancelBookingRequestForm: any

  BasicInformationForm: FormGroup = this.fb.group({
    is_job_only_school: ['job_only_school'],
    school_name: ['', [Validators.required, this.noWhitespaceValidator]],
    street_name: ['', [Validators.required, this.noWhitespaceValidator]],
    street_number: ['', [Validators.required, this.noWhitespaceValidator]],
    state: ['', [Validators.required, this.noWhitespaceValidator]],
    city: ['', [Validators.required, this.noWhitespaceValidator]],
    postal_code: ['', [Validators.required, this.noWhitespaceValidator]],
    organization_type: ['', [Validators.required, this.noWhitespaceValidator]]
  });
  otherInformationForm: FormGroup = this.fb.group({
    full_name: ['', [Validators.required, this.noWhitespaceValidator]],
    phone_no: ['', [Validators.required, this.noWhitespaceValidator]],
    user_email: ['', [Validators.required, this.noWhitespaceValidator]],
    password: ['', [Validators.required, this.noWhitespaceValidator]],
    confirm_password: ['', [Validators.required, this.noWhitespaceValidator]],
    acceptedTerms: ['']
  });

  loginForm: FormGroup = this.fb.group({
    client_id: [environment.client_id],
    client_secret: [environment.client_secret],
    grant_type: ['password'],
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  formJoin: any;
  passwordMatch: boolean = true;
  is_job_only_user: any;
  filtersArray: any;
  openModal: boolean = true;
  terms_accepted: any;
  showAcceptBtn: boolean = false;

  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    // this.authService.loggedOut();
    this.formInit()
    this.getFiltersData()
  }

  getFiltersData() {
    this.commonService.registerChoices('register').subscribe({
      next: (res: any) => {
        this.filtersArray = res.data;
      },
      error: (err: any) => {
        this.ngxService.stop()
        if (err.status == 500) {
          this.openModal = false
        }
        console.log(err);
      }
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }
  formInit() {
    this.cancelBookingRequestForm = this.fb.group({
      school_id: ['', [Validators.required, this.noWhitespaceValidator]],
      user_name: ['', [Validators.required, this.noWhitespaceValidator]],
      user_email: ['', [Validators.required, this.noWhitespaceValidator]],
    });
  }
  hideBasicInfo() {
    this.submittedBasic = true;
    if (this.BasicInformationForm.invalid) {
      return;
    } else {
      this.hideBasicInfos = true
      window.scroll(0, 50)
    }
  }
  hideOtherInfo() {
    this.hideBasicInfos = false
    this.submittedOther = false;
    this.submittedBasic = false;
    this.hideOtherInfos = false
    window.scroll(0, 50)
  }
  getFormControlValues() {
    this.otherInformationForm.patchValue({
      acceptedTerms:this.showAcceptBtn
    })
    this.formJoin = { ...this.BasicInformationForm.value, ...this.otherInformationForm.value }
    return this.formJoin;
  }

  getBasicFormControlValues() {
    return this.BasicInformationForm.value
  }
  get b(): { [key: string]: AbstractControl } {
    return this.BasicInformationForm.controls;
  }
  get a(): { [key: string]: AbstractControl } {
    return this.cancelBookingRequestForm.controls;
  }

  getAccessFormControlValues() {
    return this.cancelBookingRequestForm.value
  }

  getOtherFormControlValues() {
    return this.otherInformationForm.value
  }
  get o(): { [key: string]: AbstractControl } {
    return this.otherInformationForm.controls;
  }
  makePasswordFalse() {
    this.passwordMatch = true
  }
  submitRegistration() {
    
    this.submittedOther = true;
    let password = this.otherInformationForm.controls['password'].value
    let confirm_password = this.otherInformationForm.controls['confirm_password'].value
    if (this.otherInformationForm.invalid) {
      this.passwordMatch = true
      return;
    }
    if (this.showAcceptBtn == false) {
      this.toastr.error("Please accept the terms and conditions to continue")
      return;
    }

    if (password !== confirm_password) {
      this.passwordMatch = false
      this.submittedOther = false
      return;
    }

    this.ngxService.start();
    this.bookingService.register(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.toastr.success("Sucessfully Registered");
          let user_email = this.otherInformationForm.controls['user_email'].value
          let password = this.otherInformationForm.controls['password'].value
          this.loginForm.patchValue({
            username: user_email,
            password: password
          });
          this.authService.login(this.loginForm.value).subscribe({
            next: (res) => {
              this.commonService.getUserData('').subscribe((res) => {
                if (res) {
                  var userRole = res.data.properties.user_type.replace(/[^a-zA-Z ]/g, "");
                  this.is_job_only_user = res.data.properties.job_only_school
                  localStorage.setItem('is_job_only_user', res.data.properties.job_only_school);
                  localStorage.setItem('schoolId', res.data.mixpanel_data.school_id);
                  localStorage.setItem('userData', JSON.stringify(res.data));
                  localStorage.setItem('userRole', userRole);
                  document.cookie = "userRole=" + userRole + ";domain=." + environment.cookiesDomain + ";path=/";
                  localStorage.setItem('schoolAdId', res.data.properties.additional_user_id);
                  this.terms_accepted = res.data.properties.terms_accepted
                  this.toPageTredirection(res.data.properties.user_type, res.data.properties.terms_accepted)
                  this.ngxService.stop();
                } else {
                  console.log("error in the response", res);
                  this.toastr.error('error in login', res.message);
                  this.ngxService.stop();
                }
              });
            },
            error: (err) => {
              console.log(err);
            }
          })
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  toPageTredirection(user: any, terms: any) {
    switch (user) {
      case 'School':
        if (terms == true) {
          this._router.navigateByUrl('/school/jobs/post-job');
        }else{
          this._router.navigateByUrl('/school/termsAndCondition');
        }
        break;
    }
  }

  openRequestAccessModal() {
    if (this.openModal == false) {
      return;
    } else {
      this.bsModalRef = this.modalService.show(this.cancelReasonModal, { class: 'modal-lg', backdrop: 'static' });
    }
  }

  closeModal() {
    this.submitted = false;
    this.cancelBookingRequestForm.reset()
    this.bsModalRef.hide()
  }
  submit_access() {
    this.submitted = true;
    if (this.cancelBookingRequestForm.invalid) {
      return;
    }
    this.ngxService.start();
    this.bookingService.getSchoolAccess(this.getAccessFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.submitted = false;
          this.cancelBookingRequestForm.reset()
          this.bsModalRef.hide()
          this.toastr.success(res.message);
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.message);
        this.ngxService.stop();
      }
    })
  }

  checkCondition(event: any) {
    if (event.target.checked == true) {
      this.showAcceptBtn = true
    }
    if (event.target.checked == false) {
      this.showAcceptBtn = false
    }
  }
}
