
  <nav class="navbar" [ngClass]="this.schoolProps.properties.job_only_school ? 'job-navbar' : ''">
    <div class="container-fluid display-profile-header pl-20 pr-20 align-items-center">
      <div class="d-flex">
        <!-- <i  class="bi bi-list"></i> -->
        <!-- <img alt=""  *ngIf="this.schoolProps.properties.job_only_school" class="logo-image" src="assets/images/side-nav/fav_img.png"> -->
        <img alt="" *ngIf="this.schoolProps.properties.job_only_school" class="side_logo_text d-show-350"
          src="assets/images/side-nav/CCJobsWhite.png" />
          <img alt="" *ngIf="this.schoolProps.properties.job_only_school" class="fav_logo d-none-350"
          src="assets/images/side-nav/fav_img.png" />
        <img alt="" *ngIf="!this.schoolProps.properties.job_only_school" class="side_logo_text_booking d-show-350"
          src="assets/images/favicon/CCLogo.png" />
          <img alt="" *ngIf="!this.schoolProps.properties.job_only_school" class="fav_logo d-none-350"
          src="assets/images/favicon/fav_icon_new.png" />
        <!-- <input type="text" placeholder="Search" class="form-control ml-20"> -->
      </div>
      <div class="nav-item list-unstyled d-flex align-items-center">
        <p class="nav-link ShowOnRes plan-badge" *ngIf="this.schoolProps.properties.job_only_school">
          {{ plan }}
        </p>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle px-0" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <span class="mr-10">{{ schoolProps.mixpanel_data.school_name }}</span>
            <img width="42px" alt="i" class="school_avatar"
              [src]="
              schoolLogo == '' || schoolLogo == null || schoolLogo == undefined ? 'assets/images/pro_avatar.png' : schoolLogo" />
          </a>
          <ul class="dropdown-menu" *ngIf="!hideHeader" aria-labelledby="navbarDropdown">
            <!-- <li><a class="dropdown-item">{{this.schoolProps.mixpanel_data.school_name}}</a></li> -->
            <!-- <li><a class="dropdown-item">{{this.schoolProps.mixpanel_data.name}}</a></li> -->
            <li *ngIf="this.schoolProps.properties.job_only_school  && !this.schoolProps.properties.is_booking_school">
              <a class="dropdown-item" routerLink="/school/school-profile" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Profile</a>
            </li>
            <!-- <li><a class="dropdown-item">{{this.schoolProps.mixpanel_data.group_name}}</a></li> -->

            <li *ngIf="!this.schoolProps.properties.job_only_school">
              <a class="dropdown-item" routerLink="/school/school-profile" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">School Profile</a>
            <li *ngIf="!this.schoolProps.properties.job_only_school">
              <a class="dropdown-item" routerLink="/school/settings/my-settings" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Profile Settings</a>
            </li>
            <li *ngIf="!this.schoolProps.properties.job_only_school">
              <a class="dropdown-item" routerLink="/school/settings/admin-settings" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Admin Settings</a>
            </li>
            <li *ngIf="this.schoolProps.properties.job_only_school && !this.schoolProps.properties.booking_school">
              <a class="dropdown-item" routerLink="/school/settings/edit-admin-settings/additional-users"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Manage Team</a>
            </li>
            <li *ngIf="!this.schoolProps.properties.job_only_school && this.schoolProps.properties.booking_school">
              <a class="dropdown-item" routerLink="/school/settings/edit-admin-settings/additional-users"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Manage Team</a>
            </li>
            <li *ngIf="this.schoolProps.properties.job_only_school && !this.schoolProps.properties.booking_school">
              <a class="dropdown-item" routerLink="/school/jobs/job-settings" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">My Settings</a>
            </li>
            <li *ngIf="from_page_cookie == 'loginas'">
              <a class="dropdown-item" (click)="backToAdmin()">Back to admin</a>
            </li>
            <li *ngIf="from_page_cookie == 'loginas_coordinator'">
              <a class="dropdown-item" (click)="backToCoOrdinator()">Back to Coordinator</a>
            </li>
            <app-swich-back-to-python [jobOnly]="this.schoolProps.properties.job_only_school" fromComp="header_menu"></app-swich-back-to-python>
            <li>
              <a class="dropdown-item" href="" (click)="logout()">Logout</a>
            </li>
            <!-- <li><a class="dropdown-item">Switch to old ClassCover Website</a></li> -->
            <div class="HideOnRes">
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a class="dropdown-item">{{
                  schoolProps.mixpanel_data.school_name
                  }}</a>
              </li>
              <li>
                <a class="dropdown-item plan-badge">{{ plan }}</a>
              </li>
            </div>
          </ul>
        </li>
      </div>

      <!-- <p class="nav-link HideOnRes plan-badge" *ngIf="this.schoolProps.properties.job_only_school">
            {{plan}}
        </p> -->
    </div>
  </nav>
