<div class="container login-container jobs_only_user">
        <div class="row flex-res">
            <div class="col-md-6 ads r_simage">
                <p class="join_hub">Join the <span class="hub">hub.</span></p>
                <p class="sign_txt">Gain access to a vast community of educators, and streamline your hiring process
                    through our specialised jobs platform.</p>
            </div>
            <div class="col-md-6  card">
                <div class="">
                <div class="register-form">
                    <div class="px-0 pt-5 mb-4 ">

                        <p class="text-center">
                            <img alt="" class="r_image" src="assets/images/side-nav/cc_original@2x.png">
                        </p>
                        <ul id="progress-bar" class="px-0 mt-30 mb-100"
                            [ngClass]="!hideBasicInfos ? 'progressbar' : 'progressbar_c'">
                            <li [ngClass]="!hideBasicInfos ? 'one_active' : ''" class="">Basic information</li>
                            <li [ngClass]="hideBasicInfos ? 'two_active' : ''">Create password</li>
                        </ul>
                        <div *ngIf="!hideBasicInfos">
                            <form [formGroup]="BasicInformationForm" autocomplete="off">
                                <div class="row px-0 mt-30">
                                    <div class="col-lg-12">
                                        <label>Are you part of a school or education business? <span
                                                class="mandatory">*</span></label>
                                        <ng-select [searchable]="false" [clearable]="false"
                                            [dropdownPosition]="'bottom'" [multiple]="false" type="text"
                                            formControlName="organization_type" placeholder="Select state">
                                            <ng-option value="">Select organisation type</ng-option>
                                            <ng-option value="school">School</ng-option>
                                            <ng-option value="company">Education Business</ng-option>
                                        </ng-select>
                                        <div *ngIf="submittedBasic">
                                            <span class="show-errors"
                                                *ngIf="b['organization_type'].errors?.['required'] || b['organization_type'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-10">
                                        <label>Name of School/Company <span class="mandatory">*</span></label>
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="school_name" placeholder="Enter Name of School/Company">
                                        <div *ngIf="submittedBasic">
                                            <span class="show-errors"
                                                *ngIf="b['school_name'].errors?.['required'] || b['school_name'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mt-10">
                                        <label>Street number <span class="mandatory">*</span></label>
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="street_number" placeholder="Type here">
                                        <div *ngIf="submittedBasic">
                                            <span class="show-errors"
                                                *ngIf="b['street_number'].errors?.['required'] || b['street_number'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div> 
                                    </div>
                                    <div class="col-lg-6 mt-10">
                                        <label>Street name <span class="mandatory">*</span></label>
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="street_name" placeholder="Type here">
                                        <div *ngIf="submittedBasic">
                                            <span class="show-errors"
                                                *ngIf="b['street_name'].errors?.['required'] || b['street_name'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-12 mt-10">
                                        <label>State <span class="mandatory">*</span></label>
                                        <ng-select [searchable]="false" [clearable]="false"
                                            [dropdownPosition]="'bottom'" [multiple]="false" type="text"
                                            formControlName="state" placeholder="Select state">
                                            <ng-option value="">Select state</ng-option>
                                            <ng-option value="Australian Capital Territory">Australian Capital
                                                Territory</ng-option>
                                            <ng-option value="New South Wales">New South Wales</ng-option>
                                            <ng-option value="Northern Territory">Northern Territory</ng-option>
                                            <ng-option value="Queensland">Queensland</ng-option>
                                            <ng-option value="South Australia">South Australia</ng-option>
                                            <ng-option value="Tasmania">Tasmania</ng-option>
                                            <ng-option value="Victoria">Victoria</ng-option>
                                            <ng-option value="Western Australia">Western Australia</ng-option>
                                        </ng-select>
                                        <div *ngIf="submittedBasic">
                                            <span class="show-errors"
                                                *ngIf="b['state'].errors?.['required'] || b['state'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mt-10">
                                        <label>City or suburb <span class="mandatory">*</span></label>
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="city" placeholder="Type here">
                                        <div *ngIf="submittedBasic">
                                            <span class="show-errors"
                                                *ngIf="b['city'].errors?.['required'] || b['city'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mt-10">
                                        <label>Postcode <span class="mandatory">*</span></label>
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="postal_code" placeholder="Type here">
                                        <div *ngIf="submittedBasic">
                                            <span class="show-errors"
                                                *ngIf="b['postal_code'].errors?.['required'] || b['postal_code'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 mt-20">
                                        <p class="" (click)="hideBasicInfo()"><button
                                                class="btn cc_p_btn btn_w">Next</button></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="hideBasicInfos">
                            <form [formGroup]="otherInformationForm" autocomplete="off">
                                <div class="row px-0 mt-30">
                                    <div class="col-lg-12 mt-10">
                                        <label>Full name <span class="mandatory">*</span></label>
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="full_name" placeholder="Enter full name">
                                        <div *ngIf="submittedOther">
                                            <span class="show-errors"
                                                *ngIf="o['full_name'].errors?.['required'] || o['full_name'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-10">
                                        <label>Phone number <span class="mandatory">*</span></label>
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="phone_no" placeholder="Enter phone number">
                                        <div *ngIf="submittedOther">
                                            <span class="show-errors"
                                                *ngIf="o['phone_no'].errors?.['required'] || o['phone_no'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-10">
                                        <label>Email <span class="mandatory">*</span></label>
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="user_email" placeholder="Enter email">
                                        <div *ngIf="submittedOther">
                                            <span class="show-errors"
                                                *ngIf="o['user_email'].errors?.['required'] || o['user_email'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-10">
                                        <label>Create password <span class="mandatory">*</span></label>
                                        <input autocomplete="off" type="password" class="form-control"
                                            formControlName="password" placeholder="Enter password of your choice">
                                        <div *ngIf="submittedOther">
                                            <span class="show-errors"
                                                *ngIf="o['password'].errors?.['required'] || o['password'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-10">
                                        <label>Retype password <span class="mandatory">*</span></label>
                                        <input autocomplete="off" (keyup)="makePasswordFalse()" type="password"
                                            class="form-control" formControlName="confirm_password"
                                            placeholder="Reenter password">
                                        <div *ngIf="submittedOther">
                                            <span class="show-errors"
                                                *ngIf="o['confirm_password'].errors?.['required'] || o['confirm_password'].errors?.['whitespace']">*
                                                This field is required</span>
                                        </div>
                                        <span class="show-errors" *ngIf="!passwordMatch">
                                            Password does not match</span>
                                    </div>
                                    <div class="row mt-10">
                                        <div class="col-lg-12">
                                            <div class="d-flex">
                                                <div>
                                            <input id="checkBox" (change)="checkCondition($event)" class="form-check-input" type="checkbox" />
                                        </div>
                                        <div>
                                            <label for="checkBox" class="ml-5 txt-regular m-0 mt-4">I agree to the <a class="reg-Link clr-cc-vilot"  target="_blank"  href="https://www.classcover.com.au/terms-and-conditions/">Terms and Conditions</a> and
                                                <a class="reg-Link clr-cc-vilot" target="_blank" href="https://www.classcover.com.au/privacy-policy/">Privacy Policy</a>.</label>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="col-lg-12 mt-10">
                                        <p (click)="hideOtherInfo()" class="text-regular-medium clr-cc-vilot c-p"><i
                                                class="bi bi-chevron-left"></i> Back</p>
                                    </div>
                                    <div class="col-lg-12 mt-10">
                                        <p  (click)="submitRegistration()"><button
                                                class="btn cc_p_btn btn_w">Get started</button></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <hr class="mt-20 mb-20">
                        <div class="col-lg-12 mt-5 text-center">
                            <!-- <p class="txt-regular clr-grey-3 c-p" (click)="openRequestAccessModal()">Does your school or
                                organisation already have a
                                ClassCover Jobs account?<span class="text-regular-medium clr-cc-vilot c-p"> Request
                                    access</span></p> -->

                            <p class="txt-regular clr-grey-3 c-p">Already have an account? <span routerLink="/"
                                    class="text-regular-medium clr-cc-vilot c-p">Login</span></p>
                        </div>
                        <div class="col-lg-12 mt-5 text-center">
                            <p class="text-regular-medium"> or</p>
                        </div>
                        <div class="col-lg-12 mt-5 text-center">
                            <p class="txt-regular clr-grey-3 c-p" (click)="openRequestAccessModal()">Join an existing
                                account <span class="text-regular-medium clr-cc-vilot c-p"> Request
                                    access</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <ng-template #cancelReasonModal>
            <form [formGroup]="cancelBookingRequestForm" class="jobs_only_user">
                <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel">Request access to be added to your school’s
                        ClassCover account</h4>
                    <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
                </div>

                <div class="modal-body pb-0">
                    <div class="row  align-items-center">
                        <div class="col-lg-12 mt-10">
                            <label>School <span class="mandatory">*</span></label>
                            <ng-select [searchable]="true" [clearable]="false" [dropdownPosition]="'bottom'"
                                [multiple]="false" type="text" formControlName="school_id" placeholder="Select state">
                                <ng-option value="">Select school</ng-option>
                                <ng-option *ngFor="let list of filtersArray.school_list"
                                    value="{{list.school_id}}">{{list.school_name}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted">
                                <span class="show-errors"
                                    *ngIf="a['school_id'].errors?.['required'] || a['school_id'].errors?.['whitespace']">*
                                    This field is required</span>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-10">
                            <label>Name <span class="mandatory">*</span></label>
                            <input autocomplete="off" type="text" class="form-control" formControlName="user_name"
                                placeholder="Type here">
                            <div *ngIf="submitted">
                                <span class="show-errors"
                                    *ngIf="a['user_name'].errors?.['required'] || a['user_name'].errors?.['whitespace']">*
                                    This field is required</span>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-10">
                            <label>Email <span class="mandatory">*</span></label>
                            <input autocomplete="off" type="text" class="form-control" formControlName="user_email"
                                placeholder="Enter email">
                            <div *ngIf="submitted">
                                <span class="show-errors"
                                    *ngIf="a['user_email'].errors?.['required'] || a['user_email'].errors?.['whitespace']">*
                                    This field is required</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="modal-footer mt-10">
                    <button type="button" (click)="submit_access()" class="btn cc_p_btn fs_13">Submit</button>
                </div>
            </form>
        </ng-template>
    </div>
</div>