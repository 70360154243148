<i *ngIf="validateDict.is_bathurst_validated" class="bi bi-check-circle-fill check-completed ml-5"></i>
<i *ngIf="validateDict.is_maitland_newcastle_validated" class="bi bi-check-circle-fill check-completed ml-5"></i>
<i *ngIf="validateDict.is_edubase_validated" class="bi bi-check-circle-fill check-completed ml-5"></i>
<i *ngIf="validateDict.is_brisbane_validated" class="bi bi-check-circle-fill check-completed ml-5"></i>
<i *ngIf="validateDict.is_canberra_goulburn_validated" class="bi bi-check-circle-fill check-completed ml-5"></i>
<i *ngIf="validateDict.is_diocese_wagga_validated" class="bi bi-check-circle-fill check-completed ml-5"></i>
<!-- <i *ngIf="validateDict.is_doe_school" class="bi bi-check-circle-fill check-completed ml-5"></i> -->
<i *ngIf="validateDict.is_lismore_school" class="bi bi-check-circle-fill check-completed ml-5"></i>
<i *ngIf="validateDict.is_diocese_lismore_validated" class="bi bi-check-circle-fill check-completed ml-5"></i>

<span *ngIf="validateDict.is_validated" class="tooltips">
    <i *ngIf="validateDict.training_completed"  class="bi bi-check-circle-fill check-completed ml-5"></i>
    <i *ngIf="!validateDict.training_completed"  class="bi bi-check-circle-fill partially-completed ml-5"></i>
    <span [ngClass]="from == 'bookingSideInviteList' ? 'tooltiptextSideInvite' : 'tooltiptext'" class="">
        <p class="mb-10">Verified with NSW Department of Education</p>
        <p><span><i class="bi bi-check-circle-fill check-completed ml-5"></i></span> Approval to teach</p>
        <p   *ngIf="!validateDict.training_completed" class="mb-5" ><span><i class="bi bi-x-circle-fill  ml-5 mandatory-not-completed"></i></span> Mandatory Training Completed</p>
        <p   *ngIf="validateDict.training_completed" class="mb-5" ><span><i class="bi bi-check-circle-fill check-completed ml-5"></i></span> Mandatory Training Completed</p>
        <p class="mt-10">Find out more <a href="https://intercom.help/classcover-support/en/articles/4669343-what-does-the-verification-symbol-next-to-a-teacher-s-name-mean-nsw-only"  target="_blank">here</a></p>
    </span>
</span>