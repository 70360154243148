import { ChangeDetectorRef, Component, OnInit, ViewChildren, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Renderer2 } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { map, take } from "rxjs/operators";
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';

// Libs
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import * as moment from 'moment';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

// import { PreBookingModalsComponent } from '../modals/pre-booking-modals/pre-booking-modals.component';
import timepicker from 'src/app/pages/producer/timepicker';
import { DatePipe } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartConfiguration, ChartData, ChartEvent, ChartOptions, ChartType } from 'chart.js';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-insights-report',
  templateUrl: './insights-report.component.html',
  styleUrls: ['./insights-report.component.scss']
})
export class InsightsReportComponent implements OnInit {
  fromDate: Date;
  toDate: Date;
  data: any;
  chartData: any;
  // public lineChartData: ChartConfiguration['data'] | undefined 
  labelData: any;
  splitLabelDate: any
  public lineChartData: ChartConfiguration['data'] | undefined;
  public lineChartDataOption: ChartConfiguration['options'] | undefined;
  public doughnutChartData: ChartConfiguration['data'] | undefined;
  public barChartData: ChartConfiguration['data'] | undefined;
  public barChartType: ChartType = 'bar';
  public doughnutChartOption: ChartConfiguration['options'] = {
    aspectRatio: 2.17,

    elements: {
      arc: {
        backgroundColor: ['rgba(24, 200, 100, 1)', 'rgba(236, 239, 242, 1)'],
        hoverBackgroundColor: ['rgba(24, 200, 100, 1)', 'rgba(236, 239, 242, 1)'],
        hoverBorderColor:['rgba(24, 200, 100, 1)', 'rgba(236, 239, 242, 1)']
      }
    }


  }

  public barChartOptions: ChartConfiguration['options'] = {
    indexAxis: 'y',
    responsive: true,

    //   yAxes: [{
    //     barPercentage: 0.4
    // }],
    scales: {
      gridLines: {
        display: false
      },
      x: {
        grid: {
          display: true
        }

      },
      y: {
        min: 0,
        grid: {
          display: false
        }

      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    
    elements: {
      bar: {
        borderWidth: 0,
        backgroundColor: 'rgba(58, 12, 163, 1)',
        hoverBackgroundColor: 'rgba(58, 12, 163, 1)',
        hoverBorderColor: 'rgba(58, 12, 163, 1)',
      },
    }
    
  };

  // public lineChartOptions: ChartConfiguration['options'] = {

  //   scales: {
  //     x: {

  //       grid: {
  //         display: false
  //       }
  //     },
  //     y: {
  //       min: 0,
  //       max: 10,
  //       grid: {
  //         display: true
  //       }

  //     },
  //   },
  //   plugins: {
  //     legend: {
  //       display: true,
  //     },

  //   },
  //   elements: {
  //     line: {
  //       tension: 2,
  //       borderWidth: 1.5,
  //     },
  //   },
  // };

  chartDonutData: any;
  labelDonutData: any;
  topTeacher: any;
  topAbsences: any;
  barChartLabel: any;
  barChartDatas: any;
  activeTeacherCount: any;
  absent_teacher_count: any;

  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private toastr: ToastrService) {
    this.fromDate = new Date();
    this.toDate = new Date();
    this.fromDate.setDate(this.fromDate.getDate() - 15);
    this.toDate.setDate(this.toDate.getDate() - 1);
  }
  sendDateForm: FormGroup = this.fb.group({
    from: 'angular',
    toDate: [''],
    fromDate: [''],
  });

  ngOnInit(): void {
    this.ngxService.start()
    this.bookingService.insightReportPageAPI(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        console.log('k', res)
        this.data = res
        this.chartData = res.datasets
        this.labelData = res.labels
        let splitLabelDateArr: any[] = []
        let splitLabelDateArrOne: any[] = []

        // this.splitLabelDate = this.labelData
        this.labelData.forEach((data: any) => {
          this.splitLabelDate = data.split("#")
          var item = this.splitLabelDate[0]+' ('+this.splitLabelDate[1]+')'
          // console.log(this.splitLabelDate[0])
          splitLabelDateArr.push(this.splitLabelDate[0])
          splitLabelDateArrOne.push(item)
        })      
          this.lineChartData = {
          datasets: this.chartData,
          labels:splitLabelDateArr,
        }
        this.lineChartDataOption = {
          scales: {
            x: {
              grid: {
                display: true
              }
            },
            y: {
              min: 0,
              max: 10,
              grid: {
                display: true
              }
      
            },
          },

          elements: {
            line: {
              tension: 2,
              borderWidth: 1.5,
            },
          },
          // plugins: {
          //   tooltip: {
          //     callbacks: {
          //       title: function(tooltipItem) {
                  
          //           var dates = splitLabelDateArrOne
          //           dates.forEach((data: any) => {
          //             dates = data.split("#")
          //             var item = dates[0]+' ('+dates[1]+')'
          //             splitLabelDateArrOne.push(item)
          //           })   
          //           return dates
          //       }
          //   }
          //   },
          // }
        }
        this.ngxService.stop()
      },
      error: (err: any) => {
        console.log(err);
      }
    });


    this.bookingService.teachersListReportPageAPI(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        console.log('k', res)
        this.topTeacher = res.teacher_data
      },
      error: (err: any) => {
        console.log(err);
      }
    });
    this.bookingService.absenceListGraphReportPageAPI(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        console.log('k', res)
        this.topAbsences = res.absence_data
        this.absent_teacher_count = res.absent_teacher_count
      },
      error: (err: any) => {
        console.log(err);
      }
    });
    this.bookingService.absenceGraphReportPageAPI(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        this.barChartDatas = res.datasets
        this.barChartLabel = res.labels
        this.barChartData = {
          datasets: this.barChartDatas,
          labels: this.barChartLabel,
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
    this.bookingService.teachersGraphReportPageAPI(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        console.log('k1', res)
        this.activeTeacherCount = res.active_teacher_count
        this.chartDonutData = res.datasets
        this.labelDonutData = res.labels
        this.doughnutChartData = {
          datasets: this.chartDonutData,
          labels: this.labelDonutData,
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  getFormControlValue() {
    this.sendDateForm.patchValue({
      toDate: new DatePipe('en-US').transform(this.toDate, 'dd-MM-yyyy'),
      fromDate: new DatePipe('en-US').transform(this.fromDate, 'dd-MM-yyyy')
    })
    return this.sendDateForm.value
  }

  getInsightsData() {
    if(this.toDate == null || this.fromDate == null || this.toDate == undefined || this.fromDate == undefined){
      this.toastr.error("Value should not be empty")
      return
    }
    this.ngxService.start()
    this.bookingService.insightReportPageAPI(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        console.log('k', res)
        this.data = res
        this.chartData = res.datasets
        this.labelData = res.labels
        this.lineChartData = {
          datasets: this.chartData,
          labels: this.labelData,
        }
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop()
      }
    });
    this.bookingService.teachersListReportPageAPI(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        console.log('k', res)
        this.topTeacher = res.teacher_data
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop()
      }
    });
    this.bookingService.absenceListGraphReportPageAPI(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        console.log('k', res)
        this.topAbsences = res.absence_data
        this.absent_teacher_count = res.absent_teacher_count
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop()
      }
    });
    this.bookingService.absenceGraphReportPageAPI(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        this.barChartDatas = res.datasets
        this.barChartLabel = res.labels
        this.barChartData = {
          datasets: this.barChartDatas,
          labels: this.barChartLabel,
        }
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop()
      }
    });
    this.bookingService.teachersGraphReportPageAPI(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        console.log('k', res)
        this.chartDonutData = res.datasets
        this.labelDonutData = res.labels
        this.doughnutChartData = {
          datasets: this.chartDonutData,
          labels: this.labelDonutData,
        }
        this.ngxService.stop()
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop()
      }
    });
  }

}
