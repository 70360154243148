import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable, take } from "rxjs";
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { url } from 'inspector';
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { TeacherProfilePopupComponent } from '../../teacher-profile-popup/teacher-profile-popup.component';
import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';
import { SendMailComponent } from '../../modals/send-mail/send-mail.component';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../services/api/message.service';
import { MixpanelService } from 'src/app/mixpanel.service';

@Component({
  selector: 'app-headhunter',
  templateUrl: './headhunter.component.html',
  styleUrls: ['./headhunter.component.scss']
})
export class HeadhunterComponent implements OnInit {
  public schoolProps!: SchoolData;
  @ViewChild('resendPendingInviteModal') resendPendingInviteModal: any;
  headHunter: any;
  page: number = 1;
  selectAllCheck: boolean = false;
  isPartialChecked: boolean = false
  hideButton: boolean = true;
  consumerDetails: Array<any> = [];
  consumerDetailsInvited: Array<any> = [];
  allAppJobDetailsFilterForm: FormGroup = this.fb.group({
    job_id: [null],
    subject: [''],
    filter_by_name: [''],
    job_role_type: [''],
    school_level: [''],
    school_distance: [''],
    page: [''],
    show_partial_match: [''],
    filterType: ['']
  });

  inviteToJobForm: FormGroup = this.fb.group({
    job_id: [''],
    teacher_id: [''],
    custom_message: ['']
  });
  HeadHunterList: any;
  teacherLengthLimit: any;
  availTeacher: any;
  totalArrayCount: any;
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  FindNewteachersList: Observable<any> = this.obsArray.asObservable();
  currentPage: number = 1;
  showFilters: boolean = false;
  recent_applicant: any;
  isActiveDesc: boolean = false;
  isDistanceDesc: boolean = false;
  job_id: any
  Invitedteacher: any;
  remainingInvites: any;
  showRemainingCard: boolean = false;
  filtersArray: any;
  openFilters: boolean = false;
  showMatched: boolean = false;
  doubleClickDisabled: boolean = false
  setActiveActive: boolean = false;
  setDistanceActive: boolean = false;

  constructor(
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private mixpanelService: MixpanelService
  ) { }

  ngOnInit(): void {
    this.ngxService.start()
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.getFilterData()
    this.route.queryParams.subscribe(params => {
      this.job_id = params['jobId'];
    });
    if (this.job_id == undefined) {
      this.bookingService.getHeadHunter(this.getFormControlValue()).subscribe({
        next: (res: any) => {
          this.teacherLengthLimit = res.data.total_count;
          this.HeadHunterList = res.data.consumer_data;
          this.recent_applicant = res.data.recent_applicant
          this.remainingInvites = res.data.invite_count
          if (this.HeadHunterList.length > 0) {
            this.showFilters = true
          } else {
            this.showFilters = false
          }
          this.obsArray.next(res.data.consumer_data);
          this.ngxService.stop()
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop()
        }
      });
    }
  }

  getFilterData() {
    this.commonService.schoolChoices('headhunter').subscribe({
      next: (res: any) => {
        this.headHunter = res.data.job_list;
        this.filtersArray = res.data
        if (this.job_id != undefined) {
          this.allAppJobDetailsFilterForm.patchValue({
            job_id: this.job_id
          });
          this.getFilterInputs('')
        }
      },
      error: (err: any) => {
        // alert("error")
        console.log(err);
        this.ngxService.stop()
      }
    });
  }
  openFilter() {
    this.openFilters = !this.openFilters
  }
  getData(){
   var jobID = this.allAppJobDetailsFilterForm.controls['job_id'].value;
    this._router.navigate(['/school/headhunter'], { queryParams: { jobId: jobID } });

    if (jobID != undefined) {
      this.allAppJobDetailsFilterForm.patchValue({
        job_id: jobID
      });
      this.getFilterInputs('')
    }
  }
  getFilterInputs(filterApplied: any) {
    this.ngxService.start()
    this.ngListener()
    this.currentPage = 1;
    this.selectAllCheck = false
    this.consumerDetails = [];
    this.hideButton = true
    if (filterApplied == 'filterApplied') {
      this.allAppJobDetailsFilterForm.patchValue({
        filterType: 'filter'
      })
    }
    if (filterApplied == '') {
      this.openFilters = false
    }

    if (filterApplied == '' || filterApplied == 'clearFilter') {
      this.allAppJobDetailsFilterForm.patchValue({
        subject: [''],
        filter_by_name: [''],
        job_role_type: [''],
        school_level: [''],
        school_distance: [''],
        page: 1,
        show_partial_match: [''],
        filterType: ['']
      })
    }
    this.bookingService.getHeadHunter(this.getFormControlValue()).subscribe({
      next: (res: any) => {
        this.allAppJobDetailsFilterForm.patchValue({
          filterType: ''
        })
        this.teacherLengthLimit = res.data.total_count;
        this.HeadHunterList = res.data.consumer_data;
        this.recent_applicant = res.data.recent_applicant
        this.remainingInvites = res.data.invite_count
        var is_premium = res.data.is_premium_subscription
        if(is_premium){
          this.showRemainingCard = false
        }else{
          this.showRemainingCard = true
        }
        this.showFilters = true
        if (filterApplied == '' || filterApplied == 'clearFilter') {
          this.allAppJobDetailsFilterForm.patchValue({
            subject: res.data.job_data.subject_taught,
            job_role_type: res.data.job_data.job_role_type,
            school_level: res.data.job_data.school_level,
            school_distance: res.data.job_data.school_distance,
            filter_by_name: ''
          })
        }

        if (this.HeadHunterList.length > 0) {
          this.showMatched = true
        } else {
          this.showMatched = false
        }

        this.obsArray.next(res.data.consumer_data);
        this.ngxService.stop()

      },
      error: (err: any) => {
        this.allAppJobDetailsFilterForm.patchValue({
          filterType: ''
        })
        console.log(err);
        this.ngxService.stop()
      }
    });
  }
  getFormControlValue() {
    this.allAppJobDetailsFilterForm.patchValue({
      page: this.currentPage
    });
    if (this.allAppJobDetailsFilterForm.controls['subject'].value == '') {
      this.allAppJobDetailsFilterForm.patchValue({
        subject: ''
      });
    }
    return this.allAppJobDetailsFilterForm.value
  }
  // set checkbox selected teachers
  setCheckbox(event: any, type: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.consumerDetails = [];
      this.consumerDetailsInvited = [];
      this.HeadHunterList.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          this.consumerDetails.push(x);
          this.selectAllCheck = true;
          this.hideButton = false
        } else {
          this.hideButton = true
          x.isChecked = false;
          this.consumerDetails = [];
          this.selectAllCheck = false;
        }
      });
      // if (checked == true && this.consumerDetails.length == 0) {
      //   event.target.checked = false
      //   this.toastr.warning('Invite already sent to the showing results , scroll down for more results')
      // }
    } else {
      if (checked) {
        type.isChecked = true;
        this.consumerDetails.push(type)
        this.selectAllCheck = false;
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.consumerDetails = this.consumerDetails.filter(x => x != type);
      }
    }
    if (checked == true && this.consumerDetails.length != 0 && (this.HeadHunterList.length == (this.consumerDetails.length + this.consumerDetailsInvited.length))) {
      this.selectAllCheck = true
    } else {
      this.selectAllCheck = false
      event.target.checked = false
    }
    if (this.consumerDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }

  isPartial(event: any) {
    this.isPartialChecked = event.target.checked
    this.allAppJobDetailsFilterForm.patchValue({
      show_partial_match: this.isPartialChecked
    })
    this.getFilterInputs('filterApplied')
  }



  getTeacherAvailabilityModal(teacher_id: any, from: any, invite_sent: any, remainingInvites: any) {
    // this.ngxService.start();
    this.doubleClickDisabled = true
    this.availTeacher = teacher_id
    this.bookingService.getAvailability(this.availTeacher).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.doubleClickDisabled = false
          // this.obsArray.next(res.data.data);
          const initialState = {
            teacher: res.data,
            from: from,
            invite_sent: invite_sent,
            remainingInvites: remainingInvites,
            job_id: this.allAppJobDetailsFilterForm.controls["job_id"].value
          };
          this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.closeBtnName = 'Close';
          // this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.doubleClickDisabled = false
        }
      },
      error: (err: any) => {
        this.doubleClickDisabled = false
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
        this.ngxService.stop();
      }
    });
  }
  getTeacherLength() {
    this.FindNewteachersList.subscribe(result => {
      this.totalArrayCount = result.length;
    });
  }
  sendMessage(teacher: any) {
    var jobID = this.allAppJobDetailsFilterForm.controls['job_id'].value;
    const initialState = {
      teacher: teacher,
      headHunterJobId: jobID
    };
    this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
  }
  sendMail() {
    var jobID = this.allAppJobDetailsFilterForm.controls['job_id'].value;
    const initialState = {
      teacher: Array.prototype.map.call(this.consumerDetails, function (item) { return item.user.id; }).join(","),
      from: 'headHunter',
      all_checkbox: true,
      checked_status: this.consumerDetails,
      selectAllVal: this.selectAllCheck,
      headHunterJobId: jobID
    };
    this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
  }
  openInviteModal(teacher: any) {
    if(this.schoolProps.mixpanel_data.is_premium_subscription == false){
      if (this.consumerDetails.length > this.remainingInvites) {
        this.toastr.warning('Selected list is greater than remaining invite, invites will send only for remaining invite count')
      }
    }
  
    this.Invitedteacher = teacher
    this.inviteToJobForm.patchValue({
      custom_message: ''
    })
    this.bsModalRef = this.modalService.show(this.resendPendingInviteModal, { class: 'modal-md', backdrop: 'static' });
  }
  inviteToJob(teacher: any) {
    this.ngxService.start();
    if (this.consumerDetails.length > 0) {
      this.inviteToJobForm.patchValue({
        job_id: this.allAppJobDetailsFilterForm.controls["job_id"].value,
        teacher_id: Array.prototype.map.call(this.consumerDetails, function (item) { return item.id; }).join(",")
      })
    } else {
      this.inviteToJobForm.patchValue({
        job_id: this.allAppJobDetailsFilterForm.controls["job_id"].value,
        teacher_id: teacher.id.toString()
      })
    }
    this.bsModalRef.hide();
    this.mixpanelService.sendInviteToJob('Headhunter Invite');
    this.bookingService.inviteJob(this.getInviteJobFormControlValue()).subscribe({
      next: (res: any) => {
        this.inviteToJobForm.patchValue({
          custom_message: ''
        })
        if (res.status) {
          this.toastr.success(res.message);
          if (this.consumerDetails.length > 0) {
            this.getFilterInputs('filterApplied')
            this.consumerDetails = [];
            this.hideButton = true
            this.selectAllCheck = false;
            this.remainingInvites = res.invite_count
          } else {
            var index = this.HeadHunterList.map((x: any) => {
              return x.id;
            }).indexOf(res.data[0].id);
            this.HeadHunterList[index] = res.data[0];
            this.ngxService.stop();
            this.remainingInvites = res.invite_count
          }
        } else {
          alert('error')
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        alert("error")
        console.log(err);
        // this.toastr.error(err.message);
        this.ngxService.stop()
      }
    });

  }
  closeModal() {
    this.bsModalRef.hide()
    this.inviteToJobForm.reset()
  }
  getInviteJobFormControlValue() {
    return this.inviteToJobForm.value
  }
  // Scroll to getData
  scroll = (event: any): void => {
    this.getTeacherLength();
    // alert(this.teacherLengthLimit)
    let totalPages = Math.ceil(this.teacherLengthLimit / this.totalArrayCount);
    // console.log(this.teacherLengthLimit);
    // console.log(this.totalArrayCount)
    // console.log(totalPages)

    // alert(totalPages)
    // alert(this.teacherLengthLimit)
    // alert(this.totalArrayCount)
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.Paginator(this.totalArrayCount, this.currentPage, 20);
      // console.log(this.currentPage);
      // console.log(activePage);
      this.allAppJobDetailsFilterForm.patchValue({
        filterType: 'filter'
      })
      this.currentPage = activePage + 1;
      // alert("hi")
      if (this.allAppJobDetailsFilterForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.FindNewteachersList.pipe(take(1)), this.bookingService.getHeadHunter(this.getFormControlValue())]).subscribe((data: any) => {
          this.teacherLengthLimit = data[1].data.total_count;
          // console.log(data[0]);
          const newArr = [...data[0], ...data[1].data.consumer_data];
          this.obsArray.next(newArr);
          this.HeadHunterList = newArr
          this.allAppJobDetailsFilterForm.patchValue({
            filterType: ''
          })
          this.ngxService.stop();
        });
      }
    }
  };
  Paginator(totlallength: any, page: any, per_page: any) {
    var page = page || 1,
      per_page = per_page || 20,
      offset = (page - 1) * per_page,

      // paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }
  ngListener() {
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }


  sort(type: any) {
    // let direction = this.isDesc ? 1 : -1;
    if (type == 'lastActive') {
      this.setActiveActive = !this.setActiveActive
      this.setDistanceActive = false
      if (this.isActiveDesc) {
        this.HeadHunterList = this.HeadHunterList.sort((i: any, j: any) => (j.last_active > i.last_active ? -1 : 1));
      }
      else {
        this.HeadHunterList = this.HeadHunterList.sort((i: any, j: any) => (j.last_active > i.last_active ? 1 : -1));
      }
      this.isActiveDesc = !this.isActiveDesc
    }
    if (type == 'distance') {
      this.setDistanceActive = !this.setDistanceActive
      this.setActiveActive  = false
      console.log(this.isDistanceDesc);
      if (this.isDistanceDesc) {
        this.HeadHunterList = this.HeadHunterList.sort((i: any, j: any) => (j.distance_from < i.distance_from ? -1 : 1));
      }
      else {
        this.HeadHunterList = this.HeadHunterList.sort((i: any, j: any) => (j.distance_from < i.distance_from ? 1 : -1));
      }
      this.isDistanceDesc = !this.isDistanceDesc
    }
  }
  setAllTrue() {
    this.consumerDetails = [];
    this.hideButton = true
    this.selectAllCheck = false;
  }
  sub!: Subscription
  ngAfterContentInit() {
    this.sub = this.sharedService.setValData.subscribe(
      data => {
        if (data == 'triggerSetAllFunc') {
          this.setAllTrue()
        }
      }
    )
  }
}
