import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfig {
  constructor() { }

  schoolAdUser = environment.apiUrl + 'api/v1/school-user/';
  // schoolChoices = environment.apiUrl +'v1/school-choices/?source=';
  meCall = environment.apiUrl + 'api/v1/mixpanel-intercom/user-data/';
  createToken = environment.apiUrl + 'oauth/v2/token/';
  getUserCredentail = environment.apiUrl + 'api/v3/school/redirect/';

  //School Api's
  schoolApiUrl = {
    getBookingList: this.schoolAdUser + 'booking-request-list/',
    bookingRequest: environment.apiUrl + 'api/v1/bookings/',
    preConfirmed: this.schoolAdUser + 'booking-preconfirmed-list/',
    findNewTeacher: this.schoolAdUser + 'find-new-teachers/',
    myTeachers: this.schoolAdUser + 'my-list/',
    myChoices: environment.apiUrl + 'api/v1/school-choices/?source=',
    registerChoices: environment.apiUrl + 'api/v1/register-choices/?source=',
    preConfirmedReviewOccurence: environment.apiUrl + 'api/v1/bookings/get-recurring-dates/',
    cancelBookingRequest: environment.apiUrl + 'api/v1/bookings/cancel-booking-line-item/',
    editBookingRequest: environment.apiUrl + 'api/v1/bookings/edit-booking/',
    calendar: environment.apiUrl + 'api/v1/school-user/school-calander/',
    getRequestHistory: this.schoolAdUser + 'request-history/',
    getInFlightData: this.schoolAdUser + 'booking-inflight/',
    stopBookingRequest: environment.apiUrl + 'api/v1/bookings/cancel-booking/',
    getPendingInviteList: environment.apiUrl + 'api/v1/invite-teacher/pending-invite/',
    getInvitePendingList: environment.apiUrl + 'api/v1/invite-teacher/pending/',
    getInviteHistoryList: environment.apiUrl + 'api/v1/invite-teacher/history/',
    updateBookingRequest: environment.apiUrl + 'api/v1/bookings/update-booking/',
    reSendInvite: environment.apiUrl + 'api/v1/invite-teacher/resend-invite/',
    cancelInvite: environment.apiUrl + 'api/v1/invite-teacher/cancel-invite/',
    profileSaveChanges: environment.apiUrl + 'api/v1/school-user/school-settings/',
    // sendMailInvite: environment.apiUrl +'api/v1/invite-teacher/send-mail/',
    sendMailInvite: environment.apiUrl + 'api/v1/school-user/send-message/',
    listHealth: environment.apiUrl + 'api/v1/school-user/teacher-health/',
    pendingRequestList: environment.apiUrl + 'api/v1/invite-school/pending-request/',
    pendingRequestPendingList: environment.apiUrl + 'api/v1/invite-school/pending/',
    pendingRequestHistoryList: environment.apiUrl + 'api/v1/invite-school/history/',
    pendingRequestBlockedList: environment.apiUrl + 'api/v1/invite-school/blocked/',
    getMyList: environment.apiUrl + 'api/v1/school-user/my-list/',
    requestAction: environment.apiUrl + 'api/v1/invite-school/request-action/',
    myListAction: environment.apiUrl + 'api/v1/school-user/mylist-actions/',
    getJobs: environment.apiUrl + 'api/v1/jobs/school-jobs/',
    getJobsListingSideCom: environment.apiUrl + 'api/v1/jobs/school-stats/',
    callBackPaymentFunctionAPI: environment.apiUrl + 'api/v1/school-user/check_payment/',
    boostJob: environment.apiUrl + 'api/v1/jobs/boost-job/',
    getJobDetails: environment.apiUrl + 'api/v1/jobs/job-applicants/',
    jobDetailsAction: environment.apiUrl + 'api/v1/jobs/update-application-status/',
    addExternalEvent: environment.apiUrl + 'api/v1/school-event/',
    postJobsApi: environment.apiUrl + 'api/v1/jobs/',
    updateJobsAPi: environment.apiUrl + 'api/v1/jobs/',
    jobMakeLive: environment.apiUrl + 'api/v1/jobs/activate-job/',
    jobSubsUpdate: environment.apiUrl + 'api/v1/school-user/update-job-subscription/',
    jobPaymentHistory: environment.apiUrl + 'api/v1/school-user/payment-history/',
    editJobsApi: environment.apiUrl + 'api/v1/jobs/job-details/',
    addNotesApi: environment.apiUrl + 'api/v1/school-user/notes-update/',
    createSmart: environment.apiUrl + 'api/v1/smart-groups/',
    ProviderRatings: environment.apiUrl + 'api/v1/update-rating/',
    calendarbookings: environment.apiUrl + 'api/v1/school-user/school-booking/',
    bulkEditData: environment.apiUrl + 'api/v1/bookings/bulk-edit-booking/',
    updateBulkDataApi: environment.apiUrl + 'api/v1/bookings/bulk-edit-booking/',
    // addNotesApi: environment.apiUrl +'en/school/xhr/provider/notes/update/'
    profilePageDetails: environment.apiUrl + 'api/v1/school-user/school-profile/',
    // editProfileData: environment.apiUrl +'api/v1/save_schoolprofile/',
    editProfileData: environment.apiUrl + 'api/v1/school-user/school-profile-update/',
    sendReceiveRequestAPI: environment.apiUrl + 'api/v1/show_school/',
    profilePageChanges: environment.apiUrl + 'api/v3/get_states/',
    // profilePageTimeZoneChanges: environment.apiUrl +'api/v3/time_zone/',        
    sendInvite: environment.apiUrl + 'api/v1/invite-teacher/',
    getSmartGroups: environment.apiUrl + 'api/v1/smart-groups/smartgroup-list/',
    getSmartGroupsList: environment.apiUrl + 'api/v1/smart-groups/',
    smartGroupsActions: environment.apiUrl + 'api/v1/smart-groups/actions/',
    sendNudgeApi: environment.apiUrl + 'api/v1/school-user/nudge-action/',
    addToSmartGroup: environment.apiUrl + 'api/v1/smart-groups/add-list/',
    exportTeachers: environment.apiUrl + 'api/v1/school-user/export/',
    getAvailability: environment.apiUrl + 'api/v1/school-user/teacher-details/',
    saveChangesAdminSettings: environment.apiUrl + 'api/v1/school-user/admin-settings/',
    addAdditionalUserApi: environment.apiUrl + 'api/v1/school-user/',
    saveChangesSchoolSettings: environment.apiUrl + 'api/v1/school-user/school-settings/',
    manageTeamSettings: environment.apiUrl + 'api/v1/school-user/manage-team/',
    checkAvalability: environment.apiUrl + 'api/v1/school-user/available-check/',
    getInviteRequest: environment.apiUrl + 'api/v1/school-user/invite-request/',
    editSmartGroup: environment.apiUrl + 'api/v1/smart-groups/',
    register: environment.apiUrl + 'api/v1/school/',
    getSchoolAccessApi: environment.apiUrl + 'api/v1/request-access/school/',
    chargebee: environment.apiUrl + 'api/v1/school-user/chargebee/',
    headHunter: environment.apiUrl + 'api/v1/jobs/headhunter/',
    inviteJobApi: environment.apiUrl + 'api/v1/jobs/invite-job/',
    recentJobs: environment.apiUrl + 'api/v1/jobs/recent-job/',
    addNewList: environment.apiUrl + 'api/v1/invite-teacher/add-new/',
    schoolStaff: environment.apiUrl + 'api/v1/school-staff/school-staff/',
    updateSchoolStaff: environment.apiUrl + 'api/v1/school-staff/',
    deleteSchoolStaff: environment.apiUrl + 'api/v1/school-staff/staff-remove/',
    exportBookingsAPI: environment.apiUrl + 'api/v1/school-user/export-booking/',
    getHubSpokeDataAPI: environment.apiUrl + 'api/v1/school-user/hub-spoke-request/',
    insightReportPageAPI: environment.apiUrl + 'api/v1/dashboard-booking/',
    teachersListReportPageAPI: environment.apiUrl + 'api/v1/teachers_list/',
    absenceListGraphReportPageAPI: environment.apiUrl + 'api/v1/absence_list_graph/',
    absenceGraphReportPageAPI: environment.apiUrl + 'api/v1/absence_graph/',
    teachersGraphReportPageAPI: environment.apiUrl + 'api/v1/teachers_graph/',
    exportPayrollAPI: environment.apiUrl + 'api/v1/school-user/export-payroll/',
    closeSubsAPI: environment.apiUrl + 'api/v1/school-user/subscription-action/',
    addTeacherToList: environment.apiUrl + 'api/v1/jobs/add-teacher-list/',
    publicProfile: environment.apiUrl + 'api/v1/school/',
    jobTermsConditionAPI: environment.apiUrl + 'api/v1/accept/term_condition/',
    // api/v1/?teacher_id=3    
    nextPrevFortAPI: environment.apiUrl + 'api/v1/school-user/teacher-fortnight/',
    checkBookingStatus: environment.apiUrl + 'api/v1/school-user/booking-status-check/',
  };

  commonApi = {
    switchBackToPython: environment.apiUrl + 'api/v1/school-user/back-to-cc/',
  }
}