import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { last, map, take } from "rxjs/operators";
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { SendMailComponent } from '../modals/send-mail/send-mail.component';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CardProfileViewComponent } from '../card-profile-view/card-profile-view.component'
// models
import { SchoolData } from 'src/app/models/schoolUser';
import { TeacherProfilePopupComponent } from '../teacher-profile-popup/teacher-profile-popup.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-pending-invites',
  templateUrl: './pending-invites.component.html',
  styleUrls: ['./pending-invites.component.scss']
})
export class PendingInvitesComponent implements OnInit {
  public schoolProps!: SchoolData;
  @ViewChild('cancelPendingInviteModal') cancelPendingInviteModal: any;
  @ViewChild('resendPendingInviteModal') resendPendingInviteModal: any;
  @ViewChild('sendEmailModal') sendEmailModal: any;
  // @ViewChild('actionModals') actionModals: any;

  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  obsHistoryArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  PendingInvitesteachersList: Observable<any> = this.obsArray.asObservable();
  PendingHistoryteachersList: Observable<any> = this.obsHistoryArray.asObservable();
  totalArrayCount: any;
  totalArrayHistoryCount: any
  teacherLengthLimit: any;
  teacherHistoryLengthLimit: any;

  filtersArray: any = []
  subjectsAndTags = [];
  levelFilter = [];
  schoolTimings = [];
  consumerType = [];
  teacher: Array<any> = [];
  // actionType: any;
  // public actionForm: any
  data: any;
  currentPage: number = 1
  currentHistoryPage: number = 1
  pendingData: any;
  historyData: any;
  resendCancelForm: FormGroup = this.fb.group({
    consumer_id: [''],
    invite_id: ['']
  });

  invitesPendingFilterForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_levels: [''],
    filter_subjects: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    page: ['']
  });
  invitesHistoryFilterForm: FormGroup = this.fb.group({
    filter_consumer_type: [''],
    filter_levels: [''],
    filter_subjects: [''],
    filter_name: [''],
    filter_is_validated: [''],
    filterType: [''],
    page: ['']
  });
  categoryList: any;
  isMasterSel: boolean = false;
  checkedCategoryList: any;
  checkBoxSelectedTeacher: any = [];
  hideButton: boolean = true;
  multipleType: boolean = false;
  teacherName: any;
  teacherInviteRemaining: any;
  consumerDetails: Array<any> = [];
  isChecked: any;
  selectAllCheck: boolean = false;
  listHealth: any;
  type: any;
  public submitted: boolean = false;
  public sendEmailForm: any
  availTeacher: any;
  isClicked: boolean = false;

  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,) { }

  ngOnInit(): void {

    this.ngxService.start();
    this.getFiltersData();
    this.getListHealth();
    this.formInit();
    // Booking request
    this.bookingService.getInvitePendingList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.pendingData = res.data.pending
        this.teacherLengthLimit = res.data.pending_total_count;
        this.obsArray.next(res.data.pending);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    })
    this.ngListener()
    //End of booking request
  }

  getInivteList() {
    if (this.activeTab == 'invites') {
      this.bookingService.getInvitePendingList(this.getInputFormControlValues()).subscribe({
        next: (res: any) => {
          this.pendingData = res.data.pending
          this.teacherLengthLimit = res.data.pending_total_count;
          this.obsArray.next(res.data.pending);
          this.ngHistoryOnDestroy()
          this.ngListener()
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      })
    } else if (this.activeTab == 'history') {
      this.bookingService.getInviteHistoryList(this.getInputFormControlValues()).subscribe({
        next: (res: any) => {
          this.historyData = res.data.history
          this.teacherHistoryLengthLimit = res.data.history_total_count;
          this.obsHistoryArray.next(res.data.history);
          this.ngHistoryListener()
          this.ngOnDestroy()
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      })
    }
  }
  formInit() {
    this.sendEmailForm = this.fb.group({
      email_content: ['', [Validators.required, Validators.maxLength(280)]],
      provider_ids: ['']
    });

    // this.actionForm = this.fb.group({
    //   email_content: ['', [Validators.required, Validators.maxLength(280)]],
    //   action: [''],
    //   request_id: [''],
    //   provider_ids: ['']
    // });
  }
  // Get list health data
  getListHealth() {
    // this.bookingService.listHealth(this.currentPage).subscribe({
    //   next: (res: any) => {
    //     console.log("hellooooo", res.data);
    //     this.listHealth = res.data
    //   },
    //   error: (err: any) => {
    //     console.log(err);
    //   }
    // })
  }
  // Get filters data
  getFilterInputs() {
    this.currentPage = 1;
    this.currentHistoryPage = 1
    this.ngxService.start();
    if (this.activeTab == 'invites') {
      this.bookingService.getInvitePendingList(this.getInputFormControlValues()).subscribe({
        next: (res: any) => {
          this.pendingData = res.data.pending
          this.teacherLengthLimit = res.data.pending_total_count;
          this.obsArray.next(res.data.pending);
          this.selectAllCheck = false
          this.consumerDetails = [];
          this.hideButton = true
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      });
    } else if (this.activeTab == 'history') {
      this.bookingService.getInviteHistoryList(this.getInputFormControlValues()).subscribe({
        next: (res: any) => {
          this.historyData = res.data.history
          this.teacherHistoryLengthLimit = res.data.history_total_count;
          this.obsHistoryArray.next(res.data.history);
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      });
    }
  }
  // End of get filters data


  getInputFormControlValues() {
    if (this.activeTab == 'invites') { //Filter for invites
      this.invitesPendingFilterForm.patchValue({
        filterType: 'invites',
        page: this.currentPage
      });
      return this.invitesPendingFilterForm.value;
    } else if (this.activeTab == 'history') {//Filter for history
      this.invitesHistoryFilterForm.patchValue({
        filterType: 'history',
        page: this.currentHistoryPage
      });
      return this.invitesHistoryFilterForm.value;
    }
  }


  // Get Filter Data
  getFiltersData() {
    // this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.commonService.schoolChoices('pending_invite').subscribe({
      next: (res: any) => {
        this.filtersArray = res.data;
        this.subjectsAndTags = res.data.tags;
        this.levelFilter = res.data.experience_tree;
        this.consumerType = res.data.consumer_type;
        // this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }


  selectAll(type: any, event: any) {

    var checked = event.target.checked;
    if (type == 'All') {
      this.consumerDetails = [];
      this.pendingData.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          this.consumerDetails.push(x);
          this.hideButton = false
        } else {
          this.hideButton = true
          x.isChecked = false;
          this.consumerDetails = [];
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.consumerDetails.push(type)
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.consumerDetails = this.consumerDetails.filter(x => x != type);
      }
    }
    this.selectAllCheck = this.pendingData.length == this.consumerDetails.length ? true : false;
    if (this.consumerDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }

  getFormControlValues() {
    return this.resendCancelForm.value;
  }

  resendInvite(teacher: any) {
    this.ngxService.start();
    this.teacher = teacher
    this.resendCancelForm.patchValue({
      consumer_id: (teacher.teacher.id).toString()
    });
    // this.resendCancelForm.addControl('consumer_id', new FormControl((teacher.teacher.id).toString()));
    this.bookingService.reSendInvite(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.toastr.success(res.message);
          var index = this.pendingData.map((x: any) => {
            return x.teacher.id;
          }).indexOf(res.data.pending[0].teacher.id);
          this.pendingData[index] = res.data.pending[0];
          this.bsModalRef.hide();
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }

      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  teacherProfile(teacher: any) {
    const initialState = {
      teacher: teacher
    };
    this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  cancelInviteModal(teacher: any) {
    this.multipleType = false
    if (teacher == 'multiple') {
      this.multipleType = true
      this.bsModalRef = this.modalService.show(this.cancelPendingInviteModal, { class: 'modal-md', backdrop: 'static' });
    } else {
      this.teacher = teacher
      this.bsModalRef = this.modalService.show(this.cancelPendingInviteModal, { class: 'modal-md', backdrop: 'static' });
    }
  }
  resendInviteModal(teacher: any) {
    this.multipleType = false
    if (teacher == 'multiple') {
      this.multipleType = true
      this.bsModalRef = this.modalService.show(this.resendPendingInviteModal, { class: 'modal-md', backdrop: 'static' });
    } else {
      this.teacher = teacher
      this.teacherName = teacher.teacher.user.full_name
      this.teacherInviteRemaining = teacher.resend_counter
      this.bsModalRef = this.modalService.show(this.resendPendingInviteModal, { class: 'modal-md', backdrop: 'static' });
    }
  }
  sendMailModal(teacher: any) {
    this.bsModalRef = this.modalService.show(this.sendEmailModal, { class: 'modal-md', backdrop: 'static' });
  }

  cancelInvite(teacher: any) {
    this.ngxService.start();
    this.resendCancelForm.patchValue({
      invite_id: (teacher.id).toString()
    });
    // this.resendCancelForm.addControl('invite_id', new FormControl((teacher.id).toString()));
    this.bookingService.cancelInvite(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          var index = this.pendingData.map((x: any) => {
            return x.teacher.id;
          }).indexOf(teacher.teacher.id);
          this.pendingData.splice(index, 1);
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.statusText);
        console.log(err);
        this.ngxService.stop();
      }
    })
  }


  cancelMultipleInvite() {
    this.ngxService.start();
    this.resendCancelForm.patchValue({
      invite_id: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.id; }).join(","))
    });
    this.currentPage = 1;
    this.currentHistoryPage = 1
    this.bookingService.cancelInvite(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          this.getInivteList();
          this.consumerDetails = [];
          this.hideButton = true
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }

      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.statusText + ', Please contact admin');
        this.ngxService.stop();
      }
    })
  }
  resendMultipleInvite() {
    this.ngxService.start();
    // this.resendCancelForm.addControl('consumer_id', new FormControl(Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(",")));
    this.resendCancelForm.patchValue({
      consumer_id: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(","))
    });
    this.currentPage = 1;
    this.currentHistoryPage = 1
    this.bookingService.reSendInvite(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          this.getInivteList();
          this.selectAllCheck = false
          this.consumerDetails = [];
          this.hideButton = true
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }

      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  getTeacherAvailabilityModal(teacher_id: any) {
    // this.ngxService.start();
    this.availTeacher = teacher_id
    this.bookingService.getAvailability(this.availTeacher).subscribe({
      next: (res: any) => {
        if (res.status) {
          const initialState = {
            teacher: res.data
          };
          this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.closeBtnName = 'Close';
          // this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
        }

      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
      }
    });
  }
  activeTab = 'invites';

  invites(activeTab: string) {
    this.ngxService.start();
    this.selectAllCheck = false
    this.consumerDetails = [];
    this.hideButton = true
    this.activeTab = activeTab;
    this.currentPage = 1
    this.formInit();
    this.ngHistoryOnDestroy();
    this.ngListener();
    this.getFiltersData();
    this.bookingService.getInvitePendingList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        console.log("hi", res.data);
        this.pendingData = res.data.pending
        this.teacherLengthLimit = res.data.pending_total_count;
        this.obsArray.next(res.data.pending);
        console.log(this.pendingData);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })

  }

  history(activeTab: string) {
    this.ngxService.start();
    this.selectAllCheck = false
    this.consumerDetails = [];
    this.hideButton = true
    this.activeTab = activeTab;
    this.ngOnDestroy();
    this.ngHistoryListener();
    this.getFiltersData();
    this.currentHistoryPage = 1
    this.formInit();
    this.bookingService.getInviteHistoryList(this.getInputFormControlValues()).subscribe({
      next: (res: any) => {
        this.historyData = res.data.history
        this.teacherHistoryLengthLimit = res.data.history_total_count;
        this.obsHistoryArray.next(res.data.history);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.sendEmailForm.controls;
  }
  getControlMailValues() {
    return this.sendEmailForm.value;
  }
  sendEmail() {
    this.submitted = true;
    if (this.sendEmailForm.invalid) {
      return;
    }
    this.ngxService.start();
    // this.sendEmailForm.addControl('provider_ids', new FormControl(Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(",")));
    this.sendEmailForm.patchValue({
      provider_ids: (Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(","))
    });
    this.bookingService.sendMailInvite(this.getControlMailValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          this.formInit()
          this.getInivteList();
          this.selectAllCheck = false
          this.consumerDetails = [];
          this.hideButton = true
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
    window.removeEventListener('scroll', this.scrollHistoryPage, true);
  }
  ngListener() {
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }
  ngHistoryOnDestroy() {
    window.removeEventListener('scroll', this.scrollHistoryPage, true);
  }
  ngHistoryListener() {
    window.addEventListener('scroll', this.scrollHistoryPage, true); //third parameter
  }

  getTeacherLength() {
    this.PendingInvitesteachersList.subscribe(result => {
      this.totalArrayCount = result.length;
    });
  }
  getHistoryTeacherLength() {
    this.PendingHistoryteachersList.subscribe(result => {
      this.totalArrayHistoryCount = result.length;
    });
  }

  scrollHistoryPage = (event: any): void => {
    this.getHistoryTeacherLength();
    let totalPages = Math.ceil(this.teacherHistoryLengthLimit / this.totalArrayHistoryCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.HistoryPaginator(this.totalArrayHistoryCount, this.currentHistoryPage, 30);
      this.currentHistoryPage = activePage + 1;
      if (this.invitesHistoryFilterForm.controls['page'].value != this.currentHistoryPage) {
        this.ngxService.start();
        forkJoin([this.PendingHistoryteachersList.pipe(take(1)), this.bookingService.getInviteHistoryList(this.getInputFormControlValues())]).subscribe((data: any) => {
          this.teacherHistoryLengthLimit = data[1].data.history_total_count;
          const newArr = [...data[0], ...data[1].data.history];
          this.obsHistoryArray.next(newArr);
          this.historyData = newArr
          this.ngxService.stop();
        });
      }
    }
  };
  // Scroll to getData
  scroll = (event: any): void => {
    this.getTeacherLength();
    let totalPages = Math.ceil(this.teacherLengthLimit / this.totalArrayCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.Paginator(this.totalArrayCount, this.currentPage, 30);
      this.currentPage = activePage + 1;
      if (this.invitesPendingFilterForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.PendingInvitesteachersList.pipe(take(1)), this.bookingService.getInvitePendingList(this.getInputFormControlValues())]).subscribe((data: any) => {
          this.teacherLengthLimit = data[1].data.pending_total_count;
          const newArr = [...data[0], ...data[1].data.pending];
          this.obsArray.next(newArr);
          this.pendingData = newArr
          this.ngxService.stop();
        });
      }
    }
  };
  Paginator(totlallength: any, page: any, per_page: any) {
    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }
  HistoryPaginator(totlallength: any, page: any, per_page: any) {
    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }

  // send email modal
  sendMessage(teacher: any) {
    const initialState = {
      teacher: teacher,
      from: 'pending_invites'
    };
    this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
  }

}
