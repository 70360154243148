import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { url } from 'inspector';
import { PageChangedEvent } from "ngx-bootstrap/pagination/public_api";
import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-job-user',
  templateUrl: './job-user.component.html',
  styleUrls: ['./job-user.component.scss']
})

export class JobUserComponent implements OnInit {
  currentPage: number = 1;
  public schoolProps!: SchoolData;
  data: any;
  rotate = true;
  maxSize = 5;
  showBoundaryLinks = true;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  page: number = 1;
  apiService: any;
  totalCount: any;
  totalItems: any;
  filterCheck: any;
  filtersArray: any;
  activeJob: any;
  expiredJob: any;
  closeJob: any;
  draft_jobs: any;
  close_job: any;
  statics: any;
  profilePercent: any;
  other_job: any;

  payment_status: any;
  job_id: any;
  id: any;
  makeLiveForm: FormGroup = this.fb.group({
    job_id: ['']
  });
  jobUpdateForm: FormGroup = this.fb.group({
    plan_id: ['']
  });


  sub_id: any;
  plan_name: any;
  plan_id: any;
  job_ad_credit: any;

  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');

    // this.formInit();
    // this.getFiltersData();
    this.bookingService.getJobs(this.currentPage).subscribe({
      next: (res: any) => {
        this.activeJob = res.data.active_job
        // this.expiredJob = res.data.expired_job
        this.close_job = res.data.expired_close_job
        this.draft_jobs = res.data.draft_job
        this.statics = res.data.stat
        this.closeJob = '0'
        this.job_ad_credit = res.data.job_ad_credit
        this.profilePercent = res.data.profile_percentage
        this.other_job = res.data.other_job
        this.totalCount = parseInt(res.data.page_count) * 10;
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
      }
    })

    this.route.queryParams.subscribe(params => {
      this.payment_status = params['state'];
    });
    this.route.queryParams.subscribe(params => {
      this.sub_id = params['sub_id'];
    });

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });

    this.route.queryParams.subscribe(params => {
      this.plan_id = params['plan_id'];
    });


    this.job_id = localStorage.getItem('job_id')
    if (this.job_id == null) {
      this.makeLiveForm.patchValue({
        job_id:'',
      });
    } else {
      this.makeLiveForm.patchValue({
        job_id:this.job_id,
      });
    }

    this.jobUpdateForm.patchValue({
      plan_id: this.plan_id,
    });
    if ((this.payment_status == 'succeeded' || this.payment_status == 'requested') && this.plan_id != undefined) {
      this.toastr.success("Payment Successful");
      this._router.navigateByUrl("/school/jobs")
      localStorage.removeItem('job_id');
    }else if(this.payment_status == 'succeeded' || this.payment_status == 'requested'){
      this.toastr.success("Payment Successful");
      if (this.job_id != null) {
        localStorage.removeItem('job_id');
        this._router.navigateByUrl("/school/jobs")
      }else{
        this._router.navigateByUrl("/school/jobs")
      }
    }
  }
  getjobUpdateFormValue() {
    return this.jobUpdateForm.value
  }
  getMakeLiveFormValue() {
    return this.makeLiveForm.value
  }

  pageChanged(event: PageChangedEvent): void {
    if (this.filterCheck != 1) {
      this.currentPage = event.page
      this.ngxService.start();
      this.bookingService.getJobs(this.currentPage).subscribe({
        next: (res: any) => {
          this.activeJob = res.data.active_job
          this.totalCount = parseInt(res.data.page_count) * 10;
          window.scroll(0, 0)
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
        }
      })
    }
  }

  activeTab = 'activeJobs';

  activeJobs(activeTab: string) {
    this.activeTab = activeTab;
  }

  expiredJobs(activeTab: string) {
    this.activeTab = activeTab;
  }

  ClosedJobs(activeTab: string) {
    this.activeTab = activeTab;
  }

  DraftJobs(activeTab: string) {
    this.activeTab = activeTab;
  }

  sendJobId(id: any) {
    this._router.navigate(['/school/job-details'],{ queryParams: { job_id: id }});
  }
  editJob(job_id: any) {
    this._router.navigate(['/school/post-job'], { queryParams: { job_id: job_id} });
  }
}

