import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { BookingService } from 'src/app/services/api/booking.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/api/common.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-inline-calendar',
  templateUrl: './inline-calendar.component.html',
  styleUrls: ['./inline-calendar.component.scss']
})
export class InlineCalendarComponent implements OnInit {

  
  title?: string;
  closeBtnName?: string;
  teacher: any;
  inlineEndDate: any;

  @Input() availability: any;  
  @Input() teacherID: any;  
  @Input() from: any
  @Output() onDatePicked = new EventEmitter<any>();
  nextPrevForm: FormGroup = this.fb.group({
    providerId: [''],
    date: [''],
  });
  dateList: any;
  constructor(public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    public router: Router) {
      this.inlineEndDate = new Date();
     }

  ngOnInit(): void {
  }

  // getFormControlValues(){
  //   return this.nextPrevForm.value;
  // }
  // addDays(date: any,id:any) {
  //   var momentObj = moment(date, 'DD/MM/YYYY');
  //   alert(momentObj.add(14, 'd').format('DD/MM/YYYY'))
  //   this.nextPrevForm.patchValue({
  //     date: momentObj.add(14, 'd').format('DD/MM/YYYY'),
  //     providerId:id
  //   });
  //   // return momentObj.add(14, 'd').format('DD/MM/YYYY')
  // }

  // substractDays(date: any,id:any) {
  //   var momentObj = moment(date, 'DD/MM/YYYY');
  //   alert(momentObj.subtract(14, 'd').format('DD/MM/YYYY'))
  //   this.nextPrevForm.patchValue({
  //     date: momentObj.subtract(14, 'd').format('DD/MM/YYYY'),
  //     providerId:id
  //   });
  // }

  prevClick(id:any) {
    this.setPrevNxtData(this.inlineEndDate, true, 2,id);
  }

  nextClick(id:any) {
    this.setPrevNxtData(this.inlineEndDate, true, 1,id);
  }

  setPrevNxtData(res: any, apiCall: boolean, sendDate: number,id:any) {
    console.log(this.inlineEndDate)
    // Set Next Prev Api Call
    if (apiCall) {
      this.ngxService.start();
      //  1 for nextfortnight
      //  2 for previousfortnight
      if (sendDate == 1) {
        this.inlineEndDate.setDate(this.inlineEndDate.getDate() + 14);
        this.nextPrevForm.patchValue({
          date:  moment(this.inlineEndDate).format('DD/MM/YYYY'),
          providerId:[id]
        });
      } else if (sendDate == 2) {
        this.inlineEndDate.setDate(this.inlineEndDate.getDate() - 14);
        this.nextPrevForm.patchValue({
          date: moment(this.inlineEndDate).format('DD/MM/YYYY'),
          providerId:[id]
        });
      }
      this.bookingService.nextPrevFortAPI(this.nextPrevForm.value).subscribe({
        next: (res: any) => {
          this.availability = res.data[0].availability
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      })
    }
  }

}
