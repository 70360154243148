<div class="container pb-50" id="profile-school-settings">
    <div class="row">
        <div class="col-md-12 col-lg-12 mt-20">
            <p class="user-title">Admin settings</p>
            <div class="row">
                <div class="col-md-9 col-lg-9 col-12">
                    <div class="card px-15  mt-20">
                        <div class="d-flex justify-content-space-bet align-items-center">
                            <div>
                                <h1 class="user-title">Manage Team</h1>
                                <p class="txt-regular">Manage school users and their permissions on ClassCover
                                </p>
                            </div>
                            <div class="flex-end">
                                <p class="text-regular-bold c-p h_b" (click)="editSettings('additional-users')"><i class="bi bi-pencil-fill fs-12 mr-2"></i>
                                    Edit</p>
                            </div>
                        </div>
                    </div>
                    <div class="card px-15  mt-20">
                        <div class="d-flex justify-content-space-bet align-items-center">
                            <div>
                                <h1 class="user-title">Daily Booking Summary</h1>
                                <p class="txt-regular">Decide when you would like your school’s booking summary to be sent out each day
                                </p>
                            </div>
                            <div class="flex-end">
                                <p class="text-regular-bold c-p h_b" (click)="editSettings('daily-summary')"><i class="bi bi-pencil-fill fs-12 mr-2"></i>
                                    Edit</p>
                            </div>
                        </div>
                    </div>

                    <div class="card px-15  mt-20">
                        <div class="d-flex justify-content-space-bet align-items-center">
                            <div>
                                <h1 class="user-title">Reason for absence list</h1>
                                <p class="txt-regular">List of reasons teaching staff at your school might be absent
                                </p>
                            </div>
                            <div class="flex-end">
                                <p class="text-regular-bold c-p h_b" (click)="editSettings('staff-reason-for-absent')"><i class="bi bi-pencil-fill fs-12 mr-2"></i>
                                    Edit</p>
                            </div>
                        </div>
                    </div>
                    <div class="card px-15  mt-20">
                        <div class="d-flex justify-content-space-bet align-items-center">
                            <div>
                                <h1 class="user-title">Default school times & Break times</h1>
                                <p class="txt-regular">Your school timings for when you need to book teachers
                                </p>
                            </div>
                            <div class="flex-end">
                                <p class="text-regular-bold c-p h_b" (click)="editSettings('default-school-times')"><i class="bi bi-pencil-fill fs-12 mr-2"></i>
                                    Edit</p>
                            </div>
                        </div>
                    </div>
                    <div class="card px-15  mt-20">
                        <div class="d-flex justify-content-space-bet align-items-center">
                            <div>
                                <h1 class="user-title">Booking Confirmation Form Settings</h1>
                                <p class="txt-regular">Mandatory fields to assist with payroll reporting
                                </p>
                            </div>
                            <div class="flex-end">
                                <p class="text-regular-bold c-p h_b" (click)="editSettings('booking-confirmation-form')"><i class="bi bi-pencil-fill fs-12 mr-2"></i>
                                    Edit</p>
                            </div>
                        </div>
                    </div>
                    <div class="card px-15  mt-20">
                        <div class="d-flex justify-content-space-bet align-items-center">
                            <div>
                                <h1 class="user-title">Payroll report settings</h1>
                                <p class="txt-regular">Customise the headings for fields related to payroll in the booking form here
                                </p>
                            </div>
                            <div class="flex-end">
                                <p class="text-regular-bold c-p h_b" (click)="editSettings('payroll-report-settings')"><i class="bi bi-pencil-fill fs-12 mr-2"></i>
                                    Edit</p>
                            </div>
                        </div>
                    </div>
                    <div class="card px-15  mt-20">
                        <div class="d-flex justify-content-space-bet align-items-center">
                            <div>
                                <h1 class="user-title">In-app cancellations</h1>
                                <p class="txt-regular">Manage how you’d like ClassCover to handle booking cancellations
                                </p>
                            </div>
                            <div class="flex-end">
                                <p class="text-regular-bold c-p h_b" (click)="editSettings('inAppCancel')"><i class="bi bi-pencil-fill fs-12 mr-2"></i>
                                    Edit</p>
                            </div>
                        </div>
                    </div>
                    <div class="card px-15  mt-20">
                        <div class="d-flex justify-content-space-bet align-items-center">
                            <div>
                                <h1 class="user-title">Customise Checkbox</h1>
                                <p class="txt-regular">Customise what you’d like your custom checkbox to say
                                </p>
                            </div>
                            <div class="flex-end">
                                <p class="text-regular-bold c-p h_b" (click)="editSettings('customCheckbox')"><i class="bi bi-pencil-fill fs-12 mr-2"></i>
                                    Edit</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card px-15  mt-20">
                        <div class="d-flex justify-content-space-bet align-items-center">
                            <div>
                                <h1 class="user-title">SMS settings</h1>
                                <p class="txt-regular">SMS notifications for when a relief teacher is being offered a booking
                                </p>
                            </div>
                            <div class="flex-end">
                                <p class="text-regular-bold c-p h_b" (click)="editSettings('sms-settings')"><i class="bi bi-pencil-fill fs-12 mr-2"></i>
                                    Edit</p>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-md-3 col-lg-3 col-12 mt-20">
                    <div class="card px-15">
                        <p class="txt-regular c-p h_b" routerLink="/school/my-settings" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"><i class="bi bi-person-lines-fill mr-5"></i> Profile settings</p>
                        <p class="txt-regular c-p mt-10 h_b"  routerLink="/school/admin-settings" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"><i class="bi bi-gear-fill mr-5"></i> Admin settings</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
