<div class="row rowMargin mt-20 mb-50" *ngIf="step != '_completed'">
    <div class="col-lg-12 text-center">
        <p class="fs-24 text-regular-bold">Hi {{ schoolProps.mixpanel_data.name }}, welcome to ClassCover. Get started
            in a few steps.</p>

        <p *ngIf="this.stepsCheck?.build_list == 'false' && this.stepsCheck?.test_booking == 'false' && this.stepsCheck?.profile_complete == 'false'"
            class="text-regular-medium fs-16 mt-15"><span class="clr-red">0 of 3</span> steps
            complete</p>
        <p *ngIf="this.stepsCheck?.build_list == 'true' && this.stepsCheck?.test_booking == 'false' && this.stepsCheck?.profile_complete == 'false'"
            class="text-regular-medium fs-16 mt-15"><span class="clr-red">1 of 3</span> steps
            complete</p>
        <p *ngIf="this.stepsCheck?.build_list == 'true' && this.stepsCheck?.test_booking == 'true' && this.stepsCheck?.profile_complete == 'false'"
            class="text-regular-medium fs-16 mt-15"><span class="clr-red">2 of 3</span> steps
            complete</p>
        <p *ngIf="this.stepsCheck?.build_list == 'true' && this.stepsCheck?.test_booking == 'true' && this.stepsCheck?.profile_complete == 'true'"
            class="text-regular-medium fs-16 mt-15"><span class="clr-red">3 of 3</span> steps
            complete</p>
    </div>


    <div class="row rowMargin_one align-items-center mt-30 c-p">
        <div routerLink="/school/school-onboarding-findNew" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="row justify-content-md-center justify-content-sm-center  align-items-center  pt-10 pb-10  c-p">
            <div class="col-2 col-md-1 col-sm-2 col-lg-1">
                <div>
                    <img class="onboarding_img" src="assets/images/onboarding_num.png">
                    <p class="clr-cc-vilot  on_img_text">1</p>
                </div>
            </div>
            <div class="col-8 col-md-10 col-sm-8 col-lg-10 ml-20">
                <p class="fs-21 text-regular-bold mb-15">Build your list</p>
                <p class="txt-regular fs-16">Build your list with new and known relief pool contacts.</p>
            </div>
            <div class="col-2 col-md-1 col-lg-1 cus-width">
                <i *ngIf="this.stepsCheck?.build_list == 'false'" class="bi bi-chevron-right fs-20 clr-cc-vilot"></i>
                <p *ngIf="this.stepsCheck?.build_list == 'true'" class="clr-cc-vilot"><i
                        class="bi bi-check-lg fs-24 v-align-m"></i></p>
            </div>
        </div>
        <div routerLink="/school/onboarding-classcover-overview"
            [ngClass]="this.stepsCheck?.build_list == 'false' ? 'cardDisabled' : '' " routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="row justify-content-md-center justify-content-sm-center  align-items-center pt-10 pb-10 c-p">
            <div class="col-2 col-md-1 col-sm-2 col-lg-1">
                <div>
                    <img class="onboarding_img" src="assets/images/onboarding_num.png">
                    <p class="clr-cc-vilot  on_img_text">2</p>

                </div>
            </div>
            <div class="col-8 col-md-10 col-sm-8 col-lg-10 ml-20">
                <p class="fs-21 text-regular-bold mb-15">Place a test booking</p>
                <p class="txt-regular fs-16">See how easy it is to make a booking.</p>
            </div>
            <div class="col-2 col-md-1 col-lg-1 cus-width">
                <i *ngIf="this.stepsCheck?.test_booking == 'false'" class="bi bi-chevron-right fs-20 clr-cc-vilot"></i>
                <p *ngIf="this.stepsCheck?.test_booking == 'true'" class=""><i
                        class="bi bi-check-lg fs-24  v-align-m"></i></p>
            </div>
        </div>
        <div routerLink="/school/school-profile"
            [ngClass]="this.stepsCheck?.test_booking == 'false' ? 'cardDisabled' : '' " routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="row justify-content-md-center justify-content-sm-center  align-items-center pt-10 pb-10 c-p">
            <div class="col-2 col-md-1 col-sm-2 col-lg-1">
                <div>
                    <img class="onboarding_img" src="assets/images/onboarding_num.png">
                    <p class="clr-cc-vilot  on_img_text">3</p>
                </div>
            </div>
            <div class="col-8 col-md-10 col-sm-8 col-lg-10 ml-20">
                <p class="fs-21 text-regular-bold mb-15">Complete your profile</p>
                <p class="txt-regular fs-16">Complete your school’s profile.</p>
            </div>
            <div class="col-2 col-md-1 col-lg-1 cus-width">
                <i *ngIf="this.stepsCheck?.profile_complete == 'false'"
                    class="bi bi-chevron-right fs-20 clr-cc-vilot"></i>
                <p *ngIf="this.stepsCheck?.profile_complete == 'true'" class="clr-cc-vilot"><i
                        class="bi bi-check-lg fs-24 v-align-m"></i></p>
            </div>
        </div>
        <p class="text-right text-regular-medium fs-16 clr-cc-vilot"  routerLink="/school/booking-request"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Skip onboarding</p>
    </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <hr>
                <div class="card boost_onboarding px-0 mt-20">
                    <div class="d-flex align-items-center">
                        <div>
                            <img class="onScreen_img" src="assets/images/img_on.png">
                        </div>
                        <div class="pl-15 pr-15 w-100">
                            <p class="user-title fs-21">Want to boost your list?</p>
                            <p class="txt-regular mt-5">Schools with a completed profile are more likely to grow their
                                list.
                            </p>
                            <div class="d-flex justify-content-space-bet align-items-center mt-12">
                                <div class="progress mr-10" role="progressbar" aria-label="Basic example"
                                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                    <div class="progress-bar" style="{{profile_percent}}"></div>
                                </div>
                                <p class="txt-regular">{{schoolProps.properties.profile_percentage}} of 100% completed
                                </p>
                            </div>
                            <div class="mt-12">
                                <a class="user-title clr-cc-vilot c-p" [ngClass]="this.stepsCheck?.test_booking == 'true' ? '' : 'disabled'" for="" routerLink="/school/school-profile"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                                    Complete your school profile <i class="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>

<!-- <div class="row  pb-30 pt-20 mt-50 text-center" *ngIf="step == '_completed'">
    <div class="col-md-8 offset-md-2 col-lg-8 offset-lg-2">
    <div class="card px-50">
        <p><img class="on_img" src="assets/images/CC_Onboarding.png"></p>
        <p class="fs-24 text-regular-bold mt-20">Great work on completing the steps! Continue to explore or start
            booking straight away.</p>
        <p><button class="btn cc_p_btn mt-20" routerLink="/school/booking-request" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Get started</button></p>
            </div>
    </div>
</div> -->