import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, TemplateRef, ViewChildren, QueryList, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { HttpClient, HttpResponse } from '@angular/common/http';

// Libs
import { CalendarOptions, FullCalendarComponent, } from '@fullcalendar/angular';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import * as moment from 'moment';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
// import { CKEditorModule } from 'ckeditor4-angular';
import { ToastrService } from 'ngx-toastr';
import { AddNewModalComponent } from '../modals/add-new-modal/add-new-modal.component';
import { environment } from 'src/environments/environment';


// Services
import { SchoolCalendarService } from 'src/app/services/api/school-calendar.service';
import { MomentService } from 'src/app/services/api/moment.service';
import { CommonService } from 'src/app/services/api/common.service';
import { SendMailComponent } from '../modals/send-mail/send-mail.component';

// Components
import { BookingConfrimModalComponent } from '../modals/booking-confrim-modal/booking-confrim-modal.component';
import { BookingService } from 'src/app/services/api/booking.service';
import { MixpanelService } from 'src/app/mixpanel.service';
import { SchoolData } from 'src/app/models/schoolUser';

@Component({
  selector: 'app-school-calendar',
  templateUrl: './school-calendar.component.html',
  styleUrls: ['./school-calendar.component.scss']
})

export class SchoolCalendarComponent implements OnInit {
  bookingDetails: any;
  bsValue = new Date();
  @ViewChild('addExternalEvent') addExternalEvent: any;
  @ViewChild('bulkEditConfirmModal') bulkEditConfirmModal: any;
  @ViewChild('cancelReasonModal') cancelReasonModal: any;
  @ViewChild('exportModal') exportModal: any;

  minDate: Date;
  bulkData: any;
  bulkDetails: Array<any> = [];
  selectAllAppCheck: boolean = false;
  filtersArray: any = [];
  defaultViewMonth: any = 'month'
  bsInlineRangeValue: Date[] | undefined;
  is_openCustomPicker: boolean = false;
  is_openCustomPickerRange: boolean = false;
  startTime: Date = new Date();
  endTime: Date = new Date();
  eventRangeValue: any;
  selectedEvent: any;
  public schoolProps!: SchoolData;

  @ViewChild('customPickerDiv', { static: false }) customPickerDiv?: ElementRef;
  @ViewChild('eventDateRange', { static: false }) eventDateRange?: ElementRef;
  @ViewChild("customDatepickerExport", { static: false }) customDatepickerExport?: ElementRef
  @ViewChild("eventDateRangeExport", { static: false }) eventDateRangeExport?: ElementRef
  selectedEventID: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private calendarService: SchoolCalendarService,
    private moment: MomentService,
    private commonservice: CommonService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private bookingService: BookingService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private _router: Router,
    private mixpanelService: MixpanelService,
    private renderer: Renderer2,
    private httpClient: HttpClient
  ) {
    this.minDate = new Date();
    // this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.minDate.setFullYear(this.minDate.getFullYear());
    this.eventRangeValue = [this.startTime, this.endTime];

    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.customPickerDiv?.nativeElement && e.target !== this.customDatepickerExport?.nativeElement) {
        this.is_openCustomPicker = false;
      }
      // if (e.target !== this.eventDateRange?.nativeElement && e.target !== this.eventDateRangeExport?.nativeElement) {
      //   this.is_openCustomPickerRange = false;
      // }
    });
  }
  editBooking: boolean = false;
  public eventList: any;
  public selectedDate: any
  bookingList: Array<any> = [];
  public calendarType = 'month';
  public startDate: any;
  public endDate: any;
  public submitted: boolean = false;
  public Desubmitted: boolean = false;
  public totalHoursBooking!: number;
  public totalBookings!: number;
  calendarMonthSelection: any;
  public myList: any;
  @ViewChild('bulkDataModal') bulkDataModal: any;

  addExternalEventForm: FormGroup = this.fb.group({
    title: ['', [Validators.required, Validators.minLength(6)]],
    start_end_date: [new Date(), [Validators.required, Validators.minLength(0)]],
    comment: ['', [Validators.required, Validators.minLength(0)]],
    school_user_id: ['']
  });
  selectAllCheck: boolean = false;
  consumerDetails: Array<any> = [];
  hideButton: boolean = true;
  activeTab: boolean = true;
  content = "";
  calendarBookingFilterForm: FormGroup = this.fb.group({
    filter_name: [''],
    date: [''],
  });
  getBulkDataForm: FormGroup = this.fb.group({
    filter_name: [''],
    from_date: [''],
    to_date: [''],
  });
  editBookings: FormGroup = this.fb.group({
    booking_teachercovered: [''],
    booking_classcovered: [''],
    booking_leaveapproved: [''],
    booking_notes: [''],
    booking_payrollfield1: [''],
    booking_payrollfield2: [''],
    booking_payrollfield3: [''],
    booking_absencereason: [''],
    line_item_ids: [''],
    action: ['']
  });


  bsBulkFromValue: any = ''
  bsBulkToValue: any = ''
  config = {
    // extraPlugins: "autogrow",
    // resize_enabled: false,
    // autoGrow_maxHeight: 200,
    // autoGrow_maxWidth: 200,
    // removePlugins: "resize"
  };

  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  public calendarOptions?: CalendarOptions;
  public cancelBookingRequestForm: any
  public getExportForm: any

  ShowLenghtError: any = 280;
  ShowLenght: any;
  ngOnInit(): void {
    var date = new Date();
    this.bsInlineRangeValue = [this.bsValue, this.bsValue];
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');

    let startDate = this.moment.changeDateFormat('ymd', new Date(date.getFullYear(), date.getMonth(), 1));
    let endDate = this.moment.changeDateFormat('ymd', new Date(date.getFullYear(), date.getMonth() + 1, 1));
    this.ngxService.start();
    this.mixpanelService.calendarView();
    this.getFiltersData()
    this.formInit()
    this.checkLength()
    // this.getCalendarData(startDate, endDate);
    this.calendarService.getCalendarData(startDate, endDate).subscribe({
      next: (res: any) => {
        console.log('k', res.data)
        this.eventList = res.data.bookings_events_list;
        this.totalHoursBooking = res.data.booked_hours;
        this.totalBookings = res.data.total_bookings;
        this.setCalendatEvents(new Date());
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });

    const startTime = new Date();
    startTime.setHours(8);
    startTime.setMinutes(0);
    const endTime = new Date();
    endTime.setHours(16);
    endTime.setMinutes(0);
    this.startTime = startTime;
    this.endTime = endTime;
  }

  getFiltersData() {
    this.commonService.schoolChoices('booking').subscribe({
      next: (res: any) => {
        console.log(res.choices);
        this.filtersArray = res.choices;
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }
  formInit() {
    this.cancelBookingRequestForm = this.fb.group({
      custom_cancel_msg: ['', [Validators.required, this.noWhitespaceValidator]],
      action: [''],
      line_item_ids: ['']
    });
    this.getExportForm = this.fb.group({
      from_date: [''],
      to_date: ['']
    })
  }
  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }

  resetCalendarOnEvent() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
    // this.ngxService.start();
    // this.getFiltersData()
    // this.formInit()
    // this.checkLength()
    // var date = new Date();
    // let startDate = this.moment.changeDateFormat('ymd', new Date(date.getFullYear(), date.getMonth(), 1));
    // let endDate = this.moment.changeDateFormat('ymd', new Date(date.getFullYear(), date.getMonth() + 1, 0));
    // this.ngxService.start();
    // // this.getCalendarData(startDate, endDate);
    // this.calendarService.getCalendarData(startDate, endDate).subscribe({
    //   next: (res: any) => {
    //     console.log('k', res.data)
    //     this.eventList = res.data.bookings_events_list;
    //     this.totalHoursBooking = res.data.booked_hours;
    //     this.totalBookings = res.data.total_bookings;
    //     this.setCalendatEvents(new Date());
    //   },
    //   error: (err: any) => {
    //     console.log(err);
    //   }
    // });
  }

  setCalendatEvents(date: any) {
    // console.log(this.eventList)
    this.selectedDate = new Date(date).toJSON().slice(0, 10).replace(/-/g, '-');
    if (this.calendarType == 'week') {
      this.calendarOptions = {
        initialView: 'dayGridMonth',
        // eventLimitText: "More",
        selectable: true,
        firstDay: 1,
        weekends: false,
        duration: { days: 3 },
        dayHeaderContent: (args) => {
          return moment(args.date).format('ddd D')
        },
        headerToolbar: {
          left: 'myCustomButtonLeft',
          center: 'title',
          right: 'myCustomButtonRight'
          // right: 'month,basicWeek,basicDay'
        },


        customButtons: {
          myCustomButtonRight: {
            text: 'Next',
            click: this.handleNextMonthClick.bind(this)
          },
          myCustomButtonLeft: {
            text: 'Previous',
            click: this.handlePreviousMonthClick.bind(this)
          },
        },
        fixedWeekCount: false,
        showNonCurrentDates: false,
        dateClick: this.handleDateClick.bind(this),
        eventClick: (event) => {
          this.evenetClick(event);
        },
        // viewSkeletonRender: any,
        eventContent: this.renderEventContent,  // This will render the event with image
        events: this.eventList,
      }
    } else {
      this.calendarOptions = {
        initialView: 'dayGridMonth',
        // eventLimitText: "More",
        selectable: true,
        dayMaxEvents: 2,
        firstDay: 1,
        weekends: false,
        duration: { days: 1 },
        headerToolbar: {
          left: 'myCustomButtonLeft',
          // left: 'previousMonth',
          center: 'title',
          right: 'myCustomButtonRight',

          // right: 'month,basicWeek,basicDay'
        },
        buttonText: {
          prev: 'Previous'
        },
        customButtons: {
          myCustomButtonRight: {
            text: 'Next',
            click: this.handleNextMonthClick.bind(this)
          },
          myCustomButtonLeft: {
            text: 'Previous',
            click: this.handlePreviousMonthClick.bind(this)
          },
          // prev: {
          //   text: 'Previous',
          //   hint: 'Previous',
          //   icon: 'arrow-left',
          //   bootstrapFontAwesome: 'd',
          //   click: this.handlePreviousMonthClick.bind(this)
          // },
          // next: {
          //   text: 'Next',
          //   icon: 'arrow-right',
          //   click: this.handleNextMonthClick.bind(this)
          // }
        },
        fixedWeekCount: false,
        showNonCurrentDates: false,
        dateClick: this.handleDateClick.bind(this),
        eventClick: (event) => {
          console.log(event)
          this.evenetClick(event);
        },
        // viewSkeletonRender: any,
        eventContent: this.renderEventContent,  // This will render the event with image
        events: this.eventList
      }
    }

    this.checkEventsOnDateClick(this.eventList, this.selectedDate);
  }

  evenetClick(arg: any) {
    // return;
    if (arg.event._def.extendedProps.status == 'booked') {
      this.bookingPopup(arg.event._def.extendedProps, '');
    } else if (arg.event._def.extendedProps.status == 'casual_cancelled') {
      this.bookingPopup(arg.event._def.extendedProps, '');
    } else {
      this.externalEventPopup(arg);
    }
  }
  openBulkDetailModal() {
    this.bsModalRef = this.modalService.show(this.bulkDataModal, { class: 'modal-lg', backdrop: 'static' });
  }
  handleDateClick(arg: any) {
    this.selectedDate = arg.dateStr;
    this.bookingList = [];
    this.checkEventsOnDateClick(this.eventList, arg.dateStr);
  }

  checkEventsOnDateClick(eventArray: any, selectedDate: any) {
    this.selectedDate = moment(selectedDate).format('MMMM D,yyyy');
    let pipe = new DatePipe('en-US');
    this.bookingList = [];
    console.log("aray", eventArray);
    console.log(selectedDate);
    for (let i = 0; i < eventArray.length; i++) {
      // let eventDate = pipe.transform(eventArray[i]['date'], 'yyyy-MM-dd');
      if (eventArray[i]['date'] == moment(selectedDate).format('DD/MM/YYYY') && eventArray[i]['status'] == 'booked') {
        this.bookingList.push(eventArray[i])
      }
      if (eventArray[i]['date'] == moment(selectedDate).format('DD/MM/YYYY') && eventArray[i]['status'] == 'casual_cancelled') {
        this.bookingList.push(eventArray[i])
      }
    }
    this.ngxService.stop();
  }

  bookingPopup(bookingData: any, edit: any) {
    if (edit == 'edit') {
      const initialState = {
        edit: edit,
        bookingDatas: bookingData
      };
      this.bsModalRef = this.modalService.show(BookingConfrimModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
    } else {
      const initialState = {
        bookingDatas: bookingData
      };
      this.bsModalRef = this.modalService.show(BookingConfrimModalComponent, { initialState, class: 'modal-lg', backdrop: 'static' });
    }

  }

  externalEventPopup(arg: any) {
    this.selectedEventID = ''
    if (arg == '') {
      var startFrom = new Date();
      var endFrom = new Date();
      // Set hours
      startFrom.setHours(8);
      endFrom.setHours(16);
      // Then set minutes
      startFrom.setMinutes(0);
      endFrom.setMinutes(0);

      let startDate = moment(startFrom).format('DD/MM/YYYY hh:mm A');

      let endDate = moment(endFrom).format('DD/MM/YYYY hh:mm A');
      this.addExternalEventForm.patchValue({
        start_end_date: startDate + ' - ' + endDate,
      })
      this.bsModalRef = this.modalService.show(this.addExternalEvent, { class: 'modal-md', backdrop: 'static' });
    } else {
      this.selectedEvent = arg;
      this.selectedEventID = arg.event._def.publicId
      this.eventRangeValue = [new Date(arg.event._def.extendedProps.event_date.start),
      new Date(arg.event._def.extendedProps.event_date.end)];
      this.startTime = new Date(new Date(arg.event._def.extendedProps.event_date.start));
      this.endTime = new Date(new Date(arg.event._def.extendedProps.event_date.end));
      let startDate = moment(arg.event._def.extendedProps.event_date.start).format('DD/MM/YYYY hh:mm A');
      let endDate = moment(arg.event._def.extendedProps.event_date.end).format('DD/MM/YYYY hh:mm A');

      this.addExternalEventForm.patchValue({
        title: arg.event._def.title,
        start_end_date: startDate + ' - ' + endDate,

      })
      this.bsModalRef = this.modalService.show(this.addExternalEvent, { class: 'modal-md', backdrop: 'static' });
    }
  }

  // GET EVENTS FROM CALENDER PREVIOUS CLICK
  handlePreviousMonthClick(): void {
    this.ngxService.start();
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.prev();
    let getDates = this.calendarType == 'month' ? this.monthStartEndDate(calendarApi.getDate()) : this.weekStartEndDate(calendarApi.getDate())
    this.getCalendarData(getDates.startDate, getDates.endDate);
  }

  // GET EVENTS FROM CALENDER NEXT CLICK
  handleNextMonthClick(): void {
    this.ngxService.start();
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.next();
    let getDates = this.calendarType == 'month' ? this.monthStartEndDate(calendarApi.getDate()) : this.weekStartEndDate(calendarApi.getDate())
    this.getCalendarData(getDates.startDate, getDates.endDate);
  }

  editBookingSec() {
    this.editBooking = !this.editBooking
  }
  selectAllApp(type: any, event: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.bulkDetails = [];
      this.bulkData.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          console.log(x.isChecked)
          this.bulkDetails.push(x);
          this.hideButton = false
        } else {
          this.hideButton = true
          x.isChecked = false;
          console.log(x.isChecked)
          this.bulkDetails = [];
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.bulkDetails.push(type)
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.bulkDetails = this.bulkDetails.filter(x => x != type);
      }
    }
    this.selectAllAppCheck = this.bulkData.length == this.bulkDetails.length ? true : false;
    if (this.bulkDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }


  // Event Render Design
  renderEventContent(eventInfo: any, createElement: any) {
    let eventDetails = eventInfo.event._def.extendedProps;
    let status, title, start_time, end_time, classCovered;
    console.log("de", eventDetails);
    if (eventDetails.status == 'booked') {
      status = 'booked';
      title = eventDetails.notification.consumer.user.full_name;
      start_time = moment(eventDetails.start_time, 'HHmmss').format("HH:mm A");
      end_time = moment(eventDetails.end_time, 'HHmmss').format("HH:mm A");
    } else if (eventDetails.status == 'booked-externally') {
      status = 'booked-externally';
      title = eventDetails.notification.consumer.user.full_name;
      start_time = moment(eventDetails.start_time, 'HHmmss').format("HH:mm A");
      end_time = moment(eventDetails.end_time, 'HHmmss').format("HH:mm A");
    } else if (eventDetails.status == undefined) {
      status = 'external-event';
      title = eventInfo.event._def.title;
      start_time = moment(eventInfo.event.start).format("hh:mm a");
      end_time = moment(eventInfo.event.end).format("hh:mm a");
    } else if (eventDetails.status == 'casual_cancelled') {
      status = 'Cancelled';
      title = eventDetails.notification.consumer.user.full_name;
      start_time = moment(eventInfo.event.start).format("hh:mm a");
      end_time = moment(eventInfo.event.end).format("hh:mm a");
    }

    let eventData = '<div class="col event-indicator status-' + status + '">';
    eventData += '<p class="event-time">' + start_time + ' - ' + end_time + '</p>';
    eventData += '<p class="event-name">' + title + '</p>';
    eventData += '</div>';
    return createElement = { html: eventData }
  }

  // Calendar Type Changed Event
  changeCalendarType(eventData: any) {
    console.log(eventData)
    let calendarApi = this.calendarComponent.getApi();
    this.calendarType = eventData;
    if (eventData == 'week') {
      calendarApi.changeView('dayGridWeek');
      this.setCalendatEvents(new Date())
      let getDates = this.weekStartEndDate(calendarApi.getDate())
      this.getCalendarData(getDates.startDate, getDates.endDate);
    } else {
      calendarApi.changeView('dayGridMonth');
      this.setCalendatEvents(new Date())
      let getDates = this.monthStartEndDate(calendarApi.getDate())
      this.getCalendarData(getDates.startDate, getDates.endDate);
    }
  }

  // Get Month Start & End Date
  monthStartEndDate(date: any) {
    let startDate = moment(date).startOf('month').format('YYYY-MM-DD');
    let endDate = moment(date).endOf('month').add(1,'d').format('YYYY-MM-DD');
    let startEndDate;
    return startEndDate = { startDate, endDate };
  }

  // Get Week Start & End Date
  weekStartEndDate(date: any) {
    let startDate = moment(date).startOf('week').format('YYYY-MM-DD');
    let endDate = moment(date).endOf('week').format('YYYY-MM-DD');
    let startEndDate;
    return startEndDate = { startDate, endDate };
  }

  // Get Calendar Events
  getCalendarData(startDate: any, endDate: any) {
    this.ngxService.start()
    this.calendarService.getCalendarData(startDate, endDate).subscribe({
      next: (res: any) => {
        this.eventList = res.data.bookings_events_list;
        this.totalHoursBooking = res.data.booked_hours;
        this.totalBookings = res.data.total_bookings;
        this.setCalendatEvents(startDate);
        this.ngxService.stop()
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }

  // Get Form Controls for html validations
  get f(): { [key: string]: AbstractControl } {
    return this.addExternalEventForm.controls;
  }

  // External Event Form Submission
  adddExternalEvent(selectedEventID: any): void {
    this.submitted = true;
    if (this.addExternalEventForm.invalid) {
      return;
    }

    this.addExternalEventForm.patchValue({
      school_user_id: this.schoolProps.mixpanel_data.school_id,
    })

    this.ngxService.start();
    if(selectedEventID != ''){
      this.calendarService.editExternalEvent(this.addExternalEventForm.value,selectedEventID).subscribe({
        next: (res: any) => {
          console.log(res)
          this.toastr.success(res.message)
          this.resetCalendarOnEvent()
          this.bsModalRef.hide();
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      })
    }else{
      this.calendarService.addExternalEvent(this.addExternalEventForm.value).subscribe({
        next: (res: any) => {
          console.log(res)
          this.toastr.success(res.message)
          this.resetCalendarOnEvent()
          this.bsModalRef.hide();
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      })
    }
    
  }

  onOpenCalendar(container: any) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
      // console.log(this.monthStartEndDate(event.date))
    };
    container.setViewMode('month');
  }

  changeCalendarMonth() {
    this.ngxService.start();
    let getDates = this.monthStartEndDate(this.calendarMonthSelection);
    // console.log(getDates);
    // return;
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate(this.calendarMonthSelection)
    this.getCalendarData(getDates.startDate, getDates.endDate);
    this.ngxService.start();
  }
  getCalenderBookings() {
    this.ngxService.start();
    this.bookingService.calendarbookingDetails('').subscribe({
      next: (res: any) => {
        this.bookingDetails = res.data
        console.log("cal data", res.data)
        this.ngxService.stop();
      },
      error: (err: any) => {
        this.ngxService.stop();
        console.log(err);
      }
    });
  }

  // set checkbox selected teachers
  setCheckbox(event: any, type: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.consumerDetails = [];
      this.bookingDetails.forEach((x: any) => {
        console.log(x.booking)
        if (checked) {
          x.booking.forEach((checkbox: any) => {
            checkbox.isChecked = true;
            this.consumerDetails.push(checkbox);
          });
          this.hideButton = false
        } else {
          this.hideButton = true
          x.booking.forEach((checkbox: any) => {
            checkbox.isChecked = false;
          });
          this.consumerDetails = [];
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.consumerDetails.push(type)
        this.selectAllCheck = false;
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.consumerDetails = this.consumerDetails.filter(x => x != type);
        this.selectAllCheck = false;
      }
    }

    this.selectAllCheck = this.bookingDetails.length == this.consumerDetails.length ? true : false;
    if (this.consumerDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
    console.log(this.consumerDetails)
  }

  sendMessage(teacher: any) {
    const initialState = {
      teacher: teacher,
      from: ''
    };
    this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
  }

  // send email modal
  sendMail() {
    const initialState = {
      teacher: Array.prototype.map.call(this.consumerDetails, function (item) { return item.notification.consumer.id; }).join(","),
      from: 'find_new',
      all_checkbox: true,
      checked_status: this.consumerDetails,
    };
    this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
  }

  getFilterInputs() {
    this.ngxService.start();
    this.bookingService.calendarbookingDetails(this.getcalendarBookingFilterFormValues()).subscribe({
      next: (res: any) => {
        this.bookingDetails = res.data
        console.log("cal data", res.data)
        this.ngxService.stop();
      },
      error: (err: any) => {
        this.ngxService.stop();
        console.log(err);
      }
    });
  }

  getcalendarBookingFilterFormValues() {
    let inlineEndDate = new DatePipe('en-US').transform(this.bsValue, 'dd/MM/YYYY')
    this.calendarBookingFilterForm.patchValue({
      date: inlineEndDate,
    });
    return this.calendarBookingFilterForm.value;
  }

  setActiveTab(tab: boolean) {
    this.activeTab = tab;

  }
  getBulkDataFormValue() {
    this.getBulkDataForm.patchValue({
      from_date: moment(this.bsBulkFromValue).format('DD/MM/YYYY'),
      to_date: moment(this.bsBulkToValue).format('DD/MM/YYYY')
    })
    return this.getBulkDataForm.value;
  }
  sendBulkDataFormValue() {
    this.editBookings.patchValue({
      line_item_ids: Array.prototype.map.call(this.bulkDetails, function (item) { return item.id; }).join(",")
    })
    return this.editBookings.value;
  }

  getBulkEditData(event: any) {
    if (event == '') {
      if (this.bsBulkFromValue == '' || this.bsBulkFromValue == undefined && this.bsBulkToValue == '' || this.bsBulkToValue == undefined) {
        this.toastr.error("Start date or end date cannot be empty");
        return;
      }
    }
    if (this.bsBulkToValue == '' || this.bsBulkToValue == undefined) {
      return;
    }
    if (this.bsBulkFromValue == '' || this.bsBulkFromValue == undefined) {
      this.toastr.error("Start date or end date cannot be empty");
      return;
    }
    this.ngxService.start();
    this.bookingService.bulkEditData(this.getBulkDataFormValue()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bulkData = res.data
          console.log("cal data", res.data)
          this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.ngxService.stop();
        console.log(err);
      }
    });
  }
  openBulkConfrimModal() {
    this.bsModalRef.hide()
    this.bsModalRef = this.modalService.show(this.bulkEditConfirmModal, { class: 'modal-md', backdrop: 'static' });
  }
  getFormControlValues() {
    this.cancelBookingRequestForm.patchValue({
      line_item_ids: Array.prototype.map.call(this.bulkDetails, function (item) { return item.id; }).join(",")
    })
    return this.cancelBookingRequestForm.value;
  }

  updateBulkData(action: any) {
    if (action == 'bulk_edit') {
      this.ngxService.start();
      this.editBookings.patchValue({
        action: action
      })
      this.bookingService.updateBulkDataApi(this.sendBulkDataFormValue()).subscribe({
        next: (res: any) => {
          if (res.status) {
            this.bulkData = res.data
            console.log("cal data", res.data)
            this.toastr.success(res.message);
            this.bsModalRef.hide()
            this.bsBulkFromValue = ''
            this.bsBulkToValue = ''
            this.editBookings.reset()
            this.editBookings.patchValue({
              booking_absencereason: ['']
            })
            this.getBulkDataForm.patchValue({
              filter_name: [''],
              from_date: [''],
              to_date: [''],
            });
            this.bulkData = ''
            this.bulkDetails = []
            this.resetCalendarOnEvent()
          } else {
            this.toastr.error(res.message);
            this.ngxService.stop();
          }
        },
        error: (err: any) => {
          this.ngxService.stop();
          console.log(err);
        }
      });
    } else if (action == 'bulk_delete') {
      this.Desubmitted = true
      if (this.cancelBookingRequestForm.invalid) {
        return;
      }
      this.ngxService.start();
      this.cancelBookingRequestForm.patchValue({
        action: action
      })
      this.bookingService.updateBulkDataApi(this.getFormControlValues()).subscribe({
        next: (res: any) => {
          if (res.status) {
            this.bulkData = res.data
            console.log("cal data", res.data)
            this.toastr.success(res.message);
            this.cancelBookingRequestForm.reset();
            this.bsBulkFromValue = ''
            this.bsBulkToValue = ''
            this.formInit();
            this.checkLength()
            this.Desubmitted = false
            this.bsModalRef.hide()
            this.getBulkDataForm.patchValue({
              filter_name: [''],
              from_date: [''],
              to_date: [''],
            });
            this.bulkData = ''
            this.bulkDetails = []
            this.ngxService.stop();
          } else {
            this.toastr.error(res.message);
            this.ngxService.stop();
          }
        },
        error: (err: any) => {
          this.ngxService.stop();
          console.log(err);
        }
      });
    }

  }
  closeBulkModal() {
    this.bsBulkFromValue = ''
    this.bsBulkToValue = ''
    this.getBulkDataForm.patchValue({
      filter_name: [''],
      from_date: [''],
      to_date: [''],
    });
    this.bulkData = ''
    this.bulkDetails = []
  }
  closeConfirmModal() {
    this.bsModalRef.hide()
    this.editBookings.reset()
    this.editBookings.patchValue({
      booking_absencereason: ['']
    })
    this.bsBulkFromValue = ''
    this.bsBulkToValue = ''
    this.getBulkDataForm.patchValue({
      filter_name: [''],
      from_date: [''],
      to_date: [''],
    });
    this.bulkData = ''
    this.bulkDetails = []
  }
  closeBulkEditModal() {
    this.bsModalRef.hide()
    this.editBookings.reset()
    this.editBookings.patchValue({
      booking_absencereason: ['']
    })
    this.bsBulkFromValue = ''
    this.bsBulkToValue = ''
    this.getBulkDataForm.patchValue({
      filter_name: [''],
      from_date: [''],
      to_date: [''],
    });
    this.bulkData = ''
    this.bulkDetails = []
  }

  openBulkDeleteModal() {
    this.bsModalRef = this.modalService.show(this.cancelReasonModal, { class: 'modal-md', backdrop: 'static' });
  }
  get cm(): { [key: string]: AbstractControl } {
    return this.cancelBookingRequestForm.controls;
  }
  checkLength() {
    let timing = this.cancelBookingRequestForm.controls['custom_cancel_msg'].value;
    let charectorLength = this.ShowLenghtError - timing.length
    this.ShowLenght = charectorLength
  }

  closeModal() {
    this.bsModalRef.hide()
    this.cancelBookingRequestForm.reset();
    this.formInit();
    this.checkLength()
    this.Desubmitted = false
    this.bsBulkFromValue = ''
    this.bsBulkToValue = ''
    this.getBulkDataForm.patchValue({
      filter_name: [''],
      from_date: [''],
      to_date: [''],
    });
    this.bulkData = ''
    this.bulkDetails = []
  }

  openAddnewModal() {
    this.bsModalRef = this.modalService.show(AddNewModalComponent, { class: 'modal-lg' });
  }

  getTime(event: any) {
    console.log(event)
  }
  openExportModal() {
    this.bsModalRef = this.modalService.show(this.exportModal, { class: 'modal-md', backdrop: 'static' });
  }
  openCustomPicker() {
    this.is_openCustomPicker = true
  }
  openCustomPickerRange() {
    this.is_openCustomPickerRange = true
  }
  getExportControlValues() {
    return this.getExportForm.value

  }
  getExportValue(getValue: any) {
    let startDate = new DatePipe('en-US').transform(getValue[0], 'yyyy-MM-dd')
    let endDate = new DatePipe('en-US').transform(getValue[1], 'yyyy-MM-dd')
    this.bsInlineRangeValue = [getValue[0], getValue[1]];

    this.getExportForm.patchValue({
      from_date: startDate,
      to_date: endDate
    })
    // alert(this.bsInlineRangeValue)

  }

  exportCalandarData() {
    this.ngxService.start();
    this.downloadLink().subscribe(
      (resp: any) => {
        console.log(resp)
        var blob = new Blob([resp.body], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        // var anchor.download = "myList.csv";
        const contentDisposition = resp.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            anchor.download = matches[1].replace(/['"]/g, '');
          }
        }
        anchor.href = url;
        anchor.click();
        this.ngxService.stop()
      });
  }

  downloadLink(): Observable<HttpResponse<Blob>> {
    this.ngxService.start()
    return this.httpClient.get<Blob>(environment.apiUrl + 'api/v1/school-user/export-booking/', {
      params: this.getExportControlValues(),
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  // this.bookingService.exportBookingsAPI(this.getExportControlValues()).subscribe({
  //   next: (res: any) => {    
  //     this.ngxService.stop();
  //   },
  //   error: (err: any) => {
  //     this.ngxService.stop();
  //     this.toastr.error(err.message);
  //     console.log(err);
  //   }

  // });



  setEventRangeValue(event: any) {
    console.log(event)
    this.eventRangeValue = event;
  }

  closeDateRange() {
    var startFrom = moment(this.eventRangeValue[0]).format('DD/MM/YYYY');
    var endFrom = moment(this.eventRangeValue[1]).format('DD/MM/YYYY');
    // Set hours
    let startTime = moment(this.startTime).format('hh:mm A');
    let endTime = moment(this.endTime).format('hh:mm A');

    let startDate = moment(startFrom + ' ' + startTime, 'DD/MM/YYYY hh:mm A').format('DD/MM/YYYY hh:mm A');
    let endDate = moment(endFrom + ' ' + endTime, 'DD/MM/YYYY hh:mm A').format('DD/MM/YYYY hh:mm A');
    console.log(startDate, 'startDate');
    console.log(endDate, 'endDate');
    this.addExternalEventForm.patchValue({
      start_end_date: startDate + ' - ' + endDate
    })
    this.is_openCustomPickerRange = false;
  }

  deleteExternalEvent(id: any, type: any) {
    let deletedId = id == '' ? this.selectedEvent?.event._def.publicId : id;
    let params = { params: { delete_action: type } };
    this.ngxService.start();
    this.calendarService.deleteExternalEvent(deletedId, params).subscribe({
      next: (res: any) => {
        if (res.status) {
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
          this.bsModalRef.hide();
          this.toastr.success(res.message);
        } else {
          alert("error! " + res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err);
        this.ngxService.stop();
      }
    })
  }
  closeExternalModal() {
    this.addExternalEventForm.reset()
    this.submitted = false
    this.is_openCustomPickerRange = false
    this.bsModalRef.hide()
  }

  reOpenBooking(id: any){
    this._router.navigate(['/school/booking-request/reopen/' + id]); 
  }


}





