import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorInterceptor } from "./services/auth/auth-interceptor.interceptor";
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Group Modules
import { LayoutModule } from './layout/layout.module';
import { ProducerModule } from './pages/producer/producer.module';
import { ConsumerModule } from './pages/consumer/consumer.module';

// Libs
import { NgxUiLoaderModule, NgxUiLoaderConfig } from "ngx-ui-loader";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { IntercomModule } from 'ng-intercom';
import { NgChartsModule } from 'ng2-charts';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#ec428b',
  bgsOpacity: 1,
  fgsSize: 40,
  fgsType: "circle",
  fgsColor: "#D3C7F2",
  pbColor: "#D3C7F2",
  text: "Loading, please wait...",
  // logoUrl: '../assets/images/side-nav/fav_icon_new.png',
  logoSize: 50
};

// Layout Components
import {
  PageNotFoundComponent
} from '.';

import { LoginComponent } from './pages/authorization/login/login.component';
import { BodyComponent } from './body/body.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SchoolProfileComponent } from './pages/producer/school-profile/school-profile.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { JobsLoginComponent } from './pages/authorization/jobs-login/jobs-login.component';
import { RegisterComponent } from './pages/authorization/register/register.component';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { HammerModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { PublicSchoolComponent } from './pages/common/public-school/public-school.component';
import { UnsuscribeEmailComponent } from './pages/common/unsuscribe-email/unsuscribe-email.component';
import { SharedModuleModule } from './shared-module/shared-module.module';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
    BodyComponent,
    SchoolProfileComponent,
    JobsLoginComponent,
    RegisterComponent,
    PublicSchoolComponent,
    UnsuscribeEmailComponent
  ],
  imports: [
    BrowserModule,
    SharedModuleModule,
    ImageCropperModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    ProducerModule,
    ConsumerModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgCircleProgressModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      // extendedTimeOut: 0,
      // closeButton: true,
    }),
    // TourNgxBootstrapModule.forRoot(),
    CarouselModule.forRoot(),
    ProgressbarModule.forRoot(),
    HammerModule,
    IntercomModule.forRoot({
      appId: environment.INTERCOM_ID, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    NgChartsModule
    ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorInterceptor, multi: true }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
