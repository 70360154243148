import { Component, OnInit, Output, EventEmitter, HostListener, ChangeDetectorRef, Input, ElementRef } from '@angular/core';
import { SchoolData } from 'src/app/models/schoolUser';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { ActivatedRoute, Router, NavigationStart, RouterLinkActive, RouterLink } from '@angular/router';
import { SharedService } from '../../services/api/message.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgClass } from '@angular/common';


interface toggleSidenav {
  screenWidth: number;
  collapsed: boolean;
}
@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
    
    standalone: true,
    imports: [NgClass, RouterLinkActive, RouterLink]
})

export class SideNavComponent implements OnInit {
  @Output() onToggleSideNav: EventEmitter<toggleSidenav> = new EventEmitter();
  collapsed = false;
  offsetFlag: boolean | undefined;
  scrollTop: any;
  scrolling!: boolean;
  is_booking_open: boolean = true
  is_manage_open: boolean = false
  is_discover_open: boolean = false

  public schoolProps!: SchoolData;
  hideSideMenu: boolean = false;
  job_id: any;
  languages = []
  sub!: Subscription
  hideNavOnJobs: boolean = false;
  name = 'Angular';
  title: any;
  currentUrl: any;
  makeJobsActive: boolean = false;
  is_reports_open: boolean = false;
  sideNavHide: boolean = false;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private sharedService: SharedService,
    private el: ElementRef,
    private authService: AuthenticationService,) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.screenWidth <= 768) {
      this.collapsed = false;
      this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 996) {
      this.collapsed = true;
      this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    } else {
      this.collapsed = false;
      this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
  }
  screenWidth = 0;
  ngOnInit(): void {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.routerChangeMethod(event.url);
      }
    })

    this.currentUrl = this._router.url.split('?');
    if(this.currentUrl[0] == '/school/onboarding-classcover-overview'){
      this.sideNavHide = true
    }else{
      this.sideNavHide = false
    }

    if (this.currentUrl[0] == '/school/jobs' || this.currentUrl[0] == '/school/jobs/headhunter') {
      this.makeJobsActive = true
    }else{
      this.makeJobsActive = false 
    }
    if (this.currentUrl[0] == '/school/jobs/job-details') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      } else {
        this.collapsed = false;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    }else  if (this.currentUrl[0] == '/school/jobs/post-job') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      } else {
        this.collapsed = false;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    } else  if (this.currentUrl[0] == '/school/school-onboarding') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      } else {
        this.collapsed = false;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    }  else  if (this.currentUrl[0] == '/school/school-onboarding-findNew') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      } else {
        this.collapsed = false;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    }  else  if (this.currentUrl[0] == '/school/onboarding-profile') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      } else {
        this.collapsed = false;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    }else  if (this.currentUrl[0] == '/school/onboarding-completed') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      } else {
        this.collapsed = false;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      } 
    }  else {
      this.hideNavOnJobs = false
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      } else {
        this.collapsed = false;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    }
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    if (this.schoolProps.properties.booking_school_switch_angular) {
      this.hideSideMenu = true
    } else {
      this.hideSideMenu = false
    }
  }
  refershHeadhunter(){
    let currentUrl = "school/jobs/headhunter";
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }
  routerChangeMethod(url: any) {
    this.title = url.split('?');
    this.currentUrl = this._router.url.split('?');
    if(this.title[0] == '/school/onboarding-classcover-overview'){
      this.sideNavHide = true
    }else{
      this.sideNavHide = false
    }

    if(this.title[0] == '/school/jobs' || this.title[0] == '/school/jobs/headhunter' ) {
      this.makeJobsActive = true
    }else{
      this.makeJobsActive = false
    }
    if (this.title[0] == '/school/jobs/job-details') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    }else  if (this.title[0] == '/school/jobs/post-job') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    }else  if (this.title[0] == '/school/school-onboarding') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    } else  if (this.title[0] == '/school/school-onboarding-findNew') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    } else  if (this.title[0] == '/school/onboarding-profile') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      } 
    }  else  if (this.title[0] == '/school/onboarding-completed') {
      this.hideNavOnJobs = true
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }  
    }   else {
      this.hideNavOnJobs = false
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 996) {
        this.collapsed = true;
        this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
      }
    }
  }

  ngAfterContentInit() {
    this.sub = this.sharedService.navData.subscribe(
      data => {
        this.hideNavOnJobs = data
      }
    )
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
    this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
  }

  closeSidenav(): void {
    this.collapsed = false;
    this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
  }

  getClass() {
    class MyClass { }
    const instance = new MyClass();
  }

  is_booking_collapsed() {
    this.is_booking_open = !this.is_booking_open
  }
  is_manage_collapsed() {
    this.is_manage_open = !this.is_manage_open
  }
  is_discover_collapsed() {
    this.is_discover_open = !this.is_discover_open
  }
  is_reports_collapsed() {
    this.is_reports_open = !this.is_reports_open
  }
  // public isScrolled = false;
  // public scroll = false;
  // private timeout!: number;

  // @HostListener('window:scroll', ['$event'])
  // onScroll() {
  //   if(this.scrolling = true){
  //     this.isScrolled = true;
  //   }
  //   clearTimeout(this.timeout);
  //   this.timeout = setTimeout(() => {
  //     this.isScrolled = false
  //   }, 200);
  // }

  switchToJobs(){
    if(window.location.hostname == 'booking-test.classcover.com.au'){
      window.open("https://jobs-test.classcover.com.au",  '_blank');
    }else{
      window.open("https://jobs.classcover.com.au",  '_blank');
    }
  }
  stepTwo(){
    this._router.navigate(['/school/school-onboarding'], { queryParams: { step: '_two' } });
  }
  logout() {
    if(localStorage.getItem('bookingSchool') == "true"){
      this.authService.loggedOut();
      if (this._router) {
        this._router.ngOnDestroy();
      }
    }else{
      this.authService.loggedOut();
      if (this._router) {
        this._router.ngOnDestroy();
      }
    }
   
  }

}
