<div class="modal-header">
    <h4 class="modal-title" id="addEdit">Add to your list</h4>
    <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
</div>

<div class="modal-body pb-0">
    <div class="row">
        <div class="col-md-12">
            <p class="txt-regular">You can add teachers individually to your list or bulk upload them. Adding them to
                your list will help make booking them a lot easier.</p>
        </div>
    </div>
    <div class="row mt-10">
        <!-- Pending Request Tabs -->
        <div class="col-lg-12 col-12 mb-5">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link" [ngClass]="{ 'active':activeTab==='pending'}" (click)="invites('pending')"
                        id="nav-invite-tab" data-bs-toggle="tab" data-bs-target="#nav-invite" type="button" role="tab"
                        aria-controls="nav-invite" aria-selected="true">Add individually</button>
                    <button class="nav-link" [ngClass]="{ 'active':activeTab==='history'}" (click)="history('history')"
                        id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history" type="button" role="tab"
                        aria-controls="nav-history" aria-selected="false">Bulk upload</button>
                </div>
            </nav>
        </div>
    </div>

    <div class="tab-content" id="nav-tabContent">
        <!-- Pending Tab Content -->
        <div class="tab-pane fade show" [ngClass]="{ 'active':activeTab==='pending'}" id="nav-invite" role="tabpanel"
            aria-labelledby="nav-invite-tab">
            <form [formGroup]="addNewForm">
                <div formArrayName="teacher_list">
                    <div class="row align-items-center" *ngFor="let phone of quantities().controls; let i = index"
                        [formArrayName]="i">
                        <div class="col-md-6 mt-15">
                            <input formControlName="full_name" type="text" placeholder="Full name" class="form-control"
                                aria-label="Full name">
                        </div>
                        <div class="col-md-6 mt-15">
                            <input formControlName="phone_no" type="text" placeholder="Phone number"
                                class="form-control" aria-label="Phone number">
                        </div>
                        <div class="col-md-6 mt-15">
                            <input formControlName="email" type="text" placeholder="Email" class="form-control"
                                aria-label="Email">
                        </div>
                    </div>
                </div>

            </form>
            <div class="row mt-15">
                <div class="col-md-12">
                    <p (click)="addPhone()" class="c-p text-regular-bold fit-content"><i
                            class="bi bi-plus-circle-fill"></i> Add
                        another</p>
                </div>
            </div>
            <div class="modal-footer mt-10">
                <button (click)="saveAddNewList()" type="button" class="btn cc_p_btn fs_13">Save</button>
            </div>
        </div>
        <div class="tab-pane fade" id="nav-history" [ngClass]="{ 'active':activeTab==='history'}" role="tabpanel"
            aria-labelledby="nav-history-tab">
            <div>
                <p class="txt-regular">Simply follow these steps to invite multiple teachers at once:</p>
                <ul class="txt-regular mt-15">
                    <li><a href="https://phoenixstatic.azureedge.net/static/media/download-example-spreadsheet.xlsx">Download
                            example spreadsheet</a></li>
                    <li>Add your teachers details</li>
                    <li>Upload the file</li>
                </ul>
            </div>
            <!-- <form [formGroup]="bulkUploadForm"> -->
                <div class="add-image">
                    <label for="FileInput" class="filelabel">
                        <div class="uploadText">
                            <div>
                                <p class=" text-regular-bold">
                                    Upload XLS file
                                </p>
                            </div>

                            <div>
                                <p *ngIf="fileName" class="text-regular-bold clr-green">
                                    {{ fileName }}
                                    <!-- <span (click)="removeFile()" class="">x</span> -->
                                </p>
                            </div>
                        </div>

                        <input (change)="onFileSelected($event)" id="FileInput"
                            name="bulk" accept=".xlsx, .xls, .csv" type="file"
                            class="FileUpload1 form-control"/>
                    </label>
                </div>
            <!-- </form> -->
            <div class="modal-footer mt-10">
                <button (click)="saveAddNewList()" type="button" class="btn cc_p_btn fs_13">Save</button>
            </div>
        </div>
    </div>
</div>