import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MomentService {

  constructor(

    ) { }

  public changeDateFormat(format: any, date: any) {
    var date;

    switch(format) { 
      case 'dmy': { 
        date = moment(date).format('DD-MM-YYYY');
         break; 
      } 
      case 'ymd': { 
        date = moment(date).format('YYYY-MM-DD');
         break; 
      }
      case 'mdy': {
        date = moment(date).format('MM-DD-YYYY');
         break; 
      } 
      default: {
        date = moment(date).format('YYYY/MM/DD');          
         break; 
      } 
    } 
    return date;
  }

  public changetimeFormat(format: any, timeParams: any){
    var time;

    switch(format) { 
      case '24hrs': { 
        // time = moment(timeParams,'HHmmss').format("HH:mm A");
        time = moment(timeParams,'HHmmss').format("HH:mm A");
         break; 
      } 
      case 'seconds': { 
        time = moment(timeParams,'HHmmss').format("HH:mm A");
         break; 
      }
      case 'minutes': {
        time = moment(timeParams,'HHmmss').format("HH:mm A");
         break; 
      } 
      default: {
        time = moment(timeParams,'HHmmss').format("HH:mm A");
         break; 
      } 
    } 
    return time;
  }

}
