@if (isCheckUser() && sideNav) {
  <app-side-nav (onToggleSideNav)="onToggleSideNav($event)"></app-side-nav>
}
@if (isCheckUser() && producerHeader) {
  <app-producer-header></app-producer-header>
}
@if (isCheckUser() && consumerHeader) {
  <app-consumer-header></app-consumer-header>
}
<app-body [collapsed]="isSidenavCollapsed"
  [screenWidth]="screenWidth">
</app-body>