import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { NgChartsModule } from 'ng2-charts';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { environment as environment_1 } from 'src/environments/environment';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
// import { ProducerModule } from './app/pages/producer/producer.module';
import { LayoutModule } from './app/layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedModuleModule } from './app/shared-module/shared-module.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { BrowserModule, HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthInterceptorInterceptor } from './app/services/auth/auth-interceptor.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { routes } from './app/app-routes';
import { provideRouter } from '@angular/router';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#ec428b',
  bgsOpacity: 1,
  fgsSize: 40,
  fgsType: "circle",
  fgsColor: "#D3C7F2",
  pbColor: "#D3C7F2",
  text: "Loading, please wait...",
  // logoUrl: '../assets/images/side-nav/fav_icon_new.png',
  logoSize: 50
};



if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
      provideRouter(routes),
        importProvidersFrom(BrowserModule, FullCalendarModule, SharedModuleModule, ImageCropperModule, NgSelectModule, FormsModule, ReactiveFormsModule, LayoutModule, NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), NgCircleProgressModule.forRoot(), BsDatepickerModule.forRoot(), TimepickerModule.forRoot(), ModalModule.forRoot(), ToastrModule.forRoot({
            timeOut: 3000,
            // extendedTimeOut: 0,
            // closeButton: true,
        }), 
        IntercomModule.forRoot({
            appId: environment.INTERCOM_ID,
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        }), 
        // TourNgxBootstrapModule.forRoot(),
        CarouselModule.forRoot(), ProgressbarModule.forRoot(), HammerModule, NgChartsModule),
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
})
  .catch(err => console.error(err));
