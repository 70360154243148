<div class="find-new-page p-15 mt-25">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <a class="clr-cc-vilot user-title c-p" (click)="stepTwo()"><i class="bi bi-arrow-left-short"></i> Back</a>
        </div>
        <div class="d-flex justify-content-center mt-10">
            <div class="on_border com_1"></div>
            <div class="on_border com_2"></div>
            <div class="on_border com_3"></div>
        </div>
    </div>

</div>


<div class="booking-request-page-demo pb-90 p-15" #bookingRequest>
    <div class="row mt-10">
        <div class="col-xl-12">
            <!-- Filters -->
            <div class="row mt-10">
                <div class="col-md-3   col-sm-6 col-lg-3 mt-10">
                    <div class="demoTooltip">
                        <div class="form-floating  mt-10">
                            <ng-select [searchable]="false" [readonly]="true" [clearable]="false"
                                [dropdownPosition]="'bottom'" [(ngValue)]="selectedID" placeholder="12/12/2025"
                                [multiple]="false">
                            </ng-select>
                            <label for="floatingSelect">Date of booking</label>
                        </div>
                        <div class="demoTooltipText" *ngIf="first_step">
                            <p class="text-s-m-g-1">Start by narrowing your list by availability, subjects and more.</p>
                            <div class="nextDemoTab  mt-20">
                                <div>
                                    <ul class="navDot">
                                        <li class="showNumber">1</li>
                                        <li class="hideNumber"></li>
                                        <li class="hideNumber"></li>
                                        <li class="hideNumber"></li>
                                        <li class="hideNumber"></li>
                                        <li class="hideNumber"></li>
                                    </ul>
                                </div>
                                <div>
                                    <button class="text-s-m-g-1 nextText c-p" (click)="nextStep('two')">NEXT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-lg-3 mt-10">
                    <div class="form-floating mt-10">
                        <ng-select [searchable]="false" [readonly]="true" [clearable]="false"
                            [dropdownPosition]="'bottom'" [(ngValue)]="selectedID" placeholder="4" [multiple]="false">
                        </ng-select>
                        <label for="floatingSelect">No. of staff</label>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-lg-3 mt-10">
                    <div class="form-floating mt-10">
                        <ng-select [searchable]="false" [readonly]="true" [clearable]="false"
                            [dropdownPosition]="'bottom'" [(ngValue)]="selectedID" placeholder="Teacher"
                            [multiple]="false">
                        </ng-select>
                        <label for="floatingSelect">Role type</label>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6 mt-10">
                    <div class="form-floating mt-10">
                        <ng-select [searchable]="false" [readonly]="true" [clearable]="false"
                            [dropdownPosition]="'bottom'" [(ngValue)]="selectedID" placeholder="Full day"
                            [multiple]="false">
                        </ng-select>
                        <label for="floatingSelect">Booking range</label>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mt-10">
                    <div class="form-floating mt-10">
                        <ng-select [searchable]="false" [readonly]="true" [clearable]="false"
                            [dropdownPosition]="'bottom'" [(ngValue)]="selectedID" placeholder="3 Km"
                            [multiple]="false">
                        </ng-select>
                        <label for="floatingSelect">Distance</label>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mt-10">
                    <div class="form-floating mt-10">
                        <ng-select [searchable]="false" [readonly]="true" [clearable]="false"
                            [dropdownPosition]="'bottom'" [(ngValue)]="selectedID" placeholder="Marry anthew"
                            [multiple]="false">
                        </ng-select>
                        <label for="floatingSelect">Teacher(s) being covered</label>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mt-10">
                    <div class="form-floating mt-10">
                        <ng-select [searchable]="false" [readonly]="true" [clearable]="false"
                            [dropdownPosition]="'bottom'" [(ngValue)]="selectedID" placeholder="Class 3A"
                            [multiple]="false">
                        </ng-select>
                        <label for="floatingSelect">Class being covered</label>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mt-10">
                    <div class="form-floating mt-10">
                        <ng-select [searchable]="false" [readonly]="true" [clearable]="false"
                            [dropdownPosition]="'bottom'" [(ngValue)]="selectedID" placeholder="Sick"
                            [multiple]="false">
                        </ng-select>
                        <label for="floatingSelect">Reason of absence</label>
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-md-12 col-sm-6 mt-10">
                    <p class="text-regular-bold c-p fit-content  clr-cc-vilot">
                        <i class="bi bi-plus fs-20 v-align-m"></i> Add more booking
                        information
                    </p>
                </div>
            </div>

            <div class="row align-items-center pt-10">
                <div class="col-md-12">
                    <p>
                        <i class="bi bi-info-circle-fill"></i> You are looking to book
                        <span class="text-regular-bold">1 teacher</span>
                        <span>
                            for the
                            <span class="text-regular-bold">
                                whole day
                            </span>
                            on
                            <span class="text-regular-bold">May 25, 2022 - June 01, 2022</span>
                        </span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="row mt-15">
                        <div class="col-lg-6 col-xl-5 col-md-4">
                            <div class="addAllDiv">
                                <div class="select-filter-list">
                                    <input aria-label="Select teachers" type="checkbox" data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                        class="dropdown-toggle text-regular-bold c-p form-check-input mr-10" />

                                </div>


                                <span class="d-flex ml-15 align-items-center">
                                    <i class="bi bi-plus fs-20"></i>
                                    <p class="text-regular-bold c-p clr-cc-vilot booking-add-all">
                                        <span>Add
                                            Selected</span>
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div class="col-lg-6 col-xl-7 col-md-8">
                            <div class="addAllDiv1 top-filter">
                                <div class="c-p text-regular-bold clr-cc-vilot h_b showHideTag t-dec-none"><i
                                        class="bi bi-eye' mr-4 fs-16"></i>
                                    Show Filters
                                </div>


                                <div class="dropdown sortByBR">
                                    <p data-bs-toggle="dropdown"
                                        class="mt-2 d-flex dropdown-toggle clr-cc-vilot h_b  c-p">
                                        <span><i class="bi bi-filter-left mr-5"></i></span>
                                        <span class="mt-1">Sort by</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- List of Teachers Card -->
                    <div class="mt-20">
                        <div class="card mt-20 pb-15" *ngFor="let teacher of teacher; index as i">
                            <div class="demoTooltip">
                                <div class="row align-items-center mt-10">
                                    <div class="col-md-9 col-sm-8 col-lg-8">
                                        <div class="col-md-12 col-lg-12 d-flex align-items-center profile-info">
                                            <div class="pic-and-check c_input mr-10 ml-10">
                                                <input class="form-check-input" type="checkbox" />
                                            </div>
                                            <div class="pic-and-check ml-0">
                                                <img class="profile-picture" alt="pic"
                                                    [src]="'../../../../assets/images/teacher-static-profile.png'" />
                                                <br>
                                                <div class="s_r_div">
                                                    <p class="text-center d_1">
                                                        <i class="bi bi-star-fill"></i>
                                                        <i class="bi bi-star-fill ml-5"></i>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="pic-and-check ml-10">
                                                <p class="d-flex align-items-center">
                                                    <span class="user-title">{{teacher.name}}
                                                    </span>
                                                    <span>
                                                        <i class="bi bi-check-circle-fill check-completed ml-5"></i>
                                                    </span>
                                                </p>

                                                <p class="user-sub-title">
                                                    <span>Teacher</span>
                                                    <span class="divider-right ml-8 mr-4"></span>
                                                    <span>
                                                        Active 20 days ago</span>
                                                </p>
                                                <p class="complete-match">
                                                    Compete Match
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!--System and tab reponsive section add to list and add notes break point 576px  -->
                                    <div
                                        class="col-md-3 col-sm-4 col-lg-4 d-flex justify-content-right align-self-flex-start">
                                        <div class="add-all-section pr-15">
                                            <div class="demoTooltip_two">
                                                <p class="text-regular-bold clr-cc-vilot" *ngIf="!addList">
                                                    <i class="bi bi-plus fs-19 v-align-m"></i> Add to invite
                                                    list
                                                </p>
                                                <div class="demoTooltipText_two" *ngIf="i == 0 && third_step">
                                                    <p class="text-s-m-g-1">Start adding to your booking shortlist.
                                                    </p>
                                                    <div class="nextDemoTab  mt-20">
                                                        <div>
                                                            <ul class="navDot">
                                                                <li class="hideNumber"></li>
                                                                <li class="hideNumber"></li>
                                                                <li class="showNumber">3</li>
                                                                <li class="hideNumber"></li>
                                                                <li class="hideNumber"></li>
                                                                <li class="hideNumber"></li>
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            <button class="text-s-m-g-1 nextText c-p"
                                                                (click)="nextStep('four')">NEXT</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <p *ngIf="addList" class="text-regular-bold c-p clr-green">
                                                <i class="bi bi-check-lg fs-19 v-align-m"></i> Added to
                                                invite list
                                            </p>

                                            <button class="text-regular-bold c-p clr-cc-vilot edit-notes">
                                                <i class="bi bi-pencil-fill vertical-align-bottom fs-11 ml-5 mr-3"></i>
                                                Add notes
                                            </button>
                                        </div>
                                        <!--End of system and tab reponsive section add to list and add notes break point 576px  -->
                                    </div>
                                </div>
                                <div class="demoTooltipText_one" *ngIf="i == 0 && second_step">
                                    <p class="text-s-m-g-1">Your list will now match your chosen filters.
                                    </p>
                                    <div class="nextDemoTab  mt-20">
                                        <div>
                                            <ul class="navDot">
                                                <li class="hideNumber"></li>
                                                <li class="showNumber">2</li>
                                                <li class="hideNumber"></li>
                                                <li class="hideNumber"></li>
                                                <li class="hideNumber"></li>
                                                <li class="hideNumber"></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <button class="text-s-m-g-1 nextText c-p" (click)="nextStep('three')">NEXT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Side Component -->
                <div class="col-lg-4">
                    <div class="card px-15">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-6">
                                <div class="demoTooltip_three">
                                    <p class="user-title">Invite list</p>
                                    <div class="demoTooltipText_three" *ngIf="fourth_step">
                                        <p class="text-s-m-g-1">Review your booking request to make sure everything
                                            looks good.
                                        </p>
                                        <div class="nextDemoTab  mt-20">
                                            <div>
                                                <ul class="navDot">
                                                    <li class="hideNumber"></li>
                                                    <li class="hideNumber"></li>
                                                    <li class="hideNumber"></li>
                                                    <li class="showNumber">4</li>
                                                    <li class="hideNumber"></li>
                                                    <li class="hideNumber"></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <button class="text-s-m-g-1 nextText c-p" (click)="nextStep('five')">NEXT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-6">
                                <p class="text-regular-medium text-right c-p clr-cc-vilot">clear all</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-xl-6">
                                <div class="demoTooltip_five">
                                    <div class="form-floating mt-10">
                                        <ng-select [searchable]="false" [readonly]="true" [clearable]="false"
                                            [dropdownPosition]="'bottom'" [(ngValue)]="selectedID"
                                            placeholder="All at once" [multiple]="false">
                                            <ng-option value="1"> All at once</ng-option>
                                        </ng-select>
                                        <label for="floatingSelect">Request type</label>
                                    </div>
                                    <div class="demoTooltipText_five" *ngIf="fifth_step">
                                        <p class="text-s-m-g-1">You can change the request type from All at once to Once
                                            at a time from here.
                                        </p>
                                        <div class="nextDemoTab  mt-20">
                                            <div>
                                                <ul class="navDot">
                                                    <li class="hideNumber"></li>
                                                    <li class="hideNumber"></li>
                                                    <li class="hideNumber"></li>
                                                    <li class="hideNumber"></li>
                                                    <li class="showNumber">5</li>
                                                    <li class="hideNumber"></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <button class="text-s-m-g-1 nextText c-p" (click)="nextStep('six')">NEXT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-xl-6">
                                <div class="form-floating mt-10">
                                    <ng-select [searchable]="false" [readonly]="true" [clearable]="false"
                                        [dropdownPosition]="'bottom'" placeholder="1 hour" [multiple]="false"
                                        id="floatingSelect" aria-label="Floating label select example">
                                        <ng-option value="300">5 minutes</ng-option>
                                    </ng-select>
                                    <label for="floatingSelect">Close booking after</label>
                                </div>
                            </div>

                            <div>
                                <div class="form-check form-switch d-flex align-items-center p-0 mt-10">
                                    <label class="form-check-label" for="delay-send">Delay send</label><br />
                                    <input aria-label="" class="form-check-input delay-send" type="checkbox"
                                        role="switch" id="delay-send" />
                                    <br />
                                </div>
                                <p class="text-regular fs-12 clr-grey">Choose a time to send the booking request in
                                    the future.</p>
                            </div>
                        </div>
                        <div class="row mt-10">
                            <div class="inviteListEmptyState" *ngIf="!addList">
                                <p class="text-">Invite list is empty</p>
                            </div>
                            <div class="px-15 pt-0" *ngIf="addList">
                                <div class="card px-7 mt-5" *ngFor="let teacher of teacher; index as i">
                                    <div class="row headings">
                                        <div class="col-md-10 col-lg-10 col-10">
                                            <div class="d-flex align-items-center">
                                                <div class="">
                                                    <img class="profile-picture profile_picture_small" alt="img"
                                                        [src]="'../../../../assets/images/teacher-static-profile.png'" />
                                                </div>
                                                <div class="line-item">
                                                    <p class="name ml-10">
                                                        {{teacher.name}}
                                                        <span>
                                                            <i class="bi bi-check-circle-fill check-completed ml-5"></i>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2 col-lg-2 col-2 d-flex align-items-center">
                                            <p class="c-p">
                                                <i class="bi bi-x clr-cc-vilot remove-line-item"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class=" ml-5">
                                <div class="form-check">
                                    <input aria-label="" class="form-check-input c-p mt-4" type="checkbox"
                                        id="send_emergency_list" />
                                    <label class="form-check-label c-p" for="send_emergency_list">
                                        Send to emergency list
                                    </label>
                                </div>
                            </div>

                            <div class="pb-10 mt-20">
                                <div>
                                    <div class="demoTooltip_six">
                                        <button type="button" class="btn cc_p_btn fs_13 w-100" id="send-invite-btn">
                                            <i class="bi bi-send-fill" style="margin-right: 2px"></i>
                                            Send Invite
                                        </button>
                                        <div class="demoTooltipText_six" *ngIf="six_step">
                                            <p class="text-s-m-g-1">Click here to send your booking request.
                                            </p>
                                            <div class="nextDemoTab  mt-20">
                                                <div>
                                                    <ul class="navDot">
                                                        <li class="hideNumber"></li>
                                                        <li class="hideNumber"></li>
                                                        <li class="hideNumber"></li>
                                                        <li class="hideNumber"></li>
                                                        <li class="hideNumber"></li>
                                                        <li class="showNumber">6</li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <button class="text-s-m-g-1 nextText c-p" (click)="nextStep('done')">DONE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>