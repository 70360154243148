<div class="row mt-20 mb-50" *ngIf="step != '_completed'">
    <div class="col-lg-12 text-center">
        <p class="fs-24 text-regular-bold">Hi {{ schoolProps.mixpanel_data.name }}, welcome to ClassCover. Get started
            in a few steps.</p>

        <p *ngIf="step == '_one'" class="text-regular-medium fs-16 mt-15"><span class="clr-red">0 of 3</span> steps
            complete</p>
        <p *ngIf="step == '_two'" class="text-regular-medium fs-16 mt-15"><span class="clr-red">1 of 3</span> steps
            complete</p>
        <p *ngIf="step == '_three'" class="text-regular-medium fs-16 mt-15"><span class="clr-red">2 of 3</span> steps
            complete</p>
    </div>

    <a routerLink="/school/school-onboarding-findNew" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <div class="row justify-content-md-center justify-content-sm-center  align-items-center mt-30 c-p">
            <div class="col-2 col-sm-2 col-lg-1">
                <div>
                    <img class="onboarding_img" src="assets/images/onboarding_num.png">
                    <p class="clr-cc-vilot  on_img_text">1</p>
                </div>
            </div>
            <div class="col-8 col-md-5">
                <p class="fs-21 text-regular-bold mb-15">Build your list</p>
                <p class="txt-regular fs-16">Build your list with new and known relief pool contacts.</p>
            </div>
            <div class="col-2 col-lg-1 cus-width">
                <i class="bi bi-chevron-right clr-cc-vilot"></i>
            </div>
        </div>
    </a>

    <a routerLink="/school/onboarding-classcover-overview" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <div class="row justify-content-md-center justify-content-sm-center  align-items-center mt-20 c-p">
            <div class="col-2 col-sm-2 col-lg-1">
                <div>
                    <img class="onboarding_img" src="assets/images/onboarding_num.png">
                    <p class="clr-cc-vilot  on_img_text">2</p>
                </div>
            </div>
            <div class="col-8 col-md-5">
                <p class="fs-21 text-regular-bold mb-15">Place a test booking</p>
                <p class="txt-regular fs-16">See how easy it is to make a booking.</p>
            </div>
            <div class="col-2 col-lg-1 cus-width">
                <i class="bi bi-chevron-right clr-cc-vilot"></i>
            </div>
        </div>
    </a>

    <a routerLink="/school/onboarding-profile" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <div class="row justify-content-md-center justify-content-sm-center  align-items-center mt-20 c-p">
            <div class="col-2 col-sm-2 col-lg-1">
                <div>
                    <img class="onboarding_img" src="assets/images/onboarding_num.png">
                    <p class="clr-cc-vilot  on_img_text">3</p>
                </div>
            </div>
            <div class="col-8 col-md-5">
                <p class="fs-21 text-regular-bold mb-15">Complete your profile</p>
                <p class="txt-regular fs-16">Complete your school’s profile.</p>
            </div>
            <div class="col-2 col-lg-1 cus-width">
                <i class="bi bi-chevron-right clr-cc-vilot"></i>
            </div>
        </div>
    </a>
    <div class="row justify-content-md-center justify-content-lg-center justify-content-sm-center mt-20">
        <div class="col-lg-8 col-md-9">
            <hr>
            <div class="card boost_onboarding px-0 mt-20">
                <div class="d-flex align-items-center">
                    <div>
                        <img class="onScreen_img" src="assets/images/img_on.png">
                    </div>
                    <div class="pl-15 pr-15 w-100">
                        <p class="user-title fs-21">Want to boost your list?</p>
                        <p class="txt-regular mt-5">Schools with a completed profile are more likely to grow their list.
                        </p>
                        <div class="d-flex justify-content-space-bet align-items-center mt-12">
                            <div class="progress mr-10" role="progressbar" aria-label="Basic example" aria-valuenow="0"
                                aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar" style="{{profile_percent}}"></div>
                            </div>
                            <p class="txt-regular">{{schoolProps.properties.profile_percentage}} of 100% completed</p>
                        </div>
                        <div class="mt-12">
                            <a class="user-title clr-cc-vilot c-p" for="" routerLink="/school/onboarding-profile"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                                Complete your school profile <i class="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row  pb-30 pt-20 mt-50 text-center" *ngIf="step == '_completed'">
    <div class="col-md-8 offset-md-2 col-lg-8 offset-lg-2">
    <div class="card px-50">
        <p><img class="on_img" src="assets/images/CC_Onboarding.png"></p>
        <p class="fs-24 text-regular-bold mt-20">Great work on completing the steps! Continue to explore or start
            booking straight away.</p>
        <p><button class="btn cc_p_btn mt-20" routerLink="/school/booking-request" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Get started</button></p>
            </div>
    </div>
</div>