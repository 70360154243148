import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  ProducerHeaderComponent,
  ConsumerHeaderComponent,
  SideNavComponent,
  ConfirmationModalComponent
}
  from './';
import { HtmlComponentsComponent } from './html-components/html-components.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  declarations: [
    ProducerHeaderComponent,
    ConsumerHeaderComponent,
    SideNavComponent,
    HtmlComponentsComponent,
    ConfirmationModalComponent,
    LoadingPageComponent,
    // InlineCalendarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModuleModule,
    PopoverModule.forRoot()
  ],
  exports: [ProducerHeaderComponent, ConsumerHeaderComponent, SideNavComponent]
})
export class LayoutModule { }
