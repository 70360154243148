<nav class="navbar jobs_only_user">
    <div class="container-fluid display-profile-header pl-20 pr-20 align-items-center">
        <div class="d-flex">
            <img alt="" class="side_logo_text_booking" src="assets/images/favicon/cc_reverse@2x.png" />
        </div>
        <div class="nav-item public-profile d-flex align-items-center">
            <li class="nav-item">
                <a class="nav-link" target="_blank"
                    href="https://www.classcover.com.au/teachers?utm_source=cc_app&amp;utm_campaign=jobs">Learn more</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" target="_blank"
                    href="https://www.classcover.com.au/hire-teachers-through-classcover-jobs?utm_source=cc_app&amp;utm_campaign=jobs">Post
                    a job</a>
            </li>
            <li class="nav-item pr-2">
                <a class="nav-link" target="_blank" href="http://phoenix.classcover.com.au/en/login">Login</a>
            </li>
            <li class="nav-item pl-2">
                <a class="btn btn-cta" target="_blank" href="http://phoenix.classcover.com.au/en/teacher/register">Sign
                    up</a>
            </li>
        </div>
    </div>
</nav>
<div class="row pb-90 pt-90 jobs_only_user">
    <!-- First half -->
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-15">
        <p class="text-center"><img class="unsuscribe-img" src="../../../../assets/images/common/unsuscribe-img.png">
        </p>
        <p class="text-center fs-17 mt-10 txt-regular">Are you sure you want to unsubscribe from ClassCover Headhunter
            emails?</p>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-20">
                <p class="text-center"><button class="btn cc_p_btn">Unsubscribe</button></p>
                <!-- <button class="btn cc_p_btn_outline ml-20">Cancel</button> -->
            </div>
    </div>
</div>