import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { last, map, take } from "rxjs/operators";
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { SchoolData } from 'src/app/models/schoolUser';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-create-smart-group',
  templateUrl: './create-smart-group.component.html',
  styleUrls: ['./create-smart-group.component.scss']
})
export class CreateSmartGroupComponent implements OnInit {
  submitted: boolean = false;
  showNewStaffOne: boolean = false;
  public smartGroups: any;
  smart_groups = [];
  currentPage: number = 1
  pageFrom: any;
  teacherData: any;
  schoolStaffs: any;
  type: any = '';
  data: any;
  is_individualDetails: boolean = false;
  // index: number;
  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.formInit();
  }

  formInit() {
    if (this.type == 'edit') {
      this.smartGroups = this.fb.group({
        name: [this.data.title, [Validators.required]],
        school_staff: [this.data.school_staff.selected_ids],
        description: [this.data.description, [Validators.required]],
      });
    } else {
      this.smartGroups = this.fb.group({
        name: ['', [Validators.required]],
        school_staff: [[]],
        description: ['', [Validators.required]],
        first_name: [''],
        last_name: [''],
        email: [''],
        phone: ['']
        // staffs: this.fb.array([]),
      });
    }
  }

  addNewAddressGroup() {
    this.showNewStaffOne = true;
    this.is_individualDetails = true
    this.smartGroups.get('first_name').setValidators([Validators.required]);
    this.smartGroups.get('last_name').setValidators([Validators.required]);
    this.smartGroups.get('email').setValidators([Validators.required]);
    this.smartGroups.get('phone').setValidators([Validators.required]);
    this.smartGroups.patchValue({
      school_staff: []
    })
    // const add = this.smartGroups.get('staffs') as FormArray;
    // add.push(
    //   this.fb.group({
    //     first_name: ['', [Validators.required]],
    //     last_name: ['',[Validators.required]],
    //     email: ['',[Validators.required]],
    //     phone_number: ['',[Validators.required]],
    //   })
    // );
  }

  deleteAddressGroup() {
    this.showNewStaffOne = false;
    this.is_individualDetails = false
    this.smartGroups.get('first_name').clearValidators()
    this.smartGroups.get('first_name').updateValueAndValidity(); 


    this.smartGroups.get('last_name').clearValidators(); 
    this.smartGroups.get('last_name').updateValueAndValidity();

    this.smartGroups.get('email').clearValidators(); 
    this.smartGroups.get('email').updateValueAndValidity();

    this.smartGroups.get('phone').clearValidators(); 
    this.smartGroups.get('phone').updateValueAndValidity();
  }

  closeSmartModal() {
    this.bsModalRef.hide();
    this.smartGroups.reset();
  }
  get s(): { [key: string]: AbstractControl } {
    return this.smartGroups.controls;
  }
  getControlSmartValues() {
    return this.smartGroups.value;
  }

  submitEditedSmartGroup() {
    this.submitted = true;
    if (this.smartGroups.invalid) {
      return;
    }
    let staff = this.smartGroups.controls['school_staff'].value
    this.smartGroups.patchValue({
      school_staff: staff.toString()
    });

    this.currentPage = 1;
    this.ngxService.start();
    // Get filters data API
    let id = this.data.id
    this.bookingService.editSmartGroup(id, this.getControlSmartValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.toastr.success(res.message);
          this.bsModalRef.hide();
          this.smartGroups.reset();
          this.submitted = false;
          if (this.pageFrom == 'my_list_page') {
            this.commonService.schoolChoices('my_list').subscribe({
              next: (res: any) => {
                this.smart_groups = res.data.smart_group
                this.ngxService.stop();
              },
              error: (err: any) => {
                console.log(err);
                this.toastr.error(res.message);
                this.ngxService.stop();
              }
            });
          } else if (this.pageFrom == 'smart_group_page') {
            let currentUrl = this._router.url;
            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this._router.navigate([currentUrl]);
            });
          }
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.statusText + ', Please contact admin');
        this.ngxService.stop();
      }
    });
  }
  submitSmartGroup() {
    this.submitted = true;
    if (this.smartGroups.invalid) {
      return;
    }
    this.currentPage = 1;
    this.ngxService.start();
    // Get filters data API
    this.bookingService.CreateSmartGroup(this.getControlSmartValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.toastr.success(res.message);
          this.bsModalRef.hide();
          this.smartGroups.reset();
          this.submitted = false;
          if (this.pageFrom == 'my_list_page') {
            this.commonService.schoolChoices('my_list').subscribe({
              next: (res: any) => {
                this.smart_groups = res.data.smart_group
                this.ngxService.stop();
              },
              error: (err: any) => {
                console.log(err);
                this.toastr.error(res.message);
                this.ngxService.stop();
              }
            });
          } else if (this.pageFrom == 'smart_group_page') {
            let currentUrl = this._router.url;
            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this._router.navigate([currentUrl]);
            });
          }
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.statusText + ', Please contact admin');
        this.ngxService.stop();
      }
    });
    // End of get filters data API
  }
  showIndividualDetails() {
    this.showNewStaffOne = true
    this.is_individualDetails = true
  }
  removeIndividual() {
    this.showNewStaffOne = false
    this.is_individualDetails = false
  }
}
