import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, ViewChild, IterableDiffers } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { SchoolData } from 'src/app/models/schoolUser';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-manage-team-access',
  templateUrl: './manage-team-access.component.html',
  styleUrls: ['./manage-team-access.component.scss']
})
export class ManageTeamAccessComponent implements OnInit {
  activeTab = 'manage-access';
  manageTeamData: any;
  public schoolProps!: SchoolData;
  editType?: string;
  selectAll: boolean = false;
  checkBoxSelectedTeacher: any = [];
  selectedStaff: any;
  roleList: any;
  @ViewChild('resendPendingInviteModal') resendPendingInviteModal: any;
  submittedAdduser: boolean = false;

  manageTeamAccessForm: FormGroup = this.fb.group({
    role_id: ['', [Validators.required]],
    permission: ['', [Validators.required]],
    action: ['edit'],
    add_user_id: ['']
  });

  additionalUserForm: FormGroup = this.fb.group({
    first_name: [''],
    last_name: [''],
    email: ['', [Validators.required, this.noWhitespaceValidator]],
    phone: ['', [Validators.required, this.noWhitespaceValidator]],
  });


  permissionsList = [
    {
      tag: 'owner',
      name: 'Owner'
    },
    {
      tag: 'administrator_access',
      name: 'Administrator Access'
    },
    {
      tag: 'limited_access',
      name: 'Limited Access'
    }
  ];
  @ViewChild('editSelectedStaff') editSelectedStaff: any;
  @ViewChild('RemovestaffConfirmation') RemovestaffConfirmation: any;
  userID: any;
   
  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    public bsModalRef: BsModalRef,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private iterableDiffers: IterableDiffers,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.route.params.subscribe(params => {
      this.editType = params['editType'];
    });
      this.bookingService.manageTeamSettings('').subscribe({
        next: (res: any) => {
          this.manageTeamData = res.data;
          this.roleList = res.role;
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
  }
  invites(activeTab: string) {
    this.activeTab = activeTab;
  }
 public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }
  get ad(): { [key: string]: AbstractControl } {
    return this.additionalUserForm.controls;
  }
    // set checkbox selected teachers
    setCheckbox(event: any, index: number) {
      if (!this.checkBoxSelectedTeacher.includes(event)) {
        this.checkBoxSelectedTeacher = [...this.checkBoxSelectedTeacher, event];
      } else {
        this.checkBoxSelectedTeacher = this.checkBoxSelectedTeacher.filter((item: any) => item !== event);
      }
      this.selectAll = this.checkBoxSelectedTeacher.length > 0 ? true : false;
    }
    openSelectedStaffEdit(staff: any) {
      this.selectedStaff = staff;
      this.selectAll = false;
      this.bsModalRef = this.modalService.show(this.editSelectedStaff, { class: 'modal-md', backdrop: 'static' });
      this.manageTeamAccessForm.patchValue({
        add_user_id: staff?.additional_user?.user?.id.toString(),
        role_id: staff?.role_access?.role_id,
        permission: staff?.role_access?.check_access,
      });
    }
  editSelected() {
    this.bsModalRef = this.modalService.show(this.editSelectedStaff, { class: 'modal-md', backdrop: 'static' });
  }
  deleteSelected(getItem: any) {
      this.manageTeamAccessForm.patchValue({
        add_user_id: getItem?.additional_user?.user?.id.toString(),
        action: 'remove'
      });
    this.bsModalRef = this.modalService.show(this.RemovestaffConfirmation, { class: 'modal-md', backdrop: 'static' });
  }

  makeAdminRta() {
    if (this.selectAll) {
      this.manageTeamAccessForm.patchValue({
        add_user_id: this.checkBoxSelectedTeacher.map((u: any) => u.additional_user?.user?.id).join(','),
        action: 'edit'
      });
    }
    this.commonService.makeRtaAdmin(this.manageTeamAccessForm.value).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });

  }
  removeStaff() {
    if(this.checkBoxSelectedTeacher.length > 0){
      this.manageTeamAccessForm.patchValue({
        add_user_id: this.checkBoxSelectedTeacher.map((u: any) => u.additional_user?.user?.id).join(','),
        action: 'remove'
      });
    }
 
    this.commonService.makeRtaAdmin(this.manageTeamAccessForm.value).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.bsModalRef.hide();
          this.toastr.success(res.message);
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message);
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }
  openAddUserModal() {
    this.bsModalRef = this.modalService.show(this.resendPendingInviteModal, { class: 'modal-md', backdrop: 'static' });
  }
  closeAddUserModal() {
    this.bsModalRef.hide()
    this.submittedAdduser = false;
  }
  getAddUserFormControl() {
    return this.additionalUserForm.value;
  }
  addAdditionalUser() {
    this.submittedAdduser = true;
    if (this.additionalUserForm.invalid) {
      return;
    }
    this.ngxService.start();
    this.bookingService.addAdditionalUserApi(this.getAddUserFormControl()).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.toastr.success(res.message)
          this.submittedAdduser = false;
          this.modalService.hide();
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        } else {
          this.toastr.error(res.message)
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
}
