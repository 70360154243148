<div class="edit-admin-setting pb-50 pt-15"
	[ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'job_booking_user'">
	<div class="row align-items-center">
		<div class=" back-to d-flex">
			<p *ngIf="!this.schoolProps.properties.job_only_school" class="text-regular-bold c-p"
				routerLink="/school/admin-settings" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
				<i class="bi bi-arrow-left-short"></i> Back
			</p>
			<p *ngIf="this.schoolProps.properties.job_only_school" class="text-regular-bold c-p"
				routerLink="/school/jobs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
					class="bi bi-arrow-left-short"></i> Back</p>
		</div>
		<div class="col-lg-9 col-12 col-md-9">
			<div *ngIf="editType != 'additional-users'">
				<!-- <p class="user-title mt-20" *ngIf="editType == 'additional-users'">Manage team access</p> -->
				<p class="user-title mt-20" *ngIf="editType == 'daily-summary'">Daily booking summary</p>
				<p class="user-title mt-20" *ngIf="editType == 'staff-reason-for-absent'">Staff reason for absence list
				</p>
				<p class="user-title mt-20" *ngIf="editType == 'default-school-times'">Default school times</p>
				<p class="user-title mt-20" *ngIf="editType == 'booking-confirmation-form'">Booking confirmation form
					settings
				</p>
				<p class="user-title mt-20" *ngIf="editType == 'payroll-report-settings'">Payroll report settings
				</p>
				<p class="user-title mt-20" *ngIf="editType == 'sms-settings'">SMS settings
				</p>
			</div>
			<div *ngIf="editType == 'additional-users' && this.schoolProps.properties.job_only_school">
				<p class="user-title mt-20">Manage team access</p>

			</div>
		</div>

		<div class="col-lg-3 col-12 col-md-3"
			*ngIf="editType == 'additional-users' && this.schoolProps.properties.job_only_school">
			<button (click)="openAddUserModal()" *ngIf="activeTab === 'manage-access'" type="button"
				class="btn cc_p_btn fs_13 float-right" id="invite-team">Invite team</button>
		</div>
	</div>

	<div class="row" *ngIf="editType == 'additional-users' && !this.schoolProps.properties.job_only_school">
		<app-manage-team-access></app-manage-team-access>
	</div>
	<div class="row" *ngIf="editType == 'additional-users' && this.schoolProps.properties.job_only_school">
		<div class="col-lg-12 col-12 col-md-12">
			<div class="col-lg-12 col-12 col-md-12">
				<div class="history-header sticky  px-15 md-d-none mt-20">
					<div class="row align-items-center">
						<div class="col-lg-4">
							<p class="text_medium">Name</p>
						</div>
						<div class="col-lg-4">
							<!-- <p class="text_medium">Job posting access</p> -->
						</div>
						<div class="col-lg-4">
							<p class="text_medium">Action</p>
						</div>
					</div>
				</div>
				<div class="history-description mt-20">
					<form [formGroup]="manageTeamForm">
						<div class="card px-15 mt-10" *ngFor="let item of manageTeamData">
							<div class="row align-items-center">
								<div class="col-lg-4 col-md-6 col-sm-6 col-12">
									<p class="text_medium lg-d-none mb-5">Name</p>
									<p class="txt-regular">{{item.additional_user.user.full_name}} <span
											*ngIf="item.id == this.schoolProps.properties.additional_user_id"><b>(me)</b></span>
									</p>
									<p class="txt-regular mt-5">{{item.additional_user.user.email}}</p>
								</div>
								<div class="col-lg-4 col-md-4 col-sm-4 col-6">
									<!-- <p class="text_medium lg-d-none mb-5">Job posting access</p>
									<div class="form-check form-switch">
										<input (change)="jobAccess(item.id,$event)"
										    [attr.disabled]="item.role_access.access == 'Owner' ? '' : null"
										    class="form-check-input delay-send c-p" [checked]="item.is_admin"
										    type="checkbox" role="switch" id="">
										</div> -->
								</div>
								<div class="col-lg-4 col-md-4 col-sm-4 col-12">
									<p class="text_medium lg-d-none mb-5">Action</p>
									<p (click)="removeAddModal(item.additional_user.user.full_name, item.additional_user.user.id)"
										*ngIf="item.role_access.access != 'Owner'" class="text_medium remove_btn">Remove
									</p>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-20" *ngIf="editType == 'daily-summary'">
		<div class="col-lg-9 col-12 col-md-12">
			<form [formGroup]="dailyBookingForm">
				<p class="txt-regular">When would you like your school’s daily booking summary to be sent out each day?
				</p>
				<div class="d-flex align-items-center daily-summary mt-20">
					<ng-select id="schudule_time_hrs" formControlName="booking_summary_hr" name="schudule_time_hrs"
						class="fit-content">
						<ng-option *ngFor="let item of hours_d" value="{{item?.value}}">{{item?.title}}</ng-option>
					</ng-select>
					<p class="ml-10 mr-10">:</p>
					<ng-select id="schudule_time_mins" formControlName="booking_summary_min" name="schudule_time_mins"
						class="fit-content">
						<ng-option *ngFor="let items of minutes_d" value="{{items?.value}}">{{items?.title}}</ng-option>
					</ng-select>
				</div>



				<button (click)="saveAdminSettings()" type="button" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
					Save changes
				</button>
			</form>
		</div>
	</div>
	<div class="row" *ngIf="editType == 'staff-reason-for-absent'">
		<div class="col-lg-9 col-12 col-md-12">
			<p class="text-regular-bold text-right mb-10">
				<span class=" c-p h_b" (click)="editSortableBtn()"><i class="bi fs-12 mr-2 bi-pencil-fill"></i>
					Update staff reason for
					absence list</span>
			</p>
			<p class="txt-regular mb-10">Reason</p>
			<bs-sortable [(ngModel)]="leaveList" (dragend)="updateListSwap($event)"
				[ngModelOptions]="{standalone: true}" [itemTemplate]="itemTemplate"
				itemClass="absence-sortable-item"></bs-sortable>
			<ng-template #itemTemplate let-item="item" let-index="index">
				<div class="card px-15 mt-10">
					<form [formGroup]="abcenseReasonForm">
						<div class="row">
							<div class="col-lg-11 col-11">
								<p class="txt-regular"><span><i
											class="bi bi-three-dots-vertical mr-10"></i></span>{{item.initData}}
								</p>
							</div>
							<div (click)="removeStaffAbsenceList(item.id)" class="col-lg-1 col-1">
								<i class="bi bi-trash h_b c-p"></i>
							</div>
						</div>
					</form>
				</div>
			</ng-template>
		</div>
	</div>
	<div class="row mt-20" *ngIf="editType == 'default-school-times'">
		<div class="col-lg-9 col-12 col-md-9">
			<div class="history-header sticky  px-15">
				<div class="row align-items-center">
					<div class="col-lg-3 col-2">
						<p class="txt-regular">Start</p>
					</div>
					<div class="col-lg-3 col-2">
						<p class="txt-regular">End</p>
					</div>
					<div class="col-lg-3 col-5">
						<p class="txt-regular">Booking type</p>
					</div>
					<div class="col-lg-3 col-3">
						<p class="txt-regular">Options</p>
					</div>
				</div>
			</div>
			<div class="history-description">
				<div class="card px-15 mt-10">
					<div class="row align-items-center">
						<div class="col-lg-3 col-md-2 col-sm-2 col-2">
							<p class="txt-regular">{{editAllDataSchoolFirst?.start_time}}</p>
						</div>
						<div class="col-lg-3 col-md-2 col-sm-2 col-2">
							<p class="txt-regular">{{editAllDataSchoolFirst?.end_time}}</p>
						</div>
						<div class="col-lg-3 col-md-5 col-sm-5 col-5">
							<p class="txt-regular">{{editAllDataSchoolFirst?.booking_type}}</p>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-3 col-3">
							<p (click)="editSchoolTime('first-time')" class="text-regular-medium c-p">Edit</p>
						</div>
					</div>
				</div>
				<div class="card px-15 mt-10">
					<div class="row align-items-center">
						<div class="col-lg-3 col-md-2 col-sm-2 col-2">
							<p class="txt-regular">{{editAllDataSchoolSecond?.start_time}}</p>
						</div>
						<div class="col-lg-3 col-md-2 col-sm-2 col-2">
							<p class="txt-regular">{{editAllDataSchoolSecond?.end_time}}</p>
						</div>
						<div class="col-lg-3 col-md-5 col-sm-5 col-5">
							<p class="txt-regular">{{editAllDataSchoolSecond?.booking_type}}</p>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-3 col-3">
							<p (click)="editSchoolTime('second-time')" class="text-regular-medium">Edit</p>
						</div>
					</div>
				</div>
				<div class="card px-15 mt-10">
					<div class="row align-items-center">
						<div class="col-lg-3 col-md-2 col-sm-2 col-2">
							<p class="txt-regular">{{editAllDataSchoolThird?.start_time}}</p>
						</div>
						<div class="col-lg-3 col-md-2 col-sm-2 col-2">
							<p class="txt-regular">{{editAllDataSchoolThird?.end_time}}</p>
						</div>
						<div class="col-lg-3 col-md-5 col-sm-5 col-5">
							<p class="txt-regular">{{editAllDataSchoolThird?.booking_type}}</p>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-3 col-3">
							<p (click)="editSchoolTime('third-time')" class="text-regular-medium">Edit</p>
						</div>
					</div>
				</div>
			</div>
			<p class="user-title mt-20">Break times</p>
			<p class="mt-10 txt-regular">Nominated break times are subtracted from hours worked in the payroll summary.
			</p>
			<p class="text-regular-bold text-right mb-10">
				<span (click)="addNewBreakTime()" class=" c-p h_b"> + Add break time </span>
			</p>
			<div class="history-header sticky  px-15">
				<div class="row align-items-center">
					<div class="col-lg-4 col-md-4 col-sm-4 col-3">
						<p class="txt-regular">Start</p>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-4 col-3">
						<p class="txt-regular">End</p>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-4 col-3">
						<p class="txt-regular">Options</p>
					</div>
				</div>
			</div>
			<div class="history-description">
				<div class="card px-15 mt-10" *ngFor="let item of editAllDataBreak; index as i">
					<div class="row align-items-center">
						<div class="col-lg-4 col-md-4 col-sm-4 col-3">
							<p class="txt-regular">{{item?.start_time}}</p>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-4 col-3">
							<p class="txt-regular">{{item?.end_time}}</p>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-4 col-6">
							<div class="d-flex">
								<p (click)="editModelOpen(item, i)" class="text-regular-medium c-p">Edit</p>
								<p (click)="removeBreakSchoolTime(i)" class="text-regular-medium ml-20 c-p">Delete</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-20" *ngIf="editType == 'booking-confirmation-form'">
		<div class="col-lg-9 col-12 col-md-9">
			<p class="txt-regular">Schools are encouraged to make the following fields mandatory, in order to assist
				with the payroll reporting. Simply click the tick box next to the field to highlight the selection. You
				can also customise the field
				names that appear in the booking forms and payroll report by entering new alternate text. Don’t forget
				to click save following any adjustments.
			</p>
			<form [formGroup]="bookingConfirmForm">
				<div class="mt-20">
					<div class="form-check">
						<input class="form-check-input c-p" type="checkbox" id="is_class_being_covered"
							formControlName="is_class_being_covered">
						<label class="form-check-label c-p" for="is_class_being_covered">
							Class being covered
						</label>
						<input type="text" class="form-control mt-10" placeholder="Class being covered"
							formControlName="class_being_covered_data">
					</div>
				</div>
				<div class="mt-20">
					<div class="form-check">
						<input class="form-check-input c-p" type="checkbox" id="is_teacher_being_covered"
							formControlName="is_teacher_being_covered">
						<label class="form-check-label c-p" for="is_teacher_being_covered">
							Staff being covered
						</label>
						<input type="text" class="form-control mt-10" placeholder="Staff being covered"
							formControlName="teacher_being_covered_data">
					</div>
				</div>
				<div class="form-check mt-20">
					<input class="form-check-input c-p" type="checkbox" id="confirmation_reason_absense"
						formControlName="confirmation_reason_absense">
					<label class="form-check-label c-p" for="confirmation_reason_absense">
						Reason for absence
					</label>
				</div>
				<div class="form-check mt-20">
					<input class="form-check-input c-p" type="checkbox" id="booking_without_communication"
						formControlName="booking_without_communication">
					<label class="form-check-label c-p" for="booking_without_communication">
						Prevent users from making bookings without verbal confirmation
					</label>
				</div>
				<button type="button" (click)="saveAdminSettings()" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
					Save changes
				</button>
			</form>
		</div>
	</div>
	<div class="row mt-20" *ngIf="editType == 'payroll-report-settings'">
		<div class="col-lg-9 col-12 col-md-9">
			<p class="txt-regular">Customise the headings for fields related to payroll in the booking from here </p>
			<form [formGroup]="payrollSettingsForm">
				<input type="text" class="form-control mt-20" placeholder="User Id"
					formControlName="payroll_field_label_1">
				<input type="text" class="form-control mt-20" placeholder="User name"
					formControlName="payroll_field_label_2">
				<input type="text" class="form-control mt-20" placeholder="Payroll info"
					formControlName="payroll_field_label_3">
				<p class="txt-regular mt-20">This field provides 'Yes', 'No' and empty options in a drop down menu </p>
				<input type="text" class="form-control mt-10" placeholder="Payroll info"
					formControlName="leave_approved_label">
				<button type="button" (click)="saveAdminSettings()" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
					Save changes
				</button>
			</form>
		</div>
	</div>
	<div class="row mt-10" *ngIf="editType == 'sms-settings'">
		<div class="col-lg-9 col-12 col-md-9">
			<form [formGroup]="smsSettingsForm">
				<label>Default maximum number of SMS invites</label>
				<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'" [multiple]="false"
					formControlName="sms_invite_limit" aria-label="start_hrs">
					<ng-option value="unlimited">unlimited</ng-option>
					<ng-option value="5">5</ng-option>
					<ng-option value="10">10</ng-option>
					<ng-option value="15">15</ng-option>
					<ng-option value="20">20</ng-option>
					<ng-option value="25">25</ng-option>
					<ng-option value="30">30</ng-option>
				</ng-select>
				<!-- <input type="text" class="form-control" placeholder="Default maximum number of SMS invites"
					formControlName="sms_invite_limit"> -->
				<p class="txt-regular mt-10">SMS Relieves if position has been filled option - Here the system can
					automitically notify the teachers that respond to accept the job after it has already been filled.
				</p>
				<div class="form-check mt-10">
					<input class="form-check-input c-p" type="checkbox" id="is_disable_sms"
						formControlName="is_disable_sms">
					<label class="form-check-label c-p" for="is_disable_sms">
						Disable this feature
					</label>
				</div>
				<button type="button" (click)="saveAdminSettings()" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
					Save changes
				</button>
			</form>
		</div>
	</div>
	<div class="row" *ngIf="editType == 'customCheckbox'">
		<div class="col-lg-9 col-12 col-md-9">
			<h1 class="user-title mt-20">Customise Checkbox</h1>
			<p class="txt-regular mt-10">Customise what you want this checkbox to say. You’ll be able to filter
				casuals
				in My List and while placing bookings.
			</p>

			<!-- <button type="button" (click)="customModal()" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
				Save changes
			</button> -->
			<form [formGroup]="customCheckboxForm">
				<input type="text" class="form-control mt-10" placeholder="Custom filed"
					formControlName="admin_custom_field">
				<div *ngIf="submitCheckboxForm">
					<span class="show-errors"
						*ngIf="customCheckboxForm.controls['admin_custom_field'].errors?.['required'] || customCheckboxForm.controls['admin_custom_field'].errors?.['whitespace']">*
						This field is required</span>
				</div>
				<button type="button" (click)="customModal()" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-20 btn-sm">
					Save changes
				</button>
			</form>
		</div>
	</div>
	<div class="row" *ngIf="editType == 'inAppCancel'">
		<div class="col-lg-9 col-12 col-md-9">
			<form [formGroup]="contactDetailsforCancelForm" #contactDetailsforCancel="ngForm">
				<h1 class="user-title mt-20">In-App Cancellations</h1>
				<p class="text-regular-bold mt-10">Toggling this on will allow for anyone booked by your school to be
					able to
					cancel bookings.</p>
				<div class="form-check form-switch">
					<div class="d-flex align-items-center">
						<input class="form-check-input delay-send mr-10 p-0" [ngModelOptions]="{standalone: true}"
							(change)="cancelBookingInApp($event)" [(ngModel)]="inAppCancelSwitch" type="checkbox"
							role="switch" id="in-app-cancel" />
						<label class="form-check-label" for="">The responsible school personnel will be
							notified via email and push notification when a booking has been cancelled.</label>
					</div>
				</div>
				<div *ngIf="showContactInfo">
					<div class="row">
						<div class="col-lg-6  col-md-6 col-sm-12  mb-5">
							<label>Contact number</label>
							<input type="text" formControlName="cancellation_phone" class="form-control">
							<div *ngIf="submittedInAppForm">
								<span class="show-errors"
									*ngIf="contactDetailsforCancelForm.controls['cancellation_phone'].errors?.['required'] || contactDetailsforCancelForm.controls['cancellation_phone'].errors?.['whitespace']">*
									This field is required</span>
							</div>
						</div>
						<div class="col-lg-6  col-md-6 col-sm-12 mb-5">
							<label>Email</label>
							<input type="text" formControlName="cancellation_email" class="form-control">
							<div *ngIf="submittedInAppForm">
								<span class="show-errors"
									*ngIf="contactDetailsforCancelForm.controls['cancellation_email'].errors?.['required'] || contactDetailsforCancelForm.controls['cancellation_email'].errors?.['whitespace']">*
									This field is required</span>
							</div>
						</div>
					</div>
				</div>
				<div class="form-check mt-10">
					<div class="">
						<input class="form-check-input mr-10 p-0" [ngModelOptions]="{standalone: true}"
							[(ngModel)]="automaticallyReplace" type="checkbox" id="autoRebook" />
						<label class="text-regular-bold mt-4" for="autoRebook">Automatically allow ClassCover to rebook when there’s a last minute cancellation</label>
					</div>
				</div>
				<p class="txt-regular">A new booking request will be automatically created and sent to anyone on your list that matches the job criteria. <a class="u_l clr-cc-vilot" href="https://www.classcover.com.au/terms-and-conditions/">Learn
						more</a></p>
				<button type="button" (click)="saveAdminSettings()" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
					Save changes
				</button>
			</form>
		</div>
	</div>
</div>
<!-- schoolTimeFirst Model -->
<ng-template #schoolTimeFirst>
	<form [formGroup]="editAllDataSchoolFirstForm">
		<div class="modal-header">
			<h4 class="modal-title" id="exampleModalLabel">Default School Times -
				{{editAllDataSchoolFirst.booking_type}}
			</h4>
			<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
		</div>
		<div class="modal-body pb-0 justify-content-center">
			<div class="col-md-12 col-sm-12 mt-10 d-flex custom-input-select placeContentCenter">
				<div class="form-control input-group" style="width:125px;">
					<div class="form-floating">
						<div class="d-flex mt-10">
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="first_form_start_time" aria-label="start_hrs">
								<ng-option *ngFor="let item of hours_d"
									value="{{item?.value}}">{{item?.title}}</ng-option>
							</ng-select>
							<span class="ml-10 mr-10">:</span>
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="first_form_end_time" aria-label="start_min">
								<ng-option *ngFor="let items of minutes_d"
									value="{{items?.value}}">{{items?.title}}</ng-option>
							</ng-select>
						</div>
						<label for="floatingSelect">Start time</label>
					</div>
				</div>
				<div class="form-control ml-3 input-group" style="width:125px;">
					<div class="form-floating">
						<div class="d-flex mt-10">
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="one_form_start_time" aria-label="end_hrs">
								<ng-option *ngFor="let item of hours_d"
									value="{{item?.value}}">{{item?.title}}</ng-option>
							</ng-select>
							<span class="ml-10 mr-10">:</span>
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="one_form_end_time" aria-label="end_min">
								<ng-option *ngFor="let items of minutes_d"
									value="{{items?.value}}">{{items?.title}}</ng-option>
							</ng-select>
						</div>
						<label for="floatingSelect">End time</label>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer mt-10">
			<button type="button" (click)="saveEditSchoolTime('first-time')" class="btn cc_p_btn fs_13">Confirm</button>
		</div>
	</form>
</ng-template>
<!-- schoolTimeSecond Model -->
<ng-template #schoolTimeSecond>
	<form [formGroup]="editAllDataSchoolSecondForm">
		<div class="modal-header">
			<h4 class="modal-title" id="exampleModalLabel">Default School Times -
				{{editAllDataSchoolSecond.booking_type}}
			</h4>
			<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
		</div>
		<div class="modal-body pb-0">
			<div class="col-md-12 col-sm-12 mt-10 d-flex custom-input-select placeContentCenter">
				<div class="form-control input-group" style="width: 125px;">
					<div class="form-floating">
						<div class="d-flex mt-10">
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="second_start_time" aria-label="start_hrs">
								<ng-option *ngFor="let item of hours_d"
									value="{{item?.value}}">{{item?.title}}</ng-option>
							</ng-select>
							<span class="ml-10 mr-10">:</span>
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="second_end_time" aria-label="start_min">\
								<ng-option *ngFor="let items of minutes_d"
									value="{{items?.value}}">{{items?.title}}</ng-option>
							</ng-select>
						</div>
						<label for="floatingSelect">Start time</label>
					</div>
				</div>
				<div class="form-control ml-3 input-group" style="width: 125px;">
					<div class="form-floating">
						<div class="d-flex mt-10">
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="two_start_time" aria-label="end_hrs">
								<ng-option *ngFor="let item of hours_d"
									value="{{item?.value}}">{{item?.title}}</ng-option>
							</ng-select>
							<span class="ml-10 mr-10">:</span>
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="two_end_time" aria-label="end_min">
								<ng-option *ngFor="let items of minutes_d"
									value="{{items?.value}}">{{items?.title}}</ng-option>
							</ng-select>
						</div>
						<label for="floatingSelect">End time</label>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer mt-10">
			<button type="button" (click)="saveEditSchoolTime('second-time')"
				class="btn cc_p_btn fs_13">Confirm</button>
		</div>
	</form>
</ng-template>
<!-- schoolTimeThird Model -->
<ng-template #schoolTimeThird>
	<form [formGroup]="editAllDataSchoolThirdForm">
		<div class="modal-header">
			<h4 class="modal-title" id="exampleModalLabel">Default School Times -
				{{editAllDataSchoolThird.booking_type}}
			</h4>
			<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
		</div>
		<div class="modal-body pb-0">
			<div class="col-md-12 col-sm-12 mt-10 d-flex custom-input-select placeContentCenter">
				<div class="form-control input-group" style="width: 125px;">
					<div class="form-floating">
						<div class="d-flex mt-10">
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="third_start_time" aria-label="start_hrs">
								<ng-option *ngFor="let item of hours_d"
									value="{{item?.value}}">{{item?.title}}</ng-option>
							</ng-select>
							<span class="ml-10 mr-10">:</span>
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="third_end_time" aria-label="start_min">
								<ng-option *ngFor="let items of minutes_d"
									value="{{items?.value}}">{{items?.title}}</ng-option>
							</ng-select>
						</div>
						<label for="floatingSelect">Start time</label>
					</div>
				</div>
				<div class="form-control ml-3 input-group" style="width: 125px;">
					<div class="form-floating">
						<div class="d-flex mt-10">
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="three_start_time" aria-label="end_hrs">
								<ng-option *ngFor="let item of hours_d"
									value="{{item?.value}}">{{item?.title}}</ng-option>
							</ng-select>
							<span class="ml-10 mr-10">:</span>
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="three_end_time" aria-label="end_min">
								<ng-option *ngFor="let items of minutes_d"
									value="{{items?.value}}">{{items?.title}}</ng-option>
							</ng-select>
						</div>
						<label for="floatingSelect">End time</label>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer mt-10">
			<button type="button" (click)="saveEditSchoolTime('third-time')" class="btn cc_p_btn fs_13">Confirm</button>
		</div>
	</form>
</ng-template>
<!-- Add Break Time Model -->
<ng-template #addBreakTimeModel>
	<form [formGroup]="addBreakTimeForm">
		<div class="modal-header">
			<h4 class="modal-title" id="exampleModalLabel">Add Break Time</h4>
			<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
		</div>
		<div class="modal-body pb-0">
			<div class="col-md-12 col-sm-12 mt-10 d-flex custom-input-select placeContentCenter">
				<div class="form-control input-group" style="width: 125px;">
					<div class="form-floating">
						<div class="d-flex mt-10">
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="add_start_time" aria-label="start_hrs">
								<ng-option *ngFor="let item of hours_d"
									value="{{item?.value}}">{{item?.title}}</ng-option>
							</ng-select>
							<span class="ml-10 mr-10">:</span>
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="add_end_time" aria-label="start_min">
								<ng-option *ngFor="let items of minutes_d"
									value="{{items?.value}}">{{items?.title}}</ng-option>
							</ng-select>
						</div>
						<label for="floatingSelect">Start time</label>
					</div>
				</div>
				<div class="form-control ml-3 input-group" style="width:125px;">
					<div class="form-floating">
						<div class="d-flex mt-10">
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="plus_start_time" aria-label="end_hrs">
								<ng-option *ngFor="let item of hours_d"
									value="{{item?.value}}">{{item?.title}}</ng-option>
							</ng-select>
							<span class="ml-10 mr-10">:</span>
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="plus_end_time" aria-label="end_min">
								<ng-option *ngFor="let items of minutes_d"
									value="{{items?.value}}">{{items?.title}}</ng-option>
							</ng-select>
						</div>
						<label for="floatingSelect">End time</label>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer mt-10">
			<button type="button" (click)="addBreakSchoolTime()" class="btn cc_p_btn fs_13">Save</button>
		</div>
	</form>
</ng-template>
<!-- Edit Break Time Model -->
<ng-template #editBreakTimeModel>
	<form [formGroup]="editBreakTimeForm">
		<div class="modal-header">
			<h4 class="modal-title" id="exampleModalLabel">Edit Break Time</h4>
			<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
		</div>
		<div class="modal-body pb-0">
			<div class="col-md-12 col-sm-12 mt-10 d-flex custom-input-select placeContentCenter">
				<div class="form-control input-group" style="width: 125px;">
					<div class="form-floating">
						<div class="d-flex mt-10">
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="edit_start_time" aria-label="start_hrs">
								<ng-option *ngFor="let item of hours_d"
									value="{{item?.value}}">{{item?.title}}</ng-option>
							</ng-select>
							<span class="ml-10 mr-10">:</span>
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="edit_end_time" aria-label="start_min">
								<ng-option *ngFor="let items of minutes_d"
									value="{{items?.value}}">{{items?.title}}</ng-option>
							</ng-select>
						</div>
						<label for="floatingSelect">Start time</label>
					</div>
				</div>
				<div class="form-control ml-3 input-group" style="width: 125px;">
					<div class="form-floating">
						<div class="d-flex mt-10">
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="second_edit_start_time" aria-label="end_hrs">
								<ng-option *ngFor="let item of hours_d"
									value="{{item?.value}}">{{item?.title}}</ng-option>
							</ng-select>
							<span class="ml-10 mr-10">:</span>
							<ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
								[multiple]="false" formControlName="second_edit_end_time" aria-label="end_min">
								<ng-option *ngFor="let items of minutes_d"
									value="{{items?.value}}">{{items?.title}}</ng-option>
							</ng-select>
						</div>
						<label for="floatingSelect">End time</label>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer mt-10">
			<button type="button" (click)="editBreakSchoolTime()" class="btn cc_p_btn fs_13">Save</button>
		</div>
	</form>
</ng-template>
<!-- Update Staff Absence List Model -->
<ng-template #updateStaffAbsenceListmodel>
	<div class="modal-header">
		<h4 class="modal-title" id="exampleModalLabel">Staff Reason For Absence</h4>
		<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
	</div>
	<div class="modal-body mt-10 pb-0">
		<div class="row align-items-center mt-10" *ngFor="let todo of leaveList; let i = index;">
			<div class="col-md-11">
				<input type="text" [value]="todo" (change)="onChangeValue($event, i)" class="form-control">
			</div>
			<div class="col-md-1">
				<i (click)="removeStaffAbsenceList(i)" class="bi bi-trash c-p h_b"></i>
			</div>
		</div>
		<form [formGroup]="updateStaffAbsenceListForm">
			<div formArrayName="quantities">
				<div class="row align-items-center" *ngFor="let quantity of quantities().controls; let i=index"
					[formArrayName]="i">
					<div class="col-md-11">
						<input type="text" id="first-list-id" formControlName="new_leave_reason" class="form-control">
					</div>
					<div class="col-md-1">
						<i (click)="removeTempStaffAbsent(i)" class="bi bi-trash h_b c-p"></i>
					</div>
				</div>
			</div>
			<button type="button" (click)="addQuantity()" class="btn fs-13 cc_p_btn mt-10">Add Reason</button>
		</form>
	</div>
	<div class="modal-footer mt-10">
		<button type="button" (click)="updateStaffAbsenceListSave()" class="btn fs-13 cc_p_btn">Save</button>
	</div>
</ng-template>
<!-- Invite New Member -->
<ng-template #inviteNewMemberModel>
	<div class="modal-header">
		<h4 class="modal-title">Additional user</h4>
		<button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
	</div>
	<form [formGroup]="newInviteMemberStaff">
		<div class="modal-body mt-10 pb-0">
			<div class="row">
				<div class="col-md-6 mt-10">
					<input type="text" formControlName="new_staff_first_name" placeholder="First name"
						class="form-control">
					<div *ngIf="submittedStaffDetails">
						<span class="show-errors"
							*ngIf="i['new_staff_first_name'].errors?.['required'] || i['new_staff_first_name'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
				<div class="col-md-6 mt-10">
					<input type="text" formControlName="new_staff_last_name" placeholder="Last name"
						class="form-control">
					<div *ngIf="submittedStaffDetails">
						<span class="show-errors"
							*ngIf="i['new_staff_last_name'].errors?.['required'] || i['new_staff_last_name'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
				<div class="col-md-6 mt-10">
					<input type="text" formControlName="new_staff_email" placeholder="Email" class="form-control">
					<div *ngIf="submittedStaffDetails">
						<span class="show-errors"
							*ngIf="i['new_staff_email'].errors?.['required'] || i['new_staff_email'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
				<div class="col-md-6 mt-10">
					<input type="text" formControlName="new_staff_phone_number" placeholder="Phone number"
						class="form-control">
					<div *ngIf="submittedStaffDetails">
						<span class="show-errors"
							*ngIf="i['new_staff_phone_number'].errors?.['required'] || i['new_staff_phone_number'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
			</div>
		</div>
	</form>
	<div class="modal-footer mt-10">
		<button type="button" (click)="addNewStaffSave()" class="btn cc-blue-btn">Save</button>
	</div>
</ng-template>
<ng-template #resendPendingInviteModal>
	<div class="modal-header">
		<h4 class="modal-title" id="exampleModalLabel">Additional user
		</h4>
		<button type="button" (click)="closeAddUserModal()" class="btn-close" aria-label="Close"></button>
	</div>
	<form [formGroup]="additionalUserForm">
		<div class="modal-body pb-0">
			<div class="row align-items-center">
				<div class="col-md-12 mt-10">
					<input class="form-control" placeholder="First name " formControlName="first_name">
				</div>
				<div class="col-md-12 mt-10">
					<input class="form-control" placeholder="Last name" formControlName="last_name">
				</div>
				<div class="col-md-12 mt-10">
					<input class="form-control" placeholder="Email" formControlName="email">
					<div *ngIf="submittedAdduser">
						<span class="show-errors"
							*ngIf="ad['email'].errors?.['required'] || ad['email'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
				<div class="col-md-12 mt-10">
					<input class="form-control" placeholder="Phone number" formControlName="phone">
					<div *ngIf="submittedAdduser">
						<span class="show-errors"
							*ngIf="ad['phone'].errors?.['required'] || ad['phone'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
			</div>
		</div>
	</form>
	<div [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : ''"
		class="modal-footer mt-10">
		<button type="button" (click)="addAdditionalUser()" class="btn cc_p_btn  fs_13">Add user </button>
	</div>
</ng-template>
<ng-template #removeAddUserModal>
	<div class="modal-header">
		<h4 class="modal-title" id="exampleModalLabel">Confirmation</h4>
		<button type="button" (click)="this.bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
	</div>
	<form [formGroup]="additionalUserForm">
		<div class="modal-body pb-0">
			<div class="row align-items-center">
				<div class="col-md-12">
					<p class="txt-regular">Are you sure you want to remove <b>{{userName}}</b>?</p>
				</div>
			</div>
		</div>
	</form>
	<div [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : ''" class="modal-footer">
		<button (click)="removeAdd(removeId)" type="button" class="btn cc_p_btn  fs_13">Remove</button>
	</div>
</ng-template>
<ng-template #customCheckboxModal>
	<div class="modal-header">
		<h4 class="modal-title" id="exampleModalLabel">Clear previous checks?</h4>
		<button type="button" (click)="closeCustomModal()" class="btn-close" aria-label="Close"></button>
	</div>
	<form [formGroup]="customCheckboxForm">
		<div class="modal-body pb-0">
			<div class="row align-items-center">
				<div class="col-md-12">
					<p class="txt-regular">Do you want to clear the previous checks you had marked as Booked before?</p>
				</div>
			</div>
			<div class="mt-10">
				<div class="form-check">
					<input class="form-check-input mt-4" (change)="makeTrue()" formControlName="change_checked" value="0" type="radio"
						name="change_checked" id="leave_as_is" />
					<label class="form-check-label" for="leave_as_is">
						Leave them checked as is
					</label>
				</div>
				<div class="form-check mt-5">
					<input class="form-check-input  mt-4" (change)="makeTrue()" formControlName="change_checked" value="1" type="radio"
						name="change_checked" id="clear_previous" />
					<label class="form-check-label" for="clear_previous">
						Clear previous checks
					</label>
				</div>
				<div *ngIf="submitCheckboxForm">
					<span class="show-errors">*
						This field is required</span>
				</div>
			</div>
		</div>
	</form>
	<div [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : ''" class="modal-footer">
		<button (click)="saveAdminSettings()" type="button" class="btn cc_p_btn  fs_13">Update</button>
	</div>
</ng-template>
<ng-template #editSelectedStaff>
	<div class="modal-header">
		<h4 class="modal-title" id="exampleModalLabel">
			<span *ngIf="!selectAll">
				{{selectedStaff?.additional_user?.user?.full_name}}
			</span>
			<span *ngIf="selectAll">
				Edit Permission and access
			</span>
		</h4>
		<button type="button" (click)="this.bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
	</div>
	<form [formGroup]="manageTeamAccessForm" #manageTeamAccess="ngForm">
		<div class="modal-body pb-0">
			<div class="row align-items-center">
				<div class="col-md-12 mt-10" *ngIf="!selectAll">
					<label>Email</label>
					<input class="form-control" placeholder="Email"
						value="{{selectedStaff?.additional_user?.user?.email}}" disabled>
				</div>
				<div class="col-md-12 mt-10 ">
					<div class="mb-10">
						<label>Role</label>
					</div>
					<div class="form-check form-check-inline" *ngFor="let roleData of roleList; index as i;">
						<input class="form-check-input c-p" type="radio" name="role_id" id="{{roleData?.id}}"
							formControlName="role_id" [value]="roleData?.id">
						<label class="form-check-label c-p" for="{{roleData?.id}}">
							{{roleData?.role}}
						</label>

					</div>
					<div *ngIf="manageTeamAccess.submitted">
						<span class="show-errors"
							*ngIf="manageTeamAccessForm.controls['role_id'].errors?.['required'] || manageTeamAccessForm.controls['role_id'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
				<div class="col-md-12 mt-10">
					<div class="mb-10">
						<label>Permissions</label>
					</div>
					<div class="form-check form-check-inline" *ngFor="let permission of permissionsList; index as i;">
						<input class="form-check-input c-p" type="radio" name="permission" id="{{permission?.tag}}"
							value="{{permission?.tag}}" formControlName="permission">
						<label class="form-check-label c-p" for="{{permission?.tag}}">{{permission?.name}}</label>
					</div>
					<div *ngIf="manageTeamAccess.submitted">
						<span class="show-errors"
							*ngIf="manageTeamAccessForm.controls['permission'].errors?.['required'] || manageTeamAccessForm.controls['permission'].errors?.['whitespace']">*
							This field is required</span>
					</div>
				</div>
			</div>
			<div class="col-md-12 mt-10 text-center">
				<button type="submit" class="btn cc_p_btn" (click)="makeAdminRta()">
					Save
				</button>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #RemovestaffConfirmation>
	<div class="modal-header">
		<h4 class="model_headings" id="editProfileInformationModal">
			Confirmation
		</h4>
		<button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
	</div>
	<div class="modal-body pb-0">
		<p>Are you sure you want to remove selected?</p>
	</div>
	<div class="modal-footer">
		<button type="submit" (click)="removeStaff()" class="btn cc_p_btn">
			Remove
		</button>
	</div>
</ng-template>
<ng-template #inAppCancelModal>
	<div class="modal-header">
		<h4 class="model_headings" id="editProfileInformationModal">
			Update school contact for cancellations
		</h4>
		<button type="button" (click)="closeInAppModal()" class="btn-close" aria-label="Close"></button>
	</div>
	<div class="modal-body pb-0">
		<form [formGroup]="contactDetailsforCancelForm" #contactDetailsforCancel="ngForm">
			<div class="col-lg-12">
				<label>Contact number</label>
				<input type="text" formControlName="cancellation_phone" class="form-control">
				<div *ngIf="submittedInAppForm">
					<span class="show-errors"
						*ngIf="contactDetailsforCancelForm.controls['cancellation_phone'].errors?.['required'] || contactDetailsforCancelForm.controls['cancellation_phone'].errors?.['whitespace']">*
						This field is required</span>
				</div>
			</div>
			<div class="col-lg-12 mt-5">
				<label>Email</label>
				<input type="text" formControlName="cancellation_email" class="form-control">
				<div *ngIf="submittedInAppForm">
					<span class="show-errors"
						*ngIf="contactDetailsforCancelForm.controls['cancellation_email'].errors?.['required'] || contactDetailsforCancelForm.controls['cancellation_email'].errors?.['whitespace']">*
						This field is required</span>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer mt-10">
		<!-- <p class="txt-regular c-p mr-10" (click)="closeInAppModal()">Cancel</p> -->
		<button type="submit" (click)="sendInAppCancelData()" class="btn cc_p_btn fs-13">
			Save
		</button>
	</div>
</ng-template>