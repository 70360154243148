<div class=" pt-15 pad-b jobs_only_user job_subs_page">
    
    <form [formGroup]="subscriptionForm">
        <div class="">
            <div class="row">
                <div class="col-lg-12 col-12 col-md-12 back-jobs  d-flex">
                    <p class="text-regular-bold clr-cc-vilot  c-p" routerLink="/school/jobs" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}"><i class="bi bi-arrow-left-short"></i> Back to jobs
                    </p>
                </div>
            </div>
            <div class="row mt-10" *ngIf="(this.schoolProps.mixpanel_data.is_subscription_expired && this.filtersArray?.is_subscription_expired) && !closeExpired">
                <div class="col-lg-12">
                    <div class="expired-card px-15">
                        <span class="close-expiredModal" (click)="closeExpiredModal()"><i class="bi bi-x-lg"></i></span>
                        <p class="user-title clr-red"><i class="bi bi-exclamation-circle-fill mr-4"></i> Monthly starter Subscription expired</p>
                        <p class="txt-regular mt-10 clr-red">Your monthly starter subscription to ClassCover Jobs ended on {{this.filtersArray.valid_date}}. Re-subscribe to continue using ClassCover Jobs.</p>
                    </div>
                </div>
            </div>
        
            <div class="row mt-10 justify-content-center">
                <div class="col-lg-6 col-12 col-md-12">
                    <p class="choose_p text-center">Choose The Right Plan for You</p>
                    <!-- <p class="choose_s_p text-center mt-10">Whether you are hiring for one role or need an ongoing
                        solution,
                        we
                        have a plan to suit you.</p> -->
                    <div class="d-flex justify-content-center align-items-center mt-30">
                        <span class="bill_type_t mr-10" [ngClass]="monthlyBilling ? 'active_t' : ''">Billed
                            Monthly</span>
                        <span>
                            <div class="form-check form-switch">
                                <input class="form-check-input delay-send" [ngModelOptions]="{standalone: true}"
                                    (change)="changeBillType($event)" [(ngModel)]="billing_type" type="checkbox"
                                    role="switch" id="delay-send">
                            </div>
                        </span>
                        <span class="bill_type_t  ml-10" [ngClass]="annualBilling ? 'active_t' : ''">Billed
                            Annually</span>
                    </div>
                </div>
                <div class="row mt-10">
                    <div class="col-container">
                        <div class="row  mt-10">
                            <div class="col-md-6 col-lg-4  d-flex">
                                <div class="card cardHeight single-card mt-30"
                                    [ngClass]="{disableCard : hideSinglePost , highlight : selectedSingle}">
                                    <p class="subs_head">
                                        {{filtersArray?.price_plan?.Single_Post?.name}}</p>
                                    <ul class="col">
                                        <li><i class="bi bi-check2"></i> {{filtersArray?.price_plan?.Single_Post?.ad_credit}} job ad credit</li>
                                        <li><i class="bi bi-check2"></i> Pay as you go.
                                            ${{filtersArray?.price_plan?.Single_Post?.price}} per job post</li>
                                        <li><i class="bi bi-check2"></i> Unlimited job locations
                                        </li>
                                        <li><i class="bi bi-check2"></i> Access to headhunter. Search through local
                                            talent and invite to jobs</li>
                                    </ul>


                                    <div class="bottom_div" *ngIf="!hideSinglePost , "  (click)="selectplan('singlePost')">
                                        <button *ngIf="!selectedSingle" class="btn btn_jobs_sec mt-10">Buy a job credit
                                            <!-- ${{filtersArray?.price_plan?.Single_Post?.price}} -->
                                        </button>
                                        <button *ngIf="selectedSingle" class="btn btn_jobs_sec sec mt-10"><i
                                                class="bi bi-check2"></i> Selected</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4  d-flex">
                                <div class="card fixed-height mtOnRes" *ngIf="monthlyBilling"
                                    [ngClass]="{ highlight : (selectedMonthlyStarter || activeMonthly) , activeplan : this.filtersArray?.current_plan == 'monthly_starter' , disableCard: this.filtersArray?.is_plan_active && filtersArray?.current_plan == 'monthly_premium' || this.filtersArray?.current_plan == 'annual_premium'  || this.filtersArray?.current_plan == 'annual_starter'}">
                                    <p *ngIf="monthlyBilling" class="subs_head">
                                        {{filtersArray?.price_plan?.Monthly_Starter?.name}} <span
                                            class="promote_t user-sub-title">Popular</span>
                                    </p>
                                    <!-- <p class="card-head mt-10">Great for individual schools</p> -->
                                    <ul class="mt-5 col">
                                        <li><i class="bi bi-check2"></i> {{filtersArray?.price_plan?.Monthly_Starter?.ad_credit}} job ad credits per month</li>
                                        <li><i class="bi bi-check2"></i> Buy additional credits for ${{filtersArray?.price_plan?.Monthly_Starter?.job_with_subscription_aud}}</li>
                                        <li><i class="bi bi-check2"></i> Unlimited job locations
                                        </li>
                                        <li><i class="bi bi-check2"></i> Access to headhunter. Search through local
                                            talent and invite to jobs</li>
                                    </ul>

                                    <div class="bottom_div">
                                        <p><span class="dol_sym">$</span><span
                                                class="price_t">{{filtersArray?.price_plan?.Monthly_Starter?.price}}</span>
                                            <span class="price_ty_t">
                                                /month</span>
                                        </p>
                                        <p class="bill_t">When billed monthly</p>
                                        <div>
                                            <div *ngIf="this.filtersArray?.current_plan != 'monthly_starter' && this.filtersArray?.current_plan != 'monthly_premium' && this.filtersArray?.current_plan != 'annual_premium' && this.filtersArray?.current_plan != 'annual_starter'"
                                                (click)="selectplan('monthlyStarter')">
                                                <button
                                                    *ngIf="!selectedMonthlyStarter && this.filtersArray?.current_plan != 'monthly_starter'"
                                                    class="btn btn_jobs_sec mt-10">
                                                    Select plan
                                                </button>
                                                <button
                                                    *ngIf="selectedMonthlyStarter && this.filtersArray?.current_plan != 'monthly_starter'"
                                                    class="btn btn_jobs_sec sec mt-10">
                                                    <i class="bi bi-check2"></i> Selected
                                                </button>
                                            </div>
                                            <!-- <div
                                                *ngIf="this.filtersArray?.is_plan_active && filtersArray?.current_plan == 'monthly_premium' || this.filtersArray?.current_plan == 'annual_premium'  || this.filtersArray?.current_plan == 'annual_starter'">
                                                <button class="btn btn_jobs_sec mt-10 disabled">
                                                    Choose
                                                </button>
                                            </div> -->
                                            <div
                                                *ngIf="this.filtersArray?.current_plan == 'monthly_starter' && this.filtersArray?.is_plan_active && monthlyStarterPlan == true">
                                                <button class="btn btn_jobs_sec mt-10">
                                                    Current active plan
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mtOnRes fixed-height" *ngIf="annualBilling == true"
                                    [ngClass]="{ highlight : (selectedAnnualStarter || activeMonthly) , activeplan : this.filtersArray?.current_plan == 'annual_starter' , disableCard : this.filtersArray?.is_plan_active && this.filtersArray?.current_plan == 'annual_premium'}">
                                    <p class="subs_head">
                                        {{filtersArray?.price_plan?.Annual_Starter?.name}} <span
                                            class="promote_t user-sub-title">Popular</span>
                                    </p>
                                    <!-- <p class="card-head mt-10">Great for individual schools</p> -->
                                    <ul class="mt-5 col">
                                        <li><i class="bi bi-check2"></i> {{filtersArray?.price_plan?.Annual_Starter?.ad_credit}} job ad credits per month</li>
                                        <li><i class="bi bi-check2"></i> Buy additional credits for ${{filtersArray?.price_plan?.Annual_Starter?.job_with_subscription_aud}}</li>
                                        <li><i class="bi bi-check2"></i> Unlimited job locations
                                        </li>
                                        <li><i class="bi bi-check2"></i> Access to headhunter. Search through local
                                            talent and invite to jobs</li>
                                    </ul>

                                    <div class="bottom_div">
                                        <p><span class="dol_sym">$</span><span
                                                class="price_t">{{filtersArray?.price_plan?.Annual_Starter?.price}}</span>
                                            <span class="price_ty_t">
                                                /month</span>
                                        </p>
                                        <p class="bill_t">When billed annualy</p>
                                        <div>
                                            <div *ngIf="this.filtersArray?.current_plan != 'annual_starter' && this.filtersArray?.current_plan != 'annual_premium'"
                                                (click)="selectplan('AnnualStarter')">
                                                <button
                                                    *ngIf="!selectedAnnualStarter && this.filtersArray?.current_plan != 'annual_starter'"
                                                    class="btn btn_jobs_sec mt-10">
                                                    Select plan
                                                </button>
                                                <button
                                                    *ngIf="selectedAnnualStarter && this.filtersArray?.current_plan != 'annual_starter'"
                                                    class="btn btn_jobs_sec sec mt-10">
                                                    <i class="bi bi-check2"></i> Selected
                                                </button>
                                            </div>
                                            <!-- <div *ngIf="this.filtersArray?.current_plan == 'annual_premium'">
                                                <button
                                                    *ngIf="!selectedAnnualStarter && this.filtersArray?.current_plan != 'annual_starter'"
                                                    class="btn btn_jobs_sec mt-10 disabled">
                                                    Choose
                                                </button>  
                                            </div> -->
                                            <div
                                                *ngIf="this.filtersArray?.current_plan == 'annual_starter' && monthlyStarterPlan == true">
                                                <button class="btn btn_jobs_sec mt-10">
                                                    Current active plan
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4 d-flex">
                                <div class="card cardHeight mt-30" *ngIf="monthlyBilling == true"
                                    [ngClass]="{ highlight : selectedMonthlyPremium , activeplan : this.filtersArray?.current_plan == 'monthly_premium' , disableCard : this.filtersArray?.is_plan_active && (this.filtersArray?.current_plan == 'annual_starter' || this.filtersArray?.current_plan == 'annual_premium')}">
                                    <p class="subs_head">
                                        {{filtersArray?.price_plan?.Monthly_Premium?.name}}
                                    </p>
                                    <!-- <p class="card-head mt-10">Great for departments and schools with multiple
                                        locations.
                                    </p> -->
                                    <ul class="mt-5 col">
                                        <li><i class="bi bi-check2"></i> Post unlimited jobs</li>
                                        <li><i class="bi bi-check2"></i> Unlimited job locations</li>
                                        <li><i class="bi bi-check2"></i> Access to headhunter. Search through local
                                            talent and invite to jobs</li>
                                    </ul>
                                    <div class="bottom_div">
                                        <p><span class="dol_sym">$</span><span
                                                class="price_t">{{filtersArray?.price_plan?.Monthly_Premium?.price}}</span>
                                            <span class="price_ty_t">
                                                /month</span>
                                        </p>
                                        <p class="bill_t">When billed monthly</p>
                                        <div *ngIf="this.filtersArray?.current_plan != 'monthly_premium' && this.filtersArray?.current_plan != 'annual_premium'  && this.filtersArray?.current_plan != 'annual_starter'"
                                            (click)="selectplan('monthlyPremium')">
                                            <button
                                                *ngIf="!selectedMonthlyPremium && this.filtersArray?.current_plan != 'monthly_premium'"
                                                class="btn btn_jobs_sec mt-10">Select plan</button>
                                            <button
                                                *ngIf="selectedMonthlyPremium && this.filtersArray?.current_plan != 'monthly_premium'"
                                                class="btn btn_jobs_sec sec mt-10"><i class="bi bi-check2"></i>
                                                Selected</button>
                                        </div>
                                        <div
                                            *ngIf="this.filtersArray?.current_plan == 'annual_premium'  || this.filtersArray?.current_plan == 'annual_starter'">
                                            <!-- <button
                                            *ngIf="!selectedMonthlyPremium && this.filtersArray?.current_plan != 'monthly_premium'"
                                            class="btn btn_jobs_sec mt-10 disabled">Choose</button> -->

                                        </div>
                                        <div *ngIf="this.filtersArray?.current_plan == 'monthly_premium'">
                                            <button class="btn btn_jobs_sec mt-10">
                                                Current active plan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card cardHeight mt-30" *ngIf="annualBilling"
                                    [ngClass]="{ highlight : selectedAnnualPremium , activeplan : this.filtersArray?.current_plan == 'annual_premium'}">
                                    <p *ngIf="annualBilling" class="subs_head">
                                        {{filtersArray?.price_plan?.Annual_Premium?.name}}
                                    </p>
                                    <!-- <p class="card-head mt-10">Great for departments and schools with multiple
                                        locations.
                                    </p> -->
                                    <ul class="mt-5 col">
                                        <li><i class="bi bi-check2"></i> Post unlimited jobs</li>
                                        <li><i class="bi bi-check2"></i> Unlimited job locations</li>
                                        <li><i class="bi bi-check2"></i> Access to headhunter. Search through local
                                            talent and invite to jobs</li>
                                    </ul>
                                    <div class="bottom_div">
                                        <p><span class="dol_sym">$</span><span
                                                class="price_t">{{filtersArray?.price_plan?.Annual_Premium?.price}}</span>
                                            <span class="price_ty_t">
                                                /month</span>
                                        </p>
                                        <p class="bill_t">When billed annualy</p>
                                        <div *ngIf="this.filtersArray?.current_plan != 'annual_premium'"
                                            (click)="selectplan('AnnualPremium')">
                                            <button
                                                *ngIf="!selectedAnnualPremium && this.filtersArray?.current_plan != 'annual_premium'"
                                                class="btn btn_jobs_sec mt-10">Select plan</button>
                                            <button
                                                *ngIf="selectedAnnualPremium && this.filtersArray?.current_plan != 'annual_premium'"
                                                class="btn btn_jobs_sec sec mt-10"><i class="bi bi-check2"></i>
                                                Selected</button>
                                        </div>
                                        <div *ngIf="this.filtersArray?.current_plan == 'annual_premium'">
                                            <button class="btn btn_jobs_sec mt-10">
                                                Current active plan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-30" *ngIf="this.checkBoost != undefined">
                <div class="">
                    <div class="col-lg-12">
                        <div class="card boost_jobs_card px-0">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img class="boost_img" src="assets/images/jobs/job_only_user/Boost_y.png">
                                </div>
                                <div class="px-15">
                                    <h4 class="boost_heading">Boost your job to reach more educators</h4>
                                    <p class="txt-regular mt-5">Boosted job posts sit at the top of
                                        search results and are sent directly to the inbox of educators
                                        in your area.</p>
                                    <div class="form-check mt-5" *ngIf="filtersArray?.free_boost">
                                        <input class="form-check-input c-p" type="checkbox" id="boost_job"
                                            [ngModel]="is_boost" [ngModelOptions]="{standalone: true}"
                                            (change)="setBoostValue($event)">
                                        <label class="form-check-label c-p m-0" for="boost_job">
                                            Boost job
                                        </label>
                                    </div>
                                    <div class="form-check mt-5" *ngIf="!filtersArray?.free_boost">
                                        <input class="form-check-input c-p" type="checkbox" id="boost_job"
                                            [ngModel]="is_boost" [ngModelOptions]="{standalone: true}"
                                            (change)="setBoostValue($event)">
                                        <label class="form-check-label c-p m-0" for="boost_job">
                                            Boost job for ${{filtersArray?.price_plan?.Boost_Job?.price}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-15">
                <div class="">
                    <div class="col-lg-12">
                        <button
                            [ngClass]="selectedMonthlyStarter || selectedSingle || selectedMonthlyPremium  || selectedAnnualStarter || selectedAnnualPremium ? '' : 'disabled'"
                            (click)="callChargebee()"
                            class="btn cc_p_btn text-regular-bold-w mt-10 float-right">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>