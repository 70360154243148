import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
@Component({
  selector: 'app-card-profile-view',
  templateUrl: './card-profile-view.component.html',
  styleUrls: ['./card-profile-view.component.scss']
})
export class CardProfileViewComponent implements OnInit {
  @Input() TeacherProfile: any;
  selectedList: Array<any> = [];
  selectedTeacherList: Array<any> = [];
  isMasterSel: boolean = false
  checkBoxSelectedTeacher: any = [];
  categoryList: any;
  checkedCategoryList: any
  @ViewChildren("checkboxes") checkboxes!: QueryList<ElementRef>

  constructor() { }

  ngOnInit(): void {
  }

  isAllSelected() {
    this.isMasterSel = this.categoryList.every(function(TeacherProfile:any) {
        return TeacherProfile.isSelected == true;
      })
    this.getCheckedItemList();
  }
   
  getCheckedItemList(){
    this.checkedCategoryList = [];
    for (var i = 0; i < this.categoryList.length; i++) {
      if(this.categoryList[i].isSelected)
      this.checkedCategoryList.push(this.categoryList[i]);
    }
    this.checkedCategoryList = JSON.stringify(this.checkedCategoryList);
  }

  addToSelectedList(event: any) {
    if (!this.checkBoxSelectedTeacher.includes(event)) {
      this.checkBoxSelectedTeacher = [...this.checkBoxSelectedTeacher, event];
    } else {
      this.checkBoxSelectedTeacher = this.checkBoxSelectedTeacher.filter((item: any) => item !== event);
    }
  }
}
