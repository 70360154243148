<div class="pending-request-page pb-30 pt-15 ">
    <div class="row">
        <!-- Pending Request Tabs -->
        <div class="col-lg-9 col-12 mb-20">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link" [ngClass]="{ 'active':activeTab==='pending'}" (click)="invites('pending')"
                        id="nav-invite-tab" data-bs-toggle="tab" data-bs-target="#nav-invite" type="button" role="tab"
                        aria-controls="nav-invite" aria-selected="true">Pending</button>
                    <button class="nav-link" [ngClass]="{ 'active':activeTab==='history'}" (click)="history('history')"
                        id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history" type="button" role="tab"
                        aria-controls="nav-history" aria-selected="false">History</button>
                    <button class="nav-link" [ngClass]="{ 'active':activeTab==='blocked'}" (click)="blocked('blocked')"
                        id="nav-blocked-tab" data-bs-toggle="tab" data-bs-target="#nav-blocked" type="button" role="tab"
                        aria-controls="nav-blocked" aria-selected="false">Blocked</button>
                </div>
            </nav>

            <div class="tab-content" id="nav-tabContent">
                <!-- Pending Tab Content -->
                <div class="tab-pane fade show" [ngClass]="{ 'active':activeTab==='pending'}" id="nav-invite"
                    role="tabpanel" aria-labelledby="nav-invite-tab">
                <div *ngIf="pendingData?.length !=  0">
                    <form [formGroup]="pendingRequestFilterForm" >
                        <div class="row mt-20"  >
                            <div class="col-lg-4 col-md-6 col-sm-6 mt-10">
                                <ng-select formControlName="filter_consumer_type" (change)="getFilterInputs()"
                                    [multiple]="true" placeholder="Role type">
                                    <ng-option *ngFor="let tag of consumerType" [value]="tag['id']"
                                        [disabled]="tag['is_parent']">
                                        {{tag['title']}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6  mt-10">
                                <ng-select formControlName="filter_levels" (change)="getFilterInputs()"
                                    [items]="levelFilter" [multiple]="true" bindLabel="title" bindValue="value"
                                    placeholder="Search by level">
                                </ng-select>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6 mt-10">
                                <ng-select [items]="subjectsAndTags" (change)="getFilterInputs()"
                                    formControlName="filter_subjects" bindLabel="title" bindValue="data" groupBy="tags"
                                    [multiple]="true" placeholder="Search by subjects and tags">
                                    <ng-template ng-optgroup-tmp let-item="item">
                                        {{item.title}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="row" *ngIf="schoolProps?.properties?.is_doe_school">
                            <div class="col-lg-12 mt-10">
                                <div class="form-check">
                                    <input class="form-check-input c-p  mt-4" (change)="getFilterInputs()"
                                        type="checkbox" id="is-validated" formControlName="filter_is_validated">
                                    <label class="form-check-label c-p" for="is-validated">
                                        Approved to teach only
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-5 col-sm-6 mt-10">
                                <div class="input-group">
                                    <input formControlName="filter_name" id="search-by-name" type="text"
                                        placeholder="Search by name" class="form-control" aria-label="Search by name">
                                    <button class="input-group-text" (click)="getFilterInputs()" for="search-by-name"><i
                                            class="bi bi-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div *ngIf="PendingRequestteachersList | async as pendinglist">
                        <div class="row mt-20 sub-header align-items-center">
                            <div class="col-lg-4 col-md-4">
                                <input #checkAll [(ngModel)]="selectAllCheck" class="form-check-input mt-0 mr-5"
                                    id="checkAll" type="checkbox" (change)="selectAll('All', $event)">
                                <label class="form-check-label  mt-5 mr-20 mb-0" for="checkAll">
                                    <span class="h_b" *ngIf="!selectAllCheck">Select all</span>
                                    <span class="h_b" *ngIf="selectAllCheck">Unselect all</span>

                                </label>
                                <!-- <p class="txt-regular mr-20">Select all</p> -->
                                <span class="text-r-m-16">{{consumerDetails.length}} Selected</span>
                            </div>
                            <div class="col-lg-8 col-md-8  text-regular-medium"
                                [ngClass]="hideButton ? 'input-cursor-na':''">
                                <div class="d-flex lign-items-center justify-content-end">
                                    <span class="h_b c-p d-flex align-items-center"
                                        (click)="accepetRequestModal('decline')"> <i class="bi bi-x-lg fs-13"></i>
                                        <span class="ml-3 h_b" [ngClass]="!hideButton ? 'text-regular-bold':''"> Decline
                                            selected </span>
                                    </span>
                                    <span class="h_b c-p d-flex align-items-center ml-10"
                                        (click)="accepetRequestModal('accept')">
                                        <i class="bi bi-check-lg fs-19 "></i>
                                        <span class="h_b" [ngClass]="!hideButton ? 'text-regular-bold':''">Accept
                                            selected</span>
                                    </span>
                                    <span class="d-flex c-p align-items-center h_b ml-12"
                                        (click)="accepetRequestModal('email')">
                                        <i class="bi bi-chat-left fs-14"></i>
                                        <span [ngClass]="!hideButton ? 'text-regular-bold':''" class="ml-4 h_b">Message
                                            selected</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- Pending Request List -->
                        <form [formGroup]="declineInviteForm">
                            <div class="">
                                <div class="card mt-10 px-15" *ngFor="let teacher of pendinglist; index as i">
                                    <div class="row align-items-center">
                                        <div class="col-lg-7 col-md-7 col-11">
                                            <div class="col-md-12 col-lg-12 d-flex">
                                                <div class="pic-and-check" >
                                                    <input [disabled]="teacher.status == 'GAP'" class="form-check-input mr-10 c-p"
                                                        (change)="selectAll(teacher, $event)"
                                                        [checked]="teacher.isChecked" type="checkbox"
                                                        id="{{teacher.teacher.id}}" type="checkbox">
                                                    <!-- <input type="hidden" formControlName="selectedValues" value="{{values}}"> -->
                                                </div>
                                                <div class="pic-and-check ml-0">
                                                    <img alt="img"
                                                        [src]="teacher.teacher.meta.profile_image != null ? teacher.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                        class="profile-picture img-complete-match">
                                                </div>

                                                <div class="pic-and-check ml-10">
                                                    <div class="d-flex">
                                                        <p>
                                                            <span class="s_n"
                                                                (click)="getTeacherAvailabilityModal(teacher.teacher.id);">{{teacher.teacher.user.full_name}}</span>
                                                            <span>
                                                                <app-validated-dict
                                                                    [validateDict]="teacher.teacher.validated_dict">
                                                                </app-validated-dict>
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <p class="user-sub-title">
                                                        <span
                                                            *ngIf="teacher?.teacher?.meta.you_are_a">{{teacher?.teacher?.meta.you_are_a}}
                                                        </span>
                                                        <span *ngIf="teacher?.teacher?.meta.you_are_a"
                                                            class="divider-right ml-3 mr-5">
                                                        </span>
                                                        Active
                                                        {{teacher?.teacher?.user.previous_login}}
                                                    </p>
                                                    <p class="text-s-r-g-3">
                                                        Accreditation No : <span
                                                            *ngIf="teacher?.teacher?.accreditation.accreditation">
                                                            <span class="text-s-m-g-3"
                                                                *ngIf="teacher?.teacher?.accreditation.accreditation.length > 0">{{teacher?.teacher?.accreditation.accreditation[0].accreditation_id}}</span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- For mobile view dropdown btn-->
                                        <div class="col-lg-1 col-md-1 col-1 lg-d-none" *ngIf="teacher.status != 'GAP'"
                                            [ngClass]="hideButton ? '':'input-cursor-na'">
                                            <i type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                class="bi bi-three-dots dropdown-toggle"></i>
                                            <ul class="dropdown-menu txt-regular">
                                                <li><a class="dropdown-item"
                                                        (click)="actions(teacher, 'email')">Email</a></li>
                                                <li><a class="dropdown-item"
                                                        (click)="actions(teacher, 'block')">Block</a></li>
                                                <li><a class="dropdown-item"
                                                        (click)="actions(teacher , 'remove')">Remove from list</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <!--End of for mobile view dropdown btn-->

                                        <div class="col-lg-4 col-md-4" *ngIf="teacher.status != 'GAP'"
                                            [ngClass]="hideButton ? '':'input-cursor-na'">
                                            <div class="accept-decline">
                                                <div class="d-flex align-items-center user-title c-p h_b"
                                                    (click)="actionModal(teacher, 'decline')">
                                                    <i class="bi bi-x-lg fs-16 v-align-0"></i>
                                                    <p class="ml-3"> Decline</p>
                                                </div>
                                                <div class="d-flex align-items-center ml-10 user-title clr-cc-vilot c-p h_b"
                                                    (click)="actionModal(teacher, 'accept')">
                                                    <i class="bi bi-check-lg fs-23 v-align-0"></i>
                                                    <p class="ml-3"> Accept</p>
                                                </div>

                                            </div>
                                            <div class="days-ago">
                                                <p class="text-s-m-g-3">{{teacher.created_at}}</p>
                                            </div>
                                        </div>

                                        <!-- For large and tab view dropdown btn -->
                                        <div class="col-lg-1 col-md-1 sm-d-none" *ngIf="teacher.status != 'GAP'"
                                            [ngClass]="hideButton ? '':'input-cursor-na'">
                                            <i type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                class="bi bi-three-dots dropdown-toggle h_b"></i>
                                            <ul class="dropdown-menu txt-regular c-p">
                                                <li><a class="dropdown-item"
                                                        (click)="actionModal(teacher, 'email')">Email</a></li>
                                                <li><a class="dropdown-item"
                                                        (click)="actionModal(teacher, 'block')">Block</a></li>
                                                <li><a class="dropdown-item"
                                                        (click)="actionModal(teacher , 'remove')">Remove from
                                                        list</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <!--End of for large and tab view dropdown btn -->
                                        <div class="col-lg-4 col-md-4 sm-d-none" *ngIf="teacher.status == 'GAP'">
                                            <div class="accept-decline">
                                                <div class="d-flex align-items-center user-title clr-grey-2">
                                                    <p class="ml-3">Group approval pending</p>
                                                </div>
                                            </div>
                                            <div class="days-ago">
                                                <p class="text-s-m-g-3">from {{teacher.created_at}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    </div>
                    <div class="text-center mt-20" *ngIf="pendingData?.length == 0">

                        <p><img class="no-invite-image"
                                src="assets/images/CCPI_PendingRequests_NoAcceptedRequests-021.png"
                                alt="No invites found!" /></p>
                        <p class="title">No requests found!
                        </p>
                    </div>
                    <!--End of Pending Request List -->
                </div>
                <!--End of Pending Tab Content -->

                <!-- History Tab Content -->
                <div class="tab-pane fade" id="nav-history" [ngClass]="{ 'active':activeTab==='history'}"
                    role="tabpanel" aria-labelledby="nav-history-tab">
                    <div class="mt-20">
                        <form [formGroup]="pendingHistoryFilterForm">
                            <div class="row mt-20">
                                <div class="col-lg-4 col-md-6 col-sm-6 mt-10">
                                    <ng-select formControlName="filter_consumer_type" (change)="getFilterInputs()"
                                        [multiple]="true" placeholder="Role type">
                                        <ng-option *ngFor="let tag of consumerType" [value]="tag['id']"
                                            [disabled]="tag['is_parent']">
                                            {{tag['title']}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6  mt-10">
                                    <ng-select formControlName="filter_levels" (change)="getFilterInputs()"
                                        [items]="levelFilter" [multiple]="true" bindLabel="title" bindValue="value"
                                        placeholder="Search by level">
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 mt-10">
                                    <ng-select [items]="subjectsAndTags" (change)="getFilterInputs()"
                                        formControlName="filter_subjects" bindLabel="title" bindValue="data"
                                        groupBy="tags" [multiple]="true" placeholder="Search by subjects and tags">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.title}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row" *ngIf="schoolProps?.properties?.is_doe_school">
                                <div class="col-lg-12 mt-10">
                                    <div class="form-check">
                                        <input class="form-check-input c-p  mt-4" (change)="getFilterInputs()"
                                            type="checkbox" id="is-validated-history"
                                            formControlName="filter_is_validated">
                                        <label class="form-check-label c-p" for="is-validated-history">
                                            Approved to teach only
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-5 col-md-5 col-sm-6 mt-10">
                                    <div class="input-group">
                                        <input formControlName="filter_name" id="search-by-name" type="text"
                                            placeholder="Search by name" class="form-control"
                                            aria-label="Search by name">
                                        <button class="input-group-text" (click)="getFilterInputs()"
                                            for="search-by-name"><i class="bi bi-search"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="mt-20"  *ngIf="PendingHistoryteachersList | async as pendingHistory">
                            <div class="card mt-10 px-15"  *ngFor="let teacher of pendingHistory; index as i">
                                <div class="row align-items-center">
                                    <div class="col-lg-8 col-md-8 col-11">
                                        <div class="col-md-12 col-lg-12 d-flex align-items-center">
                                            <div class="pic-and-check ml-0">
                                                <img alt="img"
                                                    [src]="teacher?.teacher?.meta.profile_image != null ? teacher?.teacher?.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                    class="profile-picture img-complete-match">
                                            </div>
                                            <div class="pic-and-check ml-10">
                                                <div class="d-flex">
                                                    <p>
                                                        <span class="s_n"
                                                            (click)="getTeacherAvailabilityModal(teacher.teacher.id);">{{teacher?.teacher?.user.full_name}}
                                                        </span>
                                                            
                                                        <span>
                                                            <app-validated-dict
                                                                [validateDict]="teacher?.teacher?.validated_dict">
                                                            </app-validated-dict>
                                                        </span>
                                                        <span *ngIf="teacher.teacher.is_complete == false" class="bg-tags-custom ml-10">Incomplete
                                                            Profile
                                                          </span>
                                                          <span *ngIf="teacher.is_student && teacher.student_tag != ''" class="s_tag ml-10">{{
                                                            teacher.student_tag }}
                                                          </span>
                                                    </p>
                                                </div>
                                                <p class="user-sub-title">
                                                    <span *ngIf="teacher?.teacher?.meta.you_are_a">
                                                        {{teacher?.teacher?.meta.you_are_a}}
                                                    </span>
                                                    <span *ngIf="teacher?.teacher?.meta.you_are_a"
                                                        class="divider-right ml-3 mr-5">
                                                    </span>
                                                    <span>
                                                        Active {{teacher?.teacher?.user.previous_login}}
                                                    </span>
                                                </p>
                                                <p class="text-s-r-g-3">
                                                    Accreditation No : <span
                                                        *ngIf="teacher?.teacher?.accreditation.accreditation">
                                                        <span class="text-s-m-g-3"
                                                            *ngIf="teacher?.teacher?.accreditation.accreditation.length > 0">{{teacher?.teacher?.accreditation.accreditation[0].accreditation_id}}</span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="accept-decline" *ngIf="teacher.status == 'PC'">
                                            <div class="d-flex text-regular-bold c-p">
                                                <p class="clr-green"> Accepted</p>
                                            </div>
                                        </div>
                                        <div class="accept-decline" *ngIf="teacher.status == 'DBG'">
                                            <div class="d-flex text-regular-bold-grey c-p">
                                                <p class="btn-cc-red"> Declined by group</p>
                                            </div>
                                        </div>
                                        <div class="accept-decline" *ngIf="teacher.status == 'RDS'">
                                            <div class="d-flex text-regular-bold c-p">
                                                <p class="btn-cc-red">Declined</p>
                                            </div>
                                        </div>

                                        <div class="days-ago mt-3">
                                            <p class="text-s-m-g-3">{{teacher.updated_at}}, </p>
                                        </div>
                                        <div class="reason-for-decline mt-3"
                                            *ngIf="teacher.status == 'RDS' && teacher.decline_reason != ''">
                                            <button class="notesTooltip  c-p h_b">
                                                <div class="d-flex   align-items-center c-p h_b">
                                                  <i class="bi bi-exclamation-circle fs-13 mr-4"></i>
                                                    <p class="text-regular-bold-grey">Reason for decline</p>
                                                </div>
                                                <div class="notesTooltiptext text-s-m-g-1">{{ teacher.decline_reason }}</div>
                                            </button>
                                            <!-- <div class="d-flex text-regular-bold-grey align-items-center c-p h_b"
                                                triggers="mouseenter:mouseleave" popover="{{teacher.decline_reason}}">
                                                <i class="bi bi-exclamation-circle fs-13 mr-4"></i>
                                                <p class="text-medium">Reason for decline</p>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-20" *ngIf="historyData?.length == 0">

                            <p><img class="no-invite-image"
                                    src="assets/images/CCPI_PendingRequests_NoAcceptedRequests-021.png"
                                    alt="No invites found!" /></p>
                            <p class="title">You have not accepted any requests yet! You can accept requests from the
                                pending tab to see them over here in history</p>
                        </div>
                    </div>
                </div>
                <!--End of History Tab Content -->


                <!-- Blocked Tab Content -->
                <div class="tab-pane fade" id="nav-blocked" [ngClass]="{ 'active':activeTab==='blocked'}"
                    role="tabpanel" aria-labelledby="nav-blocked-tab">
                    <div class="mt-20">
                        <form [formGroup]="pendingBlockedFilterForm">
                            <div class="row mt-20">
                                <div class="col-lg-4 col-md-6 col-sm-6 mt-10">
                                    <ng-select formControlName="filter_consumer_type" (change)="getFilterInputs()"
                                        [multiple]="true" placeholder="Role type">
                                        <ng-option *ngFor="let tag of consumerType" [value]="tag['id']"
                                            [disabled]="tag['is_parent']">
                                            {{tag['title']}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6  mt-10">
                                    <ng-select formControlName="filter_levels" (change)="getFilterInputs()"
                                        [items]="levelFilter" [multiple]="true" bindLabel="title" bindValue="value"
                                        placeholder="Search by level">
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 mt-10">
                                    <ng-select [items]="subjectsAndTags" (change)="getFilterInputs()"
                                        formControlName="filter_subjects" bindLabel="title" bindValue="data"
                                        groupBy="tags" [multiple]="true" placeholder="Search by subjects and tags">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.title}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row" *ngIf="schoolProps?.properties?.is_doe_school">
                                <div class="col-lg-12 mt-10">
                                    <div class="form-check">
                                        <input class="form-check-input c-p  mt-4" (change)="getFilterInputs()"
                                            type="checkbox" id="is-validated-blocked"
                                            formControlName="filter_is_validated">
                                        <label class="form-check-label c-p" for="is-validated-blocked">
                                            Approved to teach only
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-5 col-md-5 col-sm-6 mt-10">
                                    <div class="input-group">
                                        <input formControlName="filter_name" id="search-by-name" type="text"
                                            placeholder="Search by name" class="form-control"
                                            aria-label="Search by name">
                                        <button class="input-group-text" (click)="getFilterInputs()"
                                            for="search-by-name"><i class="bi bi-search"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="mt-20" *ngIf="blockedData?.length > 0">
                            <div class="card mt-10 px-15" *ngFor="let teacher of blockedData">
                                <div class="row align-items-center">
                                    <div class="col-lg-8 col-md-8 col-11">
                                        <div class="col-md-12 col-lg-12 d-flex align-items-center">
                                            <div class="pic-and-check ml-0">
                                                <img alt="img"
                                                    [src]="teacher?.teacher?.meta.profile_image != null ? teacher?.teacher?.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                    class="profile-picture img-complete-match">
                                            </div>
                                            <div class="pic-and-check ml-10">
                                                <div class="d-flex">
                                                    <p>
                                                        <span class="s_n"
                                                            (click)="getTeacherAvailabilityModal(teacher.teacher.id);">{{teacher?.teacher?.user.full_name}}</span>
                                                        <span>
                                                            <app-validated-dict
                                                                [validateDict]="teacher?.teacher?.validated_dict">
                                                            </app-validated-dict>
                                                        </span>
                                                    </p>
                                                </div>
                                                <p class="user-sub-title">
                                                    <span *ngIf="teacher?.teacher?.meta.you_are_a">
                                                        {{teacher?.teacher?.meta.you_are_a}}
                                                    </span>
                                                    <span *ngIf="teacher?.teacher?.meta.you_are_a"
                                                        class="divider-right ml-3 mr-5">
                                                    </span>
                                                    <span>
                                                        Active {{teacher?.teacher?.user.previous_login}}
                                                    </span>
                                                </p>
                                                <p class="text-s-r-g-3">
                                                    Accreditation No : <span
                                                        *ngIf="teacher?.teacher?.accreditation.accreditation">
                                                        <span class="text-s-m-g-3"
                                                            *ngIf="teacher?.teacher?.accreditation.accreditation.length > 0">{{teacher?.teacher?.accreditation.accreditation[0].accreditation_id}}</span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="accept-decline" *ngIf="teacher.status == 'BL'">
                                            <div class="d-flex text-regular-bold h_b c-p">
                                                <p (click)="actionModal(teacher, 'unblock')" class="">Unblock
                                                </p>
                                            </div>
                                        </div>

                                        <div class="days-ago mt-3">
                                            <p class="text-s-m-g-3">{{teacher.updated_at}}</p>
                                        </div>
                                        <div class="reason-for-decline mt-3"
                                            *ngIf="teacher.status == 'BL' && teacher.decline_reason != ''">
                                            <button class="notesTooltip  c-p h_b">
                                                <div class="d-flex   align-items-center c-p h_b">
                                                  <i class="bi bi-exclamation-circle fs-13 mr-4"></i>
                                                    <p class="text-regular-bold-grey">Blocked reason</p>
                                                </div>
                                                <div class="notesTooltiptext text-s-m-g-1">{{ teacher.decline_reason }}</div>
                                            </button>
                                            <!-- <div class="d-flex  text-regular-bold-grey align-items-center c-p h_b"
                                                triggers="mouseenter:mouseleave" popover="{{teacher.decline_reason}}">
                                                <i class="bi bi-exclamation-circle fs-13 mr-4"></i>
                                                <p class="user-sub-title">Blocked reason</p>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-20" *ngIf="blockedData?.length == 0">
                            <p><img class="no-invite-image"
                                    src="assets/images/CCPI_PendingRequests_NoAcceptedRequests.png" alt="No_img" /></p>
                            <p class="title">You have not blocked anyone yet</p>

                        </div>
                    </div>
                </div>
                <!-- End of Blocked Tab Content -->
            </div>
        </div>
        <!--End of Pending Request Tabs -->
        <div class="col-lg-3 col-12">
            <div class="row">
                <app-health-check [healthList]="listHealth"></app-health-check>
            </div>
            <div class="row mt-20" *ngIf="GroupPendingRequestData?.length > 0">
                <div class="col-lg-12 col-12">
                    <div class="card px-15">
                        <p class="text-regular-bold">Group approval <span
                                class="preview-length ml-5">{{GroupPendingRequestData.length}}</span></p>
                        <div>
                            <div class="mt-10" *ngFor="let teacher of GroupPendingRequestData;  index as i">
                                <div class="col-md-12 col-lg-12 d-flex align-items-center invite-request-preview">
                                    <div class="pic-and-check ml-0">
                                        <img alt="img"
                                            [src]="teacher.teacher.meta.profile_image != null ? teacher.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                            class="profile-picture img-complete-match">
                                    </div>

                                    <div class="pic-and-check ml-10">
                                        <div class="d-flex">
                                            <p>
                                                <span
                                                    class="text-regular-bold c-p">{{teacher.teacher.user.full_name}}</span>
                                            </p>
                                        </div>
                                        <p class="user-sub-title">
                                            <span
                                                *ngIf="teacher?.teacher?.meta.you_are_a">{{teacher?.teacher?.meta.you_are_a}}
                                            </span>
                                            <span *ngIf="teacher?.teacher?.meta.you_are_a"
                                                class="divider-right ml-3 mr-5">
                                            </span>
                                            {{teacher?.teacher?.distance_from}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div *ngIf="GroupPendingRequestData?.length == 0">
                            <div class="col-md-12 col-lg-12 mt-20 text-center invite-request-preview">
                                <p class="text-regular-medium">No group approval!</p>
                                <p><img class="no-invite-image" src="assets/images/no-request-found.png"
                                        alt="No requests found!" /></p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #actionModals>
        <div class="modal-header">
            <h4 class="modal-title" *ngIf="actionType == 'block'" id="exampleModalLabel">Block {{teacherName}}</h4>
            <h4 class="modal-title" *ngIf="actionType == 'remove'" id="exampleModalLabel">Remove {{teacherName}}</h4>
            <h4 class="modal-title" *ngIf="actionType == 'email'" id="exampleModalLabel">Send mail to {{teacherName}}
            </h4>
            <h4 class="modal-title" *ngIf="actionType == 'accept'" id="exampleModalLabel">Accept {{teacherName}}</h4>
            <h4 class="modal-title" *ngIf="actionType == 'decline'" id="exampleModalLabel">Are you sure you want to decline
                {{teacherName}}?</h4>
            <h4 class="modal-title" *ngIf="actionType == 'unblock'" id="exampleModalLabel">Unblock {{teacherName}}</h4>

            <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row  align-items-center">
                <div class="col-md-12">
                    <p class="text-regular-medium" *ngIf="actionType == 'block'">Are you sure you want to block
                        {{teacherName}}?</p>
                    <p class="text-regular-medium" *ngIf="actionType == 'remove'">Are you sure you want to remove
                        {{teacherName}}?</p>
                    <p class="text-regular-medium" *ngIf="actionType == 'accept'">Are you sure you want to accept
                        {{teacherName}}?</p>
                    <!-- <p class="text-regular-medium" *ngIf="actionType == 'decline'">Are you sure you want to decline
                        {{teacherName}}?</p> -->
                    <p class="text-regular-medium" *ngIf="actionType == 'unblock'">Are you sure you want to unblock
                        {{teacherName}}?</p>

                        <div class="row" *ngIf="actionType == 'decline'">
                            <form [formGroup]="actionForm">
                                <div class="col-md-12">
                                    <label for="exampleFormControlTextarea1" class="form-label text-regular-medium ">Add reason for decline</label>
                                    <textarea class="form-control mt-5" formControlName="decline_reason"
                                        (keyup)="checkLength()" maxlength='500' placeholder="Add Message here..."
                                        id="exampleFormControlTextarea1" rows="6"></textarea>
                                    <p class="txt-regular mt-10 mb-0">Max ({{this.ShowLenght}} characters)</p>
                                </div>
                            </form>
                        </div>

                    <div class="row" *ngIf="actionType == 'email'">
                        <form [formGroup]="actionForm">
                            <div class="col-md-12">
                                <label for="exampleFormControlTextarea1" class="form-label user-title-m">Send a message
                                    to the
                                    {{teacherName}} *</label>
                                <textarea class="form-control mt-5" formControlName="email_content"
                                    (keyup)="checkLength()" maxlength='500' placeholder="Add Message here..."
                                    id="exampleFormControlTextarea1" rows="6"></textarea>
                                <p class="txt-regular mt-10 mb-0">Max ({{this.ShowLenght}} characters)</p>
                                <div *ngIf="submitted">
                                    <span class="show-errors" *ngIf="f['email_content'].errors?.['required']">*Message
                                        is
                                        required</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button type="button" (click)="actions(teacher, actionType)" class="btn cc_p_btn fs_13">Confirm</button>
        </div>
    </ng-template>

    <ng-template #acceptRequestModal>
        <div class="modal-header">
            <h4 class="modal-title" *ngIf="actionType == 'accept'" id="exampleModalLabel">Accept requests</h4>
            <h4 class="modal-title" *ngIf="actionType == 'decline'" id="exampleModalLabel">Are you sure you want to decline
                selected requests?</h4>
            <h4 class="modal-title" *ngIf="actionType == 'email'">Send a email to selected staff?</h4>
            <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row  align-items-center">
                <div class="col-md-12">
                    <p class="text-regular-medium" *ngIf="actionType == 'accept'">Are you sure you want to accept
                        selected requests?</p>
                    <div class="row" *ngIf="actionType == 'email'">
                        <form [formGroup]="actionForm">
                            <div class="col-md-12">
                                <label for="exampleFormControlTextarea1" class="form-label user-title-m">Send a message
                                    to the
                                    staff *</label>
                                <textarea class="form-control mt-5" formControlName="email_content"
                                    (keyup)="checkLength()" maxlength='500' placeholder="Add Message here..."
                                    id="exampleFormControlTextarea1" rows="6"></textarea>
                                <p class="txt-regular mt-10 mb-0">Max ({{this.ShowLenght}} characters)</p>
                                <div *ngIf="submitted">
                                    <span class="show-errors" *ngIf="f['email_content'].errors?.['required']">* Cancel
                                        message is
                                        required</span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" *ngIf="actionType == 'decline'">
                        <form [formGroup]="actionForm">
                            <div class="col-md-12">
                                <label for="exampleFormControlTextarea1" class="form-label text-regular-medium">Add reason for decline</label>
                                <textarea class="form-control mt-5" formControlName="decline_reason"
                                    (keyup)="checkLength()" maxlength='500' placeholder="Add Message here..."
                                    id="exampleFormControlTextarea1" rows="6"></textarea>
                                <p class="txt-regular mt-10 mb-0">Max ({{this.ShowLenght}} characters)</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button *ngIf="actionType != 'email'" type="button" (click)="actions('', actionType)"
                class="btn cc-blue-btn fs_13">Confirm</button>
            <button *ngIf="actionType == 'email'" type="button" (click)="mailSend('', actionType)"
                class="btn cc-blue-btn fs_13">Confirm</button>
        </div>
    </ng-template>
</div>