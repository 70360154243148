<nav class="navbar">
    <div class="container-fluid display-profile-header pl-20 pr-20 align-items-center">
      <div class="d-flex">
        <img alt="" class="side_logo_text_booking"
          src="assets/images/favicon/cc_reverse@2x.png" />
      </div>
      <div class="nav-item public-profile d-flex align-items-center">
        <li class="nav-item">
            <a class="nav-link" target="_blank" href="https://www.classcover.com.au/teachers?utm_source=cc_app&amp;utm_campaign=jobs">Learn more</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" target="_blank" href="https://www.classcover.com.au/hire-teachers-through-classcover-jobs?utm_source=cc_app&amp;utm_campaign=jobs">Post a job</a>
        </li>
        <li class="nav-item pr-2">
            <a class="nav-link" target="_blank" href="http://phoenix.classcover.com.au/en/login">Login</a>
        </li>
        <li class="nav-item pl-2">
            <a class="btn cc_p_btn" target="_blank" href="http://phoenix.classcover.com.au/en/teacher/register">Sign up</a>
        </li>
      </div>
    </div>
  </nav>


  <div class="row pb-90 pt-90">
    <!-- First half -->
    <div class="col-lg-8 col-md-12 col-sm-12 col-12 mb-15">
      <div class="card px-15">
        <div class="row">
          <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10">
            <div class="align-items-center d-flex">
              <p class="wrapper">
                <img alt="" class="avatar" [src]="
                    profileData?.additional_info?.profile_image == null ||
                    profileData?.additional_info?.profile_image == ''
                      ? '../../../../assets/images/pro_avatar.png'
                      : profileData?.additional_info?.profile_image
                  " />
              </p>
              <div class="ml-10">
                <p class="c-p ul-title">{{ profileData?.title }}</p>
                <p class="txt-regular mt-2">
                  <i class="bi bi-geo-alt-fill"></i>
                  {{ profileData?.address?.city }},
                  {{ profileData?.address.address_timezone.state }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">            
          </div>
        </div>
      </div>


      <div *ngIf="profileData?.subscription?.job_only_school == false" class="card px-15 mt-30">
        <div class="row">
          <div class="col-lg-10 col-md-10 col-sm-9 col-xs-9 col-9">
            <h5 class="ul-title">Overview</h5>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3">

          </div>
        </div>
        <div class="mt-5 txt-regular">
          <p class="mt-10 pre-line" [innerHTML]="profileData?.overview"></p>
        </div>
        <div class="mt-10">
          <div class="row align-items-center mt-20">
            <div class="col-lg-4">
              <div class="text-r-g-3">School Type</div>
            </div>
            <div class="col-lg-8">
              <span class="txt-regular">{{
                profileData?.subscription?.school_type
                }}</span>
            </div>
          </div>
          <div class="row align-items-center mt-20">
            <div class="col-lg-4">
              <div class="text-r-g-3">School Contact</div>
            </div>
            <div class="col-lg-8">
              <span class="txt-regular clr-pink">{{ profileData?.email }}</span>
              <span class="txt-regular">
                <i class="bi bi-dot"></i> {{ profileData?.phone_number }}</span>
            </div>
          </div>

        </div>
      </div>
      <div *ngIf="profileData?.subscription?.job_only_school == true" class="card px-15 mt-30">
        <div class="row">
          <div class="col-lg-10 col-md-10 col-sm-9 col-xs-9 col-9">
            <h5 class="ul-title">Overview</h5>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3">

          </div>
        </div>
        <div class="mt-5 txt-regular">
          <p class="mt-10 pre-line" [innerHTML]="profileData?.overview"></p>
        </div>
        <div class="mt-10">
          <div class="row align-items-center">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="text-r-g-3">School Type</div>
              </div>
              <div class="col-lg-8">
                <span class="txt-regular">{{
                  profileData?.subscription?.display_school_type
                  }}</span>
              </div>
            </div>

            <div class="row align-items-center mt-20">
              <div class="col-lg-4">
                <div class="text-r-g-3">
                  School Contact
                </div>
              </div>
              <div class="col-lg-8">
                <span class="txt-regular clr-pink">{{
                  profileData?.email
                  }}</span>
                <span class="txt-regular">
                  <i class="bi bi-dot mr-1 ml-5"></i>
                  {{ profileData?.phone_number }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card px-15 mt-30">
        <h5 class="ul-title mb-20">
          Location
        </h5>
        <iframe width="100%" class="e2e-iframe-trusted-src" height="380"
          [src]="this.mapsUrl"></iframe>
      </div>
    </div>
    <!-- Second Half -->
    <div *ngIf="profileData?.subscription?.job_only_school == false" class="col-lg-4 col-md-12 col-sm-12 col-12">

      <div class="row" *ngIf="recentJobsList?.length > 0">
        <div class="col-12">
          <div class="card px-15 ">
            <h4 class="ul-title">Recent Jobs Posted</h4>
            <div class="row " *ngFor="let job of recentJobsList; index as i">
              <div class="col-lg-12 col-md-12 col-sm-12 col-12"  (click)="openPublicJobs(job.id)">
                <h6 class="pt-3 mb-4 user-title">
                  {{ job?.job_title }}
                  <span class="qualified-in mt-5 bg-tags p-2 ml-6">{{
                    job?.job_type_display
                    }}</span>
                </h6>
                <small class="txt-regular">{{ job?.job_title }}<i class="bi bi-dot"></i>{{
                  job?.school_level_display }}</small><br />
                <small class="text-r-g-3"><i class="bi bi-geo-alt-fill bi-geo-alt-fill-public"></i>
                  {{ job?.location }}</small>
              </div>
              <hr class="mt-8 mb-8" />
            </div>
            <p class="c-p clr-cc-vilot text-center user-title mt-5 mb-10">
              View all
              <i class="bi bi-arrow-up-right"></i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>