export const navbarData = [
    {
        routeLink: 'producer',
        icon: 'bi bi-menu-button',
        label: 'Dashboard'
    },
    {
        routeLink: 'producer/booking-request',
        icon: 'bi bi-calendar2-event',
        label: 'Bookings',
        submenuLevel1: [
            {
                routeLink: 'producer/booking-request',
                icon: 'fal fa-cog',
                label: 'Sub menu level 1',
            },
            {
                routeLink: '',
                icon: 'fal fa-cog',
                label: 'Sub menu level 1',
                submenuLevel2: [
                    {
                        routeLink: '',
                        icon: 'fal fa-cog',
                        label: 'Sub menu level 2',
                    }
                ]
            }
        ]
    },
    {
        routeLink: 'producer/my-list',
        icon: 'bi bi-people',
        label: 'My List'
    },
    {
        routeLink: 'producer/find-teachers',
        icon: 'bi bi-person-check',
        label: 'Find New'
    },
    {
        routeLink: 'producer/calendar',
        icon: 'bi bi-calendar2-check',
        label: 'Calendar'
    },
    {
        routeLink: 'producer/jobs',
        icon: 'bi bi-briefcase',
        label: 'Jobs'
    },
    {
        routeLink: 'producer/booking-history',
        icon: 'bi bi-clock-history',
        label: 'Booking History'
    },
    {
        routeLink: 'producer/reports',
        icon: 'bi bi-graph-up-arrow',
        label: 'Reports'
    },
    


];