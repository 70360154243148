<div class="booking-request-page pb-40 pt-15 ">
    <form [formGroup]="bookingHistoryFilterForm">
        <div class="row mt-15">
            <div class="col-lg-4 col-md-6 col-sm-6 mt-10">
                <div class="input-group">
                    <input id="search-by-name" formControlName="created_by" type="text" placeholder="Search by creator"
                        class="form-control" autocomplete="off" aria-label="Search by name">
                    <button class="input-group-text" for="search-by-name" (click)="getFilterInputs('filter','')"><i
                            class="bi bi-search"></i>
                    </button>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 mt-10">
                <div class="input-group">
                    <input type="text" readonly placeholder="Choose date" class="form-control" bsDaterangepicker
                        [minDate]="minDate"
                        [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY',customTodayClass: 'custom-today-class', containerClass: 'theme-red', showWeekNumbers:false,displayMonths: 1,showClearButton: true, clearPosition: 'right'}"
                        [daysDisabled]="[6,0]" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="getFilterInputs('filter',$event)" [(ngModel)]="bsRangeValue">
                    <span class="input-group-text" for="search-by-name" (click)="clearDate()"><i class="bi bi-x-lg"></i>
                    </span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6  col-sm-6 mt-10">
                <ng-select formControlName="status" [searchable]="false" [clearable]="false"
                    [dropdownPosition]="'bottom'" [multiple]="false" (change)="getFilterInputs('filter','')"
                    aria-label="Select status">
                    <ng-option *ngFor="let status of filtersArray?.booking_status" value="{{status.value}}" value="">
                        {{status.title}}</ng-option>
                </ng-select>
            </div>
            <div class="col-lg-4 col-md-6  col-sm-6 mt-10">
                <ng-select formControlName="booking_type" [searchable]="false" [clearable]="false"
                    [dropdownPosition]="'bottom'" [multiple]="false" (change)="getFilterInputs('filter','')"
                    aria-label="Select status">
                    <ng-option value="booking_request">Booking Request</ng-option>
                    <ng-option value="pre_confirmed">Pre-Confirmed Booking</ng-option>
                    <ng-option value="all_bookings">All</ng-option>
                </ng-select>
            </div>
            <div class="col-lg-4 col-md-6  col-sm-6 mt-10">
                <ng-select formControlName="booking_role_type" [searchable]="false" [clearable]="false"
                    [dropdownPosition]="'bottom'" [multiple]="false" (change)="getFilterInputs('filter','')"
                    aria-label="role type">
                    <ng-option value="All">Role type</ng-option>
                    <ng-option *ngFor="let role_type of filtersArray?.booking_role_type" value="{{role_type.title}}" value="">
                        {{role_type.title}}</ng-option>
                </ng-select>
            </div>
        </div>
    </form>
    <span *ngIf="data?.length > 0">
        <div class="history-header sticky  px-15 md-d-none mt-20">
            <div class="row align-items-center">
                <div class="col-lg-2">
                    <p class="text-regular-medium">Created on</p>
                </div>
                <div class="col-lg-2">
                    <p class="text-regular-medium">Created by</p>
                </div>
                <div class="col-lg-3">
                    <p class="text-regular-medium">Booking date</p>
                </div>
                <div class="col-lg-3">
                    <p class="text-regular-medium">Status</p>
                </div>
                <div class="col-lg-2">
                    <p class="text-regular-medium">Booking type</p>
                </div>
            </div>
        </div>


        <div class="history-description mt-20">
            <!-- <button ... (click)="addProductToCart(p); $event.stopPropagation()">Add to cart</button> -->

            <div class="card card_his_hover px-15 mt-10 c-p" *ngFor="let teacher of data">
                <div class="row align-items-center" (click)="sendBookingId(teacher.id)">
                    <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                        <p class="text-regular-bold lg-d-none mb-5">Created on</p>
                        <p class="txt-regular">{{teacher.created_at|date:"MMM d, Y"}}</p>
                        <p class="txt-regular mt-5">{{teacher.created_at|date:"h:mm a"}}</p>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                        <p class="text-regular-bold lg-d-none mb-5">Created by</p>
                        <p class="txt-regular">{{teacher.created_by}}</p>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <p class="text-regular-bold lg-d-none mb-5">Booking date</p>
                        <p class="txt-regular"><i class="bi bi-calendar-event fs-13 mr-2"></i> {{teacher.booking_date}}
                        </p>
                        <p class="txt-regular mt-5"><i class="bi bi-clock fs-13 mr-2"></i> {{teacher.time}}</p>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <p class="text-regular-bold lg-d-none mb-5">Status</p>
                        <p class="text-regular-bold status-his-{{teacher.status}}">{{teacher.status}}</p>
                        <p class="bg-tags-custom fit-content mt-2" *ngIf="teacher.strategy == 'PRE'">Pre confirmed</p>
                        <p class="txt-regular mt-2">{{teacher.updated_at|date:"EEE, MMM d, Y"}}</p>

                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                        <p class="txt-regular">{{teacher.booking_role_type}}</p>
                        
                    </div>
                </div>
                <hr *ngIf="teacher.remarks != null || teacher.meta.is_clone_booking || teacher.meta.is_reopen_booking">
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="reason-for-decline mt-2" *ngIf="teacher.remarks != null">
                            <button class="notesTooltip  c-p h_b">
                                <div class="d-flex  align-items-center  clr-cc-vilot">
                                    <i class="bi bi-exclamation-circle-fill fs-16 mr-4 clr-cc-vilot"></i>
                                    <p class="text-regular-medium">Reason of cancellation</p>
                                </div>
                                <div class="notesTooltiptext text-s-m-g-1">{{ teacher.remarks }}</div>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="d-flex gap-6 justify-content-right">
                        <!-- <p class="text-regular-bold  lg-d-none mb-5">Action</p>
                        <button (click)="sendBookingId(teacher.id) ; $event.stopPropagation()" class="text-regular-bold clr-cc-vilot c-p"><i
                                class="bi bi-clipboard-data fs-13 mr-2"></i> View</button> -->
                        <button *ngIf="teacher.meta.is_clone_booking" (click)="cloneBooking(teacher.id) ; $event.stopPropagation()"
                            class="btn-outline text-regular-bold  clr-cc-vilot c-p">Clone booking</button>
                        <button *ngIf="teacher.meta.is_reopen_booking" (click)="reOpenBooking(teacher.id) ; $event.stopPropagation()"
                            class="text-regular-bold   btn-outline clr-cc-vilot c-p">Reopen booking
                        </button>
                    </div>
                    </div>

                </div>
            </div>
            <pagination class="mt-20" [totalItems]="totalCount" class="pagination-sm mt-20"
                [boundaryLinks]="showBoundaryLinks" [rotate]="rotate" [maxSize]="maxSize" [(ngModel)]="currentPage"
                (pageChanged)="pageChanged($event)"></pagination>
        </div>
    </span>
    <div class="px-15 md-d-none mt-20" *ngIf="data?.length == 0">
        <p class="user-title text-center">No requests found. Make your first booking request now.</p>
        <p class="text-center"><button class="btn cc_p_btn fs_13 mt-20">Booking request</button></p>
    </div>
</div>