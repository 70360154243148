<div class="profile-card-view">
    <div class="row">
        <div class="col-md-12 col-lg-12 d-flex align-items-center">
            <div class="pic-and-check">
                <input class="form-check-input mr-10 c-p" #checkboxes (click)="addToSelectedList(TeacherProfile)" type="checkbox" value="" id="flexCheckDefault">
            </div>
            <div class="pic-and-check ml-0">
                <img alt="" [src]="'../../../../assets/images/teacher-static-profile.png'"
                    class="profile-picture img-complete-match">
            </div>
            <!-- {{TeacherProfile | json}} -->
            <div class="pic-and-check ml-10">
                <p class="s_n">{{TeacherProfile?.teacher?.user.full_name}}</p>
                <p class="user-sub-title" *ngIf="TeacherProfile?.teacher?.meta.you_are_a">
                    {{TeacherProfile?.teacher?.meta.you_are_a}} <span *ngIf="TeacherProfile?.teacher?.meta.you_are_a"
                        class="divider-right ml-3 mr-5"></span>
                    Active  
                    {{TeacherProfile?.teacher?.user.previous_login}}</p>
                <p class="user-sub-title">
                    Accreditation No <span *ngIf="TeacherProfile?.teacher?.accreditation.accreditation">
                        <span
                            *ngIf="TeacherProfile?.teacher?.accreditation.accreditation.length > 0">{{TeacherProfile?.teacher?.accreditation.accreditation[0].accreditation_id}}</span>
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>