<div class="my-list-page pt-15 ">
    <div class="row">
        <!-- Pending Request Tabs -->
        <div class="col-lg-9 col-12">
            <!-- My list Tab Content -->

            <form [formGroup]="myListFilterForm">
                <div class="row mt-20">
                    <div class="col-lg-4 col-md-4 col-sm-6 mt-10">
                        <div class="input-group">
                            <input formControlName="filter_name" id="search-by-name" type="text"
                                placeholder="Search by name" class="form-control" aria-label="Search by name">
                            <button class="input-group-text" (click)="getFilterInputs()" for="search-by-name"><i
                                    class="bi bi-search"></i></button>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 mt-10">
                        <ng-select formControlName="filter_consumer_type" [items]="consumerType" bindLabel="title"
                            bindValue="id" (change)="getFilterInputs()" [multiple]="true" placeholder="Role type">
                            <!-- <ng-option *ngFor="let tag of consumerType" [value]="tag['id']"
                                [disabled]="tag['is_parent']">
                                {{tag['title']}}
                            </ng-option> -->
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items | slice:0:2; index as i">
                                    <span class="ng-value-label">{{
                                        items[i].title
                                        }}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </div>
                                <div class="ng-value" *ngIf="items.length > 2">
                                    <span class="ng-value-label">{{items.length - 2}} more...</span>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6  mt-10">
                        <ng-select formControlName="filter_experience_items" (change)="getFilterInputs()"
                            [items]="levelFilter" [multiple]="true" bindLabel="title" bindValue="value"
                            placeholder="Search by level">
                        </ng-select>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 mt-10">
                        <ng-select [items]="subjectsAndTags" (change)="getFilterInputs()" formControlName="filter_tags"
                            bindLabel="title" bindValue="data" groupBy="tags" [multiple]="true"
                            placeholder="Search by subjects and tags">
                            <ng-template ng-optgroup-tmp let-item="item">
                                {{item.title}}
                            </ng-template>
                        </ng-select>
                    </div>

                    <div class="col-md-4 col-sm-6 col-lg-4 mt-10">
                        <ng-select formControlName="filter_active_inactive" (change)="getFilterInputs()"
                            [items]="filtersArray.active_inactive" [searchable]="false" [multiple]="false"
                            bindLabel="title" bindValue="value">
                        </ng-select>
                    </div>
                    <div class="col-md-4 col-sm-6 col-lg-4 mt-10">
                        <ng-select formControlName="distance" (change)="getFilterInputs()"
                            [items]="filtersArray.distance_choices" [searchable]="false" [multiple]="false"
                            bindLabel="title" bindValue="value">
                        </ng-select>
                    </div>
                </div>
                <!-- <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-6 mt-10">
                                <button class="btn cc_p_btn fs_13" (click)="showSmartCreateModal()"><i
                                        class="bi bi-plus-lg mr-5"></i>smart
                                    group</button>
                            </div>
                        </div> -->
                <div class="d-flex mt-20">
                    <div>
                        <button class="btn c_s_btn max_c fs_13" (click)="showSmartCreateModal()"><i
                                class="bi bi-plus-lg mr-5"></i>Smart
                            group</button>
                    </div>
                    <button (click)="scrollLeft()" class="scroll_l_btn ml-5"><i class="bi bi-chevron-left"></i></button>
                    <div #widgetsContent class="d-flex smart_g_show">
                        <!-- <carousel [itemsPerSlide]="itemsPerSlide" [showIndicators]="false"  [indicatorsByChunk]="true" [singleSlideOffset]="singleSlideOffset" [noWrap]="!noWrap" [interval]="0"
                                [startFromIndex]="5" (slideRangeChange)="onSlideRangeChange($event)">
                                <slide *ngFor="let name of smart_groups; let index=index">
                                    <p  (click)="openTab(name['value'])" class="bg-tags carousel_tag  max_c c-p tag_c">{{name['title']}}</p>
                                </slide>
                            </carousel> -->
                        <div *ngFor="let name of smart_groups; let index=index">
                            <p [ngClass]="name['activeColor'] ? 'carousel_tag_a' : ''"
                                (click)="openTab(name['value'],'')"
                                class="bg-tags carousel_tag mr-5 ml-5  max_c c-p tag_c">{{name['title']}}</p>
                        </div>
                    </div>
                    <button (click)="scrollRight()" class="scroll_r_btn"><i class="bi bi-chevron-right"></i></button>
                </div>

                <!-- <div class="container" [ngStyle]="{'marginTop': '20px'}">
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" [(ngModel)]="noWrap">
                        Infinite
                      </label>
                    </div>
                  </div> -->

                <!-- <pre class="card card-block card-header">{{slidesChangeMessage}}</pre> -->
            </form>
            <hr>
            <nav>
                <div class="nav nav-tabs mt-10" id="nav-schoolList" role="tablist">
                    <button *ngIf="showTab" class="nav-link" [ngClass]="{ 'active':activeTab==='myPrefferedList'}"
                        (click)="myPrefferedList('myPrefferedList')" id="nav-myPrefferedList-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-myPrefferedList" type="button" role="tab"
                        aria-controls="nav-myPrefferedList" aria-selected="false">{{this.Tabtitle}}</button>

                    <button class="nav-link" [ngClass]="{ 'active':activeTab==='schoolList'}"
                        (click)="schoolList('schoolList')" data-bs-toggle="tab" data-bs-target="#nav-schoolList"
                        type="button" role="tab" aria-controls="nav-schoolList" aria-selected="true">All
                        teachers</button>

                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show" [ngClass]="{ 'active':activeTab==='schoolList'}" id="nav-schoolList"
                    role="tabpanel" aria-labelledby="nav-schoolList-tab">
                    <div class="">
                        <div class="row mt-20 clr-cc-vilot top-filter">
                            <div class="d-flex flex-wrap left-content gap-17">
                                <div class="c-p h_b mt-2" (click)="hideLevelSub()">
                                    <p *ngIf="tagsAndSubjects">
                                        <span>
                                            <i class="bi bi-eye-slash-fill"></i>
                                        </span>
                                        <span>
                                            Hide more
                                            details
                                        </span>
                                    </p>
                                    <p *ngIf="!tagsAndSubjects">
                                        <span>
                                            <i class="bi bi-eye"></i>
                                        </span>
                                        <span>
                                            View more
                                            details
                                        </span>
                                    </p>
                                </div>
                                <div class="selected-data message-btn h_b">
                                    <div class="dropdown sortByBR">
                                        <p data-bs-toggle="dropdown" class="ml-10 mt-2 d-flex dropdown-toggle h_b  c-p">
                                            <span><i class="bi bi-filter-left mr-5"></i></span>
                                            <span class="">Sort by</span>
                                        </p>
                                        <ul class="dropdown-menu c-p txt-regular">
                                            <!-- <li>
                                                <a class="dropdown-item" (click)="sortBy('availability')">Availability</a>
                                              </li> -->
                                            <li>
                                                <a class="dropdown-item" (click)="sortBy('name')">Name</a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" (click)="sortBy('lastSeen')">Last Seen</a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" (click)="sortBy('distance')">Distance</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="selected-data message-btn h_b">
                                    <p (click)="exportTeachersApi('my_list')" class="export_filter">
                                        <span>Export</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-15 sub-header">
                            <div class="col-lg-3 col-md-3 left-content">
                                <div class="d-flex">
                                    <input #checkAll [(ngModel)]="selectAllCheck" (change)="selectAll('All', $event)"
                                        class="form-check-input mt-1 mr-10  c-p" id="checkAll" type="checkbox">
                                    <!-- <label class="form-check-label c-p mb-0 mt-0 mr-10" for="checkAll">
                                        <span class="h_b" *ngIf="!selectAllCheck">Select all</span>
                                        <span class="h_b" *ngIf="selectAllCheck">Unselect all</span>
                                    </label> -->
                                </div>
                                <div class="selected-data c-p">
                                    <p class="text-regular-medium" *ngIf="consumerDetails.length == 0">
                                        {{teacherLengthLimit}} matches found</p>
                                    <p class="text-regular-medium" *ngIf="consumerDetails.length > 0">
                                        {{consumerDetails.length}} Selected</p>
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-9 flex-wrap gap-17 right-content top-filter">

                                <div [ngClass]="hideButton ? 'input-cursor-na':''"
                                    class="selected-data message-btn clr-cc-vilot">
                                    <i class="bi bi-plus-lg mr-2"></i>
                                    <p (click)="openSmartGroupModal('all' , '','','')">Add Selected</p>
                                </div>
                                <div [ngClass]="hideButton ? 'input-cursor-na':''"
                                    class="selected-data message-btn  clr-cc-vilot">
                                    <i class="bi bi-hand-index-thumb mr-5"></i>
                                    <p (click)="openNudgeModal('multipleList','','')">Nudge</p>
                                </div>
                                <div [ngClass]="hideButton ? 'input-cursor-na':''"
                                    class="selected-data message-btn  clr-cc-vilot">
                                    <i class="bi-eye-slash-fill mr-5"></i>
                                    <p (click)="OpenSuspendactionModal('multipleList','suspend')">Hide or Suspend</p>
                                </div>
                                <div [ngClass]="hideButton ? 'input-cursor-na':''"
                                    class="selected-data message-btn  clr-cc-vilot">
                                    <i class="bi bi-chat-left mr-4"></i>
                                    <p (click)="sendMailModal('email' , 'multi')">Message</p>
                                </div>


                            </div>
                        </div>

                        <!-- Pending Request List -->
                        <form [formGroup]="declineInviteForm">
                            <div class="" *ngIf="myList | async as list">
                                <div class="" *ngIf="list.length > 0">
                                    <div class="card mt-10 px-15" *ngFor="let teacher of list;  index as i"
                                        [ngClass]="!teacher.is_active ? 'teacher-inactive': ''">
                                        <div class="row align-items-center">
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="col-md-12 col-lg-12 d-flex">
                                                    <div class="pic-and-check1 c_input">
                                                        <input class="form-check-input mr-10 c-p"
                                                            (change)="selectAll(teacher, $event)"
                                                            [checked]="teacher.isChecked" type="checkbox"
                                                            id="{{teacher.teacher.id}}" type="checkbox">
                                                        <!-- <input type="hidden" formControlName="selectedValues" value="{{values}}"> -->
                                                    </div>
                                                    <div class="pic-and-check2 ml-0">
                                                        <img alt="img"
                                                            [src]="(teacher.teacher.meta.profile_image != null || teacher.teacher.meta.profile_image == '') ? teacher.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                            class="profile-picture img-complete-match">
                                                        <br>
                                                        <form [formGroup]="submitRatingsForm">
                                                            <div class="s_r_div">
                                                                <p class="text-center mt-2" *ngIf="teacher.rating == 0">
                                                                    <i class="bi bi-star"></i>
                                                                    <i class="bi bi-star ml-5"></i>
                                                                </p>
                                                                <p class="text-center" *ngIf="teacher.rating == 1">
                                                                    <i class="bi bi-star-fill"></i>
                                                                    <i class="bi bi-star ml-5"></i>
                                                                </p>
                                                                <p class="text-center" *ngIf="teacher.rating == 2">
                                                                    <i class="bi bi-star-fill"></i>
                                                                    <i class="bi bi-star-fill ml-5"></i>
                                                                </p>
                                                                <p class="text-center  d_2"
                                                                    *ngIf="teacher.rating == -2">
                                                                    <i class="bi bi-star-fill"></i>
                                                                    <i class="bi bi-star-fill ml-5"></i>
                                                                </p>
                                                                <p class="text-center d_1" *ngIf="teacher.rating == -1">
                                                                    <i class="bi bi-star-fill"></i>
                                                                    <i class="bi bi-star-fill ml-5"></i>
                                                                </p>
                                                                <div class="tooltiptext">
                                                                    <p class="text-center ratings_d"
                                                                        (click)="actions(teacher, 'update_rating','4')">
                                                                        <i class="bi bi-star-fill"></i>
                                                                        <i class="bi bi-star-fill ml-5"></i>
                                                                    </p>
                                                                    <p class="text-center ratings_d"
                                                                        (click)="actions(teacher, 'update_rating','3')">
                                                                        <i class="bi bi-star-fill"></i>
                                                                        <i class="bi bi-star ml-5"></i>
                                                                    </p>
                                                                    <p class="text-center ratings_d"
                                                                        (click)="actions(teacher, 'update_rating','2')">
                                                                        <i class="bi bi-star"></i>
                                                                        <i class="bi bi-star ml-5"></i>
                                                                    </p>
                                                                    <p class="text-center ratings_d d_1"
                                                                        (click)="actions(teacher, 'update_rating','1')">
                                                                        <i class="bi bi-star-fill"></i>
                                                                        <i class="bi bi-star-fill ml-5"></i>
                                                                    </p>
                                                                    <p class="text-center ratings_d d_2"
                                                                        (click)="actions(teacher, 'update_rating','0')">
                                                                        <i class="bi bi-star-fill"></i>
                                                                        <i class="bi bi-star-fill ml-5"></i>
                                                                    </p>
                                                                    <p class="text-center fo_t mt-5">
                                                                        <a href="https://intercom.help/classcover-support/en/articles/2087846-managing-stars"
                                                                            target="_blank">Find out more here</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <div class="pic-and-check3 ml-10">
                                                        <div class="d-flex">
                                                            <p>
                                                                <span class="s_n"
                                                                    (click)="getTeacherAvailabilityModal(teacher.teacher.id);">{{teacher.teacher.user.full_name}}</span>
                                                                <span>
                                                                    <app-validated-dict
                                                                        [validateDict]="teacher.teacher.validated_dict">
                                                                    </app-validated-dict>
                                                                </span>
                                                                <span *ngIf="teacher.teacher.is_complete == false"
                                                                    class="bg-tags-custom ml-10">Incomplete
                                                                    Profile
                                                                </span>
                                                                <span
                                                                    *ngIf="teacher.is_student && teacher.student_tag != ''"
                                                                    class="s_tag ml-10">{{
                                                                    teacher.student_tag }}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <p class="user-sub-title">
                                                            <span
                                                                *ngIf="teacher?.teacher?.meta.you_are_a">{{teacher?.teacher?.meta.you_are_a}}
                                                            </span>
                                                            <span *ngIf="teacher?.teacher?.meta.you_are_a"
                                                                class="divider-right ml-3 mr-5">
                                                            </span>
                                                            Active
                                                            {{teacher?.teacher?.user.previous_login}}
                                                        </p>
                                                        <p class="text-s-r-g-3">
                                                            Accreditation No : <span
                                                                *ngIf="teacher?.teacher?.accreditation.accreditation">
                                                                <span class="text-s-m-g-3"
                                                                    *ngIf="teacher?.teacher?.accreditation.accreditation.length > 0">{{teacher?.teacher?.accreditation.accreditation[0].accreditation_id}}</span>
                                                            </span>
                                                        </p>
                                                        <div class="form-check mt-1">
                                                            <input (change)="openCustomCheckModal($event , teacher)"
                                                                [checked]="teacher.is_certified"
                                                                id="is_certified{{teacher.id}}" type="checkbox"
                                                                class="form-check-input mt-4 c-p">
                                                            <label class="form-check-label c-p"
                                                                for="is_certified{{teacher.id}}">
                                                                {{custom_text}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12 lg-d-none  d-flex justify-content-right align-self-flex-start"
                                                [ngClass]="hideButton ? '':'input-cursor-na'">
                                                <span class="mr-35">
                                                    <div class="">
                                                        <p (click)="openNudgeModal('singleNudge',teacher.teacher.id,teacher.teacher.user.full_name)"
                                                            *ngIf="teacher?.nudge.show_nudge  && teacher?.nudge.can_nudge"
                                                            class="text-regular-bold c-p clr-cc-vilot">
                                                            <span><i class="bi bi-hand-index-thumb mr-5"></i></span>
                                                            <span>Nudge</span>
                                                        </p>
                                                        <p *ngIf="teacher?.nudge.show_nudge && !teacher?.nudge.can_nudge"
                                                            class="text-regular-bold c-p clr-cc-vilot">
                                                            <!-- <span><i class="bi bi-hand-index-thumb mr-5"></i></span> -->
                                                            <span>Nudged</span>
                                                        </p>
                                                        <p (click)="sendMailModal(teacher.teacher.id , '')"
                                                            *ngIf="!teacher?.nudge.show_nudge"
                                                            class="text-regular-bold c-p clr-cc-vilot">
                                                            <span><i class="bi bi-chat-left mr-5"></i></span>
                                                            <span>Message</span>
                                                        </p>
                                                    </div>
                                                    <div *ngIf="teacher.is_active" class="mt-15">
                                                        <p (click)="openSmartGroupModal(smart_groupsDuplicate , teacher.teacher.user.full_name , teacher.teacher.id,teacher.smart_group)"
                                                            class="text-regular-bold clr-cc-vilot c-p"><i
                                                                class="bi bi-plus-lg"></i>
                                                            Add to</p>
                                                    </div>
                                                </span>
                                                <i type="button" data-bs-toggle="dropdown"
                                                    [ngClass]="hideButton ? '':'input-cursor-na'" aria-expanded="false"
                                                    class="bi bi-gear-fill dropdown-toggle h_b"></i>
                                                <ul class="inline-action dropdown-menu txt-regular c-p">
                                                    <li><a class="dropdown-item"
                                                            (click)="actionModal(teacher, 'share')">Share</a></li>
                                                    <li>
                                                        <a *ngIf="teacher.is_active" class="dropdown-item"
                                                            (click)="actionModal(teacher, 'suspend')">Suspend</a>
                                                        <a *ngIf="!teacher.is_active" class="dropdown-item"
                                                            (click)="actionModal(teacher, 'reactivate')">Reactivate</a>
                                                    </li>
                                                    <li><a class="dropdown-item"
                                                            (click)="actionModal(teacher, 'remove')">Remove from
                                                            my list</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 level-sub-info mt-5" *ngIf="tagsAndSubjects">
                                            <div class="row ">
                                                <div class="col-md-2">
                                                    <p class="grey-3-14 mt-5">Levels</p>
                                                </div>
                                                <div class="col-md-9">
                                                    <p class="txt-regular tags"><span class="levels mt-5"
                                                            *ngFor="let levels of teacher.teacher.meta.experiance_tree">{{levels}},
                                                        </span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2  mt-5">
                                                    <p class="grey-3-14">Qualified in</p>
                                                </div>
                                                <div class="col-md-9">
                                                    <p class="txt-regular tags"><span class="qualified-in mt-5"
                                                            *ngFor="let qualified of teacher.teacher.meta.tags">{{qualified}},
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <p class="grey-3-14 mt-5">Smart groups</p>
                                                </div>
                                                <div class="col-md-9">
                                                    <p class="txt-regular tags"><span class="bg-tags mt-5"
                                                            *ngFor="let group of teacher.smart_group">{{group.group_name}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt-30" *ngIf="list?.length == 0">

                                    <p><img class="no-invite-image" src="assets/images/my_list_empty.png"
                                            alt="No invites found!" /></p>
                                    <p class="title">No matches found for the applied filters</p>
                                    <button class="btn cc_p_btn fs_13 mt-20" (click)="resetFilters();">Reset
                                        filters</button>
                                </div>
                            </div>
                        </form>
                        <!--End of Pending Request List -->

                    </div>
                </div>
                <!--End of my list Tab Content -->

                <!-- My Preffered List Tab Content -->
                <div class="tab-pane fade show" [ngClass]="{ 'active':activeTab==='myPrefferedList'}"
                    id="nav-myPrefferedList" role="tabpanel" aria-labelledby="nav-myPrefferedList-tab">
                    <div class="" *ngIf="smartGroupData.length > 0">
                        <div class="">
                            <div class="row mt-20 sub-header">
                                <div class="col-lg-4 col-md-4 left-content">
                                    <div class="d-flex">
                                        <input #checkAllSmartGroup [(ngModel)]="selectAllCheckSmartGroup"
                                            (change)="selectAllSmartGroup('All', $event)"
                                            class="form-check-input  c-p mt-0 mr-5" id="checkAllSmartGroup"
                                            type="checkbox">
                                        <label class="form-check-label  c-p mt-0 mb-0 mr-20" for="checkAllSmartGroup">
                                            <span class="h_b" *ngIf="!selectAllCheckSmartGroup">Select all</span>
                                            <span class="h_b" *ngIf="selectAllCheckSmartGroup">Unselect all</span>

                                        </label>
                                    </div>
                                    <div class="selected-data c-p">
                                        <p *ngIf="consumerDetailsSmartGroup.length == 0">{{smartGroupData.length}}
                                            Matches
                                            found</p>
                                        <p *ngIf="consumerDetailsSmartGroup.length > 0">
                                            {{consumerDetailsSmartGroup.length}} Selected</p>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8 flex-wrap gap-17 right-content top-filter">

                                    <!-- <div [ngClass]="hideButtonSmartGroup ? 'input-cursor-na':''"
                                        class="selected-data message-btn h_b">
                                        <i class="bi bi-plus-lg mr-2"></i>
                                        <p (click)="openSmartGroupModal('all' , '','','')">Add Selected</p>
                                    </div> -->
                                    <div [ngClass]="hideButtonSmartGroup ? 'input-cursor-na':''"
                                        class="selected-data message-btn  h_b">
                                        <i class="bi bi-hand-index-thumb mr-5"></i>
                                        <p (click)="openNudgeModal('multipleSmartList','','')">Nudge Selected</p>
                                    </div>

                                    <div [ngClass]="hideButtonSmartGroup ? 'input-cursor-na':''"
                                        class="selected-data message-btn  h_b">
                                        <i class="bi bi-chat-left mr-4"></i>
                                        <p (click)="sendMailModal('email' , 'multi')">Message</p>
                                    </div>

                                </div>
                            </div>

                            <!-- Pending Request List -->
                            <form [formGroup]="declineInviteForm">
                                <div class="">
                                    <div class="card mt-10 px-15" *ngFor="let teacher of smartGroupData;  index as i"
                                        [ngClass]="!teacher.is_active ? 'teacher-inactive': ''">
                                        <div class="row align-items-center">
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="col-md-12 col-lg-12 d-flex">
                                                    <div class="pic-and-check c_input">
                                                        <input class="form-check-input mr-10 c-p"
                                                            (change)="selectAllSmartGroup(teacher, $event)"
                                                            [checked]="teacher.isChecked" type="checkbox"
                                                            id="{{teacher.teacher.id}}" type="checkbox">
                                                        <!-- <input type="hidden" formControlName="selectedValues" value="{{values}}"> -->
                                                    </div>
                                                    <div class="pic-and-check ml-0">
                                                        <img alt="img"
                                                            [src]="teacher.teacher.meta.profile_image != null ? teacher.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                            class="profile-picture img-complete-match">
                                                        <br>
                                                        <form [formGroup]="submitRatingsForm">
                                                            <div class="s_r_div">
                                                                <p class="text-center mt-2" *ngIf="teacher.rating == 0">
                                                                    <i class="bi bi-star"></i>
                                                                    <i class="bi bi-star ml-5"></i>
                                                                </p>
                                                                <p class="text-center" *ngIf="teacher.rating == 1">
                                                                    <i class="bi bi-star-fill"></i>
                                                                    <i class="bi bi-star ml-5"></i>
                                                                </p>
                                                                <p class="text-center" *ngIf="teacher.rating == 2">
                                                                    <i class="bi bi-star-fill"></i>
                                                                    <i class="bi bi-star-fill ml-5"></i>
                                                                </p>
                                                                <p class="text-center  d_1"
                                                                    *ngIf="teacher.rating == -1">
                                                                    <i class="bi bi-star-fill"></i>
                                                                    <i class="bi bi-star-fill ml-5"></i>
                                                                </p>
                                                                <p class="text-center d_2" *ngIf="teacher.rating == -2">
                                                                    <i class="bi bi-star-fill"></i>
                                                                    <i class="bi bi-star-fill ml-5"></i>
                                                                </p>
                                                                <div class="tooltiptext">
                                                                    <p class="text-center ratings_d"
                                                                        (click)="actions(teacher, 'update_rating','4')">
                                                                        <i class="bi bi-star-fill"></i>
                                                                        <i class="bi bi-star-fill ml-5"></i>
                                                                    </p>
                                                                    <p class="text-center ratings_d"
                                                                        (click)="actions(teacher, 'update_rating','3')">
                                                                        <i class="bi bi-star-fill"></i>
                                                                        <i class="bi bi-star ml-5"></i>
                                                                    </p>
                                                                    <p class="text-center ratings_d"
                                                                        (click)="actions(teacher, 'update_rating','2')">
                                                                        <i class="bi bi-star"></i>
                                                                        <i class="bi bi-star ml-5"></i>
                                                                    </p>
                                                                    <p class="text-center ratings_d d_1"
                                                                        (click)="actions(teacher, 'update_rating','1')">
                                                                        <i class="bi bi-star-fill"></i>
                                                                        <i class="bi bi-star-fill ml-5"></i>
                                                                    </p>
                                                                    <p class="text-center ratings_d d_2"
                                                                        (click)="actions(teacher, 'update_rating','0')">
                                                                        <i class="bi bi-star-fill"></i>
                                                                        <i class="bi bi-star-fill ml-5"></i>
                                                                    </p>
                                                                    <p class="text-center fo_t mt-5">
                                                                        <a href="https://intercom.help/classcover-support/en/articles/2087846-managing-stars"
                                                                            target="_blank">Find out more here</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <div class="pic-and-check ml-10">
                                                        <div class="d-flex">
                                                            <p>
                                                                <span class="s_n"
                                                                    (click)="getTeacherAvailabilityModal(teacher.teacher.id);">{{teacher.teacher.user.full_name}}</span>
                                                                <span>
                                                                    <app-validated-dict
                                                                        [validateDict]="teacher.teacher.validated_dict">
                                                                    </app-validated-dict>
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <p class="user-sub-title">
                                                            <span
                                                                *ngIf="teacher?.teacher?.meta.you_are_a">{{teacher?.teacher?.meta.you_are_a}}
                                                            </span>
                                                            <span *ngIf="teacher?.teacher?.meta.you_are_a"
                                                                class="divider-right ml-3 mr-5">
                                                            </span>
                                                            Active
                                                            {{teacher?.teacher?.user.previous_login}}
                                                        </p>
                                                        <p class="text-s-r-g-3">
                                                            Accreditation No : <span
                                                                *ngIf="teacher?.teacher?.accreditation.accreditation">
                                                                <span class="text-s-m-g-3"
                                                                    *ngIf="teacher?.teacher?.accreditation.accreditation.length > 0">{{teacher?.teacher?.accreditation.accreditation[0].accreditation_id}}</span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12 lg-d-none text-right  d-flex justify-content-right align-self-flex-start"
                                                [ngClass]="hideButtonSmartGroup ? '':'input-cursor-na'">
                                                <span class="mr-35">
                                                    <div class="">
                                                        <p (click)="openNudgeModal('singleSmartNudge',teacher.teacher.id,teacher.teacher.user.full_name)"
                                                            *ngIf="teacher?.nudge.show_nudge && teacher?.nudge.can_nudge"
                                                            class="text-regular-bold c-p clr-cc-vilot">
                                                            <span><i class="bi bi-hand-index-thumb mr-5"></i></span>
                                                            <span>Nudge</span>
                                                        </p>
                                                        <p *ngIf="teacher?.nudge.show_nudge && !teacher?.nudge.can_nudge"
                                                            class="text-regular-bold c-p clr-cc-vilot">
                                                            <!-- <span><i class="bi bi-hand-index-thumb mr-5"></i></span> -->
                                                            <span>Nudged</span>
                                                        </p>
                                                        <p (click)="sendMailModal(teacher.teacher.id , '')"
                                                            *ngIf="!teacher?.nudge.show_nudge"
                                                            class="text-regular-bold c-p clr-cc-vilot">
                                                            <span><i class="bi bi-chat-left mr-5"></i></span>
                                                            <span>Message</span>
                                                        </p>
                                                    </div>
                                                    <div class="mt-16 c-p">
                                                        <p *ngIf="teacher.smart_group_type.is_backup_list"
                                                            class="text-regular-bold backup_clr"><i
                                                                class="bi bi-check2"></i>
                                                            Added to backup list</p>
                                                        <p *ngIf="teacher.smart_group_type.is_preferred_list"
                                                            class="text-regular-bold preferred_clr"><i
                                                                class="bi bi-check2"></i>
                                                            Added to preferred list</p>
                                                    </div>
                                                </span>
                                                <i type="button" data-bs-toggle="dropdown"
                                                    [ngClass]="hideButtonSmartGroup ? '':'input-cursor-na'"
                                                    aria-expanded="false"
                                                    class="bi bi-gear-fill dropdown-toggle h_b"></i>
                                                <ul class="inline-action dropdown-menu txt-regular c-p">
                                                    <li><a class="dropdown-item"
                                                            (click)="actionModal(teacher, 'share')">Share</a></li>
                                                    <li>
                                                        <a *ngIf="teacher.is_active" class="dropdown-item"
                                                            (click)="actionModal(teacher, 'suspend')">Suspend</a>
                                                        <a *ngIf="!teacher.is_active" class="dropdown-item"
                                                            (click)="actionModal(teacher, 'reactivate')">Reactivate</a>
                                                    </li>
                                                    <!-- <li><a class="dropdown-item"
                                                            (click)="actionModal(teacher, 'remove')">Remove from
                                                            my list</a>
                                                    </li> -->
                                                    <li><a class="dropdown-item"
                                                            (click)="OpenRemoveStaffsModal(teacher.id,teacher.smart_group_type)">Remove
                                                            from
                                                            smart group</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-12 col-lg-12 level-sub-info mt-5" *ngIf="tagsAndSubjects">
                                                                                                    <div class="row ">
                                                                                                        <div class="col-md-2">
                                                                                                            <p class="grey-3-14 mt-5">Levels</p>
                                                                                                        </div>
                                                                                                        <div class="col-md-9">
                                                                                                            <p class="txt-regular tags"><span class="levels mt-5"
                                                                                                                    *ngFor="let levels of teacher.teacher.meta.experiance_tree">{{levels}},
                                                                                                                </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="row">
                                                                                                        <div class="col-md-2  mt-5">
                                                                                                            <p class="grey-3-14">Qualified in</p>
                                                                                                        </div>
                                                                                                        <div class="col-md-9">
                                                                                                            <p class="txt-regular tags"><span class="qualified-in mt-5"
                                                                                                                    *ngFor="let qualified of teacher.teacher.meta.tags">{{qualified}},
                                                                                                                </span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="row">
                                                                                                        <div class="col-md-2">
                                                                                                            <p class="grey-3-14 mt-5">Smart groups</p>
                                                                                                        </div>
                                                                                                        <div class="col-md-9">
                                                                                                            <p class="txt-regular tags"><span class="bg-tags mt-5"
                                                                                                                    *ngFor="let group of teacher.smart_group">{{group.group_name}}</span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div> -->
                                    </div>
                                </div>
                            </form>
                            <!--End of Pending Request List -->

                        </div>
                    </div>

                    <div class="text-center mt-30" *ngIf="smartGroupData?.length == 0">
                        <p><img class="no-invite-image" src="assets/images/NoResultFound/no_data_for_backup_list.png"
                                alt="No invites found" /></p>
                        <p class="title">This Smart Group is currently empty. <a class="clr-cc-vilot"
                                href="https://intercom.help/classcover-support/en/articles/6761274-how-to-save-time-with-smart-groups">Learn
                                how to use Smart Groups</a></p>

                    </div>
                </div>
                <!-- End of My Preffered List Tab Content -->
            </div>
        </div>
        <div class="col-lg-3 col-12 hideON991">
            <div class="row mt-20">
                <div class="col-lg-12 col-12">
                    <div class="card px-15">
                        <p class="txt-regular">Upload or quick add your existing casual staff to your list</p>
                        <button (click)="openAddnewModal()" class="btn cc_p_btn fs_13 fit-content mt-10">Add
                            new
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-20">
                <app-health-check></app-health-check>
            </div>
            <app-invite-request-tab></app-invite-request-tab>

            <!-- <div class="row mt-20">
                <div class="col-lg-12 col-12">
                    <div class="card px-15">
                        <p class="text-s-m-g-3">Are you looking for more teachers to add to your list?</p>
                        <button class="btn cc_p_btn fs_13 mt-10" (click)="redirectToPostCallOut()">Post a
                            callout</button>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <!-- 
    <ng-template #createSmartGroupModal>
     
    </ng-template> -->


    <ng-template #suspendActionModals>
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">Suspend</h4>
            <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row  align-items-center">
                <div class="col-md-12">
                    <p class="text-regular-medium">Are you sure you want to suspend?</p>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button type="button" (click)="suspendActions()" class="btn cc_p_btn fs_13">Confirm</button>
        </div>
    </ng-template>
    <ng-template #actionModals>
        <div class="modal-header">
            <h4 class="modal-title" *ngIf="actionType == 'suspend'" id="exampleModalLabel">Suspend {{teacherName}}</h4>
            <h4 class="modal-title" *ngIf="actionType == 'remove'" id="exampleModalLabel">Remove {{teacherName}}</h4>
            <h4 class="modal-title" *ngIf="actionType == 'share'" id="exampleModalLabel">Share {{teacherName}}</h4>
            <h4 class="modal-title" *ngIf="actionType == 'reactivate'" id="exampleModalLabel">Reactivate {{teacherName}}
            </h4>

            <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row  align-items-center">
                <div class="col-md-12">
                    <p class="text-regular-medium" *ngIf="actionType == 'suspend'">Are you sure you want to suspend
                        {{teacherName}}?</p>
                    <p class="text-regular-medium" *ngIf="actionType == 'remove'">Are you sure you want to remove
                        {{teacherName}}?</p>
                    <p class="text-regular-medium" *ngIf="actionType == 'reactivate'">Are you sure you want to
                        reactivate {{teacherName}}?</p>
                    <div class="" *ngIf="actionType == 'share'">
                        <p class="text-regular-medium">Are you sure you want to share {{teacherName}}?</p>
                        <form [formGroup]="shareStaffProfile">
                            <input type="text" placeholder="Enter email id" formControlName="to_mail"
                                class="form-control mt-10">
                            <div *ngIf="submitted">
                                <span class="show-errors" *ngIf="f['to_mail'].errors?.['required']">* Email id is
                                    required</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button type="button" (click)="actions(teacher, actionType,'')" class="btn cc_p_btn fs_13">Confirm</button>
        </div>
    </ng-template>

    <ng-template #sendEmailModal>
        <form [formGroup]="sendEmailForm">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Send email</h4>
                <button type="button" (click)="closeSendMailModal()" class="btn-close" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-0">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <label for="exampleFormControlTextarea1" class="form-label txt-regular mt-10">Type a message to
                            send. This can be to remind them about their availability, new school check in times,etc...
                            (max 280 characters).</label>
                        <textarea class="form-control mt-5" formControlName="email_content" (keyup)="checkLength()"
                            maxlength=280 placeholder="Add Message here..." id="exampleFormControlTextarea1"
                            rows="6"></textarea>
                        <div *ngIf="submitted">
                            <span class="show-errors" *ngIf="m['email_content'].errors?.['required']">* Message is
                                required</span>
                        </div>

                        <label for="support_url" class="form-label txt-regular mt-15">Supporting link
                            (optional) - Add a link to include with your message</label>
                        <input class="form-control mt-5" formControlName="support_url" placeholder="https://"
                            id="support_url">

                        <div class="form-check mt-15" *ngIf="msgType == 'multi'">
                            <input id="selectAllList" (change)="checkBroad($event)" type="checkbox" class="form-check-input mt-4 c-p">
                            <label class="form-check-label c-p" for="selectAllList">
                                Select all teachers ({{this.teacherLengthLimit}}) - This will send an email to everyone
                                on your list regardless of their status</label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer mt-10">
                <button type="button" (click)="sendEmail(teacherId)" class="btn cc_p_btn fs_13">Send</button>
            </div>
        </form>
    </ng-template>

    <ng-template #nudgeModal>
        <form [formGroup]="moveToForm">
            <div class="modal-header">
                <h4 *ngIf="this.sendNudgeform.controls['nudgeFrom'].value == 'multipleSmartList'" class="modal-title"
                    id="exampleModalLabel">Confirm</h4>
                <h4 *ngIf="this.sendNudgeform.controls['nudgeFrom'].value == 'multipleList'" class="modal-title"
                    id="exampleModalLabel">Confirm</h4>
                <h4 *ngIf="this.sendNudgeform.controls['nudgeFrom'].value == 'singleSmartNudge'" class="modal-title"
                    id="exampleModalLabel">You are nudging to {{nudgeUserName}}</h4>
                <h4 *ngIf="this.sendNudgeform.controls['nudgeFrom'].value == 'singleNudge'" class="modal-title"
                    id="exampleModalLabel">You are nudging to {{nudgeUserName}}</h4>
                <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-0">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <p class="txt-regular">You will only be able to nudge them again after 7 days.
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer mt-10">
                <button type="button" (click)="sendNudge(nudgeTeacherId)" class="btn cc_p_btn fs_13">
                    Send nudge
                </button>
            </div>
        </form>
    </ng-template>

    <ng-template #customCheckModal>
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">Custom Field</h4>
            <button type="button" (click)="closeCustomBoxModal(teacher)" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <p class="txt-regular" *ngIf="this.is_checked">
                        Confirm you want to check this?
                    </p>
                    <p class="txt-regular" *ngIf="!this.is_checked">
                        Confirm you want to un-check this?
                    </p>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button type="button" (click)="closeCustomBoxModal(teacher)" class="btn_cc_back h_b">Cancel</button>
            <button type="button" (click)="actions(teacher,'customCheck','' )" class="btn cc_p_btn fs_13">
                Confirm
            </button>
        </div>
    </ng-template>

    <ng-template #RemoveStaffsModal>
        <form [formGroup]="moveToForm">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Confirmation</h4>
                <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-0">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <p class="txt-regular">Are you sure you want to remove the following teachers from this Smart
                            Group?
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer mt-10">
                <button type="button" (click)="bsModalRef.hide()" class="btn_cc_back h_b">Cancel</button>
                <button type="button" (click)="RemoveFromList(this.selectedGroupID,teacher_id,this.action)"
                    class="btn cc_p_btn fs_13">
                    Remove
                </button>
            </div>
        </form>
    </ng-template>

    <ng-template #smartGroupModal>
        <form [formGroup]="sendSelectedSmartGroup">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Add to smart groups</h4>
                <button type="button" (click)="closeAddToModal()" class="btn-close" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-0">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <p class="text-regular-medium">Select smart groups in which you want to add <span
                                *ngIf="teacher_name != ''">{{teacher_name}}</span>
                        </p>
                        <p><i class="bi bi-info-circle-fill mr-5 grey-3"></i> <span class="grey-3-14">You can choose
                                multiple smart groups</span>
                        </p>

                        <div class="d-flex flex-wrap mt-5">
                            <button *ngFor="let name of smart_groups; let index=index"
                                [ngClass]="{bad_active:name['selected'] , disabled :name['existing'] }"
                                (click)="selectSmartGroup(name['value'] , name['existing'])"
                                class="smart_cus_badge pl-8 pr-8 mr-5  mt-10 max_c c-p tag_c">
                                {{name['title']}}
                                <span *ngIf="name['selected'] == true"><i class="bi bi-x-lg ml-3"></i></span>
                            </button>
                            <button class="bad_active" (click)="selectAllGroups(smart_groups)"
                                class="smart_cus_badge pl-8 pr-8 mr-5  mt-10 max_c c-p tag_c">

                                <!-- <span><i class="bi bi-plus mr-1"></i></span> -->
                                Add all
                                <!-- <span><i class="bi bi-x-lg ml-3"></i></span> -->
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center addToCheck">
                    <div class="col-md-12">
                        <h5 class="mt-20">Add to</h5>
                        <div class="ml-25">
                            <div class="form-check">
                                <input class="form-check-input mt-5" formControlName="action" value="preferred_list"
                                    type="radio" name="action" id="preffered" />
                                <label class="form-check-label" for="preffered">
                                    Preffered list
                                </label>
                            </div>
                            <div class="form-check mt-5">
                                <input class="form-check-input" formControlName="action" value="backup_list"
                                    type="radio" name="action" id="backup" />
                                <label class="form-check-label" for="backup">
                                    Backup list
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer mt-10">
                <!-- <button type="button" (click)="closeAddToModal()" class="btn_cc_back h_b">Cancel</button> -->
                <button type="button" (click)="sendSmartList(smart_groups,selectedType)"
                    class="btn cc_p_btn pl-25 pr-25 fs_13">
                    Save
                </button>
            </div>
        </form>
    </ng-template>
</div>