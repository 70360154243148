import { Component } from '@angular/core';
import { SchoolData } from 'src/app/models/schoolUser';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-onboarding-school',
  templateUrl: './onboarding-school.component.html',
  styleUrls: ['./onboarding-school.component.scss']
})
export class OnboardingSchoolComponent {
  public schoolProps!: SchoolData;
  profile_percent: any;
  step: any;

  
  constructor(
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private _router:Router
  ) { }

  ngOnInit(): void {
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.route.queryParams.subscribe(params => {
      this.step = params['step'];
    });
    if(this.step == undefined){
      this._router.navigate(['/school/school-onboarding'], { queryParams: { step: '_one' } });
    }

   this.profile_percent = 'width:' + this.schoolProps.properties.profile_percentage + '%';
   this.ngxService.stop()
  }

  
}
