<div *ngIf="this.checkToken != ''" class=""
  [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user': 'school-profile-page'">
  <!-- Heading -->
  <!-- body content -->
  <div class="row pb-30 pt-20">
    <div class="row" *ngIf="onboardingProcess">
      <div class="col-lg-12 col-md-12 col-12">
        <a class="clr-cc-vilot user-title c-p" (click)="stepTwo()"><i class="bi bi-arrow-left-short"></i> Back</a>
      </div>
      <div class="d-flex justify-content-center mt-10 mb-30 ">
        <div class="on_border com_1"></div>
        <div class="on_border com_2"></div>
        <div class="on_border com_3"></div>
      </div>
    </div>

    <!-- First half -->
    <div class="col-lg-8 col-md-12 col-sm-12 col-12 mb-15">
      <div class="card px-15">
        <div class="row">
          <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10">
            <div class="align-items-center d-flex">
              <p class="wrapper">
                <img alt="" class="avatar" [src]="
                    profileData?.additional_info?.profile_image == null ||
                    profileData?.additional_info?.profile_image == ''
                      ? '../../../../assets/images/pro_avatar.png'
                      : profileData?.additional_info?.profile_image
                  " />
              </p>
              <div class="ml-10">
                <p class="c-p ul-title">{{ profileData?.title }}</p>
                <!-- <p *ngIf="this.schoolProps.properties.organization_type == 'school'" class="text-r-18 mt-2">
                                    <span>Primary School</span>
                                    <span><i class="bi bi-dot"></i></span>
                                    <span>Primary School</span>
                                </p> -->
                <p class="txt-regular mt-2">
                  <i class="bi bi-geo-alt-fill"></i>
                  {{ profileData?.address?.city }},
                  {{ profileData?.address.address_timezone.state }}
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="viewAsProducer == undefined" class="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
            <div class="d-flex justify-content-end">
              <span class="c-p text-regular-bold clr-cc-vilot" (click)="showEditProfInfoModal(profileData)"><i
                  class="bi bi-pencil-fill fs-18"></i> Edit</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.schoolProps.properties.job_only_school == false" class="card px-15 mt-16">
        <div class="row">
          <div class="col-lg-6 col-md-7 col-sm-5">
            <h5 class="user-title">Recent Announcements</h5>
          </div>
          <div *ngIf="viewAsProducer == undefined" class="col-lg-6 col-md-5 col-sm-7">
            <div class="d-flex justify-content-end">
              <p class="c-p mr-20 text-regular-bold clr-cc-vilot" (click)="showSchoolAnnModal()">
                View More
              </p>

              <p (click)="shownewAnnouncementModal()" class="text-regular-bold clr-cc-vilot c-p">
                <i class="bi bi-plus-circle-fill"></i> New Announcement
              </p>
            </div>
          </div>
        </div>
        <div *ngFor="
            let announcements of profileData?.additional_info?.announcements;
            index as i
          ">
          <div class="row mt-20" *ngIf="i < 2">
            <div class="col-lg-10 col-md-10 col-12">
              <small class="text-r-g-3">{{ announcements?.date }}</small>
              <p class="text-regular-medium">
                {{ announcements?.announcement }}
              </p>
            </div>
            <div *ngIf="viewAsProducer == undefined" class="col-lg-2 col-md-10 col-12 d-flex clr-cc-vilot">
              <i class="bi bi-pencil-square  fs-20 mx-10 c-p" (click)="editAnnoucement(i)"></i>
              <i class="bi bi-x-circle-fill fs-20 mx-10 c-p" (click)="removeAnnoucement(i)"></i>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="this.schoolProps.properties.job_only_school == false" class="card px-15 mt-16">
        <div class="row">
          <div class="col-lg-10 col-md-10 col-sm-9 col-xs-9 col-9">
            <h5 class="ul-title">Overview</h5>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3">
            <p *ngIf="viewAsProducer == undefined" class="c-p text-right text-regular-bold clr-cc-vilot"
              (click)="showOverviewModal(profileData)">
              <i class="bi bi-pencil-fill fs-18"></i> Edit
            </p>
          </div>
        </div>
        <div class="mt-5 txt-regular">
          <p class="mt-10 pre-line" [innerHTML]="profileData?.overview"></p>
        </div>
        <div class="mt-10">
          <div class="row align-items-center">
            <div class="col-lg-4">
              <div class="text-r-g-3">School Size</div>
            </div>
            <div class="col-lg-8">
              <span class="txt-regular">{{ profileData?.subscription?.enrolment_size }} students
                <i class="bi bi-dot"></i> {{ profileData?.additional_info?.teachers_size }} staff</span>
            </div>
          </div>

          <div class="row align-items-center mt-20">
            <div class="col-lg-4">
              <div class="text-r-g-3">School Type</div>
            </div>
            <div class="col-lg-8">
              <span class="txt-regular">{{
                profileData?.subscription?.display_school_type
                }}</span>
            </div>
          </div>

          <div class="row align-items-center mt-20">
            <div class="col-lg-4">
              <div class="text-r-g-3">Principal</div>
            </div>
            <div class="col-lg-8">
              <span class="txt-regular">{{
                profileData?.additional_info?.school_principal_full_name
                }}</span>
            </div>
          </div>

          <div class="row align-items-center mt-20">
            <div class="col-lg-4">
              <div class="text-r-g-3">Gender</div>
            </div>
            <div class="col-lg-8">
              <span class="txt-regular">{{
                profileData?.additional_info?.school_gender
                }}</span>
            </div>
          </div>

          <div class="row align-items-center mt-20">
            <div class="col-lg-4">
              <div class="text-r-g-3">School Contact</div>
            </div>
            <div class="col-lg-8">
              <span class="txt-regular clr-cc-vilot">{{ profileData?.email }}</span>
              <span class="txt-regular">
                <i class="bi bi-dot"></i> {{ profileData?.phone_number }}</span>
            </div>
          </div>
          <div class="row align-items-center mt-20">
            <div class="col-lg-4">
              <div class="text-r-g-3">Website</div>
            </div>
            <div class="col-lg-8">
              <span class="txt-regular clr-cc-vilot">{{ profileData?.additional_info?.school_website }}</span>
            </div>
          </div>

          <div class="row align-items-center mt-20">
            <div class="col-lg-4">
              <div class="text-r-g-3">RTA Contact</div>
            </div>
            <div class="col-lg-8">
              <span class="txt-regular">{{ profileData?.rta_user?.user?.full_name }}
                <i class="bi bi-dot"></i>{{ profileData?.rta_user?.phone }}</span>
            </div>
          </div>

          <div class="row align-items-center mt-20">
            <div class="col-lg-4">
              <div class="text-r-g-3">School level</div>
            </div>
            <div class="col-lg-8">
              <span class="txt-regular">{{ profileData?.subscription?.school_level_display }}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.schoolProps.properties.job_only_school == true" class="card px-15 mt-16">
        <div class="row">
          <div class="col-lg-10 col-md-10 col-sm-9 col-xs-9 col-9">
            <h5 class="ul-title">Overview</h5>
          </div>
          <div *ngIf="viewAsProducer == undefined" class="col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3">
            <p *ngIf="this.schoolProps.properties.organization_type == 'school'"
              class="c-p text-right text-regular-bold clr-cc-vilot" (click)="showOverviewJobModal(profileData)">
              <i class="bi bi-pencil-fill fs-18"></i> Edit
            </p>
            <p *ngIf="this.schoolProps.properties.organization_type == 'company'" class="c-p text-right text_medium"
              (click)="showCompanyOverviewModal(profileData)">
              <i class="bi bi-pencil-fill fs-18"></i> Edit
            </p>
          </div>
        </div>
        <div class="mt-5 txt-regular">
          <p class="mt-10 pre-line" [innerHTML]="profileData?.overview"></p>
        </div>
        <div class="mt-10">
          <div class="row align-items-center">
            <div *ngIf="this.schoolProps.properties.organization_type == 'school'" class="row align-items-center">
              <div class="col-lg-4">
                <div class="text-r-g-3">School Type</div>
              </div>
              <div class="col-lg-8">
                <span class="txt-regular">{{
                  profileData?.subscription?.display_school_type
                  }}</span>
              </div>
            </div>

            <div class="row align-items-center mt-20">
              <div class="col-lg-4">
                <div *ngIf="
                    this.schoolProps.properties.organization_type == 'school'
                  " class="text-r-g-3">
                  School Contact
                </div>
                <div *ngIf="
                    this.schoolProps.properties.organization_type == 'company'
                  " class="text-r-g-3">
                  Website and contact information
                </div>
              </div>
              <div class="col-lg-8">
                <span class="txt-regular  clr-cc-vilot">{{
                  profileData?.email
                  }}</span>
                <span class="txt-regular">
                  <i class="bi bi-dot mr-1 ml-5"></i>
                  {{ profileData?.phone_number }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.schoolProps.properties.job_only_school == false" class="card px-15 mt-16">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
            <h5 class="ul-title">Onboarding Documents</h5>
          </div>
          <div *ngIf="viewAsProducer == undefined" class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
            <p class="c-p text-regular-bold clr-cc-vilot text-right" (click)="showOnboardDocModal()">
              <i class="bi bi-plus-circle-fill"></i> New Onboarding Documents
            </p>
          </div>
        </div>
        <div class="mt-10">
          <div class="row align-items-center" *ngFor="
              let documents of profileData?.additional_info
                ?.onboarding_documents;
              index as i
            ">
            <div class="col-lg-4">
              <div class="text-r-g-3">{{ documents?.doc_type }}</div>
            </div>
            <div class="col-lg-4">
              <a href="{{ documents?.doc_url }}" target="_blank">
                <span class="txt-regular clr-cc-vilot">View Document <i class="bi bi-box-arrow-up-right"></i></span>
              </a>
            </div>
            <div *ngIf="viewAsProducer == undefined" class="col-lg-4 d-flex justify-content-end clr-cc-vilot">
              <i class="bi bi-pencil-square fs-20 mx-10 c-p" (click)="editOnBoarding(i)"></i>
              <i class="bi bi-x-circle-fill fs-20 mx-10 c-p" (click)="removeOnBoarding(i)"></i>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="this.schoolProps.properties.job_only_school == false && profileData?.admin_settings?.casuals_cancel_booking?.is_casual_cancel == '1'"
        class="card px-15 mt-16">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-10 col-xs-9 col-9">
            <h5 class="ul-title">School contact for cancellations <span class="plan-badge">Cancellations enabled</span>
            </h5>
          </div>
          <div *ngIf="viewAsProducer == undefined" class="col-lg-3 col-md-3 col-sm-2 col-xs-3 col-3">
            <p class="c-p text-regular-bold clr-cc-vilot text-right" (click)="editCancelDetailsModal()">
              <i class="bi bi-pencil-fill"></i> Edit
            </p>
          </div>
          <div>
            <div class="mt-10">
              <div class="row align-items-center">
                <div class="col-lg-4">
                  <div class="text-r-g-3">Email</div>
                </div>
                <div class="col-lg-4">
                  <div class="txt-regular">{{profileData.additional_info.booking_cancel_casuals.cancellation_email}}
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-10">
              <div class="row align-items-center">
                <div class="col-lg-4">
                  <div class="text-r-g-3">Phone number</div>
                </div>
                <div class="col-lg-4">
                  <div class="txt-regular">{{profileData.additional_info.booking_cancel_casuals.cancellation_phone}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="this.schoolProps.properties.job_only_school == false" class="card px-15 mt-16">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
            <h5 class="ul-title">Additional Information</h5>
          </div>
          <div *ngIf="viewAsProducer == undefined" class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
            <p class="c-p text-regular-bold clr-cc-vilot text-right" (click)="showEditeAdditionalModal()">
              <i class="bi bi-plus-circle-fill"></i> New Additional Information
            </p>
          </div>
          <div *ngFor="
              let additional_info of profileData?.additional_info
                ?.additional_information;
              index as i
            ">
            <div class="row mt-20" *ngIf="i < 2">
              <div class="col-10">
                <p class="text-regular-medium">
                  {{ additional_info?.title }}
                </p>
                <small class="text-r-g-3">{{ additional_info?.content }}</small>
              </div>
              <div *ngIf="viewAsProducer == undefined" class="col-2 d-flex clr-cc-vilot">
                <i class="bi bi-pencil-square fs-20 mx-10 c-p" (click)="editAdditionalInfo(i)"></i>
                <i class="bi bi-x-circle-fill fs-20 mx-10 c-p" (click)="removeAdditionalInfo(i)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card px-15 mt-16">
        <h5 *ngIf="this.schoolProps.properties.organization_type == 'school'" class="ul-title mb-20">
          School Location
        </h5>
        <h5 *ngIf="this.schoolProps.properties.organization_type == 'company'" class="ul-title mb-20">
          Location
        </h5>
        <iframe sandbox="allow-scripts" width="100%" class="e2e-iframe-trusted-src" height="380"
          [src]="this.mapsUrl"></iframe>
      </div>
    </div>
    <!-- Second Half -->
    <div *ngIf="this.schoolProps.properties.job_only_school == false" class="col-lg-4 col-md-12 col-sm-12 col-12">
      <div class="row">
        <div *ngIf="viewAsProducer == undefined" class="d-flex align-items-center">
          <p class="text-regular-bold mr-5">Receive teacher requests</p>
          <label class="switch m-0">
            <input [(ngModel)]="show_school" (change)='onCheckboxChange($event)' type="checkbox" />
            <span class="slider round"></span>
          </label>
        </div>
        <div *ngIf="viewAsProducer == undefined && !onboardingProcess">
          <p class="text-regular-bold c-p mt-10 h_b" (click)="viewAsTeacher()">View as Teacher <i
              class="bi bi-arrow-up-right"></i></p>
        </div>

        <div class="col-12" *ngIf="viewAsProducer == undefined">
          <div class="card px-15 mt-15">
            <p class="ul-title">Profile Completion</p>
            <p class="mb-0" *ngIf="recentJobsList?.profile_percentage < '100'">Upload your school crest to get to 100%.
            </p>
            <div class="row align-items-center text-s-m-g-3">
              <div class="col-lg-8 col-md-8 col-sm-12 col-12 d-flex align-items-center">
                <i class="bi bi-lightbulb mr-10"></i>
                <p class="mb-0">
                  Profiles at 100% completion get their jobs ads featured in
                  their location.
                </p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                <div class="text-center">
                  <circle-progress [percent]="recentJobsList?.profile_percentage" [radius]="25" [outerStrokeWidth]="5"
                    [innerStrokeWidth]="5" [outerStrokeColor]="'#83D367'" [innerStrokeColor]="'#ECEFF2'"
                    [animation]="true" [animationDuration]="300" [space]="-5" [showSubtitle]="false"
                    [titleFontSize]="'14'" [unitsFontSize]="'14'" [unitsColor]="'#4F4F4F'"
                    [titleColor]="'#4F4F4F'"></circle-progress>
                  <span class="ml-2">Complete</span>
                </div>
              </div>
            </div>
            <p class="text-center" *ngIf="recentJobsList?.profile_percentage < 100">
              <button class="btn cc_p_btn fs_13 ml-10 br-30" (click)="completeProfile()">
                Complete Profile
              </button>
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="!onboardingProcess">
        <div class="row" [ngClass]="viewAsProducer == undefined ? ' mt-16' : ''"
          *ngIf="recentJobsList?.job_list?.length > 0">
          <div class="col-12">
            <div class="card px-15 ">
              <h4 class="ul-title">Recent Jobs Posted</h4>
              <div class="row  h_b c-p" *ngFor="let job of recentJobsList?.job_list; index as i">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12" (click)="openAngularJobDetail(job.id)">
                  <h6 class="pt-3 mb-4 user-title mr-6">
                    {{ job?.job_title }}
                    <span class="qualified-in mt-5 bg-tags p-2">{{
                      job?.job_type?.job_type_display
                      }}</span>
                  </h6>
                  <small class="txt-regular">{{ job?.school?.title }}<i class="bi bi-dot"></i>{{
                    job?.school_level?.school_level_display }}</small><br />
                  <small class="text-r-g-3"><i class="bi bi-geo-alt-fill bi-geo-alt-fill-public"></i>
                    {{ job?.location }}</small>
                </div>
                <hr class="mt-8 mb-8" />
              </div>
              <p class="c-p clr-cc-vilot text-center user-title mt-5 mb-10" (click)="openAngularJobs()">
                View all
                <i class="bi bi-arrow-up-right"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="this.schoolProps.properties.job_only_school == true" class="col-lg-4 col-md-12 col-sm-12 col-12">
      <div *ngIf="viewAsProducer == undefined">
        <p class="text-regular-bold c-p h_b" (click)="viewAsTeacher()">View as Teacher <i
            class="bi bi-arrow-up-right"></i></p>
      </div>
      <div *ngIf="!onboardingProcess">
        <div class="row" *ngIf="recentJobsList?.job_list?.length > 0">
          <div class="col-12">
            <div class="card px-15" [ngClass]="viewAsProducer == undefined ? ' mt-16' : ''">
              <h4 class="ul-title">Recent Jobs Posted</h4>
              <div class="row " *ngFor="let job of recentJobsList?.job_list; index as i">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 c-p" (click)="openAngularJobDetail(job.id)">
                  <!-- <div class="d-flex">
                  <h6
                    class="pt-3 mb-4 title-profile-second-head justify-content-start"
                  >
                    {{ job?.job_title }}
                  </h6>
                  <span
                    class="qualified-in mt-5 bg-tags p-2 ml-6 justify-content-end"
                    >{{ job?.job_type?.job_type_display }}</span
                  >
                </div> -->
                  <h6 class="pt-3 mb-4 user-title mr-6">
                    {{ job?.job_title }}
                    <span class="qualified-in mt-5 bg-tags p-2">{{
                      job?.job_type?.job_type_display
                      }}</span>
                  </h6>
                  <small class="txt-regular">{{ job?.school?.title }}<i class="bi bi-dot"></i>{{
                    job?.school_level?.school_level_display }}</small><br />
                  <small class="text-r-g-3"><i class="bi bi-geo-alt-fill bi-geo-alt-fill-public"></i>
                    {{ job?.location }}</small>
                </div>
                <hr class="mt-8 mb-8" />
              </div>
              <p class="c-p clr-cc-vilot text-center user-title mt-5 mb-10" (click)="openAngularJobs()">
                View all
                <i class="bi bi-arrow-up-right"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit school Additional Overview model -->
    <ng-template #editSchoolOverviewModal>
      <div class="modal-header">
        <h4 class="model_headings" id="editAdditionalInformation">
          Edit Overview
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
      </div>
      <form [ngClass]="
          this.schoolProps.properties.job_only_school == true
            ? 'jobs_only_user'
            : 'school-profile-page'
        " [formGroup]="overviewForm">
        <div class="modal-body pb-0 pt-0">
          <!-- <div class="row">
                    <div class="col-lg-12">
                        <small class="addtional_information_text">This information will only be viewable to teachers on
                            your
                            school list.</small>
                    </div>
                </div> -->
          <div class="row">
            <div class="col-lg-12 mt-20">
              <label class="form-label">Email *</label>
              <input formControlName="school_email" value="{{ profileData?.email }}" placeholder="Enter here"
                type="text" class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['school_email'].errors?.['required'] || po['school_email'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>
            <div class="col-lg-6 mt-20">
              <label class="form-label">Contact Number *</label>
              <input formControlName="contact_number" value="{{ profileData?.phone_number }}"
                placeholder="Enter Contact number" type="text" class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['contact_number'].errors?.['required'] || po['contact_number'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
            <div *ngIf="this.schoolProps.properties.organization_type == 'school'" class="col-lg-6 mt-20">
              <label class="form-label">Type *</label>
              <ng-select [dropdownPosition]="'bottom'" placeholder="Enter School type" [searchable]="false"
                formControlName="school_type" [multiple]="false" placeholder="School type">
                <ng-option *ngFor="let type of school_type" value="{{ type.value }}" data-title="{{ type.value }}">{{
                  type.title }}
                </ng-option>
              </ng-select>
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['school_type'].errors?.['required'] || po['school_type'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>
            <div class="col-lg-6 mt-20">
              <label class="form-label">School Emergency Contact Number</label>
              <input formControlName="school_emergency_phone_number" value="{{ profileData?.phone_number }}"
                placeholder="Emergency Contact number" type="text" class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['school_emergency_phone_number'].errors?.['required'] || po['school_emergency_phone_number'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>

            <div class="col-lg-6 mt-20">
              <label class="form-label">School Principal</label>
              <input formControlName="school_principal_full_name" value="{{ profileData?.phone_number }}"
                placeholder="Enter Name" type="text" class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['school_principal_full_name'].errors?.['required'] || po['school_principal_full_name'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 mt-20">
              <label class="form-label">RTA Contact Name</label>
              <input formControlName="rta_name" value="{{ profileData?.phone_number }}" placeholder="RTA contact name"
                [attr.disabled]="true" type="text" class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['rta_name'].errors?.['required'] || po['rta_name'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>
            <div class="col-lg-6 mt-20">
              <label class="form-label">RTA Contact Number</label>
              <input formControlName="rta_number" value="{{ profileData?.phone_number }}"
                placeholder="RTA Contact Number" [attr.disabled]="true" type="text" class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['rta_number'].errors?.['required'] || po['rta_number'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 mt-20">
              <label class="form-label">School Size</label>
              <ng-select [dropdownPosition]="'bottom'" placeholder="Enter School Size" [searchable]="false"
                formControlName="enrolment_size" [multiple]="false" placeholder="School type">
                <ng-option value="">Select</ng-option>

                <ng-option data-title="<21" value="<21">&lt; 21</ng-option>

                <ng-option data-title="21-50" value="21-50">21-50</ng-option>

                <ng-option data-title="51-100" value="51-100">51-100</ng-option>

                <ng-option data-title="101-200" value="101-200">101-200</ng-option>
                <ng-option data-title="201-300" value="201-300">201-300</ng-option>
                <ng-option data-title="301-400" selected="" value="301-400">301-400</ng-option>
                <ng-option data-title="401-500" value="401-500">401-500</ng-option>
                <ng-option data-title="501-600" value="501-600">501-600</ng-option>
                <ng-option data-title="601-700" value="601-700">601-700</ng-option>
                <ng-option data-title="701-800" value="701-800">701-800</ng-option>
                <ng-option data-title="801-900" value="801-900">801-900</ng-option>
                <ng-option data-title="901-1000" value="901-1000">901-1000</ng-option>
                <ng-option data-title="1001" value=">1001">&gt; 1001</ng-option>
                <ng-option data-title="Special(<150)" value="Special(<150)">Special(&lt; 150)</ng-option>
                <ng-option data-title="Unclassified" value="Unclassified">Unclassified</ng-option>
              </ng-select>
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['enrolment_size'].errors?.['required'] || po['enrolment_size'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
            <div class="col-lg-6 mt-20">
              <label class="form-label">Faculty count</label>
              <ng-select [dropdownPosition]="'bottom'" placeholder="Enter Faculty count" [searchable]="false"
                formControlName="teachers_size" [multiple]="false" placeholder="School type">
                <ng-option value="">Select</ng-option>

                <ng-option data-title="<21" value="<21">&lt; 21</ng-option>

                <ng-option data-title="21-50" value="21-50">21-50</ng-option>

                <ng-option data-title="51-100" value="51-100">51-100</ng-option>

                <ng-option data-title="101-200" value="101-200">101-200</ng-option>
              </ng-select>
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['teachers_size'].errors?.['required'] || po['teachers_size'].errors?.['whitespace']">* This
                  is required</span>
              </div>
            </div>

            <div class="col-lg-6 mt-20">
              <label class="form-label">Gender</label>
              <ng-select [dropdownPosition]="'bottom'" placeholder="Enter Gender" [searchable]="false"
                formControlName="school_gender" [multiple]="false" placeholder="School type">
                <ng-option value="">Select gender</ng-option>

                <ng-option data-title="Boys" value="Boys">Boys</ng-option>

                <ng-option data-title="Girls" value="Girls">Girls</ng-option>

                <ng-option data-title="Co-education" value="Co-education">Co-education</ng-option>
              </ng-select>
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['school_gender'].errors?.['required'] || po['school_gender'].errors?.['whitespace']">* This
                  is required</span>
              </div>
            </div>
            <div class="col-lg-6  mt-20">
              <label for="level">Level</label>
              <ng-select class="mb-10" [clearable]="false" [items]="filtersArray.school_level"
                formControlName="school_level" bindLabel="title" bindValue="value" groupBy="tags" [closeOnSelect]="true"
                [dropdownPosition]="'bottom'" [multiple]="true" placeholder="Select level" aria-label="school_level">
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <label class="mt-0">Select level</label>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items;  index as i">
                    <span class="ng-value-label">{{items[i].title}}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 mt-20">
              <label class="form-label">Overview *</label>
              <textarea rows="4" placeholder="Enter Overview" formControlName="school_overview"
                class="form-control"></textarea>
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="po['school_overview'].errors?.['required'] || po['school_overview'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-15">
          <button type="submit" (click)="submitOverview()" class="btn cc_p_btn">
            Save
          </button>
        </div>
      </form>
    </ng-template>


    <!-- Edit job onlu user overview model -->
    <ng-template #editJobOnlyUserOverview>
      <div class="modal-header">
        <h4 class="model_headings" id="editAdditionalInformation">
          Edit Overview
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
      </div>
      <form [ngClass]="
          this.schoolProps.properties.job_only_school == true
            ? 'jobs_only_user'
            : 'school-profile-page'
        " [formGroup]="jobsOnlyOverviewForm">
        <div class="modal-body pb-0 pt-0">
          <div class="row">
            <div class="col-lg-12 mt-20">
              <label class="form-label">Email *</label>
              <input formControlName="school_email" value="{{ profileData?.email }}" placeholder="Enter here"
                type="text" class="form-control" />
              <div *ngIf="Jsubmitted">
                <span class="show-errors"
                  *ngIf="Jo['school_email'].errors?.['required'] || Jo['school_email'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>
            <div class="col-lg-6 mt-20">
              <label class="form-label">Contact Number *</label>
              <input formControlName="contact_number" value="{{ profileData?.phone_number }}"
                placeholder="Enter Contact number" type="text" class="form-control" />
              <div *ngIf="Jsubmitted">
                <span class="show-errors"
                  *ngIf="Jo['contact_number'].errors?.['required'] || Jo['contact_number'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
            <div *ngIf="this.schoolProps.properties.organization_type == 'school'" class="col-lg-6 mt-20">
              <label class="form-label">Type *</label>
              <ng-select [dropdownPosition]="'bottom'" placeholder="Enter School type" [searchable]="false"
                formControlName="school_type" [multiple]="false" placeholder="School type">
                <ng-option *ngFor="let type of school_type" value="{{ type.value }}" data-title="{{ type.value }}">{{
                  type.title }}
                </ng-option>
              </ng-select>
              <div *ngIf="Jsubmitted">
                <span class="show-errors"
                  *ngIf="Jo['school_type'].errors?.['required'] || Jo['school_type'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>


            <div class="col-lg-12 mt-20">
              <label class="form-label">Overview *</label>
              <textarea rows="4" placeholder="Enter Overview" formControlName="school_overview"
                class="form-control"></textarea>
              <div *ngIf="Jsubmitted">
                <span class="show-errors"
                  *ngIf="Jo['school_overview'].errors?.['required'] || Jo['school_overview'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-15">
          <button type="submit" (click)="submitJobOnlyOverview()" class="btn cc_p_btn">
            Save
          </button>
        </div>
      </form>
    </ng-template>


    <!-- Edit company Additional Overview model -->
    <ng-template #editCompanyOverviewModal>
      <div class="modal-header">
        <h4 class="model_headings" id="editAdditionalInformation">
          Edit Overview
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
      </div>
      <form [ngClass]="
          this.schoolProps.properties.job_only_school == true
            ? 'jobs_only_user'
            : 'school-profile-page'
        " [formGroup]="companyOverviewForm">
        <div class="modal-body pb-0 pt-0">
          <div class="row">
            <div class="col-lg-12 mt-20">
              <label class="form-label">Website *</label>
              <input formControlName="school_email" value="{{ profileData?.email }}" placeholder="Enter here"
                type="text" class="form-control" />
              <div *ngIf="Csubmitted">
                <span class="show-errors"
                  *ngIf="Co['school_email'].errors?.['required'] || Co['school_email'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>
            <div class="col-lg-6 mt-20">
              <label class="form-label">Contact Number *</label>
              <input formControlName="contact_number" value="{{ profileData?.phone_number }}"
                placeholder="Enter Contact number" type="text" class="form-control" />
              <div *ngIf="Csubmitted">
                <span class="show-errors"
                  *ngIf="Co['contact_number'].errors?.['required'] || Co['contact_number'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>

            <div class="col-lg-12 mt-20">
              <label class="form-label">Overview *</label>
              <textarea rows="4" placeholder="Enter Overview" formControlName="school_overview"
                class="form-control"></textarea>
              <div *ngIf="Csubmitted">
                <span class="show-errors"
                  *ngIf="Co['school_overview'].errors?.['required'] || Co['school_overview'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-15">
          <button type="submit" (click)="submitCompanyOverview()" class="btn cc_p_btn">
            Save
          </button>
        </div>
      </form>
    </ng-template>

    <!-- Edit profile information model -->
    <ng-template #editProfileInformationModal>
      <div class="modal-header">
        <h4 class="model_headings" id="editProfileInformationModal">
          Edit Overview
        </h4>
        <button type="button" (click)="closeProfileInfo()" class="btn-close" aria-label="Close"></button>
      </div>
      <form [ngClass]="
          this.schoolProps.properties.job_only_school == true
            ? 'jobs_only_user'
            : 'school-profile-page'
        " [formGroup]="profileInfoEdit">
        <div class="modal-body pb-0">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <label class="">Crest / Logo</label>
                <div class="col-6 col-md-4 col-lg-4">
                  <img alt="" [src]="
                      this.ShowProfileData?.additional_info?.profile_image ==
                        null ||
                      this.ShowProfileData?.additional_info?.profile_image == ''
                        ? '../../../../assets/images/pro_avatar.png'
                        : this.ShowProfileData?.additional_info?.profile_image
                    " class="profile-avatar" />
                  <input type="file" formControlName="logo" class="form-control mt-15"
                    (change)="fileChangeEvent($event)" alt="school logo" />
                </div>
                <div *ngIf="this.imageChangedEvent?.target?.files?.length > 0" class="col-6 col-md-4 col-lg-4">
                  <div class="cropper-container">
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                      [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="3 / 3" format="png"
                      [style.display]="showCropper ? null : 'none'" (imageCropped)="imageCropped($event)"
                      (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                      (loadImageFailed)="loadImageFailed()">
                    </image-cropper>
                  </div>
                </div>
                <!-- <div class="col-xs-12 col-md-4 col-lg-4 text-align-center">
                                <img *ngIf="croppedImage == ''" [src]="croppedImage" />
                                <img *ngIf="croppedImage != ''"
                                    class="form-control" [src]="croppedImage" />
                            </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 mt-15">
              <label class="form-label">School Website *</label>
              <input formControlName="school_website" placeholder="Enter website" type="text" class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="f['school_website'].errors?.['required'] || f['school_website'].errors?.['whitespace']">* This
                  is required</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 mt-15">
              <label class="form-label">Country *</label>
              <ng-select [dropdownPosition]="'bottom'" (change)="countryOnChange()" placeholder="Enter School type"
                [searchable]="false" formControlName="profile_country" [multiple]="false" placeholder="Select country">
                <ng-option *ngFor="let country of filtersArray.country" value="{{ country.value }}"
                  data-title="{{ country.value }}">
                  {{ country.title }}
                </ng-option>
              </ng-select>
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="f['profile_country'].errors?.['required'] || f['profile_country'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
            <div class="col-lg-6 mt-15">
              <label class="form-label">State *</label>
              <ng-select (change)="getTimeZoneValue()" [dropdownPosition]="'bottom'" [searchable]="false"
                formControlName="profile_state" [multiple]="false" placeholder="Select state">
                <ng-option *ngFor="let state of actualState" value="{{ state.value }}" data-title="{{ state.value }}">
                  {{ state.title }}
                </ng-option>
              </ng-select>
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="f['profile_state'].errors?.['required'] || f['profile_state'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 mt-15">
              <label class="form-label">Street Name *</label>
              <input formControlName="street_1" placeholder="Enter street" type="text" class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="f['street_1'].errors?.['required'] || f['street_1'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>

            <div class="col-lg-6 mt-15">
              <label class="form-label">City *</label>
              <input formControlName="city" placeholder="Enter city" type="text" class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors" *ngIf="f['city'].errors?.['required'] || f['city'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mt-15">
              <label class="form-label">Street Number *</label>
              <input formControlName="street_number" type="text" placeholder="Enter street number"
                class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="f['street_number'].errors?.['required'] || f['street_number'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>
            <div class="col-lg-4 mt-15">
              <label class="form-label">Postal Code *</label>
              <input formControlName="postal_code" type="text" placeholder="Enter postal code" class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="f['postal_code'].errors?.['required'] || f['postal_code'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>
            <div class="col-lg-4 mt-15">
              <label class="form-label">Timezone *</label>
              <input [readonly]="true" formControlName="StatetimeZone" type="text" placeholder="Enter timezone"
                class="form-control" />
              <div *ngIf="submitted">
                <span class="show-errors"
                  *ngIf="f['StatetimeZone'].errors?.['required'] || f['StatetimeZone'].errors?.['whitespace']">* This is
                  required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-15">
          <button type="submit" (click)="saveProfileInformation()" class="btn cc_p_btn">
            Save
          </button>
        </div>
      </form>
    </ng-template>

    <!-- Edit profile newAnnouncementModal model -->
    <ng-template #newAnnouncementModal>
      <form [ngClass]="
          this.schoolProps.properties.job_only_school == true
            ? 'jobs_only_user'
            : 'school-profile-page'
        " [formGroup]="newAnnounceMentForm" (ngSubmit)="saveNewAnnoucement()" #newAnnoucement="ngForm">
        <div class="modal-header">
          <h4 class="model_headings" id="editProfileInformationModal">
            <span *ngIf="
                getAnnoucemNetFormControl['from'].value != 'announcementEdit'
              ">New Announcement</span>
            <span *ngIf="
                getAnnoucemNetFormControl['from'].value == 'announcementEdit'
              ">Edit Announcement</span>
          </h4>
          <button type="button" (click)="closeAnnocementModal()" class="btn-close" aria-label="Close"></button>
        </div>
        <div class="modal-body pb-0">
          <div class="row">
            <div class="col-lg-12 mb-20">
              <label>This information will only be viewable to teachers on your
                school's list.
              </label>
            </div>
            <div class="col-lg-12">
              <textarea rows="4" placeholder="Add New Announcement" formControlName="announcement"
                class="form-control"></textarea>
            </div>
            <div *ngIf="newAnnoucement.submitted">
              <span class="show-errors"
                *ngIf="newAnnounceMentForm.controls['announcement'].errors?.['required'] || newAnnounceMentForm.controls['announcement'].errors?.['whitespace']">*
                This is required</span>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-15">
          <button type="submit" *ngIf="
              getAnnoucemNetFormControl['from'].value != 'announcementEdit'
            " class="btn cc_p_btn">
            Save
          </button>

          <button type="submit" (click)="saveNewAnnoucement()" *ngIf="
              getAnnoucemNetFormControl['from'].value == 'announcementEdit'
            " class="btn cc_p_btn">
            Edit
          </button>
        </div>
      </form>
    </ng-template>

    <!-- Edit profile RemoveAnnounceConfirmation model -->
    <ng-template #RemoveAnnounceConfirmation>
      <div class="modal-header">
        <h4 class="model_headings mb-0" id="editProfileInformationModal">
          Confirmation
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body pb-0">Are you sure you want to remove this?</div>
      <div class="modal-footer mt-15">
        <button type="submit" (click)="publicEditProfile()" class="btn fs-13 cc_p_btn">
          Remove
        </button>
      </div>
    </ng-template>

    <!-- Edit profile schoolAnnouncementsModal model -->
    <ng-template #schoolAnnouncementsModal>
      <div class="modal-header">
        <h4 class="model_headings" id="editProfileInformationModal">
          School Announcements
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body pb-0">
        <div *ngFor="
            let announcements of profileData?.additional_info?.announcements;
            index as i
          ">
          <div class="col-xs-12 col-md-12">
            <div class="d-flex align-items-center border-bottom pb-3 mb-3">

              <div class="w-100 ml-3">
                <small class="text-r-g-3">{{ announcements?.date }}
                  <i class="bi bi-pin-angle fs-16 c-p" *ngIf="!announcements?.pinned" (click)="pinAnnoucement(i)"></i>
                  <i class="bi bi-pin-angle-fill fs-16 clr-cc-vilot" *ngIf="announcements?.pinned"></i>
                </small>
                <p class="text-regular-medium">
                  {{ announcements?.announcement }}
                </p>
              </div>

              <div class="ml-3 d-flex clr-cc-vilot justify-content-end">
                <i class="bi bi-pencil-square fs-20 mx-10 c-p" (click)="editAnnoucement(i)"></i>
                <i class="bi bi-x-circle-fill fs-20 mx-10 c-p" (click)="removeAnnoucement(i)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Edit profile editOnboardingDocumentsModal model -->
    <ng-template #editOnboardingDocumentsModal>
      <form [ngClass]="
          this.schoolProps.properties.job_only_school == true
            ? 'jobs_only_user'
            : 'school-profile-page'
        " [formGroup]="onboardingDocumentsForm" #onboarding="ngForm">
        <div class="modal-header">
          <h4 class="model_headings" id="editProfileInformationModal">
            <span *ngIf="
                getOnBoardingFormControl['from'].value != 'onboarding_edit'
              ">
              Add Onboarding Document</span>
            <span *ngIf="
                getOnBoardingFormControl['from'].value == 'onboarding_edit'
              ">
              Edit Onboarding Document</span>
          </h4>
          <button type="button" (click)="closeOnBoardingModal()" class="btn-close" aria-label="Close"></button>
        </div>
        <div class="modal-body pb-0">
          <div class="row">
            <div class="col-lg-12 mb-20">
              <label>Add links to documents that you want to only share with teachers in your school’s list.
              </label>
            </div>
            <div class="col-lg-12">
              <input formControlName="doc_type" type="text" placeholder="Type of Document" class="form-control" />
              <div *ngIf="onboarding.submitted">
                <span class="show-errors"
                  *ngIf="onboardingDocumentsForm.controls['doc_type'].errors?.['required'] || onboardingDocumentsForm.controls['doc_type'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
            <div class="col-lg-12 mt-20">
              <input formControlName="doc_link" type="text" placeholder="Link to document" class="form-control" />
              <div *ngIf="onboarding.submitted">
                <span class="show-errors"
                  *ngIf="onboardingDocumentsForm.controls['doc_link'].errors?.['required'] || onboardingDocumentsForm.controls['doc_link'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-15">
          <button type="submit" (click)="saveOnboarding()"
            *ngIf="getOnBoardingFormControl['from'].value != 'onboarding_edit'" class="btn cc_p_btn">
            Save
          </button>

          <button type="submit" (click)="saveOnboarding()"
            *ngIf="getOnBoardingFormControl['from'].value == 'onboarding_edit'" class="btn cc_p_btn">
            Edit
          </button>
        </div>
      </form>
    </ng-template>

    <!-- Edit profile editAdditionalInformationModal model -->
    <ng-template #editAdditionalInformationModal>
      <form [ngClass]="
          this.schoolProps.properties.job_only_school == true
            ? 'jobs_only_user'
            : 'school-profile-page'
        " [formGroup]="AdditionalInfoForm" #additionalInfo="ngForm">
        <div class="modal-header">
          <h4 class="model_headings" id="editProfileInformationModal">
            <span *ngIf="
                getAdditionalInfoFormControl['from'].value !=
                'additionalinfo_edit'
              ">Add Additional Information</span>
            <span *ngIf="
                getAdditionalInfoFormControl['from'].value ==
                'additionalinfo_edit'
              ">Edit Additional Information</span>
          </h4>
          <button type="button" (click)="closeAdditionalInfoModal()" class="btn-close" aria-label="Close"></button>
        </div>
        <div class="modal-body pb-0">
          <div class="row">
            <div class="col-lg-12 mb-20">
              <label>This information will only be viewable to teachers on your
                school's list.
              </label>
            </div>
            <div class="col-lg-12">
              <input formControlName="title" type="text" placeholder="Enter Title" class="form-control" />
              <div *ngIf="additionalInfo.submitted">
                <span class="show-errors"
                  *ngIf="AdditionalInfoForm.controls['title'].errors?.['required'] || AdditionalInfoForm.controls['title'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
            <div class="col-lg-12 mt-20">
              <textarea rows="4" formControlName="information" placeholder="Enter Information"
                class="form-control"></textarea>
              <div *ngIf="additionalInfo.submitted">
                <span class="show-errors"
                  *ngIf="AdditionalInfoForm.controls['information'].errors?.['required'] || AdditionalInfoForm.controls['information'].errors?.['whitespace']">*
                  This is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-15">
          <button type="submit" (click)="saveAdditionalInfo()" *ngIf="
              getAdditionalInfoFormControl['from'].value !=
              'additionalinfo_edit'
            " class="btn cc_p_btn">
            Save
          </button>

          <button type="submit" (click)="saveOnboarding()" *ngIf="
              getAdditionalInfoFormControl['from'].value ==
              'additionalinfo_edit'
            " class="btn cc_p_btn">
            Edit
          </button>
        </div>
      </form>
    </ng-template>
    <ng-template #receiveRequestModal>
      <div class="modal-header">
        <h4 class="model_headings mb-0" id="editProfileInformationModal">
          Confirmation
        </h4>
        <button type="button" (click)="cancelRequest()" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body pb-0">
        <div *ngIf="receiveRequestEvent">
          <p class="user-title">Show school so teachers can request to join your list</p>
          <p class="txt-regular">You can turn this off at any time</p>
        </div>
        <div *ngIf="!receiveRequestEvent">
          <p class="user-title">Hide school so teachers can't request to join your list</p>
          <p class="txt-regular">You can switch this on at any time</p>
        </div>
      </div>
      <div class="modal-footer mt-15">
        <button type="submit" (click)="cancelRequest()" class="btn fs-13 cc_p_btn_outline">
          Cancel
        </button>
        <button type="submit" (click)="sendReceiveRequest()" class="btn fs-13 cc_p_btn">
          Confirm
        </button>
      </div>
    </ng-template>
    <ng-template #inAppCancelModal>
      <div class="modal-header">
        <h4 class="model_headings mb-0" id="editProfileInformationModal">
          Update school contact for cancellations
        </h4>
        <button type="button" (click)="this.bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body pb-0">
        <form [formGroup]="contactDetailsforCancelForm" #contactDetailsforCancel="ngForm">

          <div class="col-lg-12">
            <label>Email</label>
            <input type="text" formControlName="cancellation_email" class="form-control">
            <div *ngIf="submittedInAppForm">
              <span class="show-errors"
                *ngIf="contactDetailsforCancelForm.controls['cancellation_email'].errors?.['required'] || contactDetailsforCancelForm.controls['cancellation_email'].errors?.['whitespace']">*
                This field is required</span>
            </div>
          </div>
          <div class="col-lg-12 mt-5">
            <label>Contact number</label>
            <input type="text" formControlName="cancellation_phone" class="form-control">
            <div *ngIf="submittedInAppForm">
              <span class="show-errors"
                *ngIf="contactDetailsforCancelForm.controls['cancellation_phone'].errors?.['required'] || contactDetailsforCancelForm.controls['cancellation_phone'].errors?.['whitespace']">*
                This field is required</span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer mt-10">
        <!-- <p class="txt-regular c-p mr-10" (click)="closeInAppModal()">Cancel</p> -->
        <button type="submit" (click)="sendInAppCancelData()" class="btn cc_p_btn fs-13">
          Save
        </button>
      </div>
    </ng-template>

    <div class="d-flex justify-content-end" *ngIf="onboardingProcess">
      <button class="btn cc_p_btn btn_length pr-30 pl-30"  (click)="stepCompleted()">Done</button>
    </div>
  </div>
</div>
<div *ngIf="this.checkToken == ''">
  <nav class="navbar">
    <div class="container-fluid display-profile-header pl-20 pr-20 align-items-center">
      <div class="d-flex">
        <img alt="" class="side_logo_text_booking" src="assets/images/favicon/cc_reverse@2x.png" />
      </div>
      <div class="nav-item public-profile d-flex align-items-center">
        <li class="nav-item">
          <a class="nav-link" target="_blank"
            href="https://www.classcover.com.au/teachers?utm_source=cc_app&amp;utm_campaign=jobs">Learn more</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank"
            href="https://www.classcover.com.au/hire-teachers-through-classcover-jobs?utm_source=cc_app&amp;utm_campaign=jobs">Post
            a job</a>
        </li>
        <li class="nav-item pr-2">
          <a class="nav-link" target="_blank" href="http://phoenix.classcover.com.au/en/login">Login</a>
        </li>
        <li class="nav-item pl-2">
          <a class="btn cc_p_btn" target="_blank" href="http://phoenix.classcover.com.au/en/teacher/register">Sign
            up</a>
        </li>
      </div>
    </div>
  </nav>
  <div class="container pb-30 pt-20">
    <div class="row"
      [ngClass]="this.profileData?.subscription.job_only_school == true ? 'jobs_only_user': 'school-profile-page'">
      <!-- First half -->
      <div class="col-lg-8 col-md-12 col-sm-12 col-12 mb-15">
        <div class="card px-15">
          <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10">
              <div class="align-items-center d-flex">
                <p class="wrapper">
                  <img alt="" class="avatar" [src]="
                    profileData?.additional_info?.profile_image == null ||
                    profileData?.additional_info?.profile_image == ''
                      ? '../../../../assets/images/pro_avatar.png'
                      : profileData?.additional_info?.profile_image
                  " />
                </p>
                <div class="ml-10">
                  <p class="c-p ul-title">{{ profileData?.title }}</p>
                  <p class="txt-regular mt-2">
                    <i class="bi bi-geo-alt-fill"></i>
                    {{ profileData?.address?.city }},
                    {{ profileData?.address.address_timezone.state }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
            </div>
          </div>
        </div>


        <div class="card px-15 mt-30">
          <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-9 col-xs-9 col-9">
              <h5 class="ul-title">Overview</h5>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3">

            </div>
          </div>
          <div class="mt-5 txt-regular">
            <p class="mt-10 pre-line" [innerHTML]="profileData?.overview"></p>
          </div>
          <div class="mt-10">
            <div class="row align-items-center mt-20">
              <div class="col-lg-4">
                <div class="text-r-g-3">School Type</div>
              </div>
              <div class="col-lg-8">
                <span class="txt-regular">{{
                  profileData?.subscription?.display_school_type
                  }}</span>
              </div>
            </div>
            <div class="row align-items-center mt-20">
              <div class="col-lg-4">
                <div class="text-r-g-3">School Contact</div>
              </div>
              <div class="col-lg-8">
                <span class="txt-regular clr-cc-vilot">{{ profileData?.email }}</span>
                <span class="txt-regular">
                  <i class="bi bi-dot"></i> {{ profileData?.phone_number }}</span>
              </div>
            </div>

          </div>
        </div>

        <div class="card px-15 mt-30">
          <h5 class="ul-title mb-20">
            Location
          </h5>
          <iframe width="100%" class="e2e-iframe-trusted-src" height="380" [src]="this.mapsUrl"></iframe>
        </div>
      </div>
      <!-- Second Half -->
      <div *ngIf="!onboardingProcess">
        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
          <div class="row" *ngIf="recentJobsList?.length > 0">
            <div class="col-12">
              <div class="card px-15 ">
                <h4 class="ul-title">Recent Jobs Posted</h4>
                <div class="row  h_b c-p" *ngFor="let job of recentJobsList; index as i">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-12" (click)="openPublicJobs(job.id)">
                    <h6 class="pt-3 mb-4 user-title mr-6">
                      {{ job?.job_title }}
                      <span class="qualified-in mt-5 bg-tags p-2">{{
                        job?.job_type_display
                        }}</span>
                    </h6>
                    <small class="txt-regular">{{ job?.school }}<i class="bi bi-dot"></i>{{
                      job?.school_level_display }}</small><br />
                    <small class="text-r-g-3"><i class="bi bi-geo-alt-fill bi-geo-alt-fill-public"></i>
                      {{ job?.location }}</small>
                  </div>
                  <hr class="mt-8 mb-8" />
                </div>
                <p class="c-p clr-cc-vilot text-center user-title mt-5 mb-10" (click)="openPublicJobsCommon()">
                  View all
                  <i class="bi bi-arrow-up-right"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>