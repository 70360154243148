<div class="" *ngIf="!editBooking">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Booking Details <span class="clr-red" *ngIf="bookingDatas.status == 'casual_cancelled'">(cancelled)</span></h5>
        <button type="button" class="btn-close" (click)="bsModalRef.hide()" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <div class="modal-body">
            <!-- {{bookingDatas | json}} -->
            <div class="row  align-items-center mt-10">
                <div class="col-md-9 col-sm-8 col-lg-9">
                    <div class="col-md-12 col-lg-12 d-flex align-items-center">
                        <div class="pic-and-check ml-0">
                            <img alt="img"
                                [src]="bookingDatas.notification.consumer.meta.profile_image != null ? bookingDatas.notification.consumer.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                class="profile-picture img-complete-match">
                        </div>

                        <div class="pic-and-check ml-10">
                            <div class="d-flex">
                                <p>
                                    <span class="user-title c-p">{{bookingDatas.notification.consumer.user.full_name}}</span>
                                    <span>
                                        <app-validated-dict [validateDict]="bookingDatas.notification.consumer.validated_dict">
                                        </app-validated-dict>
                                    </span>
                                </p>
                            </div>
                            <p class="user-sub-title"><i class="bi bi-geo-alt clr-grey-7"></i>
                                {{bookingDatas.notification.consumer.location}}<span class="divider-right ml-5 mr-5">
                                </span><i class="bi bi-telephone clr-grey-7"></i> {{bookingDatas.notification.consumer.phone}}
                            </p>
                            <p class="user-sub-title envelope-icon">
                                Accreditation No <span *ngIf="bookingDatas.notification.consumer.accreditation.accreditation">
                                    <span
                                        *ngIf="bookingDatas.notification.consumer.accreditation.accreditation.length > 0">{{bookingDatas.notification.consumer.accreditation.accreditation[0].accreditation_id}}</span>
                                    <span class="divider-right ml-5 mr-5"></span><i
                                        class="bi bi-envelope clr-grey-7"></i>
                                    {{bookingDatas.notification.consumer.user.email}}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-15">
                <p class="txt-regular"><i class="bi bi-calendar-event fs-13 mr-2"></i> {{bookingDatas.display_date}}</p>
                <p class="txt-regular mt-5"><i class="bi bi-clock fs-13 mr-2"></i> {{bookingDatas.start_time}} -
                    {{bookingDatas.end_time}}</p>
            </div>
            <div class="row">
                <div class="col-md-6  mt-10">
                    <p><span class="text-r-g-3">{{filtersArray.custom_field1}}:</span><span class="txt-regular">
                            {{bookingDatas.information.class_being_covered}}</span></p>
                </div>
                <div class="col-md-6 mt-10">
                    <p><span class="text-r-g-3">{{filtersArray.custom_field2}}:</span><span class="txt-regular">
                            {{bookingDatas.information.provider_being_covered}}</span></p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-10">
                    <p><span class="text-r-g-3">Reason of absence:</span><span class="txt-regular">
                        {{bookingDatas.information.reason_for_absence}}  </span></p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-10">
                    <p><span class="text-r-g-3">Notes:</span><span class="txt-regular">
                            {{bookingDatas.information.notes}}</span></p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-10">
                    <p class="text-regular-bold c-p showHideDiv fit-content" (click)="showHiddenDivOnclick();">
                        <span *ngIf="showHiddenDiv">Hide <i class="bi bi-chevron-up"></i></span>
                        <span *ngIf="!showHiddenDiv">Show more <i class="bi bi-chevron-down"></i></span>
                    </p>

                </div>
            </div>
            <div class="row" *ngIf="showHiddenDiv">
                <div class="col-md-12 mt-10">
                    <p><span class="text-r-g-3">{{filtersArray.payroll_report_fields?.payroll_field_label_1}}:</span><span class="txt-regular ml-5">
                            {{bookingDatas.information.payroll_field1}}</span></p>

                </div>
                <div class="col-md-12 mt-10">
                    <p><span class="text-r-g-3">{{filtersArray.payroll_report_fields?.payroll_field_label_2}}:</span><span class="txt-regular ml-5">
                            {{bookingDatas.information.payroll_field2}}</span></p>

                </div>
                <div class="col-md-12 mt-10">
                    <p><span class="text-r-g-3">{{filtersArray.payroll_report_fields?.payroll_field_label_3}}:</span><span class="txt-regular ml-5">
                            {{bookingDatas.information.payroll_field3}}</span></p>

                </div>
                <div class="col-md-12 mt-10">
                    <p><span class="text-r-g-3">Attachments:</span><span class="txt-regular ml-5">
                            <a href="{{bookingDatas.information.details.attachment_link}}" target="_blank">{{bookingDatas.information.details.attachment_link}}</a></span></p>

                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer" *ngIf="bookingDatas.status != 'casual_cancelled'">
        <button type="button" (click)="cancelReason(cancelReasonModal)" class="btn_cc_back h_b">Cancel booking</button>
        <button type="button" (click)="editBookingSec()" class="btn cc_p_btn fs_13">Edit booking</button>
    </div>
</div>
<div class="" *ngIf="editBooking">
    <form [formGroup]="editBookings">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Details</h5>
            <button type="button" class="btn-close" (click)="closeEditBookingSec()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p class="txt-regular">Booking request edits will need to be reviewed and confirmed by staff
                            that
                            are involved. You can edit your booking up until the day of your booking.</p>
                    </div>
                </div>
                <div class="row  align-items-center">
                    <div class="col-md-6  mt-10">
                        <input type="text" class="form-control" formControlName="booking_teachercovered"
                         placeholder="{{filtersArray.custom_field2}}">
                    </div>
                    <div class="col-md-6  mt-10">
                        <input type="text" class="form-control" formControlName="booking_classcovered"
                            placeholder="{{filtersArray.custom_field1}}">
                    </div>
                    <div class="col-md-6  mt-10">
                        <select class="form-select" formControlName="booking_absencereason"
                        aria-label="booking-absence-reason">
                        <option *ngFor="let absense of filtersArray.absence_reason"
                            value="{{absense.value}}">
                            {{absense.title}}
                        </option>
                    </select>
                    </div>
                    <div class="col-md-6  mt-10">
                        <select id="booking_option_leaveApproved"
                            formcontrolname="booking_leaveapproved" aria-label="Leave approved"
                            class="form-select">
                            <option value="1">Approved</option>
                            <option value="0">Not Approved</option>
                        </select>
                    </div>
                    <div class="col-md-12  mt-10">
                        <textarea type="text" class="form-control" formControlName="booking_notes" rows="3"
                            placeholder="This note will be sent to the staff via email"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mt-10">
                        <p class="text-regular-bold c-p showHideDiv fit-content" (click)="showHiddenEditDivOnclick();">
                            <span *ngIf="showEditHiddenDiv">Hide <i class="bi bi-chevron-up"></i></span>
                            <span *ngIf="!showEditHiddenDiv">Show more <i class="bi bi-chevron-down"></i></span>
                        </p>

                    </div>
                </div>
                <div class="row" *ngIf="showEditHiddenDiv">
                    <div class="col-md-6  mt-10">
                        <input type="text" class="form-control" formControlName="booking_payrollfield1" placeholder="{{filtersArray.payroll_report_fields?.payroll_field_label_1}}">
                    </div>
                    <div class="col-md-6  mt-10">
                        <input type="text" class="form-control" formControlName="booking_payrollfield2" placeholder="{{filtersArray.payroll_report_fields?.payroll_field_label_2}}">
                    </div>
                    <div class="col-md-6  mt-10">
                        <input type="text" class="form-control" formControlName="booking_payrollfield3" placeholder="{{filtersArray.payroll_report_fields?.payroll_field_label_3}}">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="saveEditBooking()" class="btn cc_p_btn fs_13">Update</button>
        </div>
    </form>
</div>
<ng-template #cancelReasonModal>
    <form [formGroup]="cancelBookingForm">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Cancel Booking</h5>
            <button type="button" class="btn-close" (click)="closeModal()" aria-label="Close"></button>
        </div>


        <div class="modal-body pb-0">
            <div class="row  align-items-center">
                <div class="col-md-12">
                    <label for="exampleFormControlTextarea1" class="form-label user-title-m">Cancellation reason *</label>
                    <textarea class="form-control mt-5" (keyup)="checkLength()" formControlName="cancel_message" placeholder="Add Message here..."
                        id="exampleFormControlTextarea1" rows="6"></textarea>
                        <p class="txt-regular mt-10">Max ({{this.ShowLenght}} characters)</p>
                        <div *ngIf="submitted">
                            <span class="show-errors" *ngIf="f['cancel_message'].errors?.['required']">* Cancel message is required</span>
                        </div>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <!-- <button type="button"  (click)="bsModalRef.hide()" class="btn_cc_back">Back</button> -->
            <button type="button" (click)="cancelBooking()" class="btn cc_p_btn fs_13">Confirm</button>
        </div>
    </form>
</ng-template>