import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Observable, forkJoin, take } from 'rxjs';
import { BookingService } from 'src/app/services/api/booking.service';
import * as moment from 'moment';


// Services
import { CommonService } from 'src/app/services/api/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { SendMailComponent } from '../modals/send-mail/send-mail.component';
import { ToastrService } from 'ngx-toastr';
import { TeacherProfilePopupComponent } from '../teacher-profile-popup/teacher-profile-popup.component';
import { DatePipe } from '@angular/common';
import { SchoolData } from 'src/app/models/schoolUser';
import { MixpanelService } from 'src/app/mixpanel.service';

@Component({
  selector: 'app-find-new',
  templateUrl: './find-new.component.html',
  styleUrls: ['./find-new.component.scss']
})
export class FindNewComponent implements OnInit {
  @ViewChildren("checkboxes") checkboxes!: QueryList<ElementRef>
  @ViewChild('sendInviteModal') sendInviteModal: any;
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  FindNewteachersList: Observable<any> = this.obsArray.asObservable();
  totalArrayCount: any;
  teacherLengthLimit: any;
  public schoolProps!: SchoolData;
  findNewFilterForm: FormGroup = this.fb.group({
    filter_distance: ['20'],
    filter_date: [''],
    filter_state: ['Any'],
    filter_name: [''],
    filter_tags: [''],
    filter_experienceItems: [''],
    filter_consumer_type: [''],
    filter_is_validated: [false],
    page: [],
    date: [''],
    is_sgt: [false],
    is_nsbts: [false],
    is_other_staff: [false],
    is_corporate_staff: [false]
  });
  resStatus: any;
  // Filters
  filtersArray: any = [];
  distances = [];
  subjectsAndTags = [];
  Find_New_School_Role_Type = [];
  currentPage: number = 1;
  inlineEndDate: any;
  bsValue: any;
  hideButton: boolean = true;
  title?: string;
  state: any;
  levelFilter = [];
  consumerType = [];
  consumerList: any;
  dateList: any;
  checkBoxSelectedTeacher: any = [];
  inviteList: Array<any> = [];
  consumerDetails: Array<any> = [];
  selectAllCheck: boolean = false;
  totalCount: any;
  checkBoxSelectedTeacherLength: any;
  myInvites: any;
  myRequest: any;
  teacher: any;
  inviteDetails: any = [''];
  sendInviteForm: FormGroup = this.fb.group({
    consumer_id: ['']
  });
  cancelReasonModal?: BsModalRef;
  availTeacher: any;
  datesHeaderArr: any = [];
  from_page_cookie: any;
  dateVal: string;

  constructor(private commonService: CommonService,
    private ngxService: NgxUiLoaderService,
    private bookingService: BookingService,
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private _router: Router,
    private toastr: ToastrService,
    private mixpanelService: MixpanelService
  ) {
    // this.bsValue = moment(new Date()).format('DD/MM/YYYY');
    this.bsValue = ''
    this.dateVal = moment(new Date()).format('DD/MM/YYYY');
  }


  ngOnInit(): void {
    this.ngxService.start();
    this.getFiltersData();
    this.mixpanelService.findNewTeacherPage()
    this.from_page_cookie = document.cookie.match('(^|;)\\s*' + 'from' + '\\s*=\\s*([^;]+)')?.pop() || '';
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.findNewFilterForm.patchValue({
      filter_state: this.schoolProps.mixpanel_data.state
    })
    this.bookingService.getFindNewTeacher(this.getFilterFormControlValues('')).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.teacherLengthLimit = res.data.total_count;
          this.consumerList = res.data.consumer_list;
          this.obsArray.next(res.data.consumer_list);
          if (this.consumerList.length > 0) {
            this.inlineEndDate = res.data.consumer_list[0].availability[0].intervals[0].date;
          }
          this.myRequest = res.data.pending_request;
          this.myInvites = res.data.pending_invite;
          this.ngxService.stop();
        } else {
          this.ngxService.stop();
        }

      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
    this.ngListener()
  }

  public setArrData(data: any): void {
    console.log(data);
  }

  getFiltersData() {
    this.commonService.schoolChoices('fnt').subscribe({
      next: (res: any) => {
        this.filtersArray = res.data;
        this.subjectsAndTags = res.data.tags;
        this.levelFilter = res.data.experience_tree;
        this.consumerType = res.data.consumer_type;
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }

  get getFormControl() {
    return this.findNewFilterForm.controls;
  }

  clearDateInput() {
    if (this.bsValue == '') {
      return;
    }
    this.currentPage = 1;
    this.ngxService.start();
    this.bookingService.getFindNewFilterTeacher(this.getFilterFormControlValues('clearInput')).subscribe({
      next: (res: any) => {
        this.teacherLengthLimit = res.data.total_count;
        this.consumerList = res.data.consumer_list;
        this.obsArray.next(res.data.consumer_list);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }
  getFilterInputs() {
    this.currentPage = 1;
    this.ngxService.start();
    this.bookingService.getFindNewFilterTeacher(this.getFilterFormControlValues('OnFilter')).subscribe({
      next: (res: any) => {
        this.teacherLengthLimit = res.data.total_count;
        this.consumerList = res.data.consumer_list;
        this.obsArray.next(res.data.consumer_list);
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }

  // set checkbox selected teachers
  setCheckbox(event: any, type: any) {
    var checked = event.target.checked;
    if (type == 'All') {
      this.consumerDetails = [];
      this.consumerList.forEach((x: any) => {
        if (checked) {
          x.isChecked = true;
          this.consumerDetails.push(x);
          this.selectAllCheck = false;
          this.hideButton = false
        } else {
          this.hideButton = true
          x.isChecked = false;
          this.consumerDetails = [];
          this.selectAllCheck = false;
        }
      });
    } else {
      if (checked) {
        type.isChecked = true;
        this.consumerDetails.push(type)
        this.selectAllCheck = false;
        this.hideButton = false
      } else {
        type.isChecked = false;
        this.consumerDetails = this.consumerDetails.filter(x => x != type);
      }
    }
    this.selectAllCheck = this.consumerList.length == this.consumerDetails.length ? true : false;
    if (this.consumerDetails.length > 0) {
      this.hideButton = false
    } else {
      this.hideButton = true
    }
  }


  // send email modal
  sendMail() {
    const initialState = {
      teacher: Array.prototype.map.call(this.consumerDetails, function (item) { return item.teacher.id; }).join(","),
      from: 'find_new',
      all_checkbox: true,
      checked_status: this.consumerDetails,
    };
    this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });

  }

  getFilterFormControlValues(nextPrew: any) {
    if (nextPrew == 'nextPrew') {
      // this.inlineEndDate = this.dateVal
      this.findNewFilterForm.patchValue({
        page: 1,
        filter_date: '',
      });
    } else if (nextPrew == 'OnFilter') {
      if (this.bsValue != '') {
        let newDate = new DatePipe('en-US').transform(this.bsValue, 'dd/MM/YYYY')
        this.inlineEndDate = newDate
        this.findNewFilterForm.patchValue({
          page: this.currentPage,
          filter_date: newDate,
          date: newDate
        });
      } else {
        this.findNewFilterForm.patchValue({
          page: this.currentPage,
          filter_date: '',
        });
      }
    }
    else {
      this.bsValue = ''
      // let newDate = new DatePipe('en-US').transform(this.dateVal, 'dd/MM/YYYY')
      this.inlineEndDate = this.dateVal
      this.findNewFilterForm.patchValue({
        page: this.currentPage,
        filter_date: '',
        date: ''
      });
    }
    // else if(nextPrew == 'OnFilter') {
    //   let newDate = new DatePipe('en-US').transform(this.bsValue, 'dd/MM/YYYY')
    //   this.findNewFilterForm.patchValue({
    //     page: this.currentPage,
    //     filter_date: newDate,
    //     date: newDate
    //   });
    // }else{
    //   let newDate = new DatePipe('en-US').transform(this.bsValue, 'dd/MM/YYYY')
    //   this.findNewFilterForm.patchValue({
    //     page: this.currentPage,
    //     filter_date: '',
    //     date: newDate
    //   });
    // }
    // if (this.bsValue == undefined) {
    //   this.findNewFilterForm.patchValue({
    //     page: this.currentPage,
    //   });
    // } else {
    //   this.findNewFilterForm.patchValue({
    //     page: this.currentPage,
    //   });
    // }
    return this.findNewFilterForm.value;
  }
  openSendInviteModal(teacher: any) {
    this.bsModalRef = this.modalService.show(this.sendInviteModal, { class: 'modal-md', backdrop: 'static' });
    this.teacher = teacher

    // this.multipleType = false
    // if(teacher == 'multiple'){
    //   this.multipleType = true
    //   this.bsModalRef = this.modalService.show(this.cancelPendingInviteModal, { class: 'modal-md', backdrop: 'static' });
    // }else{
    //   this.teacher = teacher
    //   this.bsModalRef = this.modalService.show(this.cancelPendingInviteModal, { class: 'modal-md', backdrop: 'static' });
    // }
  }
  getInviteValue() {
    this.sendInviteForm.patchValue({
      page: this.currentPage,
    });
    return this.sendInviteForm.value;
  }
  sendInvite(teacher: any) {
    this.ngxService.start();
    this.sendInviteForm.patchValue({
      consumer_id: (teacher.id).toString()
    });
    // this.sendInviteForm.addControl('consumer_id',  new FormControl((teacher.id).toString()));
    this.bookingService.sendInviteTeachers(this.getInviteValue()).subscribe({
      next: (res: any) => {
        if (res.status == true) {
          this.bsModalRef.hide();
          this.mixpanelService.findNewTeacherPageInvite()
          this.bookingService.getFindNewTeacher(this.getFilterFormControlValues('')).subscribe({
            next: (res: any) => {
              this.teacherLengthLimit = res.data.total_count;
              this.consumerList = res.data.consumer_list;
              this.obsArray.next(res.data.consumer_list);

              this.myRequest = res.data.pending_request;
              this.myInvites = res.data.pending_invite;
              this.ngxService.stop();
              // this.resStatus = res.status
            },
          });
          this.toastr.success(res.message);
        } else {
          this.ngxService.stop();
          this.toastr.error(res.message);
          // alert(res.status)
        }
      },
      error: (err: any) => {
        this.ngxService.stop();
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
      },
    });
  }
  sendMessage(teacher: any) {
    const initialState = {
      teacher: teacher,
      from: ''
    };
    this.bsModalRef = this.modalService.show(SendMailComponent, { initialState, class: 'modal-md', backdrop: 'static' });
  }

  getTeacherAvailabilityModal(teacher_id: any) {
    // this.ngxService.start();
    this.availTeacher = teacher_id
    this.bookingService.getAvailability(this.availTeacher).subscribe({
      next: (res: any) => {
        if (res.status) {
          // this.obsArray.next(res.data.data);
          const initialState = {
            teacher: res.data,
          };
          this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.closeBtnName = 'Close';
          // this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
        }

      },
      error: (err: any) => {
        this.ngxService.stop();
        this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
  ngListener() {
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  getTeacherLength() {
    this.FindNewteachersList.subscribe(result => {
      this.totalArrayCount = result.length;
    });
  }
  // Scroll to getData
  scroll = (event: any): void => {
    this.getTeacherLength();
    let totalPages = Math.ceil(this.teacherLengthLimit / this.totalArrayCount);
    // console.log(this.teacherLengthLimit);
    // console.log(this.totalArrayCount)
    // alert(totalPages)
    // alert(this.teacherLengthLimit)
    // alert(this.totalArrayCount)
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      let activePage = this.Paginator(this.totalArrayCount, this.currentPage, 30);
      // console.log(this.currentPage);
      // console.log(activePage);
      this.currentPage = activePage + 1;
      // alert("hi")
      if (this.findNewFilterForm.controls['page'].value != this.currentPage) {
        this.ngxService.start();
        forkJoin([this.FindNewteachersList.pipe(take(1)), this.bookingService.getFindNewFilterTeacher(this.getFilterFormControlValues('OnFilter'))]).subscribe((data: any) => {
          this.teacherLengthLimit = data[1].data.total_count;
          // console.log(data[0]);
          const newArr = [...data[0], ...data[1].data.consumer_list];
          this.obsArray.next(newArr);
          this.consumerList = newArr
          this.ngxService.stop();
        });
      }
    }
  };
  Paginator(totlallength: any, page: any, per_page: any) {

    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,

      // paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(totlallength / per_page);
    return total_pages;
  }
  nextFortnight() {
    this.setPrevNxtData(this.inlineEndDate, true, 1);
  }

  prevFortnight() {
    this.setPrevNxtData(this.inlineEndDate, true, 2);
  }
  formatSameDate(date: any) {
    var currentDate = date;
    var momentObj = moment(currentDate, 'DD/MM/YYYY');
    return momentObj.format('DD/MM/YYYY')
  }
  resetFilters() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }

  setPrevNxtData(res: any, apiCall: boolean, sendDate: number) {
    this.bsValue = ''
    this.inlineEndDate = res;
    this.selectAllCheck = false;
    this.hideButton = false
    this.consumerDetails = []
    // Set Next Prev Api Call
    if (apiCall) {
      this.ngxService.start();
      //  1 for nextfortnight
      //  2 for previousfortnight

      if (sendDate == 1) {
        let nxtPrevDate = this.addDays(this.inlineEndDate)
        this.findNewFilterForm.patchValue({
          date: nxtPrevDate,
          // filter_date:nxtPrevDate
        });
      } else if (sendDate == 2) {
        let nxtPrevDate = this.substractDays(this.inlineEndDate)
        this.findNewFilterForm.patchValue({
          date: nxtPrevDate,
          // filter_date:nxtPrevDate
        });
      }
      // let apiDate = sendDate == 1 ? this.inlineEndDate : sendDate == 2 ? this.addDays(this.inlineEndDate) : this.substractDays(this.inlineEndDate);

      // let nxtPrevDate = { 'date': apiDate };
      // console.log(nxtPrevDate);

      this.bookingService.getFindNewFilterTeacher(this.getFilterFormControlValues('nextPrew')).subscribe({
        next: (res: any) => {
          this.teacherLengthLimit = res.data.total_count;
          this.consumerList = res.data.consumer_list
          this.obsArray.next(res.data.consumer_list);
          this.datesHeaderArr = res.data.consumer_list;
          if (this.consumerList.length > 0) {
            this.inlineEndDate = res.data.consumer_list[0].availability[0].intervals[0].date;
          }
          // this.bsValue = this.formatSameDate(this.inlineEndDate);
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      })
    }
  }

  addDays(date: any) {
    var currentDate = date;
    var momentObj = moment(currentDate, 'DD/MM/YYYY');
    return momentObj.add(14, 'd').format('DD/MM/YYYY')
  }

  substractDays(date: any) {
    var currentDate = date;
    var momentObj = moment(currentDate, 'DD/MM/YYYY');
    return momentObj.subtract(14, 'd').format('DD/MM/YYYY')
  }

  redirectToPostCallOut() {
    if (window.location.hostname == "booking-test.classcover.com.au") {
      window.open("https://jobs-test.classcover.com.au/school/post-job?type=postCallOut")
    } else {
      window.open("https://jobs.classcover.com.au/school/post-job?type=postCallOut")
    }
    // this._router.navigate(['/school/post-job/'], { queryParams: { type: 'postCallOut' } });
  }

}
