import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
// Pages
import {
  PageNotFoundComponent
} from '.';

import { LoginComponent } from './pages/authorization/login/login.component';
import { RegisterComponent } from './pages/authorization/register/register.component';
import { AuthGuard } from './services/auth/auth.guard';
import { HtmlComponentsComponent } from './layout/html-components/html-components.component';
import { SchoolProfileComponent } from 'src/app/pages/producer/school-profile/school-profile.component';
import { PublicSchoolComponent } from './pages/common/public-school/public-school.component';
import {UnsuscribeEmailComponent} from './pages/common/unsuscribe-email/unsuscribe-email.component';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' , data: { title: 'Login | ClassCover' }},

  {
    path: 'login',
    data: { title: 'Login | ClassCover' },
    component: LoginComponent,
  },
  {
    path: 'register',
    data: { title: 'Register | ClassCover' },
    pathMatch: 'full',
    component: RegisterComponent,
  },
  {
    path: 'public-profile/:slugName',
    data: {
      title: 'school-profile',
      role: 'School'
    },
    component: PublicSchoolComponent,
    // canActivate: [AuthGuard, CheckSwitch]
  },
  {
    path: 'unsuscribe-email',
    data: {
      title: 'Unsuscribe-email',
      role: 'School'
    },
    component: UnsuscribeEmailComponent,
    // canActivate: [AuthGuard, CheckSwitch]
  },
  {
    path: 'school',
    loadChildren: () => import('./pages/producer/producer-routing.module').then(m => m.ProducerRoutingModule)
  },
  {
    path: 'consumer',
    loadChildren: () => import('./pages/consumer/consumer-routing.module').then(m => m.ConsumerRoutingModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: []
  }
];
