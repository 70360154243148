import { Component } from '@angular/core';
import { SchoolData } from 'src/app/models/schoolUser';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-onboarding-tour',
  templateUrl: './onboarding-tour.component.html',
  styleUrls: ['./onboarding-tour.component.scss']
})
export class OnboardingTourComponent {

  public schoolProps!: SchoolData;
  profile_percent: any;
  step: any;
  first_step: boolean = true
  second_step: boolean = false
  third_step: boolean = false
  fourth_step: boolean = false
  fifth_step: boolean = false
  six_step: boolean = false

  selectedID: any;
  teacher = [{
    'name':"Giana Stanton"
  },{
    'name':"Brandon George"
  },{
    'name':"Lindsey Calzoni"
  },{
    'name':"Hanna Culhane"
  },{
    'name':"Emery Torff"
  },{
    'name':"Erin Septimus"
  },{
    'name':"James Westervelt"
  }]
  addList: boolean = false;
  constructor(
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private _router:Router
  ) { }

  ngOnInit(): void {
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.selectedID = "1"
  }
  stepTwo(){
    this._router.navigate(['/school/school-onboarding'], { queryParams: { step: '_two' } });
  }
  stepThree(){
    this._router.navigate(['/school/school-onboarding'], { queryParams: { step: '_three' } });
  }

  nextStep(step: any){
    if(step == 'two'){
      this.first_step = false
      this.second_step = true
      this.third_step = false
      this.fourth_step = false
      this.fifth_step = false
      this.six_step = false
    }else if(step == 'three'){
      this.first_step = false
      this.second_step = false
      this.third_step = true
      this.fourth_step = false
      this.fifth_step = false
      this.six_step = false
    }else if(step == 'four'){
      this.addList = true
      this.first_step = false
      this.second_step = false
      this.third_step = false
      this.fourth_step = true
      this.fifth_step = false
      this.six_step = false
    }else if(step == 'five'){
      this.first_step = false
      this.second_step = false
      this.third_step = false
      this.fourth_step = false
      this.fifth_step = true
      this.six_step = false
    }else if(step == 'six'){
      this.first_step = false
      this.second_step = false
      this.third_step = false
      this.fourth_step = false
      this.fifth_step = false
      this.six_step = true
      window.scroll(0, 1300)
    }else if(step == 'done'){
      this.first_step = false
      this.second_step = false
      this.third_step = false
      this.fourth_step = false
      this.fifth_step = false
      this.six_step = false
      this._router.navigate(['/school/school-onboarding'], { queryParams: { step: '_three' } });
    }

  }
}

