<div class="modal-body px-10">
    <div class="row align-items-center">
        <div class="col-md-12">
            <p class="text-regular-medium text-center">
                No active session
            </p>
        </div>
    </div>
    <div class="mt-10">
        <p class="text-center">
            <button type="button" (click)="redirectToLogin()" class="btn cc_p_btn fs_13">
                Ok!
            </button>
        </p>
    </div>
</div>
