<div class="smart_groups_page pb-90 p-15">
    <p class="mt-15 text-regular-medium text-right"><button (click)="showSmartCreateModal('','')"
            class="btn cc_p_btn fs_13 ml-10 pt-10  pb-10 pl-30 pr-30">Create a Smart Group</button></p>
    <form [formGroup]="searchNameForm">
    <div class="row mt-20">
        <div class="col-md-12 col-lg-12">
            <ng-select formControlName="filter_group_name" [multiple]="true" (change)="filterNameSearch()" placeholder="Search by name">
                <ng-option *ngFor="let tag of filter_list" [value]="tag['id']">
                {{tag['name']}}
                </ng-option>
            </ng-select>
        </div>
    </div>
    </form>

    <div class="row">
        <div class="accordion"   *ngFor="let data of smartGroupList; index as i" id="accordionExample">
            <div class="accordion-item card">
                <h2 class="accordion-header"  (click)="openCollapse(data.id,data , $event , i)">
                    <button class="accordion-button" type="button"  data-bs-toggle="collapse" aria-expanded="true"
                    attr.aria-controls="collapse{{data.id}}">
                        <div class="row">
                            <div class="col-md-6 left-content">
                                <p class="f_n h_b">
                                    {{data.title}}
                                </p>
                            </div>
                            <div class="col-md-6 right-content text-regular-bold ">
                                <div class="d-flex restrict">
                                <p (click)="redirectBookingPage(openedGroupId)" class="restrict text-regular-bold clr-cc-vilot"
                                    *ngIf="(data.id == openedGroupId) && data.openPanel && (preferredList?.length > 0 || backUplist?.length > 0)"><i
                                        class="restrict bi bi-journal-text"></i> Place a booking</p>
                                <p (click)="showSmartCreateModal('edit',data)" class="ml-15 restrict pencil_icon"><i class="restrict bi bi-pencil-fill e_si clr-cc-vilot"></i></p>
                                <p class="ml-15 restrict people_icon"><i class="restrict bi bi-people-fill"></i> <span class="restrict"> {{data.teacher_count}}</span></p>
                                <i type="button" data-bs-toggle="dropdown" [ngClass]="hideButton ? '':'input-cursor-na'"
                                    aria-expanded="false"
                                    class="restrict bi  bi-three-dots-vertical dropdown-toggle ml-15 h_b"></i>
                                <ul class="restrict inline-action dropdown-menu txt-regular c-p">
                                    <li><a class="restrict dropdown-item" (click)="openDeleteGroupModal(data.id)">Delete Smart
                                            Group</a>
                                    </li>
                                </ul>
                            </div>
                                <!-- <p class="ml-15"><i class="bi bi-three-dots-vertical"></i></p> -->
                            </div>
                            <!-- {{data.school_staff.staff_list | json}} -->
                            <div *ngIf="data.school_staff.staff_list.length > 0" class="col-md-12 mt-5">
                                <p class="txt-regular tags"><span class="bg-tags mt-5"
                                        *ngFor="let group of data.school_staff.staff_list">{{group.full_name}}</span>
                                </p>
                            </div>
                            <div *ngIf="data.description" class="col-md-12 mt-10">
                                <p class="txt-regular tags">{{data.description}}</p>
                            </div>
                        </div>
                    </button>
                </h2>
                <!-- {{openedGroupId}}
                {{data.id}} -->
                <!-- [ngClass]="i == 0 ? 'show':''"  -->
                <div class="accordion-collapse collapse" [ngClass]="!openCollapseFind[i] ? 'openCollapseHide' : 'openCollapseShow show'" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="row mt-10 sub-header">
                            <div class="col-lg-5 col-md-8 left-content  c-p  space_bet text-regular-bold">
                                <!-- <div class="selected-data c-p">
                                    <p class="grey-3-14"> Add more to list</p>
                                </div> -->
                                <div class="">
                                    <p class="h_b" [ngClass]="hideButton ? 'input-cursor-na':''"
                                        (click)="OpenMoveStaffsModal()"><i class="bi bi-arrow-left-right mr-2"></i> Move
                                    </p>
                                </div>
                                <div class="selected-data message-btn ml-20 h_b">

                                    <p class="h_b" [ngClass]="hideButton ? 'input-cursor-na':''" (click)="sendMessage()">
                                        <i class="bi bi-send-fill mr-2"></i> Email Teachers</p>
                                </div>
                                <div class="selected-data message-btn ml-20 h_b">

                                    <p class="h_b" [ngClass]="hideButton ? 'input-cursor-na':''"
                                        (click)="OpenRemoveStaffsModal()"><i class="bi bi-trash-fill mr-2"></i> Remove
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 c-p  right-content text-regular-bold clr-cc-vilot">

                            </div>
                        </div>
                        <nav>
                            <div class="nav nav-tabs mt-10" id="nav-tab" role="tablist">
                                <button class="nav-link" [ngClass]="{ 'active':activeTab==='preferred'}"
                                    (click)="showSmartList('preferred')" id="nav-invite-tab{{groupId}}"
                                    data-bs-toggle="tab" attr.data-bs-target="#nav-invite-{{groupId}}" type="button"
                                    role="tab" aria-controls="nav-invite" aria-selected="true">Preferred
                                    list</button>
                                <button class="nav-link" [ngClass]="{ 'active':activeTab==='backup'}"
                                    (click)="showSmartList('backup')" id="nav-history-tab{{groupId}}"
                                    data-bs-toggle="tab" attr.data-bs-target="#nav-history-{{groupId}}" type="button"
                                    role="tab" aria-controls="nav-history" aria-selected="false">Backup list</button>
                            </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent" *ngIf="data.id == openedGroupId">
                            <!-- Pending Tab Content -->
                            <div class="tab-pane fade show" [ngClass]="{ 'active':activeTab==='preferred'}"
                                id="nav-invite-{{groupId}}" role="tabpanel"
                                attr.aria-labelledby="nav-invite-tab{{groupId}}">
                                <div class="col-md-12 mt-10" *ngIf="preferredList?.length > 0">
                                    <div class="card px-15 mt-5" *ngFor="let list of preferredList">
                                        <div class="row align-items-center">
                                            <div class="col-lg-9 col-md-7 col-11">
                                                <div class="col-md-12 col-lg-12 d-flex align-items-center">
                                                    <div class="pic-and-check c_input">
                                                        <input class="form-check-input mr-10 c-p"
                                                            (change)="selectAll(list, $event)"
                                                            [checked]="list.isChecked" type="checkbox"
                                                            id="{{list.teacher.id}}" type="checkbox">
                                                    </div>
                                                    <div class="pic-and-check ml-0">
                                                        <img alt="img"
                                                            [src]="list.teacher.meta.profile_image != null ? list.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                            class="profile-picture img-complete-match">
                                                    </div>
                                                    <div class="pic-and-check ml-10">
                                                        <div class="d-flex">
                                                            <p>
                                                                <span
                                                                    (click)="getTeacherAvailabilityModal(list.teacher.id)"
                                                                    class="s_n">{{list.teacher.user.full_name}}</span>
                                                                <span>
                                                                    <app-validated-dict
                                                                        [validateDict]="list.teacher.validated_dict">
                                                                    </app-validated-dict>
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <p class="user-sub-title">
                                                            <span
                                                                *ngIf="list?.teacher?.meta.you_are_a">{{list?.teacher?.meta.you_are_a}}
                                                            </span>
                                                            <span *ngIf="list?.teacher?.meta.you_are_a"
                                                                class="divider-right ml-3 mr-5">
                                                            </span>
                                                            Active
                                                            {{list?.teacher?.user.previous_login}}
                                                        </p>
                                                        <p class="text-s-r-g-3">
                                                            Accreditation No : <span
                                                                *ngIf="list?.teacher?.accreditation.accreditation">
                                                                <span class="text-s-m-g-3"
                                                                    *ngIf="list?.teacher?.accreditation.accreditation.length > 0">{{list?.teacher?.accreditation.accreditation[0].accreditation_id}}</span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-12">
                                                <p [ngClass]="!hideButton ? 'input-cursor-na':''"
                                                    (click)="OpenSingleRemoveStaffsModal(list?.id,list?.id)"
                                                    class="text-right clr-cc-vilot c-p"><i class="bi bi-trash-fill"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-10" *ngIf="preferredList?.length == 0">
                                    <p class="text-center"><img class="SmartGroups_NoMatchesFound"
                                            src="assets/images/SmartGroups_NoMatchesFound.png" alt="empty_img" /></p>
                                    <p class="text-r-18  text-center">Looks like your preferred list is empty.
                                    </p>
                                    <p class="mt-20 text-center"><button  routerLink="/school/my-list"
                                        class="btn cc_p_btn fs_13 ml-10 pt-10  pb-10 pl-30 pr-30">Add to your preferred list</button></p>
                                </div>
                            </div>
                            <!--End of Pending Tab Content -->
                            <!-- <app-booking-request [customTitle]="groupId"></app-booking-request> -->
                            <!-- History Tab Content -->
                            <div class="tab-pane fade"
                                [ngClass]="{ 'active':activeTab==='backup' , 'show':activeTab==='backup'}"
                                id="nav-history-{{groupId}}" role="tabpanel"
                                attr.aria-labelledby="nav-history-tab{{groupId}}">
                                <div class="col-md-12 mt-10" *ngIf="backUplist?.length > 0">
                                    <div class="card px-15 mt-5" *ngFor="let back_list of backUplist">
                                        <div class="row align-items-center">
                                            <div class="col-lg-9 col-md-7 col-11">
                                                <div class="col-md-12 col-lg-12 d-flex align-items-center">
                                                    <div class="pic-and-check c_input">
                                                        <input class="form-check-input mr-10 c-p"
                                                            (change)="selectAll(back_list, $event)"
                                                            [checked]="back_list.isChecked" type="checkbox"
                                                            id="{{back_list.teacher.id}}" type="checkbox">
                                                    </div>
                                                    <div class="pic-and-check ml-0">
                                                        <img alt="img"
                                                            [src]="back_list.teacher.meta.profile_image != null ? back_list.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                            class="profile-picture img-complete-match">
                                                    </div>

                                                    <div class="pic-and-check ml-10">
                                                        <div class="d-flex">
                                                            <p>
                                                                <span
                                                                    (click)="getTeacherAvailabilityModal(back_list.teacher.id)"
                                                                    class="s_n">{{back_list.teacher.user.full_name}}</span>
                                                                <span>
                                                                    <app-validated-dict
                                                                        [validateDict]="back_list.teacher.validated_dict">
                                                                    </app-validated-dict>
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <p class="user-sub-title">
                                                            <span
                                                                *ngIf="back_list?.teacher?.meta.you_are_a">{{back_list?.teacher?.meta.you_are_a}}
                                                            </span>
                                                            <span *ngIf="back_list?.teacher?.meta.you_are_a"
                                                                class="divider-right ml-3 mr-5">
                                                            </span>
                                                            Active
                                                            {{back_list?.teacher?.user.previous_login}}
                                                        </p>
                                                        <p class="text-s-r-g-3">
                                                            Accreditation No : <span
                                                                *ngIf="back_list?.teacher?.accreditation.accreditation">
                                                                <span class="text-s-m-g-3"
                                                                    *ngIf="back_list?.teacher?.accreditation.accreditation.length > 0">{{back_list?.teacher?.accreditation.accreditation[0].accreditation_id}}</span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-12">
                                                <p [ngClass]="!hideButton ? 'input-cursor-na':''"
                                                    (click)="OpenSingleRemoveStaffsModal(back_list?.id,back_list?.id)"
                                                    class="text-right clr-cc-vilot c-p"><i class="bi bi-trash-fill"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-10" *ngIf="backUplist?.length == 0">
                                    <p class="text-center"><img class="SmartGroups_NoMatchesFound"
                                            src="assets/images/SmartGroups_NoMatchesFound.png" alt="empty_img" /></p>
                                    <p class="text-r-18 text-center">Looks like your backup list is empty.
                                    </p>
                                    <p class="mt-20 text-center"><button routerLink="/school/my-list"
                                        class="btn cc_p_btn fs_13 ml-10 pt-10  pb-10 pl-30 pr-30">Add to your backup list</button></p>
                                </div>

                            </div>
                            <!--End of History Tab Content -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #moveSatffsModal>
    <form [formGroup]="moveToForm">
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">Confirmation</h4>
            <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <p class="txt-regular">Are you sure you want to move the selected to the 
                        <span *ngIf="activeTab == 'preferred'">backup list?</span>
                        <span *ngIf="activeTab == 'backup'">preferred list?</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button type="button" (click)="bsModalRef.hide()" class="btn_cc_back h_b">Cancel</button>
            <button type="button" (click)="moveToList(openedGroupId,teacher_id)" class="btn cc_p_btn fs_13">
                Move
            </button>
        </div>
    </form>
</ng-template>

<ng-template #RemoveStaffsModal>
    <form [formGroup]="moveToForm">
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">Confirmation</h4>
            <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <p class="txt-regular">Are you sure you want to remove the following teachers from this Smart Group?
                    </p>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button type="button" (click)="bsModalRef.hide()" class="btn_cc_back h_b">Cancel</button>
            <button type="button" (click)="RemoveFromList(openedGroupId,teacher_id)" class="btn cc_p_btn fs_13">
                Remove
            </button>
        </div>
    </form>
</ng-template>

<ng-template #DeleteGroupModal>
    <form [formGroup]="moveToForm">
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">Confirmation</h4>
            <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <p class="txt-regular">Are you sure you want to delete this Smart Group?
                    </p>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button type="button" (click)="bsModalRef.hide()" class="btn_cc_back h_b">Cancel</button>
            <button (click)="DeleteGroup(openedGroupId)" class="btn cc_p_btn fs_13">
                Delete
            </button>
        </div>
    </form>
</ng-template>