<div class="row mt-20"
    *ngIf="this.schoolProps.properties.job_only_school && boostJob?.is_boost_job == false && boostJob?.job_status.job_status == 'L'">
    <div class="jobs_only_user">
        <div class="col-lg-12">
            <div class="card boost_jobs_card px-0" *ngIf="!freeBoost">
                <div class="d-flex align-items-center">
                    <div>
                        <img class="boost_img" src="assets/images/jobs/job_only_user/Boost_y.png">
                    </div>
                    <div class="pl-15 pr-15">
                        <h4 class="boost_heading">Boost your job to reach more educators</h4>
                        <p class="txt-regular mt-5">Boosted job posts sit at the top of
                            search results and are sent directly to the inbox of educators
                            in your area.</p>

                        <div class="mt-5">
                            <label (click)="boostPostedJob(this.boostJob.id)" class="user-title clr-cc-vilot c-p mt-3"
                                for="boost_job">
                                Boost job for $20 <i class="bi bi-arrow-right"></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card boost_jobs_cards px-0" *ngIf="freeBoost">
                <div class="d-flex align-items-center">
                    <div class="pl-15 pr-15 px-15">
                        <div>
                            <p class="text-regular-medium int_off">🎉 Introductory offer </p>
                            <h4 class="boost_heading mt-10">Enjoy 2 <span class="clr-cc-vilot">FREE</span> job boosts
                                every month</h4>
                            <p class="txt-regular mt-5">Boosted job posts sit at the top of search results and are sent
                                directly to the inbox of educators in your area.</p>
                            <div class="mt-5">
                                <label (click)="boostPostedJob(this.boostJob.id)" class=" c-p mt-3" for="boost_job">
                                    <span class="user-title clr-cc-vilot "> Boost job for $0</span><span
                                        class="strike_rate clr-grey-3  fs-16">$20</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="free_boostNotify text-center">
                        <p class="text-regular-bold fs-24">{{freeBoostCount}}</p>
                        <p class="txt-regular">Left</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>