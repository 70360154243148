<div class="pb-30 pt-15"
    [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'">
    <h4 class="mb-20">Edit settings </h4>

    <div class="row">
        <div class="col-lg-8 col-12 col-md-8">
            <div class="card px-15">
                <h5 class="ul-title mb-20">Account settings</h5>
                <form [formGroup]="updatePassword">
                    <div class="row">
                        <div class="col-lg-6 col-12 col-md-6">
                            <input type="text" class="form-control mb-20" placeholder="Current password"
                                formControlName="current_password">
                        </div>
                        <div class="col-lg-6 col-12 col-md-6">
                            <input type="text" class="form-control mb-20" placeholder="New password"
                                formControlName="new_password">
                        </div>
                        <div class="col-lg-6 col-12 col-md-6">
                            <input type="text" class="form-control mb-20" placeholder="Confirm new password"
                                formControlName="confirm_password">
                            <button type="button" (click)="SubmitForm('update-password')"
                                class="btn pl-20 pr-20 cc_p_btn fs_13  btn-sm">
                                Change password
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-lg-8 col-12 col-md-8 mt-20">
            <div class="card px-15">
                <h5 class="ul-title mb-20">Job applicant notifications</h5>
                <form [formGroup]="jobApllicantNotification">
                    <div class="form-check">
                        <input class="form-check-input c-p mt-4" type="checkbox" (change)="setJobAlert();"
                            id="enable_notification" formControlName="enable_notification">
                        <label class="form-check-label c-p" for="enable_notification">
                            Enable job application summary emails
                        </label>
                    </div>
                    <div class="ml-25 mt-10">
                        <div class="form-check input-cursor-na">
                            <input class="form-check-input mt-4" formControlName="job_alert_notify" value="weekly"
                                [checked]="jobApllicantNotification.controls['job_alert_notify'].value == 'weekly' ? true : false"
                                value="1" type="radio" name="job_alert_notify" id="job_alert_notify">
                            <label class="form-check-label" for="job_alert_notify">
                                Weekly
                            </label>
                        </div>
                    </div>
                    <button type="button" (click)="SubmiJobNotitForm('job-notification')"
                        class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
                        Save changes
                    </button>
                </form>
            </div>
        </div>
    </div>


    <div class="row  mt-20">
        <div class="col-lg-8 col-12 col-md-8">
            <div class="card px-15 ">
                <div class="row">
                    <div class="col-lg-10 col-md-10 col-sm-9 col-xs-9 col-12">
                        <h5 class="ul-title">Personal Information</h5>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3 col-12">
                        <p class="c-p text-right text_medium" (click)="disableFalse()">
                            Edit
                        </p>
                    </div>
                </div>

                <form [formGroup]="personalForm">
                    <div class="row">
                        <div class="col-lg-6 col-12 col-md-6">
                            <input type="text" class="form-control mb-10" [attr.disabled]="!edit ? '' : null"
                                placeholder="First name" formControlName="first_name">
                        </div>
                        <div class="col-lg-6 col-12 col-md-6">
                            <input type="text" class="form-control mb-10" [attr.disabled]="!edit ? '' : null"
                                placeholder="Last name" formControlName="last_name">
                        </div>
                        <div class="col-lg-6 col-12 col-md-6">
                            <input type="text" class="form-control mb-10" [attr.disabled]="!edit ? '' : null"
                                placeholder="Phone number" formControlName="phone_number">
                        </div>
                        <div *ngIf="edit" class="col-lg-12 col-12 col-md-12">
                            <button type="button" (click)="submitPersonalForm('personal_info')"
                                class="btn fit-content pl-20 pr-20 cc_p_btn fs_13 btn-sm">
                                Save changes
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="row  mt-20">
        <div class="col-lg-8 col-12 col-md-8">
            <div class="card px-15 ">
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-12">
                        <h5 class="ul-title">Subscription and Billing</h5>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-12">
                        <p routerLink="/school/job-payment-history" class="c-p clr-cc-vilot text-right text_medium">
                            View invoices
                        </p>
                    </div>
                </div>

                <form [formGroup]="personalForm">
                    <div class="row">
                        <div class="col-lg-12 col-12 col-md-12">
                            <div>
                                <span class="txt-regular grey-3">Current Subscription: </span><span
                                    class="txt-regular grey-1"> {{editData?.subscription?.current_plan}}</span>
                            </div>
                            <div class="mt-10" *ngIf="editData?.subscription?.is_subscription_expired">
                                <span class="txt-regular grey-3">Expired on: </span><span class="txt-regular grey-1">
                                    {{editData?.subscription?.valid_to}}</span>
                            </div>
                            <div class="mt-10"
                                *ngIf="!editData?.subscription?.is_subscription_expired && !editData?.subscription.is_subscription_cancelled">
                                <span class="txt-regular grey-3">Renews on: </span><span class="txt-regular grey-1">
                                    {{editData?.subscription?.valid_to}}</span>
                            </div>
                            <div class="mt-10"
                                *ngIf="!editData?.subscription?.is_subscription_expired && editData?.subscription?.is_subscription_cancelled">
                                <span class="txt-regular grey-3">Expires on: </span><span class="txt-regular grey-1">
                                    {{editData?.subscription?.valid_to}}</span>
                            </div>
                        </div>

                        <div class="col-lg-12 col-12 col-md-12 mt-15"
                            *ngIf="!editData?.subscription?.is_subscription_expired && !editData?.subscription?.is_subscription_cancelled">
                            <button type="button" (click)="openCancelSubsModal('cancel')"
                                class="btn fit-content res-w-100  pl-20 pr-20 cc_p_btn_outline fs_13 btn-sm mr-20">
                                Cancel subscription
                            </button>
                            <button *ngIf="editData?.subscription?.current_plan != 'Annual Premium'"
                                routerLink="/school/job-subscription" type="button"
                                class="btn fit-content pl-20 pr-20  res-w-100 cc_p_btn fs_13 btn-sm">
                                Upgrade subscription
                            </button>
                        </div>
                        <div class="col-lg-12 col-12 col-md-12 mt-15"
                            *ngIf="!editData?.subscription?.is_subscription_expired && editData?.subscription?.is_subscription_cancelled">
                            <button (click)="openCancelSubsModal('reactivate')" type="button"
                                class="btn fit-content pl-20  res-w-100 pr-20 mr-20 cc_p_btn_outline fs_13 btn-sm">
                                Re-activate subscription
                            </button>
                            <button *ngIf="editData?.subscription?.current_plan != 'Annual Premium'"
                                routerLink="/school/job-subscription" type="button"
                                class="btn fit-content  res-w-100 pl-20 pr-20 cc_p_btn fs_13 btn-sm">
                                Upgrade subscription
                            </button>
                        </div>
                        <div class="col-lg-12 col-12 col-md-12 mt-15"
                            *ngIf="editData?.subscription?.is_subscription_expired">
                            <button type="button" routerLink="/school/job-subscription"
                                class="btn fit-content  res-w-100 pl-20 pr-20 cc_p_btn fs_13 btn-sm">
                                Re-activate subscription
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <ng-template #cancelSubsModal>
        <div *ngIf="action == 'cancel'">
            <div class="modal-header"
                [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'">
                <h4 class="modal-title" id="editProfileInformationModal">
                    Cancel subscription
                </h4>
                <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
            </div>
            <div class="modal-body pb-0">
                <div class="col-xs-12 col-md-12">
                    <p class="txt-regular">
                        Are you sure you want to cancel your subscription?
                    </p>
                </div>

            </div>
            <div class="modal-footer pb-0">
                <div
                    [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'">
                    <button (click)="closeSubs(action)" type="button"
                        class="btn fit-content pl-20 pr-20 cc_p_btn fs_13 btn-sm">
                        Cancel subscription
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="action == 'reactivate'">
            <div class="modal-header"
                [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'">
                <h4 class="modal-title" id="editProfileInformationModal">
                    Are you sure you want to re-activate your subscription?
                </h4>
                <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
            </div>
            <div class="modal-body pb-0">
                <div class="col-xs-12 col-md-12">
                    <div>
                        <span class="txt-regular grey-3">Current Subscription: </span><span class="txt-regular grey-1">
                            {{editData?.subscription?.current_plan}}</span>
                    </div>
                    <div class=""
                        *ngIf="!editData?.subscription?.is_subscription_expired && editData?.subscription?.is_subscription_cancelled">
                        <span class="txt-regular grey-3">Expires on: </span><span class="txt-regular grey-1">
                            {{editData?.subscription?.valid_to}}</span>
                    </div>
                </div>

            </div>
            <div class="modal-footer pb-0">
                <div
                    [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'">
                    <button (click)="closeSubs(action)" type="button"
                        class="btn fit-content pl-20 pr-20 cc_p_btn fs_13 btn-sm">
                        Re-activate subscription
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>