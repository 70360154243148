<div class="cust-confirm-modal" tabindex="-1">
  <div class="row">
    <div class="col-lg-12">
      <h4 class="modal-title float-start">{{ title }}</h4>
      <i class="bi  bi-x-lg c-p close float-end" aria-hidden="true" (click)="this.bsModalRef.hide()"></i>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="body-content d-flex">
        <!-- <div class="circle bg-filled-warning" *ngIf="confirmAction == 'confirm'"><i class="bi bi-exclamation-triangle-fill"></i></div>
          <div class="circle bg-filled-success" *ngIf="confirmAction == 'success'"><i class="bi bi-exclamation-triangle-fill"></i></div> -->
        <!-- <div class="circle bg-filled-error" *ngIf="confirmAction == 'error'"><i class="bi bi-x"></i></div> -->
        <div>
          <!-- <h4 class="modal-title mb-0"></h4> -->
          <p class="pt-10 txt-regular">{{ contents }}</p>
          <p class="pt-10 txt-regular">{{ body }}</p>
          <p class="pt-10 txt-regular">{{ subContent }}</p>
        </div>
      </div>
    </div>
    <div class="col-lg-12" *ngIf="showForm">
      <form [formGroup]="popUpConfirmationForm" novalidate>
        <div class="row mb-20">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="popup_confirmation_details" class="form-label">{{inputLabel}}</label>
              <textarea class="form-control mb-20" id="popup_confirmation_details" rows="3"
                formControlName="rejection_reason"></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12" *ngIf="confirmAction == 'confirm'">
      <div class="modal-footer border-0">
        <!-- <button type="button" class="btn btn-outline me-2" (click)="bsModalRef.hide()">Cancel</button> -->
        <button type="button" class="btn cc_p_btn fs_13" (click)="confirmSave()">{{saveBtnLable ? saveBtnLable :
          "Save"}}</button>
      </div>
    </div>

    <div class="col-lg-12 p-0" *ngIf="confirmAction != 'confirm'">
      <div class="modal-footer border-0 p-0">
        <button type="button" class="btn primary" (click)="SuccessAlert()">Ok</button>
      </div>
    </div>
  </div>
</div>