import { Component, Injector, Input } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, NavigationEnd, Route } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface toggleSidenav {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title = 'Class Cover';
  producerHeader: boolean = false;
  consumerHeader: boolean = false;
  publicHeader: boolean = false;
  sideNav: boolean = false;

  titleService: Title;

  constructor(
    public router: Router,
    private injector: Injector,
    public activatedRoute: ActivatedRoute
  ) {
    this.titleService = injector.get<Title>(Title);
  }

  ngOnInit() {
    this.isCheckUser();
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild!.firstChild!;
          // console.log(child);
          if (child) {
            // console.log(this.activatedRoute)
            // console.log(child.snapshot.data['title']);
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });

  }

  isCheckUser() {
    const url = this.router.url;
    if (
      (url === '/') ||
      (url === '/login') ||
      (url.match(/\/login.*/)) ||
      (url.match(/\/public-profile.*/)) ||
      (url.match(/\/signup.*/)) ||
      (url === '/signup') ||
      (url === '/register') ||
      (url === '/public-profile')
    ) {
      return false;
    }
    let useRole = localStorage.getItem('userRole');
    switch (useRole) {
      case 'School':
        this.producerHeader = true;
        this.sideNav = true;
        break;
      case 'Teacher':
        this.consumerHeader = true;
        break;
      default:
        this.publicHeader = true;
        break;
    }

    return true;

  }

  isSidenavCollapsed = false;
  screenWidth = 0;

  onToggleSideNav(data: toggleSidenav): void {
    this.screenWidth = data.screenWidth
    this.isSidenavCollapsed = data.collapsed
  }

}
