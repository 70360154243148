<div class="container pb-50" id="profile-school-settings">
  <div class="row">
    <div class="col-md-12 col-lg-12 mt-20">
      <p class="user-title">Profile settings</p>
      <div class="row">
        <div class="col-md-9 col-lg-9 col-12">
          <div class="card px-15 mt-20">
            <div class="d-flex justify-content-space-bet align-items-center">
              <div>
                <h1 class="user-title">School profile information</h1>
                <p class="txt-regular">
                  Edit how you want your school to appear on your profile and to
                  teachers
                </p>
              </div>
              <div class="flex-end">
                <p
                  class="text-regular-bold c-p h_b"
                  (click)="editSettings('school-profile')"
                >
                  <i class="bi bi-pencil-fill fs-12 mr-2"></i> Edit
                </p>
              </div>
            </div>
          </div>
          <div class="card px-15 mt-20">
            <div class="d-flex justify-content-space-bet align-items-center">
              <div>
                <h1 class="user-title">Password</h1>
                <p class="txt-regular">Change your login password</p>
              </div>
              <div class="flex-end">
                <p
                  class="text-regular-bold c-p h_b"
                  (click)="editSettings('update-password')"
                >
                  <i class="bi bi-pencil-fill fs-12 mr-2"></i> Edit
                </p>
              </div>
            </div>
          </div>

          <p class="user-title mt-20">School settings</p>

          <div class="card px-15 mt-20">
            <div class="d-flex justify-content-space-bet align-items-center">
              <div>
                <h1 class="user-title">Booking and notification settings</h1>
                <p class="txt-regular">
                  Edit your account booking and notification settings
                </p>
              </div>
              <div class="flex-end">
                <p
                  class="text-regular-bold c-p h_b"
                  (click)="editSettings('booking-and-notification-settings')"
                >
                  <i class="bi bi-pencil-fill fs-12 mr-2"></i> Edit
                </p>
              </div>
            </div>
          </div>
          <div class="card px-15 mt-20">
            <div class="d-flex justify-content-space-bet align-items-center">
              <div>
                <h1 class="user-title">Default search settings</h1>
                <p class="txt-regular">
                  Manage how you’d like casuals to appear
                </p>
              </div>
              <div class="flex-end">
                <p
                  class="text-regular-bold c-p h_b"
                  (click)="editSettings('default-search-settings')"
                >
                  <i class="bi bi-pencil-fill fs-12 mr-2"></i> Edit
                </p>
              </div>
            </div>
          </div>
          <div class="card px-15 mt-20">
            <div class="d-flex justify-content-space-bet align-items-center">
              <div>
                <h1 class="user-title">Manage smart groups</h1>
                <p class="txt-regular">Manage how your teachers are grouped</p>
              </div>
              <div class="flex-end">
                <p
                  class="text-regular-bold c-p h_b"
                  (click)="editSettings('manage-smart-groups')"
                >
                  <i class="bi bi-pencil-fill fs-12 mr-2"></i> Edit
                </p>
              </div>
            </div>
          </div>
          <div class="card px-15 mt-20">
            <div class="d-flex justify-content-space-bet align-items-center">
              <div>
                <h1 class="user-title">Manage job applicant notifications</h1>
                <p class="txt-regular">Enable or disable notifications when a job you posted gets a new applicant</p>
              </div>
              <div class="flex-end">
                <p
                  class="text-regular-bold c-p h_b"
                  (click)="editSettings('manage-job-applicant-notifications')"
                >
                  <i class="bi bi-pencil-fill fs-12 mr-2"></i> Edit
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-12 mt-20">
          <div class="card px-15">
            <p
              class="txt-regular c-p h_b"
              routerLink="/school/my-settings"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <i class="bi bi-person-lines-fill mr-5"></i> Profile settings
            </p>
            <p
              class="txt-regular c-p mt-10 h_b"
              routerLink="/school/admin-settings"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <i class="bi bi-gear-fill mr-5"></i> Admin settings
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
