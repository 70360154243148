import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { SchoolData } from 'src/app/models/schoolUser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  public schoolProps!: SchoolData;

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken: string, mixpanel_app_id: any): void {
    mixpanel.init(mixpanel_app_id);
    mixpanel.identify(userToken);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
  bookingRequestPage(): void {
    mixpanel.track("Booking Page view", {
      "Angular": true,
    });
  }
  bookingRequestSent(InviteLength: number, requestType: any, delay_from_previous: any, timeToLive: any, booking_dates: any, send_backup_list: any, reBook: any) {
    mixpanel.track(
      "Booking Request Sent", {
      "Angular": true,
      "list count": InviteLength,
      "request type": requestType,
      "request delay": delay_from_previous + 'min gap',
      "close booking after": timeToLive,
      "bookingRange": booking_dates,
      "back_up_list_checked": send_backup_list,
      "rebook": reBook,
      "rebook_dublicate": false,
    }
    );
  }
  preconfirmedBooking(dayType: any) {
    if (dayType != 'bookingRequest') {
      if (dayType = 'oneDayBooking') {
        mixpanel.track("Pre-Confirmed Booking", {
          "Angular": true
        });
      } else {
        mixpanel.track("Recurring Pre-Confirmed Booking", {
          "Angular": true
        });
      }
    }

  };
  smartGroupPage(smartLength: any) {
    mixpanel.track(
      "Smart Group", {
      "Angular": true,
      "smart_group_count": smartLength,
    }
    );
  }
  smartGroupPageBookings(id: any) {
    mixpanel.track(
      "Place a booking from smart group", {
      "Angular": true,
    }
    );
  }
  findNewTeacherPage() {
    mixpanel.track("Find New Teacher Page view", {
      "Angular": true,
    });
  };
  findNewTeacherPageInvite() {
    mixpanel.track("Teacher(s) invited from FNT", {
      "Angular": true,
    });
  }

  calendarView(): void {
    mixpanel.track("Calendar Page view", {
      "Angular": true,
    });
  }
  requestHistory(): void {
    mixpanel.track("Request History Page view", {
      "Angular": true,
    });
  }
  myTeachers(): void {
    mixpanel.track("My Teachers Page view", {
      "Angular": true,
    });
  }
  removerTeacher() {
    mixpanel.track("Teacher Removed click", {
      "Angular": true,
    });
  }
  inflightPage(): void {
    mixpanel.track("Booking Request Inflight Page view", {
      "Angular": true,
    });
  }
  sendInviteToJob(action: any): void {
    mixpanel.track(action);
  }
  applicantStatusChange(action: any): void {
    mixpanel.track(action);
  }
  applicantStatusChangeonBulk(status: any, action: any): void {
    mixpanel.track(status, action);
  }
  subscriptionPurchase(plan_name: any): void {
    mixpanel.track(
      'Subscription type', {
      plan_name
    }
    );
  }
  jobCreditMixpanel(action: any): void {
    mixpanel.track(action);
  }

  postJobAction(remote: any, boost_job: any, success: any): void {
    mixpanel.track(
      "Post job",
      {
        "is_boosted": boost_job,
        "is_remote": remote,
        "is_successful": success,
      }
    );
  }
  schoolUserLoad(data: any): void {
    mixpanel.identify(data.mixpanel_data.school_id);
    mixpanel.people.set({
      "$city":data.mixpanel_data.city,
      "$name":data.mixpanel_data.school_name,
      "school_name":data.mixpanel_data.school_name,
      "group_name":data.mixpanel_data.group_name,
      "postal_code":data.mixpanel_data.postal_code,
      "state":data.mixpanel_data.state,
      "Sign up date":data.mixpanel_data.mx_school_signup_date,
      "USER_ID":data.mixpanel_data.school_id,
      "distinct_id":data.mixpanel_data.school_id,
      "platform":"Web",
      "school_teacher_count":data.mixpanel_data.school_teacher_count,
      "teacher_covered":data.mixpanel_data.tc_required,
      "class_covered":data.mixpanel_data.cc_required,
      "subscription_type":data.mixpanel_data.subscription_type,
      "school_level":data.mixpanel_data.school_level,
      "school_type":data.mixpanel_data.school_type,
      "low_year":data.mixpanel_data.low_year,
      "high_year":data.mixpanel_data.high_year,
      "enrolment_size":data.mixpanel_data.enrolment_size,
      "school_booking_request":data.mixpanel_data.school_booking_request,
    });
  }
}

