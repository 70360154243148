import { Component } from '@angular/core';

@Component({
  selector: 'app-completed-onboarding',
  templateUrl: './completed-onboarding.component.html',
  styleUrls: ['./completed-onboarding.component.scss']
})
export class CompletedOnboardingComponent {

}
