import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { flatMap, last, map, take } from "rxjs/operators";
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

// models
import { SchoolData } from 'src/app/models/schoolUser';
import { TeacherProfilePopupComponent } from '../teacher-profile-popup/teacher-profile-popup.component';

// Components
import { ConfirmationModalComponent } from '../../../layout/confirmation-modal/confirmation-modal.component';
import { format } from 'path';
import { AddNotesComponent } from '../modals/add-notes/add-notes.component';
import { ToastrService } from 'ngx-toastr';
import { Intercom } from 'ng-intercom';
import { MixpanelService } from 'src/app/mixpanel.service';
import timepicker from 'src/app/pages/producer/timepicker';

@Component({
  selector: 'app-booking-request',
  templateUrl: './booking-request.component.html',
  styleUrls: ['./booking-request.component.scss']
})

export class BookingRequestComponent implements OnInit {
  @ViewChildren("checkboxes") checkboxes!: QueryList<ElementRef>
  @ViewChildren("bookingRequest") bookingRequestPage!: ElementRef;
  @ViewChild('flexiBookingModal') flexiBookingModal: any;
  @ViewChild('emergencyBookingModal') emergencyBookingModal: any;


  public schoolProps!: SchoolData;
  datePickerConfig: Partial<BsDatepickerConfig> | undefined;
  status: boolean = false;
  obsArray: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  teachersList: Observable<any> = this.obsArray.asObservable();
  currentPage: number = 1;
  pageSize: number = 1;
  getScrolltoCallApi: boolean = true;
  inviteList: Array<any> = [];
  CustomId: Array<any> = [];
  backUpList: Array<any> = [];
  delayValue: Array<any> = [];
  delayValueee: Array<any> = [];
  hideme: any = {};
  unAvailable: any = {};
  is_readonly: boolean = false
  tagsAndSubjects: boolean = true;
  showFilters: boolean = false;
  showDelay: boolean = false;
  showInformation: boolean = false;
  activeIndex: number = 0;
  scrolled: boolean = true;
  stickyAddAll: boolean = false;
  showBackup: boolean = false;
  showBackupTime: boolean = false;
  showEmergencyTime: boolean = false;
  addTagText: boolean = true
  BindSmartValue = [];
  fileName: any;
  minDate: Date;
  bsRangeValue: Date[];
  bsValue = new Date();
  bookingRage: any;
  teacherLengthLimit: any;
  totalArrayCount: any
  // Filters
  filtersArray: any = []
  subjectsAndTags = [];
  levelFilter = [];
  consumerType = [];
  consumerRoleType: any = [];
  selectedSubjects = [];
  schoolTimings: any;
  checkBoxSelectedTeacher: any = [];
  selectedRole: any
  // Modal
  @Input() customTitle: any;
  bsModalRef!: BsModalRef;
  submitted: boolean = false;
  noOfStaffs = [
    {
      'title': 1,
      'value': 1
    }, {
      'title': 2,
      'value': 2
    }, {
      'title': 3,
      'value': 3
    }, {
      'title': 4,
      'value': 4
    }, {
      'title': 5,
      'value': 5
    }, {
      'title': 6,
      'value': 6
    }, {
      'title': 7,
      'value': 7
    }, {
      'title': 8,
      'value': 8
    }, {
      'title': 9,
      'value': 9
    }, {
      'title': 10,
      'value': 10
    },
  ]
  selectedPeople = [];
  minutes: any
  bookingFilterForm: FormGroup = this.fb.group({
    booking_dates: [],
    filter_consumer_type: [''],
    filter_experience_items: [''],
    booking_levels: [''],
    filter_tags: [''],
    system_tags: [''],
    filter_name: [''],
    start_hrs: ['08'],
    start_min: ['00'],
    end_hrs: ['15'],
    end_min: ['30'],
    show_partial_matches: [true],
    filter_rating: [''],
    filter_distance: ['10000000'],
    filter_is_certified: [false],
    filter_is_validated: [false],
    timerange: ['full_day'],
    filter_isactive: ['0'],
    page: [],
    no_of_staff: ['1'],
    booking_classcovered: ['', [Validators.required, this.noWhitespaceValidator]],
    booking_teachercovered: [],
    booking_teachercovered_id: [''],
    booking_absencereason: [''],
    booking_smartgroup: [''],
    booking_role_type: [1],
    booking_notes: [''],
    booking_attachment: [''],
    booking_payrollfield1: [''],
    booking_payrollfield2: [''],
    booking_payrollfield3: [''],
    booking_leaveapproved: ['1'],
    strategy: ['ALL_AT_ONCE'],
    delay_from_previous: ['2'],
    time_to_live: ['3600'],
    schedule_booking: [false],
    schudule_time_hours: ['05'],
    schudule_time_mins: ['00'],
    filter_is: [''],
    // schudule_time_am_pm: ['AM'],
    schedule_day: ['today'],
    booking_type: ['booking_request'],
    is_special_booking: [false],
    cleaner_booking: [],
    booking_attachement_link: [''],
    start_time: ['08:00'],
    end_time: ['15:30'],
    emergency_timetolive: ['1800'],
    emergency_send_time: [''],
    send_emergency_list: [false],
    update_timetolive: ['3600'],
    send_backup_list: [false],
    flexi_booking: [''],
    send_to_everyone: [false],
    uuid: [''],
    update_type: [''],
    // filter_order_by: ['#match|DESC'],
    customDelayPrevious: this.fb.array([]),
    custom_delay_previous: [''],
    is_sgt: [false],
    is_nsbts: [false],
    is_other_staff: [false],
    is_corporate_staff: [false],
    sort_by: ['DESC'],
    lineitem:[''],
    rebook_duplicate:[false]
  });

  bookingAvailableForm: FormGroup = this.fb.group({
    booking_dates: [''],
    time_range: [''],
    consumer_id: ['']
  });


  selectFilter = [
    {
      'title': 'Select all',
      'value': 1
    }, {
      'title': 'Select active',
      'value': 2
    }, {
      'title': 'Select inactive',
      'value': 3
    }, {
      'title': 'Select approved to teach',
      'value': 4
    }, {
      'title': 'Select not approved to teach',
      'value': 5
    }
  ]
  selectedFilter: any;
  booking_id: any;
  addedTeacherList: any;
  data: any;
  fromPage: any;
  addMoreBooking: boolean = false;
  bookingInFlightData: any;
  hidemeForAddMore: boolean = false
  teacherData: any;
  school_staff: any;
  smart_group: any;
  TeacherCoveredArr: any;
  school_staffs: any;
  bookingType: any;
  makeMadantory: boolean = false;
  sub_is_readonly: boolean = false;
  level_is_readonly: boolean = false;
  send_is_readonly: boolean = false;
  availTeacher: any;
  is_clone_reopen: any;
  editOnClone: boolean = false
  reOpenBooking: boolean = false
  showbackUpList: boolean = false;
  disableBackup: boolean = false;
  disableEmergencyList: boolean = false;
  requestDefaultType: any;
  listHealth: any;
  showUpdatebtn: boolean = false;
  showInviteListMsg: boolean = false;
  delayBetReq: boolean = false;
  pendingInvites: any;
  pendingRequest: any;
  bookingRoleType: any;
  hidetimeRange: boolean = false;
  InviteRequestData: any;
  list: Array<any> = [];
  selectedGroup: any;
  smartID: any;
  timepicker: typeof timepicker | undefined;
  hours_d: any;
  minutes_d: any;
  custom_text: any;
  from_page_cookie: any;
  startTime: any;
  endTime: any;
  schoolFullTime: any;
  schoolHalfTimeAm: any;
  schoolHalfTimePm: any;
  makeManTrue: boolean = false;
  screenWidth!: number;
  showInviteList: boolean = false;
  lineId: any;
  rebookBookings: boolean = false;

  constructor(private route: ActivatedRoute,
    private bookingService: BookingService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private toastr: ToastrService,
    public intercom: Intercom,
    private mixpanelService: MixpanelService
  ) {
    this.minDate = new Date();

    // this.maxDate = new Date();
    // this.minDate.setFullYear(this.minDate.getFullYear());
    // this.maxDate.setDate(this.maxDate.getDate() + 7);
    if (this.minDate.getDay() == 0) {
      this.minDate.setDate(this.minDate.getDate() + 1);
      this.bsValue.setDate(this.bsValue.getDate() + 1);
    } else if (this.minDate.getDay() == 6) {
      this.minDate.setDate(this.minDate.getDate() + 2);
      this.bsValue.setDate(this.bsValue.getDate() + 2);
    } else {
      this.minDate.setDate(this.minDate.getDate());
      this.bsValue.setDate(this.bsValue.getDate());
    }

    this.bsRangeValue = [this.bsValue, this.bsValue];

    // this.maxDate.setDate(this.maxDate.getFullYear());
    // this.bsValue = new Date(
    //   new Date().setFullYear(new Date().getFullYear())
    // );    
  }

  // choose better name ;)
  get formArr() {
    return this.bookingFilterForm.get("customDelayPrevious") as FormArray;
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 992) {
      this.showInviteList = true
    } else {
      this.showInviteList = false
    }
  }
  ngOnInit(): void {
    this.ngxService.start();
    this.from_page_cookie = document.cookie.match('(^|;)\\s*' + 'from' + '\\s*=\\s*([^;]+)')?.pop() || '';
    this.mixpanelService.bookingRequestPage();
    this.getListHealth()
    this.getInviteRequestData();

    var setBookingRole = this.bookingFilterForm.controls['booking_role_type'].value;
    this.bookingFilterForm.patchValue({
      filter_consumer_type: [setBookingRole],
    });
    this.timepicker = timepicker
    this.hours_d = this.timepicker[0].hours
    this.minutes_d = this.timepicker[0].minutes

    let timing = this.bookingFilterForm.controls['timerange'].value;
    if (timing == 'special') {
      this.hidetimeRange = true
    } else {
      this.hidetimeRange = false
    }
    // Add more Teachers
    this.route.params.subscribe(params => {
      this.is_clone_reopen = params['page_type'];
    });

    this.route.queryParams.subscribe(params => {
      this.lineId = params['lineId'];
    });

    if (this.is_clone_reopen == 'smart-group') {
      this.route.params.subscribe(params => {
        this.smartID = params['booking_id'];
        this.booking_id = undefined
      });
    } else {
      this.route.params.subscribe(params => {
        this.booking_id = params['booking_id'];
      });
    }
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    // checking booking id
    if (this.booking_id != undefined || (this.is_clone_reopen != undefined && this.is_clone_reopen != 'smart-group')) {
      // In-flight page booking data API
      this.bookingService.getInFlightData(this.booking_id, this.is_clone_reopen , this.lineId).subscribe({
        next: (res: any) => {
          this.data = res.data;
          this.bookingInFlightData = res.data.notifications;
          this.requestDefaultType = res.data.strategy;
          if (this.requestDefaultType == 'ALL_AT_ONCE' || this.requestDefaultType == 'AAO') {
            this.bookingFilterForm.patchValue({
              strategy: 'ALL_AT_ONCE',
            });
            this.delayBetReq = true
          } else if (this.requestDefaultType == 'ONE_AT_A_TIME' || this.requestDefaultType == 'OAT') {
            this.bookingFilterForm.patchValue({
              strategy: 'ONE_AT_A_TIME',
            });
            this.delayBetReq = false
          } else {
            this.bookingFilterForm.patchValue({
              strategy: 'ALL_AT_ONCE',
            });
            this.delayBetReq = true
          }
          this.commonService.schoolChoices('booking').subscribe({
            next: (res: any) => {
              this.filtersArray = res.choices;
              this.schoolTimings = res.choices.school_timings;
              this.subjectsAndTags = res.choices.tags;
              this.levelFilter = res.choices.experience_tree;
              this.consumerType = res.choices.consumer_type;
              this.school_staff = res.choices.school_staff;
              this.school_staffs = res.choices.school_staff;
              this.smart_group = res.choices.smart_group;
              this.bookingRoleType = res.choices.booking_role_type;
              this.custom_text = res.choices.certified_text;

              this.schoolFullTime = this.schoolTimings[0].start_time + ' - ' + this.schoolTimings[0].end_time
              this.schoolHalfTimeAm = this.schoolTimings[1].start_time + ' - ' + this.schoolTimings[1].end_time
              this.schoolHalfTimePm = this.schoolTimings[2].start_time + ' - ' + this.schoolTimings[2].end_time

              let newArr: any[] = []
              this.consumerType.forEach((type: any) => {
                type.sub_type.forEach((subtype: any) => {
                  newArr.push({ id: subtype.id, title: subtype.title, type: type.title });
                });
              });
              this.consumerRoleType = newArr
              if (this.bookingFilterForm.controls['no_of_staff'].value != 1) {
                this.bookingRoleType.forEach((roleType: any) => {
                  if (roleType.id == this.bookingFilterForm.controls['booking_role_type'].value) {
                    this.selectedRole = roleType.title + "s"
                  }
                });
              } else {
                this.bookingRoleType.forEach((roleType: any) => {
                  if (roleType.id == this.bookingFilterForm.controls['booking_role_type'].value) {
                    this.selectedRole = roleType.title
                  }
                });
              }
              var setBookingRole = this.bookingFilterForm.controls['booking_role_type'].value;
              this.bookingFilterForm.patchValue({
                filter_consumer_type: [setBookingRole],
              });
              if (this.filtersArray.teachercovered_required == true || this.filtersArray.classbeingcovered_required == true) {
                this.makeMadantory = true
              } else {
                this.makeMadantory = false
              }
              this.bookingFilterForm.patchValue({
                booking_smartgroup: this.data.smart_group.smart_group_id,
                booking_classcovered: this.data.information.class_being_covered
              });
              if (this.data.information.provider_being_covered != '') {
                this.bookingFilterForm.patchValue({
                  booking_teachercovered: this.data.information.provider_being_covered
                });
              } else {
                this.bookingFilterForm.patchValue({
                  booking_teachercovered: null
                });
              }

              this.bookingService.getTeachersList(this.getFormControlValues()).subscribe({
                next: (res: any) => {
                  this.teacherLengthLimit = res.data.total_count;
                  this.obsArray.next(res.data.data);
                  this.teacherData = res.data.data
                  this.backUpList = []
                  if (this.data.smart_group.smart_group_id != '') {
                    this.teacherData.forEach((teachers: any) => {
                      if (teachers.smart_group_type.is_backup_list == true) {
                        this.backUpList.push(teachers.teacher)
                      }
                    });
                    this.backUpList = [...this.backUpList];
                  } else {
                    this.bookingFilterForm.patchValue({
                      send_backup_list: false
                    });
                  }


                  if (this.editOnClone == true) {
                    this.teacherLengthLimit = res.data.total_count;
                    this.obsArray.next(res.data.data);
                    this.teacherData = res.data.data
                    this.bookingInFlightData.forEach((teachers: any) => {
                      this.pushInviteList(teachers.teacher)
                      // this.inviteList.push(teachers.teacher)
                    });
                    // this.inviteList = [...this.inviteList];
                    let b1 = res.data.data;
                    let b2 = this.inviteList;
                    var sdsdsd = b1.filter((item1: any) =>
                      !b2.some((item2: any) => {
                        if (item2.id == item1.teacher.id) {
                          item1.teacher.hideme = true;
                        }
                      }
                      ));
                  }
                  if (this.addMoreBooking == true) {
                    this.teacherLengthLimit = res.data.total_count;
                    this.obsArray.next(res.data.data);
                    this.teacherData = res.data.data
                    let b1 = res.data.data;
                    let b2 = this.bookingInFlightData;
                    var sdsdsd = b1.filter((item1: any) =>
                      !b2.some((item2: any) => {
                        if (item2.teacher.id == item1.teacher.id) {
                          item1.teacher.hideme = true;
                          item1.teacher.hidemeNone = true;
                        }
                      }
                      ));
                  }
                  this.ngxService.stop();
                },
                error: (err: any) => {
                  console.log(err);
                  this.ngxService.stop();
                }
              })
              // this.ngxService.stop();
            },
            error: (err: any) => {
              this.ngxService.stop();
              console.log(err);
            }
          });
          if (this.is_clone_reopen == undefined) {
            this.addMoreBooking = true;
            this.bookingFilterForm.patchValue({
              update_type: 'add_more_booking',
              flexi_booking: this.data.flexi_booking
            });
          } else if (this.is_clone_reopen == 'clone') {
            this.editOnClone = true
            this.bookingFilterForm.patchValue({
              update_type: 'clone_booking',
              flexi_booking: this.data.flexi_booking
            });

          } else if (this.is_clone_reopen == 'reopen') {
            this.bookingFilterForm.patchValue({
              update_type: 'reopen_booking',
              flexi_booking: this.data.flexi_booking
            });
            this.addMoreBooking = true
          } else if (this.is_clone_reopen == 'rebook') {
            this.bookingFilterForm.patchValue({
              update_type: 'rebook_booking',
              flexi_booking: this.data.flexi_booking,
              lineitem: this.lineId,
              rebook_duplicate: true
            });
            this.editOnClone = true
            this.rebookBookings = true
          }
          this.status = false;

          if (this.data.time_range == 'Full Day') {
            this.data.time_range = 'full_day'
          } else if (this.data.time_range == 'Half Day AM') {
            this.data.time_range = 'first_half'
          } else if (this.data.time_range == 'Half Day PM') {
            this.data.time_range = 'second_half'
          } else if (this.data.time_range == 'special') {
            this.data.time_range = 'special'
          }

          this.bsRangeValue = [new Date(this.data.start_date), new Date(this.data.end_date)];

          let rangeOne = this.bsRangeValue[0];
          let rangeTwo = this.bsRangeValue[1];
          let startDate = new DatePipe('en-US').transform(rangeOne, 'dd/MM/yyyy')
          let endDate = new DatePipe('en-US').transform(rangeTwo, 'dd/MM/yyyy')
          let start_time_split = this.data.booking_start_time.split(':')
          let end_time_split = this.data.booking_end_time.split(':')
          let start_hrs = start_time_split[0]
          let start_min = start_time_split[1]
          let end_hrs = end_time_split[0]
          let end_min = end_time_split[1]
          let bookingRange = this.bookingFilterForm.controls['timerange'].value;
          let startHour = bookingRange == 'full_day' ? '' + start_hrs + '' : bookingRange == 'first_half' ? '' + start_hrs + '' : bookingRange == 'second_half' ? '' + start_hrs + '' : '' + start_hrs + '';
          let startMin = bookingRange == 'full_day' ? '' + start_min + '' : bookingRange == 'first_half' ? '' + start_min + '' : bookingRange == 'second_half' ? '' + start_min + '' : '' + start_min + '';
          let endHour = bookingRange == 'full_day' ? '' + end_hrs + '' : bookingRange == 'first_half' ? '' + end_hrs + '' : bookingRange == 'second_half' ? '' + end_hrs + '' : '' + end_hrs + '';
          let endMin = bookingRange == 'full_day' ? '' + end_min + '' : bookingRange == 'first_half' ? '' + end_min + '' : bookingRange == 'second_half' ? '' + start_hrs + '' : '' + end_min + '';
          let booking_dates = rangeOne.getTime() < rangeTwo.getTime() ? startDate + ' - ' + endDate : startDate;
          // let scheduled_time = this.data.scheduled_time.scheduled_time.split(':')
          // let start_scheduled_time = scheduled_time[0]
          // let end_scheduled_time = scheduled_time[1]

          this.bookingFilterForm.patchValue({
            start_hrs: startHour,
            start_min: startMin,
            end_hrs: endHour,
            end_min: endMin,
            booking_dates: booking_dates,
            timerange: this.data.time_range,
            no_of_staff: this.data.teacher_count,
            booking_notes: this.data.information.notes,
            booking_classcovered: this.data.information.class_being_covered,
            booking_absencereason: this.data.information.reason_for_absence,
            booking_attachement_link: this.data.information.details.attachment_link,
            booking_payrollfield1: this.data.information.payroll_field1,
            booking_payrollfield2: this.data.information.payroll_field2,
            booking_payrollfield3: this.data.information.payroll_field3,
            booking_leaveapproved: this.data.information.leave_approved.toString(),
            send_emergency_list: this.data.send_emergency_list,
            send_backup_list: this.data.send_backup_list,
            filter_tags: this.data.meta.system_tags,
            backup_list_sent: this.data.backup_list_sent,
            update_timetolive: this.data.meta.update_timetolive.toString(),
            emergency_list_sent: this.data.emergency_list_sent,
            emergency_timetolive: this.data.meta.emergency_timetolive.toString(),
            filter_experience_items: this.data.meta.levels,
            time_to_live: this.data.time_to_live.toString(),
            booking_role_type: this.data.booking_role_type.id,
            filter_consumer_type: this.data.booking_role_type.id
            // schedule_booking: this.data.is_scheduled,
            // schudule_time_hours: start_scheduled_time,
            // schudule_time_mins: end_scheduled_time,
            // schedule_day: this.data.meta.levels,
          });

          if (this.data.send_backup_list == true) {
            this.showbackUpList = true
            this.showBackupTime = true
            if (this.data.backup_list_sent == true) {
              this.disableBackup = true
            }
          }
          if (this.data.send_emergency_list == true) {
            // this.disableEmergencyList=false
            this.showEmergencyTime = true
            if (this.data.emergency_list_sent == true) {
              this.disableEmergencyList = true
            }
          }
          if (this.data.information.provider_being_covered != '') {
            this.bookingFilterForm.patchValue({
              booking_teachercovered: this.data.information.provider_being_covered + '|' + this.data.information.staff_being_covered_id,
            });
          }


        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      })

      //End of booking request
      //End of In-flight page booking data API
      // End of add more Teachers
    } else {
      //redirecting to getFilterInputs() function from the response of this.getFiltersData()
      if (this.is_clone_reopen == 'smart-group') {
        this.getFiltersData();
      } else {
        this.getFiltersData();
        // Booking request
        this.bookingService.getBookingList(this.getFormControlValues()).subscribe({
          next: (res: any) => {
            if (res.status) {
              this.teacherLengthLimit = res.data.total_count;
              this.obsArray.next(res.data.data);
              this.teacherData = res.data.data
              this.ngxService.stop();
            } else if (res.status == false) {
              this.toastr.error(res.message)
              this.teacherLengthLimit = res.data.total_count;
              this.obsArray.next(res.data.data);
              this.teacherData = res.data.data
              this.ngxService.stop();
            } else {
              this.toastr.error(res.message)
              this.teacherLengthLimit = res.data.total_count;
              this.obsArray.next(res.data.data);
              this.teacherData = res.data.data
              this.ngxService.stop();
            }
          },
          error: (err: any) => {
            this.ngxService.stop();
            // this.toastr.error(err.message)
            console.log(err);
          }
        })
        //End of booking request
      }

    }
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }
  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }
  pushInviteList(teacherData: any) {
    let delay = this.bookingFilterForm.controls['delay_from_previous'].value;
    let key = 'minutes'
    if (teacherData.hasOwnProperty(key)) {
    } else {
      Object.assign(teacherData, { minutes: delay });
    }
    this.inviteList.push(teacherData);
    this.inviteList = [...this.inviteList];
  }

  getListHealth() {
    // this.bookingService.listHealth(this.currentPage).subscribe({
    //   next: (res: any) => {
    //     console.log("hellooooo", res.data);
    //     this.listHealth = res.data
    //   },
    //   error: (err: any) => {
    //     console.log(err);
    //   }
    // })
  }

  getInviteRequestData() {
    this.bookingService.getInviteRequest().subscribe({
      next: (res: any) => {
        this.InviteRequestData = res.data

        this.pendingInvites = res.data.pending_invite
        this.pendingRequest = res.data.pending_request
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  changeDelayInput(index: number, event: any) {
    this.inviteList[index].minutes = event.target.value;
  }
  clear(any: any) {

  }
  getSpecialValue() {
    let timing = this.bookingFilterForm.controls['timerange'].value;
    if (timing == 'special') {
      this.hidetimeRange = true
    } else {
      this.hidetimeRange = false
    }
  }
  // Get filters data
  getFilterInputs(filterType: any) {
    if (this.bsRangeValue == undefined) {
      this.toastr.error("Please select a valid date")
      this.ngxService.stop();
      return
    }
    this.currentPage = 1;
    this.ngxService.start();
    // Get filters data API
    let smart = this.bookingFilterForm.controls['booking_smartgroup'].value;
    // cheking smart group filter applied
    if (smart != '') {
      this.bookingService.getTeachersList(this.getFormControlValues()).subscribe({
        next: (res: any) => {
          // comparing two array (filtered group staff array and default staff array) 
          this.teacherLengthLimit = res.data.total_count;
          this.obsArray.next(res.data.data);
          this.teacherData = res.data.data
          if (filterType == 'smartFilter' || (smart != '' && this.inviteList.length == 0)) {
            let arrObj = Array();
            this.school_staffs.map((e: any, i: any) => {
              e.smart_group.map((f: any, j: any) => {
                if (f == smart) {
                  arrObj.push(e);
                }
              })
            })
            // pushing filterd staff to make staff
            this.school_staff = [];
            arrObj.forEach((element) => {
              this.school_staff.push(element);
            });
            this.smart_group.forEach((groupName: any) => {
              if (groupName.value == smart) {
                this.bookingFilterForm.patchValue({
                  booking_classcovered: groupName.title
                });
              }
            });
            if (this.selectedGroup != smart) {
              if (this.school_staff.length > 0) {
                this.bookingFilterForm.patchValue({
                  booking_teachercovered: this.school_staff[0].data,
                  booking_teachercovered_id: this.school_staff[0].value
                });
              } else {
                this.bookingFilterForm.patchValue({
                  booking_teachercovered: null
                });
              }
            }

            this.inviteList = []
            this.backUpList = []
            this.teacherData.forEach((teachers: any) => {
              if (teachers.smart_group_type.is_backup_list == true) {
                this.backUpList.push(teachers.teacher)
              }
              if (teachers.smart_group_type.is_preferred_list == true) {
                this.pushInviteList(teachers.teacher)
                // this.inviteList.push(teachers.teacher)
              }
            });
            this.selectedGroup = smart
            // this.inviteList = [...this.inviteList];
            this.backUpList = [...this.backUpList];
          }
          this.backUpList = []
          this.teacherData.forEach((teachers: any) => {
            if (teachers.smart_group_type.is_backup_list == true) {
              this.backUpList.push(teachers.teacher)
            }
          });
          this.backUpList = [...this.backUpList];
          let b1 = res.data.data;
          let b2 = this.inviteList;
          var sdsdsd = b1.filter((item1: any) =>
            !b2.some((item2: any) => {
              if (item2.id == item1.teacher.id) {
                item1.teacher.hideme = true;
              }
            }
            ));
          if (this.addMoreBooking == true) {
            let b1 = this.teacherData;
            let b2 = this.bookingInFlightData;
            var sdsdsd = b1.filter((item1: any) =>
              !b2.some((item2: any) => {
                if (item2.teacher.id == item1.teacher.id) {
                  item1.teacher.hideme = true;
                  item1.teacher.hidemeNone = true;
                }
              }
              ));
          }
          // Get filters data API
          this.ngxService.stop();
        },
        error: (err: any) => {
          this.ngxService.stop();
          console.log(err);
        }
      });
    } else {
      if (filterType == 'setBookingRole') {
        var setBookingRole = this.bookingFilterForm.controls['booking_role_type'].value;
        this.bookingFilterForm.patchValue({
          filter_consumer_type: [setBookingRole],
        });
      }
      if (smart == '' && filterType == 'smartFilter') {
        this.bookingFilterForm.patchValue({
          send_backup_list: false,
          booking_classcovered: '',
          booking_teachercovered: null,
        });
      }
      this.school_staff = this.school_staffs
      this.backUpList = []
      // let teacher_cover_val = this.bookingFilterForm.controls['booking_teachercovered'].value;
      // if (teacher_cover_val == null) {
      //   this.bookingFilterForm.patchValue({
      //     booking_teachercovered: null,
      //   });
      // }
      // this.bookingFilterForm.patchValue({
      //   send_backup_list: false,
      //   booking_classcovered: '',
      //   booking_teachercovered: null,
      // });

      this.bookingService.getTeachersList(this.getFormControlValues()).subscribe({
        next: (res: any) => {
          this.teacherLengthLimit = res.data.total_count;
          this.obsArray.next(res.data.data);
          this.teacherData = res.data.data
          let b1 = res.data.data;
          let b2 = this.inviteList;
          var sdsdsd = b1.filter((item1: any) =>
            !b2.some((item2: any) => {
              if (item2.id == item1.teacher.id) {
                item1.teacher.hideme = true;
              }
            }
            ));
          if (this.addMoreBooking == true) {
            let b1 = this.teacherData;
            let b2 = this.bookingInFlightData;
            var sdsdsd = b1.filter((item1: any) =>
              !b2.some((item2: any) => {
                if (item2.teacher.id == item1.teacher.id) {
                  item1.teacher.hideme = true;
                  item1.teacher.hidemeNone = true;
                }
              }
              ));
          }
          this.ngxService.stop();
        },
        error: (err: any) => {
          console.log(err);
          this.ngxService.stop();
        }
      });
    }

    // End of get filters data API
  }
  // End of get filters data

  getTeacherAvailabilityModal(teacher_id: any) {
    this.ngxService.start();
    this.availTeacher = teacher_id
    this.bookingService.getAvailability(this.availTeacher).subscribe({
      next: (res: any) => {
        if (res.status) {
          const initialState = {
            teacher: res.data
          };
          this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.closeBtnName = 'Close';
          // this.ngxService.stop();
        } else {
          this.toastr.error(res.message);
        }

      },
      error: (err: any) => {
        this.ngxService.stop();
        // this.toastr.error(err.statusText + ', Please contact admin');
        console.log(err);
      }
    });
    this.ngxService.stop();
  }
  // set checkbox selected teachers
  setCheckbox(event: any, index: number) {
    if (!this.checkBoxSelectedTeacher.includes(event)) {
      this.checkBoxSelectedTeacher = [...this.checkBoxSelectedTeacher, event];
    } else {
      this.checkBoxSelectedTeacher = this.checkBoxSelectedTeacher.filter((item: any) => item !== event);
    }
  }

  // Set invite list from selected checkbox
  inviteSelectedCheck(type: any) {
    if (this.booking_id != undefined) {
      if (type == 'selected') {
        if (this.checkBoxSelectedTeacher.length > 0) {
          this.checkBoxSelectedTeacher.forEach((teachers: any) => {
            this.pushInviteList(teachers.teacher)
            // this.inviteList.push(teachers.teacher)
            teachers.teacher.hideme = !teachers.teacher.hideme;
          });
          this.checkboxes.forEach((element) => {
            element.nativeElement.checked = false;
          });

          // this.inviteList = [...this.inviteList];
          this.checkBoxSelectedTeacher = []
        }
      } else if (type == 'all') {

        let newArr: any[] = []
        let teacherDataArr: any[] = []
        let DataArr: any[] = []

        this.teacherData.forEach((teachers: any) => {
          teacherDataArr.push(teachers.teacher.id)
        })

        this.bookingInFlightData.forEach((teachers: any) => {
          DataArr.push(teachers.teacher.id)
        })

        // var sdsdsd = teacherDataArr.filter((item1: any) =>
        //   DataArr.some((item2: any) => {
        //     if(
        //       item2.id !== item1.id
        //     ){
        //       return item1;
        //     }
        //   }
        //   ));
        newArr = teacherDataArr.filter((id: any) => !DataArr.includes(id));

        this.teacherData.forEach((teachers: any) => {
          newArr.forEach((data: any) => {
            if (data == teachers.teacher.id) {
              if (!this.checkDuplicate(teachers.teacher.id)) {
                this.pushInviteList(teachers.teacher)
                // this.inviteList.push(teachers.teacher)
                teachers.teacher.hideme = !teachers.teacher.hideme;
              }
            }
          })
        })
      }
      // this.inviteList = [...this.inviteList];
    } else {
      if (type == 'selected') {
        if (this.checkBoxSelectedTeacher.length > 0) {
          this.checkBoxSelectedTeacher.forEach((teachers: any) => {
            this.pushInviteList(teachers.teacher)
            // this.inviteList.push(teachers.teacher)
            teachers.teacher.hideme = !teachers.teacher.hideme;
          });
          this.checkboxes.forEach((element) => {
            element.nativeElement.checked = false;
          });

          // this.inviteList = [...this.inviteList];
          this.checkBoxSelectedTeacher = []
        }
      } else if (type == 'all') {
        this.teacherData.forEach((teachers: any) => {
          if (!this.checkDuplicate(teachers.teacher.id)) {
            this.pushInviteList(teachers.teacher)
            // this.inviteList.push(teachers.teacher)
            teachers.teacher.hideme = !teachers.teacher.hideme;
          }
        })
        // this.inviteList = [...this.inviteList];
      }
    }
  }

  // Get Filter Data
  getFiltersData() {
    // this.ngxService.start();
    this.schoolProps = JSON.parse(localStorage.getItem("userData") || '{}');
    this.commonService.schoolChoices('booking').subscribe({
      next: (res: any) => {
        this.filtersArray = res.choices;
        this.schoolTimings = res.choices.school_timings;
        this.subjectsAndTags = res.choices.tags;
        this.levelFilter = res.choices.experience_tree;
        this.school_staff = res.choices.school_staff;
        this.school_staffs = res.choices.school_staff;
        this.smart_group = res.choices.smart_group;
        this.bookingRoleType = res.choices.booking_role_type;
        this.requestDefaultType = res.choices.request_type_default_select;
        this.consumerType = res.choices.consumer_type;
        this.custom_text = res.choices.certified_text;

        this.schoolFullTime = this.schoolTimings[0].start_time + ' - ' + this.schoolTimings[0].end_time
        this.schoolHalfTimeAm = this.schoolTimings[1].start_time + ' - ' + this.schoolTimings[1].end_time
        this.schoolHalfTimePm = this.schoolTimings[2].start_time + ' - ' + this.schoolTimings[2].end_time

        if (this.bookingFilterForm.controls['no_of_staff'].value != 1) {
          this.bookingRoleType.forEach((roleType: any) => {
            if (roleType.id == this.bookingFilterForm.controls['booking_role_type'].value) {
              this.selectedRole = roleType.title + "s"
            }
          });
        } else {
          this.bookingRoleType.forEach((roleType: any) => {
            if (roleType.id == this.bookingFilterForm.controls['booking_role_type'].value) {
              this.selectedRole = roleType.title
            }
          });
        }

        let newArr: any[] = []
        this.consumerType.forEach((type: any) => {
          type.sub_type.forEach((subtype: any) => {
            newArr.push({ id: subtype.id, title: subtype.title, type: type.title });
          });
        });
        this.consumerRoleType = newArr

        if (this.is_clone_reopen == 'smart-group') {
          this.smart_group.forEach((groupName: any) => {
            if (groupName.value == this.smartID) {
              this.bookingFilterForm.patchValue({
                booking_classcovered: groupName.title,
                booking_smartgroup: groupName.value
              });
            }
          });
          this.getFilterInputs('smartFilter')
        }
        if (this.filtersArray.teachercovered_required == true || this.filtersArray.classbeingcovered_required == true) {
          this.makeMadantory = true
        } else {
          this.makeMadantory = false
        }

        if (this.requestDefaultType == 'ALL_AT_ONCE') {
          this.bookingFilterForm.patchValue({
            strategy: 'ALL_AT_ONCE',
          });
          this.delayBetReq = true
        } else if (this.requestDefaultType == 'ONE_AT_A_TIME') {
          this.bookingFilterForm.patchValue({
            strategy: 'ONE_AT_A_TIME',
          });
          this.delayBetReq = false
        }
        // this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    });
  }


  // For Invitelist
  showHiddenDrawer(): void {
    if (this.addMoreBooking == true) {
      this.status = !this.status;
    } else if (this.inviteList.length > 0) {
      this.status = !this.status;
    }
  }

  showAdditionalInformation(): void {
    this.showInformation = !this.showInformation;
  }

  onFileSelected(event: any) {
    if (event.target.files.length > 0) {
      this.fileName = !this.fileName;
      this.fileName = event.target.files[0].name

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        //me.modelvalue = reader.result;
      };
    }
  }
  removeFile(event: any) {
    event.preventDefault()
    this.bookingFilterForm.patchValue({
      booking_attachment: ''
    })
    this.fileName = false
    return
  }
  // Scroll to getData
  scroll = (event: any): void => {
    this.getTeacherLength();
    let totalPages = Math.ceil(this.teacherLengthLimit / this.totalArrayCount);
    if (window.innerHeight + window.scrollY + parseInt('10') >= document.body.scrollHeight && totalPages > 1) {
      this.ngxService.start();
      let activePage = this.Paginator(this.totalArrayCount, this.currentPage, 30);
      this.currentPage = activePage + 1;
      if (this.bookingFilterForm.controls['page'].value != this.currentPage) {
        forkJoin([this.teachersList.pipe(take(1)), this.bookingService.getTeachersList(this.getFormControlValues())]).subscribe((data: any) => {
          this.teacherLengthLimit = data[1].data.total_count;
          const newArr = [...data[0], ...data[1].data.data];
          this.obsArray.next(newArr);
          this.teacherData = newArr
          let b1 = this.teacherData;
          let b2 = this.inviteList
          var sdsdsd = b1.filter((item1: any) =>
            !b2.some((item2: any) => {
              if (item2.id == item1.teacher.id) {
                item1.teacher.hideme = true;
              }
            }
            ));
          if (this.addMoreBooking == true) {
            let b1 = this.teacherData;
            let b2 = this.bookingInFlightData;
            var sdsdsd = b1.filter((item1: any) =>
              !b2.some((item2: any) => {
                if (item2.teacher.id == item1.teacher.id) {
                  item1.teacher.hideme = true;
                  item1.teacher.hidemeNone = true;
                }
              }
              ));
          }
          this.ngxService.stop();
        });
      }
    }
  };

  getTeacherLength() {
    this.teachersList.subscribe(result => {
      this.totalArrayCount = result.length;
    });
  }

  Paginator(totlallength: any, page: any, per_page: any) {

    var page = page || 1,
      per_page = per_page || 10,
      offset = (page - 1) * per_page,

      // paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(totlallength / per_page);
    // return {
    // page: page,
    // per_page: per_page,
    // pre_page: page - 1 ? page - 1 : null,
    // next_page: (total_pages > page) ? page + 1 : null,
    // total: items.length,
    // total_pages: total_pages,
    // data: paginatedItems
    // };
    return total_pages;
  }

  addToInviteList(arrayList: any) {
    this.addedTeacherList = arrayList;
    console.log("ad", this.addedTeacherList);
    arrayList.hideme = !arrayList.hideme;
    if (!this.checkDuplicate(arrayList.id))
      this.pushInviteList(this.addedTeacherList)
    // this.inviteList.push(this.addedTeacherList);
    // this.inviteList = [...this.inviteList];
  }

  checkDuplicate(id: any) {
    return this.inviteList.some(function (el) {
      return el.id === id;
    });
  }

  removeTeacher(arrayList: any) {
    for (var i in this.teacherData) {
      if (this.teacherData[i].teacher.id == arrayList.id) {
        this.teacherData[i].teacher.hideme = false;
        delete this.teacherData[i].teacher.minutes;
        break; //Stop this loop, we found it!
      }
    }
    var index = this.inviteList.map(x => {
      return x.id;
    }).indexOf(arrayList.id);
    if (this.addMoreBooking == false) {
      if (this.inviteList.length == 1) {
        this.status = false;
      }
    }
    this.inviteList.splice(index, 1);
    this.inviteList = [...this.inviteList]
  }

  showtags() {
    this.tagsAndSubjects = !this.tagsAndSubjects;
  }
  showfilters() {
    this.showFilters = !this.showFilters;
  }
  showDelayTime() {
    this.showDelay = !this.showDelay;
    let schedule_booking = this.bookingFilterForm.controls['schedule_booking'].value;
    this.bookingFilterForm.patchValue({
      schedule_booking: schedule_booking
    });
  }


  getFormControlValues() {
    if (this.bsRangeValue == undefined) {
      this.ngxService.stop();
      this.toastr.error("Please select a valid date")
      return
    }
    //  Changed date format of bookingRange
    let rangeOne = this.bsRangeValue[0];
    let rangeTwo = this.bsRangeValue[1];
    let startDate = new DatePipe('en-US').transform(rangeOne, 'dd/MM/yyyy')
    let endDate = new DatePipe('en-US').transform(rangeTwo, 'dd/MM/yyyy')


    //  Changed start and endtime base on booking range
    let bookingRange = this.bookingFilterForm.controls['timerange'].value;
    if (this.bookingFilterForm.controls['timerange'].value != 'special') {
      let startHour = bookingRange == 'full_day' ? '08' : bookingRange == 'first_half' ? '08' : bookingRange == 'second_half' ? '11' : '08';
      let startMin = bookingRange == 'full_day' ? '30' : bookingRange == 'first_half' ? '45' : bookingRange == 'second_half' ? '50' : '00';

      let endHour = bookingRange == 'full_day' ? '15' : bookingRange == 'first_half' ? '11' : bookingRange == 'second_half' ? '15' : '15';
      let endMin = bookingRange == 'full_day' ? '30' : bookingRange == 'first_half' ? '49' : bookingRange == 'second_half' ? '30' : '30';
      let booking_dates = rangeOne.getTime() < rangeTwo.getTime() ? startDate + ' - ' + endDate : startDate;
      this.schoolProps
      // Checbox Patches

      this.bookingFilterForm.patchValue({
        booking_dates: booking_dates,
        page: this.currentPage,
        start_hrs: startHour,
        start_min: startMin,
        end_hrs: endHour,
        end_min: endMin,
        start_time: startHour + ':' + startMin,
        end_time: endHour + ':' + endMin,
        cleaner_booking: this.schoolProps.properties.is_cleaner_school,
      });
      if (this.editOnClone == true) {
        this.bookingFilterForm.patchValue({
          uuid: '',
        });
      } else {
        this.bookingFilterForm.patchValue({
          uuid: this.booking_id,
        });
      }
    } else {
      let startHour = this.bookingFilterForm.controls['start_hrs'].value;
      let startMin = this.bookingFilterForm.controls['start_min'].value;

      let endHour = this.bookingFilterForm.controls['end_hrs'].value;
      let endMin = this.bookingFilterForm.controls['end_min'].value;

      let StartTime = startHour + ':' + startMin;
      let EndTime = endHour + ':' + endMin;

      let booking_dates = rangeOne.getTime() < rangeTwo.getTime() ? startDate + ' - ' + endDate : startDate;
      this.schoolProps
      // Checbox Patches
      this.bookingFilterForm.patchValue({
        booking_dates: booking_dates,
        page: this.currentPage,
        start_time: startHour + ':' + startMin,
        end_time: endHour + ':' + endMin,
        cleaner_booking: this.schoolProps.properties.is_cleaner_school
      });
      if (this.editOnClone == true) {
        this.bookingFilterForm.patchValue({
          uuid: '',
        });
      } else {
        this.bookingFilterForm.patchValue({
          uuid: this.booking_id,
        });
      }
    }
    return this.bookingFilterForm.value;

  }
  getFormControlCustomValues() {
    if (this.bsRangeValue == undefined) {
      this.ngxService.stop();
      this.toastr.error("Please select a valid date")
      return
    }
    let rangeOne = this.bsRangeValue[0];
    let rangeTwo = this.bsRangeValue[1];

    let startDate = new DatePipe('en-US').transform(rangeOne, 'dd/MM/yyyy')
    let endDate = new DatePipe('en-US').transform(rangeTwo, 'dd/MM/yyyy')
    //  Changed start and endtime base on booking range
    let bookingRange = this.bookingFilterForm.controls['timerange'].value;
    let booking_dates = rangeOne.getTime() < rangeTwo.getTime() ? startDate + ' - ' + endDate : startDate;
    this.schoolProps
    // Checbox Patches

    this.bookingAvailableForm.patchValue({
      booking_dates: booking_dates,
      time_range: bookingRange
    });
    return this.bookingAvailableForm.value;
  }



  testPopup() {
    const initialState = {
      title: 'Confirm booking details',
      contents: 'Are you sure you want to cancel this booking request?',
      confirmAction: 'confirm',
      saveBtnLable: 'Confirm',
      showForm: false,
      inputLabel: ''
    };

    this.bsModalRef = this.modalService.show(ConfirmationModalComponent, { initialState });
    this.bsModalRef.content.confirmationDetails.subscribe((result: any) => {
      if (result) {
        // Object.keys(this.inviteList).map(function(k: any){
        //   console.log(k)
        // }).join(",");        
      }
    });
  }
  checkFlexyFilled(type: any) {
    if (this.bookingFilterForm.controls['flexi_booking'].value == '') {
      this.toastr.warning("Please select one option to continue");
      return;
    }
    if (this.bookingFilterForm.controls['flexi_booking'].value == '0') {
      this.status = false
      this.bsModalRef.hide();
      this.submitted = true;
      if (this.bookingFilterForm.invalid) {
        window.scroll(0, 0);
        return;
      }
    } else {
      this.bsModalRef.hide();
      this.checkBookings(type)
    }
  }
  changeMandatory(make: any) {
    if (make == 'mandaotry') {
      this.filtersArray.classbeingcovered_required = true;
      this.filtersArray.teachercovered_required = true;
      this.submitted = true;
    } else if (make == 'not_mandaotry') {
      this.filtersArray.classbeingcovered_required = false;
      this.filtersArray.teachercovered_required = false;
    }
  }
  checkMan() {
    if (this.submitted && this.makeManTrue) {
      if ((this.bookingFilterForm.controls['booking_teachercovered'].value == null || this.bookingFilterForm.controls['booking_teachercovered'].value == '') && (this.bookingFilterForm.controls['booking_classcovered'].value == '' || this.bookingFilterForm.controls['booking_classcovered'].value == null)) {
        this.filtersArray.classbeingcovered_required = true;
        this.filtersArray.teachercovered_required = true;
      } else {
        this.filtersArray.classbeingcovered_required = false;
        this.filtersArray.teachercovered_required = false;
      }
    }
  }


  CreateNew(type: any) {
    this.ngxService.stop()
    if (((this.filtersArray.absencereason_required == true && this.bookingFilterForm.controls['booking_absencereason'].value == '') || (this.filtersArray.teachercovered_required == true && this.bookingFilterForm.controls['booking_teachercovered'].value == null) || (this.filtersArray.classbeingcovered_required == true && this.bookingFilterForm.controls['booking_classcovered'].value == '')) && !this.makeManTrue) {
      this.submitted = true;
      if (this.bookingFilterForm.invalid) {
        window.scroll(0, 0)
        if (this.showInviteList == true) {
          this.status = false
        }
        return;
      }
    }
    if (((this.filtersArray.teachercovered_required == true && this.bookingFilterForm.controls['booking_teachercovered'].value == null) || (this.filtersArray.classbeingcovered_required == true && this.bookingFilterForm.controls['booking_classcovered'].value == '')) && this.makeManTrue) {
      this.bsModalRef = this.modalService.show(this.flexiBookingModal, { class: 'modal-md', backdrop: 'static' });
      return;
    }
    if ((this.filtersArray.teachercovered_required == false && this.bookingFilterForm.controls['booking_teachercovered'].value == null) && (this.filtersArray.classbeingcovered_required == false && this.bookingFilterForm.controls['booking_classcovered'].value == '')) {
      this.submitted = true;
      if (this.bookingFilterForm.invalid) {
        this.makeManTrue = true
        this.bsModalRef = this.modalService.show(this.flexiBookingModal, { class: 'modal-md', backdrop: 'static' });
        if (this.showInviteList == true) {
          window.scroll(0, 0)
          this.status = false
        }
        return;
      }
    } else {
      this.ngxService.stop()
      // this.bsModalRef.hide();
      this.checkBookings(type)
    }
  }

  checkAvail(type: any) {
    if (this.bsRangeValue == undefined) {
      this.ngxService.stop();
      this.toastr.error("Please select a valid date")
      return
    }
    this.ngxService.start()
    this.bookingAvailableForm.patchValue({
      consumer_id: Array.prototype.map.call(this.inviteList, function (item) { return item.id; }).join(",")
    });
    this.bookingService.checkAvalability(this.getFormControlCustomValues()).subscribe({
      next: (res: any) => {
        if (res.status) {
          if (res.teacher_id == '') {
            this.showInviteListMsg = false
            this.inviteList.forEach((data: any) => {
              data.unAvailable = false
            });
            this.ngxService.stop()
            this.CreateNew(type)
          } else {
            this.inviteList.forEach((data: any) => {
              res.teacher_id.forEach((id: any) => {
                if (id == data.id) {
                  data.unAvailable = true
                  if (this.showInviteList == true) {
                    this.status = true
                  }
                  this.toastr.error("Invite list contains unavailable teachers. Remove them and continue", '', { timeOut: 5000 })
                  this.showInviteListMsg = true
                }
              });
            });
            this.ngxService.stop()
            return;
          }
        } else {
          this.ngxService.stop()
          this.toastr.error(res.message);
        }
      },
      error: (err: any) => {
        // this.toastr.error(err.error.message);
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  bookingsApi() {
    this.bsModalRef.content.confirmationDetails.subscribe((result: any) => {
      if (result) {

        let newArr: any[] = []
        this.inviteList.forEach((list: any) => {
          newArr.push({ teacher_id: list.id, minutes: list.minutes })
        })
        this.bookingFilterForm.patchValue({
          custom_delay_previous: newArr
        });
        this.bookingFilterForm.addControl('consumer_id', new FormControl(Array.prototype.map.call(this.inviteList, function (item) { return item.id; }).join(",")));
        this.ngxService.start();
        this.bookingService.sendBookingRequest(this.getFormControlValues()).subscribe({
          next: (res: any) => {
            if (res.status == false) {
              this.toastr.error(res.message);
              this.ngxService.stop();
            } else {
              this.booking_id = res.booking_id
              this.toastr.success(res.message);
              let requestType = this.bookingFilterForm.controls['strategy'].value;
              if (requestType == "ONE_AT_A_TIME") {
                requestType = 'One at a time'
              } else {
                requestType = 'All at once'
              }
              let delay_from_previous = this.bookingFilterForm.controls['delay_from_previous'].value;
              let closeBooking = this.bookingFilterForm.controls['time_to_live'].value;
              let timeToLive = (closeBooking / 60) + ' minutes';
              let bookingRange = this.bookingFilterForm.controls['timerange'].value;
              let send_backup_list = this.bookingFilterForm.controls['send_backup_list'].value;
              if (this.is_clone_reopen == 'rebook') {
                var reBook = true
              } else {
                var reBook = false
              }
              this.mixpanelService.bookingRequestSent(this.inviteList.length, requestType, delay_from_previous, timeToLive, bookingRange, send_backup_list, reBook);

              this._router.navigate(['/school/bookings-in-flight', this.booking_id]);
            }
          },
          error: (err: any) => {
            console.log(err)
            this.toastr.error(err.error.message);
            this.ngxService.stop();
          }
        })
      }
    });
  }
  checkBookings(type: any) {
    let rangeOne = this.bsRangeValue[0];
    let rangeTwo = this.bsRangeValue[1];
    let startDate = new DatePipe('en-US').transform(rangeOne, 'dd/MM/yyyy')
    let endDate = new DatePipe('en-US').transform(rangeTwo, 'dd/MM/yyyy')
    let booking_dates = rangeOne.getTime() < rangeTwo.getTime() ? startDate + ' - ' + endDate : startDate;
    let bookingRange = this.bookingFilterForm.controls['timerange'].value;
    if (bookingRange != 'special') {
      this.startTime = bookingRange == 'full_day' ? this.schoolFullTime : bookingRange == 'first_half' ? this.schoolHalfTimeAm : bookingRange == 'second_half' ? this.schoolHalfTimePm : '08:00';
    } else {
      let startHour = this.bookingFilterForm.controls['start_hrs'].value;
      let startMin = this.bookingFilterForm.controls['start_min'].value;
      let endHour = this.bookingFilterForm.controls['end_hrs'].value;
      let endMin = this.bookingFilterForm.controls['end_min'].value;
      this.startTime = startHour + ":" + startMin + ' - ' + endHour + ":" + endMin
      // this.endTime = 
    }

    let noOfStaffs = this.bookingFilterForm.controls['no_of_staff'].value;
    let requestType = this.bookingFilterForm.controls['strategy'].value;
    let closeBooking = this.bookingFilterForm.controls['time_to_live'].value;
    let teacher_covered = this.bookingFilterForm.controls['booking_teachercovered'].value;
    this.school_staff.forEach((id: any) => {
      if (id.data == teacher_covered) {
        this.bookingFilterForm.patchValue({
          booking_teachercovered_id: id.value
        });
      }
    });
    let date = new Date();
    let todayDate = new DatePipe('en-US').transform(date, 'dd/MM/yyyy')

    // var HrsOrMins = ''
    // if (closeBooking >= 3600) {
    //   var HrsOrMins = 'hours'
    // } else if (closeBooking < 3600) {
    //   var HrsOrMins = 'minutes'
    // }
    if (requestType == "ONE_AT_A_TIME") {
      requestType = 'One at a time'
    } else {
      requestType = 'All at once'
    }
    if (type == 'regular') {
      if (todayDate == startDate) {
        const initialState = {
          title: 'Just checking...',
          contents: 'You are about to book someone for ' + booking_dates + ' and we just wanted to ensure you had the correct date as that’s today?',
          confirmAction: 'confirm',
          saveBtnLable: 'Continue',
          showForm: false,
          inputLabel: ''
        };
        this.bsModalRef = this.modalService.show(ConfirmationModalComponent, { initialState, backdrop: 'static' });
        this.bsModalRef.content.confirmationDetails.subscribe((result: any) => {
          if (result) {
            const initialState = {
              title: 'Confirm booking details',
              contents: 'contacting ' + noOfStaffs + ' teacher(s) to cover ' + booking_dates + ' ' + this.startTime,
              body: 'Teachers will be contacted ' + requestType + ' during the booking period of ' + (closeBooking / 60) + ' minutes',
              subContent: 'Your short list contains ' + this.inviteList.length + ' teachers',
              confirmAction: 'confirm',
              saveBtnLable: 'Confirm and request',
              showForm: false,
              inputLabel: ''
            };
            this.bsModalRef = this.modalService.show(ConfirmationModalComponent, { initialState, backdrop: 'static' });
            this.bookingsApi()
          }
        });
      } else {
        const initialState = {
          title: 'Confirm booking details',
          contents: 'contacting ' + noOfStaffs + ' teacher(s) to cover ' + booking_dates + ' ' + this.startTime,
          body: 'Teachers will be contacted ' + requestType + ' during the booking period of ' + (closeBooking / 60) + ' minutes',
          subContent: 'Your short list contains ' + this.inviteList.length + ' teachers',
          confirmAction: 'confirm',
          saveBtnLable: 'Confirm and request',
          showForm: false,
          inputLabel: ''
        };
        this.bsModalRef = this.modalService.show(ConfirmationModalComponent, { initialState, backdrop: 'static' });
        this.bookingsApi()
      }
    } else if (type == 'update') {
      const initialState = {
        title: 'Confirm booking details',
        contents: 'contacting ' + noOfStaffs + ' teacher(s) to cover ' + booking_dates + ' ' + this.startTime,
        body: 'Teachers will be contacted ' + requestType + ' during the booking period of ' + (closeBooking / 60) + ' minutes',
        subContent: 'Your short list contains ' + this.inviteList.length + ' teachers',
        confirmAction: 'confirm',
        saveBtnLable: 'Confirm and request',
        showForm: false,
        inputLabel: ''
      };
      let newArr: any[] = []
      this.inviteList.forEach((list: any) => {
        newArr.push({ teacher_id: list.id, minutes: list.minutes })
      })
      this.bookingFilterForm.patchValue({
        custom_delay_previous: newArr
      });
      if (this.inviteList.length > 0) {
        this.bsModalRef = this.modalService.show(ConfirmationModalComponent, { initialState, backdrop: 'static' });
        this.bsModalRef.content.confirmationDetails.subscribe((result: any) => {
          if (result) {
            this.bookingFilterForm.addControl('consumer_id', new FormControl(Array.prototype.map.call(this.inviteList, function (item) { return item.id; }).join(",")));
            this.bookingFilterForm.addControl('booking_uuid', new FormControl(this.booking_id));
            this.ngxService.start();
            this.bookingService.updateBookingRequest(this.getFormControlValues()).subscribe({
              next: (res: any) => {
                if (res.status) {
                  this.booking_id = res.booking_id
                  this.toastr.success(res.message);
                  let requestType = this.bookingFilterForm.controls['strategy'].value;
                  if (requestType == "ONE_AT_A_TIME") {
                    requestType = 'One at a time'
                  } else {
                    requestType = 'All at once'
                  }
                  let delay_from_previous = this.bookingFilterForm.controls['delay_from_previous'].value;
                  let closeBooking = this.bookingFilterForm.controls['time_to_live'].value;
                  let bookingRange = this.bookingFilterForm.controls['timerange'].value;
                  let timeToLive = (closeBooking / 60) + ' minutes';
                  let send_backup_list = this.bookingFilterForm.controls['send_backup_list'].value;
                  if (this.is_clone_reopen == 'reopen') {
                    var reBook = true
                  } else {
                    var reBook = false
                  }
                  this.mixpanelService.bookingRequestSent(this.inviteList.length, requestType, delay_from_previous, timeToLive, bookingRange, send_backup_list, reBook);

                  this._router.navigate(['/school/bookings-in-flight', this.booking_id]);
                } else {
                  this.toastr.error(res.message);
                  this.ngxService.stop();
                }
              },
              error: (err: any) => {
                this.toastr.error(err.error.message + ', Please contact admin');
                console.log(err);
                this.ngxService.stop();
              }
            })
          }
        });
      } else {
        this.bookingFilterForm.addControl('consumer_id', new FormControl(Array.prototype.map.call(this.inviteList, function (item) { return item.id; }).join(",")));
        this.bookingFilterForm.addControl('booking_uuid', new FormControl(this.booking_id));
        this.ngxService.start();
        this.bookingService.updateBookingRequest(this.getFormControlValues()).subscribe({
          next: (res: any) => {
            if (res.status) {
              this.booking_id = res.booking_id
              this.toastr.success(res.message);
              this._router.navigate(['/school/bookings-in-flight', this.booking_id]);
            } else {
              this.toastr.error(res.message);
              this.ngxService.stop();
            }
          },
          error: (err: any) => {
            this.toastr.error(err.error.message + ', Please contact admin');
            console.log(err);
            this.ngxService.stop();
          }
        })
      }
    }
  }
  teacherProfile(teacher: any) {
    const initialState = {
      teacher: teacher
    };
    this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl', backdrop: 'static' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  resetFilters() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }

  patchRequestDetails(type: any) {
    let strategy = this.bookingFilterForm.controls['strategy'].value;
    let delay_from_previous = this.bookingFilterForm.controls['delay_from_previous'].value;
    let time_to_live = this.bookingFilterForm.controls['time_to_live'].value;
    let update_timetolive = this.bookingFilterForm.controls['update_timetolive'].value;
    let emergency_timetolive = this.bookingFilterForm.controls['emergency_timetolive'].value;
    let schudule_time_hours = this.bookingFilterForm.controls['schudule_time_hours'].value;
    let schudule_time_mins = this.bookingFilterForm.controls['schudule_time_mins'].value;
    let schedule_day = this.bookingFilterForm.controls['schedule_day'].value;

    if (strategy == 'ALL_AT_ONCE') {
      this.delayBetReq = true
    } else {
      this.delayBetReq = false
    }
    if (this.addMoreBooking == true) {
      if (time_to_live != this.data.time_to_live.toString()) {
        this.showUpdatebtn = true
      }
      else if (update_timetolive != this.data.meta.update_timetolive.toString()) {
        this.showUpdatebtn = true
      }
      else if (emergency_timetolive != this.data.meta.update_timetolive.toString()) {
        this.showUpdatebtn = true
      }
      else {
        this.showUpdatebtn = false
      }
    }
    if (type == 'customDelay') {
      this.inviteList.forEach((teacher: any) => {
        teacher.minutes = delay_from_previous
      })
    }
    this.bookingFilterForm.patchValue({
      strategy: strategy,
      delay_from_previous: delay_from_previous,
      time_to_live: time_to_live,
      update_timetolive: update_timetolive,
      emergency_timetolive: emergency_timetolive,
      schudule_time_hours: schudule_time_hours,
      schudule_time_mins: schudule_time_mins,
      schedule_day: schedule_day
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.bookingFilterForm.controls;
  }
  get getFormControl() {
    return this.bookingFilterForm.controls;
  }

  addEditNotes(teacherName: any, type: any, notes: any, id: any) {
    const initialState = {
      teacherName: teacherName,
      type: type,
      notes: notes,
      id: id,
      from_page: 'booking_request',
      teachers_list: this.teacherData

    };
    this.bsModalRef = this.modalService.show(AddNotesComponent, { initialState, class: 'modal-md', backdrop: 'static' });
  }
  clearallInvites(teacher: any) {
    teacher.forEach((teacher: any) => {
      this.teacherData.forEach((data: any) => {
        if (data.teacher.id == teacher.id) {
          data.teacher.hideme = false;
          delete data.teacher.minutes;
        }
      })
    })
    this.status = false;
    this.inviteList = [];
  }
  showBackupLiveTime() {
    this.showBackupTime = !this.showBackupTime

    if (this.addMoreBooking == true) {
      this.showUpdatebtn = true;
    }
  }
  showEmergencyLiveTime() {
    this.showEmergencyTime = !this.showEmergencyTime

    if (this.addMoreBooking == true) {
      this.showUpdatebtn = true;
    }
  }
  closeModal() {
    this.bsModalRef.hide()
    this.submitted = false;
    this.filtersArray.classbeingcovered_required = false;
    this.filtersArray.teachercovered_required = false;
    // this.bookingFilterForm.controls['flexi_booking'].value == '0'
    this.bookingFilterForm.patchValue({
      flexi_booking: ''
    })
  }
  closeModalEmergency() {
    this.showEmergencyTime = false;
    if (this.addMoreBooking == false) {
      this.bookingFilterForm.patchValue({
        send_emergency_list: false,
        send_to_everyone: false,
      });
      this.send_is_readonly = false
      this.sub_is_readonly = false
      this.level_is_readonly = false
      this.bsModalRef.hide()
    } else {
      this.bookingFilterForm.patchValue({
        send_emergency_list: false,
      });
      this.bsModalRef.hide()
    }
  }
  patchLevelSubjectValue() {
    this.bookingFilterForm.patchValue({
      system_tags: this.bookingFilterForm.controls['system_tags'].value,
      booking_levels: this.bookingFilterForm.controls['booking_levels'].value,
    });
    let send_to_everyone = this.bookingFilterForm.controls['send_to_everyone'].value;
    let booking_levels = this.bookingFilterForm.controls['booking_levels'].value;
    let system_tags = this.bookingFilterForm.controls['system_tags'].value;
    if (booking_levels == '' && system_tags == '' && send_to_everyone == false) {
      this.toastr.warning("Please select anyone to continue");
      return;
    }
    this.showUpdatebtn = true;
    this.bsModalRef.hide()
  }
  openEmergencyModal($event: any) {
    if (this.addMoreBooking == false && this.editOnClone == false) {
      if ($event.target.checked) {
        let filterTagValue = this.bookingFilterForm.controls['filter_tags'].value;
        let filterExperieceItem = this.bookingFilterForm.controls['filter_experience_items'].value;

        this.bookingFilterForm.patchValue({
          system_tags: filterTagValue,
          booking_levels: filterExperieceItem,
        });
        let booking_levels = this.bookingFilterForm.controls['booking_levels'].value;
        let system_tags = this.bookingFilterForm.controls['system_tags'].value;
        if (system_tags != '' || booking_levels != '') {
          this.send_is_readonly = true
        } else {
          this.send_is_readonly = false
        }

        this.bsModalRef = this.modalService.show(this.emergencyBookingModal, { class: 'modal-md', backdrop: 'static' });
      } else if ($event.target.checked == false) {
        this.bookingFilterForm.patchValue({
          send_emergency_list: false,
          send_to_everyone: false
        });
        this.send_is_readonly = false
        this.sub_is_readonly = false
        this.level_is_readonly = false
        return;
      }
    } else {
      if ($event.target.checked) {
        let filterTagValue = this.bookingFilterForm.controls['filter_tags'].value;
        let filterExperieceItem = this.bookingFilterForm.controls['filter_experience_items'].value;

        this.bookingFilterForm.patchValue({
          send_to_everyone: this.data.meta.send_to_everyone,
          booking_levels: filterExperieceItem,
          system_tags: filterTagValue,
        });
        if (!this.data.emergency_list_sent) {
          if (filterTagValue != '' || filterExperieceItem != '') {
            this.send_is_readonly = true
          } else {
            this.send_is_readonly = false
          }

          if (this.data.meta.send_to_everyone == true) {
            this.sub_is_readonly = true
            this.level_is_readonly = true
          } else {
            this.sub_is_readonly = false
            this.level_is_readonly = false
          }
        } else if (this.data.emergency_list_sent) {
          this.sub_is_readonly = true
          this.level_is_readonly = true
          this.send_is_readonly = true
        }
        this.bsModalRef = this.modalService.show(this.emergencyBookingModal, { class: 'modal-md', backdrop: 'static' });
      } else {
        this.bookingFilterForm.patchValue({
          send_emergency_list: false
        });
        this.showUpdatebtn = true;
      }
    }

  }
  disableEveryone() {
    let system_tags = this.bookingFilterForm.controls['system_tags'].value;
    let booking_levels = this.bookingFilterForm.controls['booking_levels'].value;
    let send_to_everyone = this.bookingFilterForm.controls['send_to_everyone'].value;

    if (system_tags != '' || booking_levels != '') {
      this.send_is_readonly = true
    } else {
      this.send_is_readonly = false
    }

    if (send_to_everyone == true) {
      this.sub_is_readonly = true
      this.level_is_readonly = true
    } else {
      this.sub_is_readonly = false
      this.level_is_readonly = false
    }
  }

  openAdminSettings() {
    this.modalService.hide()
    this._router.navigate(['/school/edit-admin-settings/payroll-report-settings']);
  }

  setSelectFilter(filter: any) {
    this.removedIsChecked();
    if (this.selectedFilter === filter) {
      this.selectedFilter = '';
      return;
    }
    this.selectedFilter = filter;
    switch (filter) {
      case 1: {
        this.selectAllFilter();
        break;
      }
      case 2: {
        this.selectActiveFilter();
        break;
      }
      case 3: {
        this.selectInActiveFilter();
        break;
      }
      case 4: {
        this.selectApprovedFilter();
        break;
      }
      default: {
        this.selectNotApprovedFilter();
        break;
      }
    }
  }

  selectAllFilter() {
    this.teacherData.forEach((teacher: any) => {
      if (!teacher.teacher.hideme) {
        teacher.isChecked = true;
        this.checkBoxSelectedTeacher.push(teacher);
      }
    });
  }

  selectActiveFilter() {
    this.teacherData.forEach((teacher: any) => {
      if (teacher?.teacher?.is_active && !teacher.teacher.hideme) {
        teacher.isChecked = true;
        this.checkBoxSelectedTeacher.push(teacher);
      }
    });
  }

  selectInActiveFilter() {
    this.teacherData.forEach((teacher: any) => {
      if (!teacher?.teacher?.is_active && !teacher.teacher.hideme) {
        teacher.isChecked = true;
        this.checkBoxSelectedTeacher.push(teacher);
      }
    });
  }

  selectApprovedFilter() {
    this.teacherData.forEach((teacher: any) => {
      if (teacher?.teacher?.validated_dict?.is_validated && !teacher.teacher.hideme) {
        teacher.isChecked = true;
        this.checkBoxSelectedTeacher.push(teacher);
      }
    });
  }

  selectNotApprovedFilter() {
    this.teacherData.forEach((teacher: any) => {
      if (!teacher?.teacher?.validated_dict?.is_validated && !teacher.teacher.hideme) {
        teacher.isChecked = true;
        this.checkBoxSelectedTeacher.push(teacher);
      }
    });
  }

  removedIsChecked() {
    this.checkBoxSelectedTeacher = []
    this.teacherData.forEach((checkbox: any) => {
      checkbox.isChecked = false;
    });
  }

  async sortBy(type: any) {
    this.bookingFilterForm.patchValue({
      sort_by: type
    })
    this.getFilterInputs('regular')

    // if (type == 'name') {
    //   this.teacherData = this.teacherData.sort((a: any, b: any) => a.teacher.user.full_name > b.teacher.user.full_name ? 1 : -1);
    // } else if (type == 'lastSeen') {
    //   this.teacherData = this.teacherData.sort((a: any, b: any) => {
    //     return <any>new Date(b.teacher.user.last_login_min) - <any>new Date(a.teacher.user.last_login_min);
    //   });
    // } else {
    //   this.teacherData = this.teacherData.sort((i: any, j: any) => {
    //     if (j.teacher.distance_from == "") {
    //       return -1;
    //     }
    //     if (i.teacher.distance_from == "") {
    //       return 1;
    //     }
    //     if (Number(j.teacher.distance_from.split(" ")[0]) > Number(i.teacher.distance_from.split(" ")[0])) {
    //       return -1;
    //     } else {
    //       return 1;
    //     }
    //   });
    // }
  }

  makePlural() {
    if (this.bookingFilterForm.controls['no_of_staff'].value != 1) {
      this.bookingRoleType.forEach((roleType: any) => {
        if (roleType.id == this.bookingFilterForm.controls['booking_role_type'].value) {
          this.selectedRole = roleType.title + "s"
        }
      });
    } else {
      this.bookingRoleType.forEach((roleType: any) => {
        if (roleType.id == this.bookingFilterForm.controls['booking_role_type'].value) {
          this.selectedRole = roleType.title
        }
      });
    }
  }
}


