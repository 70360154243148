import { Component, Input, OnInit } from '@angular/core';
import { BookingService } from 'src/app/services/api/booking.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss']
})
export class HealthCheckComponent implements OnInit {
  percent: any;
  outerColor: any;
  subtitleColo: any;
  listHealth: any;
  percentageColor: any;
  activeText: any;
  activeTextColor: any
  percentageIconColor: any
  constructor(private bookingService: BookingService,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.bookingService.listHealth('').subscribe({
      next: (res: any) => {
        this.listHealth = res.data
        if (this.listHealth.list_health > 30 && this.listHealth.list_health < 59) {
          this.outerColor = '#FE7301'
          this.activeTextColor = '#FE7301'
          this.percentageIconColor = '#FE7301'
          this.activeText = "Active"
        } else if (this.listHealth.list_health < 30) {
          this.outerColor = '#FE5353'
          this.activeTextColor = '#FE5353'
          this.percentageIconColor = '#FE7301'
          this.activeText = "Active"
        } else if (this.listHealth.list_health > 59) {
          this.outerColor = '#03935e'
          this.activeTextColor = '#03935e'
          this.percentageIconColor = '#03935e'
          this.activeText = "Active"
        }
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
    // this.subtitleColo = activeText
  }

}
