import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonService } from 'src/app/services/api/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-public-school',
    templateUrl: './public-school.component.html',
    styleUrls: ['./public-school.component.scss'],
    
    standalone: true
})
export class PublicSchoolComponent implements OnInit {

  schoolLogo: any;
  schoolName: any;
  profileData: any;
  mapsUrl: any;
  recentJobsList: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.route.params.subscribe(params => {
      this.schoolName = params['slugName'];
    });
    this.commonService.publicSchool(this.schoolName).subscribe({
      next: (res: any) => {
        // this.overview = res.data.overview.replace(/\n/g, '<br/>');
        this.profileData = res.data;
        this.recentJobsList = res?.data?.recent_job;
        let videoUrl = 'https://maps.google.com/maps?q=' + this.profileData.location.lat + ',' + this.profileData.location.lng + '&z=15&&output=embed'
        this.mapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl)
        this.schoolLogo = res.additional_info?.profile_image;
        console.log(res);
        this.ngxService.stop();
      },
      error: (err: any) => {
        // this.toastr.error(err.message)
        this.ngxService.stop();
        console.log(err);
      }
    })
  }
  openPublicJobs(id:any){
    window.open(environment.apiUrl+"jobs/view-job/"+id , "_blank");
  }

}
