<div class="pb-30 pt-15">
    <p class="page_title">List and Booking insights</p>

    <div class="row">
        <div class="col-lg-12 col-12 col-md-12">
            <p class="txt-regular fs-18 mt-10">View real time data and get insights on bookings and absence trends in your school, as well as the health of your list.</p>
        </div>
    </div>
    <div class="row mt-20">
        <div class="col-md-3 col-sm-3">
            <div class="form-floating mb-3">
                <input type="text" placeholder="Select date" class="form-control" bsDatepicker
                    [(bsValue)]="this.fromDate"
                    [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-red', showWeekNumbers:false,adaptivePosition: true}"
                    [daysDisabled]="[6,0]">
                <label for="floatingInput">From date</label>
            </div>

        </div>
        <div class="col-md-3 col-sm-3">
            <div class="form-floating mb-3">
                <input type="text" placeholder="Select date" class="form-control" bsDatepicker [(bsValue)]="this.toDate"
                    [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-red', showWeekNumbers:false,adaptivePosition: true}"
                    [daysDisabled]="[6,0]">
                <label for="floatingInput">To date</label>
            </div>

        </div>
        <div class="col-md-3 col-sm-3">
            <button (click)="getInsightsData()" class="btn  fs-13 cc_p_btn">Go</button>

        </div>
    </div>
    <div class="row mt-20">
        <div class="col-md-12 col-sm-12">
            <p class="user-title">Overview</p>
        </div>

        <div class="row mt-20 ">
            <div class="col-lg-12 ">
                <div class="card if_det">
                    <div class="row px-15">
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <p class="small">Bookings (previous period)</p>
                            <p class="text-regular-bold">{{data?.old_booking_count}}</p>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <p class="small">Bookings (CURRENT period)</p>
                            <p class="text-regular-bold">{{data?.new_booking_count}}</p>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <p class="small">Active teachers</p>
                            <p class="text-regular-bold">{{activeTeacherCount}}</p>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <p class="small">absences</p>
                            <p class="text-regular-bold">{{absent_teacher_count}}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row mt-20 ">
            <div class="col-lg-12 ">
                <p class="user-title">Booking for selected period</p>
            </div>
        </div>
        <div class="row mt-20 ">
            <div class="col-lg-12 ">
                <div class="card px-15">
                    <canvas baseChart [data]="lineChartData" [options]="lineChartDataOption" [type]="'line'">
                    </canvas>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-30">
        <div class="col-md-12 col-sm-12">
            <p class="user-title">Top teachers booked</p>
        </div>
        <div class="row mt-20">
            <div class="col-md-5 col-lg-5 col-sm-12">
                <div class="card">
                    <div class="d-flex headData px-15">
                        <div class="w-85">
                            <p class="text-s-m-g-3">Teachers</p>
                        </div>
                        <div class="w-15">
                            <p class="text-s-m-g-3">Total bookings</p>
                        </div>
                    </div>
                    <div class="d-flex desData px-10"  *ngFor="let data of topTeacher">
                        <div class="w-85">
                            <p class="txt-regular">{{data.name}}</p>
                        </div>
                        <div class="w-15">
                            <p class="txt-regular">{{data.count}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 col-lg-7 col-sm-12">
                <div class="card px-15">
                    <p class="user-title">Active vs inactive teachers</p>
                    <canvas baseChart class="chart" [options]="doughnutChartOption" [type]="'doughnut'" [data]="doughnutChartData">
                    </canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-30">
        <div class="col-md-12 col-sm-12">
            <p class="user-title">Top teachers absences</p>
        </div>
        <div class="row mt-20">
            <div class="col-md-5 col-lg-5 col-sm-12">
                <div class="card">
                    <div class="d-flex headData px-15">
                        <div class="w-85">
                            <p class="text-s-m-g-3">Teachers</p>
                        </div>
                        <div class="w-15">
                            <p class="text-s-m-g-3">Teachers absence</p>
                        </div>
                    </div>
                    <div class="d-flex desData px-10"   *ngFor="let data of topAbsences">
                        <div class="w-85">
                            <p class="txt-regular">{{data.name}}</p>
                        </div>
                        <div class="w-15">
                            <p class="txt-regular">{{data.count}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 col-lg-7 col-sm-12">
                <div class="card px-15">
                    <p class="user-title">Top absence reasons</p>
                    <canvas
                    baseChart
                    class="chart"
                    [data]="barChartData"
                    [type]='barChartType'
                    [options]="barChartOptions"
                  >
                  </canvas>
                </div>
            </div>
        </div>
    </div>
</div>