<div class="card px-15">
    <div class="row list-h">
        <p class="user-title">List Health</p>
        <p *ngIf="listHealth?.list_health < 30" class="need-work btn-cc-red"><i class="bi bi-exclamation-circle fs-13 mr-2"></i>
            Needs work
        </p>
        <p *ngIf="listHealth?.list_health > 59" class="need-work clr-green"><i class="bi bi-exclamation-circle fs-13 mr-2"></i>
            Healthy
        </p>
        <p *ngIf="listHealth?.list_health < 59 && listHealth?.list_health > 30" class="need-work clr-orange"><i class="bi bi-exclamation-circle fs-13 mr-2"></i>
            Ok
        </p>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-6 col-sm-6">
            <div class="list-health-graph">
            <!-- You can now use it in app.component.html -->
            <circle-progress
            [percent]="listHealth?.list_health"
            [radius]="70"
            [outerStrokeWidth]="16"
            [innerStrokeWidth]="16"
            [outerStrokeColor]="outerColor"
            [innerStrokeColor]="'#ECEFF2'"
            [animation]="true"
            [animationDuration]="3000"
            [space]="-15"
            [showUnits]="true"
            [titleFontSize]="'25'"
            [unitsFontSize]="'25'"
            [unitsColor]="percentageIconColor"
            [subtitle]="activeText"
            [subtitleFontSize]="'15'"
            [titleColor]="activeTextColor"
            [subtitleColor]="activeTextColor"
            ></circle-progress>
        </div>
        </div>
        <div class="col-lg-12 col-md-6 col-sm-6  txt-regular">
            <div class="row mt-20  text-center">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                    <p class="text-r-b-18">{{listHealth?.active_teacher_count}}</p>
                    <p>Active</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                    <p class="text-r-b-18">{{listHealth?.inactive_teacher_count}}</p>
                    <p>Inactive</p>
                </div>
            </div>
            <div class="row mt-15  text-center">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                    <p class="text-r-b-18">{{listHealth?.incomplete_teacher_count}}</p>
                    <p>Incomplete</p>
                    <p>Profiles</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                    <p class="text-r-b-18">{{listHealth?.total}}</p>
                    <p>Total</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-10">
        <div class="col-lg-12">
            <p class="text-center text-r-g-3"><b>{{listHealth?.inactive_teacher_count}} profiles</b> have been inactive for over 90 days</p>
        </div>
    </div>
    <!-- <div class="row mt-10">
        <div class="col-lg-12 text-center">
            <a class="c-p  text-regular-bold t-dec-none"><i
                    class="bi bi-hand-index-thumb fs-13 nudge-icon"></i> Nudge inactive</a>
        </div>
    </div> -->
</div>