<div class="mt-3">
    <h1>Customized Html Components</h1>
</div>
<br />
<!-- Card -->
<h5>Card</h5>
<div class="card">
    <div class="card-body">
            Test Card     
    </div>
</div>
<br />


<!-- Input Text -->
<h5>Input Text</h5>
<input type="text" class="form-control" id="exampleFormControlInput1" placeholder="staff being covered">
<br />
<!-- Select Option -->
<h5>Select Option</h5>
<select class="form-select" aria-label="Default select example">
    <option selected>Open this select menu</option>
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
</select>
<br />


<!-- Checkbox -->
<h5>Checkbox</h5>
<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
<br />
<br />
<h5>Checkbox with label</h5>
<div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1">
    <label class="form-check-label ml-4" for="flexCheckDefault1">
        COVID intensive learning support program
    </label>
</div>
<br /><br />


<!-- Badges -->
<h5>Badges</h5>
<span class="badge bg-light">Random</span>
<br /><br />


<!-- Badges -->
<h5>Hr/Line</h5>
<hr />
<br />


<!-- Switches/Toggles -->
<h5>Switches/Toggles</h5>
<label class="switch">
    <input type="checkbox">
    <span class="slider round"></span>
</label>
<br /><br />


<!-- Buttons -->
<h5>Buttons</h5>
<button type="button" class="btn cc_p_btn fs_13">Send Invite</button>
<br /><br />
<button type="button" class="btn cc_p_btn fs_13"><i class="bi bi-send-fill mr-4"></i>Send Invite</button>
<br /><br />


<!-- Display headings-->
<h5>Display Text</h5>
<h6>User Title</h6>
<h4 class="user-title">Apprentice Anna Mathew Dubbins</h4>
<br />
<h6>Title with underline</h6>
<h4 class="ul-title">Invite list</h4>
<br />
<h6>Regular Text</h6>
<h6 class="txt-regular">Early childhood, Primary, Secondary, KG3, K-2, Child protection, Eraly childhood</h6>
<br />
<h6>Paragraph</h6>
<p class="txt-para">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>