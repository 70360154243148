<div class="find-new-page pb-90 p-15 mt-10">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <a class="clr-cc-vilot user-title c-p" (click)="goBack()"><i class="bi bi-arrow-left-short"></i> Back</a>
        </div>
        <div class="d-flex justify-content-center mt-10">
            <div class="on_border com_1"></div>
            <div class="on_border com_2"></div>
            <div class="on_border com_3"></div>
        </div>
    </div>
   
    <p class="mt-15 text-regular-medium text-right">
        Invite your known relief pool contacts to join your list
        <button (click)="openAddnewModal()" class="btn cc_p_btn_outline fs_14 ml-10 pt-10 pb-10 pl-30 pr-30">
            Invite to join
        </button>
    </p>

    <p class="user-title mt-20">Based on your school's profile, here are some top suggestions to add to your list</p>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <form [formGroup]="findNewFilterForm">
                <div class="row align-items-center mt-10">
                    <div class="col-lg-4 col-md-4 mt-10">
                        <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                            [multiple]="false" id="state" (change)="getFilterInputs()"
                            aria-label="Default select example" formControlName="filter_state">
                            <ng-option *ngFor="let state of filtersArray.states" value="{{ state.value }}">{{
                                state.title }}</ng-option>
                        </ng-select>
                    </div>

                    <div class="col-lg-4 col-md-4 mt-10">
                        <ng-select [searchable]="false" [clearable]="false" [dropdownPosition]="'bottom'"
                            [multiple]="false" id="distance" (change)="getFilterInputs()"
                            aria-label="Default select example" formControlName="filter_distance">
                            <ng-option *ngFor="let distance of filtersArray.distance_choices"
                                value="{{ distance.value }}">
                                {{ distance.title }}
                            </ng-option>
                        </ng-select>
                    </div>

                    <div class="col-lg-4 col-md-4 mt-10" id="custom-find-new-ng">
                        <ng-select formControlName="filter_consumer_type" [multiple]="true" placeholder="Role type"
                            (change)="getFilterInputs()">
                            <ng-option *ngFor="let tag of consumerType" [value]="tag['id']"
                                [disabled]="tag['is_parent']">
                                {{ tag["title"] }}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>

            </form>
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="">
                        <div class="row mt-15 align-items-center " *ngIf="consumerList?.length > 0">
                            <div class="col-lg-6 align-items-center mt-10">
                                <div class="form-check">

                                    <input #checkAll class="form-check-input mt-4 mr-5" [(ngModel)]="selectAllCheck"
                                        (change)="setCheckbox($event, 'All')" id="checkAll" type="checkbox" />
                                    <label class="form-check-label" for="checkAll">
                                        <span class="h_b" *ngIf="!selectAllCheck">Select all</span>
                                        <span class="h_b" *ngIf="selectAllCheck">{{ consumerDetails.length }}
                                            Selected</span>
                                    </label>
                                </div>
                            </div>

                            <div class="col-lg-6 mt-10">
                                <div class="d-flex lign-items-center  text-regular-bold fs-15 justify-content-end "
                                    [ngClass]="hideButton ? 'input-cursor-na':''">
                                    <span  (click)="bulkInvite()"  class="clr-cc-vilot d-flex align-items-center c-p"> <i
                                          class="bi  bi-send-fill"></i>
                                        <span class="ml-3 fs-15 clr-cc-vilot"
                                            [ngClass]="!hideButton ? 'text-regular-bold':''"> Invite
                                            selected </span>
                                    </span>
                                    <span class="clr-cc-vilot d-flex align-items-center  ml-12 c-p"
                                        (click)="sendMail()">
                                        <i class="bi bi-chat-left fs-15"></i>
                                        <span [ngClass]="!hideButton ? 'text-regular-bold':''"
                                            class="ml-4 fs-15 clr-cc-vilot">Message
                                            selected</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="" *ngIf="FindNewteachersList | async as FindNewlist">
                        <div class="mt-15" *ngIf="consumerList?.length > 0">
                            <div class="card px-15 mt-15" *ngFor="let teacher of FindNewlist; index as i">
                                <div class="row mb-10">
                                    <div class="col-md-7 col-sm-12 col-lg-8">
                                        <div class="col-md-12 col-lg-12 d-flex">
                                            <div class="pic-and-check">
                                                <input (click)="setCheckbox($event, teacher)"
                                                    [checked]="teacher.isChecked" #checkboxes
                                                    class="form-check-input mr-10 ml-10" type="checkbox" />
                                            </div>
                                            <div class="pic-and-check ml-0">
                                                <img alt="img"
                                                    [src]="teacher.teacher.meta.profile_image != null? teacher.teacher.meta.profile_image: '../../../../assets/images/teacher-static-profile.png'"
                                                    class="profile-picture img-complete-match" />
                                            </div>
                                            <div class="pic-and-check ml-10">
                                                <p class="d-flex align-items-center">
                                                    <span class="user-title c-p">
                                                        {{ teacher.teacher.user.full_name }}
                                                    </span>
                                                    <span
                                                        *ngIf="teacher.teacher.is_student && teacher.teacher.student_tag != ''"
                                                        class="s_tag ml-5">{{
                                                        teacher.teacher.student_tag }}
                                                    </span>
                                                    <span *ngIf="teacher.teacher.user.new_teacher"
                                                        class="new_tag ml-5">New
                                                    </span>
                                                    <span>
                                                        <app-validated-dict
                                                            [validateDict]="teacher.teacher.validated_dict">
                                                        </app-validated-dict>
                                                    </span>
                                                </p>

                                                <p class="user-sub-title">
                                                    {{ teacher.teacher.meta.you_are_a
                                                    }}<span class="divider-right ml-3 mr-5"></span>Active {{
                                                    teacher.teacher.user.previous_login
                                                    }}<span class="divider-right ml-3 mr-5"></span>{{
                                                    teacher.teacher.distance_from }}
                                                </p>
                                                <p class="" *ngIf="teacher.teacher.meta.status">
                                                    <span class="clr-grey-3 user-sub-title">Status : </span><span
                                                        class="user-sub-title">{{teacher.teacher.meta.status}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row ml-26" *ngIf="teacher.teacher.meta.tags != ''">
                                            <div class="col-md-2 col-3 fit-content pr-0 mt-5">
                                                <p class="grey-3-14">Qualified in</p>
                                            </div>
                                            <div class="col-md-9 col-9">
                                                <p class="txt-regular tags"><span class="qualified-in mt-5"
                                                        *ngFor="let qualified of teacher.teacher.meta.tags">{{qualified}},
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-12 col-md-5  d-flex text-right justify-content-right">
                                        <button [ngClass]="this.consumerDetails.length > 0 ? 'input-cursor-na' : ''"
                                            class="btn cc_p_btn_outline fs_14 ml-10  pl-20 pr-20 c-p"
                                            (click)="sendMessage(teacher.teacher)">
                                            Message
                                        </button>
                                        <button *ngIf="teacher.is_invite_on == false"
                                            [ngClass]="this.consumerDetails.length > 0 ? 'input-cursor-na' : ''"
                                            class="btn cc_p_btn fs_14 ml-10  pl-20 pr-20 c-p"
                                            (click)="openSendInviteModal(teacher.teacher)">
                                            <i class="bi bi-send-fill"></i> Invite
                                        </button>

                                        <button *ngIf="teacher.is_invite_on == true"
                                            class="btn cc_p_s_btn_outline fs_14 ml-10 pt-5 pb-5  pl-20 pr-20 c-p clr-green"><i
                                                class="bi bi-check2"></i> Invite sent
                                            <!-- <span class="notesTooltiptext text-s-m-g-1">{{ teacher.invite_sent_date }}</span> -->
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-30" *ngIf="consumerList?.length == 0">
                            <p class=" text-center"><img class="no-invite-image width-40"
                                    src="assets/images/my_list_empty.png" alt="No matches found!" /></p>
                            <p class="title text-center">No results match your criteria
                            </p>
                            <p class="title text-center">Please try again or adjust the distance from school filter
                            </p>
                            <p class="text-center"><button class="btn cc_p_btn fs_13 mt-20 text-center"
                                    (click)="resetFilters();">Reset
                                    filters</button></p>
                        </div>
                    </div>
                    <div class="d-flex mt-20 justify-content-end">
                        <button class="btn cc_p_btn  btn_length pr-30 pl-30"  (click)="stepTwo()" >Next</button>
                      </div>
                    <pagination  *ngIf="consumerList?.length != 0"  [totalItems]="totalCount" class="pagination-sm mt-20 d-flex justify-content-center"
                    [boundaryLinks]="showBoundaryLinks" [rotate]="rotate" [maxSize]="maxSize" [(ngModel)]="currentPage"
                    (pageChanged)="pageChanged($event)"></pagination>

                   
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #sendInviteModal>
    <form [formGroup]="sendInviteForm">
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">
                Invite to join your list
            </h4>
            <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <p class="text-regular-medium">
                        Clicking “Confirm” assumes you have crossed checked with any
                        relevant registration body that they are qualified and approved to
                        work in your school.
                    </p>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-10">
            <button type="button" (click)="sendInvite(teacher , selectedType)" class="btn cc_p_btn fs_13">
                Confirm
            </button>
        </div>
    </form>
</ng-template>