export const environment = {
  production: false,
  siteUrl: '/',
  apiUrl:'https://phoenix.classcover.com.au/',
  client_id: 'lJYgHijgnFPO6QJhrGfBKnolygIxtezCA6YiuwAh',    
  client_secret: 'V0CSwpcOwk1EPn2NlBDo20XZXmmzf7gfYbf6igkSiPaMQAGqlqiCi6gCqZ8fYIPSTNBSIWYMfZBfgmF7pOJPcMKBFDVUxK1yPqZHPcND8YBBJOxo61xODcMedGnfLJ7h',
  INTERCOM_ID: 'dm13jjra',
  backToAdmin: 'https://phoenix.classcover.com.au/admin/',
  cookiesDomain: 'classcover.com.au',
};
