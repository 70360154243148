import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationModalComponent } from 'src/app/layout/confirmation-modal/confirmation-modal.component';
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-booking-confrim-modal',
  templateUrl: './booking-confrim-modal.component.html',
  styleUrls: ['./booking-confrim-modal.component.scss']
})
export class BookingConfrimModalComponent implements OnInit {
  title?: string;
  closeBtnName?: string;
  bookingDatas: any;
  showHiddenDiv: boolean = false;
  editBooking: boolean = false;
  currentPage: number = 1;
  edit: any
  filtersArray: any = []
  public editBookings: any;
  public cancelBookingForm: any
  cancelReasonModal?: BsModalRef;
  public submitted: boolean = false;
  ShowLenghtError: any = 200;
  ShowLenght: any;
  // @ViewChild('cancelReasonModal') cancelReasonModal: any;
  showEditHiddenDiv: boolean = false;



  constructor(public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private bookingService: BookingService,
    private _router: Router,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {   
    if(this.edit == 'edit'){
      if(this.bookingDatas.is_flexi_booking && this.bookingDatas.information.class_being_covered == '' && this.bookingDatas.information.provider_being_covered == ''){
        this.editBooking = true
      }else{
        this.editBooking = false
      }
    }
   
    this.getFiltersData();
    this.formInit();
    let timing = this.cancelBookingForm.controls['cancel_message'].value;  
    let charectorLength = this.ShowLenghtError - timing.length
    this.ShowLenght = charectorLength
  }
  formInit() {
    this.editBookings = this.fb.group({
      booking_teachercovered: [this.bookingDatas.information.provider_being_covered],
      booking_classcovered: [this.bookingDatas.information.class_being_covered],
      booking_leaveapproved: [this.bookingDatas.information.leave_approved],
      booking_notes: [this.bookingDatas.information.notes],
      booking_payrollfield1: [this.bookingDatas.information.payroll_field1],
      booking_payrollfield2: [this.bookingDatas.information.payroll_field2],
      booking_payrollfield3: [this.bookingDatas.information.payroll_field3],
      booking_absencereason: [this.bookingDatas.information.reason_for_absence]
    });

    this.cancelBookingForm = this.fb.group({
      cancel_message: ['' , [Validators.required, Validators.maxLength(280)]],
    });
  }

  showHiddenDivOnclick() {
    this.showHiddenDiv = !this.showHiddenDiv
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.cancelBookingForm.controls;
  }


  cancelReason(cancelReasonModal: TemplateRef<any>) {
    this.cancelReasonModal = this.modalService.show(cancelReasonModal, { class: 'modal-md', backdrop: 'static' });

    // this.bsModalRef = this.modalService.show(this.cancelReasonModal, { class: 'modal-md', backdrop: 'static' });
  }
  editBookingSec() {
      this.editBooking = !this.editBooking
  }
  closeEditBookingSec() {
    if(this.edit == 'edit'){
      this.bsModalRef.hide()
      this.editBookings.reset();
      this.formInit();
    }else{
      this.editBooking = !this.editBooking
      this.editBookings.reset();
      this.formInit();
    }
  
  }
  showHiddenEditDivOnclick() {
    this.showEditHiddenDiv = !this.showEditHiddenDiv
  }
  getFiltersData() {
    this.commonService.schoolChoices('booking').subscribe({
      next: (res: any) => {
        this.filtersArray = res.choices;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  cancelBooking() {
    this.submitted = true;
    if (this.cancelBookingForm.invalid) {
      return;
    }
    this.ngxService.start();
    this.cancelBookingForm.addControl('line_item', new FormControl(Array.prototype.map.call(this.bookingDatas, function (item) { return item.line_item_id; }).join(",")));
    this.bookingService.cancelBookingRequest(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        this.modalService.hide()
        this.toastr.success(res.message);  
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        this.toastr.error(err.message);  
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  saveEditBooking() {
    this.ngxService.start();
    this.editBookings.addControl('line_item', new FormControl(Array.prototype.map.call(this.bookingDatas, function (item) { return item.line_item_id; }).join(",")));
    this.bookingService.editBookingRequest(this.getEditFormControlValues()).subscribe({
      next: (res: any) => {
        console.log(res)
        this.modalService.hide()
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  getFormControlValues(): any {
    const line_item_id = this.bookingDatas.line_item_id
    this.cancelBookingForm.patchValue({
      page: this.currentPage,
      line_item: line_item_id
    });
    return this.cancelBookingForm.value;
  }
  getEditFormControlValues(): any {
    const line_item_id = this.bookingDatas.line_item_id
    this.editBookings.patchValue({
      page: this.currentPage,
      line_item: line_item_id
    });
    return this.editBookings.value;
  }

  closeModal() {
    this.cancelBookingForm.reset();
    this.formInit();
    this.cancelReasonModal?.hide() 
  }
  checkLength(){
    let timing = this.cancelBookingForm.controls['cancel_message'].value;  
    let charectorLength = this.ShowLenghtError - timing.length
    this.ShowLenght = charectorLength
  }
}
