import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, } from '@angular/common/http';
import { AppConfig } from '../app-config';

// models
import { SchoolData } from 'src/app/models/schoolUser';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public schoolProps!: SchoolData;

  constructor(
    private httpClient: HttpClient,
    private apiUrl: AppConfig
  ) { }

  public getUserData = (filterData: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.meCall + '?from=' + filterData);

  }

  public schoolChoices = (choices: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.myChoices + choices);
  }

  public registerChoices = (choices: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.registerChoices + choices);
  }

  public publicSchool = (schoolName: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.publicProfile + schoolName);
  }

  public checkboxPatches(value: any) {
    return value == true ? '1' : '0';
  }

  public getLocalStorageData(key: string) {
    this.schoolProps = JSON.parse(localStorage.getItem(key) || '{}');
    return this.schoolProps;
  }

  public recentJobs = (): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.recentJobs);
  }

  public schoolStaff = (): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.schoolStaff);
  }

  public updateSchoolStaff = (staffData: any, staffId: number): Observable<any> => {
    return this.httpClient.put<any>(this.apiUrl.schoolApiUrl.updateSchoolStaff + staffId + '/', staffData);
  }

  public deleteSchoolStaff = (staffIds: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.deleteSchoolStaff, staffIds);
  }

  public createSchoolStaff = (staffData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.updateSchoolStaff, staffData);
  }

  public makeRtaAdmin = (staffData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.manageTeamSettings, staffData);
  }

  public switchBackToPython = (): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.commonApi.switchBackToPython, { tryAngular: false });
  }

}
