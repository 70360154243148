import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-html-components',
  templateUrl: './html-components.component.html',
  styleUrls: ['./html-components.component.scss']
})
export class HtmlComponentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
