<!-- Hidden for demo -->
<div class="col-md-12 col-sm-12 col-lg-12  Fortnight" *ngIf="from == undefined">
    <div class="row align-items-center">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="d-flex jus_c_right gap-3 flex-wrap availability-legends">
                <div class="text-legend align-items-center d-flex">
                    <div class="user-sub-title available-color mr-5"></div>
                    <div class="txt-regular">Available</div>
                </div>
                <div class="text-legend align-items-center d-flex ml-5">
                    <div class="havent-indicated user-sub-title mr-5"></div>
                    <div class="txt-regular">Haven’t Indicated</div>
                </div>
                <div class="text-legend align-items-center d-flex ml-5">
                    <div class="booked user-sub-title mr-5"></div>
                    <div class="txt-regular">Booked</div>
                </div>
                <div class="text-legend align-items-center d-flex ml-5">
                    <div class="booked-elsewhere user-sub-title mr-5"></div>
                    <div class="txt-regular">Booked Elsewhere</div>
                </div>
                <div class="text-legend align-items-center d-flex ml-5">
                    <div class="unavailable user-sub-title mr-5"></div>
                    <div class="txt-regular">Unavailable</div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-10">
        <a class="c-p text-regular-bold float-left h_b t-dec-none h_b clr-cc-vilot" (click)="prevClick(teacherID);"><i
                class="bi fs-20 bi-arrow-left-short v-align-m"></i> Previous
            Fortnight</a>

        <a class="c-p text-regular-bold float-right h_b t-dec-none h_b clr-cc-vilot"
            (click)="nextClick(teacherID);">Next
            Fortnight <i class="bi bi-arrow-right-short fs-20 v-align-m"></i></a>
    </div>
    <!--End of Hidden for demo -->
<div class="call-outs mt-10">
    <span class="availability-grid" *ngFor="let availability of availability">
        <!-- <span>Jul</span> -->
        <div class="call-out {{availability.day}}" [ngClass]="availability.intervals.length > 1 ? 'half-day': ''">
            <span *ngIf="availability.intervals.length == 1">
                <div class="link user-{{availability.intervals[0].status}} tooltipss">
                    <span
                        *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'"
                        class="tooltiptext txt-regular clr-white">{{availability.tool_tip}}<br>{{availability.intervals[0].display_status}}</span>
                    <a
                        *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">
                        {{availability.date}}
                    </a>
                    <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                        <i class="bi bi-calendar-x sat-sun-icon"></i>
                    </a>
                </div>
                <!-- <div *ngIf="availability.intervals[0].is_booked == false"
                            class="link user-{{availability.intervals[0].status}}">
                            <a
                                *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">
                                {{availability.date}}
                            </a>
                            <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                                <i class="bi bi-calendar-x sat-sun-icon"></i>
                            </a>
                        </div> -->
            </span>

            <span *ngIf="availability.intervals.length > 1">
                <div class="user-{{availability.intervals[0].status}} tooltipsss"
                    [ngClass]="availability.intervals.length[0] ? 'link-interval': 'link-interval'">
                    <span
                        class="tooltiptext txt-regular clr-white">{{availability.tool_tip}}<br>{{availability.intervals[0].display_status}}</span>
                    <a
                        *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">
                        {{availability.date}}
                    </a>
                    <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                        <i class="bi bi-calendar-x sat-sun-icon"></i>
                    </a>
                </div>
                <!-- <div *ngIf="availability.intervals[0].is_booked == false"
                            class="user-{{availability.intervals[0].status}}"
                            [ngClass]="availability.intervals.length[0] ? 'link-interval': 'link-interval'">
                            <a
                                *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">
                                {{availability.date}}
                            </a>
                            <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                                <i class="bi bi-calendar-x sat-sun-icon"></i>
                            </a>
                        </div> -->
                <div class="link-intervals user-{{availability.intervals[1].status}} tooltipssss">
                    <span
                        class="tooltiptext txt-regular clr-white">{{availability.tool_tip}}<br>{{availability.intervals[1].display_status}}</span>
                </div>
                <!-- <div *ngIf="availability.intervals[1].is_booked == false"
                            class="link-intervals user-{{availability.intervals[1].status}}">
                        </div> -->
            </span>

        </div>
        <!-- <span>{{availability.day}}</span> -->
    </span>
    <!-- <div class="call-out half-days">
            <div class="link-interval user-unavailable">
                <a>1</a>
            </div>
            <div class="link-intervals user-available">
            </div>  -->
</div>

</div>

<!--End of Hidden for demo -->
<div class="call-outs mt-10" *ngIf="from == 'FNT'">
    <span class="availability-grid" *ngFor="let availability of availability">
        <!-- <span>Jul</span> -->
        <div class="call-out {{availability.day}}" [ngClass]="availability.intervals.length > 1 ? 'half-day': ''">
            <span *ngIf="availability.intervals.length == 1">
                <div class="link user-{{availability.intervals[0].status}} tooltipss">
                    <span
                        *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'"
                        class="tooltiptext txt-regular clr-white">{{availability.tool_tip}}<br>{{availability.intervals[0].display_status}}</span>
                    <a
                        *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">
                        {{availability.date}}
                    </a>
                    <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                        <i class="bi bi-calendar-x sat-sun-icon"></i>
                    </a>
                </div>
                <!-- <div *ngIf="availability.intervals[0].is_booked == false"
                            class="link user-{{availability.intervals[0].status}}">
                            <a
                                *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">
                                {{availability.date}}
                            </a>
                            <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                                <i class="bi bi-calendar-x sat-sun-icon"></i>
                            </a>
                        </div> -->
            </span>

            <span *ngIf="availability.intervals.length > 1">
                <div class="user-{{availability.intervals[0].status}} tooltipsss"
                    [ngClass]="availability.intervals.length[0] ? 'link-interval': 'link-interval'">
                    <span
                        class="tooltiptext txt-regular clr-white">{{availability.tool_tip}}<br>{{availability.intervals[0].display_status}}</span>
                    <a
                        *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">
                        {{availability.date}}
                    </a>
                    <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                        <i class="bi bi-calendar-x sat-sun-icon"></i>
                    </a>
                </div>
                <!-- <div *ngIf="availability.intervals[0].is_booked == false"
                            class="user-{{availability.intervals[0].status}}"
                            [ngClass]="availability.intervals.length[0] ? 'link-interval': 'link-interval'">
                            <a
                                *ngIf="availability.day == 'Mon' || availability.day == 'Tue' || availability.day == 'Wed' || availability.day == 'Thu' || availability.day == 'Fri'">
                                {{availability.date}}
                            </a>
                            <a *ngIf="availability.day == 'Sat' || availability.day == 'Sun'">
                                <i class="bi bi-calendar-x sat-sun-icon"></i>
                            </a>
                        </div> -->
                <div class="link-intervals user-{{availability.intervals[1].status}} tooltipssss">
                    <span
                        class="tooltiptext txt-regular clr-white">{{availability.tool_tip}}<br>{{availability.intervals[1].display_status}}</span>
                </div>
                <!-- <div *ngIf="availability.intervals[1].is_booked == false"
                            class="link-intervals user-{{availability.intervals[1].status}}">
                        </div> -->
            </span>

        </div>
        <!-- <span>{{availability.day}}</span> -->
    </span>
    <!-- <div class="call-out half-days">
            <div class="link-interval user-unavailable">
                <a>1</a>
            </div>
            <div class="link-intervals user-available">
            </div>  -->
</div>