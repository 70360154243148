import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../app-config';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/api/common.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import {MixpanelService} from 'src/app/mixpanel.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  is_job_only_user: any;
  redirectToPage: any;

  constructor(
    private http: HttpClient,
    private apiUrl: AppConfig,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private mixpanelService: MixpanelService,
    private _router: Router
    ) {
  }
  private loggedIn: boolean = false;

  login(loginDetails: any) {
    this.ngxService.start()
    return this.http.post<any>(this.apiUrl.createToken,{ 'client_id': loginDetails.client_id, 'client_secret': loginDetails.client_secret, 'grant_type': loginDetails.grant_type , 'username': loginDetails.username, 'password': loginDetails.password,'access_token':loginDetails.access_token }).pipe(map(
          userData => {
            var user = userData;
            console.log('Successfully logined data',  );
            this.loggedIn = true;
            document.cookie = "access_token=" + user.access_token + ";domain=."+environment.cookiesDomain+";path=/";
            document.cookie = "refresh_token=" + user.refresh_token + ";domain=."+environment.cookiesDomain+";path=/";
            return user;
          }
        )
      );
  }

  getToken(): string | null {
    return document.cookie.match('(^|;)\\s*' + 'access_token' + '\\s*=\\s*([^;]+)')?.pop() || '';
  }

  getRefreshToken(): string | null {
    return document.cookie.match('(^|;)\\s*' + 'refresh_token' + '\\s*=\\s*([^;]+)')?.pop() || '';
  }

  getUserData(): string | null {
    return localStorage.getItem('userData');
  }

  isLoggedIn() {
    return this.getToken() != '';
  }

  loggedOut() {
    localStorage.clear();
    sessionStorage.clear();
    this._router.ngOnDestroy();
    document.cookie = "refresh_token=; path=/; domain=."+environment.cookiesDomain+"; expires=" + new Date(0).toUTCString();
    document.cookie = "access_token=; path=/; domain=."+environment.cookiesDomain+"; expires=" + new Date(0).toUTCString();
    document.cookie = "userRole=; path=/; domain=."+environment.cookiesDomain+"; expires=" + new Date(0).toUTCString();
    document.cookie = "from=; path=/; domain=."+environment.cookiesDomain+"; expires=" + new Date(0).toUTCString();
    // let currentUrl = "/login";
    // this._router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
    //   this._router.navigate([currentUrl]);
    // });
          // this._router.navigateByUrl('/login');

    window.open('/login', '_self');
  }

  deleteCookie(cookiename: any)
    {
        var d = new Date();
        d.setDate(d.getDate() - 1);
        var expires = ";expires="+d;
        var name=cookiename;
        //alert(name);
        var value="";
        document.cookie = name + "=" + value + expires + "; path=/acc/html";                    
    }
  
  public getCredentials = (user_id: any): Observable<any> => {
    return this.http.get<any>(this.apiUrl.getUserCredentail+user_id);
  }
  

}
