<div class="pb-30 pt-15"
    [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'">
    <div class="row">
        <div class="col-lg-6 col-6 col-md-6 back-jobs d-flex">
            <p class="text-regular-bold clr-cc-vilot c-p" routerLink="/school/jobs" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"><i class="bi bi-arrow-left-short"></i> Back to jobs</p>
        </div>
    </div>

    <div class="row">
        <!-- Pending Request Tabs -->
        <div class="col-lg-8 col-12  mt-20">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link" [ngClass]="{ 'active':activeTab==='all'}" (click)="all('all')"
                        id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab"
                        aria-controls="nav-all" aria-selected="true">
                        All applicants
                    </button>
                    <button class="nav-link" [ngClass]="{ 'active':activeTab==='hired'}" (click)="hired('hired')"
                        id="nav-hired-tab" data-bs-toggle="tab" data-bs-target="#nav-hired" type="button" role="tab"
                        aria-controls="nav-hired" aria-selected="false">
                        Hired
                    </button>
                    <button class="nav-link" [ngClass]="{ 'active':activeTab==='shortlisted'}"
                        (click)="shortlisted('shortlisted')" id="nav-shortlisted-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-shortlisted" type="button" role="tab" aria-controls="nav-shortlisted"
                        aria-selected="false">
                        Shortlisted
                    </button>
                    <button class="nav-link" [ngClass]="{ 'active':activeTab==='rejected'}"
                        (click)="rejected('rejected')" id="nav-rejected-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-rejected" type="button" role="tab" aria-controls="nav-rejected"
                        aria-selected="false">
                        Rejected
                    </button>
                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <!-- Pending Tab Content -->
                <div class="tab-pane fade show" [ngClass]="{ 'active':activeTab==='all'}" id="nav-all" role="tabpanel"
                    aria-labelledby="nav-all-tab">
                    <div class="filters" *ngIf="showAllApplicantFilter">
                        <form [formGroup]="allAppJobDetailsFilterForm">
                            <div class="row mt-15">
                                <div class="col-lg-4 col-md-4 col-sm-6 mt-10">
                                    <ng-select [dropdownPosition]="'bottom'" formControlName="filter_consumer_type"
                                        (change)="getFilterInputs()" [multiple]="true" placeholder="Role type">
                                        <ng-option *ngFor="let tag of consumerType" [value]="tag['id']"
                                            [disabled]="tag['is_parent']">
                                            {{tag['title']}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6  mt-10">
                                    <ng-select [dropdownPosition]="'bottom'" formControlName="filter_levels"
                                        (change)="getFilterInputs()" [items]="levelFilter" [multiple]="true"
                                        bindLabel="title" bindValue="value" placeholder="Search by level">
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-4 mt-10">
                                    <ng-select [clearable]="false" [dropdownPosition]="'bottom'" [searchable]="false"
                                        [multiple]="false" formControlName="filter_distance"
                                        (change)="getFilterInputs()">
                                        <ng-option *ngFor="let distance of filtersArray.distance_choices"
                                            value="{{distance.value}}">
                                            {{distance.title}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-10">
                                    <div class="input-group">
                                        <input formControlName="filter_name" id="search-by-name" type="text"
                                            placeholder="Search by name" class="form-control"
                                            aria-label="Search by name">
                                        <button class="input-group-text" (click)="getFilterInputs()"
                                            for="search-by-name"><i class="bi bi-search"></i></button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-10">
                                    <ng-select [items]="subjectsAndTags" (change)="getFilterInputs()"
                                        formControlName="filter_subjects" bindLabel="title" bindValue="data" groupBy="tags"
                                        [multiple]="true" placeholder="Search by subjects and tags">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.title}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 mt-10">
                                    <div class="form-check">
                                        <input class="form-check-input c-p mt-4" (change)="getFilterInputs()"
                                            type="checkbox" id="is-validated_2" formControlName="filter_is_validated">
                                        <label class="form-check-label c-p" for="is-validated_2">
                                            Approved to teach only
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="allApplicant?.length > 0">
                        <div class="row mt-20 sub-header">
                            <div class="col-lg-6 col-md-6 left-content">
                                <div class="d-flex">
                                    <input #checkAllApp [(ngModel)]="selectAllAppCheck"
                                        class="form-check-input mt-0 mr-5" id="checkAllApp" type="checkbox"
                                        (change)="selectAllApp('All', $event)">
                                    <label class="form-check-label mt-0 mb-0 mr-20" for="checkAllApp">
                                        <span *ngIf="!selectAllAppCheck">Select all</span>
                                        <span *ngIf="selectAllAppCheck">Unselect all</span>
                                    </label>
                                    <!-- <p class="txt-regular mr-20">Select all</p> -->
                                </div>
                                <div class="selected-data c-p">
                                    <p class="grey-2-14">{{allAppDetails.length}} Selected</p>
                                </div>

                            </div>
                            <div class="col-lg-6 col-md-6  right-content text-regular-medium"
                                [ngClass]="hideButton ? 'input-cursor-na':''">
                                <div (click)="sendMail();" class="selected-data message-btn">
                                    <i class="bi bi-chat-left mr-5"></i>
                                    <p>Email Selected</p>
                                </div>
                                <div (click)="openUpdateStatusModal();" class="selected-data message-btn ml-20">
                                    <i class="bi bi-pencil fs-12 mr-5"></i>
                                    <p>Update status</p>
                                </div>
                            </div>
                        </div>
                        <app-boost-job [freeBoost]="is_free_boost" [freeBoostCount]="is_free_boostCount"  [boostJob]="jobDetails"></app-boost-job>
                        <div class="card mt-20 px-10" *ngFor="let applicants of allApplicant">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-11">
                                    <div class="col-md-12 col-lg-12 d-flex">
                                        <div class="pic-and-check">
                                            <input class="form-check-input mr-10 c-p"
                                                (change)="selectAllApp(applicants, $event)"
                                                [checked]="applicants.isChecked" type="checkbox"
                                                id="{{applicants.teacher.id}}" type="checkbox">
                                            <!-- <input type="hidden" formControlName="selectedValues" value="{{values}}"> -->
                                        </div>
                                        <div class="pic-and-check ml-0">
                                            <img alt="img"
                                                [src]="applicants.teacher.meta.profile_image != null ? applicants.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                class="profile-picture-s img-complete-match">
                                        </div>
                                        <div class="pic-and-check ml-10">
                                            <div class="d-flex">
                                                <div class="user-title c-p s_n">
                                                    <span [ngClass]="!doubleClickDisabled ? '':'ProfileModaldisabled'"
                                                        (click)="getTeacherAvailabilityModal($event , applicants.teacher.id)">
                                                        {{applicants.teacher.user.full_name}}
                                                    </span>
                                                </div>
                                                <div>
                                                    <app-validated-dict
                                                        [validateDict]="applicants.teacher.validated_dict">
                                                    </app-validated-dict>
                                                </div>
                                            </div>
                                            <div class="">
                                                <p class="text-s-m-g-1 mt-2">
                                                    <span
                                                        *ngIf="applicants.teacher.meta.you_are_a">{{applicants.teacher.meta.you_are_a}}</span>
                                                    <span class="divider-right ml-8 mr-4"></span>
                                                    <span *ngIf="applicants.teacher.user.previous_login"> Active
                                                        {{applicants.teacher.user.previous_login}}</span>
                                                </p>
                                            </div>


                                            <div class="">
                                                <p class="text-s-m-g-1 mt-2">
                                                    <span class="text-s-r-g-2"
                                                        *ngIf="applicants.teacher.accreditation.accreditation">Accreditation
                                                        No -
                                                        <span>{{applicants?.teacher?.accreditation?.accreditation[0]?.accreditation_id}}</span>
                                                    </span>
                                                    <span *ngIf="applicants?.teacher?.accreditation?.accreditation"
                                                        class="divider-right ml-8 mr-4"></span>
                                                    <span>
                                                        {{applicants.teacher.location}}</span>
                                                </p>
                                            </div>
                                            <div class="mt-5">
                                                <p class="text-s-m-g-1 d-flex align-items-center">
                                                    <span *ngIf="applicants?.resume != ''">
                                                        <a href="{{applicants?.resume}}" class="txt_s_r_b"><i
                                                                class="bi bi-download mr-2"></i> Resume</a>
                                                    </span>
                                                    <span class="ml-10" *ngIf="applicants?.cover_letter != ''">
                                                        <a href="{{applicants?.cover_letter}}" class="txt_s_r_b"><i
                                                                class="bi bi-download mr-2"></i> Cover
                                                            letter</a>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <i (click)="sendMessage(applicants.teacher)"
                                        class="bi bi-chat-left mr-3 clr-cc-vilot place-right d-flex c-p"
                                        [ngClass]="hideButton ? '':'input-cursor-na'"></i>

                                    <div class="mt-10 sm-d-none  place-right d-flex align-items-center status-column"
                                        [ngClass]="hideButton ? '':'input-cursor-na'">
                                        <span class="grey-2-14 mr-5"
                                            *ngIf="applicants.application_status == 'Hired' || applicants.application_status == 'Shortlisted' || applicants.application_status == 'Rejected'">Status
                                        </span>
                                        <p type="button" *ngIf="applicants.application_status == 'Pending'"
                                            data-bs-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle text-regular-bold clr-cc-vilot">Change Status</p>
                                        <p type="button"
                                            *ngIf="applicants.application_status == 'Hired' || applicants.application_status == 'Shortlisted' || applicants.application_status == 'Rejected'"
                                            data-bs-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle text-regular-bold clr-cc-vilot">
                                            {{applicants.application_status}}
                                        </p>
                                        <ul class="dropdown-menu txt-regular c-p">
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants , 'Hired')"
                                                    [ngClass]="applicants.application_status === 'Hired' ? 'input-cursor-na': ''">Hired</a>
                                            </li>
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants , 'Shortlisted')"
                                                    [ngClass]="applicants.application_status === 'Shortlisted' ? 'input-cursor-na': ''">Shortlisted</a>
                                            </li>
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants, 'Rejected')"
                                                    [ngClass]="applicants.application_status === 'Rejected' ? 'input-cursor-na': ''">Rejected</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <hr class="mt-10"
                                    *ngIf="this.schoolProps.properties.booking_school == true && applicants.school_my_teacher == false">
                                <div class=" d-flex place-content-end ">
                                    <div class=""
                                        *ngIf="this.schoolProps.properties.booking_school == true && applicants.school_my_teacher == false">
                                        <p (click)="addTeacherToList(applicants.teacher.id)"
                                            class="text-regular-bold clr-cc-vilot fit-content c-p">Add teacher
                                            to
                                            list</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="allApplicant?.length == 0" class="mt-20">
                        <h4 class="mb-20 no_appli_found text-center  ">You have no applicants at the moment.</h4>
                        <div
                            *ngIf="this.schoolProps.properties.job_only_school &&  jobDetails?.job_status.job_status == 'L'">
                            <h4 class="mb-10 no_appli_found text-center  ">Search for and invite potential candidates
                                <br>
                                who
                                might be a good fit for this job.
                            </h4>
                            <p class="text-center"><span class="txt-r-sb   clr-cc-vilot c-p"
                                    (click)="redirectToHeadhunter(jobDetails?.id)">Explore
                                    Headhunter <i class="bi bi-arrow-right"></i></span></p>
                        </div>
                        <div>
                            <app-boost-job  [freeBoost]="is_free_boost" [freeBoostCount]="is_free_boostCount"  [boostJob]="jobDetails"></app-boost-job>
                            <div *ngIf="this.schoolProps.properties.job_only_school" class="otherJob_tab">
                                <hr class="mt-20 mb-20">
                                <p class="user-title">Other jobs on ClassCover</p>
                                <div class="card mt-10 px-15 c-p" *ngFor="let jobs of others_job">
                                    <div class="row align-items-center" (click)="openPublicJobs(jobs.job_id)">
                                        <div class="col-lg-9 col-md-8 col-sm-8 col-12">
                                            <div class="col-md-12 col-lg-12 d-flex">
                                                <div class="pic-and-check ml-0">
                                                    <img [src]="(jobs.profile_image == null || jobs.profile_image == '') ? '../../../../assets/images/pro_avatar.png' : jobs.profile_image"
                                                        class="profile-picture img-complete-match">
                                                </div>
                                                <div class="pic-and-check ml-10">
                                                    <div class="d-flex flex-wrap">
                                                        <div class="user-title c-p h_b">{{jobs.job_title}}</div>
                                                        <div *ngIf="jobs.is_boost_job" class="job-boosted">Boosted</div>
                                                    </div>
                                                    <p class="text-s-m-g-1 mt-2">
                                                        {{jobs.school_name}}
                                                        <span class="divider-right ml-3 mr-5"></span>
                                                        {{jobs.school_level}}
                                                    </p>
                                                    <p class="text-s-m-g-1 mt-2">
                                                        {{jobs.school_state}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-sm-4 col-md-4 d-flex place-content-end">
                                            <span>
                                                <div class="accept-decline mb-2">
                                                    <div class="d-flex  c-p">
                                                        <p class="text-s-m-g-2">{{jobs.view_count}} job views</p>
                                                    </div>
                                                </div>
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <p class="text-center"><img *ngIf="!this.schoolProps.properties.job_only_school"
                                    class="no-result-image mt-20" src="assets/images/jobs/no_applicant.png"
                                    alt="No matches for the applied filter!" /></p>
                            <!-- <button type="button" id="send-invite-btn" routerLink="/school/post-job"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                class="btn cc_p_btn pl-30 pr-30 fs_13 btn-sm mt-30">
                                Post a new job</button> -->
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-hired" [ngClass]="{ 'active':activeTab==='hired'}" role="tabpanel"
                    aria-labelledby="nav-hired-tab">
                    <div class="filters" *ngIf="showHiredApplicantFilter">
                        <form [formGroup]="hiredAppJobDetailsFilterForm">
                            <div class="row mt-15">
                                <div class="col-lg-4 col-md-4 col-sm-6 mt-10">
                                    <ng-select [dropdownPosition]="'bottom'" formControlName="filter_consumer_type"
                                        (change)="getFilterInputs()" [multiple]="true" placeholder="Role type">
                                        <ng-option *ngFor="let tag of consumerType" [value]="tag['id']"
                                            [disabled]="tag['is_parent']">
                                            {{tag['title']}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6  mt-10">
                                    <ng-select [dropdownPosition]="'bottom'" formControlName="filter_levels"
                                        (change)="getFilterInputs()" [items]="levelFilter" [multiple]="true"
                                        bindLabel="title" bindValue="value" placeholder="Search by level">
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-4 mt-10">
                                    <ng-select [clearable]="false" [dropdownPosition]="'bottom'" [searchable]="false"
                                        [multiple]="false" formControlName="filter_distance"
                                        (change)="getFilterInputs()">
                                        <ng-option *ngFor="let distance of filtersArray.distance_choices"
                                            value="{{distance.value}}">
                                            {{distance.title}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-10">
                                    <div class="input-group">
                                        <input formControlName="filter_name" id="search-by-name" type="text"
                                            placeholder="Search by name" class="form-control"
                                            aria-label="Search by name">
                                        <button class="input-group-text" (click)="getFilterInputs()"
                                            for="search-by-name"><i class="bi bi-search"></i></button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-10">
                                    <ng-select [items]="subjectsAndTags" (change)="getFilterInputs()"
                                        formControlName="filter_subjects" bindLabel="title" bindValue="data" groupBy="tags"
                                        [multiple]="true" placeholder="Search by subjects and tags">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.title}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 mt-10">
                                    <div class="form-check">
                                        <input class="form-check-input c-p  mt-4" (change)="getFilterInputs()"
                                            type="checkbox" id="is-validated_2" formControlName="filter_is_validated">
                                        <label class="form-check-label c-p" for="is-validated_2">
                                            Approved to teach only
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div *ngIf="hiredApplicant?.length > 0">

                        <div class="row mt-20 sub-header">
                            <div class="col-lg-4 col-md-4 left-content">
                                <div class="d-flex">
                                    <input #checkAllHired [(ngModel)]="selectAllHiredAppCheck"
                                        class="form-check-input mt-0 mr-5" id="checkAllHired" type="checkbox"
                                        (change)="selectAllHired('All', $event)">
                                    <label class="form-check-label mt-0 mb-0 mr-20" for="checkAllHired">
                                        <span *ngIf="!selectAllHiredAppCheck">Select all</span>
                                        <span *ngIf="selectAllHiredAppCheck">Unselect all</span>

                                    </label>
                                    <!-- <p class="txt-regular mr-20">Select all</p> -->
                                </div>
                                <div class="selected-data c-p">
                                    <p class="grey-2-14">{{hiredAppDetails.length}} Selected</p>
                                </div>

                            </div>
                            <div class="col-lg-8 col-md-8  right-content text-regular-medium"
                                [ngClass]="hideButton ? 'input-cursor-na':''">
                                <div (click)="sendMail();" class="selected-data message-btn">
                                    <i class="bi bi-chat-left mr-5"></i>
                                    <p>Email Selected</p>
                                </div>
                                <div (click)="openUpdateStatusModal();" class="selected-data message-btn ml-20">
                                    <i class="bi bi-pencil fs-12 mr-5"></i>
                                    <p>Update status</p>
                                </div>
                            </div>
                        </div>
                        <app-boost-job  [freeBoost]="is_free_boost" [freeBoostCount]="is_free_boostCount"  [boostJob]="jobDetails"></app-boost-job>
                        <div class="card mt-20 px-15" *ngFor="let applicants of hiredApplicant">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-11">
                                    <div class="col-md-12 col-lg-12 d-flex">
                                        <div class="pic-and-check">
                                            <input class="form-check-input mr-10 c-p"
                                                (change)="selectAllHired(applicants, $event)"
                                                [checked]="applicants.isChecked" type="checkbox"
                                                id="{{applicants.teacher.id}}" type="checkbox">
                                            <!-- <input type="hidden" formControlName="selectedValues" value="{{values}}"> -->
                                        </div>
                                        <div class="pic-and-check ml-0">
                                            <img alt="img"
                                                [src]="applicants.teacher.meta.profile_image != null ? applicants.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                class="profile-picture-s img-complete-match">
                                        </div>
                                        <div class="pic-and-check ml-10">
                                            <div class="d-flex">
                                                <div class="user-title c-p s_n">
                                                    <span [ngClass]="!doubleClickDisabled ? '':'ProfileModaldisabled'"
                                                        (click)="getTeacherAvailabilityModal($event,applicants.teacher.id);">
                                                        {{applicants.teacher.user.full_name}}
                                                    </span>
                                                </div>
                                                <div>
                                                    <app-validated-dict
                                                        [validateDict]="applicants.teacher.validated_dict">
                                                    </app-validated-dict>
                                                </div>
                                            </div>
                                            <div class="">
                                                <p class="text-s-m-g-1 mt-2">
                                                    <span
                                                        *ngIf="applicants.teacher.meta.you_are_a">{{applicants.teacher.meta.you_are_a}}</span>
                                                    <span class="divider-right ml-8 mr-4"></span>
                                                    <span *ngIf="applicants.teacher.user.previous_login"> Active
                                                        {{applicants.teacher.user.previous_login}}</span>
                                                </p>
                                            </div>
                                            <div class="">
                                                <p class="text-s-m-g-1 mt-2">
                                                    <span class="text-s-r-g-2"
                                                        *ngIf="applicants?.teacher?.accreditation.accreditation">Accreditation
                                                        No -
                                                        <span>{{applicants?.teacher?.accreditation?.accreditation[0]?.accreditation_id}}</span>
                                                    </span>

                                                    <span *ngIf="applicants?.teacher?.accreditation.accreditation"
                                                        class="divider-right ml-8 mr-4"></span>
                                                    <span>
                                                        {{applicants.teacher.location}}</span>
                                                </p>
                                            </div>
                                            <div class="mt-5">
                                                <p class="text-s-m-g-1">
                                                    <span *ngIf="applicants?.resume != ''">
                                                        <a href="{{applicants?.resume}}" class="txt_s_r_b"><i
                                                                class="bi bi-download mr-2"></i> Resume</a>
                                                    </span>
                                                    <span class="ml-10" *ngIf="applicants?.cover_letter != ''">
                                                        <a href="{{applicants?.cover_letter}}" class="txt_s_r_b"> <i
                                                                class="bi bi-download mr-2"></i> Cover
                                                            letter</a>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <i (click)="sendMessage(applicants.teacher)"
                                        class="bi bi-chat-left c-p clr-cc-vilot mr-3 place-right d-flex"
                                        [ngClass]="hideButton ? '':'input-cursor-na'"></i>

                                    <div class="mt-20 sm-d-none place-right d-flex"
                                        [ngClass]="hideButton ? '':'input-cursor-na'">
                                        <span class="grey-2-14 mr-5"
                                            *ngIf="applicants.application_status == 'Hired' || applicants.application_status == 'Shortlisted' || applicants.application_status == 'Rejected'">Status
                                        </span>
                                        <p type="button" *ngIf="applicants.application_status == 'Pending'"
                                            data-bs-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle text-regular-bold clr-cc-vilot">Change Status</p>
                                        <p type="button"
                                            *ngIf="applicants.application_status == 'Hired' || applicants.application_status == 'Shortlisted' || applicants.application_status == 'Rejected'"
                                            data-bs-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle text-regular-bold clr-cc-vilot">
                                            {{applicants.application_status}}
                                        </p>
                                        <ul class="dropdown-menu txt-regular c-p">
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants , 'Hired')"
                                                    [ngClass]="applicants.application_status === 'Hired' ? 'input-cursor-na': ''">Hired</a>
                                            </li>
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants , 'Shortlisted')"
                                                    [ngClass]="applicants.application_status === 'Shortlisted' ? 'input-cursor-na': ''">Shortlisted</a>
                                            </li>
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants, 'Rejected')"
                                                    [ngClass]="applicants.application_status === 'Rejected' ? 'input-cursor-na': ''">Rejected</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <hr class="mt-10"
                                    *ngIf="this.schoolProps.properties.booking_school == true && applicants.school_my_teacher == false">
                                <div class=" d-flex place-content-end ">
                                    <div class=""
                                        *ngIf="this.schoolProps.properties.booking_school == true && applicants.school_my_teacher == false">
                                        <p (click)="addTeacherToList(applicants.teacher.id)"
                                            class="text-regular-bold clr-cc-vilot fit-content c-p">Add teacher
                                            to
                                            list</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="hiredApplicant?.length == 0" class=" mt-20">
                        <h4 class="mb-20 no_appli_found text-center">You don’t have any hired applicants yet.</h4>
                        <div
                            *ngIf="this.schoolProps.properties.job_only_school &&  jobDetails?.job_status.job_status == 'L'">
                            <h4 class="mb-10 no_appli_found text-center  ">Search for and invite potential candidates
                                <br>
                                who
                                might be a good fit for this job.
                            </h4>
                            <p class="text-center"><span class="txt-r-sb   clr-cc-vilot c-p"
                                    (click)="redirectToHeadhunter(jobDetails?.id)">Explore
                                    Headhunter <i class="bi bi-arrow-right"></i></span></p>
                        </div>
                        <div>
                            <!-- <p><img *ngIf="this.schoolProps.properties.job_only_school" class="no-result-image mt-20"
                                    src="assets/images/jobs/job_only_user/no_applicant.png"
                                    alt="No matches for the applied filter!" /></p> -->
                            <app-boost-job  [freeBoost]="is_free_boost" [freeBoostCount]="is_free_boostCount"  [boostJob]="jobDetails"></app-boost-job>
                            <div *ngIf="this.schoolProps.properties.job_only_school" class="otherJob_tab">
                                <hr class="mt-20 mb-20">
                                <p class="user-title">Other jobs on ClassCover</p>
                                <div class="card mt-10 px-15 c-p" *ngFor="let jobs of others_job">
                                    <div class="row align-items-center" (click)="openPublicJobs(jobs.job_id)">
                                        <div class="col-lg-9 col-md-8 col-sm-8 col-12">
                                            <div class="col-md-12 col-lg-12 d-flex">
                                                <div class="pic-and-check ml-0">
                                                    <img [src]="(jobs.profile_image == null || jobs.profile_image == '') ? '../../../../assets/images/pro_avatar.png' : jobs.profile_image"
                                                        class="profile-picture img-complete-match">
                                                </div>
                                                <div class="pic-and-check ml-10">
                                                    <div class="d-flex flex-wrap">
                                                        <div class="user-title c-p h_b">{{jobs.job_title}}</div>
                                                        <!-- <div class="type-badge text-s-m-g-1">{{jobs.job_type}}</div> -->
                                                        <div *ngIf="jobs.is_boost_job" class="job-boosted">Boosted</div>
                                                    </div>
                                                    <p class="text-s-m-g-1 mt-2">
                                                        {{jobs.school_name}}
                                                        <span class="divider-right ml-3 mr-5"></span>
                                                        {{jobs.school_level}}
                                                    </p>
                                                    <p class="text-s-m-g-1 mt-2">
                                                        {{jobs.school_state}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-sm-4 col-md-4 d-flex place-content-end">
                                            <span>
                                                <div class="accept-decline mb-2">
                                                    <div class="d-flex  c-p">
                                                        <p class="text-s-m-g-2">{{jobs.view_count}} job views</p>
                                                    </div>
                                                </div>
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <p class="text-center"><img *ngIf="!this.schoolProps.properties.job_only_school"
                                    class="no-result-image mt-20" src="assets/images/jobs/no_applicant.png"
                                    alt="No matches for the applied filter!" /></p>
                            <!-- <button type="button" id="send-invite-btn" routerLink="/school/post-job"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                class="btn cc_p_btn pl-30 pr-30 fs_13 btn-sm mt-30">
                                Post a new job</button> -->
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-shortlisted" [ngClass]="{ 'active':activeTab==='shortlisted'}"
                    role="tabpanel" aria-labelledby="nav-shortlisted-tab">
                    <div class="filters" *ngIf="showShortlistApplicantFilter">
                        <form [formGroup]="shortAppJobDetailsFilterForm">
                            <div class="row mt-15">
                                <div class="col-lg-4 col-md-4 col-sm-6 mt-10">
                                    <ng-select [dropdownPosition]="'bottom'" formControlName="filter_consumer_type"
                                        (change)="getFilterInputs()" [multiple]="true" placeholder="Role type">
                                        <ng-option *ngFor="let tag of consumerType" [value]="tag['id']"
                                            [disabled]="tag['is_parent']">
                                            {{tag['title']}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6  mt-10">
                                    <ng-select [dropdownPosition]="'bottom'" formControlName="filter_levels"
                                        (change)="getFilterInputs()" [items]="levelFilter" [multiple]="true"
                                        bindLabel="title" bindValue="value" placeholder="Search by level">
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-4 mt-10">
                                    <ng-select [clearable]="false" [dropdownPosition]="'bottom'" [searchable]="false"
                                        [multiple]="false" formControlName="filter_distance"
                                        (change)="getFilterInputs()">
                                        <ng-option *ngFor="let distance of filtersArray.distance_choices"
                                            value="{{distance.value}}">
                                            {{distance.title}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-10">
                                    <div class="input-group">
                                        <input formControlName="filter_name" id="search-by-name" type="text"
                                            placeholder="Search by name" class="form-control"
                                            aria-label="Search by name">
                                        <button class="input-group-text" (click)="getFilterInputs()"
                                            for="search-by-name"><i class="bi bi-search"></i></button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-10">
                                    <ng-select [items]="subjectsAndTags" (change)="getFilterInputs()"
                                        formControlName="filter_subjects" bindLabel="title" bindValue="data" groupBy="tags"
                                        [multiple]="true" placeholder="Search by subjects and tags">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.title}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 mt-10">
                                    <div class="form-check">
                                        <input class="form-check-input c-p  mt-4" (change)="getFilterInputs()"
                                            type="checkbox" id="is-validated_2" formControlName="filter_is_validated">
                                        <label class="form-check-label c-p" for="is-validated_2">
                                            Approved to teach only
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div *ngIf="shortlistApplicant?.length > 0">
                        <div class="row mt-20 sub-header">
                            <div class="col-lg-4 col-md-4 left-content">
                                <div class="d-flex">
                                    <input #checkAllShort [(ngModel)]="selectAllShortAppCheck"
                                        class="form-check-input mt-0 mr-5" id="checkAllShort" type="checkbox"
                                        (change)="selectAllShort('All', $event)">
                                    <label class="form-check-label mt-0 mb-0 mr-20" for="checkAllShort">
                                        <span *ngIf="!selectAllShortAppCheck">Select all</span>
                                        <span *ngIf="selectAllShortAppCheck">Unselect all</span>

                                    </label>
                                    <!-- <p class="txt-regular mr-20">Select all</p> -->
                                </div>
                                <div class="selected-data c-p">
                                    <p class="grey-2-14">{{shortAppDetails.length}} Selected</p>
                                </div>

                            </div>
                            <div class="col-lg-8 col-md-8  right-content text-regular-medium"
                                [ngClass]="hideButton ? 'input-cursor-na':''">
                                <div (click)="sendMail();" class="selected-data message-btn">
                                    <i class="bi bi-chat-left mr-5"></i>
                                    <p>Email Selected</p>
                                </div>
                                <div (click)="openUpdateStatusModal();" class="selected-data message-btn ml-20">
                                    <i class="bi bi-pencil fs-12 mr-5"></i>
                                    <p>Update status</p>
                                </div>
                            </div>
                        </div>
                        <app-boost-job  [freeBoost]="is_free_boost" [freeBoostCount]="is_free_boostCount"   [boostJob]="jobDetails"></app-boost-job>
                        <div class="card mt-20 px-15" *ngFor="let applicants of shortlistApplicant">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-11">
                                    <div class="col-md-12 col-lg-12 d-flex">
                                        <div class="pic-and-check">
                                            <input class="form-check-input mr-10 c-p"
                                                (change)="selectAllShort(applicants, $event)"
                                                [checked]="applicants.isChecked" type="checkbox"
                                                id="{{applicants.teacher.id}}" type="checkbox">
                                            <!-- <input type="hidden" formControlName="selectedValues" value="{{values}}"> -->
                                        </div>
                                        <div class="pic-and-check ml-0">
                                            <img alt="img"
                                                [src]="applicants.teacher.meta.profile_image != null ? applicants.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                class="profile-picture-s img-complete-match">
                                        </div>
                                        <div class="pic-and-check ml-10">
                                            <div class="d-flex">
                                                <div class="user-title c-p s_n">
                                                    <span [ngClass]="!doubleClickDisabled ? '':'ProfileModaldisabled'"
                                                        (click)="getTeacherAvailabilityModal($event,applicants.teacher.id);">
                                                        {{applicants.teacher.user.full_name}}
                                                    </span>
                                                </div>
                                                <div>
                                                    <app-validated-dict
                                                        [validateDict]="applicants.teacher.validated_dict">
                                                    </app-validated-dict>
                                                </div>
                                            </div>
                                            <div class="">
                                                <p class="text-s-m-g-1 mt-2">
                                                    <span
                                                        *ngIf="applicants.teacher.meta.you_are_a">{{applicants.teacher.meta.you_are_a}}</span>
                                                    <span class="divider-right ml-8 mr-4"></span>
                                                    <span *ngIf="applicants.teacher.user.previous_login"> Active
                                                        {{applicants.teacher.user.previous_login}}</span>
                                                </p>
                                            </div>
                                            <div class="">
                                                <p class="text-s-m-g-1 mt-2">
                                                    <span class="text-s-m-g-2"
                                                        *ngIf="applicants?.teacher?.accreditation.accreditation">Accreditation
                                                        No -
                                                        <span>{{applicants?.teacher?.accreditation?.accreditation[0]?.accreditation_id}}</span>
                                                    </span>
                                                    <span *ngIf="applicants?.teacher?.accreditation.accreditation"
                                                        class="divider-right ml-8 mr-4"></span>
                                                    <span>
                                                        {{applicants.teacher.location}}</span>
                                                </p>
                                            </div>
                                            <div class="mt-5">
                                                <p class="text-s-m-g-1">
                                                    <span *ngIf="applicants?.resume != ''">
                                                        <a href="{{applicants?.resume}}" class="txt_s_r_b"><i
                                                                class="bi bi-download mr-2"></i> Resume</a>
                                                    </span>
                                                    <span class="ml-10" *ngIf="applicants?.cover_letter != ''">
                                                        <a href="{{applicants?.cover_letter}}" class="txt_s_r_b"> <i
                                                                class="bi bi-download mr-2"></i> Cover
                                                            letter</a>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <i (click)="sendMessage(applicants.teacher)"
                                        class="bi bi-chat-left c-p mr-3 clr-cc-vilot  place-right d-flex"
                                        [ngClass]="hideButton ? '':'input-cursor-na'"></i>
                                    <div class="mt-20 sm-d-none place-right d-flex"
                                        [ngClass]="hideButton ? '':'input-cursor-na'">
                                        <span class="grey-2-14 mr-5"
                                            *ngIf="applicants.application_status == 'Hired' || applicants.application_status == 'Shortlisted' || applicants.application_status == 'Rejected'">Status
                                        </span>
                                        <p type="button" *ngIf="applicants.application_status == 'Pending'"
                                            data-bs-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle text-regular-bold clr-cc-vilot">Change Status</p>
                                        <p type="button"
                                            *ngIf="applicants.application_status == 'Hired' || applicants.application_status == 'Shortlisted' || applicants.application_status == 'Rejected'"
                                            data-bs-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle text-regular-bold clr-cc-vilot">
                                            {{applicants.application_status}}
                                        </p>
                                        <ul class="dropdown-menu txt-regular c-p">
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants , 'Hired')"
                                                    [ngClass]="applicants.application_status === 'Hired' ? 'input-cursor-na': ''">Hired</a>
                                            </li>
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants , 'Shortlisted')"
                                                    [ngClass]="applicants.application_status === 'Shortlisted' ? 'input-cursor-na': ''">Shortlisted</a>
                                            </li>
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants, 'Rejected')"
                                                    [ngClass]="applicants.application_status === 'Rejected' ? 'input-cursor-na': ''">Rejected</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <hr class="mt-10"
                                    *ngIf="this.schoolProps.properties.booking_school == true && applicants.school_my_teacher == false">
                                <div class=" d-flex place-content-end ">
                                    <div class=""
                                        *ngIf="this.schoolProps.properties.booking_school == true && applicants.school_my_teacher == false">
                                        <p (click)="addTeacherToList(applicants.teacher.id)"
                                            class="text-regular-bold clr-cc-vilot fit-content c-p">Add teacher
                                            to
                                            list</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="shortlistApplicant?.length == 0" class="mt-20">
                        <h4 class="mb-20 no_appli_found text-center ">You don’t have any Shortlisted applicants yet.
                        </h4>
                        <div
                            *ngIf="this.schoolProps.properties.job_only_school &&  jobDetails?.job_status.job_status == 'L'">
                            <h4 class="mb-10 no_appli_found text-center  ">Search for and invite potential candidates
                                <br>
                                who
                                might be a good fit for this job.
                            </h4>
                            <p class="text-center"><span class="txt-r-sb   clr-cc-vilot c-p"
                                    (click)="redirectToHeadhunter(jobDetails?.id)">Explore
                                    Headhunter <i class="bi bi-arrow-right"></i></span></p>
                        </div>
                        <div>
                            <!-- <p><img *ngIf="this.schoolProps.properties.job_only_school" class="no-result-image mt-20"
                                    src="assets/images/jobs/job_only_user/no_applicant.png"
                                    alt="No matches for the applied filter!" /></p> -->
                            <app-boost-job  [freeBoost]="is_free_boost" [freeBoostCount]="is_free_boostCount"  [boostJob]="jobDetails"></app-boost-job>
                            <div *ngIf="this.schoolProps.properties.job_only_school" class="otherJob_tab">
                                <hr class="mt-20 mb-20">
                                <p class="user-title">Other jobs on ClassCover</p>
                                <div class="card mt-10 px-15 c-p" *ngFor="let jobs of others_job">
                                    <div class="row align-items-center" (click)="openPublicJobs(jobs.job_id)">
                                        <div class="col-lg-9 col-md-8 col-sm-8 col-12">
                                            <div class="col-md-12 col-lg-12 d-flex">
                                                <div class="pic-and-check ml-0">
                                                    <img [src]="(jobs.profile_image == null || jobs.profile_image == '') ? '../../../../assets/images/pro_avatar.png' : jobs.profile_image"
                                                        class="profile-picture img-complete-match">
                                                </div>
                                                <div class="pic-and-check ml-10">
                                                    <div class="d-flex flex-wrap">
                                                        <div class="user-title c-p h_b">{{jobs.job_title}}</div>
                                                        <!-- <div class="type-badge text-s-m-g-1">{{jobs.job_type}}</div> -->
                                                        <div *ngIf="jobs.is_boost_job" class="job-boosted">Boosted</div>
                                                    </div>
                                                    <p class="text-s-m-g-1 mt-2">
                                                        {{jobs.school_name}}
                                                        <span class="divider-right ml-3 mr-5"></span>
                                                        {{jobs.school_level}}
                                                    </p>
                                                    <p class="text-s-m-g-1 mt-2">
                                                        {{jobs.school_state}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-sm-4 col-md-4 d-flex place-content-end">
                                            <span>
                                                <div class="accept-decline mb-2">
                                                    <div class="d-flex  c-p">
                                                        <p class="text-s-m-g-2">{{jobs.view_count}} job views</p>
                                                    </div>
                                                </div>
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <p class="text-center"><img *ngIf="!this.schoolProps.properties.job_only_school"
                                    class="no-result-image mt-20" src="assets/images/jobs/no_applicant.png"
                                    alt="No matches for the applied filter!" /></p>
                            <!-- <button type="button" id="send-invite-btn" routerLink="/school/post-job"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                class="btn cc_p_btn pl-30 pr-30 fs_13 btn-sm mt-30">
                                Post a new job</button> -->
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-rejected" [ngClass]="{ 'active':activeTab==='rejected'}"
                    role="tabpanel" aria-labelledby="nav-rejected-tab">
                    <div class="filters" *ngIf="showRejectedApplicantFilter">
                        <form [formGroup]="rejectedAppJobDetailsFilterForm">
                            <div class="row mt-15">
                                <div class="col-lg-4 col-md-4 col-sm-6 mt-10">
                                    <ng-select [dropdownPosition]="'bottom'" formControlName="filter_consumer_type"
                                        (change)="getFilterInputs()" [multiple]="true" placeholder="Role type">
                                        <ng-option *ngFor="let tag of consumerType" [value]="tag['id']"
                                            [disabled]="tag['is_parent']">
                                            {{tag['title']}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6  mt-10">
                                    <ng-select [dropdownPosition]="'bottom'" formControlName="filter_levels"
                                        (change)="getFilterInputs()" [items]="levelFilter" [multiple]="true"
                                        bindLabel="title" bindValue="value" placeholder="Search by level">
                                    </ng-select>
                                </div>
                                <div class="col-lg-4 col-md-4 mt-10">
                                    <ng-select [clearable]="false" [dropdownPosition]="'bottom'" [searchable]="false"
                                        [multiple]="false" formControlName="filter_distance"
                                        (change)="getFilterInputs()">
                                        <ng-option *ngFor="let distance of filtersArray.distance_choices"
                                            value="{{distance.value}}">
                                            {{distance.title}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-10">
                                    <div class="input-group">
                                        <input formControlName="filter_name" id="search-by-name" type="text"
                                            placeholder="Search by name" class="form-control"
                                            aria-label="Search by name">
                                        <button class="input-group-text" (click)="getFilterInputs()"
                                            for="search-by-name"><i class="bi bi-search"></i></button>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-10">
                                    <ng-select [items]="subjectsAndTags" (change)="getFilterInputs()"
                                        formControlName="filter_subjects" bindLabel="title" bindValue="data" groupBy="tags"
                                        [multiple]="true" placeholder="Search by subjects and tags">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.title}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 mt-10">
                                    <div class="form-check">
                                        <input class="form-check-input c-p  mt-4" (change)="getFilterInputs()"
                                            type="checkbox" id="is-validated_2" formControlName="filter_is_validated">
                                        <label class="form-check-label c-p" for="is-validated_2">
                                            Approved to teach only
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div *ngIf="rejectedApplicant?.length > 0">
                        <div class="row mt-20 sub-header">
                            <div class="col-lg-4 col-md-4 left-content">
                                <div class="d-flex">
                                    <input #checkAllReject [(ngModel)]="selectAllRejectAppCheck"
                                        class="form-check-input mt-0 mr-5" id="checkAllReject" type="checkbox"
                                        (change)="selectAllRejected('All', $event)">
                                    <label class="form-check-label mt-0 mb-0 mr-20" for="checkAllReject">
                                        <span *ngIf="!selectAllRejectAppCheck">Select all</span>
                                        <span *ngIf="selectAllRejectAppCheck">Unselect all</span>

                                    </label>
                                    <!-- <p class="txt-regular mr-20">Select all</p> -->
                                </div>
                                <div class="selected-data c-p">
                                    <p class="grey-2-14">{{rejectedAppDetails.length}} Selected</p>
                                </div>

                            </div>
                            <div class="col-lg-8 col-md-8  right-content text-regular-medium"
                                [ngClass]="hideButton ? 'input-cursor-na':''">
                                <div (click)="sendMail();" class="selected-data message-btn">
                                    <i class="bi bi-chat-left mr-5"></i>
                                    <p>Email Selected</p>
                                </div>
                                <div (click)="openUpdateStatusModal();" class="selected-data message-btn ml-20">
                                    <i class="bi bi-pencil fs-12 mr-5"></i>
                                    <p>Update status</p>
                                </div>
                            </div>
                        </div>
                        <app-boost-job  [freeBoost]="is_free_boost" [freeBoostCount]="is_free_boostCount"  [boostJob]="jobDetails"></app-boost-job>
                        <div class="card mt-20 px-15" *ngFor="let applicants of rejectedApplicant">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-11">
                                    <div class="col-md-12 col-lg-12 d-flex">
                                        <div class="pic-and-check">
                                            <input class="form-check-input mr-10 c-p"
                                                (change)="selectAllRejected(applicants, $event)"
                                                [checked]="applicants.isChecked" type="checkbox"
                                                id="{{applicants.teacher.id}}" type="checkbox">
                                            <!-- <input type="hidden" formControlName="selectedValues" value="{{values}}"> -->
                                        </div>
                                        <div class="pic-and-check ml-0">
                                            <img alt="img"
                                                [src]="applicants.teacher.meta.profile_image != null ? applicants.teacher.meta.profile_image : '../../../../assets/images/teacher-static-profile.png'"
                                                class="profile-picture-s img-complete-match">
                                        </div>
                                        <div class="pic-and-check ml-10">
                                            <div class="d-flex">
                                                <div class="user-title c-p s_n">
                                                    <!-- <span *ngIf="this.schoolProps.properties.job_only_school == true">
                                                        {{applicants.teacher.user.full_name}}
                                                    </span> -->
                                                    <span [ngClass]="!doubleClickDisabled ? '':'ProfileModaldisabled'"
                                                        (click)="getTeacherAvailabilityModal($event,applicants.teacher.id);">
                                                        {{applicants.teacher.user.full_name}}
                                                    </span>
                                                </div>
                                                <div>
                                                    <app-validated-dict
                                                        [validateDict]="applicants.teacher.validated_dict">
                                                    </app-validated-dict>
                                                </div>
                                            </div>
                                            <div class="">
                                                <p class="text-s-m-g-1 mt-2">
                                                    <span
                                                        *ngIf="applicants.teacher.meta.you_are_a">{{applicants.teacher.meta.you_are_a}}</span>
                                                    <span class="divider-right ml-8 mr-4"></span>
                                                    <span *ngIf="applicants.teacher.user.previous_login"> Active
                                                        {{applicants.teacher.user.previous_login}}</span>
                                                </p>
                                            </div>
                                            <div class="">
                                                <p class="text-s-m-g-1 mt-2">
                                                    <span class="text-s-r-g-2"
                                                        *ngIf="applicants?.teacher?.accreditation.accreditation">Accreditation
                                                        No -
                                                        <span>{{applicants?.teacher?.accreditation?.accreditation[0]?.accreditation_id}}</span>
                                                    </span>

                                                    <span *ngIf="applicants?.teacher?.accreditation.accreditation"
                                                        class="divider-right ml-8 mr-4"></span>
                                                    <span>
                                                        {{applicants.teacher.location}}</span>
                                                </p>
                                            </div>
                                            <div class="mt-5">
                                                <p class="text-s-m-g-1">
                                                    <span *ngIf="applicants?.resume != ''">
                                                        <a href="{{applicants?.resume}}" class="txt_s_r_b"><i
                                                                class="bi bi-download mr-2"></i> Resume</a>
                                                    </span>
                                                    <span class="ml-10" *ngIf="applicants?.cover_letter != ''">
                                                        <a href="{{applicants?.cover_letter}}" class="txt_s_r_b"> <i
                                                                class="bi bi-download mr-2"></i> Cover
                                                            letter</a>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <i (click)="sendMessage(applicants.teacher)"
                                        class="bi bi-chat-left c-p mr-3 clr-cc-vilot  place-right d-flex"
                                        [ngClass]="hideButton ? '':'input-cursor-na'"></i>

                                    <div class="mt-20 sm-d-none place-right d-flex"
                                        [ngClass]="hideButton ? '':'input-cursor-na'">
                                        <span class="grey-2-14 mr-5"
                                            *ngIf="applicants.application_status == 'Hired' || applicants.application_status == 'Shortlisted' || applicants.application_status == 'Rejected'">Status
                                        </span>
                                        <p type="button" *ngIf="applicants.application_status == 'Pending'"
                                            data-bs-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle text-regular-bold clr-cc-vilot">Change Status</p>
                                        <p type="button"
                                            *ngIf="applicants.application_status == 'Hired' || applicants.application_status == 'Shortlisted' || applicants.application_status == 'Rejected'"
                                            data-bs-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle text-regular-bold clr-cc-vilot">
                                            {{applicants.application_status}}
                                        </p>
                                        <ul class="dropdown-menu txt-regular c-p">
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants , 'Hired')"
                                                    [ngClass]="applicants.application_status === 'Hired' ? 'input-cursor-na': ''">Hired</a>
                                            </li>
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants , 'Shortlisted')"
                                                    [ngClass]="applicants.application_status === 'Shortlisted' ? 'input-cursor-na': ''">Shortlisted</a>
                                            </li>
                                            <li><a class="dropdown-item"
                                                    (click)="openChangeStatusModal(applicants, 'Rejected')"
                                                    [ngClass]="applicants.application_status === 'Rejected' ? 'input-cursor-na': ''">Rejected</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <hr class="mt-10"
                                    *ngIf="this.schoolProps.properties.booking_school == true && applicants.school_my_teacher == false">
                                <div class=" d-flex place-content-end ">
                                    <div class=""
                                        *ngIf="this.schoolProps.properties.booking_school == true && applicants.school_my_teacher == false">
                                        <p (click)="addTeacherToList(applicants.teacher.id)"
                                            class="text-regular-bold clr-cc-vilot fit-content c-p">Add teacher
                                            to
                                            list</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="rejectedApplicant?.length == 0" class="mt-20">
                        <h4 class="mb-20 no_appli_found text-center">You don’t have any Rejected applicants yet.</h4>
                        <div
                            *ngIf="this.schoolProps.properties.job_only_school &&  jobDetails?.job_status.job_status == 'L'">
                            <h4 class="mb-10 no_appli_found text-center  ">Search for and invite potential candidates
                                <br>
                                who
                                might be a good fit for this job.
                            </h4>
                            <p class="text-center"><span class="txt-r-sb   clr-cc-vilot c-p"
                                    (click)="redirectToHeadhunter(jobDetails?.id)">Explore
                                    Headhunter <i class="bi bi-arrow-right"></i></span></p>
                        </div>
                        <div>
                            <!-- <p><img *ngIf="this.schoolProps.properties.job_only_school" class="no-result-image mt-20"
                                    src="assets/images/jobs/job_only_user/no_applicant.png"
                                    alt="No matches for the applied filter!" /></p> -->
                            <app-boost-job  [freeBoost]="is_free_boost" [freeBoostCount]="is_free_boostCount"   [boostJob]="jobDetails"></app-boost-job>

                            <div *ngIf="this.schoolProps.properties.job_only_school" class="otherJob_tab">
                                <hr class="mt-20 mb-20">
                                <p class="user-title">Other jobs on ClassCover</p>
                                <div class="card mt-10 px-15 c-p" *ngFor="let jobs of others_job">
                                    <div class="row align-items-center" (click)="openPublicJobs(jobs.job_id)">
                                        <div class="col-lg-9 col-md-8 col-sm-8 col-12">
                                            <div class="col-md-12 col-lg-12 d-flex">
                                                <div class="pic-and-check ml-0">
                                                    <img [src]="(jobs.profile_image == null || jobs.profile_image == '') ? '../../../../assets/images/pro_avatar.png' : jobs.profile_image"
                                                        class="profile-picture img-complete-match">
                                                </div>
                                                <div class="pic-and-check ml-10">
                                                    <div class="d-flex flex-wrap">
                                                        <div class="user-title c-p h_b">{{jobs.job_title}}</div>
                                                        <!-- <div class="type-badge text-s-m-g-1">{{jobs.job_type}}</div> -->
                                                        <div *ngIf="jobs.is_boost_job" class="job-boosted">Boosted</div>
                                                    </div>
                                                    <p class="text-s-m-g-1 mt-2">
                                                        {{jobs.school_name}}
                                                        <span class="divider-right ml-3 mr-5"></span>
                                                        {{jobs.school_level}}
                                                    </p>
                                                    <p class="text-s-m-g-1 mt-2">
                                                        {{jobs.school_state}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-sm-4 col-md-4 d-flex place-content-end">
                                            <span>
                                                <div class="accept-decline mb-2">
                                                    <div class="d-flex  c-p">
                                                        <p class="text-s-m-g-2">{{jobs.view_count}} job views</p>
                                                    </div>
                                                </div>
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <p class="text-center"><img *ngIf="!this.schoolProps.properties.job_only_school"
                                    class="no-result-image mt-20" src="assets/images/jobs/no_applicant.png"
                                    alt="No matches for the applied filter!" /></p>
                            <!-- <button type="button" id="send-invite-btn" routerLink="/school/post-job"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                class="btn cc_p_btn pl-30 pr-30 fs_13 btn-sm mt-30">
                                Post a new job</button> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-md-12 col-lg-4  mt-20">
            <div *ngIf="this.schoolProps.properties.job_only_school  &&  jobDetails?.job_status.job_status == 'L'"
                class="card new_card px-10 mb-10">
                <div>
                    <p class="user-title">Headhunter <span class="badge-new">New⚡</span></p>
                    <p class="txt-regular">Browse through applicants that might be a good fit for this job. <span
                            class="txt-r-sb   clr-cc-vilot c-p" (click)="redirectToHeadhunter(jobDetails?.id)">Explore
                            now <i class="bi bi-arrow-right"></i></span></p>
                </div>
            </div>
            <div class="card px-15" [ngClass]="jobDescription?.length > 300 ? 'jobScroollableView' : ''">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="col-md-12">
                            <div class="d-flex place-right">
                                <div *ngIf="jobDetails?.job_status.job_status == 'E' || jobDetails?.job_status.job_status == 'C'"
                                    class="">
                                    <p (click)="reOpenJob(jobDetails?.id)" class="txt-r-sb   clr-cc-vilot c-p">
                                        Reopen job
                                    </p>
                                </div>

                                <div *ngIf="jobDetails?.job_status.job_status == 'L'" class="ml-40">
                                    <p (click)="openCloseJobModal()" class="txt-r-sb   clr-cc-vilot c-p">Close
                                        job
                                    </p>
                                </div>

                                <div *ngIf="jobDetails?.job_status.job_status == 'L'" class="">
                                    <p (click)="editJob(jobDetails?.id)" class="txt-r-sb ml-40 clr-cc-vilot c-p">
                                        Edit
                                        job</p>
                                </div>
                                <div *ngIf="jobDetails?.job_status.job_status == 'D'" class="">
                                    <p (click)="editDraftJob(jobDetails?.id)" class="txt-r-sb ml-40 clr-cc-vilot c-p">
                                        Edit
                                        job</p>
                                </div>

                            </div>
                        </div>

                        <div class=" d-flex mt-10">
                            <div class="pic-and-check ml-0">
                                <img alt="img"
                                    [src]="jobDetails?.school.additional_info.profile_image != null ? jobDetails?.school.additional_info.profile_image : '../../../../assets/images/pro_avatar.png'"
                                    class="profile_dp img-complete-match">
                            </div>
                            <div class="pic-and-check pic-check2 ml-10">
                                <div class="d-flex align-items-center">
                                    <div class="job-title c-p">
                                        {{jobDetails?.job_title}}
                                    </div>

                                </div>
                                <div class="mt-3">
                                    <p class="text-s-m-g-1">
                                        <span>{{jobDetails?.school.title}}</span>
                                        <span class="divider-right ml-5 mr-5"></span>
                                        <span
                                            class="text-s-m-g-1">{{jobDetails?.school_level.school_level_display}}</span>
                                    </p>
                                </div>
                                <div class="mt-3">
                                    <p class="text-s-m-g-1 mt-2">
                                        <span>{{jobDetails?.externalclick_view_application_count.application_count}}
                                            Applicant</span>
                                        <span class="divider-right ml-8 mr-4"></span>
                                        <span>{{jobDetails?.externalclick_view_application_count.views_count}}
                                            Views</span>
                                    </p>
                                    <div
                                        *ngIf="jobDetails?.job_status.job_status != 'E' && jobDetails?.job_status.job_status != 'C'">
                                        <div *ngIf="jobDetails?.is_boost_job"
                                            class="job-boosted ml-0 text-s-m-g-1 mt-5">
                                            Boosted
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="pic-and-check align-self-flex-start ml-7"
                                *ngIf="jobDetails?.job_status.job_status == 'L'">
                                <div class="user-title c-p tooltips">
                                    <i class="bi bi-share"></i>
                                    <span class="tooltiptext">
                                        <p class="text-medium fs-16">Share this job with your network</p>
                                        <div class="d-flex gap-17 mt-10">
                                            <a href="https://facebook.com/sharer/sharer.php?u={{siteUrl}}//jobs/view-job/{{job_id}}"
                                                target="_blank"><i class="bi bi-facebook"></i></a>
                                            <a href="https://www.linkedin.com/shareArticle?mini=true&url={{siteUrl}}//jobs/view-job/{{job_id}}"
                                                target="_blank"><i class="bi bi-linkedin"></i></a>
                                            <a href="https://twitter.com/intent/tweet/?text=Check out this job {{siteUrl}}//jobs/view-job/{{job_id}}"
                                                target="_blank"><i class="bi bi-twitter"></i></a>
                                        </div>
                                        <label class="mt-10">Copy the job link below</label>
                                        <div class="input-group">
                                            <input type="text" [(ngModel)]="this.copyLink" class="form-control"
                                                name="link">
                                            <span (click)="copyUrlLink(this.copyLink)"
                                                class="input-group-text text-s-m-g-2">Copy link</span>
                                        </div>
                                        <span class="text-medium fs-14 clr-green" *ngIf="showLinkCopiedText">Link
                                            copied!</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row  c-p right_c">
                    <div class="col-md-12 col-lg-12">
                        <p class="text-14-g1-m mt-20"><i class="bi bi-briefcase mr-5"></i>
                            {{jobDetails?.job_type.job_type_display}}</p>
                        <p class="text-14-g1-m mt-5"><i class="bi bi-check-circle mr-5"></i>
                            {{jobDetails?.school_level.school_level_display}}</p>
                        <p class="text-14-g1-m mt-5"><span class="grey-2-14"><i class="bi bi-calendar mr-5"></i> Job
                                closes
                                on :</span> {{jobDetails?.job_close_date}}
                        </p>
                        <p class="text-14-g1-m mt-5"><span class="grey-2-14 mr-3"><i class="bi bi-calendar mr-5"></i>
                                Subjects :</span> <span class="mr-3"
                                *ngFor="let subject of jobDetails?.subject_taught">{{subject}},</span>
                        </p>
                        <!-- <div class="d-flex mt-5">
                        <div class="sub_w">
                        <span class="grey-2-14"><i class="bi bi-book mr-5"></i> Subjects :</span>
                    </div>
                    <div>
                        <span class="txt-regular mr-2" *ngFor="let subject of jobDetails?.subject_taught"> {{subject}},</span>
                    </div>
                    </div> -->
                        <p class="text-14-g1-m mt-5"><span class="grey-2-14"><i class="bi bi-check2-circle mr-5"></i>
                                Receive
                                applicants by :</span>
                            {{jobDetails?.apply_through.apply_through_display}}
                        </p>
                    </div>
                </div>
                <div class="row  c-p mt-20">
                    <div class="col-md-12 col-lg-12">
                        <p class="user-title">Job description</p>
                        <p class="text-14-g2-r mt-10" [innerHTML]="jobDescription"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <ng-template #actionModals>
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">Change application status</h4>
            <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
        </div>

        <div class="modal-body pb-0">
            <div class="row  align-items-center">
                <div class="col-md-12">
                    <p class="text-regular-medium" *ngIf="actionType == 'Hired'">Are you sure you want to change
                        {{teacherName}}'s status?</p>
                    <p class="text-regular-medium" *ngIf="actionType == 'Shortlisted'">Are you sure you want to change
                        {{teacherName}}'s status?</p>
                    <p class="text-regular-medium" *ngIf="actionType == 'Rejected'">Are you sure you want to change
                        {{teacherName}}'s status?</p>
                </div>
            </div>
            <div class="row mt-10 align-items-center">
                <div class="col-md-12">
                    <form [formGroup]="actionForm">
                        <div class="col-md-12">
                            <label for="exampleFormControlTextarea1" class="form-label txt-regular">Applicants aren't
                                notified when changing their status but you can choose to send a message to the
                                applicant
                                below</label>
                            <textarea class="form-control mt-5" formControlName="optional_message"
                                placeholder="Send a message to applicant (optional)" id="exampleFormControlTextarea1"
                                rows="3"></textarea>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        <div [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'"
            class="modal-footer mt-10">
            <button type="button" (click)="actions(applicant, actionType)" class="btn cc_p_btn fs_13">Confirm</button>
        </div>
    </ng-template>

    <ng-template #removeJobModal>
        <form [formGroup]="removeJobForm"
            [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Confirmation</h4>
                <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-0">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <p class="txt-regular">Are you sure you want to close this job?
                        </p>
                    </div>
                </div>
            </div>
            <div [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'"
                class="modal-footer mt-10">
                <button type="button" (click)="bsModalRef.hide()" class="btn_cc_back h_b">Cancel</button>
                <button type="button" (click)="closeJob(this.job_id)" class="btn cc_p_btn fs_13">
                    Confirm
                </button>
            </div>
        </form>
    </ng-template>
    <ng-template #updateStatusModal>
        <form [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : 'jobs-details'">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Change application status
                </h4>
                <button type="button" (click)="closeStatusModal()" class="btn-close" aria-label="Close"></button>
            </div>

            <div class="modal-body pb-0  mt-15">
                <div class="row align-items-center">
                    <form [formGroup]="actionForm">
                        <div class="col-md-12">
                            <div class="d-flex">
                                <div class="form-check">
                                    <input class="form-check-input mt-4" formControlName="status" value="H" type="radio"
                                        name="status" id="flexRadioDefault1">
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Hired
                                    </label>
                                </div>
                                <div class="form-check ml-30">
                                    <input class="form-check-input mt-4" formControlName="status" value="S" type="radio"
                                        name="status" id="flexRadioDefault2">
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Shortlisted
                                    </label>
                                </div>
                                <div class="form-check ml-30">
                                    <input class="form-check-input mt-4" formControlName="status" value="R" type="radio"
                                        name="status" id="flexRadioDefault3">
                                    <label class="form-check-label" for="flexRadioDefault3">
                                        Rejected
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-15">
                            <label for="exampleFormControlTextarea1" class="form-label txt-regular">Applicants aren't
                                notified when changing their status but you can choose to send a message to the
                                applicant
                                below</label>
                            <textarea class="form-control mt-5" formControlName="optional_message"
                                placeholder="Send a message to applicant (optional)" id="exampleFormControlTextarea1"
                                rows="3"></textarea>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer mt-10">
                <button type="button" (click)="actions('', 'selectAll')" class="btn cc_p_btn f_c fs_13">Confirm</button>
            </div>
        </form>
    </ng-template>
</div>