import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, } from '@angular/common/http';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class SchoolCalendarService {

  constructor(
    private httpClient: HttpClient,
    private apiUrl: AppConfig
  ) { }

  public getCalendarData = (startDate: any, endDate: any): Observable<any> => {
    return this.httpClient.get<any>(this.apiUrl.schoolApiUrl.calendar + '?start_date=' + startDate + '&end_date=' + endDate);
  }

  public addExternalEvent = (requestData: any): Observable<any> => {
    return this.httpClient.post<any>(this.apiUrl.schoolApiUrl.addExternalEvent, requestData);
  }
  public editExternalEvent = (requestData: any,selectID: any): Observable<any> => {
    return this.httpClient.put<any>(this.apiUrl.schoolApiUrl.addExternalEvent + selectID + '/', requestData);
  }

  public deleteExternalEvent = (id: any, params: any): Observable<any> => {
    // let params = new HttpParams();
    return this.httpClient.delete<any>(this.apiUrl.schoolApiUrl.addExternalEvent + id + '/', params);
  }

}
