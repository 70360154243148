@if (!sideNavHide) {
  <div>
    @if (!this.schoolProps.properties.job_only_school) {
      <div>
    <div id="mySidenav" class="sidenav" [ngClass]="{
      'sidenav-hide': collapsed || hideNavOnJobs,
      'sidenav-show': !collapsed || !hideNavOnJobs
    }">
          <ul class="sidenav-nav">
            <li class="sidenav-nav-item logo_space">
              <!-- <img alt="" class="side_logo_text" src="assets/images/side-nav/cc_original@2x.png"> -->
              <div class="logo-container" class="logo-container" [ngClass]="collapsed ? 'logo-container-collapsed' : ''">
                <button class="logo" (click)="toggleCollapsed()">
                  <i class="bi bi-chevron-double-left"></i>
                </button>
              </div>
            </li>
            <div class="accordion accordion-PanelsStayOpen" id="accordionPanelsStayOpenExample">
              <!-- Dashboard -->
              <!-- <li class="sidenav-nav-item">
              <a routerLink="/school" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                class="sidenav-nav-link">
                <span><i class="sidenav-icon bi bi-menu-button" data-bs-toggle="tooltip" data-bs-placement="right"
                title="Tooltip on right"></i></span>
                <span class="sidanav-label">Dashboard</span>
                <div class="sidanav-collapsed-label">Dashboard</div>
              </a>
            </li> -->
            <!-- End of Dashboard -->
            <!-- Booking -->
            @if (!this.hideSideMenu) {
              <a class="sidenav-nav-item"
                [ngClass]="is_booking_open ? 'tab-not-collapsed' : 'tab-collapsed'">
                <button class="sidenav-nav-link accordion-button" (click)="is_booking_collapsed()"  aria-label="Bookings" id="" aria-expanded="false"
                  routerLinkActive="active-submenu" [routerLinkActiveOptions]="{ exact: true }"
                  [ngClass]="is_booking_open ? 'tab-not-collapsed' : 'tab-collapsed'" aria-controls="">
                  <span><i class="sidenav-icon bi bi-calendar2-event"></i></span>
                  <span class="sidanav-label"> Booking</span>
                </button>
                @if (is_booking_open) {
                  <ul id="" class="submenu" aria-labelledby="" data-bs-parent="">
                    <li class="sidenav-nav-item">
                      <a routerLink="/school/bookings/booking-request"  aria-label="Booking Request"  routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
                        <i class="sidenav-collapsed-icon bi bi-calendar2-event"></i>
                        <span class="sidanav-label">Booking Request</span>
                      </a>
                    </li>
                    <li class="sidenav-nav-item">
                      <a routerLink="/school/bookings/preconfirm-booking" aria-label="Pre-Confirmed Booking" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
                        <i class="sidenav-collapsed-icon bi bi-calendar2-event"></i>
                        <span class="sidanav-label">Pre-Confirmed Booking</span>
                      </a>
                    </li>
                  </ul>
                }
              </a>
            }
            <!-- End of Booking -->
            <!-- Calendar -->
            @if (!this.hideSideMenu && this.schoolProps.properties.is_hub_school) {
              <li  aria-label="Hub and Spoke"  class="sidenav-nav-item">
                <a routerLink="/school/hub-spoke" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                  class="sidenav-nav-link">
                  <span><i class="sidenav-icon bi bi-diagram-2"></i></span>
                  <span class="sidanav-label">Hub and Spoke</span>
                  <span class="sidanav-collapsed-label">Hub and Spoke</span>
                </a>
              </li>
            }
            <!-- End of Calendar -->
            <!-- My List -->
            @if (!this.hideSideMenu) {
              <li class="sidenav-nav-item"  routerLinkActive="active-submenu"
                [routerLinkActiveOptions]="{ exact: true }">
                <button class="sidenav-nav-link accordion-button" aria-label="Manage" (click)="is_manage_collapsed()" id=""
                  [ngClass]="is_manage_open ? 'tab-not-collapsed' : 'tab-collapsed'">
                  <span><i class="sidenav-icon bi bi-people"></i></span>
                  <span class="sidanav-label">Manage</span>
                  <span class="sidanav-collapsed-label">Manage</span>
                </button>
                @if (is_manage_open) {
                  <ul id="" class="submenu" aria-labelledby="" data-bs-parent="">
                    <li class="sidenav-nav-item">
                      <a routerLinkActive="active"  aria-label="My List"  routerLink="/school/manage/my-list" [routerLinkActiveOptions]="{ exact: true }"
                        class="sidenav-nav-link">
                        <i class="sidenav-collapsed-icon bi bi-people"></i>
                        <span class="sidanav-label">My List</span>
                        <span class="sidanav-collapsed-label" data-bs-toggle="tooltip" data-bs-placement="right"
                        title="Tooltip on right">My List</span>
                      </a>
                    </li>
                    <li class="sidenav-nav-item">
                      <a routerLink="/school/manage/smart-groups" aria-label="Smart Groups" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
                        <i class="sidenav-collapsed-icon bi bi-people"></i>
                        <span class="sidanav-label">Smart Groups</span>
                        <span class="sidanav-collapsed-label">Smart Groups</span>
                      </a>
                    </li>
                    <li class="sidenav-nav-item">
                      <a routerLink="/school/manage/pending-invites" aria-label="Invites" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
                        <i class="sidenav-collapsed-icon bi bi-people"></i>
                        <span class="sidanav-label">Invites</span>
                        <span class="sidanav-collapsed-label">Invites</span>
                      </a>
                    </li>
                  </ul>
                }
              </li>
            }
            <!-- End of My List -->
            <!-- Discover -->
            @if (!this.hideSideMenu) {
              <li class="sidenav-nav-item" routerLinkActive="active-submenu"
                [routerLinkActiveOptions]="{ exact: true }">
                <button class="sidenav-nav-link accordion-button" aria-label="Discover" id="" (click)="is_discover_collapsed()"
                  [ngClass]="is_discover_open ? 'tab-not-collapsed' : 'tab-collapsed'" aria-controls="">
                  <span><i class="sidenav-icon bi bi-person-check"></i></span>
                  <span class="sidanav-label">Discover</span>
                  <span class="sidanav-collapsed-label">Discover</span>
                </button>
                @if (is_discover_open) {
                  <ul id="" class="submenu" aria-labelledby="">
                    <li class="sidenav-nav-item">
                      <a routerLink="/school/discover/find-new" aria-label="Find New" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                        class="sidenav-nav-link">
                        <i class="sidenav-collapsed-icon bi bi-person-check"></i>
                        <span class="sidanav-label">Find New</span>
                        <span class="sidanav-collapsed-label">Find New</span>
                      </a>
                    </li>
                    <li class="sidenav-nav-item">
                      <a routerLink="/school/discover/pending-request" aria-label="Pending Requests"  routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
                        <i class="sidenav-collapsed-icon bi bi-person-check"></i>
                        <span class="sidanav-label">Requests</span>
                        <span class="sidanav-collapsed-label">Requests</span>
                      </a>
                    </li>
                  </ul>
                }
              </li>
            }
            <!-- End of Discover -->
            <!-- Calendar -->
            @if (!this.hideSideMenu) {
              <li class="sidenav-nav-item">
                <a routerLink="/school/calendar"  aria-label="Calendar"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                  class="sidenav-nav-link">
                  <span><i class="sidenav-icon bi bi-calendar2-check"></i></span>
                  <span class="sidanav-label">Calendar</span>
                  <span class="sidanav-collapsed-label">Calendar</span>
                </a>
              </li>
            }
            <!-- End of Calendar -->
            <!-- Jobs -->
            <li class="sidenav-nav-item" (click)="switchToJobs()">
              <a class="sidenav-nav-link"  aria-label="Jobs">
                <span><i class="sidenav-icon bi bi-briefcase"></i></span>
                <span class="sidanav-label">Jobs</span>
                <span class="sidanav-collapsed-label">Jobs</span>
              </a>
            </li>
            <!-- End of Jobs -->
            <!-- Booking History -->
            @if (!this.hideSideMenu) {
              <li class="sidenav-nav-item">
                <a routerLink="/school/bookings/history" aria-label="Booking History" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
                  <span><i class="sidenav-icon bi bi-clock-history"></i></span>
                  <span class="sidanav-label">Booking History</span>
                  <span class="sidanav-collapsed-label">Booking History</span>
                </a>
              </li>
            }
            <!-- End of Booking History -->
            <!-- Discover -->
            @if (!this.hideSideMenu) {
              <li class="sidenav-nav-item" routerLinkActive="active-submenu"
                [routerLinkActiveOptions]="{ exact: true }">
                <button class="sidenav-nav-link accordion-button" aria-label="Reports" id="" (click)="is_reports_collapsed()"
                  [ngClass]="is_reports_open ? 'tab-not-collapsed' : 'tab-collapsed'" aria-controls="">
                  <span><i class="sidenav-icon bi bi-bar-chart"></i></span>
                  <span class="sidanav-label">Reports</span>
                  <span class="sidanav-collapsed-label">Reports</span>
                </button>
                @if (is_reports_open) {
                  <ul id="" class="submenu" aria-labelledby="">
                    <li class="sidenav-nav-item">
                      <a routerLink="/school/reports/insights-report"  aria-label="Insights" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
                        <i class="sidenav-collapsed-icon bi bi-bar-chart"></i>
                        <span class="sidanav-label">Insights</span>
                        <span class="sidanav-collapsed-label">Insights</span>
                      </a>
                    </li>
                    <li class="sidenav-nav-item">
                      <a routerLink="/school/reports/payroll-report" aria-label="Payroll Report" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
                        <i class="sidenav-collapsed-icon bi bi-bar-chart"></i>
                        <span class="sidanav-label">Payroll report </span>
                        <span class="sidanav-collapsed-label">Payroll report </span>
                      </a>
                    </li>
                  </ul>
                }
              </li>
            }
            <!-- End of Discover -->
            <!-- Reports -->
            <!-- <li class="sidenav-nav-item">
            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              class="sidenav-nav-link">
              <span><i class="sidenav-icon bi bi-bar-chart"></i></span>
              <span class="sidanav-label">Reports</span>
              <span class="sidanav-collapsed-label">Reports</span>
            </a>
          </li> -->
          <!-- End of Reports -->
          <div class="bottom-sidenav">
            <!-- Notification -->
            <!-- <li class="sidenav-nav-item">
            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              class="sidenav-nav-link">
              <span><i class="sidenav-icon bi bi-bell"></i></span>
              <span class="sidanav-label">Notification</span>
              <span class="sidanav-collapsed-label">Notification</span>
            </a>
          </li> -->
          <!-- End of Notification -->
          <!-- Profile Settings -->
          <!-- <li class="sidenav-nav-item">
          <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            class="sidenav-nav-link">
            <span><i class="sidenav-icon bi bi-graph-up-arrow"></i></span>
            <span class="sidanav-label">Profile Settings</span>
            <span class="sidanav-collapsed-label">Profile Settings</span>
          </a>
        </li> -->
        <!-- End of Profile Settings -->
        <!-- Admin Settings -->
        <!-- <li class="sidenav-nav-item">
        <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
          class="sidenav-nav-link">
          <span><i class="sidenav-icon bi bi-graph-up-arrow"></i></span>
          <span class="sidanav-label">Admin Settings</span>
          <span class="sidanav-collapsed-label">Admin Settings</span>
        </a>
      </li> -->
      <!-- End of Admin Settings -->
      <!-- Additional Users -->
      <!-- <li class="sidenav-nav-item">
      <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        class="sidenav-nav-link">
        <span><i class="sidenav-icon bi bi-graph-up-arrow"></i></span>
        <span class="sidanav-label">Additional Users</span>
        <span class="sidanav-collapsed-label">Additional Users</span>
      </a>
    </li> -->
    <!-- End of Additional Users -->
    <!-- Logout -->
    <li class="sidenav-nav-item">
      <a routerLink="" routerLinkActive="active" (click)="logout()" [routerLinkActiveOptions]="{ exact: true }"
        class="sidenav-nav-link">
        <span><i class="sidenav-icon bi bi-box-arrow-in-right"></i></span>
        <span class="sidanav-label">Logout</span>
        <span class="sidanav-collapsed-label">Logout</span>
      </a>
    </li>
    <!-- End of Logout -->
  </div>
</div>
</ul>
</div>
<div id="" class="sidenav-res" [ngClass]="collapsed ? 'sidenav-show' : 'sidenav-hide'">
  <ul class="sidenav-nav">
    <li class="sidenav-nav-item logo_space">
      <div class="logo-container" class="logo-container" [ngClass]="collapsed ? 'logo-container-collapsed' : ''">
        <a class="logo" (click)="toggleCollapsed()"><i class="bi bi-chevron-double-left"></i>
      </a>
    </div>
  </li>
  <div class="accordion accordion-PanelsStayOpen" id="accordionPanelsStayOpenExample">
    <!-- Booking -->
    <li class="sidenav-nav-item" [ngClass]="is_booking_open ? 'tab-not-collapsed' : 'tab-collapsed'">
      <a class="sidenav-nav-link accordion-button" (click)="is_booking_collapsed()" id="" aria-expanded="false"
        [ngClass]="is_booking_open ? 'tab-not-collapsed' : 'tab-collapsed'" aria-controls="">
        <span><i class="sidenav-icon bi bi-calendar2-event"></i></span>
      </a>
    </li>
    <!-- End of Booking -->
    <!-- Hub and spoke -->
    <li class="sidenav-nav-item">
      <a routerLink="/school/hub-spoke" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        class="sidenav-nav-link">
        <span><i class="sidenav-icon bi bi-diagram-2"></i></span>
      </a>
    </li>
    <!-- End  Hub and spoke -->
    <!-- My List -->
    <li class="sidenav-nav-item" routerLinkActive="active-submenu" [routerLinkActiveOptions]="{ exact: true }">
      <a class="sidenav-nav-link accordion-button" (click)="is_manage_collapsed()" id=""
        [ngClass]="is_manage_open ? 'tab-not-collapsed' : 'tab-collapsed'">
        <span><i class="sidenav-icon bi bi-people"></i></span>
      </a>
    </li>
    <!-- End of My List -->
    <!-- Discover -->
    <li class="sidenav-nav-item" routerLinkActive="active-submenu" [routerLinkActiveOptions]="{ exact: true }">
      <a class="sidenav-nav-link accordion-button" id="" (click)="is_discover_collapsed()"
        [ngClass]="is_discover_open ? 'tab-not-collapsed' : 'tab-collapsed'" aria-controls="">
        <span><i class="sidenav-icon bi bi-person-check"></i></span>
      </a>
    </li>
    <!-- End of Discover -->
    <!-- Calendar -->
    <li class="sidenav-nav-item">
      <a routerLink="/school/calendar" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        class="sidenav-nav-link">
        <span><i class="sidenav-icon bi bi-calendar2-check"></i></span>
      </a>
    </li>
    <!-- End of Calendar -->
    <!-- Jobs -->
    <li class="sidenav-nav-item">
      <a routerLink="/school/jobs" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        class="sidenav-nav-link">
        <span><i class="sidenav-icon bi bi-briefcase"></i></span>
      </a>
    </li>
    <!-- End of Jobs -->
    <!-- Booking History -->
    <li class="sidenav-nav-item">
      <a routerLink="/school/bookings/history" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
        <span><i class="sidenav-icon bi bi-clock-history"></i></span>
      </a>
    </li>
    <!-- End of Booking History -->
    <!-- Reports -->
    <!-- <li class="sidenav-nav-item">
    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
      class="sidenav-nav-link">
      <span><i class="sidenav-icon bi bi-bar-chart"></i></span>
      <span class="sidanav-label">Reports</span>
      <span class="sidanav-collapsed-label">Reports</span>
    </a>
  </li> -->
  <!-- End of Reports -->
  <div class="bottom-sidenav">
    <!-- Notification -->
    <!-- <li class="sidenav-nav-item">
    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
      class="sidenav-nav-link">
      <span><i class="sidenav-icon bi bi-bell"></i></span>
      <span class="sidanav-label">Notification</span>
      <span class="sidanav-collapsed-label">Notification</span>
    </a>
  </li> -->
  <!-- End of Notification -->
  <!-- Profile Settings -->
  <!-- <li class="sidenav-nav-item">
  <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
    class="sidenav-nav-link">
    <span><i class="sidenav-icon bi bi-graph-up-arrow"></i></span>
    <span class="sidanav-label">Profile Settings</span>
    <span class="sidanav-collapsed-label">Profile Settings</span>
  </a>
</li> -->
<!-- End of Profile Settings -->
<!-- Admin Settings -->
<!-- <li class="sidenav-nav-item">
<a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
  class="sidenav-nav-link">
  <span><i class="sidenav-icon bi bi-graph-up-arrow"></i></span>
  <span class="sidanav-label">Admin Settings</span>
  <span class="sidanav-collapsed-label">Admin Settings</span>
</a>
</li> -->
<!-- End of Admin Settings -->
<!-- Additional Users -->
<!-- <li class="sidenav-nav-item">
<a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
  class="sidenav-nav-link">
  <span><i class="sidenav-icon bi bi-graph-up-arrow"></i></span>
  <span class="sidanav-label">Additional Users</span>
  <span class="sidanav-collapsed-label">Additional Users</span>
</a>
</li> -->
<!-- End of Additional Users -->
<!-- Logout -->
<li class="sidenav-nav-item">
  <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
    <span><i class="sidenav-icon bi bi-box-arrow-in-right"></i></span>
  </a>
</li>
<!-- End of Logout -->
</div>
</div>
</ul>
</div>
</div>
}
<div class="">
  @if (!hideNavOnJobs) {
    <button class="btn_res" (click)="toggleCollapsed()">
      <i class="bi bi-list"></i>
    </button>
  }
</div>
@if (this.schoolProps.properties.job_only_school) {
  <div
    [ngClass]="{ jobs_only_user: this.schoolProps.properties.job_only_school }">
    <div id="mySidenav" class="sidenav" [ngClass]="{
        'sidenav-hide': collapsed || hideNavOnJobs,
        'sidenav-show': !collapsed || !hideNavOnJobs
      }">
      <ul class="sidenav-nav">
        <div class="accordion accordion-PanelsStayOpen" id="accordionPanelsStayOpenExample">
          <li class="sidenav-nav-item">
            <!-- <img alt="" class="side__jobs_logo" src="assets/images/side-nav/CC _Jobs.png"> -->
            <div class="logo-container" [ngClass]="collapsed ? 'logo-container-collapsed' : ''">
              <button class="logo" (click)="toggleCollapsed()">
                <i class="bi bi-chevron-double-left"></i>
              </button>
            </div>
          </li>
          <!-- <li *ngIf="!this.hideSideMenu" class="sidenav-nav-item"
          [ngClass]="is_booking_open ? 'tab-not-collapsed': 'tab-collapsed'">
          <a [ngClass]="makeJobsActive ? 'active-submenu' : ''" class="sidenav-nav-link accordion-button"
            (click)="is_booking_collapsed()" id="" aria-expanded="false" aria-controls="">
            <span><i class="sidenav-icon bi bi-calendar2-event"></i></span>
            <span class="sidanav-label">Jobs</span>
          </a>
          <ul id="" class="submenu" *ngIf="is_booking_open" aria-labelledby="" data-bs-parent="">
            <li class="sidenav-nav-item jobs-tab">
              <a routerLink="/school/jobs" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" class="sidenav-nav-link">
                <i class="sidenav-collapsed-icon bi bi-calendar2-event"></i>
                <span class="sidanav-label">Job listing</span>
              </a>
            </li>
            <li class="sidenav-nav-item jobs-tab">
              <a routerLink="/school/headhunter" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" class="sidenav-nav-link">
                <i class="sidenav-collapsed-icon bi bi-calendar2-event"></i>
                <span class="sidanav-label">Headhunter: Find & Invite</span>
              </a>
            </li>
          </ul>
        </li> -->
        <!-- Jobs -->
        @if (!this.hideSideMenu) {
          <li class="sidenav-nav-item">
            <a routerLink="/school/jobs" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              class="sidenav-nav-link">
              <span><i class="sidenav-icon bi bi-briefcase"></i></span>
              <span class="sidanav-label">Jobs</span>
              <span class="sidanav-collapsed-label">Jobs</span>
            </a>
          </li>
        }
        <!-- End of Jobs -->
        <!-- headhunter -->
        @if (!this.hideSideMenu) {
          <li class="sidenav-nav-item">
            <a routerLinkActive="active" (click)="refershHeadhunter()" routerLink="/school/jobs/headhunter"
              [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
              <span><i class="sidenav-icon bi bi-people"></i></span>
              <span class="sidanav-label">Headhunter</span>
              <span class="sidanav-collapsed-label">Headhunter</span>
            </a>
          </li>
        }
        <!-- End of headhunter -->
        <!-- Switch to bookings school -->
        <!-- <li class="sidenav-nav-item" *ngIf="this.schoolProps.properties.job_only_school  && this.schoolProps.properties.is_booking_school">
        <a href="https://booking.classcover.com.au/"
          class="sidenav-nav-link">
          <span><i class="sidenav-icon bi bi-people"></i></span>
          <span class="sidanav-label">Switch to bookings school</span>
          <span class="sidanav-collapsed-label">Switch to bookings school</span>
        </a>
      </li> -->
      <!-- End of Switch to bookings school -->
      <!-- Jobs -->
      <li class="sidenav-nav-item">
        <a routerLinkActive="active" routerLink="/school/jobs/job-payment-history"
          [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
          <span><i class="sidenav-icon bi bi-clock-history"></i></span>
          <span class="sidanav-label">Payment history</span>
          <span class="sidanav-collapsed-label">Payment history</span>
        </a>
      </li>
      <!-- End of Jobs -->
      <!-- Jobs -->
      @if (!this.schoolProps.properties.is_booking_school) {
        <li class="sidenav-nav-item">
          <a routerLinkActive="active" routerLink="/school/jobs/job-subscription"
            [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link">
            <span><i class="sidenav-icon bi bi-gem"></i></span>
            <span class="sidanav-label">Upgrade your account</span>
            <span class="sidanav-collapsed-label">Upgrade your account</span>
          </a>
        </li>
      }
      <!-- End of Jobs -->
      <!-- Jobs -->
      <!-- <li class="sidenav-nav-item">
      <a routerLinkActive="active" routerLink="/school/edit-admin-settings/additional-users"
        [routerLinkActiveOptions]="{exact: true}" class="sidenav-nav-link">
        <span><i class="sidenav-icon bi  bi-clock-history"></i></span>
        <span class="sidanav-label">Manage team</span>
        <span class="sidanav-collapsed-label">Manage team</span>
      </a>
    </li> -->
    <!-- End of Jobs -->
    <div class="bottom-sidenav">
      <!-- Logout -->
      <!-- <li class="sidenav-nav-item">
      <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidenav-nav-link">
        <span><i class="sidenav-icon bi bi-box-arrow-in-right"></i></span>
        <span class="sidanav-label">Logout</span>
        <span class="sidanav-collapsed-label">Logout</span>
      </a>
    </li> -->
    <!-- End of Logout -->
  </div>
</div>
</ul>
</div>
    <div id="" class="sidenav-res" [ngClass]="{
        'sidenav-hide': !collapsed || hideNavOnJobs,
        'sidenav-show': collapsed
      }">
  <ul class="sidenav-nav">
    <li class="sidenav-nav-item logo_space">
      <div class="logo-container" class="logo-container" [ngClass]="collapsed ? 'logo-container-collapsed' : ''">
        <a class="logo" (click)="toggleCollapsed()"><i class="bi bi-chevron-double-left"></i>
      </a>
    </div>
  </li>
  <div class="accordion accordion-PanelsStayOpen" id="accordionPanelsStayOpenExample">
    <!-- Jobs -->
    <li class="sidenav-nav-item">
      <a routerLink="/school/jobs" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        class="sidenav-nav-link navHov1">
        <span><i class="sidenav-icon bi bi-briefcase"></i></span>
        <span class="sideNav-colText">Jobs</span>
      </a>
    </li>
    <!-- End of Jobs -->
    <!-- Jobs -->
    <li routerLink="/school/jobs/headhunter" class="sidenav-nav-item">
      <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link navHov2">
        <span><i class="sidenav-icon bi bi-people"></i></span>
        <span class="sideNav-colText2">Headhunter</span>
      </a>
    </li>
    <!-- End of Jobs -->
    <!-- Jobs -->
    <li class="sidenav-nav-item">
      <a routerLink="/school/jobs/job-payment-history" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link navHov3">
        <span><i class="sidenav-icon bi bi-clock-history"></i></span>
        <span class="sideNav-colText3">Payment history</span>
      </a>
    </li>
    <!-- End of Jobs -->
    <!-- Jobs -->
    <!-- <li class="sidenav-nav-item">
    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidenav-nav-link">
      <span><i class="sidenav-icon bi bi-people"></i></span>
    </a>
  </li> -->
  <!-- End of Jobs -->
  <!-- Jobs -->
  @if (!this.schoolProps.properties.is_booking_school) {
    <li class="sidenav-nav-item">
      <a routerLink="/school/jobs/job-subscription" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }" class="sidenav-nav-link navHov4">
        <span><i class="sidenav-icon bi bi-person-check"></i></span>
        <span class="sideNav-colText4">Upgrade your account</span>
      </a>
    </li>
  }
  <!-- End of Jobs -->
</div>
</ul>
</div>
</div>
}
</div>
}
@if (sideNavHide) {
  <div>
    <div class="sidenav-demo-show">
      <li class="sidenav-nav-item mt-20">
        <a class="sidenav-nav-link">
          <span><i class="sidenav-icon bi bi-calendar2-event"></i></span>
          <span class="sidanav-label">Test booking</span>
          <span class="sidanav-collapsed-label">Test booking</span>
        </a>
      </li>
      <li class="sidenav-nav-item mt-20 customNavDemo">
        <hr>
          <a class="sidenav-nav-link"  (click)="stepTwo()">
            <span><i class="sidenav-icon bi bi-box-arrow-left"></i></span>
            <span class="sidanav-label">Exit Tutorial</span>
            <span class="sidanav-collapsed-label">Exit Tutorial</span>
          </a>
        </li>
      </div>
    </div>
  }