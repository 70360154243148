<div class="edit-profile pb-50 pt-15 ">
	<div class="row">
		<div class="col-lg-6 col-6 col-md-6 back-to d-flex">
			<p class="text-regular-bold c-p" routerLink="/school/my-settings" routerLinkActive="active"
				[routerLinkActiveOptions]="{exact: true}"><i class="bi bi-arrow-left-short"></i> Back</p>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-9 col-12 col-md-9" [ngClass]="editType != 'manage-smart-groups' ? 'col-lg-9 col-12 col-md-9' : 'col-lg-12 col-12 col-md-12'">
			<div class="col-lg-9 col-12 col-md-9" *ngIf="editType == 'update-password'">
				<p class="user-title mt-20 mb-20">Change Password</p>
				<form [formGroup]="updatePassword">
					<input type="text" class="form-control" placeholder="Current password"
						formControlName="current_password">
					<input type="text" class="form-control mt-20" placeholder="New password"
						formControlName="new_password">
					<input type="text" class="form-control mt-20" placeholder="Confirm new password"
						formControlName="confirm_password">
					<button type="button" (click)="SubmitForm('update-password')" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
					Change password
					</button>
				</form>
			</div>
			<div class="col-lg-9 col-12 col-md-9" *ngIf="editType == 'booking-and-notification-settings'">
				<p class="user-title mt-20">Booking and notification settings</p>
				<p class="user-title mt-30">Email notification</p>
				<form [formGroup]="bookingNotiSettings">
					<div class="form-check">                        
						<input class="form-check-input c-p" type="checkbox" id="summary_mail_every_morning"
							formControlName="summary_mail_every_morning">
						<label class="form-check-label c-p" for="summary_mail_every_morning">
						Send me a booking summary email every morning
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input c-p" type="checkbox" id="confirmation_every_booking"
							formControlName="confirmation_every_booking">
						<label class="form-check-label c-p" for="confirmation_every_booking">
						Send me confirmation of every booking I make
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input c-p" type="checkbox" id="confirmation_of_booking_made_by_staff"
							formControlName="confirmation_of_booking_made_by_staff">
						<label class="form-check-label c-p" for="confirmation_of_booking_made_by_staff">
						Send me confirmation of all casual bookings made by staff
						</label>
					</div>
					<p class="user-title mt-30">Booking options</p>
					<div class="form-check">
						<input class="form-check-input c-p" type="checkbox" id="booking_setting"
							formControlName="booking_setting">
						<label class="form-check-label c-p" for="booking_setting">
						Use school email address for all bookings made by me
						</label>
					</div>
					<p class="user-title mt-30">Default SMS type</p>
					<div class="form-check">
						<input class="form-check-input c-p" type="radio" name="sms_type" id="setting_blanket"
						formControlName="setting_blanket" [value]="1" [checked]="bookingNotiSettings.controls['setting_blanket'].value == 1 ? true : false">
						<label class="form-check-label c-p" for="setting_blanket">
						Blanket
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input c-p" type="radio" name="sms_type" id="setting_priority"
						formControlName="setting_blanket" [value]="0"   [checked]="bookingNotiSettings.controls['setting_blanket'].value == 0 ? true : false">
						<label class="form-check-label c-p" for="setting_priority">
						Priority
						</label>
					</div>
					<p class="user-title mt-30">Default booking form</p>
					<div class="form-check">
						<input class="form-check-input" formControlName="form_manual" [checked]="bookingNotiSettings.controls['form_manual'].value == 1 ? true : false" [value]="1" type="radio"
						name="booking_request" id="DefaultBookingFormEnd">
						<label class="form-check-label" for="DefaultBookingFormEnd">
						Pre-confirmed
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" formControlName="form_manual" [value]="0" [checked]="bookingNotiSettings.controls['form_manual'].value == 0 ? true : false" type="radio"
						name="booking_request" id="DefaultBookingFormAfter">
						<label class="form-check-label occurrence_loop" for="DefaultBookingFormAfter">
						Booking request
						</label>
					</div>
					<button type="button" (click)="SubmitForm('booking-notification')" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
					Save changes
					</button>
				</form>
			</div>
			<div class="col-lg-9 col-12 col-md-9" *ngIf="editType == 'default-search-settings'">
				<p class="user-title mt-20">Default search settings</p>
				<form [formGroup]="defaultSearchSettings">
					<div class="form-check mt-20">
						<input class="form-check-input c-p" type="checkbox" id="search_setting"
							formControlName="search_setting">
						<label class="form-check-label c-p" for="search_setting">
                            Include casuals who 'Haven't Indicated' availability for the current day in default searches.
						</label>
					</div>
                    <div class="form-check">
						<input class="form-check-input c-p" type="checkbox" id="show_incomplete_profile"
							formControlName="show_incomplete_profile">
						<label class="form-check-label c-p" for="show_incomplete_profile">
                            Show Incomplete Profile
						</label>
					</div>	
					<div class="form-check mt-10">
						<input class="form-check-input c-p" type="radio" name="list_setting_star" id="listByStart"
						formControlName="list_setting_star" [value]="1" [checked]="defaultSearchSettings.controls['list_setting_star'].value == 1 ? true : false">
						<label class="form-check-label c-p" for="listByStart">
                            List by star rating only.
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input c-p" type="radio" name="list_setting_star" id="listInAlpha"
						formControlName="list_setting_star" [value]="0"   [checked]="defaultSearchSettings.controls['list_setting_star'].value == 0 ? true : false">
						<label class="form-check-label c-p" for="listInAlpha">
                            List in alphabetical order (first name).
						</label>
					</div>
					<p class="user-title mt-30">Casuals request summary</p>
					<div class="form-check">
						<input class="form-check-input c-p" type="checkbox" id="teacher_request_summary"
							formControlName="teacher_request_summary">
						<label class="form-check-label c-p" for="teacher_request_summary">
						Receive weekly teacher requests summary Email
						</label>
					</div>
					<p class="user-title mt-30">Receive casuals request</p>
					<div class="form-check">
						<input class="form-check-input c-p" type="checkbox" id="show_school"
							formControlName="show_school">
						<label class="form-check-label c-p" for="show_school">
						Allow casuals to request to be added to your schools list
						</label>
					</div>
					<button type="button" (click)="SubmitForm('default-search')" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
					Save changes
					</button>
				</form>
			</div>
			<div class="col-lg-9 col-12 col-md-9" *ngIf="editType == 'manage-job-applicant-notifications'">
				<p class="user-title mt-20">Manage job applicant notifications</p>
				<p class="user-title mt-30 mb-10">Job applicant notifications</p>
				<form [formGroup]="jobApllicantNotification">
					<div class="form-check">
						<input class="form-check-input c-p" type="checkbox" (change)="setJobAlert();" id="enable_notification"
							formControlName="enable_notification">
						<label class="form-check-label c-p" for="enable_notification">
						Enable job application summary emails
						</label>
					</div>
					<div class="ml-25 mt-10">
						<div class="form-check input-cursor-na">
							<input class="form-check-input" formControlName="job_alert_notify" value="weekly"  [checked]="jobApllicantNotification.controls['job_alert_notify'].value == 'weekly' ? true : false" value="1"
								type="radio" name="job_alert_notify" id="job_alert_notify">
							<label class="form-check-label" for="job_alert_notify">
							Weekly
							</label>
						</div>
						<!-- <div class="form-check mt-5">
							<input class="form-check-input" formControlName="enableSummaryEmailType" value="2"
							    type="radio" name="enableSummaryEmailType" id="enableSummaryMonthly">
							<label class="form-check-label occurrence_loop" for="enableSummaryMonthly">
							    Monthly
							</label>
							</div> -->
					</div>
					<button type="button" (click)="SubmitForm('job-notification')" class="btn pl-20 pr-20 cc_p_btn fs_13 mt-28 btn-sm">
					Save changes
					</button>
				</form>
			</div>
			<div class="col-lg-12 col-12 col-md-12" *ngIf="editType == 'manage-smart-groups'">
				<p class="user-title mt-20" *ngIf="editType == 'manage-smart-groups'">Manage team access</p>
				<app-manage-smart-group></app-manage-smart-group>
			</div>
		</div>
		<div class="col-md-3 col-lg-3 col-12 mt-20" *ngIf="editType != 'manage-smart-groups'">
			<div class="card px-15">
				<p class="txt-regular c-p h_b active" routerLink="/school/my-settings" routerLinkActive="active"
					[routerLinkActiveOptions]="{exact: true}"><i class="bi bi-person-lines-fill mr-5"></i> Profile settings</p>
				<p class="txt-regular c-p mt-10 h_b" routerLink="/school/admin-settings" routerLinkActive="active"
					[routerLinkActiveOptions]="{exact: true}"><i class="bi bi-gear-fill mr-5"></i> Admin settings</p>
			</div>
		</div>
	</div>
</div>