<div class="row mt-10 align-items-center">
    <div class="col-md-9 col-lg-9 col-12">
        <p class="user-title" *ngIf="editType == 'additional-users'">Manage team access
        </p>
    </div>
    <div class="col-md-3 col-lg-3 col-12">
        <button (click)="openAddUserModal()" *ngIf="activeTab === 'manage-access'" type="button"
            class="btn cc_p_btn fs_13 float-right" id="invite-team">Invite team</button>
    </div>
</div>


<nav>
    <div class="nav nav-tabs mt-10" id="nav-tab" role="tablist">
        <button class="nav-link fs-18" [ngClass]="{ active: activeTab === 'manage-access' }"
            (click)="invites('manage-access')" id="nav-invite-tab" data-bs-toggle="tab" data-bs-target="#nav-invite"
            type="button" role="tab" aria-controls="nav-invite" aria-selected="true">
            Members and Permissions
        </button>
        <button class="nav-link fs-18" [ngClass]="{ active: activeTab === 'manage-smart-group' }"
            (click)="invites('manage-smart-group')" id="nav-history-tab" data-bs-toggle="tab"
            data-bs-target="#nav-history" type="button" role="tab" aria-controls="nav-history" aria-selected="false">
            Smart Groups: Classroom Teachers
        </button>
    </div>
</nav>
<div class="tab-content" id="nav-tabContent">
    <!-- Pending Tab Content -->
    <div class="tab-pane fade show" [ngClass]="{ active: activeTab === 'manage-access' }" id="nav-invite"
        role="tabpanel" aria-labelledby="nav-invite-tab">
        <div class="row sub-header">
            <!-- <div class="col-lg-4 col-md-4 left-content">
                <div class="d-flex">
                    <input #checkboxes (click)="checkAllStaff($event)" class="form-check-input mr-2" type="checkbox" value="" id="flexCheckDefault" />
                </div>
            </div> -->
            <div class="col-lg-12 col-md-12 flex-wrap gap-17 right-content top-filter">
                <div [ngClass]="!selectAll ? 'input-cursor-na':''" class="selected-data message-btn c-p h_b"
                    (click)="editSelected()">
                    <i class="bi bi-pencil-fill mr-5"></i>
                    Edit Selected
                </div>
                <div [ngClass]="!selectAll ? 'input-cursor-na':''" class="selected-data message-btn c-p h_b"
                    (click)="deleteSelected('')">
                    <i class="bi bi-trash mr-2"></i>
                    Delete Selected
                </div>
            </div>

            <div class="col-lg-12 col-12 col-md-12 mt-20">
                <div class="history-header sticky  px-15  md-d-none">
                    <div class="row align-items-center">
                        <div class="col-lg-4 offset-lg-1">
                            <p class="txt-regular">Name</p>
                        </div>
                        <div class="col-lg-3">
                            <p class="txt-regular">Role</p>
                        </div>
                        <div class="col-lg-2">
                            <p class="txt-regular">Permissions</p>
                        </div>
                        <div class="col-lg-2">
                            <p class="txt-regular"></p>
                        </div>
                    </div>
                </div>
                <div class="history-description mt-20">
                    <div class="card px-15 mt-10" *ngFor="let item of manageTeamData; index as i;"
                        [ngClass]="item?.role_access?.check_access == 'owner' ? 'owner-card' : ''">
                        <div class="row align-items-center">
                            <div class="col-lg-1 col-md-6 col-sm-6 col-12 d-flex justify-content-center">
                                <p class="txt-regular">
                                    <input #checkboxes (click)="setCheckbox(item, i)"
                                        [attr.disabled]="item?.role_access?.check_access == 'owner' ? '' : null"
                                        [checked]="item?.isChecked" [disabled]="
                                    item.hideme
                                    " class="form-check-input mr-2" type="checkbox" value="" id="flexCheckDefault" />
                                </p>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                                <p class="text-regular-bold lg-d-none mb-5">Name</p>
                                <p class="txt-regular">{{item.additional_user.user.full_name}}</p>
                                <p class="txt-regular mt-5">{{item.additional_user.user.email}}</p>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <p class="text-regular-bold lg-d-none mb-5">Role</p>
                                <p class="txt-regular">{{item.role_access.role}}</p>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-6 col-5">
                                <p class="text-regular-bold lg-d-none mb-5">Permissions</p>
                                <p class="txt-regular">{{item.role_access.access}}</p>
                            </div>
                            <div class="col-lg-2 col-md-4 col-sm-4 col-5 d-flex justify-content-end"
                                *ngIf="item?.role_access?.check_access != 'owner'">
                                <i class="bi bi-pencil-fill mr-20 clr-cc-vilot c-p"
                                    [ngClass]="selectAll ? 'input-cursor-na':''"
                                    (click)="openSelectedStaffEdit(item)"></i>
                                <i (click)="deleteSelected(item)" class="bi bi-trash clr-cc-vilot c-p"
                                    [ngClass]="selectAll ? 'input-cursor-na':''"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade show" [ngClass]="{ active: activeTab === 'manage-smart-group' }" id="nav-invite"
        role="tabpanel" aria-labelledby="nav-invite-tab">
        <app-manage-smart-group></app-manage-smart-group>
    </div>
</div>

<ng-template #editSelectedStaff>
    <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">
            <span *ngIf="!selectAll">
                {{selectedStaff?.additional_user?.user?.full_name}}
            </span>
            <span *ngIf="selectAll">
                Edit Permission and access
            </span>
        </h4>
        <button type="button" (click)="this.bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
    </div>
    <form [formGroup]="manageTeamAccessForm" #manageTeamAccess="ngForm">
        <div class="modal-body pb-0">
            <div class="row align-items-center">
                <div class="col-md-12 mt-10" *ngIf="!selectAll">
                    <label>Email</label>
                    <input class="form-control" placeholder="Email"
                        value="{{selectedStaff?.additional_user?.user?.email}}" disabled>
                </div>
                <div class="col-md-12 mt-10 ">
                    <div class="mb-10">
                        <label>Role</label>
                    </div>
                    <div class="form-check form-check-inline" *ngFor="let roleData of roleList; index as i;">
                        <input class="form-check-input c-p  mt-4" type="radio" name="role_id" id="{{roleData?.id}}"
                            formControlName="role_id" [value]="roleData?.id">
                        <label class="form-check-label c-p" for="{{roleData?.id}}">
                            {{roleData?.role}}
                        </label>

                    </div>
                    <div *ngIf="manageTeamAccess.submitted">
                        <span class="show-errors"
                            *ngIf="manageTeamAccessForm.controls['role_id'].errors?.['required'] || manageTeamAccessForm.controls['role_id'].errors?.['whitespace']">*
                            This field is required</span>
                    </div>
                </div>
                <div class="col-md-12 mt-10">
                    <div class="mb-10">
                        <label>Permissions</label>
                    </div>
                    <div class="form-check form-check-inline" *ngFor="let permission of permissionsList; index as i;">
                        <input class="form-check-input c-p mt-4" type="radio" name="permission" id="{{permission?.tag}}"
                            value="{{permission?.tag}}" formControlName="permission">
                        <label class="form-check-label c-p" for="{{permission?.tag}}">{{permission?.name}}</label>
                    </div>
                    <div *ngIf="manageTeamAccess.submitted">
                        <span class="show-errors"
                            *ngIf="manageTeamAccessForm.controls['permission'].errors?.['required'] || manageTeamAccessForm.controls['permission'].errors?.['whitespace']">*
                            This field is required</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <p class="text-right">
                    <button type="submit" class="btn cc_p_btn" (click)="makeAdminRta()">
                    Save
                </button>
            </p>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #RemovestaffConfirmation>
    <div class="modal-header">
        <h4 class="model_headings" id="editProfileInformationModal">
            Confirmation
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="btn-close" aria-label="Close"></button>
    </div>
    <div class="modal-body pb-0">
        <p>Are you sure you want to remove selected?</p>
    </div>
    <div class="modal-footer">
        <button type="submit" (click)="removeStaff()" class="btn cc_p_btn">
            Remove
        </button>
    </div>
</ng-template>

<ng-template #resendPendingInviteModal>
    <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">Additional user
        </h4>
        <button type="button" (click)="closeAddUserModal()" class="btn-close" aria-label="Close"></button>
    </div>
    <form [formGroup]="additionalUserForm">
        <div class="modal-body pb-0">
            <div class="row align-items-center">
                <div class="col-md-12 mt-10">
                    <input class="form-control" placeholder="First name " formControlName="first_name">
                </div>
                <div class="col-md-12 mt-10">
                    <input class="form-control" placeholder="Last name" formControlName="last_name">
                </div>
                <div class="col-md-12 mt-10">
                    <input class="form-control" placeholder="Email" formControlName="email">
                    <div *ngIf="submittedAdduser">
                        <span class="show-errors"
                            *ngIf="ad['email'].errors?.['required'] || ad['email'].errors?.['whitespace']">*
                            This field is required</span>
                    </div>
                </div>
                <div class="col-md-12 mt-10">
                    <input class="form-control" placeholder="Phone number" formControlName="phone">
                    <div *ngIf="submittedAdduser">
                        <span class="show-errors"
                            *ngIf="ad['phone'].errors?.['required'] || ad['phone'].errors?.['whitespace']">*
                            This field is required</span>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div [ngClass]="this.schoolProps.properties.job_only_school == true ? 'jobs_only_user' : ''"
        class="modal-footer mt-10">
        <button type="button" (click)="addAdditionalUser()" class="btn cc_p_btn  fs_13">Add user </button>
    </div>
</ng-template>