import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { FormBuilder, Validators, FormGroup, FormControl, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
    
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule]
})
export class ConfirmationModalComponent implements OnInit {

  public onClose!: Subject<boolean>;
  public confirmationDetails!: Subject<any>;

  public title!: string;
  public contents!: string;
  public body!: string;
  public subContent!: string;
  public confirmAction!: string;
  public saveBtnLable!: string;
  public showForm!: boolean;
  public inputLabel!: string;
  public hidePopUp: any;
  public teachers: any
  public requestType: any
  public delayTime: any
  popUpConfirmationForm!: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private confirmationFG: FormBuilder ) { }

  ngOnInit(): void {
    this.popUpConfirmationForm = this.confirmationFG.group({
      rejection_reason: ['']
    });
    this.onClose = new Subject();
    this.confirmationDetails = new Subject();
    // if(this.hidePopUp == 'hideModal'){
    //   let details = true;
    //   this.confirmationDetails.next(details);
    // }
  }

  confirmSave(): void {        
    //this.onClose.next(true);
    let details = this.showForm ? { 'formDetail': this.popUpConfirmationForm.value.rejection_reason, 'confirm': true } : true;
    this.confirmationDetails.next(details);
    this.bsModalRef.hide();
  }

  SuccessAlert(): void {        
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
