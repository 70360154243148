import { Component, HostListener, OnInit, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable } from "rxjs";
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

// Services
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';

// Libs
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TeacherProfilePopupComponent } from '../teacher-profile-popup/teacher-profile-popup.component';
import { ToastrService } from 'ngx-toastr';
import { MixpanelService } from 'src/app/mixpanel.service';
import { idLocale } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-bookings-in-flight',
  templateUrl: './bookings-in-flight.component.html',
  styleUrls: ['./bookings-in-flight.component.scss']
})
export class BookingsInFlightComponent implements OnInit {
  @ViewChild('cancelReasonModal') cancelReasonModal: any;

  isShowPending = false;
  isShowDeclined = false;
  booking_id: any;
  data: any;
  pendingPrecentage: any;
  stylePercentage: any;
  pending_status: any = []
  declined_status: any = []
  accepted_status: any = []
  booked_status: any = []
  public cancelBookingRequestForm: any
  submitted: boolean = false;
  availTeacher: any;
  ShowLenghtError: any = 280;
  ShowLenght: any;

  constructor(private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _router: Router,
    private bookingService: BookingService,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private mixpanelService: MixpanelService) { }
    bookingStatusForm: FormGroup = this.fb.group({
      id: [''],
    });
  ngOnInit(): void {
    this.ngxService.start();
    this.mixpanelService.inflightPage();

    this.route.params.subscribe(params => {
      this.booking_id = params['booking_id'];
    });
  
    this.formInit()
    this.checkLength()
    this.bookingService.getInFlightData(this.booking_id,'', '').subscribe({
      next: (res: any) => {
        this.data = res.data
        let pending_teachers = this.data.pending_teachers
        let total_teachers = this.data.total_teachers
        let is_booking_expired = this.data.is_closed;
        let booked_teachers = this.data.booked_teacher_count;
        let teacher_count = this.data.teacher_count;

        if(is_booking_expired == true){
          this.pendingPrecentage = ((booked_teachers / teacher_count) * 100)   
        }else{
          this.pendingPrecentage = ((pending_teachers / total_teachers) * 100)
        }
        this.stylePercentage = 'width:' + this.pendingPrecentage + '%';
        this.pending_status = [];
        this.declined_status = [];
        this.accepted_status = [];
        this.booked_status = [];


        for (let i = 0; i < this.data.notifications.length; i++) {
          if (this.data.notifications[i]['notification_response'] == 'Pending' || this.data.notifications[i]['status'] == 'Y') {
            this.pending_status.push(this.data.notifications[i])
          }
          if (this.data.notifications[i]['status'] == 'B' || this.data.notifications[i]['status'] == 'Z') {
            this.booked_status.push(this.data.notifications[i])
          }
          if (this.data.notifications[i]['status'] == 'A') {
            this.accepted_status.push(this.data.notifications[i])
          }
          if (this.data.notifications[i]['status'] == 'D') {
            this.declined_status.push(this.data.notifications[i])
          }
        }
        this.ngxService.stop();
      },
      error: (err: any) => {
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  formInit() {
    this.cancelBookingRequestForm = this.fb.group({
      cancel_message: ['', [Validators.required, this.noWhitespaceValidator]],
      booking_id: ['']
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length? null : { 'whitespace': true };       
  }
  toggleDisplayPending() {
    this.isShowPending = !this.isShowPending;
  }
  cloneBooking(id: any){
    this._router.navigate(['/school/booking-request/clone/'+id]); 
  }
  reOpenBooking(id: any){
    this._router.navigate(['/school/booking-request/reopen/' + id]); 
  }

  toggleDisplayDeclined() {
    this.isShowDeclined = !this.isShowDeclined;
  }
  get f(): { [key: string]: AbstractControl } {
    return this.cancelBookingRequestForm.controls;
  }
  getFormControlValues(){
    this.cancelBookingRequestForm.patchValue({
      booking_id: this.booking_id
    });
    return this.cancelBookingRequestForm.value;
  }
  cancelBookingRequest() {
    this.submitted = true;
    if (this.cancelBookingRequestForm.invalid) {
      return;
    }   
    this.ngxService.start();
    this.bookingService.stopBookingRequest(this.getFormControlValues()).subscribe({
      next: (res: any) => {
        if(res.status){
          this.modalService.hide()
          this.toastr.success(res.message);  
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        }else{
          // this.modalService.hide()
          this.toastr.error(res.message); 
        }
      },
      error: (err: any) => {
        this.toastr.error(err.statusText +', Please contact admin');
        console.log(err);
        this.ngxService.stop();
      }
    })
  }
  getTeacherAvailabilityModal(teacher_id: any){
    // this.ngxService.start();
    this.availTeacher = teacher_id
    this.bookingService.getAvailability(this.availTeacher).subscribe({
      next: (res: any) => {
        if(res.status){
          // this.obsArray.next(res.data.data);
          const initialState = {
            teacher: res.data
          };
          this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
          this.bsModalRef.content.closeBtnName = 'Close';
                  // this.ngxService.stop();
        }else{
          this.toastr.error(res.message); 
        }
       
      },
      error: (err: any) => {
        this.toastr.error(err.statusText +', Please contact admin');
        this.ngxService.stop();
        console.log(err);
      }
    });
  }
  cancelReason() {
    this.bsModalRef = this.modalService.show(this.cancelReasonModal, { class: 'modal-md', backdrop: 'static' });
  }
  closeModal() {
    this.cancelBookingRequestForm.reset();
    this.formInit();
    this.checkLength()
    this.submitted= false
    this.bsModalRef.hide()
  }

  addMoreStaff(id: any){
    this.ngxService.start()
    this.bookingStatusForm.patchValue({
      id:id
    })
    this.bookingService.checkBookingStatus(this.bookingStatusForm.value).subscribe({
      next: (res: any) => {
        if(res.status == true){
          this._router.navigate(['/school/booking-request/' + id]); 
        }else if(res.status == false){
          this.toastr.error(res.message)
          let currentUrl = this._router.url;
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate([currentUrl]);
          });
        }   
      },
      error: (err: any) => {
        this.ngxService.stop()
        let currentUrl = this._router.url;
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigate([currentUrl]);
        });
        this.toastr.error(err.message)
        console.log(err);
      }
    })
  }

  teacherProfile(teacher: any) {
    const initialState = {
      teacher: teacher
    };
    this.bsModalRef = this.modalService.show(TeacherProfilePopupComponent, { initialState, class: 'modal-xl' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  checkLength(){
    let timing = this.cancelBookingRequestForm.controls['cancel_message'].value;  
    let charectorLength = this.ShowLenghtError - timing.length
    this.ShowLenght = charectorLength
  }
  refreshPage(){
    this.ngxService.start()
    this.bookingService.getInFlightData(this.booking_id,'','').subscribe({
      next: (res: any) => {
        this.data = res.data
        let pending_teachers = this.data.pending_teachers
        let total_teachers = this.data.total_teachers
        let is_booking_expired = this.data.is_closed;
        let booked_teachers = this.data.booked_teacher_count;
        let teacher_count = this.data.teacher_count;

        if(is_booking_expired == true){
          this.pendingPrecentage = ((booked_teachers / teacher_count) * 100)   
        }else{
          this.pendingPrecentage = ((pending_teachers / total_teachers) * 100)
        }
        this.stylePercentage = 'width:' + this.pendingPrecentage + '%';
        this.pending_status = [];
        this.declined_status = [];
        this.accepted_status = [];
        this.booked_status = [];


        for (let i = 0; i < this.data.notifications.length; i++) {
          if (this.data.notifications[i]['notification_response'] == 'Pending' || this.data.notifications[i]['status'] == 'Y') {
            this.pending_status.push(this.data.notifications[i])
          }
          if (this.data.notifications[i]['status'] == 'B' || this.data.notifications[i]['status'] == 'Z') {
            this.booked_status.push(this.data.notifications[i])
          }
          if (this.data.notifications[i]['status'] == 'A') {
            this.accepted_status.push(this.data.notifications[i])
          }
          if (this.data.notifications[i]['status'] == 'D') {
            this.declined_status.push(this.data.notifications[i])
          }
        }
        this.ngxService.stop()
      },
      error: (err: any) => {
        this.ngxService.stop()
        this.toastr.error(err.message)
        console.log(err);
      }
    })
  }
}
