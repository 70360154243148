import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwichBackToPythonComponent } from '../layout/swich-back-to-python/swich-back-to-python.component';
import { PopoverModule } from 'ngx-bootstrap/popover';


@NgModule({
    imports: [
        CommonModule,
        PopoverModule.forRoot(),
        SwichBackToPythonComponent
    ],
    exports: [
        SwichBackToPythonComponent,
    ]
})
export class SharedModuleModule { }
