import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationModalComponent } from 'src/app/layout/confirmation-modal/confirmation-modal.component';
import { BookingService } from 'src/app/services/api/booking.service';
import { CommonService } from 'src/app/services/api/common.service';


@Component({
  selector: 'app-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.scss']
})
export class AddNotesComponent implements OnInit {
  public addEditNotes: any;
  teacherName: any;
  type: any;
  notes: any;
  id: any;
  from_page: any;
  date: any
  teachers_list:any = [];
  @ViewChild('addEdit') addEdit: any;
  submitted: boolean = false;
  ShowLenghtError: any = 200;
  ShowLenght: any;

  constructor(public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private bookingService: BookingService,
    private _router: Router,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.formInit();
    this.checkLength()
  }
  formInit() {
    this.addEditNotes = this.fb.group({
      notes: [this.notes , [Validators.required,this.noWhitespaceValidator]],
      consumer_id:[''],
      from_page:[this.from_page],
      date:[this.date]
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length? null : { 'whitespace': true };       
  }
  get f(): { [key: string]: AbstractControl } {
    return this.addEditNotes.controls;
  }
  getControlMailValues(){
    this.addEditNotes.patchValue({
      consumer_id : this.id.toString(),
    });
    return this.addEditNotes.value; 
  }
  addNotes(){
    this.submitted = true;
    if (this.addEditNotes.invalid) {
      return;
    }
    this.ngxService.start();
    this.bookingService.addNotesApi(this.getControlMailValues()).subscribe({
      next: (res: any) => {
        if(res.status){
          this.bsModalRef.hide();
          this.toastr.success(res.message);  
          var index = this.teachers_list.map((x:any) => {
            return x.teacher.id;
            }).indexOf(res.data.data[0].teacher.id);
            this.teachers_list[index] = res.data.data[0];
            this.ngxService.stop();
          // let currentUrl = this._router.url;
          // this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          //   this._router.navigate([currentUrl]);
          // });
        }else{
          this.toastr.error(res.message);  
          this.ngxService.stop();
        }
      },
      error: (err: any) => {
        this.toastr.error(err.statusText +', Please contact admin');
        console.log(err);
        this.ngxService.stop();
      }
    })
  }

  checkLength(){
    let timing = this.addEditNotes.controls['notes'].value;  
    let charectorLength = this.ShowLenghtError - timing.length
    this.ShowLenght = charectorLength
  }
}
